import React, { useCallback } from "react";
import cn from "classnames";
import { Modal } from "oneclick-component/src/components/Modal";
import { Trans } from "react-i18next";
import { Button } from "oneclick-component/src/components/Button";

interface Props {
  setShowDialog: (show: boolean) => void;
  onSend: () => void;
  isOpen: boolean;
}

export const SendingInProgressDialog = (props: Props): React.ReactElement => {
  const { setShowDialog, isOpen, onSend } = props;

  const onClickConfirm = useCallback(() => {
    setShowDialog(false);
    onSend();
  }, [setShowDialog, onSend]);

  const handleOnClose = useCallback(() => {
    setShowDialog(false);
  }, [setShowDialog]);

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose} hasXMarkButton={true}>
      <div className={cn("sm:flex", "sm:items-start", "w-full")}>
        <div
          className={cn(
            "mt-3",
            "text-center",
            "sm:mt-0",
            "sm:text-left",
            "w-full"
          )}
        >
          <p className={cn("text-red-600", "text-2xl", "mb-6")}>
            <Trans i18nKey="sendingInProgress.dialog.title" />
          </p>
          <p className="mb-9">
            <Trans i18nKey="sendingInProgress.dialog.description" />
          </p>
        </div>
      </div>
      <div
        className={cn(
          "mt-5",
          "sm:mt-4",
          "flex",
          "flex-row",
          "justify-center",
          "sm:justify-end",
          "items-center"
        )}
      >
        <Button
          className={cn(
            "font-medium",
            "flex-1",
            "self-stretch",
            "sm:flex-none"
          )}
          onClick={handleOnClose}
          theme="white"
        >
          <Trans i18nKey="sendingInProgress.dialog.action.cancel" />
        </Button>
        <Button
          className={cn("font-medium", "ml-3", "flex-1", "sm:flex-none")}
          theme="primary"
          onClick={onClickConfirm}
        >
          <Trans i18nKey="sendingInProgress.dialog.action.confirm" />
        </Button>
      </div>
    </Modal>
  );
};
