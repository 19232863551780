import {
  Station,
  useListShiftRequestHandlerShiftRequestsGetQuery as useListShiftRequestQuery,
  ListShiftRequestHandlerShiftRequestsGetApiArg as ListShiftRequestQueryArg,
} from "oneclick-component/src/store/apis/enhancedApi";
import useMeUserStations from "../../../hooks/useMeUserStations";
import { useMemo } from "react";
import {
  ViewState,
  makeViewStateEmpty,
  makeViewStateError,
  makeViewStateLoaded,
  makeViewStateLoading,
} from "./viewState";

export interface UseIncomingTabQueryOptions
  extends Partial<ListShiftRequestQueryArg> {
  pageIndex?: number;
  pageSize?: number;
  shiftBefore?: string | null;
  shiftAfter?: string | null;
  shiftSearchString?: string | null;
  shiftRateId?: number[] | null;
  shiftWorkingStationId?: number[] | null;
  ptSearchString?: string | null;
  ptRoleId?: number[] | null;
}

const useIncomingTabQuery = (
  options?: UseIncomingTabQueryOptions
): ViewState => {
  const meStations: Station[] | "all" = useMeUserStations();

  const meWorkingStationIds = useMemo<number[] | null>(() => {
    if (meStations === "all" || meStations.length === 0) {
      return null;
    }
    return meStations.reduce(
      (acc: number[], curr: Station) => [
        ...acc,
        ...curr.workingStations.map((ws) => ws.id),
      ],
      []
    );
  }, [meStations]);

  const {
    data: listShiftRequestData,
    isFetching: isListShiftRequestFetching,
    error: listShiftRequestError,
  } = useListShiftRequestQuery(
    {
      shiftStatus: ["active", "expired"],
      shiftRequestStatus: ["hired"],
      ...options,
      shiftWorkingStationId:
        options?.shiftWorkingStationId ?? meWorkingStationIds,
    },
    { refetchOnMountOrArgChange: true }
  );

  if (listShiftRequestData == null || isListShiftRequestFetching) {
    return makeViewStateLoading();
  }

  if (listShiftRequestError != null) {
    return makeViewStateError(listShiftRequestError);
  }

  if (listShiftRequestData.results.length === 0) {
    return makeViewStateEmpty();
  }

  return makeViewStateLoaded(
    listShiftRequestData.results,
    listShiftRequestData.totalCount,
    options?.pageIndex ?? 0
  );
};

export default useIncomingTabQuery;
