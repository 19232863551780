import React, {
  useCallback,
  ReactElement,
  CSSProperties,
  ForwardedRef,
} from "react";
import { Waypoint } from "react-waypoint";
import { Trans } from "react-i18next";
import { Combobox } from "@headlessui/react";
import cn from "classnames";

import { LoadingSpinner } from "../../LoadingSpinner";
import { SingleSelectComboBoxOption } from "./model";

export interface ComboBoxOptionsProps<T> {
  style?: CSSProperties;
  filteredOptions: SingleSelectComboBoxOption<T>[];
  isLoading: boolean;
  customRenderOptions?: (
    selected: boolean,
    active: boolean,
    option: SingleSelectComboBoxOption<T>
  ) => ReactElement;
  hasMoreOptions?: boolean;
  onLoadMoreOptions?: () => void;
}

function ComboBoxOptionsImpl<T>(
  props: ComboBoxOptionsProps<T>,
  ref: ForwardedRef<HTMLUListElement>
): ReactElement {
  const {
    style,
    filteredOptions,
    isLoading,
    customRenderOptions,
    hasMoreOptions,
    onLoadMoreOptions,
  } = props;

  const renderOptions = useCallback(
    (
      selected: boolean,
      active: boolean,
      option: SingleSelectComboBoxOption<T>
    ) => {
      if (customRenderOptions != null) {
        return customRenderOptions(selected, active, option);
      }
      return (
        <div
          className={cn(
            "flex",
            "flex-row",
            "items-center",
            "space-x-2",
            "px-3",
            "pt-3",
            "last:pb-3",
            "cursor-pointer",
            {
              "bg-gray-100": active,
            }
          )}
        >
          <span className={cn("block", "truncate", "text-sm", "font-medium")}>
            {option.name}
          </span>
        </div>
      );
    },
    [customRenderOptions]
  );

  return (
    <Combobox.Options
      ref={ref}
      style={style}
      className={cn(
        "absolute",
        "mt-1",
        "max-h-60",
        "w-full",
        "overflow-auto",
        "rounded-md",
        "bg-white",
        "py-1",
        "text-base",
        "shadow-lg",
        "ring-1",
        "ring-black/5",
        "focus:outline-none",
        "sm:text-sm",
        "z-50"
      )}
    >
      {filteredOptions.length === 0 ? (
        <>
          {isLoading ? (
            <div className={cn("flex", "justify-center", "my-1", "mb-2")}>
              <LoadingSpinner size="s" />
            </div>
          ) : (
            <div
              className={cn(
                "relative",
                "cursor-default",
                "select-none",
                "py-2",
                "px-4",
                "text-gray-700"
              )}
            >
              <Trans i18nKey="reactComponent.comboBox.nothingFound" />
            </div>
          )}
        </>
      ) : (
        filteredOptions.map((option) => (
          <Combobox.Option
            key={option.name}
            value={option.value}
            className={cn(
              "relative",
              "cursor-default",
              "select-none",
              "text-sm",
              "text-gray-700"
            )}
          >
            {({ selected, active }) => renderOptions(selected, active, option)}
          </Combobox.Option>
        ))
      )}
      {hasMoreOptions ? (
        <>
          <Waypoint onEnter={onLoadMoreOptions} />
          <div className={cn("flex", "justify-center", "my-1", "mb-2")}>
            <LoadingSpinner size="s" />
          </div>
        </>
      ) : null}
    </Combobox.Options>
  );
}

export const ComboBoxOptions = React.forwardRef(ComboBoxOptionsImpl) as <T>(
  props: ComboBoxOptionsProps<T> & {
    ref?: ForwardedRef<HTMLUListElement>;
  }
) => ReactElement;
