import React, { useMemo } from "react";
import Table from "oneclick-component/src/components/Table";
import Pagination from "oneclick-component/src/components/Pagination";
import cn from "classnames";
import { Announcement } from "oneclick-component/src/store/apis/enhancedApi";
import {
  AnnouncementListRecord,
  useAnnouncementListTable,
} from "./useAnnouncementListTable";
import { isoToDateTime } from "oneclick-component/src/utils/datetime";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import { ListEmptyView } from "../../components/EmptyView";
import { useTranslation } from "react-i18next";
import useOnClickTableColumnHeader, {
  ControlledTableState,
} from "oneclick-component/src/hooks/useOnClickTableColumnHeader";

interface Props {
  announcementList: Announcement[];
  pageIndex: number;
  totalPages: number;
  onClickPage: (pageIndex: number) => void;
  isLoading: boolean;
  controlledTableState: ControlledTableState;
  setControlledTableState: (
    action: (prev: ControlledTableState) => ControlledTableState
  ) => void;
  onClickTableRowFactory: (announcementId: number) => () => void;
}

const transformFromAnnouncementToAnnouncementListRecord = (
  announcement: Announcement
): AnnouncementListRecord => {
  return {
    ...announcement,
    recipient: announcement.recipient,
    sentAt: isoToDateTime(announcement.sentAt),
    announcementId: announcement.id,
  };
};

const AnnouncementListView = (props: Props): React.ReactElement => {
  const {
    announcementList,
    pageIndex,
    totalPages,
    onClickPage,
    isLoading,
    controlledTableState,
    setControlledTableState,
    onClickTableRowFactory,
  } = props;
  const tableData = useMemo(() => {
    return announcementList.map(
      transformFromAnnouncementToAnnouncementListRecord
    );
  }, [announcementList]);

  const onClickTableColumnHeader =
    useOnClickTableColumnHeader<AnnouncementListRecord>(
      setControlledTableState
    );
  const table = useAnnouncementListTable(tableData, {
    columnVisibility: {
      announcementId: false,
    },
    sorting: controlledTableState.sorting,
  });
  const { t } = useTranslation();

  return (
    <>
      <Table
        table={table}
        className="shadow"
        onClickColumnHeader={onClickTableColumnHeader}
      >
        {isLoading ? (
          <tr className={"h-171"}>
            <td colSpan={table.getVisibleFlatColumns().length}>
              <span className={cn("flex", "justify-center", "items-center")}>
                <LoadingSpinner />
              </span>
            </td>
          </tr>
        ) : announcementList.length === 0 ? (
          <tr className={"h-171"}>
            <td colSpan={table.getVisibleFlatColumns().length}>
              <ListEmptyView description={t("announcementList.emptyState")} />
            </td>
          </tr>
        ) : (
          table
            .getRowModel()
            .rows.map((row) => (
              <Table.Row
                key={row.id}
                row={row}
                className="cursor-pointer"
                onClick={onClickTableRowFactory(row.original.announcementId)}
              />
            ))
        )}
      </Table>
      <Pagination
        pageIndex={pageIndex}
        totalPages={totalPages}
        onClickPage={onClickPage}
        className={cn(
          "bg-white",
          "fixed",
          "bottom-0",
          "inset-x-0",
          "sm:static"
        )}
      />
    </>
  );
};

export default AnnouncementListView;
