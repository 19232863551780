import React, { ReactElement, ReactNode, useCallback } from "react";
import { Tab } from "@headlessui/react";
import cn from "classnames";
import { Link, To, useLocation, Outlet } from "react-router-dom";

interface TabMenuItem {
  icon?: React.ComponentType<Omit<React.SVGProps<SVGSVGElement>, "ref">>;
  title: string;
  to: To;
}

interface TabMenuProps {
  panels: TabMenuItem[];
  actionButton?: ReactNode;
  className?: string;
}

const TabMenu = (props: TabMenuProps): ReactElement => {
  const { panels, className, actionButton } = props;
  const location = useLocation();
  const selectedIndex = panels.findIndex(
    (panel) => panel.to === location.pathname
  );

  const getTabClasses = useCallback(({ selected }: { selected: boolean }) => {
    return cn(
      selected
        ? ["border-primary-500", "text-primary-600"]
        : [
            "border-transparent",
            "text-gray-500",
            "hover:border-gray-300",
            "hover:text-gray-700",
          ],
      "group",
      "inline-flex",
      "items-center",
      "border-b-2",
      "py-4",
      "px-1",
      "text-sm",
      "font-medium",
      "focus:outline-0",
      "focus:outline-none",
      "whitespace-nowrap"
    );
  }, []);

  return (
    <Tab.Group selectedIndex={selectedIndex}>
      <div className={cn("relative", "sm:pb-0", "pb-3")}>
        <div
          className={cn(
            "bg-gradient-to-l",
            "from-white",
            "h-full",
            "w-20",
            "absolute",
            "right-0",
            "pointer-events-none",
            "sm:hidden"
          )}
        />
        <div
          className={cn(
            "flex",
            "justify-between",
            "items-center",
            "overflow-x-auto",
            "overflow-y-hidden",
            "border-b",
            "border-gray-200",
            "pr-10",
            "sm:pr-0"
          )}
        >
          <Tab.List
            className={cn(
              "space-x-8",
              "flex-1",
              "flex",
              "justify-center",
              "sm:justify-start",
              className
            )}
          >
            {panels.map((item, index) => (
              <Tab key={index} className={getTabClasses} as={Link} to={item.to}>
                {({ selected }) => (
                  <>
                    {item.icon != null ? (
                      <item.icon
                        className={cn(
                          selected
                            ? ["text-primary-500", "fill-primary-500"]
                            : [
                                "text-gray-400",
                                "group-hover:text-gray-500",
                                "fill-gray-400",
                                "group-hover:fill-gray-500",
                              ],
                          "-ml-0.5",
                          "mr-2",
                          "h-5",
                          "w-5"
                        )}
                        aria-hidden="true"
                      />
                    ) : null}
                    <span>{item.title}</span>
                  </>
                )}
              </Tab>
            ))}
          </Tab.List>
          {actionButton}
        </div>
      </div>
      <Tab.Panels className={cn("pt-0", "sm:pt-5")}>
        {panels.map((_, index) => (
          <Tab.Panel key={index}>
            <Outlet />
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
};

export default TabMenu;
