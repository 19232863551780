import {
  ColumnDef,
  Table,
  TableState,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { DateTime } from "luxon";
import { AnnouncementType } from "oneclick-component/src/store/apis/enhancedApi";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import {
  formatDateTime,
  localizeDateTime,
} from "oneclick-component/src/utils/datetime";
import { useBreakPoints } from "oneclick-component/src/providers";
import { renderAnnouncementRecipient } from "../../models/announcement";

export interface AnnouncementListRecord {
  content: string;
  type: AnnouncementType;
  recipient: string;
  sentAt: DateTime;
  senderEmail: string;

  // invisible columns
  announcementId: number;
}

const columnHelper = createColumnHelper<AnnouncementListRecord>();

export const useAnnouncementListTableColumns = (): ColumnDef<
  AnnouncementListRecord,
  any
>[] => {
  const { t } = useTranslation();
  const { useIsSm } = useBreakPoints();
  const isSm = useIsSm();
  return [
    columnHelper.accessor("content", {
      size: isSm ? 664 : 200,
      header: () => t("announcementList.table.header.content"),
      cell: ({ cell }) => {
        return (
          <p
            className={cn(
              "sm:py-4",
              "font-normal",
              "text-sm",
              "leading-5",
              "text-black/86",
              "line-clamp-4",
              "w-50", // 200px, same as above size
              "sm:w-166" // 664px, same as above size
            )}
          >
            {cell.getValue()}
          </p>
        );
      },
    }),
    columnHelper.accessor("type", {
      minSize: 48,
      header: () => t("announcementList.table.header.type"),
      cell: ({ cell }) => {
        const type: AnnouncementType = cell.getValue() as AnnouncementType;
        const displayString =
          type === "FRIENDLY_REMINDER"
            ? t("announcement.type.friendlyReminder")
            : t("announcement.type.important");
        return (
          <p
            className={cn(
              "sm:py-4",
              "font-normal",
              "text-sm",
              "leading-5",
              "text-gray-500"
            )}
          >
            {displayString}
          </p>
        );
      },
    }),
    columnHelper.accessor("recipient", {
      minSize: 48,
      header: () => t("announcementList.table.header.recipient"),
      cell: ({ cell }) => {
        const recipient = cell.getValue();
        const recipientDisplay = renderAnnouncementRecipient(
          recipient,
          true,
          t
        );
        return (
          <p
            className={cn(
              "sm:py-4",
              "font-normal",
              "text-sm",
              "leading-5",
              "text-gray-500"
            )}
          >
            {recipientDisplay}
          </p>
        );
      },
    }),
    columnHelper.accessor("sentAt", {
      minSize: 152,
      header: () => t("announcementList.table.header.sentAt"),
      cell: ({ cell }) => {
        const sentAt: DateTime = cell.getValue() as DateTime;
        const displayString = `${localizeDateTime(
          sentAt,
          DateTime.DATE_MED
        )} ${formatDateTime(sentAt, "HH:mm")}`;
        return (
          <p
            className={cn(
              "sm:py-4",
              "font-normal",
              "text-sm",
              "leading-5",
              "text-gray-500"
            )}
          >
            {displayString}
          </p>
        );
      },
    }),
    columnHelper.accessor("senderEmail", {
      minSize: 98,
      header: () => t("announcementList.table.header.senderEmail"),
      cell: ({ cell }) => {
        return (
          <p
            className={cn(
              "sm:py-4",
              "font-normal",
              "text-sm",
              "leading-5",
              "text-gray-500"
            )}
          >
            {cell.getValue()}
          </p>
        );
      },
    }),
    columnHelper.accessor("announcementId", {
      enableHiding: true,
    }),
  ];
};

export const useAnnouncementListTable = (
  data: AnnouncementListRecord[],
  state?: Partial<TableState>
): Table<AnnouncementListRecord> => {
  const announcementListTableColumns = useAnnouncementListTableColumns();
  return useReactTable({
    data: data,
    columns: announcementListTableColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    defaultColumn: {
      minSize: 0,
      size: Number.MAX_SAFE_INTEGER,
      maxSize: Number.MAX_SAFE_INTEGER,
    },
    state: state,
    manualSorting: true,
  });
};
