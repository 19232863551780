import React, { useContext } from "react";
import cn from "classnames";
import {
  ColumnDef,
  Table,
  TableState,
  createColumnHelper,
  getCoreRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  useReactTable,
  OnChangeFn,
  RowSelectionState,
} from "@tanstack/react-table";
import { t } from "i18next";
import { useBreakPoints } from "oneclick-component/src/providers";
import { ShiftRateViewModel } from "oneclick-component/src/components/ShiftRateBadge";
import ShiftBadge from "oneclick-component/src/components/ShiftRateBadge/ShiftBadge";
import {
  ShiftType,
  BriefStation,
} from "oneclick-component/src/store/apis/enhancedApi";
import ShiftT3Badge from "oneclick-component/src/components/Badge/ShiftT3Badge";
import { StationBadge } from "oneclick-component/src/components/Badge";
import { FeatureConfigContext } from "../../../providers/FeatureConfigProvider";

export interface CancelledShiftListRecord {
  info: {
    title: string;
    subtitle: React.ReactElement;
    rate: ShiftRateViewModel | null;
    isAppliableToAllAtT3: boolean;
  };
  timeRange: string;
  cancelReason: string;
  partTimeType: string;
  numberOfApplicants: number;
  hiredCount: number;
  // grouping/sorting, invisible columns
  title: string;
  date: string;
  id: number;

  // helper info
  shiftType: ShiftType;
  remoteStation?: BriefStation | null;
}

const columnHelper = createColumnHelper<CancelledShiftListRecord>();

export const useCancelledShiftListTableColumns = (): ColumnDef<
  CancelledShiftListRecord,
  any
>[] => {
  const { shouldShowT3Feature } = useContext(FeatureConfigContext);
  const { useIsSm } = useBreakPoints();
  const isSm = useIsSm();
  return [
    columnHelper.accessor("info", {
      header: () => t("shiftList.cancelled.table.header.shiftInfo"),
      cell: ({ cell, row }) => {
        const info = cell.getValue();
        const isIncident = row.original.shiftType === "INCIDENT";
        return (
          <div className={cn("flex", "flex-row", "items-center")}>
            <div className={cn("flex", "flex-col", "flex-1")}>
              <div className={cn("flex", "gap-x-3", "items-center")}>
                <ShiftBadge rate={info.rate} isIncident={isIncident} />
                <p className={cn("font-medium", "text-sm", "text-black/90")}>
                  {info.title}
                </p>
                {shouldShowT3Feature && !isIncident ? (
                  <>
                    <div
                      className={cn(
                        "border",
                        "border-solid",
                        "border-black/24",
                        "self-stretch",
                        "w-[1px]",
                        "my-0.5"
                      )}
                    ></div>
                    <ShiftT3Badge active={info.isAppliableToAllAtT3} />
                  </>
                ) : null}
              </div>

              <p
                className={cn(
                  "font-normal",
                  "text-xs",
                  "leading-5",
                  "text-black/60",
                  "mt-1"
                )}
              >
                {info.subtitle}
              </p>
            </div>
            {row.original.remoteStation ? (
              <div>
                <StationBadge
                  station={row.original.remoteStation}
                  stationTeam={null}
                />
              </div>
            ) : null}
          </div>
        );
      },
    }),
    columnHelper.accessor("timeRange", {
      header: () => t("shiftList.table.header.timeRange"),
      size: 140,
      cell: ({ cell }) => {
        return (
          <p
            className={cn(
              "font-normal",
              "text-sm",
              "leading-5",
              "text-gray-500"
            )}
          >
            {cell.getValue()}
          </p>
        );
      },
    }),
    columnHelper.accessor("cancelReason", {
      header: () => t("shiftList.cancelled.table.header.cancelReason"),
      size: 120,
      cell: ({ cell }) => {
        return (
          <p
            className={cn(
              "font-normal",
              "text-sm",
              "leading-5",
              "text-gray-500"
            )}
          >
            {cell.getValue()}
          </p>
        );
      },
    }),
    columnHelper.accessor("partTimeType", {
      header: () => t("shiftList.cancelled.table.header.partTimeType"),
      size: 120,
      cell: ({ cell }) => {
        return (
          <p
            className={cn(
              "font-normal",
              "text-sm",
              "leading-5",
              "text-gray-500"
            )}
          >
            {cell.getValue()}
          </p>
        );
      },
    }),
    columnHelper.accessor("numberOfApplicants", {
      header: () => (
        <span className={"min-w-10"}>
          {t("shiftList.cancelled.table.header.numberOfApplicant")}
        </span>
      ),
      size: isSm ? 120 : 76,
      cell: ({ cell }) => {
        return (
          <div className={cn("flex", "flex-row")}>
            <p
              className={cn(
                "font-normal",
                "text-sm",
                "leading-5",
                "text-gray-500"
              )}
            >
              {cell.getValue()}
            </p>
          </div>
        );
      },
    }),
    columnHelper.accessor("hiredCount", {
      header: () => (
        <span className={"min-w-10"}>
          {t("shiftList.cancelled.table.header.hiredCount")}
        </span>
      ),
      size: isSm ? 120 : 76,
      cell: ({ cell }) => {
        return (
          <div className={cn("flex", "flex-row")}>
            <p
              className={cn(
                "font-normal",
                "text-sm",
                "leading-5",
                "text-gray-500"
              )}
            >
              {cell.getValue()}
            </p>
          </div>
        );
      },
    }),
    columnHelper.accessor("title", {
      enableHiding: true,
    }),
    columnHelper.accessor("date", {
      enableHiding: true,
    }),
    columnHelper.accessor("id", {
      enableHiding: true,
    }),
  ];
};

export const useCancelledShiftListTable = (
  data: CancelledShiftListRecord[],
  onRowSelectionChange: OnChangeFn<RowSelectionState>,
  state?: Partial<TableState>
): Table<CancelledShiftListRecord> => {
  const shiftListTableColumns = useCancelledShiftListTableColumns();
  return useReactTable({
    data: data,
    columns: shiftListTableColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    defaultColumn: {
      minSize: 0,
      size: Number.MAX_SAFE_INTEGER,
      maxSize: Number.MAX_SAFE_INTEGER,
    },
    groupedColumnMode: false,
    manualSorting: true,
    enableRowSelection: true,
    onRowSelectionChange,
    state: state,
  });
};
