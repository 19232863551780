import React, { ReactElement } from "react";
import { Menu } from "@headlessui/react";
import cn from "classnames";
import { Link } from "react-router-dom";

interface DividerPopMenuItemProps {
  type: "divider";
  className?: string;
}
interface ButtonPopMenuItemProps {
  type: "button";
  text: string;
  className?: string;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
}
interface LinkPopMenuItemProps {
  type: "link";
  text: string;
  link: string;
  className?: string;
  onClick?: (e?: React.MouseEvent<HTMLAnchorElement>) => void;
}
export type PopMenuItemProps =
  | DividerPopMenuItemProps
  | ButtonPopMenuItemProps
  | LinkPopMenuItemProps;

const PopMenuItem = (props: PopMenuItemProps): ReactElement | null => {
  const { type, className } = props;

  switch (type) {
    case "divider":
      return (
        <div
          className={cn(
            "my-1",
            "border",
            "border-gray-100",
            "border-t-0",
            className
          )}
        />
      );
    case "button":
      return (
        <Menu.Item>
          {({ active }) => (
            <button
              className={cn(
                "block",
                "px-4",
                "py-2",
                "text-sm",
                "text-gray-700",
                "w-full",
                "text-left",
                {
                  "bg-gray-100": active,
                },
                className
              )}
              onClick={props.onClick}
              type="button"
            >
              {props.text}
            </button>
          )}
        </Menu.Item>
      );
    case "link":
      return (
        <Menu.Item>
          {({ active }) => (
            <Link
              className={cn(
                "block",
                "px-4",
                "py-2",
                "text-sm",
                "text-gray-700",
                {
                  "bg-gray-100": active,
                },
                className
              )}
              to={props.link}
              onClick={props.onClick}
            >
              {props.text}
            </Link>
          )}
        </Menu.Item>
      );
    default:
  }
  return null;
};

export default PopMenuItem;
