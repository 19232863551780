import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import zh from "javascript-time-ago/locale/zh-Hant.json";

import { config } from "./config";
import GenericErrorScreen from "oneclick-component/src/components/GenericErrorScreen";
import App from "./App";
import "./index.css";

if (config.sentry?.dsn) {
  Sentry.init({
    dsn: config.sentry.dsn,
  });
}

import "./i18n/i18n.ts";

TimeAgo.addDefaultLocale(zh);
TimeAgo.addLocale(en);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<GenericErrorScreen />}>
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);
