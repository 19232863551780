import React, { useCallback } from "react";
import cn from "classnames";
import {
  CellContext,
  ColumnDef,
  Table,
  TableState,
  createColumnHelper,
  getCoreRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { XMarkIcon } from "@heroicons/react/20/solid";
import useShowMessage from "oneclick-component/src/hooks/useShowMessage";
import { Button } from "oneclick-component/src/components/Button";
import { useRemoveShiftFullTimeAttendanceHandlerShiftFullTimeAttendancesAttendanceIdDeleteMutation as useRemoveFullTime } from "oneclick-component/src/store/apis/enhancedApi";
import { useShowError } from "../../hooks/useShowError";

export interface ShiftFullTimeAttendanceRecord {
  id: number;
  nameEn: string;
  nameZhHk: string;
  stationCode: string;
  role: string;
}

const columnHelper = createColumnHelper<ShiftFullTimeAttendanceRecord>();

// eslint-disable-next-line react-refresh/only-export-components
const RemoveAction = ({
  row,
}: CellContext<
  ShiftFullTimeAttendanceRecord,
  unknown
>): React.ReactElement | null => {
  const showMessage = useShowMessage();
  const { showError } = useShowError();
  const [removeFullTime, { isLoading }] = useRemoveFullTime();
  const removeAttendance = useCallback(() => {
    removeFullTime({
      attendanceId: row.original.id,
    })
      .unwrap()
      .then(() => {
        showMessage({
          title: t("fulltimeAttendance.remove.success"),
          type: "success",
          showDismiss: true,
        });
      })
      .catch((e) => {
        showError(e, t("fulltimeAttendance.remove.fail"));
      });
  }, [row, removeFullTime, showError, showMessage]);
  return (
    <div>
      <Button
        theme="white"
        className={cn("h-6", "text-xs", "text-black/60")}
        iconClassName="!fill-black/60"
        prefixIcon={XMarkIcon}
        onClick={removeAttendance}
        disabled={isLoading}
      >
        <Trans i18nKey="fulltimeAttendance.list.column.action.remove" />
      </Button>
    </div>
  );
};

export const useShiftFullTimeAttendanceColumns = (): ColumnDef<
  ShiftFullTimeAttendanceRecord,
  any
>[] => {
  return [
    columnHelper.accessor("nameEn", {
      header: () => t("fulltimeAttendance.column.nameEn"),
      cell: ({ cell }) => {
        return (
          <p
            className={cn(
              "font-normal",
              "text-sm",
              "leading-5",
              "text-black/86"
            )}
          >
            {cell.getValue()}
          </p>
        );
      },
    }),
    columnHelper.accessor("nameZhHk", {
      header: () => t("fulltimeAttendance.column.nameZhHk"),
      cell: ({ cell }) => {
        return (
          <p
            className={cn(
              "font-normal",
              "text-sm",
              "leading-5",
              "text-black/86"
            )}
          >
            {cell.getValue()}
          </p>
        );
      },
    }),
    columnHelper.accessor("role", {
      header: () => t("fulltimeAttendance.column.role"),
      cell: ({ cell }) => {
        return (
          <p
            className={cn(
              "font-normal",
              "text-sm",
              "leading-5",
              "text-black/86"
            )}
          >
            {cell.getValue()}
          </p>
        );
      },
    }),
    columnHelper.accessor("stationCode", {
      header: () => t("fulltimeAttendance.column.station"),
      cell: ({ cell }) => {
        return (
          <p
            className={cn(
              "font-normal",
              "text-sm",
              "leading-5",
              "text-black/86"
            )}
          >
            {cell.getValue()}
          </p>
        );
      },
    }),
    columnHelper.display({
      id: "action",
      cell: RemoveAction,
    }),
  ];
};

export const useShiftFullTimeAttendanceTable = (
  data: ShiftFullTimeAttendanceRecord[],
  state?: Partial<TableState>
): Table<ShiftFullTimeAttendanceRecord> => {
  const attendanceTableColumns = useShiftFullTimeAttendanceColumns();
  return useReactTable({
    data: data,
    columns: attendanceTableColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    defaultColumn: {
      minSize: 0,
      size: Number.MAX_SAFE_INTEGER,
      maxSize: Number.MAX_SAFE_INTEGER,
    },
    groupedColumnMode: false,
    manualSorting: false,
    enableRowSelection: false,
    state: state,
  });
};
