import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { SingleSelectComboBox } from "oneclick-component/src/components/inputs/SingleSelectComboBox";
import { WorkingStation } from "oneclick-component/src/store/apis/enhancedApi";
import { sort } from "oneclick-component/src/utils/list";

import { WorkingStationOption } from "./model";

function extractStationFromOption(option: WorkingStationOption | null) {
  const optionObj = option?.object ?? null;
  const optionId = optionObj?.id ?? null;
  return {
    workingStation: optionObj,
    workingStationId: optionId,
  };
}

function filterWorkingStationOptions(
  options: WorkingStationOption[],
  query: string
) {
  const formattedQuery = query.trim().toLowerCase();
  if (formattedQuery.length === 0) {
    return options;
  }
  return options.filter((op) => {
    const workingStation = op.object;
    return (
      workingStation.shortCode.toLowerCase().includes(formattedQuery) ||
      workingStation.name.en!.toLowerCase().includes(formattedQuery) ||
      workingStation.name.zhHk!.toLowerCase().includes(formattedQuery)
    );
  });
}

interface Props {
  className?: string;
  workingStations: WorkingStation[];
  initialSelectedWorkingStation?: WorkingStation | null;
  selectedWorkingStationId: number | null;
  onChange?: (workingStationId: number | null) => void;
  onWorkingStationSelected?: (workingStation: WorkingStation | null) => void;
  disabled?: boolean;
  nullIfEmpty?: boolean;
}

export const EagerLoadWorkingStationSelectionDropdown = React.memo(
  (props: Props): ReactElement => {
    const {
      className,
      selectedWorkingStationId,
      workingStations,
      disabled,
      onChange,
      onWorkingStationSelected,
      nullIfEmpty,
    } = props;
    const { t } = useTranslation();

    const [query, setQuery] = useState("");

    const allWorkingStationOptions = useMemo(() => {
      return workingStations.map((s) => ({
        value: s.id.toString(),
        name: s.shortCode,
        object: s,
      }));
    }, [workingStations]);

    const workingStationOptions = useMemo(() => {
      const filtered = filterWorkingStationOptions(
        allWorkingStationOptions,
        query
      );
      return sort(filtered, [
        {
          key: "name",
          direction: "asc",
        },
      ]);
    }, [allWorkingStationOptions, query]);

    const onLoadOptions = useCallback((query: string) => {
      setQuery(query);
    }, []);

    const onSelectedOptionsChange = useCallback(
      (option: WorkingStationOption | null) => {
        const { workingStation, workingStationId } =
          extractStationFromOption(option);
        onChange?.(workingStationId);
        onWorkingStationSelected?.(workingStation);
      },
      [onChange, onWorkingStationSelected]
    );

    return (
      <SingleSelectComboBox<WorkingStation>
        className={className}
        selectedItem={selectedWorkingStationId?.toString() ?? null}
        placeholder={t("stationSelectionDropdown.placeholder")}
        options={workingStationOptions}
        onLoadOptions={onLoadOptions}
        hasMoreOptions={false}
        isLoading={false}
        onOptionChange={onSelectedOptionsChange}
        disabled={disabled}
        nullIfEmpty={nullIfEmpty}
      />
    );
  }
);
