import React, { ReactElement, useMemo } from "react";
import cn from "classnames";

import { Message } from "oneclick-component/src/store/apis/enhancedApi";
import AudioPlayer from "./AudioPlayer";

interface Props {
  message: Message;
}

export const MessageBubbleAudioContent = React.memo(
  (props: Props): ReactElement => {
    const { message } = props;

    const audioAttachment = useMemo(() => {
      return message.attachments[0];
    }, [message.attachments]);
    const audioAsset = audioAttachment.asset;

    return (
      <div>
        <AudioPlayer
          mime={audioAsset.contentType}
          playerId={audioAttachment.id}
          audioSource={audioAsset.url}
        />
        {message.content ? (
          <span
            className={cn(
              "block",
              "border-t",
              "border-black/[0.12]",
              "whitespace-pre-wrap",
              "overflow-wrap-anywhere",
              "text-sm",
              "text-left",
              "p-3"
            )}
          >
            {message.content}
          </span>
        ) : null}
      </div>
    );
  }
);
