import {
  PtUser,
  WeekOfYear,
  Weekly418Status,
  WorkingStatus,
} from "oneclick-component/src/store/apis/enhancedApi";
import { ReactElement, useCallback, useMemo } from "react";
import cn from "classnames";
import { PTUserAvatarImage } from "../../../../components/PTUserAvatarImage";
import { StationBadge } from "oneclick-component/src/components/Badge";
import { IconButton } from "oneclick-component/src/components/Button";
import { ChevronLeftIcon, ChevronRightIcon } from "oneclick-component/src/icon";
import { NUM_WEEK_PER_USER_CARD } from "../../../../constants/partTime418";
import {
  getNextWeek,
  getPreviousWeek,
} from "oneclick-component/src/utils/weekOfYear";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import PartTimeUser418CardWorkingStatusRowView from "./RowView";

interface Props {
  ptUser: PtUser;
  startingWeekOfYear: WeekOfYear;
  onChangeStartingWeekOfYear: (ptUserId: number, woy: WeekOfYear) => void;
  onChangeWorkingStatus: (
    ptUserId: number,
    woy: WeekOfYear,
    workingStatus: WorkingStatus | null,
    existingWorkingStatus: WorkingStatus | null,
    existing418Status: Weekly418Status
  ) => void;
  showStationTeam: boolean;
}

const PartTimeUser418Card = (props: Props): ReactElement => {
  const {
    ptUser,
    startingWeekOfYear: { year: weekYear, weekNumber },
    onChangeStartingWeekOfYear,
    onChangeWorkingStatus,
    showStationTeam,
  } = props;

  const onClickPreviousWeeks = useCallback(() => {
    onChangeStartingWeekOfYear(
      ptUser.id,
      getPreviousWeek({ year: weekYear, weekNumber }, NUM_WEEK_PER_USER_CARD)
    );
  }, [weekYear, weekNumber, onChangeStartingWeekOfYear, ptUser.id]);

  const onClickNextWeeks = useCallback(() => {
    onChangeStartingWeekOfYear(
      ptUser.id,
      getNextWeek({ year: weekYear, weekNumber }, NUM_WEEK_PER_USER_CARD)
    );
  }, [weekYear, weekNumber, onChangeStartingWeekOfYear, ptUser.id]);

  const weekOfYearsArray = useMemo<WeekOfYear[]>(() => {
    return Array(NUM_WEEK_PER_USER_CARD)
      .fill(0)
      .map((_, i) =>
        getNextWeek(
          {
            year: weekYear,
            weekNumber,
          },
          i
        )
      );
  }, [weekYear, weekNumber]);

  const workingStatusCardsQueryStatus = useSelector((state: RootState) => {
    const { ptUserWorkingStatusesMapState } = state;
    const cardsQueryStatuses = weekOfYearsArray.map(
      ({ year: _year, weekNumber: _weekNumber }) => {
        return ptUserWorkingStatusesMapState.ptUserWorkingStatuses[ptUser.id]?.[
          _year
        ]?.[_weekNumber]?.queryResultState;
      }
    );
    if (cardsQueryStatuses.some((v) => v === "error")) {
      return "error";
    }
    if (cardsQueryStatuses.some((v) => v === "loading")) {
      return "loading";
    }
    if (cardsQueryStatuses.every((v) => v === "success")) {
      return "success";
    }
    return "idle";
  });

  return (
    <div
      className={cn(
        "p-4",
        "rounded-md",
        "border",
        "border-gray-200",
        "w-full",
        "space-y-3"
      )}
    >
      <div className="flex">
        <PTUserAvatarImage
          ptUser={ptUser}
          className="mr-8"
          show418Badge={false}
        />
        <div className="flex-1">
          <div className={cn("flex", "items-center", "space-x-2")}>
            <p>{ptUser.fullNameZhHk}</p>
            {ptUser.station != null ? (
              <StationBadge
                station={ptUser.station}
                stationTeam={ptUser.stationTeam ?? null}
                showStationTeam={showStationTeam}
              />
            ) : null}
          </div>
          <div
            className={cn(
              "flex",
              "items-center",
              "space-x-2",
              "text-sm",
              "font-medium"
            )}
          >
            <span className="text-black/60">ID</span>
            <span className="text-black/86">{ptUser.eid}</span>
          </div>
        </div>
        <div className={cn("flex", "items-center", "space-x-3")}>
          <IconButton
            icon={ChevronLeftIcon}
            theme="white"
            onClick={onClickPreviousWeeks}
            disabled={workingStatusCardsQueryStatus !== "success"}
          />
          <IconButton
            icon={ChevronRightIcon}
            theme="white"
            onClick={onClickNextWeeks}
            disabled={workingStatusCardsQueryStatus !== "success"}
          />
        </div>
      </div>
      <PartTimeUser418CardWorkingStatusRowView
        ptUser={ptUser}
        queryStatus={workingStatusCardsQueryStatus}
        weekOfYears={weekOfYearsArray}
        onChangeWorkingStatus={onChangeWorkingStatus}
        className={cn("w-full", "h-36", "py-2.5")}
      />
    </div>
  );
};

export default PartTimeUser418Card;
