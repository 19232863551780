import {
  FilePickerAssetTooLargeError,
  FilePickerBaseError,
  FilePickerInvalidFileTypeError,
} from "oneclick-component/src/components/inputs/FilePicker";
import { useCallback } from "react";
import { useShowError } from "./useShowError";
import { OneClickCustomError } from "oneclick-component/src/models/error";
import { useTranslation } from "react-i18next";
import {
  WHATSAPP_ATTACHMENT_DOCUMENT_SIZE_LIMIT_MB,
  WHATSAPP_ATTACHMENT_IMAGE_SIZE_LIMIT_MB,
} from "../constants/attachments";

interface ReturnType {
  onValidationError: (error: FilePickerBaseError) => void;
  getAttachmentSizeLimitMb: (file: File) => number;
}
const useValidateAttachmentType = (): ReturnType => {
  const { showError } = useShowError();
  const { t } = useTranslation();
  const onValidationError = useCallback(
    (error: FilePickerBaseError) => {
      if (error instanceof FilePickerAssetTooLargeError) {
        showError(
          new OneClickCustomError(
            t("validateAttachment.error.assetTooLarge.message", {
              sizeLimit: error.sizeLimitMb,
            })
          ),
          t("validateAttachment.error.assetTooLarge.title")
        );
      } else if (error instanceof FilePickerInvalidFileTypeError) {
        showError(
          new OneClickCustomError(
            t("validateAttachment.error.invalidFileType.message")
          ),
          "validateAttachment.error.invalidFileType.title"
        );
      }
    },
    [t, showError]
  );
  const getAttachmentSizeLimitMb = useCallback((file: File) => {
    if (file.type.startsWith("image/")) {
      return WHATSAPP_ATTACHMENT_IMAGE_SIZE_LIMIT_MB;
    }
    // other document
    return WHATSAPP_ATTACHMENT_DOCUMENT_SIZE_LIMIT_MB;
  }, []);
  return { onValidationError, getAttachmentSizeLimitMb };
};

export default useValidateAttachmentType;
