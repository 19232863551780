import { ReactElement, useMemo, useCallback } from "react";
import cn from "classnames";
import { Button, ButtonLink } from "oneclick-component/src/components/Button";
import { PlusIcon } from "@heroicons/react/20/solid";
import { Trans, useTranslation } from "react-i18next";
import { Navigate, matchPath, useLocation } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import TabMenu from "oneclick-component/src/components/TabMenu";
import { DownloadIcon } from "oneclick-component/src/icon";
import { useGetTotalCountByStationsHandlerPartTimeUsersGetTotalPtCountByStationsGetQuery as useGetTotalPtCountByStations } from "oneclick-component/src/store/apis/enhancedApi";
import AppRoutes from "../../routes/AppRoutes";
import useGenerateAllPtCsvWebSocket from "../../hooks/useGenerateAllPtCsvWebSocket";
import { CalendarIcon, ClockIcon } from "../../icon";
import { ChatBubble } from "../../components/ChatBubble";
import { RootState } from "../../store/store";
import { useIsSuperAdmin } from "../../hooks/role";
import { getStationFilterFromMeUser } from "./type";

const PartTimeListScreen = (): ReactElement => {
  const { t } = useTranslation();
  const location = useLocation();
  const triggerAllPtCsvGeneration = useGenerateAllPtCsvWebSocket();
  const isSuperAdmin = useIsSuperAdmin();

  const { meUser } = useSelector((state: RootState) => {
    return {
      meUser: state.auth.meUser,
    };
  }, shallowEqual);

  const stationIds = meUser ? getStationFilterFromMeUser(meUser) : [];

  const { data: ptCountResponse } = useGetTotalPtCountByStations(
    { stationIds },
    { skip: meUser == null }
  );
  const { data: activePtCountResponse } = useGetTotalPtCountByStations(
    { stationIds, isFilterNotConsent: true },
    { skip: meUser == null }
  );

  const onClickExportAllPt = useCallback(() => {
    triggerAllPtCsvGeneration();
  }, [triggerAllPtCsvGeneration]);

  const ptCountDisplay = useMemo(() => {
    const total = ptCountResponse?.totalCount;
    const activeCount = activePtCountResponse?.totalCount;

    if (total == null || activeCount == null) {
      return null;
    }

    return (
      <span className={cn("text-sm", "font-medium", "text-black/60")}>
        <Trans
          i18nKey="partTime.list.title.activePtCount"
          values={{ count: activeCount, pendingCount: total - activeCount }}
        />
      </span>
    );
  }, [activePtCountResponse, ptCountResponse]);

  if (matchPath(AppRoutes.PartTimeListScreen.path, location.pathname)) {
    return (
      <Navigate
        to={AppRoutes.PartTimeListAvailabilityTab.render()}
        replace={true}
      />
    );
  }

  return (
    <main
      className={cn(
        "rounded-lg",
        "bg-white",
        "sm:shadow",
        "sm:mx-auto",
        "px-4",
        "pb-5",
        "sm:p-6"
      )}
    >
      <div className={cn("flex", "flex-row", "items-center")}>
        <div className={cn("flex-1", "space-x-1")}>
          <span className={cn("text-lg", "font-medium")}>
            <Trans i18nKey="partTime.list.title" />
          </span>
          {ptCountDisplay}
        </div>
        {isSuperAdmin ? (
          <Button
            theme="white"
            prefixIcon={DownloadIcon}
            className={cn("h-10", "text-sm", "font-normal", "mr-3")}
            iconClassName="!w-3"
            onClick={onClickExportAllPt}
          >
            <Trans i18nKey="shiftList.tab.exportMenu.exportAllPt" />
          </Button>
        ) : null}
        <ButtonLink
          theme="white"
          prefixIcon={PlusIcon}
          to={AppRoutes.CreatePartTimeScreen.render()}
          className={cn("h-10", "text-sm")}
        >
          <Trans i18nKey="partTime.list.button.addPartTimeUser" />
        </ButtonLink>
      </div>
      <TabMenu
        panels={[
          {
            icon: CalendarIcon,
            title: t("partTime.list.tab.availability.title"),
            to: AppRoutes.PartTimeListAvailabilityTab.render(),
          },
          {
            icon: ClockIcon,
            title: t("partTime.list.tab.workStatus418.title"),
            to: AppRoutes.PartTimeListWorkStatus418Tab.render(),
          },
        ]}
      />
      <ChatBubble
        className={cn(
          "fixed",
          "z-20",
          "right-4",
          "bottom-18",
          "space-y-3",
          "sm:hidden"
        )}
      />
    </main>
  );
};

export default PartTimeListScreen;
