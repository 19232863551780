export const DAYS_OF_WEEK = [0, 1, 2, 3, 4, 5, 6];
export const DAYS_OF_WEEK_NAMES = [
  "mon",
  "tue",
  "wed",
  "thu",
  "fri",
  "sat",
  "sun",
];
