import React from "react";
import cn from "classnames";
import { NotFoundIcon } from "../../icon";
import { Trans } from "react-i18next";

const NotFoundScreen = (): React.ReactElement => {
  return (
    <div
      className={cn(
        "w-full",
        "h-full",
        "flex",
        "flex-col",
        "items-center",
        "justify-center"
      )}
    >
      <NotFoundIcon className={cn("fill-gray-300", "w-40", "h-17.5")} />
      <h1 className={cn("mt-8", "text-lg", "font-normal", "text-black")}>
        <Trans i18nKey="common.notFound" />
      </h1>
      <p className={cn("mt-3", "text-sm", "font-medium", "text-black/60")}>
        <Trans i18nKey="common.notFound.description" />
      </p>
    </div>
  );
};

export default NotFoundScreen;
