import * as z from "zod";

export const fullTimeAttendanceSchema = z.object({
  id: z.number(),
  shiftId: z.number(),
  station: z.object({
    id: z.number(),
    name: z.object({ en: z.string(), zhHk: z.string() }),
    shortCode: z.string(),
    colorHexcode: z.string(),
  }),
  name: z.object({ en: z.string(), zhHk: z.string() }),
  role: z.string(),
});
