import React, { useMemo } from "react";
import { CellContext } from "@tanstack/react-table";
import { IndeterminateCheckboxInput } from "../inputs/IndeterminateCheckboxInput";

export function CellCheckbox<TData, TValue>(
  props: CellContext<TData, TValue>
): React.ReactElement {
  const { table, row } = props;

  const rowSelection = table.getState().rowSelection;
  const { checked, indeterminate, disabled } = useMemo(
    () => ({
      checked: row.getIsSelected(),
      indeterminate: row.getIsSomeSelected(),
      disabled: !row.getCanSelect(),
    }),
    // Need to update on rowSelection state change
    [rowSelection, row] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <IndeterminateCheckboxInput
      checked={checked}
      indeterminate={indeterminate}
      disabled={disabled}
      onChange={row.getToggleSelectedHandler()}
    />
  );
}
