import React, {
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { Trans, useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import cn from "classnames";

import { ToggleSwitch } from "oneclick-component/src/components/ToggleSwitch";
import { TemplateMessage } from "oneclick-component/src/store/apis/enhancedApi";
import { IconButton } from "oneclick-component/src/components/Button";
import { sort } from "oneclick-component/src/utils/list";
import { RootState } from "../../store/store";
import { MessageInputTextarea } from "./MessageInputTextarea";
import { MessageAttachmentPicker } from "./MessageAttachmentPicker";
import { useUploadAttachment } from "../../hooks/useUploadAttachment";
import AttachmentPreview from "../AttachmentPreview.tsx/AttachmentPreview";

const HORIZONTAL_DIFF_THRESHOLD = 80;
const HORIZONTAL_SCROLL_BUTTON_RATIO = 0.75;

interface TemplateMessageOptionProps {
  templateMessage: TemplateMessage;
  onTemplateMessageClick: (templateMessage: TemplateMessage) => void;
}

export const TemplateMessageOption = React.memo(
  (props: TemplateMessageOptionProps) => {
    const { templateMessage, onTemplateMessageClick } = props;

    const onButtonClick = useCallback(() => {
      onTemplateMessageClick(templateMessage);
    }, [onTemplateMessageClick, templateMessage]);

    return (
      <button
        className={cn(
          "rounded-full",
          "border",
          "border-primary-600",
          "bg-white",
          "py-1.5",
          "px-3",
          "max-w-60"
        )}
        type="button"
        onClick={onButtonClick}
      >
        <span
          className={cn(
            "block",
            "truncate",
            "overflow-hidden",
            "text-xs",
            "text-primary-600",
            "leading-5",
            "font-medium"
          )}
        >
          {templateMessage.body}
        </span>
      </button>
    );
  }
);

interface TemplateMessageSectionProps {
  onTemplateMessageClick: (templateMessage: TemplateMessage) => void;
}

type ScrollState = "start" | "middle" | "end" | "noScroll";

function getScrollState(containerElem: HTMLDivElement): ScrollState {
  if (containerElem.scrollWidth <= containerElem.offsetWidth) {
    return "noScroll";
  } else if (containerElem.scrollLeft < HORIZONTAL_DIFF_THRESHOLD) {
    return "start";
  } else if (
    containerElem.scrollWidth -
      containerElem.scrollLeft -
      containerElem.offsetWidth <
    HORIZONTAL_DIFF_THRESHOLD
  ) {
    return "end";
  }
  return "middle";
}

export const TemplateMessageSection = (
  props: TemplateMessageSectionProps
): ReactElement | null => {
  const { onTemplateMessageClick } = props;
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [scrollState, setScrollState] = useState<ScrollState>("noScroll");

  const onContainerRef = useCallback((containerElem: HTMLDivElement | null) => {
    if (containerElem == null) {
      return;
    }
    containerRef.current = containerElem;
    setScrollState(getScrollState(containerElem));
  }, []);

  // TODO: PT User language preference setting TBD, will show all available template
  const templateMessages = useSelector((state: RootState) => {
    const userTemplates = state.templateMessage.templateMessages.filter(
      (m) => m.type === "user" && m.isActive
    );
    return sort(userTemplates, [
      { key: "seq", direction: "asc" },
      { key: "id", direction: "asc" },
    ]);
  }, shallowEqual);

  const onHScrollClick = useCallback((direction: "left" | "right") => {
    const elem = containerRef.current;
    if (elem == null) {
      return;
    }
    const scrollOffset = elem.offsetWidth * HORIZONTAL_SCROLL_BUTTON_RATIO;
    elem.scrollBy({
      behavior: "smooth",
      left: direction === "left" ? -scrollOffset : scrollOffset,
    });
  }, []);

  const onLeftClick = useCallback(() => {
    onHScrollClick("left");
  }, [onHScrollClick]);

  const onRightClick = useCallback(() => {
    onHScrollClick("right");
  }, [onHScrollClick]);

  const onHorizontalScroll = useCallback(
    (ev: React.SyntheticEvent<HTMLDivElement>) => {
      ev.stopPropagation();
      setScrollState(getScrollState(ev.currentTarget));
    },
    []
  );

  if (templateMessages.length === 0) {
    return null;
  }

  return (
    <div className={cn("relative", "w-full")}>
      {scrollState !== "start" && scrollState !== "noScroll" ? (
        <div
          className={cn(
            "absolute",
            "flex",
            "items-center",
            "left-0",
            "top-0",
            "bottom-0",
            "pl-3",
            "from-white",
            "via-white/80",
            "to-transparent",
            "bg-gradient-to-r",
            "min-w-20",
            "pointer-events-none"
          )}
        >
          <IconButton
            className={cn(
              "flex",
              "items-center",
              "justify-center",
              "w-7.5",
              "h-7.5",
              "!p-1.5",
              "pointer-events-auto",
              "!shadow-none"
            )}
            theme="whiteNoFillBlackTextThick"
            icon={ChevronLeftIcon}
            onClick={onLeftClick}
          />
        </div>
      ) : null}
      <div
        ref={onContainerRef}
        className={cn("flex", "gap-3", "p-3", "overflow-x-auto")}
        onScroll={onHorizontalScroll}
      >
        {templateMessages.map((m) => (
          <TemplateMessageOption
            key={m.id}
            templateMessage={m}
            onTemplateMessageClick={onTemplateMessageClick}
          />
        ))}
      </div>
      {scrollState !== "end" && scrollState !== "noScroll" ? (
        <div
          className={cn(
            "absolute",
            "flex",
            "items-center",
            "justify-end",
            "right-0",
            "top-0",
            "bottom-0",
            "pr-3",
            "from-white",
            "via-white/80",
            "to-transparent",
            "bg-gradient-to-l",
            "min-w-20",
            "pointer-events-none"
          )}
        >
          <IconButton
            className={cn(
              "flex",
              "items-center",
              "justify-center",
              "w-7.5",
              "h-7.5",
              "!p-1.5",
              "pointer-events-auto",
              "!shadow-none"
            )}
            theme="whiteNoFillBlackTextThick"
            icon={ChevronRightIcon}
            onClick={onRightClick}
          />
        </div>
      ) : null}
    </div>
  );
};

interface Props {
  isPrivateMode: boolean;
  isOutsideReplyWindow: boolean;
  onTogglePrivateMode: (isPrivate: boolean) => void;
  sendMessage: (
    content: string,
    attachmentIds: number[],
    isPrivate: boolean
  ) => Promise<void>;
  sendTemplateMessage: (templateId: number) => Promise<void>;
}

export const MessageInput = React.memo((props: Props): ReactElement => {
  const {
    isPrivateMode,
    isOutsideReplyWindow,
    onTogglePrivateMode,
    sendMessage,
    sendTemplateMessage,
  } = props;
  const { t } = useTranslation();

  const [isSending, setIsSending] = useState(false);
  const [draftMessage, setDraftMessage] = useState("");
  const [selectedTemplate, setSelectedTemplate] =
    useState<TemplateMessage | null>(null);

  const {
    attachmentState,
    selectedAttachmentId,
    onAttachmentSelected,
    onAttachmentRemoved,
    onAttachmentSent,
  } = useUploadAttachment();

  const draftMessageRef = useRef(draftMessage);
  draftMessageRef.current = draftMessage;

  const onTemplateMessageSelected = useCallback(
    (templateMessage: TemplateMessage) => {
      setSelectedTemplate(templateMessage);
      setDraftMessage(templateMessage.body);
    },
    []
  );

  const placeholder = useMemo(() => {
    return t("messageInput.textarea.placeholder");
  }, [t]);

  const isTemplateOnly = !isPrivateMode && isOutsideReplyWindow;

  const attachmentIds = useMemo(
    () => (selectedAttachmentId != null ? [selectedAttachmentId] : []),
    [selectedAttachmentId]
  );
  const onSendMessage = useCallback(async () => {
    setIsSending(true);
    if (isTemplateOnly) {
      if (selectedTemplate != null) {
        await sendTemplateMessage(selectedTemplate.id);
      } else {
        console.error(
          "onSendMessage triggered with no selected template message"
        );
      }
    } else {
      await sendMessage(draftMessageRef.current, attachmentIds, isPrivateMode);
    }
    onAttachmentSent();
    setDraftMessage("");
    setIsSending(false);
  }, [
    sendMessage,
    sendTemplateMessage,
    isPrivateMode,
    attachmentIds,
    selectedTemplate,
    isTemplateOnly,
    onAttachmentSent,
  ]);

  return (
    <section>
      {/* eslint-disable-next-line react/jsx-no-leaked-render */}
      {!isPrivateMode && (
        <TemplateMessageSection
          onTemplateMessageClick={onTemplateMessageSelected}
        />
      )}
      <div
        className={cn(
          "shadow-[0_-4px_8px_0px_#00000014]",
          "pt-2",
          "pb-4",
          "px-3",
          {
            "bg-white": !isPrivateMode,
            "bg-neutral-700": isPrivateMode,
          }
        )}
      >
        <ToggleSwitch
          className="pb-3"
          // Toggle ring offset must match background, default is white
          inputClassName={cn({
            "focus:ring-offset-neutral-700": isPrivateMode,
          })}
          isChecked={isPrivateMode}
          label={
            <span
              className={cn({
                "text-white": isPrivateMode,
                "text-black/60": !isPrivateMode,
              })}
            >
              <Trans i18nKey="messageInput.privateModeToggle.label" />
            </span>
          }
          onToggle={onTogglePrivateMode}
        />
        {/* eslint-disable-next-line react/jsx-no-leaked-render */}
        {attachmentState.state !== "initial" && (
          <AttachmentPreview
            className="mb-3"
            isUploading={attachmentState.state === "uploading"}
            attachment={attachmentState.file}
            error={attachmentState.error}
            onAttachmentRemoved={onAttachmentRemoved}
          />
        )}
        <div className={cn("flex", "items-center", "gap-2")}>
          <MessageAttachmentPicker
            onAttachmentSelected={onAttachmentSelected}
            disabled={isTemplateOnly}
          />
          <MessageInputTextarea
            className="flex-1"
            placeholder={placeholder}
            draftMessage={draftMessage}
            selectedTemplate={selectedTemplate}
            attachmentState={attachmentState.state}
            isSending={isSending}
            isTemplateOnly={isTemplateOnly}
            onDraftMessageChange={setDraftMessage}
            onSendMessage={onSendMessage}
          />
        </div>
      </div>
    </section>
  );
});
