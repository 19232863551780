import { ReactElement, useContext } from "react";
import cn from "classnames";
import { Trans } from "react-i18next";
import { ShiftRateViewModel } from "oneclick-component/src/components/ShiftRateBadge";
import { ShiftType } from "oneclick-component/src/store/apis/enhancedApi";
import ShiftBadge from "oneclick-component/src/components/ShiftRateBadge/ShiftBadge";
import ShiftT3Badge from "oneclick-component/src/components/Badge/ShiftT3Badge";
import { FeatureConfigContext } from "../../providers/FeatureConfigProvider";

interface Props {
  className?: string;
  title: string;
  rate?: ShiftRateViewModel;
  description: string;
  shiftNumber?: string;
  workingStation: string;
  supportStation?: string;
  fulfillment: string;
  partTimeType: string[];
  shiftCount: number;
  recurringType: string;
  dateDisplay: string;
  timeDisplay: string;
  shiftType: ShiftType | null;
  showIncidentBanner?: boolean;
  isAppliableToAllAtT3: boolean;
}

const MultiShiftPreviewDetail = (props: Props): ReactElement => {
  const { shouldShowT3Feature } = useContext(FeatureConfigContext);
  const {
    className,
    title,
    rate,
    description,
    shiftNumber,
    workingStation,
    supportStation,
    fulfillment,
    partTimeType,
    shiftCount,
    recurringType,
    dateDisplay,
    timeDisplay,
    shiftType,
    showIncidentBanner = false,
    isAppliableToAllAtT3,
  } = props;
  return (
    <div className={className}>
      {shiftType === "INCIDENT" && showIncidentBanner ? (
        <div className={cn("bg-red-500", "py-1", "pl-6", "rounded-t-lg")}>
          <p className={cn("text-white", "font-medium", "text-sm")}>
            <Trans i18nKey="shiftDetail.header.incidentBanner" />
          </p>
        </div>
      ) : null}
      <div
        className={cn("px-6", {
          "pb-10 pt-5": shiftType === "INCIDENT",
          "py-10": shiftType !== "INCIDENT",
        })}
      >
        <div className={cn("flex", "flex-row", "items-center", "gap-x-3")}>
          <ShiftBadge rate={rate} isIncident={shiftType === "INCIDENT"} />
          <span
            className={cn(
              "text-lg",
              "text-black/86",
              "font-medium",
              "line-clamp-1"
            )}
          >
            {title}
          </span>
          {shouldShowT3Feature && shiftType !== "INCIDENT" ? (
            <ShiftT3Badge active={isAppliableToAllAtT3} />
          ) : null}
        </div>
        {shiftNumber ? (
          <div
            className={cn("flex", "flex-row", "gap-2", "items-center", "mb-5")}
          >
            <p className={cn("text-xs", "text-black/60", "font-normal")}>
              <Trans i18nKey="shift.create.preview.shiftNumber" />
            </p>
            <p className={cn("text-sm", "text-gray-900", "font-medium")}>
              {shiftNumber}
            </p>
          </div>
        ) : null}
        <div
          className={cn(
            "w-full",
            "mt-5",
            "border",
            "rounded-lg",
            "border-black/10",
            "p-5",
            "text-left"
          )}
        >
          <div className={cn("grid", "grid-cols-4", "gap-x-3", "gap-y-3")}>
            <div className="space-y-1">
              <h6 className={cn("text-xs", "leading-5", "text-black/60")}>
                <Trans i18nKey="shift.create.form.station" />
              </h6>
              <p
                className={cn(
                  "text-sm",
                  "font-medium",
                  "text-gray-900",
                  "break-words"
                )}
              >
                {workingStation}
              </p>
            </div>
            <div className="space-y-1">
              <h6 className={cn("text-xs", "leading-5", "text-black/60")}>
                <Trans i18nKey="shift.create.preview.fulfillment" />
              </h6>
              <p
                className={cn(
                  "text-sm",
                  "font-medium",
                  "text-gray-900",
                  "break-words"
                )}
              >
                {fulfillment}
              </p>
            </div>
            <div className="space-y-1">
              <h6 className={cn("text-xs", "leading-5", "text-black/60")}>
                <Trans i18nKey="shift.create.form.partTimeType" />
              </h6>
              <p
                className={cn(
                  "text-sm",
                  "font-medium",
                  "text-gray-900",
                  "break-words"
                )}
              >
                {partTimeType.join(",")}
              </p>
            </div>
            <div className="space-y-1">
              <h6 className={cn("text-xs", "leading-5", "text-black/60")}>
                <Trans i18nKey="shift.create.preview.totalCount" />
              </h6>
              <p
                className={cn(
                  "text-sm",
                  "font-medium",
                  "text-gray-900",
                  "break-words"
                )}
              >
                {shiftCount}
              </p>
            </div>
            {supportStation != null ? (
              <div className="space-y-1">
                <h6 className={cn("text-xs", "leading-5", "text-black/60")}>
                  <Trans i18nKey="shift.create.form.supportStation" />
                </h6>
                <p
                  className={cn(
                    "text-sm",
                    "font-medium",
                    "text-gray-900",
                    "break-words"
                  )}
                >
                  {supportStation}
                </p>
              </div>
            ) : null}
          </div>
          <hr className="my-5" />
          <div>
            <h6 className={cn("text-xs", "leading-5", "text-black/60")}>
              {recurringType === "single" ? (
                <Trans i18nKey="shift.create.preview.shiftDate.single" />
              ) : null}
              {recurringType === "repeat" ? (
                <Trans i18nKey="shift.create.preview.shiftDate.repeat" />
              ) : null}
              {recurringType === "custom" ? (
                <Trans i18nKey="shift.create.preview.shiftDate.custom" />
              ) : null}
            </h6>
            <p
              className={cn(
                "text-sm",
                "font-medium",
                "text-gray-900",
                "mt-1",
                "mb-5",
                "break-words"
              )}
            >
              {dateDisplay}
            </p>
            <h6 className={cn("text-xs", "leading-5", "text-black/60")}>
              <Trans i18nKey="shift.create.preview.shiftTime" />
            </h6>
            <p
              className={cn(
                "text-sm",
                "font-medium",
                "text-gray-900",
                "mt-1",
                "break-words"
              )}
            >
              {timeDisplay}
            </p>
          </div>
          <hr className="my-5" />
          <h6 className={cn("text-xs", "leading-5", "text-black/60")}>
            <Trans i18nKey="shift.create.form.title" />
          </h6>
          <p className={cn("text-sm", "font-medium", "text-gray-900", "mt-1")}>
            {title}
          </p>
          <h6 className={cn("text-xs", "leading-5", "text-black/60", "mt-5")}>
            <Trans i18nKey="shift.create.form.description" />
          </h6>
          <p
            className={cn(
              "text-sm",
              "font-medium",
              "text-gray-900",
              "mt-1",
              "whitespace-pre-wrap",
              "break-words"
            )}
          >
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MultiShiftPreviewDetail;
