import { ReactElement, useCallback, useContext, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import cn from "classnames";
import { Button } from "oneclick-component/src/components/Button";
import { SendIcon, ChevronDownIcon } from "oneclick-component/src/icon";
import {
  PtUser,
  PtUserWithClashedShift,
  Shift,
  Weekly418Status,
  ListShiftRequestsWithNumberingHandlerShiftsShiftIdRequestsGetApiResponse as ListShiftRequestResponse,
} from "oneclick-component/src/store/apis/enhancedApi";
import { UseInfiniteLoadQueryResponse } from "oneclick-component/src/hooks/useInfiniteQuery";
import { ResponsedShiftRequestBaseWithNumbering } from "../../../models/shiftRequest";
import {
  Select,
  SelectComponentProps,
  Option,
} from "oneclick-component/src/components/inputs";
import { FeatureConfigContext } from "../../../providers/FeatureConfigProvider";
import PTUser418Badge from "../../../components/PTUserBadge/PTUser418Badge";
import { StationFilter, stationFilterValues } from "./types";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import ResponesdPTUserRequestRow from "../../../components/ResponesdPTUserRequestRow";
import useShiftAccessControl from "../../../hooks/useShiftAccessControl";

interface ResponsedSectionFilterProps {
  status418Filter: SelectComponentProps<Weekly418Status | null>;
  stationFilter: SelectComponentProps<StationFilter | null>;
  className?: string;
}

const ResponsedSectionFilter = (
  props: ResponsedSectionFilterProps
): ReactElement => {
  const { status418Filter, stationFilter, className } = props;
  const { t } = useTranslation();
  const { shouldShowGreen418Badge } = useContext(FeatureConfigContext);

  const status418FilterOptions = useMemo<
    Option<Weekly418Status | null>[]
  >(() => {
    const nonGreenStatuses: Weekly418Status[] = ["WARNING", "UNKNOWN"];
    const allStatuses: Weekly418Status[] = shouldShowGreen418Badge
      ? [...nonGreenStatuses, "SAFE"]
      : nonGreenStatuses;
    const nullOption = {
      name: t("shiftDetail.responsed.status418Filter.all"),
      value: null,
    };
    return [
      nullOption,
      ...allStatuses.map((s) => ({
        name: s,
        value: s,
        displayComponent: <PTUser418Badge status={s} />,
      })),
    ];
  }, [shouldShowGreen418Badge, t]);

  const stationFilterOptions = useMemo<Option<StationFilter | null>[]>(() => {
    const nullOption = {
      name: t("shiftDetail.responsed.stationFilter.all"),
      value: null,
    };
    return [
      nullOption,
      ...stationFilterValues.map((v) => ({
        name: t(`shiftDetail.responsed.stationFilter.${v}`),
        value: v,
      })),
    ];
  }, [t]);
  return (
    <div className={cn("flex", "items-center", "gap-x-3", className)}>
      <Select<StationFilter | null>
        {...stationFilter}
        options={stationFilterOptions}
        className="min-w-40"
      />
      <Select<Weekly418Status | null>
        {...status418Filter}
        options={status418FilterOptions}
        className="min-w-50"
      />
    </div>
  );
};

interface SectionType {
  shift: Shift;
  requests: ResponsedShiftRequestBaseWithNumbering[];
  ptUsersWithClashedShifts: PtUserWithClashedShift[];
  fulfillmentLeft: number;
  onHire: (user: PtUser[]) => void;
  notifyContacted?: boolean;
  isSending: boolean;
  status418Filter: SelectComponentProps<Weekly418Status | null>;
  stationFilter: SelectComponentProps<StationFilter | null>;
  respondedShiftRequestsQuery: UseInfiniteLoadQueryResponse<ListShiftRequestResponse>;
  hiredUserIds: number[];
}

// eslint-disable-next-line complexity
const ResponsedSection = (props: SectionType): ReactElement => {
  const {
    requests,
    ptUsersWithClashedShifts,
    shift,
    onHire,
    isSending,
    notifyContacted,
    fulfillmentLeft,
    status418Filter,
    stationFilter,
    respondedShiftRequestsQuery,
    hiredUserIds,
  } = props;
  const ptUsersWithClashedShiftsMap: Record<
    number,
    PtUserWithClashedShift | undefined
  > = useMemo(() => {
    const result: Record<number, PtUserWithClashedShift | undefined> = {};
    ptUsersWithClashedShifts.forEach((v) => {
      result[v.ptUser.id] = v;
    });
    return result;
  }, [ptUsersWithClashedShifts]);

  const hireableRequests = useMemo(
    () =>
      requests.filter(
        (request) =>
          ptUsersWithClashedShiftsMap[request.ptUserId] == null &&
          (request.borrowingRequestStatus == null ||
            request.borrowingRequestStatus === "approved")
      ),
    [ptUsersWithClashedShiftsMap, requests]
  );

  const handleFetchMore = useCallback(() => {
    if (respondedShiftRequestsQuery.data?.nextPageIndex != null) {
      respondedShiftRequestsQuery.fetchNextPage();
    }
  }, [respondedShiftRequestsQuery]);

  const batchSendHire = useCallback(() => {
    const firstNRequests = hireableRequests.slice(0, fulfillmentLeft);
    onHire(firstNRequests.map((req) => req.ptUser));
  }, [onHire, hireableRequests, fulfillmentLeft]);

  const onHireFactory = useCallback(
    (ptUser: PtUser[]) => () => onHire(ptUser),
    [onHire]
  );

  const { canConfirmAppliedPt, canChatWithHiredPt } =
    useShiftAccessControl(shift);

  const shouldShowRedDot = useCallback(
    (req: ResponsedShiftRequestBaseWithNumbering) => {
      // ref logic doc-ed in https://github.com/oursky/mtr-oneclick/blob/ea5d845b2ae89a27a591321e9cb7b364e56965c5/docs/shift-request-status.md#shift-request-status-and-red-dot
      const isBorrowing = req.borrowingRequestStatus != null;

      if (!isBorrowing && req.status === "applied") {
        return true;
      }

      if (
        isBorrowing &&
        req.status === "applied" &&
        req.borrowingRequestStatus === "approved"
      ) {
        return true;
      }

      return false;
    },
    []
  );

  return (
    <section className={cn("flex", "flex-col", "gap-3")}>
      <div className={cn("flex", "flex-row", "items-center")}>
        <p
          className={cn(
            "grow",
            "text-black/60",
            "flex-1",
            "text-sm",
            "md:text-base"
          )}
        >
          <Trans
            i18nKey="shiftDetail.requested.appliedOrHiredGroup"
            values={{
              count: respondedShiftRequestsQuery.data?.totalCount ?? 0,
            }}
          />
        </p>
        {shift.status === "active" && canConfirmAppliedPt ? (
          <Button
            className={cn("flex-1", "sm:flex-none")}
            prefixIcon={SendIcon}
            theme="primary"
            disabled={
              isSending ||
              Math.min(fulfillmentLeft, hireableRequests.length) <= 0 ||
              respondedShiftRequestsQuery.isFetching
            }
            onClick={batchSendHire}
          >
            <span className={cn("mx-auto", "sm:mx-0")}>
              <Trans
                i18nKey="shiftDetail.requested.sendToFirstPeople"
                values={{
                  count: Math.min(fulfillmentLeft, hireableRequests.length),
                }}
              />
            </span>
          </Button>
        ) : null}
      </div>
      <ResponsedSectionFilter
        status418Filter={status418Filter}
        stationFilter={stationFilter}
      />
      {requests.length === 0 && !respondedShiftRequestsQuery.isFetching ? (
        <p className={cn("text-center", "text-black/60", "text-sm")}>
          <Trans i18nKey="shiftDetail.responsed.empty" />
        </p>
      ) : (
        requests.map((req) => (
          <ResponesdPTUserRequestRow
            key={`${shift.id}_${req.ptUserId}`}
            user={req.ptUser}
            shift={shift}
            shiftRequest={req}
            clashedShifts={
              ptUsersWithClashedShiftsMap[req.ptUserId]?.clashedShifts ?? []
            }
            showChatButton={
              canChatWithHiredPt
                ? req.borrowingRequestStatus !== "rejected"
                : false
            }
            disabled={
              req.borrowingRequestStatus === "rejected" ||
              shift.status === "cancelled" ||
              shift.status === "expired" ||
              req.ptUser.isDeleted
            }
            notifyContacted={notifyContacted}
            unread={shouldShowRedDot(req)}
            onHireFactory={onHireFactory}
            isSending={isSending}
            canHire={
              // eslint-disable-next-line react/jsx-no-leaked-render
              canConfirmAppliedPt && !hiredUserIds.includes(req.ptUserId)
            }
          />
        ))
      )}
      {respondedShiftRequestsQuery.data?.nextPageIndex != null &&
      !respondedShiftRequestsQuery.isFetching ? (
        <div className={cn("flex", "justify-center")}>
          <Button
            className={cn(
              "bg-transparent",
              "border-none",
              "!shadow-none",
              "hover:bg-transparent",
              "h-6",
              "md:my-3"
            )}
            iconClassName="fill-gray-500"
            suffixIcon={ChevronDownIcon}
            theme="white"
            onClick={handleFetchMore}
          >
            <span className={cn("text-gray-500", "text-xs", "font-normal")}>
              <Trans i18nKey="shiftDetail.userList.fetchMore" />
            </span>
          </Button>
        </div>
      ) : null}
      {respondedShiftRequestsQuery.isFetching ? (
        <div
          className={cn(
            "w-full",
            "h-full",
            "flex",
            "items-center",
            "justify-center"
          )}
        >
          <LoadingSpinner size="l" />
        </div>
      ) : null}
    </section>
  );
};

export default ResponsedSection;
