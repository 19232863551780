import React, { useCallback, useMemo } from "react";
import cn from "classnames";
import { DocumentIcon } from "../../icon";
import { Asset } from "oneclick-component/src/store/apis/enhancedApi";
import useDownloadFileFromUrl from "../../hooks/useDownloadFileFromUrl";
import { useShowError } from "../../hooks/useShowError";
import { OneClickCustomError } from "oneclick-component/src/models/error";
import { useTranslation } from "react-i18next";
import { constructFileSizeDisplayString } from "../../utils/file";

interface Props {
  className?: string;
  attachment: Asset;
}
const DownloadableAttachmentPreview = (props: Props): React.ReactElement => {
  const { className, attachment } = props;
  const downloadFileFromUrl = useDownloadFileFromUrl();
  const { showError } = useShowError();
  const { t } = useTranslation();
  const onClickAttachmentPreview = useCallback(() => {
    if (attachment.url == null) {
      showError(new OneClickCustomError(t("attachment.download.fail")));
      return;
    }
    downloadFileFromUrl(attachment.url);
  }, [attachment.url, downloadFileFromUrl, showError, t]);

  const fileSizeStr = useMemo<string>(() => {
    return attachment.contentSize != null
      ? constructFileSizeDisplayString(attachment.contentSize)
      : "";
  }, [attachment]);
  return (
    <div
      className={cn(
        "flex",
        "w-full",
        "p-3",
        "items-start",
        "gap-3",
        "rounded-lg",
        "bg-primary-100",
        "cursor-pointer",
        className
      )}
      onClick={onClickAttachmentPreview}
    >
      <div
        className={cn("w-9.5", "h-9.5", "rounded-lg", "p-2", "bg-primary-600")}
      >
        <DocumentIcon className={cn("w-5.5", "h-5.5", "fill-white")} />
      </div>
      <div className={cn("flex-1", "min-w-0")}>
        <span
          className={cn(
            "block",
            "text-sm",
            "text-black/86",
            "mb-1",
            "truncate"
          )}
        >
          {attachment.filename}
        </span>
        <div className={cn("flex", "justify-between")}>
          <span className={cn("text-xs", "text-black/60")}>{fileSizeStr}</span>
        </div>
      </div>
    </div>
  );
};

export default DownloadableAttachmentPreview;
