import { ReactElement, useCallback, useContext } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import cn from "classnames";

import { LogoIcon } from "oneclick-component/src/icon";
import { Button } from "oneclick-component/src/components/Button";
import { RootState } from "../../store/store";

import AppRoutes from "../../routes/AppRoutes";
import { AuthContext } from "../../providers/AuthProvider";
import { useShowError } from "../../hooks/useShowError";

const LogInScreen = (): ReactElement => {
  const { login, activeADAccount } = useContext(AuthContext);
  const { showError } = useShowError();
  const isLoggedIn = useSelector((state: RootState) => {
    return state.auth.meUser != null;
  });
  const { t } = useTranslation();

  const onClickLogin = useCallback(() => {
    login().catch((err: unknown) => {
      showError(err, "auth.action.login.error.title");
    });
  }, [login, showError]);

  if (isLoggedIn) {
    return <Navigate replace={true} to={AppRoutes.ShiftListScreen.render()} />;
  }

  if (activeADAccount != null) {
    return (
      <Navigate replace={true} to={AppRoutes.UnauthorizedScreen.render()} />
    );
  }

  return (
    <main
      className={cn(
        "bg-white",
        "w-full",
        "h-full",
        "flex",
        "items-center",
        "justify-center"
      )}
    >
      <div className={cn("flex", "flex-col", "space-y-8", "items-center")}>
        <LogoIcon />
        <Button className="text-center" onClick={onClickLogin}>
          <span className={cn("w-50", "sm:w-104")}>
            {t("auth.action.login")}
          </span>
        </Button>
      </div>
    </main>
  );
};

export default LogInScreen;
