import { ReactElement } from "react";
import { ChevronDownIcon } from "../../../../icon";
import cn from "classnames";
import { Popover } from "@headlessui/react";
import { MonthPickerPanel } from "./MonthPickerPanel";
import {
  FloatingPortal,
  autoUpdate,
  shift,
  size,
  useFloating,
  Placement,
  flip,
} from "@floating-ui/react";
import YearMonth from "../../../../models/yearMonth";

interface CommonMonthPickerProps {
  className?: string;
  placeholder?: string;
  hasError?: boolean;
  disabled?: boolean;
  formattedValue?: string;
  panelPlacement?: Placement;
}
interface MonthPickerWithSelectAllProps extends CommonMonthPickerProps {
  onChange: (value: YearMonth | null) => void;
  value: YearMonth | null;
  allowSelectAll: true;
}

interface MonthPickerWithoutSelectAllProps extends CommonMonthPickerProps {
  onChange: (value: YearMonth) => void;
  value: YearMonth | null;
  allowSelectAll?: false;
}

export type MonthPickerProps =
  | MonthPickerWithSelectAllProps
  | MonthPickerWithoutSelectAllProps;

export const MonthPicker = (props: MonthPickerProps): ReactElement => {
  const {
    className,
    onChange,
    placeholder = "",
    value,
    disabled = false,
    formattedValue,
    panelPlacement = "bottom-start",
    allowSelectAll,
  } = props;

  const { refs, floatingStyles } = useFloating({
    whileElementsMounted: autoUpdate,
    middleware: [
      size({
        apply({ elements }) {
          Object.assign(elements.floating.style, {
            width: `16.5rem`, // hard-coded fixed with
          });
        },
      }),
      shift(),
      flip(),
    ],
    placement: panelPlacement,
  });
  return (
    <Popover className={cn("relative", className)} as="div">
      {({ open }) => (
        <>
          <Popover.Button
            ref={refs.setReference}
            className={cn(
              "relative",
              "cursor-default",
              "w-full",
              "min-h-10.5",
              "rounded-md",
              "py-1.5",
              "pl-3",
              "pr-10",
              "text-left",
              "text-gray-900",
              "shadow-sm",
              "ring-1",
              open ? "ring-primary-600" : "ring-gray-300",
              "focus:outline-none",
              disabled ? "bg-gray-200" : "bg-white",
              "sm:text-sm",
              "sm:leading-6"
            )}
            disabled={disabled}
          >
            <span
              className={cn("block", "truncate", {
                "opacity-25": formattedValue == null && !allowSelectAll,
              })}
            >
              {formattedValue ?? placeholder}
            </span>
            <span
              className={cn(
                "pointer-events-none",
                "absolute",
                "inset-y-0",
                "right-0",
                "flex",
                "items-center",
                "pr-2"
              )}
            >
              <ChevronDownIcon
                className={cn(
                  "h-4",
                  "w-4",
                  disabled ? "fill-gray-700/25" : "fill-gray-700"
                )}
                aria-hidden="true"
              />
            </span>
          </Popover.Button>
          <FloatingPortal>
            {allowSelectAll ? (
              <MonthPickerPanel
                className={cn("z-50", "mt-3")}
                ref={refs.setFloating}
                style={floatingStyles}
                value={value}
                onChange={onChange}
                allowSelectAll={true}
              />
            ) : (
              <MonthPickerPanel
                className={cn("z-50", "mt-3")}
                ref={refs.setFloating}
                style={floatingStyles}
                value={value}
                onChange={onChange}
                allowSelectAll={false}
              />
            )}
          </FloatingPortal>
        </>
      )}
    </Popover>
  );
};
