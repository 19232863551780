import { ReactElement, useEffect, useState } from "react";
import cn from "classnames";
import { Trans } from "react-i18next";
import ProgressBar from "oneclick-component/src/components/ProgressBar";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";

interface SectionType {
  total: number;
  queueCount: number;
  isPending: boolean;
  pollingFunction: () => void;
  onComplete?: () => void;
}

export const SendProgressComponent = (props: SectionType): ReactElement => {
  const { total, queueCount, isPending, pollingFunction, onComplete } = props;
  const [isStarted, setIsStarted] = useState(false);

  useEffect(() => {
    if (isPending) {
      setIsStarted(true);
      const pollingFetchId = setInterval(() => {
        pollingFunction();
      }, 30000);

      return () => {
        clearInterval(pollingFetchId);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPending]);

  useEffect(() => {
    if (!isPending && isStarted) {
      setIsStarted(false);
      onComplete?.();
    }
  }, [isPending, isStarted, onComplete]);

  if (!isPending) {
    return <></>;
  }

  return (
    <div>
      <div
        className={cn(
          "flex",
          "flex-row",
          "items-center",
          "justify-end",
          "mt-4"
        )}
      >
        <div className={cn("mr-2", "text-sm", "flex", "flex-row")}>
          <LoadingSpinner size="xs" className="mr-2" />
          <p>
            <Trans i18nKey="sendingInProgress.description" />
          </p>
        </div>
        <ProgressBar count={queueCount} max={total} labelStyle="none" />
      </div>
      <div className={cn("text-xs", "text-right", "text-gray-400", "mt-2")}>
        <Trans i18nKey="sendingInProgress.remarks" />
      </div>
    </div>
  );
};
