import { useCallback } from "react";

const useDownloadFileFromUrl = (): ((url: string) => void) => {
  return useCallback((url) => {
    const link = document.createElement("a");
    link.href = url;
    link.dispatchEvent(new MouseEvent("click"));
  }, []);
};

export default useDownloadFileFromUrl;
