import React, { useCallback, useMemo, useState, useContext } from "react";
import { Trans } from "react-i18next";
import cn from "classnames";

import { MasterShiftDetail } from "oneclick-component/src/store/apis/enhancedApi";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import { Modal } from "oneclick-component/src/components/Modal";
import { masterShiftTimePatternSchema } from "oneclick-component/src/models/shiftTimePattern";
import { makeMasterShiftTimePatternDisplayText } from "oneclick-component/src/utils/masterShift";

import MultiShiftPreviewDetail from "../CreateMultiShiftPreviewScreen/MultiShiftPreviewDetail";
import { FeatureConfigContext } from "../../providers/FeatureConfigProvider";
import ShiftBadge from "oneclick-component/src/components/ShiftRateBadge/ShiftBadge";
import ShiftT3Badge from "oneclick-component/src/components/Badge/ShiftT3Badge";

type RecurringType = "single" | "repeat" | "custom" | "unknown";

interface MultiShiftInfoDialogProps {
  masterShift: MasterShiftDetail;
  isOpen: boolean;
  onClose: () => void;
}

const MultiShiftInfoDialog = React.memo(
  (props: MultiShiftInfoDialogProps): React.ReactElement => {
    const { isOpen, masterShift, onClose } = props;

    const { recurringType, dateDisplay, timeDisplay } = useMemo<{
      recurringType: RecurringType;
      dateDisplay: string;
      timeDisplay: string;
    }>(() => {
      const shiftTimePatternResult = masterShiftTimePatternSchema.safeParse(
        masterShift.shiftDatetimeSlots
      );
      if (!shiftTimePatternResult.success) {
        console.warn(
          "Failed to parse master shift time pattern JSON",
          shiftTimePatternResult.error
        );
        return {
          recurringType: "unknown",
          dateDisplay: "-",
          timeDisplay: "-",
        };
      }
      const { dateRange, recurrence, timeRanges } =
        makeMasterShiftTimePatternDisplayText(shiftTimePatternResult.data);

      let recurringType: RecurringType = "unknown";
      if (shiftTimePatternResult.data.recurring) {
        recurringType = "repeat";
      } else if (shiftTimePatternResult.data.shiftStartDates.length > 1) {
        recurringType = "custom";
      } else {
        recurringType = "single";
      }
      const dateDisplay = [dateRange, recurrence].filter(Boolean).join(" | ");

      return {
        recurringType,
        dateDisplay,
        timeDisplay: timeRanges,
      };
    }, [masterShift.shiftDatetimeSlots]);

    return (
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        hasXMarkButton={true}
        className={cn("w-full", "sm:max-w-[56.875rem]")}
      >
        <MultiShiftPreviewDetail
          title={masterShift.shiftTitle}
          shiftNumber={masterShift.masterShiftId}
          description={masterShift.shiftDescription}
          fulfillment={`0/${masterShift.fulfillmentCount}`}
          recurringType={recurringType}
          partTimeType={
            masterShift.ptUserRoles?.map((role) => role.name) ?? ["-"]
          }
          workingStation={masterShift.workingStation.shortCode}
          supportStation={masterShift.supportStation?.shortCode}
          shiftCount={masterShift.shifts.length}
          dateDisplay={dateDisplay}
          timeDisplay={timeDisplay}
          rate={masterShift.rate ?? undefined}
          shiftType={masterShift.shiftType}
          isAppliableToAllAtT3={masterShift.isAppliableToAllAtT3}
        />
      </Modal>
    );
  }
);

interface MultiShiftInfoProps {
  isLoading: boolean;
  masterShift: MasterShiftDetail | undefined;
}

export const MultiShiftInfo = React.memo(
  (props: MultiShiftInfoProps): React.ReactElement => {
    const { shouldShowT3Feature } = useContext(FeatureConfigContext);
    const { isLoading, masterShift } = props;
    const [isInfoDialogOpen, setIsInfoDialogOpen] = useState(false);

    const onOpenInfoDialog = useCallback(() => {
      setIsInfoDialogOpen(true);
    }, []);

    const onCloseInfoDialog = useCallback(() => {
      setIsInfoDialogOpen(false);
    }, []);

    if (isLoading || masterShift == null) {
      return (
        <div
          className={cn(
            "rounded-lg",
            "bg-white",
            "max-w-169",
            "mx-auto",
            "p-6",
            "shadow-md",
            "text-center"
          )}
        >
          <LoadingSpinner />
        </div>
      );
    }

    return (
      <div>
        <div
          className={cn(
            "rounded-lg",
            "bg-white",
            "max-w-169",
            "mx-auto",
            "shadow-md"
          )}
        >
          {masterShift.isIncident ? (
            <div className={cn("bg-red-500", "py-1", "pl-6", "rounded-t-lg")}>
              <p className={cn("text-white", "font-medium", "text-sm")}>
                <Trans i18nKey="shiftDetail.header.incidentBanner" />
              </p>
            </div>
          ) : null}
          <div className="p-6">
            <div
              className={cn("flex", "justify-between", "items-start", "mb-3")}
            >
              <div
                className={cn(
                  "flex",
                  "flex-row",
                  "items-center",
                  "gap-x-3",
                  "max-w-[90%]"
                )}
              >
                <ShiftBadge
                  rate={masterShift.rate}
                  isIncident={masterShift.isIncident}
                />
                <h2
                  className={cn(
                    "text-lg",
                    "leading-7",
                    "font-medium",
                    "line-clamp-1"
                  )}
                >
                  {masterShift.shiftTitle}
                </h2>
                {shouldShowT3Feature && !masterShift.isIncident ? (
                  <ShiftT3Badge active={masterShift.isAppliableToAllAtT3} />
                ) : null}
              </div>

              <button
                type="button"
                className={cn(
                  "text-primary-800",
                  "text-sm",
                  "underline",
                  "leading-3",
                  "flex-none"
                )}
                onClick={onOpenInfoDialog}
              >
                <Trans i18nKey="shift.multiShiftInfo.shiftContent" />
              </button>
            </div>
            <div>
              <span
                className={cn("text-black/60", "text-xs", "leading-5", "pr-2")}
              >
                <Trans i18nKey="shift.multiShiftInfo.shiftDisplayId.label" />
              </span>
              <span
                className={cn(
                  "text-gray-900",
                  "text-sm",
                  "font-medium",
                  "leading-5"
                )}
              >
                {masterShift.masterShiftId}
              </span>
            </div>
          </div>
        </div>
        <MultiShiftInfoDialog
          masterShift={masterShift}
          isOpen={isInfoDialogOpen}
          onClose={onCloseInfoDialog}
        />
      </div>
    );
  }
);
