import forms from "@tailwindcss/forms";

export default {
  content: ["./src/**/*.{js,ts,jsx,tsx}"],
  theme: {
    screens: {
      xs: "480px",
      sm: "640px",
      md: "768px",
      lg: "1024px",
      xl: "1280px",
      "2xl": "1440px",
    },
    spacing: () => {
      const spacing = { px: "1px" };
      for (let i = 0; i <= 400; i += 0.5) {
        spacing[i] = `${i * 0.25}rem`;
      }
      return spacing;
    },
    lineHeight: (theme) => theme("spacing"),
    extend: {
      colors: {
        primary: {
          50: "#fff7ed",
          100: "#feecd6",
          200: "#fcd5ac",
          300: "#f9b878",
          400: "#f69041",
          500: "#f37321",
          600: "#e45612",
          700: "#bd4011",
          800: "#963416",
          900: "#792c15",
          950: "#411409",
        },
        line: {
          island: "#0074B9",
          "tseung-kwan-o": "#903DA5",
          "south-island": "#D1C100",
        },
        grey: {
          2: "#828282",
        },
      },
      minWidth: (theme) => {
        return theme("spacing");
      },
      maxWidth: (theme) => {
        return theme("spacing");
      },
      minHeight: (theme) => {
        return theme("spacing");
      },
      maxHeight: (theme) => {
        return theme("spacing");
      },
      animation: {
        "toast-enter": "enter .2s ease-out",
        "toast-leave": "leave .15s ease-in forwards",
        "flashy-background": "flashy 1s cubic-bezier(0, 0, 0.2, 1) infinite",
      },
      opacity: {
        12: ".12",
        24: ".24",
        86: ".86",
      },
      keyframes: {
        enter: {
          "0%": {
            opacity: "0",
            transform: "translateY(-12px)",
          },
          "100%": {
            opacity: "1",
            transform: "translateY(0)",
          },
        },
        leave: {
          "0%": {
            opacity: "1",
          },
          "100%": {
            opacity: "0",
          },
        },
        flashy: {
          "75%, 100%": {
            transform: "scaleX(1.24) scaleY(1.94)",
            opacity: "0",
          },
        },
      },
    },
  },
  plugins: [forms],
};
