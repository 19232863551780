import { RowSelectionState, SortingState } from "@tanstack/react-table";
import cn from "classnames";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import Pagination from "oneclick-component/src/components/Pagination";
import useOnClickTableColumnHeader, {
  ControlledTableState,
} from "oneclick-component/src/hooks/useOnClickTableColumnHeader";
import { useBreakPoints } from "oneclick-component/src/providers";
import {
  GetListShiftsResponse,
  ShiftRequestBase,
  ShiftWithRequests,
  useListShiftsHandlerShiftsGetQuery as useListShift,
  useListShiftCancelReasonsHandlerShiftsCancelReasonsGetQuery,
  UserStationProfile,
  WorkingStation,
} from "oneclick-component/src/store/apis/enhancedApi";
import {
  formatDateTime,
  isoToDateTime,
} from "oneclick-component/src/utils/datetime";
import { makeShiftDisplayInterval } from "oneclick-component/src/utils/shift";

import { SHIFT_LIST_PAGE_SIZE, ShiftListContext } from "..";
import { RootState } from "../../../store/store";
import { saveActiveShiftListFilter } from "../../../store/filterState";
import i18next from "../../../i18n/i18n";
import CancelShiftDialog from "../../../components/CancelShiftDialog/CancelShiftDialog";
import MobileSearchView from "../../../components/MobileSearchView";
import { SHIFT_ACTIVE_TAB_GROUPING_COLUMN_HEADER_MAP } from "../../../constants/shifts/activeTab";
import { useIsManager } from "../../../hooks/role";
import { useSelectedStationProfile } from "../../../hooks/useSelectedStationProfile";
import { useShowError } from "../../../hooks/useShowError";
import { HiredShiftRequestBase } from "../../../models/shiftRequest";
import AppRoutes from "../../../routes/AppRoutes";
import {
  getDateRangeFromFilter,
  ShiftDateRangeFilter,
} from "../DateRangeFilter/model";
import useShiftRateFilterOptions from "../useShiftRateFilterOptions";
import { usePtUserRoleFilterOptions } from "../usePtUserRoleFilterOptions";
import { constructProfileScopedWorkingStationFilter } from "../workingStationFilter";
import ActiveShiftTabActionBar from "./ActionBar";
import ActiveShiftTabSelectedActionBar from "./SelectedActionBar";
import ActiveShiftTabTable from "./Table";
import {
  ActiveShiftListRecord,
  useActiveShiftListTable,
} from "./useActiveShiftListTable";

const MAX_INCIDENT_SHIFTS_COUNT = 100;
const transformFromShiftToShiftListRecord = (
  shift: ShiftWithRequests
): ActiveShiftListRecord => {
  const [_, { startTime, endTime, overnight }] = makeShiftDisplayInterval(
    shift.dutyStartTime,
    shift.dutyEndTime
  );
  const timeRangeString =
    endTime === ""
      ? `${startTime} - ${i18next.t("common.tbc")}`
      : `${startTime} - ${endTime}${overnight}`;
  const appliedRequests: ShiftRequestBase[] = shift.shiftRequests.filter(
    (req) =>
      (req.status === "applied" || req.status === "contacted") &&
      !req.ptUser.isDeleted
  );
  const hireRequests: HiredShiftRequestBase[] = shift.shiftRequests
    .filter((req) => req.status === "hired")
    .map((req) => req as HiredShiftRequestBase);

  let remoteStation = null;

  if (shift.isIncident) {
    remoteStation = shift.supportStation;
  } else if (
    shift.workingStation.shortCode !==
    shift.workingStation.mainStation.shortCode
  ) {
    remoteStation = shift.workingStation;
  }
  return {
    info: {
      title: shift.shiftTitle,
      subtitle: (
        <Trans
          i18nKey="shiftList.table.displayId"
          values={{ displayId: shift.displayId }}
        />
      ),
      rate: shift.rate,
      isAppliableToAllAtT3: shift.isAppliableToAllAtT3,
    },
    timeRange: timeRangeString,
    partTimeType: shift.ptUserRoles.map((role) => role.name).join(", "),
    appliedRequests: appliedRequests,
    hireStatus: {
      workingStationShortCode: shift.workingStation.shortCode,
      fulfillmentCount: shift.fulfillmentCount,
      hiredRequests: hireRequests,
    },
    title: shift.shiftTitle,
    date: formatDateTime(isoToDateTime(shift.dutyStartTime), "yyyy-LL-dd"),
    numberOfApplicants: appliedRequests.length,
    fulfillmentCount: shift.fulfillmentCount,
    fullTimeCount: shift.fulltimeCount,
    hiredFullTimeCount: (shift.fullTimeAttendances ?? []).length,
    id: shift.id,
    shiftType: shift.shiftType,
    incidentAccessControl: shift.incidentAccessControl,
    remoteStation: remoteStation,
  };
};

const useSortingQueryParam = (
  sortingState: SortingState
): string | undefined => {
  const defaultSort = `dutyStartTime+,dutyEndTime+`;
  if (sortingState.length === 0 || sortingState.length > 1) {
    return `${defaultSort}`;
  }
  // sortingState.length === 1
  const singleColumnSorting = sortingState[0];
  const sortDirectionSymbol = singleColumnSorting.desc ? "-" : "+";

  switch (singleColumnSorting.id) {
    case "info": {
      return `shiftTitle${sortDirectionSymbol}`;
    }
    case "timeRange": {
      return `dutyStartTime${sortDirectionSymbol},dutyEndTime${sortDirectionSymbol}`;
    }
    case "partTimeType": {
      return `ptUserRoleId${sortDirectionSymbol},${defaultSort}`;
    }
    case "appliedRequests": {
      return `numApplicants${sortDirectionSymbol},${defaultSort}`;
    }
    case "hireStatus": {
      return `fulfillmentCount${sortDirectionSymbol},${defaultSort}`;
    }
    default: {
      return `${defaultSort}`;
    }
  }
};

interface ActiveShiftTabQueryInfo {
  activeShiftsResponse: GetListShiftsResponse | undefined;
  cachedActiveShiftsResponse: GetListShiftsResponse | undefined;
  activeIncidentShiftsResponse: GetListShiftsResponse | undefined;
  isLoading: boolean;
  error: unknown;
}

const useActiveShiftTabQuery = ({
  pageIndex,
  selectedStationProfile,
  workingStationId,
  searchString,
  shiftDateRangeFilter,
  shiftRateId,
  ptUserRoleIds,
  sorting,
}: {
  pageIndex: number;
  selectedStationProfile: UserStationProfile | null;
  searchString?: string;
  workingStationId?: number | null;
  shiftDateRangeFilter?: ShiftDateRangeFilter;
  shiftRateId?: number | null;
  ptUserRoleIds?: number[] | null;
  sorting?: string;
}): ActiveShiftTabQueryInfo => {
  const profileScopedWorkingStationFilter =
    constructProfileScopedWorkingStationFilter(
      selectedStationProfile,
      workingStationId
    );

  const dateRange =
    shiftDateRangeFilter != null
      ? getDateRangeFromFilter(shiftDateRangeFilter)
      : null;

  const {
    currentData: activeShiftsResponse,
    isFetching: isFetchingShifts,
    error: getShiftsError,
    data: cachedActiveShiftsResponse,
  } = useListShift(
    {
      status: "active",
      shiftType: "REGULAR",
      pageSize: SHIFT_LIST_PAGE_SIZE,
      sort: sorting,
      pageIndex: pageIndex,
      searchString,
      before: dateRange?.before?.toISO(),
      after: dateRange?.after?.toISO(),
      ptUserRoleIds: ptUserRoleIds,
      rateId: shiftRateId,
      workingStationId: profileScopedWorkingStationFilter,
    },
    { refetchOnMountOrArgChange: true }
  );

  const {
    currentData: activeIncidentShiftsResponse,
    isFetching: isFetchingIncidentShifts,
    error: getIncidentShiftsError,
  } = useListShift(
    {
      status: "active",
      shiftType: "INCIDENT",
      sort: sorting,
      searchString,
      before: dateRange?.before?.toISO(),
      after: dateRange?.after?.toISO(),
      ptUserRoleIds: ptUserRoleIds,
      rateId: shiftRateId,
      pageIndex: 0,
      pageSize: MAX_INCIDENT_SHIFTS_COUNT,
    },
    { refetchOnMountOrArgChange: true, skip: pageIndex !== 0 }
  );

  const isLoading = isFetchingShifts || isFetchingIncidentShifts;
  const error = getShiftsError ?? getIncidentShiftsError;

  return {
    activeShiftsResponse,
    cachedActiveShiftsResponse,
    activeIncidentShiftsResponse,
    isLoading,
    error,
  };
};

// eslint-disable-next-line complexity
const ActiveShiftTab = (): React.ReactElement => {
  const { showError } = useShowError();
  const navigate = useNavigate();
  const { useIsSm } = useBreakPoints();
  const isSm = useIsSm();
  const isManager = useIsManager();
  const dispatch = useDispatch();
  const selectedStationProfile = useSelectedStationProfile();
  const [pageIndex, setPageIndex] = useState(0);
  const { data: cancelReasons } =
    useListShiftCancelReasonsHandlerShiftsCancelReasonsGetQuery();

  const savedFilter = useSelector((state: RootState) => {
    return state.filterState.activeShiftTabFilter;
  }, shallowEqual);

  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [searchStringFilter, setSearchStringFilter] = useState<
    string | undefined
  >(savedFilter?.searchStringFilter);
  const [dateRangeFilter, setDateRangeFilter] = useState<ShiftDateRangeFilter>(
    savedFilter?.dateRangeFilter != null
      ? savedFilter.dateRangeFilter
      : {
          type: "all",
        }
  );
  const [workingStationId, setWorkingStationId] = useState<number | null>(
    savedFilter?.workingStationId ?? null
  );
  const [ptUserRoleFilter, setPtUserRoleFilter] = useState<string | null>(
    savedFilter?.ptUserRoleFilter ?? null
  );
  const [shiftRateFilter, setShiftRateFilter] = useState<number | null>(
    savedFilter?.shiftRateFilter ?? null
  );

  const [ptUserRoleFilterOptions, resolvePtUserRoleOptionToRoleId] =
    usePtUserRoleFilterOptions();
  const shiftRateFilterOptions = useShiftRateFilterOptions();

  const shiftListFilterState = useMemo(() => {
    return {
      searchString: searchStringFilter,
      dateRange: dateRangeFilter,
      ptUserRole: ptUserRoleFilter,
      shiftRate: shiftRateFilter,
      workingStationId: workingStationId,
    };
  }, [
    searchStringFilter,
    dateRangeFilter,
    ptUserRoleFilter,
    shiftRateFilter,
    workingStationId,
  ]);

  const [controlledTableState, setControlledTableState] =
    useState<ControlledTableState>({
      sorting: [{ id: "timeRange", desc: false }],
      grouping: ["shiftType", "date"],
    });
  const sortingQueryParam = useSortingQueryParam(controlledTableState.sorting);

  const {
    activeShiftsResponse,
    cachedActiveShiftsResponse,
    activeIncidentShiftsResponse,
    isLoading,
    error,
  } = useActiveShiftTabQuery({
    pageIndex,
    selectedStationProfile,
    searchString: searchStringFilter,
    workingStationId,
    shiftDateRangeFilter: dateRangeFilter,
    ptUserRoleIds: resolvePtUserRoleOptionToRoleId(ptUserRoleFilter),
    shiftRateId: shiftRateFilter,
    sorting: sortingQueryParam,
  });
  const { results: shifts } = activeShiftsResponse ?? {};
  const { results: incidentShifts } = activeIncidentShiftsResponse ?? {};
  const regularTotalCount = cachedActiveShiftsResponse?.totalCount ?? 0;
  const tableData = useMemo(() => {
    if (isLoading) {
      return [];
    }
    const shiftListRecords = (shifts ?? []).map(
      transformFromShiftToShiftListRecord
    );
    if (pageIndex !== 0) {
      return shiftListRecords;
    }
    const incidentShiftListRecords = (incidentShifts ?? []).map(
      transformFromShiftToShiftListRecord
    );
    return [...incidentShiftListRecords, ...shiftListRecords];
  }, [pageIndex, incidentShifts, shifts, isLoading]);
  const isShiftListEmpty = tableData.length === 0;

  const { setActiveShiftListLength } = useContext(ShiftListContext);
  useEffect(() => {
    setActiveShiftListLength(tableData.length);
  }, [setActiveShiftListLength, tableData]);

  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({});
  const table = useActiveShiftListTable(tableData, setRowSelection, {
    columnVisibility: {
      partTimeType: isSm ? true : false,
      action: isSm ? true : false,
      title: false,
      date: false,
      numberOfApplicants: false,
      fulfillmentCount: false,
      id: false,
      shiftType: false,
    },
    sorting: controlledTableState.sorting,
    grouping: controlledTableState.grouping,
    rowSelection,
  });

  const onCloseCancelDialog = useCallback(() => {
    setIsCancelDialogOpen(false);
    table.resetRowSelection();
  }, [table]);
  const onClickOpenDialog = useCallback(() => {
    setIsCancelDialogOpen(true);
  }, []);

  const onClickTableRowFactory = useCallback(
    (shiftId: number) => () => {
      navigate(AppRoutes.ShiftDetailScreen.render(shiftId));
    },
    [navigate]
  );

  const resetToPageOne = useCallback(() => {
    setPageIndex(0);
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    resetToPageOne();
    setRowSelection({});
  }, [
    controlledTableState.sorting,
    controlledTableState.grouping,
    shiftListFilterState,
    resetToPageOne,
  ]);

  const handleOnClickPage = useCallback((pageIndex: number) => {
    setPageIndex(pageIndex);
    window.scrollTo({ top: 0 });
  }, []);

  const onChangeSearchString = useCallback(
    (v: string) => {
      const trimmedSearchString = v.trim();
      if (trimmedSearchString === "") {
        dispatch(
          saveActiveShiftListFilter({
            searchStringFilter: undefined,
          })
        );
        setSearchStringFilter(undefined);
        return;
      }
      dispatch(
        saveActiveShiftListFilter({
          searchStringFilter: trimmedSearchString,
        })
      );
      setSearchStringFilter(trimmedSearchString);
    },
    [dispatch]
  );

  useEffect(() => {
    if (error != null) {
      showError(error);
    }
  }, [error, showError]);

  const [isMobileSearchView, setIsMobileSearchView] = useState<boolean>(false);

  const onEnterMobileSearchView = useCallback(
    () => setIsMobileSearchView(true),
    []
  );
  const onExitMobileSearchView = useCallback(
    () => setIsMobileSearchView(false),
    []
  );
  const onClickColumnHeader =
    useOnClickTableColumnHeader<ActiveShiftListRecord>(
      setControlledTableState,
      SHIFT_ACTIVE_TAB_GROUPING_COLUMN_HEADER_MAP,
      new Set<string>(["action"]),
      new Set<string>(["shiftType", "date"])
    );

  const onChangePtUserRole = useCallback(
    (role: string | null) => {
      dispatch(
        saveActiveShiftListFilter({
          ptUserRoleFilter: role,
        })
      );
      setPtUserRoleFilter(role);
    },
    [dispatch]
  );
  const onChangeShiftRate = useCallback(
    (rate: number | null) => {
      dispatch(
        saveActiveShiftListFilter({
          shiftRateFilter: rate,
        })
      );
      setShiftRateFilter(rate);
    },
    [dispatch]
  );
  const onChangeWorkingStationFilter = useCallback(
    (station: WorkingStation | null) => {
      dispatch(
        saveActiveShiftListFilter({
          workingStationId: station?.id ?? null,
        })
      );
      setWorkingStationId(station?.id ?? null);
    },
    [dispatch]
  );

  const onDateRangeFilterChange = useCallback(
    (filter: ShiftDateRangeFilter) => {
      dispatch(
        saveActiveShiftListFilter({
          dateRangeFilter: filter,
        })
      );
      setDateRangeFilter(filter);
    },
    [dispatch]
  );

  const contentUnderSearchBar = useMemo(
    () => (
      <>
        <ActiveShiftTabActionBar
          className="mb-6"
          searchString={searchStringFilter}
          onChangeSearchString={onChangeSearchString}
          dateRangeFilter={dateRangeFilter}
          onDateRangeFilterChange={onDateRangeFilterChange}
          ptUserRole={ptUserRoleFilter}
          onChangePtUserRole={onChangePtUserRole}
          ptUserRoleOptions={ptUserRoleFilterOptions}
          shiftRate={shiftRateFilter}
          onChangeShiftRate={onChangeShiftRate}
          shiftRateOptions={shiftRateFilterOptions}
          showWorkingStationFilter={!isManager}
          workingStationFilter={workingStationId}
          onChangeWorkingStationFilter={onChangeWorkingStationFilter}
          isMobileSearchView={isMobileSearchView}
          onEnterMobileSearchView={onEnterMobileSearchView}
        />
        <CancelShiftDialog
          shiftIds={table
            .getSelectedRowModel()
            .rows.map((row) => row.original.id)}
          isOpen={isCancelDialogOpen}
          onClose={onCloseCancelDialog}
          cancelReasons={cancelReasons?.cancelReasons ?? []}
          uncancellableShiftIds={table
            .getSelectedRowModel()
            .rows.filter(
              (row) =>
                row.original.shiftType === "INCIDENT" &&
                row.original.incidentAccessControl?.canCancel !== true
            )
            .map((row) => row.original.id)}
        />
        <ActiveShiftTabSelectedActionBar
          className="mb-3"
          onClickOpenDialog={onClickOpenDialog}
          selectedShiftIds={table
            .getSelectedRowModel()
            .rows.map((row) => row.original.id)}
        />
        <ActiveShiftTabTable
          isLoading={isLoading}
          isEmpty={isShiftListEmpty}
          onClickTableRowFactory={onClickTableRowFactory}
          onClickColumnHeader={onClickColumnHeader}
          table={table}
          // Make sure table UI updated when selection is updated
          rowSelection={rowSelection}
        />
        {regularTotalCount > 0 ||
        (incidentShifts != null && incidentShifts.length > 0) ? (
          <>
            <Pagination
              className={cn(
                "mt-3",
                "bg-white",
                !isSm && ["fixed", "bottom-0", "inset-x-0"]
              )}
              totalPages={Math.ceil(regularTotalCount / SHIFT_LIST_PAGE_SIZE)}
              pageIndex={pageIndex}
              onClickPage={handleOnClickPage}
            />
            <div className="h-16" />
          </>
        ) : null}
      </>
    ),
    [
      onChangeSearchString,
      dateRangeFilter,
      ptUserRoleFilter,
      ptUserRoleFilterOptions,
      shiftRateFilter,
      shiftRateFilterOptions,
      isManager,
      workingStationId,
      isMobileSearchView,
      onEnterMobileSearchView,
      table,
      isCancelDialogOpen,
      onCloseCancelDialog,
      cancelReasons?.cancelReasons,
      onClickOpenDialog,
      isLoading,
      isShiftListEmpty,
      onClickTableRowFactory,
      onClickColumnHeader,
      rowSelection,
      regularTotalCount,
      incidentShifts,
      isSm,
      pageIndex,
      handleOnClickPage,
      onChangePtUserRole,
      onChangeShiftRate,
      onChangeWorkingStationFilter,
      onDateRangeFilterChange,
      searchStringFilter,
    ]
  );

  if (isMobileSearchView) {
    return (
      <MobileSearchView
        onChangeSearchInput={onChangeSearchString}
        onExit={onExitMobileSearchView}
      >
        {contentUnderSearchBar}
      </MobileSearchView>
    );
  }
  return <div className="mt-1">{contentUnderSearchBar}</div>;
};

export default ActiveShiftTab;
