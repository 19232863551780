import { ReactElement, useCallback, useMemo } from "react";
import cn from "classnames";
import {
  WeekOfYear,
  Weekly418Status,
  WorkingStatus,
} from "oneclick-component/src/store/apis/enhancedApi";
import PTUser418Badge from "../../../../components/PTUserBadge/PTUser418Badge";
import { Option, Select } from "oneclick-component/src/components/inputs";
import { useTranslation } from "react-i18next";
import {
  dateTimeNow,
  formatDateTime,
} from "oneclick-component/src/utils/datetime";
import { weekOfYearToDateTimeRange } from "oneclick-component/src/utils/weekOfYear";
import { shallowEqual, useSelector } from "react-redux";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import { RootState } from "../../../../store/store";
interface Props {
  ptUserId: number;
  weekYear: number;
  weekNumber: number;
  onChangeWorkingStatus: (
    ptUserId: number,
    woy: WeekOfYear,
    workingStatus: WorkingStatus | null,
    existingWorkingStatus: WorkingStatus | null,
    existing418Status: Weekly418Status
  ) => void;
  className?: string;
}

const WorkingStatusCard = (props: Props): ReactElement => {
  const { ptUserId, onChangeWorkingStatus, weekYear, weekNumber, className } =
    props;
  const { t } = useTranslation();

  const { workingStatusRecord, queryStatus } = useSelector(
    (state: RootState) => {
      const { ptUserWorkingStatusesMapState } = state;
      const result =
        ptUserWorkingStatusesMapState.ptUserWorkingStatuses[ptUserId]?.[
          weekYear
        ]?.[weekNumber];
      if (result == null) {
        return { queryStatus: "idle" };
      }
      if (result.queryResultState === "loading") {
        return { queryStatus: "loading" };
      }
      if (result.queryResultState === "error") {
        return { queryStatus: "error" };
      }
      return {
        workingStatusRecord: result.workingStatusRecord,
        queryStatus: "success",
      };
    },
    shallowEqual
  );
  const workingStatus = useMemo<WorkingStatus | null>(
    () => workingStatusRecord?.workingStatus ?? null,
    [workingStatusRecord]
  );
  const workingStatusOptions: Option<WorkingStatus | null>[] = [
    {
      name: t("partTime.list.tab.workStatus418.select.pleaseSelect"),
      value: null,
    },
    {
      name: t("partTime.list.tab.workStatus418.select.gte18hrs"),
      value: "GTE18",
    },
    {
      name: t("partTime.list.tab.workStatus418.select.lt18hrs"),
      value: "LT18",
    },
  ];

  const onChangeWorkingStatusSelect = useCallback(
    (v: WorkingStatus | null) => {
      if (workingStatusRecord == null) {
        return;
      }
      onChangeWorkingStatus(
        ptUserId,
        {
          year: workingStatusRecord.year,
          weekNumber: workingStatusRecord.weekNumber,
        },
        v,
        workingStatusRecord.workingStatus,
        workingStatusRecord.weekly418Status
      );
    },
    [ptUserId, workingStatusRecord, onChangeWorkingStatus]
  );

  const { start: weekStartMonday, end: weekEndSunday } =
    weekOfYearToDateTimeRange(
      workingStatusRecord?.year ?? dateTimeNow().weekYear,
      workingStatusRecord?.weekNumber ?? dateTimeNow().weekNumber
    );
  const weekStartSunday = weekStartMonday.minus({ days: 1 });
  const weekEndSaturday = weekEndSunday.minus({ days: 1 });
  const formattedYear = useMemo<string>(() => {
    if (weekStartSunday.year === weekEndSaturday.year) {
      return formatDateTime(weekStartSunday, "yyyy");
    }
    return `${formatDateTime(weekStartSunday, "yyyy")}-${formatDateTime(
      weekEndSaturday,
      "yyyy"
    )}`;
  }, [weekStartSunday, weekEndSaturday]);
  const formattedWeek = useMemo<string>(() => {
    const daysDiffNow = weekStartSunday.diffNow("days").days + 7;
    let relativeWeekText = "";

    if (daysDiffNow < 21) {
      if (daysDiffNow > 14) {
        relativeWeekText = ` (${t("workingStatus.twoWeeksAfter")})`;
      } else if (daysDiffNow > 7) {
        relativeWeekText = ` (${t("workingStatus.nextWeek")})`;
      } else if (daysDiffNow > 0) {
        relativeWeekText = ` (${t("workingStatus.thisWeek")})`;
      }
    }

    const dateDisplayFormat = t(
      "partTime.list.tab.workStatus418.date.displayFormat"
    );
    if (weekStartSunday.month === weekEndSaturday.month) {
      return `${formatDateTime(
        weekStartSunday,
        dateDisplayFormat
      )} - ${formatDateTime(weekEndSaturday, "d")}${relativeWeekText}`;
    }
    return `${formatDateTime(
      weekStartSunday,
      dateDisplayFormat
    )} - ${formatDateTime(
      weekEndSaturday,
      dateDisplayFormat
    )}${relativeWeekText}`;
  }, [weekStartSunday, weekEndSaturday, t]);

  if (queryStatus === "idle" || queryStatus === "loading") {
    return (
      <div
        className={cn(
          "p-3",
          "rounded-md",
          "border",
          "border-gray-200",
          "h-31",
          "w-40",
          "space-y-2",
          "flex",
          "items-center",
          "justify-center",
          className
        )}
      >
        <LoadingSpinner size="l" />
      </div>
    );
  }

  return (
    <div
      className={cn(
        "p-3",
        "rounded-md",
        "border",
        "border-gray-200",
        "w-40",
        "space-y-2",
        "h-31",
        className
      )}
    >
      <div className={cn("flex", "items-center")}>
        <p className={cn("flex-1", "text-xs", "text-gray-500")}>
          {formattedYear}
        </p>
        <PTUser418Badge
          status={workingStatusRecord?.weekly418Status ?? "UNKNOWN"}
        />
      </div>
      <p className={cn("text-sm", "text-black/86")}>{formattedWeek}</p>
      <Select<WorkingStatus | null>
        options={workingStatusOptions}
        onChange={onChangeWorkingStatusSelect}
        value={workingStatus}
      />
    </div>
  );
};

export default WorkingStatusCard;
