import React, { useCallback, useMemo } from "react";
import { DateTime } from "luxon";
import { Row } from "@tanstack/react-table";
import cn from "classnames";
import {
  isoToDateTime,
  localizeDateTime,
} from "oneclick-component/src/utils/datetime";
import { GroupHeaderCheckbox } from "oneclick-component/src/components/TableWithSelect";
import { SELECT_COLUMN_ID } from "oneclick-component/src/components/TableWithSelect/constants";

interface ShiftListGroupHeaderProps<TData> {
  row: Row<TData>;
  headerColSpan: number;
}

export function ShiftListGroupHeader<TData>(
  props: ShiftListGroupHeaderProps<TData>
): React.ReactElement {
  const { row, headerColSpan } = props;

  const onSelectCellClick = useCallback(
    (ev: React.MouseEvent<HTMLTableCellElement>) => {
      ev.stopPropagation();
    },
    []
  );

  const selectCell = useMemo(() => {
    return row
      .getVisibleCells()
      .find((cell) => cell.column.id === SELECT_COLUMN_ID);
  }, [row]);

  return (
    <tr key={row.id} className="bg-gray-100">
      {row.groupingColumnId === "date" ? (
        <>
          {selectCell != null ? (
            <td
              key={selectCell.id}
              className={cn("bg-white", "pr-3", "text-sm")}
              style={{
                width:
                  selectCell.column.getSize() === Number.MAX_SAFE_INTEGER
                    ? "auto"
                    : selectCell.column.getSize(),
              }}
              onClick={onSelectCellClick}
            >
              <GroupHeaderCheckbox row={row} />
            </td>
          ) : null}
          <td
            className={cn(
              "border-b",
              "border-black/12",
              "py-2",
              "px-6",
              "text-sm",
              "font-medium"
            )}
            colSpan={headerColSpan}
          >
            {localizeDateTime(
              isoToDateTime(row.groupingValue as string),
              DateTime.DATE_MED
            )}
          </td>
        </>
      ) : null}
    </tr>
  );
}
