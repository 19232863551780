import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import {
  SingleSelectComboBox,
  SingleSelectComboBoxElement,
} from "oneclick-component/src/components/inputs/SingleSelectComboBox";
import {
  BriefStation,
  useLazyListStationHandlerStationsGetQuery as useListStation,
  ListStationHandlerStationsGetApiArg as ListStationArg,
  ListStationResponse,
} from "oneclick-component/src/store/apis/enhancedApi";
import { DEFAULT_PAGE_SIZE } from "../../constants/pagination";
import { dedup } from "oneclick-component/src/utils/list";
import { useShowError } from "../../hooks/useShowError";
import { StationOption } from "./model";

interface Props {
  className?: string;
  selectedStationId: number | null;
  initialStationOptions?: BriefStation[];
  onChange?: (stationId: number | null) => void;
  onStationSelected?: (station: BriefStation | null) => void;
  disabled?: boolean;
  nullIfEmpty?: boolean;
}

export const LazyLoadStationSelectionDropdown = React.memo(
  (props: Props): ReactElement => {
    const {
      className,
      selectedStationId,
      initialStationOptions,
      disabled,
      onChange,
      onStationSelected,
      nullIfEmpty,
    } = props;
    const { t } = useTranslation();
    const { showError } = useShowError();
    const comboBoxRef =
      useRef<SingleSelectComboBoxElement<BriefStation> | null>(null);

    useEffect(() => {
      if (initialStationOptions != null) {
        for (const s of initialStationOptions) {
          comboBoxRef.current?.addOptionToMap({
            name: s.shortCode,
            value: s.id.toString(),
            object: s,
          });
        }
      }
    }, [initialStationOptions]);

    const [stations, setStations] = useState<BriefStation[]>(
      initialStationOptions ?? []
    );
    const [hasMoreStations, setHasMoreStations] = useState(false);
    const listStationArgs = useRef<ListStationArg>({
      pageIndex: 0,
      pageSize: DEFAULT_PAGE_SIZE,
    });
    const guardLoadMore = useRef(false);

    const [listStation, { isFetching }] = useListStation();

    const stationOptions = useMemo<StationOption[]>(() => {
      return stations.map((s) => ({
        value: s.id.toString(),
        name: s.shortCode,
        object: s,
      }));
    }, [stations]);

    const onStationLoaded = useCallback((data: ListStationResponse) => {
      setStations((prev) => dedup([...prev, ...data.results]));
      setHasMoreStations(data.nextPageIndex != null);
      listStationArgs.current = {
        ...listStationArgs.current,
        pageIndex: data.nextPageIndex ?? undefined,
      };
    }, []);

    const loadStation = useCallback(
      (query: string) => {
        setHasMoreStations(false);
        listStationArgs.current = {
          q: query.trim() !== "" ? query.trim() : undefined,
          pageIndex: 0,
          pageSize: DEFAULT_PAGE_SIZE,
        };
        guardLoadMore.current = true;
        setStations([]);
        listStation(listStationArgs.current, true)
          .unwrap()
          .then((data) => {
            onStationLoaded(data);
          })
          .catch((err) => {
            showError(err);
          })
          .finally(() => {
            guardLoadMore.current = false;
          });
      },
      [listStation, onStationLoaded, showError]
    );

    const loadMoreStation = useCallback(() => {
      const requestSearchString = listStationArgs.current.q;

      const targetPage = listStationArgs.current.pageIndex ?? 0;
      if (guardLoadMore.current) {
        return;
      }

      guardLoadMore.current = true;
      listStation(
        {
          ...listStationArgs.current,
          pageIndex: targetPage,
        },
        true
      )
        .unwrap()
        .then((data) => {
          // Search query updated, skip updating option list
          if (listStationArgs.current.q !== requestSearchString) {
            return;
          }
          onStationLoaded(data);
        })
        .catch((err) => {
          showError(err);
        })
        .finally(() => {
          guardLoadMore.current = false;
        });
    }, [listStation, onStationLoaded, showError]);

    const onSelectedOptionsChange = useCallback(
      (option: StationOption | null) => {
        onChange?.(option?.object.id ?? null);
        onStationSelected?.(option?.object ?? null);
      },
      [onChange, onStationSelected]
    );

    return (
      <SingleSelectComboBox<BriefStation>
        ref={comboBoxRef}
        className={className}
        options={stationOptions}
        selectedItem={selectedStationId?.toString() ?? null}
        placeholder={t("stationSelectionDropdown.placeholder")}
        isLoading={isFetching}
        hasMoreOptions={hasMoreStations}
        onLoadOptions={loadStation}
        onLoadMoreOptions={loadMoreStation}
        onOptionChange={onSelectedOptionsChange}
        isSameKeyUnselect={false}
        disabled={disabled}
        nullIfEmpty={nullIfEmpty}
      />
    );
  }
);
