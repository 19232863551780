import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { ShiftRateViewModel } from "oneclick-component/src/components/ShiftRateBadge";
import { ShiftType } from "oneclick-component/src/store/apis/enhancedApi";

export interface MultiShiftCreateFormPreviewType {
  workingStation: string;
  supportStation?: string;
  rate?: ShiftRateViewModel;
  ptType: string[];
  recurringType: string;
  dateDisplay: string;
  timeDisplay: string;
  fulfillmentCount: number;
  title: string;
  description: string;
  shiftType: ShiftType | null;
  isAppliableToAllAtT3: boolean;
}

const initialState: MultiShiftCreateFormPreviewType = {
  workingStation: "",
  supportStation: undefined,
  rate: undefined,
  ptType: [],
  recurringType: "",
  dateDisplay: "",
  timeDisplay: "",
  fulfillmentCount: 0,
  title: "",
  description: "",
  shiftType: null,
  isAppliableToAllAtT3: false,
};

const multiShiftCreateFormPreviewSlice = createSlice({
  name: "multiShiftCreateFormPreview",
  initialState,
  reducers: {
    updatePreview: (
      state,
      action: PayloadAction<MultiShiftCreateFormPreviewType>
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    clearPreview: (state) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { updatePreview, clearPreview } =
  multiShiftCreateFormPreviewSlice.actions;

export default multiShiftCreateFormPreviewSlice.reducer;
