import {
  ReactElement,
  useEffect,
  PropsWithChildren,
  useCallback,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { useLazyListTemplateMessageHandlerTemplateMessageGetQuery as useListTemplateMessage } from "oneclick-component/src/store/apis/enhancedApi";
import LoadingScreen from "oneclick-component/src/components/LoadingScreen";
import { loadedTemplateMessage } from "../store/templateMessage";

const TemplateMessageProvider = (props: PropsWithChildren): ReactElement => {
  const dispatch = useDispatch();
  const [isInitialized, setIsInitialized] = useState(false);
  const [listTemplateMessage, { isLoading: isLoadingTemplateMessage }] =
    useListTemplateMessage();

  const fetchTemplateMessages = useCallback(async () => {
    const data = await listTemplateMessage().unwrap();
    dispatch(
      loadedTemplateMessage({
        templateMessages: data.items,
      })
    );
    return data.items;
  }, [listTemplateMessage, dispatch]);

  // Load necessary data eagerly
  useEffect(() => {
    if (isInitialized || isLoadingTemplateMessage) {
      return;
    }
    fetchTemplateMessages()
      .catch((err) => {
        // TODO: app error boundary and generic error screen
        console.error(err);
        throw new Error("Failed to initialize app");
      })
      .finally(() => setIsInitialized(true));
    // Only execute once
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return isInitialized ? <>{props.children}</> : <LoadingScreen />;
};

export default TemplateMessageProvider;
