import React, { useCallback, useState } from "react";
import cn from "classnames";
import { Dialog } from "@headlessui/react";
import { Trans, useTranslation } from "react-i18next";
import {
  useListUserStationInviteRecordHandlerUserStationInviteRecordsGetQuery as useListInviteRecord,
  useReadUserStationInviteRecordHandlerUserStationInviteRecordsReadPostMutation as useReadInviteRecord,
} from "oneclick-component/src/store/apis/enhancedApi";
import { Modal } from "oneclick-component/src/components/Modal";
import { Button } from "oneclick-component/src/components/Button";
import useShowMessage from "oneclick-component/src/hooks/useShowMessage";
import { StationBadge } from "oneclick-component/src/components/Badge";
import { SwitchProfileImage, SwitchProfileArrowImage } from "../../image";

const UnreadInviteStationDialog = (): React.ReactElement | null => {
  const { t } = useTranslation();
  const showMessage = useShowMessage();
  const [isOpen, setIsOpen] = useState(true);

  const { data } = useListInviteRecord();
  const [read] = useReadInviteRecord();

  const onClose = useCallback(() => {
    if (data && data.items.length > 0) {
      showMessage({
        type: "success",
        title: t("inviteRecordDialog.success", {
          stationCodes: data.items
            .map((record) => record.station.shortCode)
            .join(", "),
        }),
      });
    }
    read()
      .unwrap()
      .catch(() => {});
    setIsOpen(false);
  }, [t, showMessage, data, read]);

  if (data == null || data.items.length === 0) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} hasXMarkButton={false}>
      <div className={cn("sm:flex", "sm:items-start", "w-full")}>
        <div className={cn("text-center", "sm:mt-0", "sm:text-left", "w-full")}>
          <Dialog.Title
            className={cn(
              "text-black",
              "text-lg",
              "font-medium",
              "items-center",
              "leading-6"
            )}
          >
            {t(
              data.items.length === 1
                ? "inviteRecordDialog.title.single"
                : "inviteRecordDialog.title.multiple",
              {
                last: t("inviteRecordDialog.title.badgeList", {
                  isLast: "true",
                  badge: (
                    <StationBadge
                      key="stationBadge"
                      className={cn("mx-1", "align-text-top")}
                      station={data.items[data.items.length - 1].station}
                      stationTeam={null}
                    />
                  ),
                }),
                badges: data.items
                  .filter((_, index) => index !== data.items.length - 1)
                  .map((record, index) =>
                    t("inviteRecordDialog.title.badgeList", {
                      isLast:
                        data.items.length > 1 && index === data.items.length - 2
                          ? "true"
                          : "false",
                      badge: (
                        <StationBadge
                          key={record.id}
                          className={cn("mx-1", "align-text-top")}
                          station={record.station}
                          stationTeam={null}
                        />
                      ),
                    })
                  ),
              }
            )}
          </Dialog.Title>
          <div className={cn("my-8", "text-center")}>
            <div className={cn("inline-block", "mx-auto", "relative", "mb-5")}>
              <SwitchProfileImage className="shadow-md" />
              <SwitchProfileArrowImage
                className={cn("absolute", "-bottom-4", "left-1/3")}
              />
            </div>
            <p className={cn("text-base", "font-normal", "text-black")}>
              <Trans i18nKey="inviteRecordDialog.description" />
            </p>
          </div>
        </div>
      </div>
      <div
        className={cn(
          "mt-5",
          "sm:mt-4",
          "flex",
          "flex-row",
          "justify-center",
          "sm:justify-end",
          "items-center"
        )}
      >
        <Button
          className={cn(
            "font-medium",
            "ml-3",
            "flex-1",
            "sm:flex-none",
            "sm:min-w-30"
          )}
          onClick={onClose}
        >
          <Trans i18nKey="inviteRecordDialog.action" />
        </Button>
      </div>
    </Modal>
  );
};

export default UnreadInviteStationDialog;
