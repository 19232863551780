import React, { ReactElement, useCallback, useMemo } from "react";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { Message, MeUser } from "oneclick-component/src/store/apis/enhancedApi";
import {
  formatDateTime,
  isoToDateTime,
} from "oneclick-component/src/utils/datetime";

import { LockIcon, RefreshIcon } from "../../../icon";
import { PTUserAvatarImage } from "../../PTUserAvatarImage";
import { MessageBubbleContent } from "./MessageBubbleContent";
import { MessageContentType } from "./type";
import UserAvatarImage from "../../UserAvatarImage";
import PopMenu, {
  PopMenuItem,
} from "oneclick-component/src/components/PopMenu";

function isMessageInbound(message: Message, meUser: MeUser | null) {
  // inbound <=> not sent by me
  if (message.ptUser != null) {
    return true;
  }
  return message.user?.id !== meUser?.id;
}

function renderUsername(message: Message) {
  if (message.ptUser != null) {
    return message.ptUser.fullNameZhHk;
  }
  if (message.user != null) {
    if (message.userStation != null) {
      const userStationCode = message.userStation.shortCode;
      return `${message.user.name} (${userStationCode})`;
    }
    return message.user.name;
  }
  return "-";
}

interface RetryButtonProps {
  onResendClick: () => void;
}

const RetryButton = React.memo((props: RetryButtonProps) => {
  const { onResendClick } = props;
  const { t } = useTranslation();

  const getButtonClasses = useCallback((_option: { open: boolean }) => {
    return cn(
      "rounded-full",
      "shadow-sm",
      "focus:ring-2",
      "focus:ring-offset-2",
      "focus:outline-0",
      "p-2",
      "bg-red-500",
      "hover:bg-red-700",
      "focus:ring-offset-white",
      "focus:ring-indigo-500",
      "text-white"
    );
  }, []);

  return (
    <div>
      <PopMenu
        menuClass="relative"
        controlElementClass={getButtonClasses}
        controlElement={
          <RefreshIcon
            className={cn("h-4", "w-4", "text-white", "stroke-2")}
            aria-hidden="true"
          />
        }
        menuBodyClass="right-0 z-40 !min-w-0 w-30"
      >
        <PopMenuItem
          type="button"
          onClick={onResendClick}
          text={t("chatDialog.messageItem.errorMenu.retry")}
        />
      </PopMenu>
    </div>
  );
});

interface MessageAvatarImageProps {
  username: string;
  message: Message;
}

const MessageAvatarImage = React.memo(
  (props: MessageAvatarImageProps): ReactElement | null => {
    const { message, username } = props;

    if (message.ptUser != null) {
      return (
        <PTUserAvatarImage
          ptUser={message.ptUser}
          size="32"
          show418Badge={false}
        />
      );
    }

    if (message.user != null) {
      return <UserAvatarImage user={message.user} name={username} size="32" />;
    }

    return null;
  }
);

interface ChatMessageBubbleProps {
  isInbound: boolean;
  contentType: MessageContentType;
  message: Message;
}

const ChatMessageBubble = (props: ChatMessageBubbleProps): ReactElement => {
  const { isInbound, contentType, message } = props;

  const shouldShowFullWidthBubble =
    contentType === "audio" || contentType === "document";

  const displayState = useMemo(() => {
    if (message.status === "failed") {
      return "error";
    }

    if (message.isPublic) {
      if (isInbound) {
        return "publicInbound";
      }
      return "publicOutbound";
    }

    return "private";
  }, [isInbound, message]);

  return (
    <div
      className={cn("inline-block", "rounded-lg", "max-w-full", {
        "bg-primary-100": displayState === "publicOutbound",
        "bg-gray-100": displayState === "publicInbound",
        "bg-primary-900 text-white": displayState === "private",
        "bg-white border border-red-500 text-black/86":
          displayState === "error",
        "w-full": shouldShowFullWidthBubble,
      })}
    >
      <MessageBubbleContent contentType={contentType} message={message} />
    </div>
  );
};

interface ChatMessageItemHeaderProps {
  username: string;
  userExtraInfo: string | null;
  isPublicMessage: boolean;
  isPrivateMode: boolean;
  isInbound: boolean;
  timestamp: DateTime;
}

const ChatMessageItemHeader = React.memo(
  (props: ChatMessageItemHeaderProps): ReactElement => {
    const {
      username,
      userExtraInfo,
      timestamp,
      isPublicMessage,
      isPrivateMode,
      isInbound,
    } = props;

    const formattedTimestamp = useMemo(() => {
      return formatDateTime(timestamp, "h:mm a");
    }, [timestamp]);

    return (
      <div
        className={cn("flex", "items-center", "mb-1", {
          "justify-start": isInbound,
          "justify-end": !isInbound,
        })}
      >
        <div>
          <span
            className={cn(
              "truncate",
              "overflow-hidden",
              "text-xs",
              "font-medium",
              {
                "text-white": isPrivateMode,
                "text-black/[.86]": !isPrivateMode,
              }
            )}
          >
            {userExtraInfo == null ? username : `${username} ${userExtraInfo}`}
          </span>
          <span
            className={cn("text-xs", "ml-2", {
              "text-white": isPrivateMode,
              "text-black/[.86]": !isPrivateMode,
            })}
          >
            {formattedTimestamp}
          </span>
          {!isPublicMessage ? (
            <LockIcon
              className={cn(
                "w-3",
                "h-3",
                "inline-block",
                "ml-1",
                "fill-primary-500"
              )}
            />
          ) : null}
        </div>
      </div>
    );
  }
);

interface MessageListChatMessageProps {
  meUser: MeUser | null;
  isPrivateMode: boolean;
  contentType: MessageContentType;
  message: Message;
  onResend: (message: Message) => void;
}

export const MessageListChatMessage = React.memo(
  (props: MessageListChatMessageProps): ReactElement => {
    const { meUser, isPrivateMode, contentType, message, onResend } = props;
    const { t } = useTranslation();

    const isInbound = useMemo(() => {
      return isMessageInbound(message, meUser);
    }, [meUser, message]);

    const username = useMemo(() => {
      return renderUsername(message);
    }, [message]);

    const userExtraInfo = useMemo(() => {
      // Add indicator for deleted PT user
      if (message.ptUser?.isDeleted) {
        return `(${t("chatDialog.deletedPtUser")})`;
      }
      return null;
    }, [message.ptUser, t]);

    const messageTimestamp = useMemo(() => {
      return isoToDateTime(message.createdAt);
    }, [message.createdAt]);

    const onResendClick = useCallback(() => {
      onResend(message);
    }, [message, onResend]);

    return (
      <li
        className={cn(
          "list-none",
          "px-5",
          "mb-5",
          "flex",
          "items-end",
          "gap-3",
          {
            "justify-start": isInbound,
            "justify-end": !isInbound,
          }
        )}
      >
        {isInbound ? (
          <MessageAvatarImage message={message} username={username} />
        ) : null}
        <div className={cn("max-w-[85%]", "w-[85%]")}>
          <ChatMessageItemHeader
            username={username}
            userExtraInfo={userExtraInfo}
            timestamp={messageTimestamp}
            isPrivateMode={isPrivateMode}
            isPublicMessage={message.isPublic}
            isInbound={isInbound}
          />
          <div
            className={cn("flex", "gap-3", "items-end", {
              "justify-start": isInbound,
              "justify-end": !isInbound,
            })}
          >
            <ChatMessageBubble
              isInbound={isInbound}
              contentType={contentType}
              message={message}
            />
            {message.status === "failed" ? (
              <RetryButton onResendClick={onResendClick} />
            ) : null}
          </div>
        </div>
      </li>
    );
  }
);
