import { ReactElement, useMemo, useRef, useCallback, useEffect } from "react";
import cn from "classnames";
import { useLocation } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Button, ButtonLink } from "oneclick-component/src/components/Button";
import {
  useCreateMasterShiftHandlerMasterShiftsPostMutation as useCreateMasterShift,
  useGetPtUsersRolesHandlerPartTimeUsersRolesGetQuery,
  CreateMasterShiftRequest,
} from "oneclick-component/src/store/apis/enhancedApi";
import usePromptPageLeave from "oneclick-component/src/hooks/usePromptPageLeave";
import { isoToDateTime } from "oneclick-component/src/utils/datetime";
import { makeShiftDisplayInterval } from "oneclick-component/src/utils/shift";
import useShowMessage from "oneclick-component/src/hooks/useShowMessage";
import { MasterShiftTimePattern } from "oneclick-component/src/models/shiftTimePattern";
import { useShowError } from "../../hooks/useShowError";
import { MultiShiftFormValues } from "../../components/MultiShiftForm/form";
import { getDateTimeSlotsFromRecurringOptions } from "../../utils/recurring";
import { RootState } from "../../store/store";
import { ptRolesGroup } from "../../constants/ptRolesGroup";
import { clear } from "../../store/multiShiftCreateForm";
import { clearPreview } from "../../store/multiShiftPreview";
import StepBar from "../../components/StepBar";
import AppRoutes from "../../routes/AppRoutes";
import { showErrorScreen } from "../../utils/error";
import MultiShiftPreviewDetail from "./MultiShiftPreviewDetail";

interface TimeRange {
  dutyStartTime: string;
  dutyEndTime: string | null;
  isOvernight: boolean;
}

const makeMasterShiftTimePattern = (
  shift: MultiShiftFormValues,
  shiftTimeRanges: TimeRange[]
): MasterShiftTimePattern => {
  let dateTimeSlots: MasterShiftTimePattern;
  if (shift.recurringOptions.recurringType === "single") {
    dateTimeSlots = {
      recurring: false,
      shiftStartDates: [shift.recurringOptions.date],
      shiftTimeRanges: shiftTimeRanges,
    };
  } else if (shift.recurringOptions.recurringType === "repeat") {
    dateTimeSlots = {
      recurring: true,
      frequency: "weekly",
      byWeekday: shift.recurringOptions.weekdays
        .map((w, i) => (w ? i : null))
        .filter((w) => w) as number[],
      recurrenceStartDate: shift.recurringOptions.startDate,
      recurrenceEndDate: shift.recurringOptions.endDate,
      shiftTimeRanges: shiftTimeRanges,
    };
  } else {
    dateTimeSlots = {
      recurring: false,
      shiftStartDates: shift.recurringOptions.dates.map((d) => d.date),
      shiftTimeRanges: shiftTimeRanges,
    };
  }

  return dateTimeSlots;
};

const CreateMultiShiftPreviewScreen = (): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { showError } = useShowError();
  const showMessage = useShowMessage();
  const needReset = useRef<boolean>(false);
  const shift = useSelector((state: RootState) => {
    return state.multiShiftCreateForm;
  });
  const shiftPreview = useSelector((state: RootState) => {
    return state.multiShiftCreateFormPreview;
  });

  const {
    data: ptUserRoles,
    isLoading: isPtUserRolesLoading,
    error: getPtUserRolesError,
  } = useGetPtUsersRolesHandlerPartTimeUsersRolesGetQuery();

  const [createMasterShift, { isLoading }] = useCreateMasterShift();

  const resetForm = useCallback(() => {
    if (needReset.current) {
      dispatch(clear());
      dispatch(clearPreview());
    }
  }, [needReset, dispatch]);

  const ptRolesMap: Record<string, number> = useMemo(() => {
    const map: Record<string, number> = {};

    if (ptUserRoles != null) {
      for (const role of ptUserRoles.ptUserRoles) {
        map[role.name] = role.id;
      }
    }

    return map;
  }, [ptUserRoles]);

  const nonBlockNavigate = usePromptPageLeave(shift.stationId > 0, resetForm);

  const datetimeRanges = useMemo(() => {
    const allRanges = shift.timeRanges.filter(
      (range) =>
        range.startTime.hour !== ("" as any as number) &&
        range.startTime.minute !== ("" as any as number)
    );
    if (allRanges.length === 0) {
      return [];
    }
    const slots = getDateTimeSlotsFromRecurringOptions(
      shift.recurringOptions,
      allRanges
    );
    return slots;
  }, [shift]);

  const orderedDates: string[] = useMemo(() => {
    let orderedDates = [];
    if (shift.recurringOptions.recurringType === "single") {
      orderedDates = [shift.recurringOptions.date];
    } else if (shift.recurringOptions.recurringType === "repeat") {
      orderedDates = [
        shift.recurringOptions.startDate,
        shift.recurringOptions.endDate,
      ];
    } else {
      const orderedDate = [...shift.recurringOptions.dates];
      orderedDate.sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
      );
      orderedDates = orderedDate.map((d) => d.date);
    }
    return orderedDates;
  }, [shift]);

  const shiftTimeRanges: TimeRange[] = useMemo(() => {
    const timeRanges = [];
    const startDate = orderedDates[0];
    for (const timeRange of shift.timeRanges) {
      let startDateTime = isoToDateTime(startDate);
      let endDateTime = null;

      startDateTime = startDateTime.set(timeRange.startTime);
      if (timeRange.endTime.hour != null && timeRange.endTime.minute != null) {
        endDateTime = isoToDateTime(startDate);
        endDateTime = endDateTime.set({
          hour: timeRange.endTime.hour,
          minute: timeRange.endTime.minute,
        });
      }

      const dateString = makeShiftDisplayInterval(
        startDateTime.toISO()!,
        endDateTime?.toISO() ?? null
      );
      timeRanges.push({
        dutyStartTime: dateString[1].startTime,
        dutyEndTime:
          dateString[1].endTime !== "" ? dateString[1].endTime : null,
        isOvernight: dateString[1].overnight !== "",
      });
    }

    timeRanges.sort((a, b) => (a.dutyStartTime <= b.dutyStartTime ? -1 : 1));

    return timeRanges;
  }, [shift, orderedDates]);

  const onCreateClick = useCallback(() => {
    if (datetimeRanges.length === 0 || isPtUserRolesLoading) {
      return;
    }

    const dateTimeSlots: MasterShiftTimePattern = makeMasterShiftTimePattern(
      shift,
      shiftTimeRanges
    );

    const requestParams: CreateMasterShiftRequest = {
      fulfillmentCount: shift.fulfillmentCount,
      shiftTitle: shift.title,
      shiftDescription: shift.description,
      shiftRateId: shift.rate,
      ptUserRoleIds: [],
      workingStationId: shift.stationId,
      shiftDatetimeRanges: datetimeRanges,
      shiftDatetimeSlots: dateTimeSlots,
      shiftType: shift.shiftType,
      isAppliableToAllAtT3: shift.isAppliableToAllAtT3,
    };

    if (shift.shiftType === "INCIDENT") {
      requestParams.supportStationId = shift.supportStationId;
    }

    requestParams.ptUserRoleIds = shift.ptType.reduce(
      (roles: number[], inputType) => {
        let addRole: number[] = [];
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (ptRolesGroup[inputType] != null) {
          addRole = ptRolesGroup[inputType].includeRoles.map(
            (roleName) => ptRolesMap[roleName]
          );
        } else {
          addRole = [ptRolesMap[inputType]];
        }
        return [...roles, ...addRole];
      },
      []
    );

    createMasterShift({
      createMasterShiftRequest: requestParams,
    })
      .unwrap()
      .then((data) => {
        showMessage({
          title: t("shift.create.toast.success.title"),
          message: t("shift.create.toast.success.message"),
          type: "success",
          showDismiss: true,
        });
        dispatch(clear());
        dispatch(clearPreview());

        nonBlockNavigate(
          AppRoutes.MultiShiftInvitationScreen.render(data.masterShift.id)
        );
      })
      .catch((e) => {
        showError(e, t("shift.cancel.toast.fail.title"));
      });
  }, [
    shift,
    datetimeRanges,
    shiftTimeRanges,
    ptRolesMap,
    isPtUserRolesLoading,
    showError,
    showMessage,
    t,
    createMasterShift,
    dispatch,
    nonBlockNavigate,
  ]);

  const navigateEdit = useCallback(() => {
    nonBlockNavigate(AppRoutes.CreateMultiShiftScreen.path);
  }, [nonBlockNavigate]);

  // workaround development mode + strict mode will call useEffect unsubscribe twice
  // which the onpage unload reset form action need to be performed when the page unmount
  // https://react.dev/blog/2022/03/08/react-18-upgrade-guide#updates-to-strict-mode
  useEffect(() => {
    setTimeout(() => {
      needReset.current = true;
    }, 1);
  }, [location.key, needReset]);

  if (getPtUserRolesError != null) {
    return showErrorScreen(getPtUserRolesError);
  }

  return (
    <main className={"pb-20"}>
      <StepBar
        className="max-w-160"
        displays={[
          t("shift.create.multiple.step1"),
          t("shift.create.multiple.step2"),
          t("shift.create.multiple.step3"),
        ]}
        step={1}
      />
      <MultiShiftPreviewDetail
        className={cn(
          "rounded-lg",
          "bg-white",
          "max-w-169",
          "mx-auto",
          "shadow-md"
        )}
        title={shiftPreview.title}
        description={shiftPreview.description}
        fulfillment={`0/${shiftPreview.fulfillmentCount}`}
        rate={shiftPreview.rate}
        recurringType={shiftPreview.recurringType}
        partTimeType={shiftPreview.ptType}
        workingStation={shiftPreview.workingStation}
        supportStation={shiftPreview.supportStation}
        shiftCount={datetimeRanges.length}
        dateDisplay={shiftPreview.dateDisplay}
        timeDisplay={shiftPreview.timeDisplay}
        shiftType={shiftPreview.shiftType}
        showIncidentBanner={true}
        isAppliableToAllAtT3={shiftPreview.isAppliableToAllAtT3}
      />
      <footer
        className={cn(
          "fixed",
          "bottom-0",
          "left-0",
          "bg-white",
          "w-full",
          "h-16",
          "flex",
          "justify-end",
          "px-6",
          "py-3"
        )}
      >
        <ButtonLink
          theme={"white"}
          className={"mr-3 text-sm"}
          to={AppRoutes.ShiftListScreen.render()}
        >
          <Trans i18nKey="shift.create.footer.button.cancel" />
        </ButtonLink>
        <Button
          type="button"
          theme={"white"}
          className={"mr-3 text-sm"}
          onClick={navigateEdit}
          disabled={isLoading}
        >
          <Trans i18nKey="shift.create.preview.editShift" />
        </Button>
        <Button
          type="button"
          onClick={onCreateClick}
          disabled={isLoading || datetimeRanges.length === 0}
        >
          <Trans i18nKey="shift.create.footer.button.next" />
        </Button>
      </footer>
    </main>
  );
};

export default CreateMultiShiftPreviewScreen;
