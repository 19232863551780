import { RRule, ByWeekday } from "rrule";
import { DateTime } from "luxon";
import { ShiftTimeRange } from "oneclick-component/src/store/apis/enhancedApi";
import {
  isoToDateTime,
  jsDateToDateTime,
} from "oneclick-component/src/utils/datetime";
import { RecurringOptionsType } from "../components/MultiShiftForm/form";

const ruleWeekday: ByWeekday[] = [
  RRule.MO,
  RRule.TU,
  RRule.WE,
  RRule.TH,
  RRule.FR,
  RRule.SA,
  RRule.SU,
];

export const getDateTimeSlotsFromRecurringOptions = (
  options: RecurringOptionsType,
  timeRanges: {
    startTime: {
      hour: number;
      minute: number;
    };
    endTime: {
      hour: number | null;
      minute: number | null;
    };
  }[]
  // eslint-disable-next-line sonarjs/cognitive-complexity
): ShiftTimeRange[] => {
  const slots: ShiftTimeRange[] = [];
  let dates: DateTime[] = [];
  if (options.recurringType === "single") {
    dates.push(isoToDateTime(options.date));
  } else if (options.recurringType === "repeat") {
    const start = isoToDateTime(options.startDate);
    const end = isoToDateTime(options.endDate);
    const endPlusOneDay = end.plus({ days: 1 });
    const byweekday: ByWeekday[] = options.weekdays
      .map((v, i) => (v ? ruleWeekday[i] : null))
      .filter((w) => w) as ByWeekday[];

    const rule = new RRule({
      freq: RRule.WEEKLY,
      interval: 1,
      byweekday: byweekday,
      byhour: [0],
      dtstart: new Date(start.toISO() ?? ""),
      until: new Date(endPlusOneDay.toISO() ?? ""),
    });

    dates = rule.all().map((d) => jsDateToDateTime(d));
  } else {
    dates = options.dates.map((d) => isoToDateTime(d.date));
  }

  for (const date of dates) {
    for (const range of timeRanges) {
      let dtStart = date;
      let dtEnd = null;
      dtStart = dtStart.set({
        hour: range.startTime.hour,
        minute: range.startTime.minute,
      });
      if (range.endTime.hour != null && range.endTime.minute != null) {
        dtEnd = date;
        dtEnd = dtEnd.set({
          hour: range.endTime.hour,
          minute: range.endTime.minute,
        });
      }

      slots.push({
        dutyStartTime: dtStart.toISO()!,
        dutyEndTime: dtEnd?.toISO() ?? null,
      });
    }
  }

  return slots;
};
