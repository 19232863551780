import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import { ReactElement } from "react";
import cn from "classnames";

const AuthCallbackScreen = (): ReactElement => {
  return (
    <main
      className={cn(
        "bg-white",
        "w-full",
        "h-full",
        "flex",
        "items-center",
        "justify-center"
      )}
    >
      <LoadingSpinner size="l" />
    </main>
  );
};

export default AuthCallbackScreen;
