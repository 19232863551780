import { DateTime } from "luxon";
import { Message } from "oneclick-component/src/store/apis/enhancedApi";

export type MessageContentType =
  | "text"
  | "image"
  | "video"
  | "audio"
  | "document";

export function resolveMessageContentType(
  message: Message
): MessageContentType {
  if (message.attachments.length === 0) {
    return "text";
  }
  if (message.attachments.length > 1) {
    return "document";
  }
  const attachment = message.attachments[0];
  switch (attachment.attachmentType) {
    case "image":
      return "image";
    case "video":
      return "video";
    case "audio":
      return "audio";
    default:
      return "document";
  }
}

interface MessageListSystemMessageDisplayItem {
  type: "systemMessage";
  contentType: MessageContentType;
  message: Message;
}

interface MessageListChatMessageDisplayItem {
  type: "chatMessage";
  contentType: MessageContentType;
  message: Message;
}

interface MessageListDateSeparatorDisplayItem {
  type: "dateSeparator";
  date: DateTime;
}

interface MessageListChatExpiredIndicatorDisplayItem {
  type: "chatExpiredIndicator";
  expireDateTime: DateTime | null;
}

export type MessageListDiaplayItemData =
  | MessageListSystemMessageDisplayItem
  | MessageListChatMessageDisplayItem
  | MessageListDateSeparatorDisplayItem
  | MessageListChatExpiredIndicatorDisplayItem;

export function resolveMessageDisplayItemType(
  message: Message
): "systemMessage" | "chatMessage" {
  switch (message.type) {
    case "system":
      return "systemMessage";
    case "chat":
      return "chatMessage";
  }
}
