import React, { useMemo, useCallback, useContext } from "react";
import cn from "classnames";
import { UseFormSetValue, FieldErrors, Control } from "react-hook-form";
import { RadioGroup } from "@headlessui/react";
import { Trans, useTranslation } from "react-i18next";
import {
  FormSelect,
  FormTextInput,
  FormField,
  FormTextArea,
} from "oneclick-component/src/components/forms";
import { Option, Select } from "oneclick-component/src/components/inputs";
import { dateTimeNow } from "oneclick-component/src/utils/datetime";
import { WorkingStation } from "oneclick-component/src/store/apis/enhancedApi";
import { RadioInput } from "oneclick-component/src/components/inputs/RadioInput";
import SingleDateSection from "./DateSection/SingleDateSection";
import RepeatDateSection from "./DateSection/RepeatDateSection";
import CustomDateSection from "./DateSection/CustomDateSection";
import { MultiShiftFormValues } from "./form";
import {
  EagerLoadWorkingStationSelectionDropdown,
  LazyLoadWorkingStationSelectionDropdown,
} from "../WorkingStationSelectionDropdown";
import { useIsSuperAdmin } from "../../hooks/role";
import { useSelectedStationProfile } from "../../hooks/useSelectedStationProfile";
import { FeatureConfigContext } from "../../providers/FeatureConfigProvider";
import useMeUser from "../../hooks/useMeUser";
import { ToggleSwitch } from "oneclick-component/src/components/ToggleSwitch";

interface Props
  extends React.DetailedHTMLProps<
    React.FormHTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  > {
  setValue: UseFormSetValue<MultiShiftFormValues>;
  errors: FieldErrors<MultiShiftFormValues>;
  control: Control<MultiShiftFormValues>;
  formValues: MultiShiftFormValues;
  onWorkingStationSelected: (workingStation: WorkingStation | null) => void;
  partTimeGroupOptions: Option<string>[];
  shiftRateOptions: Option<number>[];
  onSubmit: () => void;
  isEditing: boolean;
}

const RegularFormContent = (props: Props): React.ReactElement => {
  const { shouldShowT3Feature } = useContext(FeatureConfigContext);
  const {
    formValues,
    setValue,
    errors,
    control,
    onWorkingStationSelected,
    partTimeGroupOptions,
    shiftRateOptions,
    isEditing,
  } = props;
  const { t } = useTranslation();
  const meUser = useMeUser();
  const selectedStationProfile = useSelectedStationProfile();
  const isSuperAdmin = useIsSuperAdmin();

  const now = useMemo(() => dateTimeNow(), []);

  const setTimeRangeStartMinuteByIndex = useCallback(
    (index: number, value: number) => {
      setValue(`timeRanges.${index}.startTime.minute`, value);
    },
    [setValue]
  );

  const setTimeRangeEndMinuteByIndex = useCallback(
    (index: number, value: number | null) => {
      setValue(`timeRanges.${index}.endTime.minute`, value);
    },
    [setValue]
  );

  const handlePtTypeSelect = useCallback(
    (role: string) => {
      setValue("ptType", [role]);
    },
    [setValue]
  );

  const onChangeRecurringType = useCallback(
    (updatedRecurringType: string) => {
      if (updatedRecurringType === "single") {
        setValue("recurringOptions", {
          recurringType: "single",
          date: now.toFormat("yyyy-LL-dd"),
        });
      } else if (updatedRecurringType === "repeat") {
        setValue("recurringOptions", {
          recurringType: "repeat",
          weekdays: [true, true, true, true, true, true, true],
          startDate: now.toFormat("yyyy-LL-dd"),
          endDate: now.toFormat("yyyy-LL-dd"),
        });
      } else if (updatedRecurringType === "custom") {
        setValue("recurringOptions", {
          recurringType: "custom",
          dates: [],
        });
      }
    },
    [setValue, now]
  );

  const dateSection = useMemo(() => {
    if (formValues.recurringOptions.recurringType === "single") {
      return (
        <SingleDateSection
          now={now}
          errors={errors}
          isEditing={isEditing}
          control={control}
          setTimeRangeStartMinuteByIndex={setTimeRangeStartMinuteByIndex}
          setTimeRangeEndMinuteByIndex={setTimeRangeEndMinuteByIndex}
        />
      );
    } else if (formValues.recurringOptions.recurringType === "repeat") {
      return (
        <RepeatDateSection
          now={now}
          errors={errors}
          endDate={formValues.recurringOptions.endDate}
          isEditing={isEditing}
          control={control}
          setTimeRangeStartMinuteByIndex={setTimeRangeStartMinuteByIndex}
          setTimeRangeEndMinuteByIndex={setTimeRangeEndMinuteByIndex}
        />
      );
    }
    return (
      <CustomDateSection
        now={now}
        errors={errors}
        isEditing={isEditing}
        control={control}
        setTimeRangeStartMinuteByIndex={setTimeRangeStartMinuteByIndex}
        setTimeRangeEndMinuteByIndex={setTimeRangeEndMinuteByIndex}
      />
    );
  }, [
    formValues,
    now,
    errors,
    isEditing,
    control,
    setTimeRangeStartMinuteByIndex,
    setTimeRangeEndMinuteByIndex,
  ]);

  const workingStationOptions = useMemo(() => {
    if (isSuperAdmin || meUser == null) {
      return [];
    }
    if (selectedStationProfile != null) {
      return selectedStationProfile.station.workingStations;
    }
    return meUser.stations.flatMap((s) => s.workingStations);
  }, [meUser, selectedStationProfile, isSuperAdmin]);

  return (
    <div className={cn("flex", "flex-col", "gap-y-5")}>
      <div className={cn("flex", "flex-row", "gap-x-5")}>
        <FormField.Control key="stationId" name="stationId" control={control}>
          {({ field }) => (
            <FormField.Container
              className="flex-1"
              label={t("shift.create.form.station")}
              errorMessage={errors.stationId?.message}
              required={true}
            >
              {isSuperAdmin ? (
                <LazyLoadWorkingStationSelectionDropdown
                  selectedWorkingStationId={field.value}
                  onWorkingStationSelected={onWorkingStationSelected}
                  onChange={field.onChange}
                  disabled={isEditing}
                />
              ) : (
                <EagerLoadWorkingStationSelectionDropdown
                  workingStations={workingStationOptions}
                  selectedWorkingStationId={field.value}
                  onWorkingStationSelected={onWorkingStationSelected}
                  onChange={field.onChange}
                  disabled={isEditing || workingStationOptions.length <= 1}
                />
              )}
            </FormField.Container>
          )}
        </FormField.Control>
        <FormSelect
          className="flex-1"
          options={shiftRateOptions}
          name="rate"
          control={control}
          label={t("shift.create.form.shiftRate")}
          required={true}
          errorMessage={errors.rate?.message}
          placeholder={t("shift.create.form.shiftRate.placeholder")}
        />
      </div>
      <div className={cn("flex", "flex-col", "md:flex-row", "gap-5")}>
        <FormField.Control name="ptType" control={control}>
          {({ field }) => (
            <FormField.Container
              label={t("shift.create.form.partTimeType")}
              errorMessage={errors.ptType?.message}
              required={true}
              className="flex-1"
            >
              <Select
                disabled={isEditing}
                options={partTimeGroupOptions}
                placeholder={t("shift.create.form.partTimeType")}
                value={field.value[0]}
                hasError={errors.ptType?.message != null}
                onChange={handlePtTypeSelect}
              />
            </FormField.Container>
          )}
        </FormField.Control>
        <FormTextInput
          className="flex-1"
          name={"fulfillmentCount"}
          control={control}
          label={t("shift.create.form.fulfillmentCount")}
          required={true}
          type="number"
          min={1}
          errorMessage={errors.fulfillmentCount?.message}
        />
      </div>
      <div className="mt-5">
        <div className="mb-5">
          <FormField.Control
            name={"recurringOptions.recurringType"}
            control={control}
          >
            {({ field }) => (
              <RadioGroup
                {...field}
                onChange={onChangeRecurringType}
                className={cn("flex", "flex-row", "gap-x-20")}
              >
                <RadioGroup.Option value="single">
                  {({ active, checked }) => (
                    <RadioInput
                      className="text-sm"
                      active={active}
                      checked={checked}
                      label={t("shift.create.multiple.recurringType.single")}
                    />
                  )}
                </RadioGroup.Option>
                <RadioGroup.Option value="repeat">
                  {({ active, checked }) => (
                    <RadioInput
                      className="text-sm"
                      active={active}
                      checked={checked}
                      label={t("shift.create.multiple.recurringType.repeat")}
                    />
                  )}
                </RadioGroup.Option>
                <RadioGroup.Option value="custom">
                  {({ active, checked }) => (
                    <RadioInput
                      className="text-sm"
                      active={active}
                      checked={checked}
                      label={t("shift.create.multiple.recurringType.custom")}
                    />
                  )}
                </RadioGroup.Option>
              </RadioGroup>
            )}
          </FormField.Control>
          <p className={cn("text-red-500", "text-sm", "mt-2")}>
            <Trans i18nKey="shift.create.form.multiShiftRemind" />
          </p>
        </div>
        {dateSection}
      </div>
      {shouldShowT3Feature ? (
        <div className={cn("my-5", "flex", "items-center", "gap-x-4")}>
          <FormField.Control name={"isAppliableToAllAtT3"} control={control}>
            {({ field }) => (
              <ToggleSwitch
                label={t("shift.create.form.isAppliableToAllAtT3.label")}
                inputClassName={cn(
                  "checked:bg-primary-500",
                  "hover:checked:bg-primary-500",
                  "focus:ring-primary-500",
                  "focus:checked:bg-primary-500",
                  "h-6",
                  "!w-11",
                  "after:ml-0.5",
                  "after:mt-0.5",
                  "checked:after:mt-0.5",
                  "checked:after:ml-5.5",
                  "focus:!ring-offset-4"
                )}
                labelClassName={cn(
                  "!text-base",
                  "!leading-6",
                  "!text-black/86",
                  "!font-normal"
                )}
                className={cn("flex", "items-center")}
                isChecked={field.value}
                onToggle={field.onChange}
              />
            )}
          </FormField.Control>
          <span className={cn("text-sm", "leading-5", "text-black/86")}>
            <Trans i18nKey="shift.create.form.isAppliableToAllAtT3.description" />
          </span>
        </div>
      ) : null}
      <FormTextInput
        name={"title"}
        control={control}
        label={t("shift.create.form.title")}
        required={true}
        errorMessage={errors.title?.message}
        placeholder={t("shift.create.form.title.placeholder")}
      />
      <FormTextArea
        className={"w-full"}
        name={"description"}
        control={control}
        rows={15}
        label={t("shift.create.form.description")}
        required={true}
        errorMessage={errors.description?.message}
        placeholder={t("shift.create.form.description.placeholder")}
      />
    </div>
  );
};

export default RegularFormContent;
