import React, { useCallback, useMemo } from "react";
import cn from "classnames";
import { Modal } from "oneclick-component/src/components/Modal";
import { Trans } from "react-i18next";
import { Dialog } from "@headlessui/react";
import { Button } from "oneclick-component/src/components/Button";

interface Props {
  setShowDialog: (show: boolean) => void;
  onSend: () => void;
  isSelectedAllStations: boolean;
  isIncident: boolean;
  isOpen: boolean;
}

export const ConfirmSendAllStationsDialog = (
  props: Props
): React.ReactElement => {
  const { setShowDialog, isOpen, onSend, isSelectedAllStations, isIncident } =
    props;

  const onClickConfirm = useCallback(() => {
    setShowDialog(false);
    onSend();
  }, [setShowDialog, onSend]);

  const handleOnClose = useCallback(() => {
    setShowDialog(false);
  }, [setShowDialog]);

  const warningMessageKey = useMemo(() => {
    if (isIncident) {
      if (isSelectedAllStations) {
        return "confirmSendAllStations.dialog.incident.title";
      }
      return "confirmSendAllStations.dialog.incident.notAll.title";
    }
    if (isSelectedAllStations) {
      return "confirmSendAllStations.dialog.title";
    }
    return "confirmSendAllStations.dialog.notAll.title";
  }, [isSelectedAllStations, isIncident]);

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose} hasXMarkButton={true}>
      {isIncident ? (
        <div className={cn("w-full", "text-center")}>
          <p className={cn("mt-8", "text-red-600", "mb-2", "text-7xl")}>!!</p>
          <p className={cn("text-red-600", "text-2xl", "mb-6")}>
            <Trans i18nKey="confirmSendAllStations.dialog.incident" />
          </p>
          <p className="mb-9">
            <Trans
              i18nKey={warningMessageKey}
              components={{
                underline: <span className={cn("underline", "font-medium")} />,
              }}
            />
          </p>
        </div>
      ) : (
        <div className={cn("sm:flex", "sm:items-start", "w-full")}>
          <div
            className={cn(
              "mt-3",
              "text-center",
              "sm:mt-0",
              "sm:text-left",
              "w-full"
            )}
          >
            <Dialog.Title
              className={cn("text-black", "text-lg", "font-medium")}
            >
              <Trans i18nKey={warningMessageKey} />
            </Dialog.Title>
          </div>
        </div>
      )}
      <div
        className={cn(
          "mt-5",
          "sm:mt-4",
          "flex",
          "flex-row",
          "justify-center",
          "sm:justify-end",
          "items-center"
        )}
      >
        <Button
          className={cn(
            "font-medium",
            "flex-1",
            "self-stretch",
            "sm:flex-none"
          )}
          onClick={handleOnClose}
          theme="white"
        >
          <Trans i18nKey="confirmSendAllStations.dialog.action.cancel" />
        </Button>
        <Button
          className={cn("font-medium", "ml-3", "flex-1", "sm:flex-none")}
          theme="primary"
          onClick={onClickConfirm}
        >
          <Trans i18nKey="confirmSendAllStations.dialog.action.confirm" />
        </Button>
      </div>
    </Modal>
  );
};
