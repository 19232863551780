import React from "react";
import cn from "classnames";
import { ShiftRateViewModel } from "./model";

interface Props {
  rate: ShiftRateViewModel;
  className?: string;
  isHideCco?: boolean;
}

const ShiftRateBadge = (props: Props): React.ReactElement => {
  const { rate, className, isHideCco } = props;
  const { code, colorHexcode } = rate;

  if (code === "CCO" && isHideCco) {
    return <></>;
  }

  if (code === "CCO") {
    return (
      <span
        className={cn(
          "leading-3",
          "text-[11px]",
          "text-center",
          "inline",
          "font-normal",
          "align-middle",
          className
        )}
      >
        <span
          className={cn(
            "ring-1",
            "ring-inset",
            "py-1",
            "pl-[5px]",
            "pr-[1px]",
            "ring-green-600",
            "text-green-600"
          )}
        >
          N
        </span>
        <span className={cn("py-1", "pr-1.5", "bg-green-600", "text-white")}>
          R
        </span>
      </span>
    );
  }

  return (
    <span
      className={cn(
        "leading-3",
        "min-w-7",
        "py-1",
        "text-[11px]",
        "text-center",
        "inline",
        "font-normal",
        "text-white",
        "align-middle",
        className
      )}
      style={{
        backgroundColor: `#${colorHexcode}`,
      }}
    >
      {code}
    </span>
  );
};

export default ShiftRateBadge;
