interface Config {
  apiBaseUrl: string;
  wsBaseUrl: string;
  msalClientId: string;
  msalAuthority: string;
  timezone: string;
  whatsAppReplyWindowMs: number;
  staticAssetBaseUrl?: string;
  sentry?: {
    dsn: string;
  };
  environment: string;
  version: string;
}

const defaultConfig: Partial<Config> = {
  timezone: "UTC+8",
  whatsAppReplyWindowMs: 24 * 60 * 60 * 1000, // 1 days
  environment: "LOCAL",
};

export const config: Config = {
  ...defaultConfig,
  ...window.appConfig,
};
