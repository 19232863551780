import React, {
  ButtonHTMLAttributes,
  PropsWithChildren,
  ReactElement,
} from "react";
import cn from "classnames";
import { Theme, themes } from "./theme";
import { LoadingSpinner } from "../LoadingSpinner";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  iconClassName?: string;
  flashyClassName?: string;
  prefixIcon?: React.ComponentType<any>;
  suffixIcon?: React.ComponentType<any>;
  theme?: Theme;
  isLoading?: boolean;
  isFlashy?: boolean;
}

export function Button(props: PropsWithChildren<ButtonProps>): ReactElement {
  const {
    children,
    className,
    iconClassName,
    flashyClassName,
    prefixIcon,
    suffixIcon,
    theme = "primary",
    isLoading = false,
    disabled,
    isFlashy = false,
    ...rest
  } = props;
  const { buttonThemeClassName, iconThemeClassName } = themes[theme];
  const Prefix = prefixIcon;
  const Suffix = suffixIcon;
  return (
    <button
      type="button"
      className={cn(
        "relative",
        "inline-flex",
        "justify-center",
        "items-center",
        "gap-x-1.5",
        "rounded-md",
        "shadow-sm",
        "focus:ring-2",
        "focus:ring-offset-2",
        "focus:outline-0",
        "px-4",
        "py-2",
        "font-medium",
        "text-sm",
        buttonThemeClassName,
        className
      )}
      disabled={disabled ?? isLoading}
      {...rest}
    >
      {isLoading ? (
        <LoadingSpinner className={cn("!h-5", "!w-5")} />
      ) : (
        <>
          {isFlashy ? (
            <span
              className={cn(
                "rounded-md",
                "absolute",
                "pointer-events-none",
                "animate-flashy-background",
                "h-full",
                "w-full",
                "opacity-75",
                "bg-white",
                flashyClassName
              )}
            ></span>
          ) : null}
          {Prefix != null ? (
            <Prefix
              className={cn(
                "-ml-0.5",
                "h-5",
                "w-5",
                "stroke-2",
                iconClassName,
                iconThemeClassName
              )}
              aria-hidden="true"
            />
          ) : null}
          {children}
          {Suffix != null ? (
            <Suffix
              className={cn(
                "-mr-0.5",
                "h-5",
                "w-5",
                "stroke-2",
                iconClassName,
                iconThemeClassName
              )}
              aria-hidden="true"
            />
          ) : null}
        </>
      )}
    </button>
  );
}
