import { ReactElement } from "react";
import cn from "classnames";
import { CheckIcon } from "@heroicons/react/24/solid";

interface Props {
  displays: string[];
  step: number;
  className?: string;
}

const StepBar = (props: Props): ReactElement => {
  const { displays, step, className } = props;

  if (displays.length === 0) {
    return <></>;
  }

  return (
    <div
      className={cn(
        "mx-auto",
        "justify-center",
        "items-center",
        "mb-8",
        "relative",
        "flex",
        "flex-row",
        className
      )}
    >
      {displays.map((_, index) => {
        if (index === 0) {
          return null;
        }
        return (
          <div
            key={`${index}`}
            className={cn("absolute", "h-[6px]", "top-4", "-translate-x-1/2", {
              "bg-gray-200": index > step,
              "bg-primary-500": index <= step,
            })}
            style={{
              width: `${(1 / displays.length) * 100}%`,
              left: `${(1 / displays.length) * 100 * index}%`,
            }}
          />
        );
      })}
      {displays.map((displayStr, index) => (
        <div
          key={`${index}`}
          className={cn(
            "flex",
            "flex-col",
            "items-center",
            "relative",
            "flex-1"
          )}
        >
          <div
            className={cn(
              "w-9",
              "h-9",
              "rounded-full",
              "flex",
              "justify-center",
              "items-center",
              {
                "bg-primary-500": step >= index,
                "bg-gray-200": step < index,
              }
            )}
          >
            {step > index ? (
              <CheckIcon className={cn("text-white", "w-6", "h-6")} />
            ) : (
              <p className={cn("text-white", "text-lg")}>{index + 1}</p>
            )}
          </div>
          <p
            className={cn("text-sm", {
              "text-primary-500": step === index,
              "text-black/24": step < index + 1 && step !== index,
              "text-black/86": step > index + 1,
            })}
          >
            {displayStr}
          </p>
        </div>
      ))}
    </div>
  );
};

export default StepBar;
