import { ReactElement, useMemo } from "react";
import cn from "classnames";

interface ProgressColor {
  color: string;
  progress: number; // 0-100
}

interface PropsType {
  max: number;
  count: number;
  labelStyle?: "top" | "right" | "left" | "none";
  barSize?: "s" | "m" | "l";
  className?: string;
  labelClassName?: string;
  colors?: Array<ProgressColor>;
  title?: string;
}

const ProgressBar = (props: PropsType): ReactElement => {
  const {
    max,
    count,
    className,
    labelClassName,
    labelStyle = "right",
    barSize = "m",
    colors = [
      { color: "bg-green-500", progress: 100 },
      { color: "bg-yellow-400", progress: 0 },
    ],
    title,
  } = props;

  const color = useMemo(() => {
    const percent = (count / max) * 100;
    for (const c of colors) {
      if (percent >= c.progress) {
        return c.color;
      }
    }
  }, [max, count, colors]);

  return (
    <div
      className={cn(
        "items-center",
        {
          "flex flex-row": labelStyle === "right" || labelStyle === "left",
        },
        className
      )}
    >
      {labelStyle === "top" ? (
        <p
          className={cn(
            "text-sm",
            "mb-1",
            "text-gray-500",
            "font-medium",
            labelClassName
          )}
        >
          {title && title !== "" ? <span className="mr-2">{title}</span> : null}
          {`${count} / ${max}`}
        </p>
      ) : null}
      {labelStyle === "left" ? (
        <p
          className={cn(
            "text-sm",
            "mr-2",
            "text-gray-500",
            "font-medium",
            labelClassName
          )}
        >{`${count} / ${max}`}</p>
      ) : null}
      <div
        className={cn(
          "h-2",
          "relative",
          barSize === "s" && "w-10",
          barSize === "m" && "w-19",
          barSize === "l" && "w-24"
        )}
      >
        <div
          className={cn(
            "h-2",
            "absolute",
            "rounded-l-full",
            { "rounded-r-full": count >= max },
            color
          )}
          style={{
            width: `${Math.min(Math.floor((count / max) * 100), 100)}%`,
          }}
        />
        <div
          className={cn(
            "h-2",
            "border",
            "border-black/10",
            "absolute",
            "w-full",
            "rounded-full"
          )}
        />
      </div>
      {labelStyle === "right" ? (
        <p
          className={cn(
            "text-sm",
            "mx-2",
            "text-gray-500",
            "font-medium",
            labelClassName
          )}
        >{`${count} / ${max}`}</p>
      ) : null}
    </div>
  );
};

export default ProgressBar;
