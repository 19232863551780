import {
  AuthenticationResult,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";
import { config } from "../config";
import AppRoutes from "../routes/AppRoutes";
import { AuthService } from "oneclick-component/src/services/auth";

export class MsalAuthService implements AuthService {
  private static _instance: MsalAuthService | null = null;
  static getInstance(): MsalAuthService {
    if (this._instance == null) {
      this._instance = new MsalAuthService();
    }
    return this._instance;
  }
  msalInstance: PublicClientApplication;
  constructor() {
    const pca = new PublicClientApplication({
      auth: {
        clientId: config.msalClientId,
        authority: config.msalAuthority,
        redirectUri: AppRoutes.AuthCallbackScreen.render(),
        postLogoutRedirectUri: AppRoutes.LogoutRedirectScreen.render(),
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
      },
    });

    pca.addEventCallback((event) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event.payload != null
      ) {
        const payload = event.payload as AuthenticationResult;
        pca.setActiveAccount(payload.account);
      }
    });
    this.msalInstance = pca;
  }

  async refreshToken(): Promise<string> {
    try {
      const authResult = await this.msalInstance.acquireTokenSilent({
        scopes: [`${config.msalClientId}/.default`],
      });
      const resp = await fetch(
        `${config.apiBaseUrl}/token?msalAccessToken=${authResult.accessToken}`
      );
      const json = await resp.json();

      return json.token;
    } catch (err: unknown) {
      // Expected to fail if refresh token expired
      console.log(
        "Failed to obtain access token and set active account to null",
        err
      );
      this.msalInstance.setActiveAccount(null);
      throw new Error();
    }
  }
}
