import { SearchInput } from "oneclick-component/src/components/inputs";
import React, { PropsWithChildren, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import cn from "classnames";
import { Trans } from "react-i18next";

import { BriefStation } from "oneclick-component/src/store/apis/enhancedApi";
import { StationFilter } from "../StationFilter";

interface BaseProps extends PropsWithChildren {
  onChangeSearchInput: (v: string) => void;
  onExit: () => void;
}

interface PropsWithoutStationFilter extends BaseProps {
  showStationFilter?: false;
}

interface PropsWithStationFilter extends BaseProps {
  showStationFilter?: true;
  stationFilter?: number;
  onStationFilterChange: (station: BriefStation | null) => void;
}

type Props = PropsWithStationFilter | PropsWithoutStationFilter;

const MobileSearchView = (props: Props): React.ReactElement => {
  const { onChangeSearchInput, onExit, children } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return createPortal(
    <div
      className={cn(
        "bg-white",
        "fixed",
        "top-0",
        "left-0",
        "right-0",
        "bottom-0",
        "w-screen",
        "z-30",
        "px-4",
        "pt-3",
        "pb-24",
        "space-y-3",
        "overflow-y-auto"
      )}
    >
      <div className={cn("flex", "space-x-5", "items-end")}>
        <SearchInput
          ref={inputRef}
          className="flex-1"
          onChange={onChangeSearchInput}
        />
        <button
          className={cn("flex-none", "py-2")}
          type="button"
          onClick={onExit}
        >
          <span className={cn("text-sm", "font-medium", "text-black/60")}>
            <Trans i18nKey="common.cancel" />
          </span>
        </button>
      </div>
      {props.showStationFilter ? (
        <StationFilter
          className="mt-3"
          selectedStationId={props.stationFilter ?? null}
          onStationSelected={props.onStationFilterChange}
        />
      ) : null}
      {children}
    </div>,
    document.body
  );
};

export default MobileSearchView;
