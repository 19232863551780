import { TFunction } from "i18next";

export const allAnnouncementRecipientType = ["ALL", "LINE", "STATION"] as const;

// Expect the following encoding
// - ALL
// - LINE:<line_code>
// - STATION:<station_code>
export function renderAnnouncementRecipient(
  recipient: string,
  isShorten: boolean,
  t: TFunction
): string {
  const trimmedRecipient = recipient.trim();
  const parts = trimmedRecipient.split(":");
  const recipientType = parts[0].toUpperCase();
  const recipientRemainder = trimmedRecipient.substring(
    recipientType.length + 1
  );
  switch (recipientType) {
    case "ALL":
      return t("announcement.recipient.all");
    case "LINE":
      return recipientRemainder.length > 0 ? recipientRemainder : "-";
    case "STATION":
      if (isShorten) {
        return recipientRemainder.length > 0
          ? t("announcement.recipient.stationShort", {
              count: recipientRemainder.split(",").length,
            })
          : "-";
      }
      return recipientRemainder.length > 0 ? recipientRemainder : "-";
    default:
      console.warn(`Unknown recipient type ${recipientType} in ${recipient}`);
      return "-";
  }
}
