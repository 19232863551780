import { CSSProperties, PropsWithChildren, ReactElement } from "react";
import cn from "classnames";

interface Props {
  className?: string;
  style?: CSSProperties;
}

export const Badge = (props: PropsWithChildren<Props>): ReactElement => {
  const { className, style, children } = props;

  return (
    <span
      style={style}
      className={cn(
        "inline-flex",
        "items-center",
        "rounded-full",
        "px-2",
        "py-0.5",
        "text-xs",
        "font-medium",
        "break-keep",
        className
      )}
    >
      {children}
    </span>
  );
};
