import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { RadioGroup } from "@headlessui/react";
import { Trans, useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import cn from "classnames";
import { makeShiftDisplayInterval } from "oneclick-component/src/utils/shift";
import { Button } from "oneclick-component/src/components/Button";
import { RadioInput } from "oneclick-component/src/components/inputs/RadioInput";
import { Modal } from "oneclick-component/src/components/Modal";
import { Calendar, Checkbox } from "oneclick-component/src/components/inputs";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import {
  dateTimeNow,
  isoToDateTime,
  localizeDateTime,
} from "oneclick-component/src/utils/datetime";
import {
  PtUser,
  Shift,
  useGetPtUserShiftsHandlerPartTimeUsersPtUserIdShiftsGetQuery as useGetPtUserShifts,
} from "oneclick-component/src/store/apis/enhancedApi";
import { useShowError } from "../../../../hooks/useShowError";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

interface SelectLastDateDialogState {
  step: "selectLastDate";
  scheduledSuspendAt?: string;
}

interface ConfirmScheduleSuspendDialogState {
  step: "confirmScheduleSuspend";
  scheduledSuspendAt: string;
  isImmediate?: boolean;
}

export type ScheduleSuspendPTUserDialogState =
  | SelectLastDateDialogState
  | ConfirmScheduleSuspendDialogState;

export interface ScheduleSuspendDialogConfirmPayload {
  scheduledSuspendAt: string;
  isImmediate?: boolean;
}

interface SelectLastDateContentProps {
  scheduledSuspendAt?: string;
  onScheduledSuspendAtChange: (date: string | undefined) => void;
  onNext: (scheduledSuspendAt: string, isImmediate: boolean) => void;
  onBack: () => void;
}

const SelectLastDateContent = React.memo(
  (props: SelectLastDateContentProps): ReactElement => {
    const { scheduledSuspendAt, onScheduledSuspendAtChange, onNext, onBack } =
      props;
    const { t } = useTranslation();

    const [isSuspendNow, setIsSuspendNow] = useState(false);
    const now = useMemo(() => dateTimeNow(), []);

    const onScheduledSuspendAtInputChange = useCallback(
      (ev: React.ChangeEvent<HTMLInputElement>) => {
        const rawValue = ev.currentTarget.value;
        if (rawValue === "") {
          onScheduledSuspendAtChange(undefined);
        } else {
          onScheduledSuspendAtChange(rawValue);
        }
      },
      [onScheduledSuspendAtChange]
    );

    const onChangeSuspendNow = useCallback(() => {
      setIsSuspendNow((prev) => !prev);
    }, []);

    const onNextClick = useCallback(() => {
      if (scheduledSuspendAt == null && !isSuspendNow) {
        return;
      }
      const finalScheduledSuspendAt = isSuspendNow
        ? now.toISODate()!
        : scheduledSuspendAt!;
      onNext(finalScheduledSuspendAt, isSuspendNow);
    }, [onNext, scheduledSuspendAt, isSuspendNow, now]);

    return (
      <div
        className={cn(
          "flex",
          "flex-col",
          "sm:min-h-[20rem]",
          "justify-between"
        )}
      >
        <div className={cn("text-center", "sm:text-left", "w-full")}>
          <p className={cn("text-black", "text-lg", "font-medium", "mb-5")}>
            <Trans i18nKey="partTime.list.tab.availability.scheduleSuspendDialog.setLastDate.title" />
          </p>
          <RadioGroup onChange={onChangeSuspendNow} value={isSuspendNow}>
            <RadioGroup.Option value={false}>
              {({ active, checked }) => (
                <RadioInput
                  className={cn("mb-3", "text-sm", "py-3")}
                  labelClassName={cn(
                    "!text-black/60",
                    "font-medium",
                    "text-sm"
                  )}
                  active={active}
                  checked={checked}
                  label={t(
                    "partTime.list.tab.availability.scheduleSuspendDialog.setLastDate.options.schedule"
                  )}
                />
              )}
            </RadioGroup.Option>
            <Calendar
              className={cn("mb-6", "ml-6")}
              placeholderClassName="text-black/[0.24]"
              min={now.toFormat("yyyy-LL-dd")}
              placeholder={t("calendar.defaultPlaceholder")}
              onChange={onScheduledSuspendAtInputChange}
              formattedValue={
                scheduledSuspendAt == null
                  ? undefined
                  : localizeDateTime(
                      isoToDateTime(scheduledSuspendAt),
                      DateTime.DATE_MED
                    )
              }
              disabled={isSuspendNow}
            />
            <RadioGroup.Option value={true}>
              {({ active, checked }) => (
                <RadioInput
                  className={cn("text-sm", "py-3")}
                  labelClassName={cn(
                    "!text-black/60",
                    "font-medium",
                    "text-sm"
                  )}
                  active={active}
                  checked={checked}
                  label={t(
                    "partTime.list.tab.availability.scheduleSuspendDialog.setLastDate.options.now"
                  )}
                />
              )}
            </RadioGroup.Option>
          </RadioGroup>
        </div>
        <div
          className={cn(
            "mt-5",
            "flex",
            "flex-row",
            "gap-3",
            "justify-center",
            "sm:justify-end",
            "items-center"
          )}
        >
          <Button
            className={cn("font-medium", "flex-1", "sm:flex-none")}
            onClick={onBack}
            theme="white"
          >
            <Trans i18nKey="partTime.list.tab.availability.scheduleSuspendDialog.back" />
          </Button>
          <Button
            className={cn("font-medium", "flex-1", "sm:flex-none")}
            theme="whiteBgRedText"
            onClick={onNextClick}
            // eslint-disable-next-line react/jsx-no-leaked-render
            disabled={scheduledSuspendAt == null && !isSuspendNow}
          >
            <Trans i18nKey="partTime.list.tab.availability.scheduleSuspendDialog.next" />
          </Button>
        </div>
      </div>
    );
  }
);

interface ConfirmScheduleSuspendShiftItemProps {
  shift: Shift;
}

const ConfirmScheduleSuspendShiftItem = React.memo(
  (props: ConfirmScheduleSuspendShiftItemProps): ReactElement => {
    const { shift } = props;

    const shiftDescriptionText = useMemo(() => {
      const [shiftIntervalText] = makeShiftDisplayInterval(
        shift.dutyStartTime,
        shift.dutyEndTime
      );
      return [`${shift.displayId}`, shift.shiftTitle, shiftIntervalText].join(
        " | "
      );
    }, [shift]);

    return (
      <div className={cn("relative", "flex", "items-start")}>
        <div className={cn("flex", "h-6", "items-center")}>
          <ExclamationTriangleIcon
            className={cn("h-4", "w-4", "text-black/60")}
          />
        </div>
        <div className={cn("ml-2", "text-sm", "leading-6", "text-left")}>
          <span className={cn("font-medium", "text-black/60")}>
            {shiftDescriptionText}
          </span>
        </div>
      </div>
    );
  }
);

interface ConfirmScheduleSuspendShiftsProps {
  className?: string;
  shifts: Shift[];
  isLoading: boolean;
  consentChecked: boolean;
  onConsentCheckboxChange: (checked: boolean) => void;
}

const ConfirmScheduleSuspendShifts = React.memo(
  (props: ConfirmScheduleSuspendShiftsProps): ReactElement => {
    const {
      className,
      isLoading,
      shifts,
      consentChecked,
      onConsentCheckboxChange,
    } = props;
    const { t } = useTranslation();

    const onConsentCheckboxInputChange = useCallback(
      (ev: React.ChangeEvent<HTMLInputElement>) => {
        ev.stopPropagation();
        const checked = ev.currentTarget.checked;
        onConsentCheckboxChange(checked);
      },
      [onConsentCheckboxChange]
    );

    return (
      <div className={className}>
        {isLoading ? (
          <div className={cn("flex", "items-center", "justify-center", "mb-5")}>
            <LoadingSpinner size="l" />
          </div>
        ) : shifts.length === 0 ? (
          <Checkbox
            name="confirmShift"
            className="mb-5"
            label={t(
              "partTime.list.tab.availability.scheduleSuspendDialog.removeShift.empty"
            )}
            labelClassName={cn(
              "!text-black/60",
              "font-medium",
              "text-sm",
              "-ml-1"
            )}
            checked={true}
            disabled={true}
          />
        ) : (
          <div className="mb-5">
            <ul className="mb-2">
              {shifts.map((s) => (
                <ConfirmScheduleSuspendShiftItem key={s.id} shift={s} />
              ))}
            </ul>
            <Checkbox
              name="confirmCancel"
              className="mb-5"
              label={t(
                "partTime.list.tab.availability.scheduleSuspendDialog.removeShift.confirmCheckbox.label"
              )}
              labelClassName={cn(
                "!text-black/60",
                "font-medium",
                "text-sm",
                "-ml-1"
              )}
              checked={consentChecked}
              onChange={onConsentCheckboxInputChange}
            />
          </div>
        )}
      </div>
    );
  }
);

interface ConfirmScheduleSuspendContentProps {
  ptUser: PtUser;
  scheduledSuspendAt: string;
  isLoading: boolean;
  onConfirm: () => void;
  onBack: () => void;
}

const ConfirmScheduleSuspendContent = React.memo(
  (props: ConfirmScheduleSuspendContentProps): ReactElement => {
    const { ptUser, scheduledSuspendAt, isLoading, onConfirm, onBack } = props;
    const { showError } = useShowError();

    const scheduledSuspendDt = useMemo(() => {
      return isoToDateTime(scheduledSuspendAt).plus({ days: 1 }).set({
        hour: 3,
        minute: 0,
        second: 0,
      });
    }, [scheduledSuspendAt]);

    const {
      data: ptUserShiftsData,
      isLoading: isLoadingPtUserShifts,
      error: getPtUserShiftsError,
    } = useGetPtUserShifts({
      ptUserId: ptUser.id,
      endAfter: scheduledSuspendDt.toISO(),
    });
    const [isAcceptedConsent, setIsAcceptedConsent] = useState(false);

    useEffect(() => {
      if (getPtUserShiftsError != null) {
        showError(getPtUserShiftsError);
        onBack();
      }
    }, [getPtUserShiftsError, showError, onBack]);

    const disableConfirm =
      ptUserShiftsData == null ||
      (ptUserShiftsData.shifts.length > 0 && !isAcceptedConsent);
    return (
      <div
        className={cn(
          "flex",
          "flex-col",
          "sm:min-h-[20rem]",
          "justify-between"
        )}
      >
        <div className={cn("text-center", "sm:text-left", "w-full")}>
          <p className={cn("text-black", "text-lg", "font-medium", "mb-5")}>
            <Trans
              i18nKey="partTime.list.tab.availability.scheduleSuspendDialog.removeShift.title"
              values={{
                shiftCount:
                  ptUserShiftsData == null ? 0 : ptUserShiftsData.shifts.length,
              }}
            />
          </p>
          <p className={cn("text-black/60", "text-sm", "mb-5")}>
            <Trans i18nKey="partTime.list.tab.availability.scheduleSuspendDialog.removeShift.content" />
          </p>
          <ConfirmScheduleSuspendShifts
            className="mb-2"
            shifts={ptUserShiftsData?.shifts ?? []}
            isLoading={isLoadingPtUserShifts}
            consentChecked={isAcceptedConsent}
            onConsentCheckboxChange={setIsAcceptedConsent}
          />
        </div>
        <div
          className={cn(
            "mt-5",
            "sm:mt-4",
            "flex",
            "flex-row",
            "gap-3",
            "justify-center",
            "sm:justify-end",
            "items-center"
          )}
        >
          <Button
            className={cn("font-medium", "flex-1", "sm:flex-none")}
            theme="white"
            onClick={onBack}
            disabled={isLoading}
          >
            <Trans i18nKey="partTime.list.tab.availability.scheduleSuspendDialog.back" />
          </Button>
          <Button
            className={cn("font-medium", "flex-1", "sm:flex-none")}
            theme="whiteBgRedText"
            onClick={onConfirm}
            disabled={disableConfirm || isLoading}
          >
            <Trans i18nKey="partTime.list.tab.availability.scheduleSuspendDialog.remove" />
          </Button>
        </div>
      </div>
    );
  }
);

interface ScheduleSuspendDialogProps {
  ptUser: PtUser;
  isOpen: boolean;
  onClose: () => void;
  onConfirmScheduleSuspend: (
    state: ScheduleSuspendDialogConfirmPayload
  ) => void;
  isLoading: boolean;
}

const ScheduleSuspendDialog = (
  props: ScheduleSuspendDialogProps
): ReactElement => {
  const { ptUser, isOpen, onClose, onConfirmScheduleSuspend, isLoading } =
    props;

  const [dialogState, setDialogState] =
    useState<ScheduleSuspendPTUserDialogState>({ step: "selectLastDate" });

  const onScheduledSuspendAtChange = useCallback(
    (scheduledSuspendAt: string | undefined) => {
      setDialogState((prev) => {
        if (prev.step !== "selectLastDate") {
          return prev;
        }
        return {
          ...prev,
          scheduledSuspendAt,
        };
      });
    },
    []
  );

  const onConfirmScheduleSuspendConfirmClick = useCallback(() => {
    if (dialogState.step !== "confirmScheduleSuspend") {
      return;
    }
    onConfirmScheduleSuspend({
      scheduledSuspendAt: dialogState.scheduledSuspendAt,
      isImmediate: dialogState.isImmediate,
    });
  }, [onConfirmScheduleSuspend, dialogState]);

  const onSelectLastDateNextClick = useCallback(
    (scheduledSuspendAt: string, isImmediate: boolean) => {
      setDialogState({
        step: "confirmScheduleSuspend",
        scheduledSuspendAt,
        isImmediate,
      });
    },
    []
  );

  const onConfirmScheduleSuspendBackClick = useCallback(() => {
    setDialogState((prev) => ({
      ...prev,
      step: "selectLastDate",
    }));
  }, []);

  // Reset internal state when dialog is dismissed
  useEffect(() => {
    if (!isOpen) {
      setDialogState({ step: "selectLastDate" });
    }
  }, [isOpen]);

  return (
    <Modal onClose={onClose} isOpen={isOpen} hasXMarkButton={false}>
      {dialogState.step === "selectLastDate" ? (
        <SelectLastDateContent
          scheduledSuspendAt={dialogState.scheduledSuspendAt}
          onScheduledSuspendAtChange={onScheduledSuspendAtChange}
          onNext={onSelectLastDateNextClick}
          onBack={onClose}
        />
      ) : null}
      {dialogState.step === "confirmScheduleSuspend" ? (
        <ConfirmScheduleSuspendContent
          ptUser={ptUser}
          scheduledSuspendAt={dialogState.scheduledSuspendAt}
          onConfirm={onConfirmScheduleSuspendConfirmClick}
          onBack={onConfirmScheduleSuspendBackClick}
          isLoading={isLoading}
        />
      ) : null}
    </Modal>
  );
};
export default ScheduleSuspendDialog;
