import { ReactElement, useCallback, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import cn from "classnames";
import { useDispatch } from "react-redux";
import PopMenu, {
  PopMenuItem,
} from "oneclick-component/src/components/PopMenu";
import { StationBadge } from "oneclick-component/src/components/Badge";
import CCOBadge from "oneclick-component/src/components/Badge/CCOBadge";
import { Button, IconButton } from "oneclick-component/src/components/Button";
import {
  ChatIcon,
  AddUserIcon,
  DotsHorizon,
} from "oneclick-component/src/icon";
import {
  PtUserWithVisitCount,
  Shift,
} from "oneclick-component/src/store/apis/enhancedApi";
import useOpenChatFromShiftRequest from "../../hooks/useOpenChatFromShiftRequest";
import { PTUserAvatarImage } from "../PTUserAvatarImage";
import { startChat } from "../../store/chatState";
import { DeletedUserIcon } from "../../icon";
import {
  HiredShiftRequestBase,
  HiredShiftRequestBaseWithNumbering,
} from "../../models/shiftRequest";
import { isoToDateTime } from "oneclick-component/src/utils/datetime";
import { DateTime } from "luxon";
import { formatShiftRequestTime } from "../../utils/shiftRequest";
import { useShouldShowPtStationTeam } from "../../hooks/useShouldShowPtStationTeam";
import useShiftAccessControl from "../../hooks/useShiftAccessControl";

interface TimestampDisplayProps {
  shiftRequest: HiredShiftRequestBase | HiredShiftRequestBaseWithNumbering;
  isIncident?: boolean;
}

const TimestampDisplay = (props: TimestampDisplayProps): ReactElement => {
  const { shiftRequest, isIncident } = props;
  let applyTimeDisplayString = null;
  if (shiftRequest.appliedAt != null) {
    const dt: DateTime = isoToDateTime(shiftRequest.appliedAt);
    applyTimeDisplayString = formatShiftRequestTime(dt);
  }
  let hireTimeDisplayString = null;
  if (shiftRequest.hiredAt != null) {
    const dt: DateTime = isoToDateTime(shiftRequest.hiredAt);
    hireTimeDisplayString = formatShiftRequestTime(dt);
  }

  return (
    <p className={cn("text-xs", "font-normal")}>
      {applyTimeDisplayString != null ? (
        <>
          <span className="text-black/60">
            <Trans i18nKey="shiftDetail.request.appliedAt" />
          </span>{" "}
          <span className={cn("text-black/86", "mr-3")}>
            {applyTimeDisplayString}
          </span>
        </>
      ) : null}
      {!isIncident && hireTimeDisplayString != null ? (
        <>
          <span className="text-black/60">
            <Trans i18nKey="shiftDetail.request.hiredAt" />
          </span>{" "}
          <span className={cn("text-black/86", "mr-3")}>
            {hireTimeDisplayString}
          </span>{" "}
        </>
      ) : null}
      {shiftRequest.cancelCount != null ? (
        <>
          <span className="text-black/60">
            <Trans
              i18nKey="shiftDetail.request.cancelCount"
              values={{ count: shiftRequest.cancelCount }}
            />
          </span>
        </>
      ) : null}
    </p>
  );
};

interface HireStatusRowActionsProps {
  className?: string;
  user: PtUserWithVisitCount;
  showHireButton: boolean;
  showChatButton: boolean;
  showOptionMenu?: boolean;
  isHireLoading?: boolean;
  isChatLoading?: boolean;
  onHireClick: () => void;
  onStartChatClick: () => void;
  onCancelHireClick: () => void;
}

const HireStatusRowActions = (
  props: HireStatusRowActionsProps
): ReactElement => {
  const {
    className,
    user,
    showHireButton,
    showChatButton,
    showOptionMenu,
    isHireLoading,
    isChatLoading,
    onHireClick,
    onStartChatClick,
    onCancelHireClick,
  } = props;
  const { t } = useTranslation();

  return (
    <div className={className}>
      {showHireButton ? (
        <Button
          prefixIcon={AddUserIcon}
          className={cn("mr-3", "whitespace-nowrap")}
          theme="white"
          onClick={onHireClick}
          disabled={isHireLoading}
        >
          <Trans i18nKey="shiftDetail.hire.addUser" />
        </Button>
      ) : null}
      {showChatButton ? (
        <IconButton
          icon={ChatIcon}
          theme="whitePrimary"
          className={cn("mr-3", "last:mr-0")}
          onClick={onStartChatClick}
          disabled={isChatLoading}
        />
      ) : null}
      {user.isDeleted ? (
        <div
          className={cn(
            "flex",
            "items-center",
            "gap-1",
            "opacity-40",
            "mr-3",
            "last:mr-0"
          )}
        >
          <DeletedUserIcon className={cn("w-4", "h-4")} />
          <span
            className={cn(
              "text-xs",
              "text-black/60",
              "font-medium",
              "whitespace-nowrap"
            )}
          >
            <Trans i18nKey="partTime.shiftStatusRow.partTimeSuspended" />
          </span>
        </div>
      ) : null}
      {showOptionMenu ? (
        <PopMenu
          menuClass={cn("relative")}
          controlElementClass={cn(
            "bg-white",
            "hover:bg-gray-50",
            "focus:ring-offset-white",
            "focus:ring-indigo-500",
            "text-primary-600",
            "border",
            "border-gray-300",
            "group",
            "rounded-full",
            "shadow-sm",
            "focus:ring-2",
            "focus:ring-offset-2",
            "focus:outline-0",
            "p-2.5"
          )}
          controlElement={
            <DotsHorizon className={cn("h-5", "w-5", "fill-primary-500")} />
          }
        >
          <div className="py-1">
            <PopMenuItem
              type="button"
              onClick={onCancelHireClick}
              className={"text-gray-700"}
              text={t("shiftDetail.hire.cancelHire")}
            />
          </div>
        </PopMenu>
      ) : null}
    </div>
  );
};

interface PropsType {
  user: PtUserWithVisitCount;
  workingStationShortCode: string;
  onCancelUser?: (user: PtUserWithVisitCount) => void;
  showOption?: boolean;
  shouldShowAction?: boolean;
  shiftRequest: HiredShiftRequestBase | HiredShiftRequestBaseWithNumbering;
  onHire?: (user: PtUserWithVisitCount) => void;
  shift?: Shift;
  isHireLoading?: boolean;
  className?: string;
  disabled?: boolean;
  isIncident?: boolean;
}

// Note(peter): Ignoring complexity to keep mobile vs desktop layout inside same component
// eslint-disable-next-line complexity, sonarjs/cognitive-complexity
const HireStatusRow = (props: PropsType): ReactElement => {
  const {
    user,
    showOption,
    workingStationShortCode,
    shiftRequest,
    onHire,
    isHireLoading,
    shouldShowAction = true,
    shift,
    className,
    onCancelUser,
    disabled,
    isIncident,
  } = props;
  const dispatch = useDispatch();
  const [openChat, isChatLoading] = useOpenChatFromShiftRequest();
  const shouldShowPtStationTeam = useShouldShowPtStationTeam();

  const onHireUser = useCallback(() => {
    onHire?.(user);
  }, [onHire, user]);

  const onCancel = useCallback(() => {
    if (onCancelUser != null) {
      onCancelUser(user);
    }
  }, [onCancelUser, user]);

  const handleStartChatClick = useCallback(() => {
    if (shiftRequest.chatId != null) {
      dispatch(
        startChat({
          chatId: shiftRequest.chatId,
        })
      );
    } else if (shift != null) {
      openChat(shift, user, shiftRequest);
    }
  }, [shiftRequest, dispatch, openChat, user, shift]);

  const { canChatWithHiredPt, canCancelHiredPt } = useShiftAccessControl(shift);

  const visit = user.visitCounts?.find(
    (station) => station.workingStationShortCode === workingStationShortCode
  );
  const visitCount = visit?.visitCount ?? 0;
  const showHireButton = onHire != null && !user.isDeleted;
  const showChatButton = canChatWithHiredPt && !user.isDeleted;
  const showOptionMenu = canCancelHiredPt && showOption && !user.isDeleted;

  const requestIndex = useMemo<number | null>(() => {
    if ("index" in shiftRequest === false) {
      return null;
    }
    return (shiftRequest as HiredShiftRequestBaseWithNumbering).index;
  }, [shiftRequest]);

  const visitCountDisplayString = visitCount > 10 ? "10+" : visitCount;

  return (
    <div
      className={cn(
        "rounded-lg",
        "border",
        "border-black/10",
        "p-4",
        "mb-3",
        "flex",
        "flex-row",
        "w-full",
        {
          "pl-10": requestIndex != null,
          "bg-[#3031330D]": disabled,
        },
        "relative",
        className
      )}
    >
      {requestIndex != null ? (
        <div
          className={cn(
            "absolute",
            "top-0",
            "left-0",
            "h-full",
            "w-8",
            "border-r",
            "border-black/10",
            "flex",
            "justify-center",
            "items-center"
          )}
        >
          <span
            className={cn(
              "text-xs",
              "leading-4",
              "font-medium",
              "text-black/60"
            )}
          >
            {requestIndex}
          </span>
        </div>
      ) : null}
      <div
        className={cn("flex", "items-center", "mr-8", {
          "opacity-40": disabled,
        })}
      >
        <PTUserAvatarImage
          ptUser={user}
          override418Status={shiftRequest.ptUserShiftWeek418Status}
        />
      </div>
      <div
        className={cn("grow", "hidden", "lg:block", {
          "opacity-40": disabled,
        })}
      >
        <div>
          <p className={cn("font-normal", "flex", "items-center")}>
            {user.fullNameZhHk}
            {user.station != null ? (
              <StationBadge
                className="mx-2"
                station={user.station}
                stationTeam={user.stationTeam ?? null}
                showStationTeam={shouldShowPtStationTeam}
              />
            ) : null}
            {user.role?.name === "CCO" ? <CCOBadge /> : null}
          </p>
          <p className={cn("text-sm", "font-normal", "mb-1")}>
            <span className="text-black/60">ID</span>{" "}
            <span className="text-black/86">{user.eid}</span>{" "}
            <span className="text-black/12">|</span>{" "}
            <span className="text-black/60">
              <Trans i18nKey="shiftDetail.visitCount" />
            </span>{" "}
            <span className="text-black/86">{visitCountDisplayString}</span>
          </p>
          <TimestampDisplay
            shiftRequest={shiftRequest}
            isIncident={isIncident}
          />
        </div>
      </div>
      {shouldShowAction ? (
        <HireStatusRowActions
          className={cn(
            "flex-row",
            "items-center",
            "ml-3",
            "hidden",
            "lg:flex"
          )}
          user={user}
          showHireButton={showHireButton}
          showChatButton={showChatButton}
          showOptionMenu={showOptionMenu}
          isHireLoading={isHireLoading}
          isChatLoading={isChatLoading}
          onHireClick={onHireUser}
          onStartChatClick={handleStartChatClick}
          onCancelHireClick={onCancel}
        />
      ) : null}
      {/** Mobile layout has different dom structure */}
      <div className={cn("grow", "block", "lg:hidden")}>
        <div
          className={cn("flex", "flex-row", "items-center", "gap-x-2", {
            "opacity-40": disabled,
          })}
        >
          <span>{user.fullNameZhHk}</span>
          {user.station != null ? (
            <StationBadge
              station={user.station}
              stationTeam={user.stationTeam ?? null}
              showStationTeam={shouldShowPtStationTeam}
            />
          ) : null}
          {user.role?.name === "CCO" ? <CCOBadge /> : null}
        </div>
        <div
          className={cn("mb-2", {
            "opacity-40": disabled,
          })}
        >
          <p className={cn("text-sm", "font-normal", "line-clamp-1")}>
            <span className="text-black/60">
              <Trans i18nKey="shiftDetail.visitCount" />
            </span>{" "}
            <span className="text-black/86">{visitCountDisplayString}</span>
          </p>
          {shiftRequest.cancelCount != null ? (
            <span className={cn("text-sm", "text-black/60")}>
              <Trans
                i18nKey="shiftDetail.request.cancelCount"
                values={{ count: shiftRequest.cancelCount }}
              />
            </span>
          ) : null}
        </div>
        {shouldShowAction ? (
          <HireStatusRowActions
            className={cn("flex", "flex-row", "items-center", "lg:hidden")}
            user={user}
            showHireButton={showHireButton}
            showChatButton={showChatButton}
            showOptionMenu={showOptionMenu}
            isHireLoading={isHireLoading}
            isChatLoading={isChatLoading}
            onHireClick={onHireUser}
            onStartChatClick={handleStartChatClick}
            onCancelHireClick={onCancel}
          />
        ) : null}
      </div>
    </div>
  );
};

export default HireStatusRow;
