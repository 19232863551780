import React from "react";
import { Table as ReactTable } from "@tanstack/react-table";
import cn from "classnames";
import { Trans } from "react-i18next";

import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import Table from "oneclick-component/src/components/Table";
import { ShiftFullTimeAttendanceRecord } from "./useShiftFullTimeAttendanceTable";

interface Props {
  isLoading: boolean;
  isEmpty: boolean;
  table: ReactTable<ShiftFullTimeAttendanceRecord>;
}

const ShiftFullTimeAttendanceTable = (props: Props): React.ReactElement => {
  const { isLoading, isEmpty, table } = props;
  return (
    <Table table={table}>
      {isLoading ? (
        <tr className={"h-20"}>
          <td colSpan={table.getVisibleFlatColumns().length}>
            <span className={cn("flex", "justify-center", "items-center")}>
              <LoadingSpinner />
            </span>
          </td>
        </tr>
      ) : null}
      {isEmpty && !isLoading ? (
        <tr className={cn("h-20", "text-center")}>
          <td colSpan={table.getVisibleFlatColumns().length}>
            <p>
              <Trans i18nKey="fulltimeAttendance.list.empty" />
            </p>
          </td>
        </tr>
      ) : null}
      {!isEmpty
        ? table.getRowModel().rows.map((row) => {
            return <Table.Row key={row.id} row={row} />;
          })
        : null}
    </Table>
  );
};

export default ShiftFullTimeAttendanceTable;
