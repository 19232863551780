import React, { ForwardedRef, ReactElement, Ref, useMemo } from "react";
import { Listbox, ListboxProps } from "@headlessui/react";
import { ChevronDownIcon } from "../../../icon";
import cn from "classnames";
import { SelectOptions } from "./SelectOptions";
import {
  FloatingPortal,
  autoUpdate,
  flip,
  shift,
  size,
  useFloating,
} from "@floating-ui/react";

export interface Option<T> {
  name: string;
  value: T;
  displayComponent?: ReactElement;
}

export interface SelectProps<T>
  extends Omit<ListboxProps<typeof Listbox, T, T>, "value" | "defaultValue"> {
  className?: string;
  options: Option<T>[];
  onChange: (value: T) => void;
  placeholder?: string;
  hasError?: boolean;
  value?: T;
}

export const Select = React.forwardRef(function Select<T>(
  props: SelectProps<T>,
  ref: ForwardedRef<HTMLElement>
): ReactElement {
  const {
    className,
    options,
    onChange,
    placeholder = "",
    value,
    disabled = false,
    hasError = false,
    ...rest
  } = props;

  const { refs, floatingStyles } = useFloating({
    whileElementsMounted: autoUpdate,
    middleware: [
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
          });
        },
      }),
      flip(),
      shift(),
    ],
  });

  const selected = useMemo(() => {
    return options.find((option) => option.value === value);
  }, [options, value]);

  return (
    <Listbox
      ref={ref}
      value={value ?? null}
      onChange={onChange}
      disabled={disabled}
      as="div"
      className={cn("relative", className)}
      {...rest}
    >
      {({ open }) => (
        <>
          <Listbox.Button
            ref={refs.setReference}
            className={cn(
              "relative",
              "w-full",
              "min-h-10.5",
              "cursor-default",
              "rounded-md",
              "py-1.5",
              "pl-3",
              "pr-10",
              "text-left",
              "text-gray-900",
              "shadow-sm",
              "ring-1",
              "ring-inset",
              "focus:outline-none",
              disabled ? "bg-gray-200" : "bg-white",
              hasError
                ? ["ring-red-300", "focus:ring-red-500"]
                : ["ring-gray-300", "focus:ring-primary-600"],
              open && (hasError ? "ring-red-500" : "ring-primary-600"),
              "sm:text-sm",
              "sm:leading-6"
            )}
          >
            <span
              className={cn(
                "block",
                "truncate",
                (disabled || selected == null) && "text-black/25"
              )}
            >
              {selected?.displayComponent ?? selected?.name ?? placeholder}
            </span>
            <span
              className={cn(
                "pointer-events-none",
                "absolute",
                "inset-y-0",
                "right-0",
                "flex",
                "items-center",
                "px-3",
                "py-2"
              )}
            >
              <ChevronDownIcon
                className={cn(
                  "h-5",
                  "w-5",
                  disabled ? "fill-gray-700/25" : "fill-gray-700"
                )}
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <FloatingPortal>
            <SelectOptions
              ref={refs.setFloating}
              style={floatingStyles}
              open={open}
              options={options}
              className="z-50"
            />
          </FloatingPortal>
        </>
      )}
    </Listbox>
  );
}) as <T>(p: SelectProps<T> & { ref?: Ref<HTMLElement> }) => ReactElement;
