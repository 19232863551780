import { ThunkAction, Action } from "@reduxjs/toolkit";
import { enhancedApi as api } from "oneclick-component/src/store/apis/enhancedApi";
import { store, RootState } from "./store";
import { FullTimeAttendanceEventPayload } from "../models/webSocketEvent";

export const removeFullTimeAttendanceFromCaches = (
  payload: FullTimeAttendanceEventPayload
): ThunkAction<
  void,
  RootState,
  unknown,
  Action<"listShiftsHandlerShiftsGet">
  // eslint-disable-next-line sonarjs/cognitive-complexity
>[] => {
  const actions = [];
  /** Search for all `IncidentShifts` cache with different params to update */
  for (const { endpointName, originalArgs } of api.util.selectInvalidatedBy(
    store.getState(),
    [{ type: "IncidentShifts" }]
  )) {
    if (endpointName === "listShiftsHandlerShiftsGet") {
      actions.push(
        api.util.updateQueryData(endpointName, originalArgs, (draft) => {
          for (const shift of draft.results) {
            if (shift.id === payload.attendance.shiftId) {
              if (shift.fullTimeAttendances != null) {
                shift.fullTimeAttendances = shift.fullTimeAttendances.filter(
                  (at) => at.id !== payload.attendance.id
                );
              }
            }
          }
        })
      );
    }
  }

  actions.push(
    api.util.updateQueryData(
      "listShiftFullTimeAttendanceByShiftHandlerShiftFullTimeAttendancesGet",
      { shiftId: payload.attendance.shiftId },
      (draft) => {
        draft.items = draft.items.filter(
          (at) => at.id !== payload.attendance.id
        );
      }
    )
  );

  return actions;
};

export const addFullTimeAttendanceToCaches = (
  payload: FullTimeAttendanceEventPayload
): ThunkAction<
  void,
  RootState,
  unknown,
  Action<"listShiftsHandlerShiftsGet">
  // eslint-disable-next-line sonarjs/cognitive-complexity
>[] => {
  const actions = [];

  /** Search for all `IncidentShifts` cache with different params to update */
  for (const { endpointName, originalArgs } of api.util.selectInvalidatedBy(
    store.getState(),
    [{ type: "IncidentShifts" }]
  )) {
    if (endpointName === "listShiftsHandlerShiftsGet") {
      actions.push(
        api.util.updateQueryData(endpointName, originalArgs, (draft) => {
          for (const shift of draft.results) {
            if (shift.id === payload.attendance.shiftId) {
              if (shift.fullTimeAttendances == null) {
                shift.fullTimeAttendances = [payload.attendance];
              } else {
                shift.fullTimeAttendances.push(payload.attendance);
              }
            }
          }
        })
      );
    }
  }

  actions.push(
    api.util.updateQueryData(
      "listShiftFullTimeAttendanceByShiftHandlerShiftFullTimeAttendancesGet",
      { shiftId: payload.attendance.shiftId },
      (draft) => {
        draft.items.push(payload.attendance);
      }
    )
  );

  return actions;
};
