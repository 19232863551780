import { Menu, Transition } from "@headlessui/react";
import { Fragment, ReactElement } from "react";
import cn from "classnames";
import { MoreIcon, CheckCircleIcon } from "../../../../icon";
import { Trans } from "react-i18next";

interface SuspendedPTUserMoreButtonProps {
  onClickReenable: () => void;
}

const SuspendedPTUserMoreButton = (
  props: SuspendedPTUserMoreButtonProps
): ReactElement => {
  const { onClickReenable } = props;

  return (
    <Menu as="div">
      <Menu.Button
        className={cn(
          "h-full",
          "px-1.5",
          "rounded-md",
          "hover:bg-gray-50",
          "focus:ring-offset-white",
          "focus:ring-indigo-500"
        )}
      >
        <MoreIcon className="fill-gray-500" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={cn(
            "absolute",
            "top-10",
            "right-0",
            "w-30",
            "sm:w-56",
            "origin-top-right",
            "rounded-md",
            "bg-white",
            "shadow-lg",
            "ring-1",
            "ring-black/5",
            "focus:outline-none"
          )}
        >
          <div className={cn("px-1", "py-1", "")}>
            <Menu.Item>
              {({ active }) => (
                <button
                  type="button"
                  className={cn(
                    active ? "bg-gray-50" : "text-gray-700",
                    "flex",
                    "w-full",
                    "items-center",
                    "space-x-3",
                    "rounded-md",
                    "px-4",
                    "py-2",
                    "text-sm"
                  )}
                  onClick={onClickReenable}
                >
                  <CheckCircleIcon
                    className={cn("hidden", "sm:block", "stroke-gray-400")}
                  />
                  <p className={cn("text-sm", "font-medium", "text-gray-700")}>
                    <Trans i18nKey="partTime.list.tab.availability.header.moreButton.dialog.reenable" />
                  </p>
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default SuspendedPTUserMoreButton;
