import React, { useCallback } from "react";
import { Trans } from "react-i18next";
import cn from "classnames";

import { Button } from "oneclick-component/src/components/Button";
import { DownloadIcon } from "oneclick-component/src/icon";
import useGenerateShiftPdfWebSocket from "../../../hooks/useGenerateShiftPdfWebSocket";
import { useIsSuperAdmin } from "../../../hooks/role";

interface ActionBarProps {
  className?: string;
  selectedShiftIds: number[];
}
const ExpiredShiftTabSelectedActionBar = (
  props: ActionBarProps
): React.ReactElement => {
  const { className, selectedShiftIds } = props;

  const triggerPdfGeneration = useGenerateShiftPdfWebSocket();
  const isSuperAdmin = useIsSuperAdmin();

  const onClickExportPdf = useCallback(() => {
    triggerPdfGeneration(selectedShiftIds);
  }, [selectedShiftIds, triggerPdfGeneration]);

  return (
    <div className={cn("flex", "flex-row", "h-8", className)}>
      <div className={cn("grow", "flex", "flex-row", "items-center")}>
        <p className={cn("text-xs", "text-black/60", "mr-2")}>
          <Trans i18nKey="shiftList.tab.expired.selectedShift" />
        </p>
        <p className={cn("text-xs", "text-black")}>{selectedShiftIds.length}</p>
      </div>
      {selectedShiftIds.length > 0 ? (
        <div>
          <Button
            theme="whiteNoFillBlackTextThick"
            prefixIcon={DownloadIcon}
            className={cn("h-8", "text-xs", "font-medium")}
            iconClassName="!w-3"
            onClick={onClickExportPdf}
            disabled={isSuperAdmin}
          >
            <Trans i18nKey="shiftList.tab.completed.exportMenu.pdf" />
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default ExpiredShiftTabSelectedActionBar;
