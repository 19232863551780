import { ReactElement, useCallback, useEffect, useId, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  useFieldArray,
  useForm,
  UseFormSetValue,
  Control,
  FieldErrors,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import cn from "classnames";

import {
  FormTextInput,
  FormField,
} from "oneclick-component/src/components/forms";
import { Button, ButtonLink } from "oneclick-component/src/components/Button";
import {
  UserAdminRole,
  BriefStation,
  useEditUserHandlerUsersUserIdPostMutation as useEditUser,
  useGetUserHandlerUsersUserIdGetQuery as useGetUser,
  UserStationRole,
} from "oneclick-component/src/store/apis/enhancedApi";
import useShowMessage from "oneclick-component/src/hooks/useShowMessage";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import GenericErrorScreen from "oneclick-component/src/components/GenericErrorScreen";

import { LazyLoadStationSelectionDropdown } from "../../components/StationSelectionDropdown";
import { useShowError } from "../../hooks/useShowError";
import AppRoutes from "../../routes/AppRoutes";
import {
  EditManagerUserFormValues,
  editUserFormSchema,
  EditUserFormValues,
} from "./editUserForm";

interface StationRoleFieldsProps {
  index: number;
  userStationRoles: UserStationRole[];
  setValue: UseFormSetValue<EditUserFormValues>;
  control: Control<EditUserFormValues>;
  stationRolesFieldErrors: FieldErrors<EditManagerUserFormValues>["stationRoles"];
  removeStationRole: (index: number) => void;
}

const StationRoleFields = (props: StationRoleFieldsProps): ReactElement => {
  const {
    index,
    userStationRoles,
    setValue,
    control,
    stationRolesFieldErrors,
    removeStationRole,
  } = props;
  const { t } = useTranslation();

  const initialStationOptions = useMemo(() => {
    return userStationRoles.map((sr) => sr.station);
  }, [userStationRoles]);

  const onStationSelected = useCallback(
    (station: BriefStation | null) => {
      if (station != null) {
        setValue(`stationRoles.${index}.stationId`, station.id);
      }
    },
    [setValue, index]
  );

  const onRemoveStationRole = useCallback(() => {
    removeStationRole(index);
  }, [removeStationRole, index]);

  return (
    <div className={cn("flex", "gap-3", "items-end", "mb-3")}>
      <FormField.Control
        name={`stationRoles.${index}.stationId`}
        control={control}
      >
        {({ field }) => (
          <FormField.Container
            label={t("user.edit.form.stationRole.stationId.label")}
            errorMessage={stationRolesFieldErrors?.[index]?.role?.message}
            required={true}
          >
            <LazyLoadStationSelectionDropdown
              initialStationOptions={initialStationOptions}
              selectedStationId={field.value}
              onStationSelected={onStationSelected}
              onChange={field.onChange}
            />
          </FormField.Container>
        )}
      </FormField.Control>
      <Button onClick={onRemoveStationRole} className="h-10" theme="red">
        <Trans i18nKey="user.edit.form.stationRoles.remove" />
      </Button>
    </div>
  );
};

const EditUserScreen = (): ReactElement => {
  const { id } = useParams();
  const userId = parseInt(id!, 10);
  const { t } = useTranslation();
  const [editUser, { isLoading: isEditingUser }] = useEditUser();
  const {
    data: userData,
    isLoading: isLoadingUser,
    isError: isGetUserError,
  } = useGetUser({
    userId,
  });
  const { showError } = useShowError();
  const showMessage = useShowMessage();

  const formId = useId();
  const {
    watch,
    reset,
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<EditUserFormValues>({
    resolver: zodResolver(editUserFormSchema),
    defaultValues: {
      stationRoles: [],
    },
  });

  const {
    fields: stationRoleFields,
    append: appendStationRole,
    remove: removeStationRole,
  } = useFieldArray({
    control,
    name: "stationRoles",
  });

  const adminRole = watch("adminRole");
  const canEdit = adminRole === "MANAGER";

  useEffect(() => {
    if (userData != null) {
      const user = userData.user;
      const formStationRoles = user.stationRoles.map((sr) => ({
        stationId: sr.station.id,
        role: sr.role,
      }));
      reset({
        nameEn: user.name,
        nameZhHk: user.nameZhHk,
        adminRole: user.adminRole as UserAdminRole,
        email: user.email,
        stationRoles: formStationRoles,
        lineCode: user.railwayLine?.shortCode ?? "-",
      });
    }
  }, [userData, reset]);

  const onAddStationRole = useCallback(() => {
    appendStationRole({
      // workaround initial value must match schema
      stationId: null as unknown as number,
      role: "ADMIN",
    });
  }, [appendStationRole]);

  const onValidSubmit = useCallback(
    async (data: EditUserFormValues) => {
      try {
        if (data.adminRole !== "MANAGER") {
          return;
        }
        await editUser({
          userId,
          editUserRequest: {
            stationRoles: data.stationRoles,
          },
        }).unwrap();
        showMessage({
          type: "success",
          title: t("user.edit.successDialog.title"),
          message: t("user.edit.successDialog.message"),
        });
      } catch (err: unknown) {
        console.error(err);
        showError(err);
      }
    },
    [userId, editUser, showMessage, showError, t]
  );

  const { stationRolesFieldErrors } = useMemo(() => {
    return {
      stationRolesFieldErrors: (
        errors as FieldErrors<EditManagerUserFormValues>
      ).stationRoles,
    };
  }, [errors]);

  if (isLoadingUser) {
    return (
      <main
        className={cn(
          "w-full",
          "h-full",
          "flex",
          "items-center",
          "justify-center"
        )}
      >
        <LoadingSpinner size="l" />
      </main>
    );
  }

  if (isGetUserError || userData == null) {
    return <GenericErrorScreen />;
  }

  return (
    <main className={cn("px-4", "pb-20", "md:px-0")}>
      <form
        id={formId}
        className={cn("flex", "flex-col", "gap-y-5")}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(onValidSubmit)}
      >
        <FormTextInput
          name={"nameEn"}
          control={control}
          label={t("user.edit.form.nameEn.label")}
          required={true}
          disabled={true}
        />
        <FormTextInput
          name={"nameZhHk"}
          control={control}
          label={t("user.edit.form.nameZhHk.label")}
          required={true}
          disabled={true}
        />
        <FormTextInput
          name={"adminRole"}
          control={control}
          label={t("user.edit.form.adminRole.label")}
          required={true}
          disabled={true}
        />
        <FormTextInput
          name={"email"}
          control={control}
          label={t("user.edit.form.email.label")}
          required={true}
          disabled={true}
        />
        {adminRole === "MANAGER" ? (
          <div>
            <label
              className={cn(
                "block",
                "text-base",
                "font-bold",
                "leading-5",
                "text-left",
                "mb-4"
              )}
            >
              {t("user.edit.form.stationRoles.label")}
              {"*"}
            </label>
            {stationRoleFields.map((field, index) => (
              <StationRoleFields
                key={`StationRoleFields-${field.stationId}-${field.role}`}
                index={index}
                userStationRoles={userData.user.stationRoles}
                setValue={setValue}
                control={control}
                stationRolesFieldErrors={stationRolesFieldErrors}
                removeStationRole={removeStationRole}
              />
            ))}
            <Button theme="primary" onClick={onAddStationRole}>
              <Trans i18nKey="user.edit.form.stationRoles.add" />
            </Button>
            {stationRolesFieldErrors?.message != null ? (
              <p className={cn("mt-2", "text-sm", "text-red-600", "text-left")}>
                {stationRolesFieldErrors.message}
              </p>
            ) : null}
          </div>
        ) : null}
        {adminRole === "LANDLORD" ? (
          <FormTextInput
            name={"lineCode"}
            control={control}
            label={t("user.edit.form.lineCode.label")}
            required={true}
            disabled={true}
          />
        ) : null}
      </form>
      <footer
        className={cn(
          "fixed",
          "bottom-0",
          "left-0",
          "bg-white",
          "w-full",
          "h-16",
          "flex",
          "justify-end",
          "px-6",
          "py-3"
        )}
      >
        <ButtonLink
          theme={"white"}
          className={"mr-3"}
          to={AppRoutes.UserListScreen.render()}
        >
          <Trans i18nKey="user.edit.footer.button.cancel" />
        </ButtonLink>
        <Button
          type="submit"
          form={formId}
          disabled={!canEdit || isEditingUser}
        >
          <Trans i18nKey="user.edit.footer.button.edit" />
        </Button>
      </footer>
    </main>
  );
};

export default EditUserScreen;
