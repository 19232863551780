import { ReactElement, useState } from "react";
import cn from "classnames";
import { Trans, useTranslation } from "react-i18next";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import { isFetchQueryBaseError } from "oneclick-component/src/models/error";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import { StationBadge } from "oneclick-component/src/components/Badge";
import { dateTimeNow } from "oneclick-component/src/utils/datetime";
import { translateWeek } from "oneclick-component/src/utils/weekOfYear";
import {
  PtUser,
  PtUserWorkingStatusWith418Status,
  useListPtUserWorkingStatusRecordHandlerPartTimeUsersWorkingStatusRecordsPostQuery as useQueryWorkingStatus,
} from "oneclick-component/src/store/apis/enhancedApi";
import CCOBadge from "oneclick-component/src/components/Badge/CCOBadge";
import PTUser418Badge from "../PTUserBadge/PTUser418Badge";
import { PTUserAvatarImage } from "./PTUserAvatarImage";
import { useShouldShowPtStationTeam } from "../../hooks/useShouldShowPtStationTeam";

interface PropsType {
  ptUser: PtUser;
  className?: string;
}

interface DisplayPropType {
  error?: FetchBaseQueryError | SerializedError;
  isLoading: boolean;
  statuses: PtUserWorkingStatusWith418Status[];
}

const weekTranslations = [
  "workingStatus.thisWeek",
  "workingStatus.nextWeek",
  "workingStatus.twoWeeksAfter",
];

const Status418Display = (props: DisplayPropType): ReactElement => {
  const { error, isLoading, statuses } = props;
  const { t } = useTranslation();

  if (error != null && isFetchQueryBaseError(error) && error.status === 403) {
    return (
      <p className={cn("text-center", "text-gray-500", "pt-3", "text-sm")}>
        <Trans i18nKey="status418Popup.error.noPermission" />
      </p>
    );
  } else if (error != null) {
    return (
      <p className={cn("text-center", "text-gray-500", "pt-3", "text-sm")}>
        <Trans i18nKey="status418Popup.error.failToDisplay" />
      </p>
    );
  }
  if (isLoading) {
    return (
      <div className={cn("pt-5", "text-center")}>
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <>
      {statuses.map((workingStatus, index) => (
        <div
          key={index}
          className={cn("p-4", "flex", "flex-row", "items-center")}
        >
          <div className="grow">
            <p className={cn("text-black/60", "font-medium", "text-sm")}>
              {translateWeek({
                year: workingStatus.year,
                weekNumber: workingStatus.weekNumber,
                t: t,
                startDisplayFormat:
                  "partTime.list.tab.workStatus418.date.popupDisplayFormat",
                endDisplayFormat:
                  "partTime.list.tab.workStatus418.date.popupEndDisplayFormat",
              })}
            </p>
            <p className={cn("text-gray-500", "font-normal", "text-sm")}>
              <Trans i18nKey={weekTranslations[index]} />
            </p>
          </div>
          <PTUser418Badge status={workingStatus.weekly418Status} />
        </div>
      ))}
    </>
  );
};

const Status418ToolTip = (props: PropsType): ReactElement => {
  const { ptUser } = props;
  const shouldShowPtStationTeam = useShouldShowPtStationTeam();
  const [now] = useState(dateTimeNow());

  const { data, isLoading, error } = useQueryWorkingStatus({
    listPtUserWorkingStatusRecordRequest: {
      ptUserIds: [ptUser.id],
      offsetDirection: "after",
      numWeekOfYears: 3,
      anchorWeekOfYear: {
        year: now.year,
        weekNumber: now.weekNumber,
      },
    },
  });

  return (
    <div className={cn("w-60", "bg-white", "rounded-lg", "py-3", "shadow-lg")}>
      <div
        className={cn(
          "flex",
          "flex-row",
          "px-6",
          "pb-5",
          "border-b",
          "border-gray-200",
          "items-center"
        )}
      >
        <PTUserAvatarImage
          ptUser={ptUser}
          show418Badge={false}
          shouldShow418ToolTip={false}
        />
        <div className="ml-3">
          <p className={cn("text-gray-900", "font-medium", "text-sm")}>
            {ptUser.fullNameZhHk}
          </p>
          {ptUser.station != null ? (
            <StationBadge
              station={ptUser.station}
              stationTeam={ptUser.stationTeam ?? null}
              showStationTeam={shouldShowPtStationTeam}
            />
          ) : null}
          {ptUser.role?.name === "CCO" ? <CCOBadge className="ml-2" /> : null}
        </div>
      </div>
      <div className="px-3">
        <Status418Display
          statuses={data?.items[0].workingStatusRecords ?? []}
          error={error}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default Status418ToolTip;
