import React, { useCallback, useState, useEffect, useContext } from "react";
import cn from "classnames";
import { t } from "i18next";
import {
  useListBorrowingRequestsHandlerShiftsBorrowingShiftRequestsGetQuery as useListBorrowingRequests,
  enhancedApi,
  ShiftRequest,
} from "oneclick-component/src/store/apis/enhancedApi";
import { ListEmptyView } from "../../components/EmptyView";
import PendingNotificationRow from "./PendingNotificationRow";
import LoadingScreen from "oneclick-component/src/components/LoadingScreen";
import Pagination from "oneclick-component/src/components/Pagination/Pagination";
import { useBreakPoints } from "oneclick-component/src/providers";
import { DEFAULT_PAGE_SIZE } from "../../constants/pagination";
import { useAppDispatch } from "../../store/hooks";
import { useSelectedStationProfile } from "../../hooks/useSelectedStationProfile";
import { useShouldShowPtStationTeam } from "../../hooks/useShouldShowPtStationTeam";
import { NotificationContext } from "../../providers/NotificationProvider";

const PendingNotificationTab = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const { setNotificationUnreadCount } = useContext(NotificationContext);
  const { useIsSm } = useBreakPoints();
  const selectedStationProfile = useSelectedStationProfile();
  const isSm = useIsSm();
  const shouldShowPtStationTeam = useShouldShowPtStationTeam();

  const [pageIndex, setPageIndex] = useState(0);
  const pageSize = DEFAULT_PAGE_SIZE;
  const {
    currentData,
    data: cachedData,
    isFetching,
    refetch,
  } = useListBorrowingRequests({
    stationId: selectedStationProfile?.station.id,
    borrowingStatus: ["pending", "contacted"],
    sort: "applied_at+",
    pageIndex,
    pageSize,
  });

  useEffect(() => {
    setPageIndex(0);
    refetch()
      .then()
      .catch((e) => {
        console.error(e);
      });
  }, [refetch]);

  useEffect(() => {
    if (currentData?.uncontactedCount != null) {
      setNotificationUnreadCount(currentData.uncontactedCount);
    }
  }, [currentData, setNotificationUnreadCount]);

  const handleOnClickPage = useCallback((pageIndex: number) => {
    setPageIndex(pageIndex);
    window.scrollTo({ top: 0 });
  }, []);

  const onStartChat = useCallback(
    (shiftRequest: ShiftRequest) => {
      dispatch(
        enhancedApi.util.updateQueryData(
          "listBorrowingRequestsHandlerShiftsBorrowingShiftRequestsGet",
          {
            borrowingStatus: ["pending", "contacted"],
            sort: "applied_at-",
            pageSize,
            pageIndex,
          },
          (draft) => {
            const _shiftRequest = draft.results.find(
              (result) =>
                result.shiftId === shiftRequest.shiftId &&
                result.ptUserId === shiftRequest.ptUserId
            );

            if (_shiftRequest != null) {
              _shiftRequest.borrowingRequestStatus = "contacted";
            }
            draft.uncontactedCount -= 1;
          }
        )
      );
    },
    [dispatch, pageIndex, pageSize]
  );

  const pendingShiftRequests = currentData?.results ?? [];

  if (isFetching) {
    return <LoadingScreen />;
  }

  return pendingShiftRequests.length === 0 ? (
    <ListEmptyView description={t("notification.emptyState")} />
  ) : (
    <section>
      <div className={"space-y-5"}>
        {pendingShiftRequests.map((request, index) => {
          return (
            <PendingNotificationRow
              key={index}
              shiftRequest={request}
              onStartChat={onStartChat}
              showStationTeam={shouldShowPtStationTeam}
            />
          );
        })}
      </div>
      <Pagination
        className={cn(
          "mt-3",
          "bg-white",
          !isSm && ["fixed", "bottom-0", "inset-x-0"]
        )}
        totalPages={Math.ceil((cachedData?.totalCount ?? 0) / pageSize)}
        pageIndex={pageIndex}
        onClickPage={handleOnClickPage}
      />
    </section>
  );
};

export default PendingNotificationTab;
