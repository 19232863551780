import {
  ColumnDef,
  Table,
  TableState,
  createColumnHelper,
  getCoreRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Link } from "react-router-dom";
import { IncomingTabRecord } from "./model";
import { Trans } from "react-i18next";
import cn from "classnames";
import { StationBadge } from "oneclick-component/src/components/Badge";
import ShiftBadge from "oneclick-component/src/components/ShiftRateBadge/ShiftBadge";
import { WorkingStation } from "oneclick-component/src/store/apis/enhancedApi";
import AppRoutes from "../../../routes/AppRoutes";

const columnHelper = createColumnHelper<IncomingTabRecord>();

export const useIncomingTabTableColumns = (): ColumnDef<
  IncomingTabRecord,
  any
>[] => {
  return [
    columnHelper.accessor("ptEid", {
      header: () => (
        <span className={cn("text-xs", "font-medium", "text-gray-500", "w-8")}>
          <Trans i18nKey="shiftRequestList.incoming.table.column.ptEid.header" />
        </span>
      ),
      cell: ({ cell }) => {
        return (
          <p
            className={cn(
              "text-sm",
              "font-medium",
              "text-gray-500",
              "w-13" // assume 6-char eid
            )}
          >
            {cell.getValue()}
          </p>
        );
      },
    }),
    columnHelper.accessor("ptNameEn", {
      header: () => (
        <span className={cn("text-xs", "font-medium", "text-gray-500", "w-41")}>
          <Trans i18nKey="shiftRequestList.incoming.table.column.ptNameEn.header" />
        </span>
      ),
      cell: ({ cell }) => {
        return (
          <span
            className={cn(
              "text-sm",
              "font-medium",
              "text-gray-900",
              "line-clamp-3",
              "w-46"
            )}
          >
            {cell.getValue()}
          </span>
        );
      },
    }),
    columnHelper.accessor("ptNameZh", {
      header: () => (
        <span className={cn("text-xs", "font-medium", "text-gray-500", "w-15")}>
          <Trans i18nKey="shiftRequestList.incoming.table.column.ptNameZh.header" />
        </span>
      ),
      cell: ({ cell }) => {
        return (
          <p
            className={cn(
              "text-gray-500",
              "text-sm",
              "font-normal",
              "line-clamp-2",
              "w-20"
            )}
          >
            {cell.getValue()}
          </p>
        );
      },
    }),
    columnHelper.accessor("ptRole", {
      header: () => (
        <span className={cn("text-xs", "font-medium", "text-gray-500")}>
          <Trans i18nKey="shiftRequestList.incoming.table.column.ptRole.header" />
        </span>
      ),
      cell: ({ cell }) => {
        return (
          <span className={cn("text-sm", "font-medium", "text-gray-500")}>
            {cell.getValue()}
          </span>
        );
      },
    }),
    columnHelper.accessor("ptStationShortCode", {
      header: () => (
        <span className={cn("text-xs", "font-medium", "text-gray-500")}>
          <Trans i18nKey="shiftRequestList.incoming.table.column.ptStationShortCode.header" />
        </span>
      ),
      cell: ({ cell, row }) => {
        const { ptUser } = row.original.shiftRequestModel;
        return ptUser.station != null ? (
          <StationBadge station={ptUser.station} />
        ) : (
          <span>{cell.getValue()}</span>
        );
      },
    }),
    columnHelper.accessor("shiftWorkingStationShortCode", {
      header: () => (
        <span className={cn("text-xs", "font-medium", "text-gray-500")}>
          <Trans i18nKey="shiftRequestList.incoming.table.column.shiftWorkingStationShortCode.header" />
        </span>
      ),
      cell: ({ cell, row }) => {
        const { shift } = row.original.shiftRequestModel;
        const workingStation: WorkingStation | null =
          shift == null
            ? null
            : shift.isIncident
            ? shift.supportStation
            : shift.workingStation;

        return workingStation != null ? (
          <StationBadge station={workingStation} />
        ) : (
          <span>{cell.getValue()}</span>
        );
      },
    }),
    columnHelper.accessor("shiftTimeDisplayString", {
      header: () => (
        <span
          className={cn("text-xs", "font-medium", "text-gray-500", "min-w-27")}
        >
          <Trans i18nKey="shiftRequestList.incoming.table.column.shiftTimeDisplayString.header" />
        </span>
      ),
      cell: ({ cell }) => {
        return (
          <span
            className={cn(
              "min-w-32",
              "line-clamp-4",
              "text-sm",
              "text-gray-500"
            )}
          >
            {cell.getValue()}
          </span>
        );
      },
    }),
    columnHelper.accessor("shiftRateCode", {
      header: () => (
        <span className={cn("text-xs", "font-medium", "text-gray-500")}>
          <Trans i18nKey="shiftRequestList.incoming.table.column.shiftRateCode.header" />
        </span>
      ),
      cell: ({ cell, row }) => {
        const { shift } = row.original.shiftRequestModel;
        return shift != null ? (
          <div className="flex">
            <ShiftBadge rate={shift.rate} isIncident={shift.isIncident} />
          </div>
        ) : (
          <span>{cell.getValue()} </span>
        );
      },
    }),
    columnHelper.accessor("shiftTitle", {
      header: () => (
        <span
          className={cn("text-xs", "font-medium", "text-gray-500", "min-w-65")}
        >
          <Trans i18nKey="shiftRequestList.incoming.table.column.shiftTitle.header" />
        </span>
      ),
      cell: ({ cell, row }) => {
        return (
          <Link
            to={AppRoutes.ShiftDetailScreen.render(
              row.original.shiftRequestModel.shiftId
            )}
          >
            <span
              className={cn(
                "min-w-70",
                "line-clamp-3",
                "text-sm",
                "font-medium",
                "text-black/86"
              )}
            >
              {cell.getValue()}
            </span>
          </Link>
        );
      },
    }),
    columnHelper.accessor("shiftDisplayId", {
      header: () => (
        <span
          className={cn("text-xs", "font-medium", "text-gray-500", "min-w-45")}
        >
          <Trans i18nKey="shiftRequestList.incoming.table.column.shiftDisplayId.header" />
        </span>
      ),
      cell: ({ cell }) => {
        return (
          <span
            className={cn(
              "min-w-50",
              "line-clamp-1",
              "text-xs",
              "leading-5",
              "text-black/60"
            )}
          >
            {cell.getValue()}
          </span>
        );
      },
    }),
    columnHelper.accessor("shiftDate", {
      enableHiding: true,
    }),
  ];
};

const useIncomingTabTable = (
  data: IncomingTabRecord[],
  state?: Partial<TableState>
): Table<IncomingTabRecord> => {
  const columns = useIncomingTabTableColumns();
  const table = useReactTable<IncomingTabRecord>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel<IncomingTabRecord>(),
    getSortedRowModel: getSortedRowModel<IncomingTabRecord>(),
    getGroupedRowModel: getGroupedRowModel<IncomingTabRecord>(),

    // override default column sizing, ref https://tanstack.com/table/v8/docs/guide/column-sizing#column-widths
    defaultColumn: {
      minSize: 0, //enforced during column resizing
      size: Number.MAX_SAFE_INTEGER,
      maxSize: Number.MAX_SAFE_INTEGER,
    },
    manualSorting: true,
    groupedColumnMode: false,
    state: {
      columnVisibility: {
        shiftDate: false,
        ptNameEn: false,
        shiftDisplayId: false,
        shiftRateCode: false,
      },
      ...state,
    },
  });
  return table;
};

export default useIncomingTabTable;
