import { ReactElement } from "react";
import { Trans, useTranslation } from "react-i18next";
import cn from "classnames";

import {
  Option,
  SearchInput,
  Select,
} from "oneclick-component/src/components/inputs";
import { Button } from "oneclick-component/src/components/Button";
import { SearchIcon } from "oneclick-component/src/icon";
import { WorkingStation } from "oneclick-component/src/store/apis/enhancedApi";
import { WorkingStationFilter } from "../../../components/WorkingStationFilter";
import { DateRangeFilter } from "../DateRangeFilter/DateRangeFilter";
import { ShiftDateRangeFilter } from "../DateRangeFilter/model";

interface ActionBarProps {
  className?: string;
  searchString?: string;
  onChangeSearchString: (value: string) => void;
  dateRangeFilter: ShiftDateRangeFilter;
  onDateRangeFilterChange: (filter: ShiftDateRangeFilter) => void;
  ptUserRole: string | null;
  onChangePtUserRole: (value: string | null) => void;
  ptUserRoleOptions: Option<string | null>[];
  shiftRate: number | null;
  onChangeShiftRate: (value: number | null) => void;
  shiftRateOptions: Option<number | null>[];
  showWorkingStationFilter: boolean;
  workingStationFilter: number | null;
  onChangeWorkingStationFilter: (station: WorkingStation | null) => void;
  isMobileSearchView: boolean;
  onEnterMobileSearchView: () => void;
}
const ActiveShiftTabActionBar = (props: ActionBarProps): ReactElement => {
  const {
    className,
    searchString,
    onChangeSearchString,
    dateRangeFilter,
    onDateRangeFilterChange,
    ptUserRole,
    onChangePtUserRole,
    ptUserRoleOptions,
    shiftRate,
    onChangeShiftRate,
    shiftRateOptions,
    workingStationFilter,
    onChangeWorkingStationFilter,
    showWorkingStationFilter,
    isMobileSearchView,
    onEnterMobileSearchView,
  } = props;
  const { t } = useTranslation();

  return (
    <>
      {/* Mobile view */}
      {!isMobileSearchView ? (
        <div
          className={cn("flex", "sm:hidden", "items-end", "gap-x-6", className)}
        >
          <Button
            className={cn("!px-3", "!py-2.5")}
            theme="white"
            onClick={onEnterMobileSearchView}
          >
            <SearchIcon className={cn("w-5", "h-5", "fill-gray-400")} />
          </Button>
          <SearchInput
            initialSearchText={searchString}
            className={cn("flex-1", "min-w-0", "h-10.5")}
            onChange={onChangeSearchString}
          />
        </div>
      ) : (
        <div className={cn("flex", "flex-col", "gap-2", className)}>
          <DateRangeFilter
            className={cn("overflow-x-auto", "pb-2")}
            dateRangeFilter={dateRangeFilter}
            onDateRangeFilterChange={onDateRangeFilterChange}
            showPastDaysFilter={false}
            showFutureDaysFilter={true}
          />
          <div className={cn("flex", "gap-6")}>
            <div>
              <label
                className={cn(
                  "text-sm",
                  "block",
                  "font-normal",
                  "text-left",
                  "text-black/86",
                  "mb-1"
                )}
              >
                <Trans i18nKey={"shiftList.filter.ptType.label"} />
              </label>
              <Select<string | null>
                className="w-27"
                value={ptUserRole}
                onChange={onChangePtUserRole}
                options={ptUserRoleOptions}
              />
            </div>
            <div>
              <label
                className={cn(
                  "text-sm",
                  "block",
                  "font-normal",
                  "text-left",
                  "text-black/86",
                  "mb-1"
                )}
              >
                <Trans i18nKey={"shiftList.filter.shiftRate.label"} />
              </label>
              <Select<number | null>
                className="w-27"
                value={shiftRate}
                onChange={onChangeShiftRate}
                options={shiftRateOptions}
              />
            </div>
            {showWorkingStationFilter ? (
              <div>
                <label
                  className={cn(
                    "text-sm",
                    "block",
                    "font-normal",
                    "text-left",
                    "text-black/86",
                    "mb-1"
                  )}
                >
                  <Trans i18nKey={"shiftList.filter.workingStation.label"} />
                </label>
                <WorkingStationFilter
                  className="w-30"
                  selectedWorkingStationId={workingStationFilter}
                  onWorkingStationSelected={onChangeWorkingStationFilter}
                />
              </div>
            ) : null}
          </div>
        </div>
      )}
      {/* Desktop view */}
      <div
        className={cn(
          "hidden",
          {
            "sm:flex": !isMobileSearchView,
          },
          "mt-0",
          "flex-wrap",
          "items-end",
          "gap-y-4",
          "gap-x-6",
          className
        )}
      >
        <DateRangeFilter
          className={cn("border-r", "border-black/12", "pr-6")}
          dateRangeFilter={dateRangeFilter}
          onDateRangeFilterChange={onDateRangeFilterChange}
          showPastDaysFilter={false}
          showFutureDaysFilter={true}
        />
        <div
          className={cn("flex", "gap-6", "pr-6", "border-r", "border-black/12")}
        >
          <div>
            <label
              className={cn(
                "text-sm",
                "block",
                "font-normal",
                "text-left",
                "text-black/86",
                "mb-1"
              )}
            >
              <Trans i18nKey={"shiftList.filter.ptType.label"} />
            </label>
            <Select<string | null>
              className="w-27"
              value={ptUserRole}
              onChange={onChangePtUserRole}
              options={ptUserRoleOptions}
            />
          </div>
          <div>
            <label
              className={cn(
                "text-sm",
                "block",
                "font-normal",
                "text-left",
                "text-black/86",
                "mb-1"
              )}
            >
              <Trans i18nKey={"shiftList.filter.shiftRate.label"} />
            </label>
            <Select<number | null>
              className="w-27"
              value={shiftRate}
              onChange={onChangeShiftRate}
              options={shiftRateOptions}
            />
          </div>
          {showWorkingStationFilter ? (
            <div>
              <label
                className={cn(
                  "text-sm",
                  "block",
                  "font-normal",
                  "text-left",
                  "text-black/86",
                  "mb-1"
                )}
              >
                <Trans i18nKey={"shiftList.filter.workingStation.label"} />
              </label>
              <WorkingStationFilter
                className="w-30"
                selectedWorkingStationId={workingStationFilter}
                onWorkingStationSelected={onChangeWorkingStationFilter}
              />
            </div>
          ) : null}
        </div>
        <div className={cn("flex-1", "-mr-6")} />
        <SearchInput
          initialSearchText={searchString}
          className={cn("w-83", "h-10.5")}
          onChange={onChangeSearchString}
          placeholder={t("shiftList.filter.searchInput.placeholder")}
        />
      </div>
    </>
  );
};

export default ActiveShiftTabActionBar;
