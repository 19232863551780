import { ReactElement } from "react";
import cn from "classnames";
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  UseControllerReturn,
  useController,
} from "react-hook-form";

export interface FormFieldContainerProps {
  className?: string;
  label?: string;
  errorMessage?: string;
  required?: boolean;
  children?: ReactElement;
}

function FormFieldContainer(props: FormFieldContainerProps): ReactElement {
  const { label, errorMessage, className, required = false, children } = props;

  return (
    <div className={className}>
      {label != null ? (
        <label
          className={cn(
            "block",
            "text-sm",
            "font-normal",
            "leading-5",
            "text-left",
            "mb-2"
          )}
        >
          {label}
          {required ? "*" : null}
        </label>
      ) : null}
      {children}
      {errorMessage != null ? (
        <p className={cn("mt-2", "text-sm", "text-red-600", "text-left")}>
          {errorMessage}
        </p>
      ) : null}
    </div>
  );
}

export interface FormFieldControlProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TName> {
  children: ({
    field,
    fieldState,
  }: UseControllerReturn<TFieldValues, TName>) => ReactElement;
}

const FormFieldControl = <
  T extends FieldValues,
  S extends FieldPath<T> = FieldPath<T>,
>(
  props: FormFieldControlProps<T, S>
): ReactElement => {
  const { children, ...rest } = props;

  const { ...state } = useController(rest);

  return <>{children({ ...state })}</>;
};

export const FormField = {
  Control: FormFieldControl,
  Container: FormFieldContainer,
};
