import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import useIncomingTabQuery, {
  UseIncomingTabQueryOptions,
} from "./useIncomingTabQuery";
import { ViewStateStatus } from "./viewState";
import IncomingTabTable from "./TableView";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import cn from "classnames";
import Pagination from "oneclick-component/src/components/Pagination";
import { SHIFT_REQUEST_LIST_PAGE_SIZE } from "../ShiftRequestListScreen";
import IncomingTabActionBar from "./ActionBar";
import { usePtUserRoleFilterOptions } from "../../ShiftListScreen/usePtUserRoleFilterOptions";
import useShiftRateFilterOptions from "../../ShiftListScreen/useShiftRateFilterOptions";
import { FilterProps } from "oneclick-component/src/models";
import useShowMessage from "oneclick-component/src/hooks/useShowMessage";
import {
  ShiftDateRangeFilter,
  getDateRangeFromFilter,
} from "../../ShiftListScreen/DateRangeFilter/model";
import useMeUser from "../../../hooks/useMeUser";
import { RootState } from "../../../store/store";
import {
  saveIncomingPtTabFilter,
  IncomingPtTabFilterState,
} from "../../../store/filterState";
import {
  Station,
  useExportShiftRequestHandlerShiftRequestsExportPostMutation as useExportShiftRequest,
} from "oneclick-component/src/store/apis/enhancedApi";
import useOnClickTableColumnHeader, {
  ControlledTableState,
} from "oneclick-component/src/hooks/useOnClickTableColumnHeader";
import useSortingQueryParam from "./useSortingQueryParam";
import { IncomingTabRecord } from "./model";
import useMeUserStations from "../../../hooks/useMeUserStations";

interface IncomingTabFilterState {
  shiftDateRange: ShiftDateRangeFilter;
  ptRole: string | null;
  shiftRate: number | null;
  workingStationId?: number | null; // only applies to Superadmin/Landlord
  ptStationId: number | null;
  searchString?: string;
  pageIndex: number;
  pageSize: number;
}

const DEFAULT_FILTER_STATE: IncomingTabFilterState = {
  shiftDateRange: { type: "all" },
  ptRole: null,
  shiftRate: null,
  searchString: undefined,
  ptStationId: null,
  pageIndex: 0,
  pageSize: SHIFT_REQUEST_LIST_PAGE_SIZE,
};

const getDefaultFilterState = (
  savedFilter?: IncomingPtTabFilterState
): IncomingTabFilterState => {
  return {
    ...DEFAULT_FILTER_STATE,
    ...savedFilter,
  };
};

const IncomingPartTimeTab = (): ReactElement => {
  const showMessage = useShowMessage();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const savedFilter = useSelector((state: RootState) => {
    return state.filterState.incomingPtTabFilter;
  }, shallowEqual);
  const [ptUserRoleFilterOptions, resolvePtUserRoleOptionToRoleId] =
    usePtUserRoleFilterOptions();
  const shiftRateFilterOptions = useShiftRateFilterOptions();
  const [controlledTableState, setControlledTableState] =
    useState<ControlledTableState>({
      sorting: [{ id: "shiftDutyStartTime", desc: false }],
      grouping: ["shiftDate"],
    });
  const sortingQueryParam = useSortingQueryParam(controlledTableState.sorting);
  const meUser = useMeUser();
  const [exportShiftRequest] = useExportShiftRequest();

  const meStations: Station[] | "all" = useMeUserStations();

  const meWorkingStationIds = useMemo<number[] | null>(() => {
    if (meStations === "all" || meStations.length === 0) {
      return null;
    }
    return meStations.reduce(
      (acc: number[], curr: Station) => [
        ...acc,
        ...curr.workingStations.map((ws) => ws.id),
      ],
      []
    );
  }, [meStations]);

  const [filterState, setFilterState] = useState<IncomingTabFilterState>(
    getDefaultFilterState(savedFilter)
  );

  const queryOptions = useMemo<UseIncomingTabQueryOptions>(() => {
    const shiftDateRange = getDateRangeFromFilter(filterState.shiftDateRange);
    return {
      pageIndex: filterState.pageIndex,
      pageSize: filterState.pageSize,
      shiftAfter: shiftDateRange?.after?.toISO(),
      shiftBefore: shiftDateRange?.before?.toISO(),
      shiftSearchString: filterState.searchString,
      shiftRateId:
        filterState.shiftRate != null ? [filterState.shiftRate] : null,
      ptRoleId: resolvePtUserRoleOptionToRoleId(filterState.ptRole),
      shiftWorkingStationId:
        filterState.workingStationId != null
          ? [filterState.workingStationId]
          : null,
      ptStationId:
        filterState.ptStationId != null ? [filterState.ptStationId] : null,
      sort: sortingQueryParam,
    };
  }, [filterState, sortingQueryParam, resolvePtUserRoleOptionToRoleId]);

  useEffect(() => {
    scrollTo({ top: 0 });
  }, [filterState, sortingQueryParam]);

  const viewState = useIncomingTabQuery(queryOptions);
  const onChangeShiftDateRange = useCallback(
    (shiftDateRange: ShiftDateRangeFilter) => {
      dispatch(
        saveIncomingPtTabFilter({
          shiftDateRange: shiftDateRange,
        })
      );
      setFilterState((prev) => ({ ...prev, pageIndex: 0, shiftDateRange }));
    },
    [dispatch]
  );
  const onChangePtRole = useCallback(
    (ptRole: string | null) => {
      dispatch(
        saveIncomingPtTabFilter({
          ptRole: ptRole,
        })
      );
      setFilterState((prev) => ({ ...prev, pageIndex: 0, ptRole }));
    },
    [dispatch]
  );
  const onChangeShiftRate = useCallback(
    (shiftRate: number | null) => {
      dispatch(
        saveIncomingPtTabFilter({
          shiftRate: shiftRate,
        })
      );
      setFilterState((prev) => ({ ...prev, pageIndex: 0, shiftRate }));
    },
    [dispatch]
  );
  const onChangeWorkingStation = useCallback(
    (workingStationId: number | null) => {
      dispatch(
        saveIncomingPtTabFilter({
          workingStationId: workingStationId,
        })
      );
      setFilterState((prev) => ({ ...prev, pageIndex: 0, workingStationId }));
    },
    [dispatch]
  );

  const onChangePtStation = useCallback(
    (stationId: number | null) => {
      dispatch(
        saveIncomingPtTabFilter({
          ptStationId: stationId,
        })
      );
      setFilterState((prev) => ({
        ...prev,
        pageIndex: 0,
        ptStationId: stationId,
      }));
    },
    [dispatch]
  );
  const onChangeSearchString = useCallback(
    (v: string | null) => {
      if (v == null || v.trim() === "") {
        dispatch(
          saveIncomingPtTabFilter({
            searchString: undefined,
          })
        );
        setFilterState((prev) => ({
          ...prev,
          pageIndex: 0,
          searchString: undefined,
        }));
        return;
      }
      dispatch(
        saveIncomingPtTabFilter({
          searchString: v,
        })
      );
      setFilterState((prev) => ({ ...prev, pageIndex: 0, searchString: v }));
    },
    [dispatch]
  );
  const workingStationFilter = useMemo<
    FilterProps<number | null> | undefined
  >(() => {
    if (meUser == null || meUser.adminRole === "MANAGER") {
      return undefined;
    }
    return {
      value: filterState.workingStationId ?? null,
      onChange: onChangeWorkingStation,
    };
  }, [filterState.workingStationId, meUser, onChangeWorkingStation]);
  const handleOnClickPage = useCallback((pageIndex: number) => {
    setFilterState((prev) => ({ ...prev, pageIndex }));
  }, []);

  const onClickColumnHeader = useOnClickTableColumnHeader<IncomingTabRecord>(
    setControlledTableState,
    undefined,
    undefined,
    new Set<string>(["shiftDate"])
  );
  const handleExport = useCallback(() => {
    exportShiftRequest({
      shiftStatus: ["active", "expired"],
      shiftRequestStatus: ["hired"],
      ...queryOptions,
      shiftWorkingStationId:
        queryOptions.shiftWorkingStationId ?? meWorkingStationIds,
    })
      .unwrap()
      .then(() => {
        showMessage({
          type: "info",
          title: t("shiftRequestList.export.started"),
        });
      })
      .catch(() => {
        showMessage({
          type: "fail",
          title: t("shiftRequestList.export.failed"),
        });
      });
  }, [queryOptions, showMessage, t, meWorkingStationIds, exportShiftRequest]);

  const isViewStateLoaded = viewState.status === ViewStateStatus.Loaded;
  return (
    <>
      <IncomingTabActionBar
        shiftDateRangeFilter={{
          value: filterState.shiftDateRange,
          onChange: onChangeShiftDateRange,
        }}
        ptRoleFilter={{
          value: filterState.ptRole,
          onChange: onChangePtRole,
          options: ptUserRoleFilterOptions,
        }}
        shiftRateFilter={{
          value: filterState.shiftRate,
          onChange: onChangeShiftRate,
          options: shiftRateFilterOptions,
        }}
        ptStationFilter={{
          value: filterState.ptStationId,
          onChange: onChangePtStation,
        }}
        workingStationFilter={workingStationFilter}
        searchString={filterState.searchString}
        onChangeSearchString={onChangeSearchString}
        onExport={handleExport}
        className="mb-6"
      />
      <IncomingTabTable
        viewState={viewState}
        tableState={controlledTableState}
        onClickColumnHeader={onClickColumnHeader}
      />
      <Pagination
        className={cn(
          "mt-3",
          "bg-white",
          "fixed",
          "bottom-0",
          "inset-x-0",
          "sm:static"
        )}
        totalPages={
          isViewStateLoaded
            ? Math.ceil(viewState.total / SHIFT_REQUEST_LIST_PAGE_SIZE)
            : 0
        }
        pageIndex={isViewStateLoaded ? viewState.pageIndex : 0}
        onClickPage={handleOnClickPage}
      />
      <div className="h-16" />
    </>
  );
};

export default IncomingPartTimeTab;
