import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import {
  SingleSelectComboBox,
  SingleSelectComboBoxElement,
} from "oneclick-component/src/components/inputs/SingleSelectComboBox";
import {
  WorkingStation,
  useLazyListWorkingStationHandlerWorkingStationsGetQuery as useListWorkingStation,
  ListWorkingStationHandlerWorkingStationsGetApiArg as ListWorkingStationArg,
  ListWorkingStationResponse,
} from "oneclick-component/src/store/apis/enhancedApi";
import { DEFAULT_PAGE_SIZE } from "../../constants/pagination";
import { dedup } from "oneclick-component/src/utils/list";
import { useShowError } from "../../hooks/useShowError";
import { WorkingStationOption } from "./model";

interface Props {
  className?: string;
  initialWorkingStationOptions?: WorkingStation[] | null;
  selectedWorkingStationId: number | null;
  onChange?: (workingStationId: number | null) => void;
  onWorkingStationSelected?: (workingStation: WorkingStation | null) => void;
  disabled?: boolean;
  nullIfEmpty?: boolean;
}

function updateQueryParamPageIndex(
  listStationArgs: ListWorkingStationArg,
  pageIndex: number | null | undefined
) {
  return {
    ...listStationArgs,
    pageIndex: pageIndex ?? undefined,
  };
}

function updateQueryParamSearchString(searchString: string) {
  return {
    q: searchString.trim() !== "" ? searchString.trim() : undefined,
    pageIndex: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  };
}

function extractStationFromOption(option: WorkingStationOption | null) {
  const optionObj = option?.object ?? null;
  const optionId = optionObj?.id ?? null;
  return {
    workingStation: optionObj,
    workingStationId: optionId,
  };
}

export const LazyLoadWorkingStationSelectionDropdown = React.memo(
  (props: Props): ReactElement => {
    const {
      className,
      initialWorkingStationOptions,
      selectedWorkingStationId,
      onChange,
      onWorkingStationSelected,
      disabled,
      nullIfEmpty,
    } = props;
    const { t } = useTranslation();
    const { showError } = useShowError();
    const comboBoxRef =
      useRef<SingleSelectComboBoxElement<WorkingStation> | null>(null);

    useEffect(() => {
      if (initialWorkingStationOptions != null) {
        for (const ws of initialWorkingStationOptions) {
          comboBoxRef.current?.addOptionToMap({
            name: ws.shortCode,
            value: ws.id.toString(),
            object: ws,
          });
        }
      }
    }, [initialWorkingStationOptions]);

    const [fetchedWorkingStations, setFetchedWorkingStations] = useState<
      WorkingStation[]
    >([]);
    const [hasMoreOptions, setHasMoreOptions] = useState(false);
    const listStationArgs = useRef<ListWorkingStationArg>({
      pageIndex: 0,
      pageSize: DEFAULT_PAGE_SIZE,
    });
    const guardLoadMore = useRef(false);

    const [listStation, { isFetching }] = useListWorkingStation();

    const workingStationOptions = useMemo<WorkingStationOption[]>(() => {
      return fetchedWorkingStations.map((s) => ({
        value: s.id.toString(),
        name: s.shortCode,
        object: s,
      }));
    }, [fetchedWorkingStations]);

    const onStationLoaded = useCallback((data: ListWorkingStationResponse) => {
      setFetchedWorkingStations((prev) => dedup([...prev, ...data.results]));
      setHasMoreOptions(data.nextPageIndex != null);
      listStationArgs.current = updateQueryParamPageIndex(
        listStationArgs.current,
        data.nextPageIndex
      );
    }, []);

    const loadWorkingStation = useCallback(
      (query: string) => {
        setHasMoreOptions(false);
        listStationArgs.current = updateQueryParamSearchString(query);
        guardLoadMore.current = true;
        setFetchedWorkingStations([]);
        listStation(listStationArgs.current, true)
          .unwrap()
          .then((data) => {
            onStationLoaded(data);
          })
          .catch((err) => {
            showError(err);
          })
          .finally(() => {
            guardLoadMore.current = false;
          });
      },
      [listStation, onStationLoaded, showError]
    );

    const loadMoreWorkingStation = useCallback(() => {
      const requestSearchString = listStationArgs.current.q;

      const targetPage = listStationArgs.current.pageIndex ?? 0;
      if (guardLoadMore.current) {
        return;
      }

      guardLoadMore.current = true;
      listStation(
        {
          ...listStationArgs.current,
          pageIndex: targetPage,
        },
        true
      )
        .unwrap()
        .then((data) => {
          // Search query updated, skip updating option list
          if (listStationArgs.current.q !== requestSearchString) {
            return;
          }
          onStationLoaded(data);
        })
        .catch((err) => {
          showError(err);
        })
        .finally(() => {
          guardLoadMore.current = false;
        });
    }, [listStation, onStationLoaded, showError]);

    const onSelectedOptionsChange = useCallback(
      (option: WorkingStationOption | null) => {
        const { workingStation, workingStationId } =
          extractStationFromOption(option);
        onChange?.(workingStationId);
        onWorkingStationSelected?.(workingStation);
      },
      [onChange, onWorkingStationSelected]
    );

    return (
      <SingleSelectComboBox<WorkingStation>
        ref={comboBoxRef}
        className={className}
        selectedItem={selectedWorkingStationId?.toString() ?? null}
        placeholder={t("stationSelectionDropdown.placeholder")}
        options={workingStationOptions}
        hasMoreOptions={hasMoreOptions}
        isLoading={isFetching}
        onLoadOptions={loadWorkingStation}
        onLoadMoreOptions={loadMoreWorkingStation}
        onOptionChange={onSelectedOptionsChange}
        isSameKeyUnselect={false}
        disabled={disabled}
        nullIfEmpty={nullIfEmpty}
      />
    );
  }
);
