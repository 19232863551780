import { ReactElement } from "react";

import { Message } from "oneclick-component/src/store/apis/enhancedApi";
import { MessageContentType } from "../type";
import { MessageBubbleTextContent } from "./MessageBubbleTextContent";
import { MessageBubbleImageContent } from "./MessageBubbleImageContent";
import { MessageBubbleVideoContent } from "./MessageBubbleVideoContent";
import { MessageBubbleAudioContent } from "./MessageBubbleAudioContent";
import { MessageBubbleDocumentsContent } from "./MessageBubbleDocumentsContent";

interface Props {
  contentType: MessageContentType;
  message: Message;
}

export const MessageBubbleContent = (props: Props): ReactElement => {
  const { contentType, message } = props;

  switch (contentType) {
    case "text":
      return <MessageBubbleTextContent message={message} />;
    case "image":
      return <MessageBubbleImageContent message={message} />;
    case "video":
      return <MessageBubbleVideoContent message={message} />;
    case "audio":
      return <MessageBubbleAudioContent message={message} />;
    case "document":
      return <MessageBubbleDocumentsContent message={message} />;
  }
};
