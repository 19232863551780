import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { OneClickCustomError } from "oneclick-component/src/models/error";

// Upload file with PUT, with header necessary for Azure Blob storage
export function useUploadFileToStorage(): (
  file: File,
  presignedUrl: string
) => Promise<void> {
  const { t } = useTranslation();
  return useCallback(
    async (file: File, presignedUrl: string) => {
      const fileContent = await file.arrayBuffer();

      const headers = new Headers();
      headers.append("X-Ms-Blob-Type", "BlockBlob");
      headers.append("X-Ms-Blob-Content-Type", file.type);

      const response = await fetch(presignedUrl, {
        method: "PUT",
        headers,
        body: fileContent,
      });

      if (!response.ok) {
        throw new OneClickCustomError(t("common.error.uplaodAssetFailed"));
      }
    },
    [t]
  );
}
