import {
  PtUser,
  PtUserVisitCount,
  PtUserWithVisitCount,
  Shift,
  useContactHandlerShiftsShiftIdContactedPtUserPtUserIdPostMutation as useContactUser,
} from "oneclick-component/src/store/apis/enhancedApi";
import React, { ReactElement, useCallback, useMemo } from "react";
import { ResponsedShiftRequestBaseWithNumbering } from "../../models/shiftRequest";
import { Button, IconButton } from "oneclick-component/src/components/Button";
import { Trans } from "react-i18next";
import cn from "classnames";
import {
  ChatIcon,
  CheckCircle,
  WarningIcon,
} from "oneclick-component/src/icon";
import { useShowError } from "../../hooks/useShowError";
import useOpenChatFromShiftRequest from "../../hooks/useOpenChatFromShiftRequest";
import { DeletedUserIcon } from "../../icon";
import { isoToDateTime } from "oneclick-component/src/utils/datetime";
import { formatShiftRequestTime } from "../../utils/shiftRequest";
import { PTUserAvatarImage } from "../PTUserAvatarImage";
import { StationBadge } from "oneclick-component/src/components/Badge";
import CCOBadge from "oneclick-component/src/components/Badge/CCOBadge";

interface TimestampDisplayProps {
  shiftRequest: ResponsedShiftRequestBaseWithNumbering;
}
const TimestampDisplay = (props: TimestampDisplayProps) => {
  const { shiftRequest } = props;
  const shiftRequestTimeDisplayString =
    shiftRequest.appliedAt == null
      ? null
      : formatShiftRequestTime(isoToDateTime(shiftRequest.appliedAt));

  const approveTimeDisplayString =
    shiftRequest.approvedAt == null
      ? null
      : formatShiftRequestTime(isoToDateTime(shiftRequest.approvedAt));
  const rejectTimeDisplayString =
    shiftRequest.rejectedAt == null
      ? null
      : formatShiftRequestTime(isoToDateTime(shiftRequest.rejectedAt));
  return (
    <p className={cn("text-xs", "font-normal")}>
      {shiftRequestTimeDisplayString == null ? null : (
        <>
          <span className="text-black/60">
            <Trans i18nKey="shiftDetail.request.appliedAt" />
          </span>{" "}
          <span className={cn("text-black/86", "mr-3")}>
            {shiftRequestTimeDisplayString}
          </span>
        </>
      )}
      {approveTimeDisplayString == null ? null : (
        <>
          <span className="text-black/60">
            <Trans i18nKey="shiftDetail.request.hiredAt" />
          </span>{" "}
          <span className={cn("text-black/86", "mr-3")}>
            {approveTimeDisplayString}
          </span>{" "}
        </>
      )}
      {rejectTimeDisplayString == null ? null : (
        <>
          <span className="text-black/60">
            <Trans i18nKey="shiftDetail.request.rejectedAt" />
          </span>{" "}
          <span className={cn("text-black/86", "mr-3")}>
            {rejectTimeDisplayString}
          </span>{" "}
        </>
      )}
      {shiftRequest.cancelCount != null ? (
        <span className={cn("block", "sm:inline-block", "text-red-500")}>
          <Trans
            i18nKey="shiftDetail.request.cancelCount"
            values={{ count: shiftRequest.cancelCount }}
          />
        </span>
      ) : null}
    </p>
  );
};

interface ShiftActionProps {
  user: PtUserWithVisitCount;
  shift: Shift;
  shiftRequest: ResponsedShiftRequestBaseWithNumbering;
  onSend?: () => void;
  isSending?: boolean;
  disabled?: boolean;
}
const ShiftAction = (props: ShiftActionProps) => {
  const { user, shift, shiftRequest, onSend, isSending, disabled } = props;

  switch (shift.status) {
    case "cancelled":
      return (
        <Button
          className={cn("mt-3", "sm:mt-0", "text-gray-600")}
          disabled={true}
          theme="white"
          iconClassName="fill-gray-600"
        >
          <Trans i18nKey="shiftDetail.action.shift.status.cancelled" />
        </Button>
      );
    default:
      break;
  }

  switch (shiftRequest.borrowingRequestStatus) {
    case "pending":
    case "contacted":
      return (
        <Button
          prefixIcon={CheckCircle}
          className={cn("mt-3", "sm:mt-0", "text-gray-600")}
          disabled={true}
          theme="white"
          iconClassName="fill-none"
        >
          <Trans
            i18nKey="shiftDetail.borrowing.status.pending"
            values={{ station: user.station!.shortCode }}
          />
        </Button>
      );
    case "rejected":
      return (
        <Button
          className={cn("mt-3", "sm:mt-0", "text-gray-600")}
          disabled={true}
          theme="white"
          iconClassName="fill-gray-600"
        >
          <Trans
            i18nKey="shiftDetail.borrowing.status.rejected"
            values={{ station: user.station!.shortCode }}
          />
        </Button>
      );
    case "approved":
    default:
      break;
  }

  switch (shiftRequest.status) {
    case "applied":
    case "contacted":
      return (
        <Button
          theme="whitePrimary"
          onClick={onSend}
          className={cn(
            "border-primary-600",
            "disabled:border-gray-300",
            "disabled:text-gray-300",
            "disabled:hover:bg-white"
          )}
          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
          disabled={isSending || disabled}
        >
          <span>
            <Trans i18nKey="shiftDetail.responsed.confirmHire" />
          </span>
        </Button>
      );
    default:
      return <></>;
  }
};

interface PTInfoProps {
  className?: string;
  disabled: boolean;
  user: PtUserWithVisitCount;
  shiftRequest: ResponsedShiftRequestBaseWithNumbering;
  userVisitCount: number;
  showStationTeam: boolean;
}

const PTInfo = (props: PTInfoProps): ReactElement => {
  const {
    className,
    disabled,
    user,
    shiftRequest,
    userVisitCount,
    showStationTeam,
  } = props;
  return (
    <div
      className={cn(
        "flex",
        "flex-row",
        {
          "opacity-40": disabled,
        },
        className
      )}
    >
      <div className={cn("flex", "items-center", "mr-8")}>
        <PTUserAvatarImage
          ptUser={user}
          override418Status={shiftRequest.ptUserShiftWeek418Status}
        />
      </div>
      <div>
        <p className={cn("font-normal", "flex", "items-center")}>
          {user.fullNameZhHk}
          {user.station != null ? (
            <StationBadge
              className="mx-2"
              station={user.station}
              stationTeam={user.stationTeam ?? null}
              showStationTeam={showStationTeam}
            />
          ) : null}
          {user.role?.name === "CCO" ? <CCOBadge /> : null}
        </p>
        <p className={cn("text-sm", "font-normal", "mb-1")}>
          <span className="text-black/60">ID</span>{" "}
          <span className="text-black/86">{user.eid}</span>{" "}
          <span className="text-black/12">|</span>{" "}
          <span className="text-black/60">
            <Trans i18nKey="shiftDetail.visitCount" />
          </span>{" "}
          <span className="text-black/86">{userVisitCount}</span>
        </p>
        <TimestampDisplay shiftRequest={shiftRequest} />
      </div>
    </div>
  );
};

interface Props {
  user: PtUserWithVisitCount;
  shift: Shift;
  clashedShifts: Shift[];
  shiftRequest: ResponsedShiftRequestBaseWithNumbering;
  onHireFactory: (ptUser: PtUser[]) => () => void;
  canHire: boolean;
  unread?: boolean;
  showChatButton?: boolean;
  showStationTeam?: boolean;
  disabled?: boolean;
  notifyContacted?: boolean;
  isSending: boolean;
}
const ResponsedPTUserRequestRow = (props: Props): React.ReactElement => {
  const {
    user,
    shiftRequest,
    shift,
    clashedShifts,
    unread,
    onHireFactory,
    isSending,
    canHire,
    showChatButton = false,
    showStationTeam = false,
    disabled = false,
    notifyContacted = false,
  } = props;
  const { showError } = useShowError();
  const [openChat, isLoading] = useOpenChatFromShiftRequest();
  const [contactUser] = useContactUser();

  const handleStartChatClick = useCallback(() => {
    openChat(shift, user, shiftRequest);
    if (notifyContacted && shiftRequest.status === "applied") {
      contactUser({
        shiftId: shift.id,
        ptUserId: user.id,
        isPartnerContact: false,
      })
        .then()
        .catch((e) => {
          showError(e, "chatDialog.messageList.startChat.error.title");
        });
    }
  }, [
    openChat,
    shift,
    user,
    shiftRequest,
    notifyContacted,
    contactUser,
    showError,
  ]);

  const disableChatButton = isLoading || user.isDeleted;

  const disableShiftAction = useMemo<boolean>(
    () => shift.status === "expired" || !canHire || clashedShifts.length > 0,
    [canHire, clashedShifts.length, shift.status]
  );

  const userVisitCount = useMemo<number>(() => {
    const userVisit: PtUserVisitCount | undefined = user.visitCounts?.find(
      (station: PtUserVisitCount) =>
        station.workingStationShortCode === shift.workingStation.shortCode
    );
    return userVisit?.visitCount ?? 0;
  }, [user, shift]);

  return (
    <div
      className={cn(
        "rounded-lg",
        "border",
        "border-black/10",
        "p-4",
        "pl-10",
        "flex",
        "flex-row",
        "items-center",
        "flex-wrap",
        "relative",
        disabled && ["bg-neutral-900/[.04]"]
      )}
    >
      {unread && !user.isDeleted ? (
        <div
          className={cn(
            "w-[6px]",
            "h-[6px]",
            "bg-red-600",
            "absolute",
            "rounded-full",
            "right-2",
            "top-2"
          )}
        />
      ) : null}
      <div
        className={cn(
          "absolute",
          "left-0",
          "h-full",
          "w-8",
          "border-r",
          "border-black/10",
          "flex",
          "justify-center",
          "items-center"
        )}
      >
        <span
          className={cn("text-xs", "leading-4", "font-medium", "text-black/60")}
        >
          {shiftRequest.index}
        </span>
      </div>

      <PTInfo
        className={cn("flex-none", "sm:w-auto", "mr-3")}
        disabled={disabled}
        user={user}
        showStationTeam={showStationTeam}
        userVisitCount={userVisitCount}
        shiftRequest={shiftRequest}
      />
      <div className={cn("basis-full", "sm:basis-0")} />
      {user.isDeleted ? (
        <div
          className={cn(
            "flex",
            "sm:flex-1",
            "sm:justify-end",
            "items-center",
            "mt-3",
            "sm:mt-0",
            "gap-1",
            "opacity-40",
            "mr-3",
            "last:mr-0"
          )}
        >
          <DeletedUserIcon className={cn("w-4", "h-4")} />
          <span
            className={cn(
              "text-xs",
              "text-black/60",
              "font-medium",
              "whitespace-nowrap"
            )}
          >
            <Trans i18nKey="partTime.shiftStatusRow.partTimeSuspended" />
          </span>
        </div>
      ) : (
        <div
          className={cn(
            "flex",
            "sm:flex-1",
            "justify-end",
            disabled && "opacity-40"
          )}
        >
          <div className="relative">
            <ShiftAction
              user={user}
              shift={shift}
              shiftRequest={shiftRequest}
              onSend={onHireFactory([user])}
              isSending={isSending}
              disabled={disableShiftAction}
            />
            {clashedShifts.length > 0 ? (
              <div
                className={cn(
                  "absolute",
                  "mt-1",
                  "flex",
                  "items-center",
                  "gap-x-1",
                  "ml-1"
                )}
              >
                {/* FIXME: should not use `ml-1` for horizontally centre aligning this */}
                <WarningIcon className={cn("w-3", "h-3", "fill-red-400")} />
                <p className={cn("text-xs", "text-red-400")}>
                  <Trans i18nKey="partTime.shiftStatusRow.hasClashedShift" />
                </p>
              </div>
            ) : null}
          </div>
        </div>
      )}
      {showChatButton ? (
        <div
          className={cn("mt-3", "sm:mt-0", "ml-3", {
            "opacity-40": disableChatButton,
          })}
        >
          <IconButton
            icon={ChatIcon}
            theme="whitePrimary"
            disabled={disableChatButton}
            onClick={handleStartChatClick}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ResponsedPTUserRequestRow;
