import { DateTime } from "luxon";
import { formatDateTime } from "./datetime";
import { WeekOfYear } from "../store/apis/enhancedApi";

export function weekOfYearToDateTimeRange(
  year: number,
  week: number
): { start: DateTime; end: DateTime } {
  const dt = DateTime.fromObject({
    weekYear: year,
    weekNumber: week,
  });
  return {
    start: dt.startOf("week"), // Monday 00:00
    end: dt.endOf("week"), // Sunday 23:59
  };
}

export const translateWeek = (options: {
  year: number;
  weekNumber: number;
  t: (key: string) => string;
  startDisplayFormat?: string;
  endDisplayFormat?: string;
}): string => {
  const {
    year,
    weekNumber,
    t,
    startDisplayFormat = "partTime.list.tab.workStatus418.date.displayFormat",
    endDisplayFormat = "partTime.list.tab.workStatus418.date.endDisplayFormat",
  } = options;

  const { start: weekStartMonday, end: weekEndSunday } =
    weekOfYearToDateTimeRange(year, weekNumber);
  const weekStartSunday = weekStartMonday.minus({ days: 1 });
  const weekEndSaturday = weekEndSunday.minus({ days: 1 });

  const dateDisplayFormat = t(startDisplayFormat);
  if (weekStartSunday.month === weekEndSaturday.month) {
    return `${formatDateTime(
      weekStartSunday,
      dateDisplayFormat
    )} - ${formatDateTime(weekEndSaturday, t(endDisplayFormat))}`;
  }
  return `${formatDateTime(
    weekStartSunday,
    dateDisplayFormat
  )} - ${formatDateTime(weekEndSaturday, dateDisplayFormat)}`;
};

export const getPreviousWeek = (
  woy: WeekOfYear,
  numWeeks: number
): WeekOfYear => {
  const currentWeekOfYearAsDt = DateTime.fromObject({
    weekYear: woy.year,
    weekNumber: woy.weekNumber,
  });
  const previousWeekOfYearAsDt = currentWeekOfYearAsDt.minus({
    days: 7 * numWeeks,
  });
  return {
    year: previousWeekOfYearAsDt.weekYear,
    weekNumber: previousWeekOfYearAsDt.weekNumber,
  };
};

export const getNextWeek = (woy: WeekOfYear, numWeeks: number): WeekOfYear => {
  const currentWeekOfYearAsDt = DateTime.fromObject({
    weekYear: woy.year,
    weekNumber: woy.weekNumber,
  });
  const nextWeekOfYearAsDt = currentWeekOfYearAsDt.plus({
    days: 7 * numWeeks,
  });
  return {
    year: nextWeekOfYearAsDt.weekYear,
    weekNumber: nextWeekOfYearAsDt.weekNumber,
  };
};
