import * as z from "zod";

export const assetSchema = z.object({
  id: z.number(),
  key: z.string(),
  url: z.string().nullable(),
  filename: z.string(),
  contentType: z.string(),
  contentSize: z.number().nullable(),
});
