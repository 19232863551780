import React, { useCallback, useMemo, useState } from "react";
import cn from "classnames";
import { Modal } from "oneclick-component/src/components/Modal";
import { Select } from "oneclick-component/src/components/inputs";
import { Trans, useTranslation } from "react-i18next";
import {
  ShiftCancelReason,
  useBatchCancelShiftsHandlerShiftsBatchCancelPostMutation,
} from "oneclick-component/src/store/apis/enhancedApi";
import { useShowError } from "../../hooks/useShowError";
import { localizeString } from "oneclick-component/src/utils/localize";
import { Dialog } from "@headlessui/react";
import { Button } from "oneclick-component/src/components/Button";
import useShowMessage from "oneclick-component/src/hooks/useShowMessage";

interface Props {
  shiftIds: number[];
  cancelReasons: ShiftCancelReason[];
  onClose: () => void;
  onCancelShiftSuccess?: () => void;
  isOpen: boolean;
  uncancellableShiftIds?: number[];
}

const CancelShiftDialog = (props: Props): React.ReactElement | null => {
  const {
    shiftIds,
    cancelReasons,
    onClose,
    isOpen,
    onCancelShiftSuccess,
    uncancellableShiftIds,
  } = props;

  const cancellableShiftIds = shiftIds.filter(
    (sid) => !uncancellableShiftIds?.includes(sid)
  );
  const { t } = useTranslation();
  const { showError } = useShowError();
  const showMessage = useShowMessage();
  const [batchCancelShift, { isLoading }] =
    useBatchCancelShiftsHandlerShiftsBatchCancelPostMutation();

  const descriptionType:
    | "allCancellable"
    | "someCancellable"
    | "noneCancellable" = useMemo(() => {
    if (
      uncancellableShiftIds == null ||
      cancellableShiftIds.length === shiftIds.length
    ) {
      return "allCancellable";
    }

    if (
      cancellableShiftIds.length > 0 &&
      cancellableShiftIds.length < shiftIds.length
    ) {
      return "someCancellable";
    }

    if (cancellableShiftIds.length === 0) {
      return "noneCancellable";
    }

    // fallback
    return "noneCancellable";
  }, [cancellableShiftIds.length, shiftIds.length, uncancellableShiftIds]);

  const cancelReasonOptions = useMemo(() => {
    return cancelReasons.map((reason) => {
      return {
        name: localizeString(reason.description),
        value: reason.id,
      };
    });
  }, [cancelReasons]);
  const [selectedReason, setSelectedReason] = useState(
    cancelReasonOptions[0]?.value
  );
  const onCancelReasonChange = useCallback((value: number) => {
    setSelectedReason(value);
  }, []);
  const onClickCancelShift = useCallback(() => {
    if (cancellableShiftIds.length > 0) {
      batchCancelShift({
        batchCancelShiftRequest: {
          shiftIds: cancellableShiftIds,
          cancelReasonId: selectedReason,
        },
      })
        .unwrap()
        .then(() => {
          showMessage({
            title: t("shift.cancel.toast.success.title"),
            type: "success",
            showDismiss: true,
          });
          onClose();
          onCancelShiftSuccess?.();
        })
        .catch((e) => {
          showError(e, t("shift.cancel.toast.fail.title"));
        });
    }
  }, [
    cancellableShiftIds,
    batchCancelShift,
    selectedReason,
    showMessage,
    t,
    onClose,
    onCancelShiftSuccess,
    showError,
  ]);

  const handleOnClose = useCallback(() => {
    if (isLoading) {
      return;
    }
    onClose();
  }, [isLoading, onClose]);

  const shouldAllowCancel =
    descriptionType === "allCancellable" ||
    descriptionType === "someCancellable";

  if (shiftIds.length === 0) {
    return null;
  }
  return (
    <Modal isOpen={isOpen} onClose={handleOnClose} hasXMarkButton={false}>
      <div className={cn("sm:flex", "sm:items-start", "w-full")}>
        <div
          className={cn(
            "mt-3",
            "text-center",
            "sm:mt-0",
            "sm:text-left",
            "w-full"
          )}
        >
          <Dialog.Title className={cn("text-black", "text-lg", "font-medium")}>
            <Trans i18nKey="shift.cancel.dialog.title" />
          </Dialog.Title>
          <div className="mt-2">
            <h2
              className={cn("font-medium", "text-sm", "text-black/60", "mt-3")}
            >
              <Trans
                i18nKey={`shift.cancel.dialog.description.${descriptionType}`}
                values={{
                  cancellableShiftIds: cancellableShiftIds.join(", "),
                  uncancellableShiftIds: uncancellableShiftIds?.join(", "),
                }}
              />
            </h2>
            {shouldAllowCancel ? (
              <>
                <h6
                  className={cn(
                    "font-normal",
                    "text-sm",
                    "text-black/90",
                    "mt-5"
                  )}
                >
                  <Trans i18nKey="shift.cancel.dialog.select.title" />
                </h6>
                <Select
                  className="mt-1"
                  options={cancelReasonOptions}
                  onChange={onCancelReasonChange}
                  value={selectedReason}
                />
              </>
            ) : null}
          </div>
        </div>
      </div>
      <div
        className={cn(
          "mt-5",
          "sm:mt-4",
          "flex",
          "flex-row",
          "justify-center",
          "sm:justify-end",
          "items-center"
        )}
      >
        <Button
          className={cn(
            "font-medium",
            "flex-1",
            "self-stretch",
            "sm:flex-none"
          )}
          onClick={handleOnClose}
          theme="white"
        >
          <Trans i18nKey="shift.cancel.dialog.button.cancel" />
        </Button>
        {shouldAllowCancel ? (
          <Button
            className={cn(
              "font-medium",
              "ml-3",
              "flex-1",
              "sm:flex-none",
              "sm:min-w-30",
              "text-rose-500"
            )}
            theme="white"
            onClick={onClickCancelShift}
            isLoading={isLoading}
          >
            <Trans i18nKey="shift.cancel.dialog.button.action" />
          </Button>
        ) : null}
      </div>
    </Modal>
  );
};

export default CancelShiftDialog;
