import z from "zod";

const stationRoleSchema = z.object({
  stationId: z.number().int(),
  role: z.string(),
});

export const createBaseUserFormSchema = z.object({
  nameEn: z.string().trim().min(1),
  nameZhHk: z.string().trim().min(1),
  adminRole: z.enum(["MANAGER", "ADMIN", "LANDLORD"]),
  email: z.string().trim().min(1),
});

export const createAdminUserFormSchema = createBaseUserFormSchema.extend({
  adminRole: z.literal("ADMIN"),
});

export const createManagerUserFormSchema = createBaseUserFormSchema.extend({
  adminRole: z.literal("MANAGER"),
  stationRoles: z.array(stationRoleSchema).min(1),
});

export const createLandlordUserFormSchema = createBaseUserFormSchema.extend({
  adminRole: z.literal("LANDLORD"),
  lineCode: z.string().trim().min(1),
});

export const createUserFormSchema = z.union([
  createAdminUserFormSchema,
  createManagerUserFormSchema,
  createLandlordUserFormSchema,
]);

export type CreateUserFormStationRole = z.infer<typeof stationRoleSchema>;
export type CreateAdminUserFormValues = z.infer<
  typeof createAdminUserFormSchema
>;
export type CreateManagerUserFormValues = z.infer<
  typeof createManagerUserFormSchema
>;
export type CreateLandlorfUserFormValues = z.infer<
  typeof createLandlordUserFormSchema
>;
export type CreateUserFormValues = z.infer<typeof createUserFormSchema>;
