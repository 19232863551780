import * as z from "zod";

export const fifteenthInstantSchema = z
  .string()
  .regex(/^(2[0-3]|[01][0-9]):(00|15|30|45)|24:00$/); // HH:mm ... mm in 00 | 15 | 30 | 45, also allow 24:00

export const regularShiftSchema = z.object({
  dayOfWeek: z.number().int().min(0).max(6),
  startTime: fifteenthInstantSchema,
  endTime: fifteenthInstantSchema,
});

export const intraDayRegularShiftSchema = z.object({
  startTime: fifteenthInstantSchema,
  endTime: fifteenthInstantSchema,
});

export type IntraDayRegularShift = z.infer<typeof intraDayRegularShiftSchema>;
export type RegularShift = z.infer<typeof regularShiftSchema>;
export type FifteenthInstant = z.infer<typeof fifteenthInstantSchema>;

export function compareByStartTimeAscending(
  v1: IntraDayRegularShift,
  v2: IntraDayRegularShift
): number {
  if (v1.startTime < v2.startTime) return -1;
  else if (v1.startTime === v2.startTime) return 0;
  return 1;
}
