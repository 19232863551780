import { useState, useCallback } from "react";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import {
  PtUserWithClashedShift,
  Shift,
  ShiftCancelReason,
  Weekly418Status,
  useGetShiftByIdHandlerShiftsShiftIdGetQuery,
  useListShiftCancelReasonsHandlerShiftsCancelReasonsGetQuery,
  useListShiftRequestsWithNumberingHandlerShiftsShiftIdRequestsGetQuery as useListShiftRequestQuery,
  useLazyListShiftRequestsWithNumberingHandlerShiftsShiftIdRequestsGetQuery as useLazyListShiftRequestQuery,
  ListShiftRequestsWithNumberingHandlerShiftsShiftIdRequestsGetApiResponse as ListShiftRequestResponse,
  useLazyListPtUserShiftCancelByShiftHandlerPtUserShiftCancelsShiftIdGetQuery as useLazyListCancelRecordQuery,
  ListPtUserShiftCancelByShiftHandlerPtUserShiftCancelsShiftIdGetApiResponse as ListCancelledRecordResponse,
} from "oneclick-component/src/store/apis/enhancedApi";
import useInfiniteQuery, {
  UseInfiniteQueryReturnValue,
  UseInfiniteLoadQueryResponse,
} from "oneclick-component/src/hooks/useInfiniteQuery";
import { HiredShiftRequestBaseWithNumbering } from "../../models/shiftRequest";

const defaultPageSize = 20;

interface UseListShiftRequestQueryReturnData<T> {
  shiftRequests?: T[];
  ptUsersWithClashedShifts?: PtUserWithClashedShift[];
}
interface UseListHiredShiftRequestQueryReturn {
  data: UseListShiftRequestQueryReturnData<HiredShiftRequestBaseWithNumbering>;
  error?: FetchBaseQueryError | SerializedError;
  isLoading: boolean;
}
const useListHiredShiftRequestQuery = (
  shiftId: number
): UseListHiredShiftRequestQueryReturn => {
  const { data: _shiftRequestResponse, error: shiftRequestError } =
    useListShiftRequestQuery({
      shiftId,
      statusList: ["hired"],
      sort: "hiredAt+,appliedAt+",
      shouldShowDeleted: true,
      pageIndex: 0,
      pageSize: 1000, // assume hired pt is limited in real life
    });

  const { results, ptUsersWithClashedShifts } = _shiftRequestResponse ?? {};
  const isLoading = results == null;
  const hiredShiftRequests = results?.map(
    (req) => req as HiredShiftRequestBaseWithNumbering
  );

  return {
    data: { shiftRequests: hiredShiftRequests, ptUsersWithClashedShifts },
    error: shiftRequestError,
    isLoading,
  };
};

const useListRequestedShiftRequestQuery = (
  shiftId: number,
  pageSize: number
): UseInfiniteLoadQueryResponse<ListShiftRequestResponse> => {
  const [pageIndex, setPageIndex] = useState(0);
  const { data, isLoading, isFetching } = useListShiftRequestQuery({
    shiftId,
    statusList: ["requested"],
    pageIndex: 0,
    pageSize: pageSize,
  });
  const [loadMore] = useLazyListShiftRequestQuery();

  const fetchNextPage = useCallback(() => {
    loadMore({
      shiftId: shiftId,
      statusList: ["requested"],
      pageIndex: pageIndex + 1,
      pageSize: pageSize,
    })
      .unwrap()
      .catch((e) => console.error(e));
    setPageIndex((prev) => prev + 1);
  }, [loadMore, pageIndex, shiftId, pageSize]);

  return {
    data,
    isLoading,
    isFetching,
    fetchNextPage,
  };
};

export interface UseListResponsedShiftRequestQueryParams {
  includeStationIds?: number[];
  excludeStationIds?: number[];
  ptUser418Status?: Weekly418Status;
}
const useListResponsedShiftRequestQuery = (
  shiftId: number,
  pageSize: number,
  filterParams?: UseListResponsedShiftRequestQueryParams
): UseInfiniteLoadQueryResponse<ListShiftRequestResponse> => {
  const [pageIndex, setPageIndex] = useState(0);
  const { data, isLoading, isFetching } = useListShiftRequestQuery({
    shiftId,
    statusList: ["applied", "contacted"],
    ...filterParams,
    sort: "appliedAt+,ptUserId+",
    pageIndex: 0,
    pageSize: pageSize,
  });
  const [loadMore] = useLazyListShiftRequestQuery();

  const fetchNextPage = useCallback(() => {
    loadMore({
      shiftId,
      statusList: ["applied", "contacted"],
      ...filterParams,
      sort: "appliedAt+,ptUserId+",
      pageIndex: pageIndex + 1,
      pageSize: pageSize,
    })
      .unwrap()
      .catch((e) => console.error(e));
    setPageIndex((prev) => prev + 1);
  }, [loadMore, pageIndex, shiftId, pageSize, filterParams]);

  return {
    data,
    isLoading,
    isFetching,
    fetchNextPage,
  };
};

export interface UseListCancelledRecordQueryParams {
  includeStationIds?: number[];
  excludeStationIds?: number[];
  ptUser418Status?: Weekly418Status;
}
const useListCancelledRecordQuery = (
  shiftId: number,
  pageIndex: number,
  pageSize: number
): UseInfiniteQueryReturnValue<ListCancelledRecordResponse> => {
  const params = {
    shiftId,
    pageIndex: pageIndex,
    pageSize: pageSize,
  };
  const data = useInfiniteQuery<ListCancelledRecordResponse>(
    useLazyListCancelRecordQuery,
    params
  );
  return data;
};

interface UseShiftDetailScreenQueryReturn {
  shift?: Shift;
  hiredShiftRequestsQuery: UseListHiredShiftRequestQueryReturn;
  respondedShiftRequestsQuery: UseInfiniteLoadQueryResponse<ListShiftRequestResponse>;
  requestedShiftRequestsQuery: UseInfiniteLoadQueryResponse<ListShiftRequestResponse>;
  cancelledRecordQuery: UseInfiniteQueryReturnValue<ListCancelledRecordResponse>;
  cancelReasons?: ShiftCancelReason[];
  error?: FetchBaseQueryError | SerializedError;
  isLoading: boolean;
  isShiftDetailLoading: boolean;
  isHiredShiftRequestsLoading: boolean;
}

// Note(peter): Disabling complexity check due to verbose loading/error state checking

export const useShiftDetailScreenQuery = (
  shiftId: number,
  responsedRequestFilter?: UseListResponsedShiftRequestQueryParams
): UseShiftDetailScreenQueryReturn => {
  const {
    data: _shift,
    error: getShiftError,
    isLoading: isShiftDetailLoading,
  } = useGetShiftByIdHandlerShiftsShiftIdGetQuery({
    shiftId,
  });
  const {
    data: hiredShiftRequestsQueryData,
    error: hiredShiftRequestsError,
    isLoading: isHiredShiftRequestsLoading,
  } = useListHiredShiftRequestQuery(shiftId);
  const respondedShiftRequestsQueryData = useListResponsedShiftRequestQuery(
    shiftId,
    defaultPageSize,
    responsedRequestFilter
  );
  const requestedShiftRequestsQueryData = useListRequestedShiftRequestQuery(
    shiftId,
    defaultPageSize
  );

  const cancelledRecordQueryData = useListCancelledRecordQuery(
    shiftId,
    0,
    defaultPageSize
  );

  const { data: _cancelReasons, error: getCancelReasonsError } =
    useListShiftCancelReasonsHandlerShiftsCancelReasonsGetQuery();

  const { shift } = _shift ?? {};
  const { cancelReasons } = _cancelReasons ?? {};

  const isLoading =
    shift == null || cancelReasons == null || isHiredShiftRequestsLoading;

  const error =
    getShiftError ?? getCancelReasonsError ?? hiredShiftRequestsError;

  return {
    shift,
    hiredShiftRequestsQuery: {
      data: hiredShiftRequestsQueryData,
      error: hiredShiftRequestsError,
      isLoading: isHiredShiftRequestsLoading,
    },
    respondedShiftRequestsQuery: respondedShiftRequestsQueryData,
    requestedShiftRequestsQuery: requestedShiftRequestsQueryData,
    cancelledRecordQuery: cancelledRecordQueryData,
    cancelReasons,
    error,
    isLoading,
    isShiftDetailLoading,
    isHiredShiftRequestsLoading,
  };
};
