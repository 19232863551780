import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { SingleSelectComboBox } from "oneclick-component/src/components/inputs/SingleSelectComboBox";
import { BriefStation } from "oneclick-component/src/store/apis/enhancedApi";
import { sort } from "oneclick-component/src/utils/list";

import { StationOption } from "./model";

function filterStationOptions(options: StationOption[], query: string) {
  const trimmedQuery = query.toLowerCase().trim();
  if (trimmedQuery.length === 0) {
    return options;
  }
  return options.filter((op) => {
    const station = op.object;
    return (
      station.shortCode.toLowerCase().includes(trimmedQuery) ||
      station.name.en!.toLowerCase().includes(trimmedQuery) ||
      station.name.zhHk!.toLowerCase().includes(trimmedQuery)
    );
  });
}

interface Props {
  className?: string;
  inputClassName?: string;
  selectedStationId: number | null;
  stations: BriefStation[];
  onChange?: (stationId: number | null) => void;
  onStationSelected?: (station: BriefStation | null) => void;
  disabled?: boolean;
  nullIfEmpty?: boolean;
}

export const EagerLoadStationSelectionDropdown = React.memo(
  (props: Props): ReactElement => {
    const {
      className,
      inputClassName,
      selectedStationId,
      stations,
      onChange,
      onStationSelected,
      disabled,
      nullIfEmpty,
    } = props;
    const { t } = useTranslation();

    const [query, setQuery] = useState("");

    const allStationOptions = useMemo<StationOption[]>(() => {
      return stations.map((s) => ({
        value: s.id.toString(),
        name: s.shortCode,
        object: s,
      }));
    }, [stations]);

    const stationOptions = useMemo(() => {
      const filtered = filterStationOptions(allStationOptions, query);
      return sort(filtered, [
        {
          key: "name",
          direction: "asc",
        },
      ]);
    }, [allStationOptions, query]);

    const onLoadOptions = useCallback((query: string) => {
      setQuery(query);
    }, []);

    const onSelectedOptionsChange = useCallback(
      (option: StationOption | null) => {
        onChange?.(option?.object.id ?? null);
        onStationSelected?.(option?.object ?? null);
      },
      [onChange, onStationSelected]
    );

    return (
      <SingleSelectComboBox<BriefStation>
        className={className}
        inputClassName={inputClassName}
        options={stationOptions}
        selectedItem={selectedStationId?.toString() ?? null}
        placeholder={t("stationSelectionDropdown.placeholder")}
        isLoading={false}
        hasMoreOptions={false}
        onLoadOptions={onLoadOptions}
        onOptionChange={onSelectedOptionsChange}
        disabled={disabled}
        nullIfEmpty={nullIfEmpty}
      />
    );
  }
);
