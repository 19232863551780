import React, {
  useCallback,
  useState,
  ReactElement,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  FloatingPortal,
  autoUpdate,
  flip,
  shift,
  size,
  autoPlacement,
  useFloating,
} from "@floating-ui/react";
import { useDebounce } from "react-use";
import { Combobox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import cn from "classnames";

import { LoadingSpinner } from "../../LoadingSpinner";
import { ComboBoxOptions } from "./ComboBoxOptions";
import {
  SingleSelectComboBoxOption,
  SingleSelectComboBoxElement,
} from "./model";

export interface SingleSelectComboBoxProps<T> {
  className?: string;
  inputClassName?: string;
  options: SingleSelectComboBoxOption<T>[];
  selectedItem: string | null;
  placeholder?: string;
  hasMoreOptions?: boolean;
  onOptionChange: (option: SingleSelectComboBoxOption<T> | null) => void;
  onLoadOptions: (query: string) => void;
  onLoadMoreOptions?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
  isSameKeyUnselect?: boolean;
  customRenderOptions?: (
    selected: boolean,
    active: boolean,
    option: SingleSelectComboBoxOption<T>
  ) => ReactElement;
  nullIfEmpty?: boolean;
}

function SingleSelectComboBoxImpl<T>(
  props: SingleSelectComboBoxProps<T>,
  ref: React.ForwardedRef<SingleSelectComboBoxElement<T>>
): ReactElement {
  const {
    className,
    inputClassName,
    options,
    selectedItem,
    placeholder,
    hasMoreOptions,
    onOptionChange,
    onLoadOptions,
    onLoadMoreOptions,
    customRenderOptions,
    disabled,
    isSameKeyUnselect = true,
    isLoading = false,
    nullIfEmpty = true, // in case empty + not null is intended
  } = props;

  const [query, setQuery] = useState<string>("");
  const [optionMap, setOptionMap] = useState<
    Record<string, SingleSelectComboBoxOption<T>>
  >({});

  // NOTE: workaround in edit form selected option not loaded initially
  useImperativeHandle(ref, () => {
    return {
      addOptionToMap: (opt) => {
        setOptionMap((prev) => {
          const newMap = { ...prev };
          newMap[opt.value] = opt;
          return newMap;
        });
      },
    };
  });

  const { refs, floatingStyles } = useFloating({
    whileElementsMounted: autoUpdate,
    middleware: [
      autoPlacement({
        allowedPlacements: ["bottom", "bottom-start", "bottom-end"],
      }),
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            minWidth: "150px",
          });
        },
      }),
      flip(),
      shift(),
    ],
  });

  useEffect(() => {
    setOptionMap((prev) => {
      const newMap = { ...prev };
      for (const opt of options) {
        newMap[opt.value] = opt;
      }
      return newMap;
    });
  }, [options]);

  useEffect(() => {
    onLoadOptions(query);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useDebounce(
    () => {
      onLoadOptions(query);
    },
    250,
    [query]
  );

  const _onChange = useCallback(
    (selectedOptionKey: string | null) => {
      const option =
        selectedOptionKey != null ? optionMap[selectedOptionKey] : null;
      onOptionChange(option);
    },
    [onOptionChange, optionMap]
  );

  const handleSelect = useCallback(
    (itemKey: string) => {
      if (selectedItem === itemKey) {
        if (isSameKeyUnselect) {
          _onChange(null);
        }
      } else {
        _onChange(itemKey);
      }
    },
    [_onChange, selectedItem, isSameKeyUnselect]
  );

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setQuery(e.target.value);
    },
    [setQuery]
  );

  const renderInputDisplayValue = useCallback(
    (itemValue: string) => {
      if (itemValue in optionMap) {
        return optionMap[itemValue].name;
      }
      return "";
    },
    [optionMap]
  );

  const resetQuery = useCallback(() => {
    setQuery("");
  }, []);

  return (
    <Combobox
      value={selectedItem}
      onChange={handleSelect}
      multiple={false}
      disabled={disabled}
      // @ts-expect-error // somehow `multiple` is parsed as true instead of false, making `nullable` true instead of boolean
      nullable={nullIfEmpty} // ref https://headlessui.com/react/combobox#allowing-empty-values
    >
      <div ref={refs.setReference} className={cn("relative", className)}>
        <div
          className={cn(
            "relative",
            "flex",
            "flex-row",
            "w-full",
            "cursor-default",
            "rounded-md",
            "text-left",
            "ring-1",
            "ring-gray-300",
            "focus:outline-none",
            "sm:text-sm",
            {
              "bg-gray-200": disabled,
              "bg-white": !disabled,
            },
            inputClassName
          )}
        >
          <div
            className={cn(
              "flex",
              "flex-row",
              "items-center",
              "flex-1",
              "flex-wrap"
            )}
          >
            <Combobox.Input
              onChange={onInputChange}
              displayValue={renderInputDisplayValue}
              className={cn(
                "py-[0.5625rem]",
                "pl-3",
                "border-none",
                "ring-0",
                "focus:border-white",
                "focus:ring-0",
                "w-full",
                "bg-transparent",
                "sm:leading-6",
                "sm:text-sm",
                "placeholder:text-black/24",
                "disabled:text-black/24"
              )}
              placeholder={placeholder}
            />
          </div>
          {isLoading ? (
            <div
              className={cn(
                "inset-y-0",
                "right-0",
                "flex",
                "items-center",
                "px-2"
              )}
            >
              <LoadingSpinner size="xs" />
            </div>
          ) : (
            <Combobox.Button
              className={cn(
                "inset-y-0",
                "right-0",
                "flex",
                "items-center",
                "px-2"
              )}
            >
              <ChevronDownIcon
                className={cn("h-4", "w-4", "stroke-1", {
                  "text-gray-900 stroke-gray-900": !disabled,
                  "text-black/24 stroke-black/24": disabled,
                })}
                aria-hidden="true"
              />
            </Combobox.Button>
          )}
        </div>
        <FloatingPortal>
          <Transition
            as="div"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={resetQuery}
          >
            <ComboBoxOptions
              ref={refs.setFloating}
              style={floatingStyles}
              filteredOptions={options}
              isLoading={isLoading}
              customRenderOptions={customRenderOptions}
              hasMoreOptions={hasMoreOptions}
              onLoadMoreOptions={onLoadMoreOptions}
            />
          </Transition>
        </FloatingPortal>
      </div>
    </Combobox>
  );
}

export const SingleSelectComboBox = forwardRef(SingleSelectComboBoxImpl) as <T>(
  props: SingleSelectComboBoxProps<T> & {
    ref?: React.ForwardedRef<SingleSelectComboBoxElement<T>>;
  }
) => ReturnType<typeof SingleSelectComboBoxImpl>;
