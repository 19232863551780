import React, { ForwardedRef, useCallback, useState } from "react";
import { t } from "i18next";
import { useDebounce } from "../../../hooks/useDebounce";
import cn from "classnames";
import { TextInput } from "./TextInput";
import { SearchIcon } from "../../../icon";

interface SearchInputProps
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "onChange"
  > {
  className?: string;
  initialSearchText?: string;
  // debounced value changed
  onChange: (searchText: string) => void;
}

const SEARCH_BAR_DEBOUNCE_MS = 250;

// Note(peter)#820: referenced from https://github.com/oursky/cornerstone-cpo/blob/df28196099d31b80e5ee6f817da4560f0017aab6/services/web/app/components/SearchInput/index.tsx
export const SearchInput = React.forwardRef(function SearchInput(
  props: SearchInputProps,
  ref: ForwardedRef<HTMLInputElement>
): React.ReactElement {
  const {
    className,
    initialSearchText = "",
    onChange,
    placeholder,
    ...rest
  } = props;

  const [value, setValue] = useState<string>(initialSearchText);
  const debouncedOnChange = useDebounce(onChange, SEARCH_BAR_DEBOUNCE_MS);

  const handleChange = useCallback(
    (value: string) => {
      setValue(value);
      debouncedOnChange(value);
    },
    [debouncedOnChange]
  );

  return (
    <TextInput
      {...rest}
      ref={ref}
      className={cn(className)}
      prefixDecorator={<SearchIcon className={cn("fill-gray-400", "mr-2")} />}
      placeholder={placeholder ?? t("common.search")}
      value={value}
      onChange={handleChange}
    />
  );
});
