import React, { ReactElement } from "react";
import { Trans } from "react-i18next";
import { ChevronLeftIcon, XMarkIcon } from "@heroicons/react/24/outline";
import cn from "classnames";

import CCOBadge from "oneclick-component/src/components/Badge/CCOBadge";
import { ChatStationsBadge } from "./ChatStationBadge";
import { Chat } from "oneclick-component/src/store/apis/enhancedApi";
import { PTUserAvatarImage } from "../PTUserAvatarImage";
import { useShouldShowPtStationTeam } from "../../hooks/useShouldShowPtStationTeam";

interface ChatDialogChatListHeaderProps {
  onDismiss: () => void;
}

export const ChatDialogChatListHeader = React.memo(
  (props: ChatDialogChatListHeaderProps): ReactElement => {
    const { onDismiss } = props;

    return (
      <header
        className={cn(
          "relative",
          "bg-white",
          "p-4",
          "border-b",
          "border-black/[0.12]"
        )}
      >
        <button
          type="button"
          className={cn("absolute", "left-1", "top-2", "p-2")}
          onClick={onDismiss}
        >
          <XMarkIcon className={cn("h-6", "w-6", "text-black", "stroke-2")} />
        </button>
        <h3
          className={cn(
            "text-black",
            "text-center",
            "text-lg",
            "leading-6",
            "font-medium"
          )}
        >
          <Trans i18nKey="chatDialog.chatList.title" />
        </h3>
      </header>
    );
  }
);

interface ChatDialogMessageListHeaderProps {
  isPrivate: boolean;
  chat: Chat;
  onBack: () => void;
  onDismiss: () => void;
}

export const ChatDialogMessageListHeader = React.memo(
  (props: ChatDialogMessageListHeaderProps): ReactElement => {
    const { isPrivate, chat, onBack, onDismiss } = props;

    const ptUserName = chat.ptUser.fullNameZhHk;
    const shouldShowPtStationTeam = useShouldShowPtStationTeam();

    return (
      <header
        className={cn("relative", "p-3", {
          "bg-[#3031330D]": !isPrivate,
          "bg-[#343434]": isPrivate,
        })}
      >
        <button
          type="button"
          className={cn(
            "visible",
            "sm:collapse",
            "absolute",
            "left-1",
            "top-2",
            "p-2"
          )}
          onClick={onBack}
        >
          <ChevronLeftIcon
            className={cn("h-6", "w-6", "stroke-2", {
              "text-black": !isPrivate,
              "text-white": isPrivate,
            })}
          />
        </button>
        <div
          className={cn(
            "pl-8",
            "sm:p-0",
            "pr-0",
            "sm:pr-8",
            "flex",
            "items-center",
            "gap-2",
            "text-lg",
            "leading-6",
            "font-medium"
          )}
        >
          <PTUserAvatarImage
            ptUser={chat.ptUser}
            size="32"
            show418Badge={false}
          />
          <span
            className={cn("truncate", {
              "text-black": !isPrivate,
              "text-white": isPrivate,
            })}
          >
            {ptUserName}
          </span>
          <ChatStationsBadge
            homeStation={chat.station}
            partnerStation={chat.partnerStation ?? null}
            ptUserStationTeam={chat.ptUser.stationTeam ?? null}
            showStationTeam={shouldShowPtStationTeam}
          />
          {chat.ptUser.role?.name === "CCO" ? <CCOBadge /> : null}
        </div>
        <button
          type="button"
          className={cn(
            "collapse",
            "sm:visible",
            "absolute",
            "right-1",
            "top-2",
            "p-2"
          )}
          onClick={onDismiss}
        >
          <XMarkIcon
            className={cn("h-6", "w-6", "stroke-2", {
              "text-black": !isPrivate,
              "text-white": isPrivate,
            })}
          />
        </button>
      </header>
    );
  }
);
