export const SHIFT_CANCELLED_TAB_GROUPING_COLUMN_HEADER_MAP: Record<
  string,
  string | undefined
> = {
  info: "title",
  timeRange: "date",
  partTimeType: "partTimeType",
  numberOfApplicants: "numberOfApplicants",
  cancelReason: "cancelReason",
};
