import { ReactElement, useCallback, useMemo } from "react";
import cn from "classnames";
import { Trans, useTranslation } from "react-i18next";
import { Control, FieldErrors, useWatch } from "react-hook-form";
import { Select, Option } from "oneclick-component/src/components/inputs";
import { FormField } from "oneclick-component/src/components/forms";
import { MultiShiftFormValues } from "../form";
import { DEFAULT_SHIFT_MINUTE } from "../../../constants/shifts/create";

interface DutyTimeSelectProps {
  control: Control<MultiShiftFormValues>;
  errors: FieldErrors<MultiShiftFormValues>;
  index: number;
  canRemove: boolean;
  isEditing: boolean;
  startTimeDisable?: boolean;
  endTimeDisable?: boolean;
  onRemove?: (index: number) => void;
  setStartMinute?: (value: number) => void;
  setEndMinute?: (value: number | null) => void;
  startMinuteLabel?: string;
  endMinuteLabel?: string;
  endHourEmptyText?: string;
  endMinuteEmptyText?: string;
}

const DutyTimeSelect = (props: DutyTimeSelectProps): ReactElement => {
  const {
    errors,
    control,
    isEditing,
    index,
    canRemove,
    startTimeDisable,
    endTimeDisable,
    onRemove,
    setStartMinute,
    setEndMinute,
    startMinuteLabel,
    endMinuteLabel,
    endHourEmptyText,
    endMinuteEmptyText,
  } = props;
  const { t } = useTranslation();

  const endHour = useWatch({
    control: control,
    name: `timeRanges.${index}.endTime.hour`,
  });

  const startTimeHourOptions: Option<number>[] = useMemo(() => {
    return new Array(24).fill(0).map((_, index) => ({
      name: index.toString().padStart(2, "0"),
      value: index,
    }));
  }, []);
  const startTimeMinsOptions: Option<number>[] = useMemo(() => {
    return new Array(60 / 15).fill(0).map((_, index) => ({
      name: (15 * index).toString().padStart(2, "0"),
      value: 15 * index,
    }));
  }, []);
  const endTimeHourOptions = useMemo(() => {
    return [
      {
        name:
          endHourEmptyText ?? t("shift.create.form.endTime.hour.placeholder"),
        value: null,
      },
      ...new Array(48).fill(0).map((_, index) => {
        const hour = index % 24;
        const day = Math.floor(index / 24);
        const hourString = hour.toString().padStart(2, "0");
        const dayString =
          day === 0
            ? ""
            : `(${t("shift.create.form.endTime.hour.option.overnight")})`;
        return {
          name: `${hourString} ${dayString}`,
          value: index,
        };
      }),
    ];
  }, [t, endHourEmptyText]);
  const endTimeMinsOptions = useMemo(() => {
    return [
      {
        name:
          endMinuteEmptyText ??
          t("shift.create.form.endTime.minute.placeholder"),
        value: null,
      },
      ...startTimeMinsOptions,
    ];
  }, [startTimeMinsOptions, endMinuteEmptyText, t]);

  const onRemoveClick = useCallback(() => {
    if (onRemove != null) {
      onRemove(index);
    }
  }, [index, onRemove]);

  const makeOnChangeStartTimeHour = useCallback(
    (fieldOnChange: (value: number) => void) => (value: number) => {
      setStartMinute?.(DEFAULT_SHIFT_MINUTE);
      fieldOnChange(value);
    },
    [setStartMinute]
  );

  const makeOnChangeEndTimeHour = useCallback(
    (fieldOnChange: (value: number | null) => void) =>
      (value: number | null) => {
        setEndMinute?.(value == null ? null : DEFAULT_SHIFT_MINUTE);
        fieldOnChange(value);
      },
    [setEndMinute]
  );

  return (
    <div>
      <div className={cn("flex", "flex-col", "md:flex-row", "w-full", "gap-5")}>
        <FormField.Container
          className={"flex-1"}
          label={startMinuteLabel ?? t("shift.create.form.startTime")}
          required={!startTimeDisable}
          errorMessage={
            errors.timeRanges?.[index]?.startTime?.hour?.message ??
            errors.timeRanges?.[index]?.startTime?.minute?.message
          }
        >
          <div className={cn("flex", "items-center", "gap-x-2")}>
            <FormField.Control
              name={`timeRanges.${index}.startTime.hour`}
              control={control}
            >
              {({ field }) => (
                <div className={cn("flex", "items-center", "flex-1")}>
                  <Select
                    className={"flex-1"}
                    options={startTimeHourOptions}
                    placeholder={t(
                      "shift.create.form.startTime.hour.placeholder"
                    )}
                    disabled={isEditing || startTimeDisable}
                    hasError={
                      errors.timeRanges?.[index]?.startTime?.hour?.message !=
                      null
                    }
                    {...field}
                    onChange={makeOnChangeStartTimeHour(field.onChange)}
                  />
                </div>
              )}
            </FormField.Control>
            <span>:</span>
            <FormField.Control
              name={`timeRanges.${index}.startTime.minute`}
              control={control}
            >
              {({ field }) => (
                <div className={cn("flex", "items-center", "flex-1")}>
                  <Select
                    className={"flex-1"}
                    options={startTimeMinsOptions}
                    placeholder={t(
                      "shift.create.form.startTime.minute.placeholder"
                    )}
                    disabled={isEditing || startTimeDisable}
                    hasError={
                      errors.timeRanges?.[index]?.startTime?.minute?.message !=
                      null
                    }
                    {...field}
                  />
                </div>
              )}
            </FormField.Control>
          </div>
        </FormField.Container>
        <FormField.Container
          className={"flex-1"}
          label={endMinuteLabel ?? t("shift.create.form.endTime")}
          errorMessage={
            errors.timeRanges?.[index]?.endTime?.hour?.message ??
            errors.timeRanges?.[index]?.endTime?.minute?.message
          }
        >
          <div className={cn("flex", "items-center", "gap-x-2")}>
            <FormField.Control
              name={`timeRanges.${index}.endTime.hour`}
              control={control}
            >
              {({ field }) => (
                <div className={cn("flex", "items-center", "flex-1")}>
                  <Select
                    className={"flex-1"}
                    options={endTimeHourOptions}
                    disabled={isEditing || endTimeDisable}
                    placeholder={
                      endHourEmptyText ??
                      t("shift.create.form.endTime.hour.placeholder")
                    }
                    hasError={
                      errors.timeRanges?.[index]?.endTime?.hour?.message != null
                    }
                    {...field}
                    onChange={makeOnChangeEndTimeHour(field.onChange)}
                  />
                </div>
              )}
            </FormField.Control>
            <span>:</span>
            <FormField.Control
              name={`timeRanges.${index}.endTime.minute`}
              control={control}
            >
              {({ field }) => (
                <div className={cn("flex", "items-center", "flex-1")}>
                  <Select
                    className={"flex-1"}
                    options={endTimeMinsOptions}
                    placeholder={
                      endMinuteEmptyText ??
                      t("shift.create.form.endTime.minute.placeholder")
                    }
                    disabled={(isEditing || endTimeDisable) ?? endHour == null}
                    hasError={
                      errors.timeRanges?.[index]?.endTime?.hour?.message != null
                    }
                    {...field}
                  />
                </div>
              )}
            </FormField.Control>
          </div>
        </FormField.Container>
      </div>
      {canRemove ? (
        <button
          type="button"
          onClick={onRemoveClick}
          className={cn("text-xs", "text-primary-600")}
        >
          <Trans i18nKey="shift.create.multiple.removeTimeRange" />
        </button>
      ) : null}
    </div>
  );
};

export default DutyTimeSelect;
