import {
  MeUser,
  UserStationProfile,
} from "oneclick-component/src/store/apis/enhancedApi";

export interface PtUserListFilter {
  searchString?: string;
  pageIndex: number;
  pageSize: number;
  sort?: string;
  stationFilter?: number;
}

export function getStationFilterFromFilterAndProfile(
  selectedStationProfile: UserStationProfile | null,
  filter: PtUserListFilter
): number[] | undefined {
  if (selectedStationProfile != null) {
    if (filter.stationFilter != null) {
      return [filter.stationFilter].filter(
        (sid) => sid === selectedStationProfile.station.id
      );
    }
    // filter.stationFilter == null
    return [selectedStationProfile.station.id];
  }
  // selectedStationProfile == null
  return filter.stationFilter != null ? [filter.stationFilter] : undefined;
}

export function getStationFilterFromMeUser(
  meUser: MeUser
): number[] | undefined {
  if (meUser.adminRole !== "MANAGER" || meUser.selectedProfile == null) {
    // super admin
    return undefined;
  }
  // station manager
  return [meUser.selectedProfile.station.id];
}
