import React from "react";
import useValidateAttachmentType from "../../../hooks/useValidateAttachmentType";
import { FilePicker } from "oneclick-component/src/components/inputs/FilePicker";
import { WHATSAPP_ATTACHMENT_ACCEPTED_MIME } from "../../../constants/attachments";
import { PlusIcon } from "@heroicons/react/24/outline";
import cn from "classnames";
import { Trans } from "react-i18next";
interface Props {
  onAttachmentSelected: (file: File) => void;
  disabled?: boolean;
  className?: string;
}
export const AnnouncementAttachmentPicker = (
  props: Props
): React.ReactElement => {
  const { onAttachmentSelected, disabled } = props;
  const { onValidationError, getAttachmentSizeLimitMb } =
    useValidateAttachmentType();
  return (
    <FilePicker
      onFileSelected={onAttachmentSelected}
      onValidationError={onValidationError}
      acceptedMime={WHATSAPP_ATTACHMENT_ACCEPTED_MIME}
      sizeLimitMb={getAttachmentSizeLimitMb}
      disabled={disabled}
      buttonNode={
        // copied classnames from <Button />
        <div
          className={cn(
            "inline-flex",
            "justify-center",
            "items-center",
            "gap-x-1.5",
            "rounded-md",
            "shadow-sm",
            "focus:ring-2",
            "focus:ring-offset-2",
            "focus:outline-0",
            "px-4",
            "py-2",
            "font-medium",
            "text-sm",
            "bg-primary-600",
            "hover:bg-primary-700",
            "focus:ring-offset-white",
            "focus:ring-indigo-500",
            "text-white",
            "hover:cursor-pointer",
            { "bg-black/25": disabled }
          )}
        >
          <PlusIcon
            className={cn("fill-white", "-ml-0.5", "h-5", "w-5", "stroke-2")}
          />
          <Trans i18nKey="attachment.upload.button" />
        </div>
      }
    />
  );
};
