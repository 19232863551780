import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import cn from "classnames";
import { ButtonLink } from "oneclick-component/src/components/Button";
import { PlusIcon } from "@heroicons/react/20/solid";
import { Trans, useTranslation } from "react-i18next";
import AppRoutes from "../../routes/AppRoutes";
import LoadingScreen from "oneclick-component/src/components/LoadingScreen";
import { Select, Option } from "oneclick-component/src/components/inputs";
import {
  useListUserHandlerUsersGetQuery as useListUser,
  useListRailwayLineHandlerRailwayLinesGetQuery as useListRailwayLine,
  ListUserHandlerUsersGetApiArg,
  User,
  UserAdminRole,
} from "oneclick-component/src/store/apis/enhancedApi";
import Pagination from "oneclick-component/src/components/Pagination";
import { LazyLoadStationSelectionDropdown } from "../../components/StationSelectionDropdown";
import { useShowError } from "../../hooks/useShowError";

const USER_LIST_PAGE_SIZE = 20;

interface RailwayLineSelectProps {
  onChange: (railwayId: number) => void;
  value: number;
}

const RailwayLineSelect = (props: RailwayLineSelectProps): ReactElement => {
  const { onChange, value } = props;
  const { t } = useTranslation();
  const { data } = useListRailwayLine();

  const railwayOptions = useMemo(() => {
    if (data == null) {
      return [];
    }
    return [
      { name: t("user.list.filter.all"), value: -1 },
      ...data.railwayLines.map((line) => ({
        name: line.shortCode,
        value: line.id,
      })),
    ];
  }, [data, t]);

  if (data == null) {
    return <></>;
  }
  return (
    <Select
      className="w-40"
      options={railwayOptions}
      onChange={onChange}
      value={value}
    />
  );
};

interface UserListItemProps {
  user: User;
}

const UserListItem = React.memo((props: UserListItemProps): ReactElement => {
  const { user } = props;

  return (
    <div className={cn("flex", "items-center", "border", "p-4", "shadow")}>
      <div className={cn("flex", "flex-1", "flex-col", "gap-1")}>
        <h5 className={cn("text-base", "font-medium")}>
          <Trans
            i18nKey="user.list.item.name"
            values={{ nameZhHk: user.nameZhHk, name: user.name }}
          />
        </h5>
        <p>{`ID: ${user.id}`}</p>
        <p>
          <Trans
            i18nKey="user.list.item.role"
            values={{ role: user.adminRole }}
          />
        </p>
        <p>
          <Trans
            i18nKey="user.list.item.email"
            values={{ email: user.email }}
          />
        </p>
      </div>
      <ButtonLink
        theme={"primary"}
        className={"mr-3"}
        to={AppRoutes.EditUserScreen.render(user.id)}
      >
        <Trans i18nKey="user.list.item.button.edit" />
      </ButtonLink>
    </div>
  );
});

interface UserListScreenContentProps {
  users: User[];
  pageIndex: number;
  totalPages: number;
  onPageChange: (pageIndex: number) => void;
}
const UserListScreenContent = (
  props: UserListScreenContentProps
): ReactElement => {
  const { users, pageIndex, totalPages, onPageChange } = props;

  return (
    <div className={cn("space-y-3", "relative", "mt-10")}>
      <div className={cn("pb-24", "space-y-3", "sm:space-y-5")}>
        {users.length > 0 ? (
          users.map((u) => <UserListItem key={u.id} user={u} />)
        ) : (
          <Trans i18nKey="user.list.empty" />
        )}
        <Pagination
          className={cn(
            "mt-3",
            "bg-white",
            "fixed",
            "sm:absolute",
            "bottom-0",
            "inset-x-0"
          )}
          totalPages={totalPages}
          pageIndex={pageIndex}
          onClickPage={onPageChange}
        />
      </div>
    </div>
  );
};

export interface UserListPagination {
  pageIndex: number;
}

const UserListScreen = (): ReactElement => {
  const { showError } = useShowError();
  const { t } = useTranslation();
  const [pageIndex, setPageIndex] = useState(0);
  const [userType, setUserType] = useState<UserAdminRole | "ALL">("ALL");
  const [railwayId, setRailwayId] = useState(-1);
  const [stationId, setStationId] = useState<number | null>(null);

  const listParams: ListUserHandlerUsersGetApiArg = useMemo(() => {
    const param: ListUserHandlerUsersGetApiArg = {
      pageIndex,
      pageSize: USER_LIST_PAGE_SIZE,
    };

    if (userType !== "ALL") {
      param.role = userType;
    }
    if (railwayId !== -1) {
      param.railwayLineId = railwayId;
    }
    if (stationId != null) {
      param.stationId = stationId;
    }

    return param;
  }, [userType, pageIndex, railwayId, stationId]);
  const {
    data: listUserData,
    isFetching,
    error: listUserError,
  } = useListUser(listParams, {
    refetchOnMountOrArgChange: true,
  });

  const adminRoleOptions: Option<"ALL" | UserAdminRole>[] = useMemo(() => {
    return [
      { name: t("user.list.filter.all"), value: "ALL" },
      { name: t("user.list.filter.role.superAdmin"), value: "ADMIN" },
      { name: t("user.list.filter.role.landlord"), value: "LANDLORD" },
      { name: t("user.list.filter.role.manager"), value: "MANAGER" },
    ];
  }, [t]);

  const handleChangeUserType = useCallback((role: UserAdminRole | "ALL") => {
    setUserType(role);
    setRailwayId(-1);
    setStationId(null);
  }, []);

  const handleStationFilterChange = useCallback((stationId: number | null) => {
    setStationId(stationId);
  }, []);
  const handleRailwayFilterChange = useCallback((railwayId: number) => {
    setRailwayId(railwayId);
  }, []);

  useEffect(() => {
    if (listUserError != null) {
      showError(listUserError);
    }
  }, [listUserError, showError]);

  const totalPages = useMemo(() => {
    if (listUserData == null) {
      return 0;
    }
    return Math.ceil(listUserData.totalCount / USER_LIST_PAGE_SIZE);
  }, [listUserData]);

  const onPageChange = useCallback((newPageIndex: number) => {
    setPageIndex(newPageIndex);
  }, []);

  return (
    <main
      className={cn(
        "rounded-lg",
        "bg-white",
        "sm:shadow",
        "sm:mx-auto",
        "px-4",
        "pb-5",
        "sm:p-6"
      )}
    >
      <div className={cn("flex", "flex-row", "items-center")}>
        <div
          className={cn(
            "flex-1",
            "text-2xl",
            "flex",
            "flex-row",
            "items-center"
          )}
        >
          <span className={cn("font-bold", "mr-2")}>
            <Trans i18nKey="user.list.title" />
          </span>
          <p className="mr-6">({listUserData?.totalCount ?? 0})</p>
          <Select
            className={cn("mr-6", "w-40")}
            options={adminRoleOptions}
            onChange={handleChangeUserType}
            value={userType}
          />
          {userType === "MANAGER" ? (
            <LazyLoadStationSelectionDropdown
              selectedStationId={stationId}
              onChange={handleStationFilterChange}
            />
          ) : null}
          {userType === "LANDLORD" ? (
            <RailwayLineSelect
              value={railwayId}
              onChange={handleRailwayFilterChange}
            />
          ) : null}
        </div>
        <ButtonLink
          theme="white"
          prefixIcon={PlusIcon}
          to={AppRoutes.CreateUserScreen.render()}
          className="text-sm"
        >
          <Trans i18nKey="user.list.button.addUser" />
        </ButtonLink>
      </div>
      <div
        className={cn("gap-y-3", "sm:gap-y-5", "h-full", "flex", "flex-col")}
      >
        {isFetching ? (
          <LoadingScreen />
        ) : (
          <UserListScreenContent
            users={listUserData?.results ?? []}
            totalPages={totalPages}
            pageIndex={pageIndex}
            onPageChange={onPageChange}
          />
        )}
      </div>
    </main>
  );
};

export default UserListScreen;
