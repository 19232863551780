import React, { PropsWithChildren, ReactElement } from "react";
import cn from "classnames";
import { Theme, themes } from "./theme";
import { Link, LinkProps } from "react-router-dom";

interface ButtonLinkProps extends LinkProps {
  className?: string;
  prefixIcon?: React.ComponentType<any>;
  suffixIcon?: React.ComponentType<any>;
  theme?: Theme;
}

export function ButtonLink(
  props: PropsWithChildren<ButtonLinkProps>
): ReactElement {
  const {
    children,
    className,
    prefixIcon,
    suffixIcon,
    theme = "primary",
    ...rest
  } = props;
  const { buttonThemeClassName, iconThemeClassName } = themes[theme];
  const Prefix = prefixIcon;
  const Suffix = suffixIcon;
  return (
    <Link
      className={cn(
        "inline-flex",
        "items-center",
        "justify-center",
        "gap-x-1.5",
        "rounded-md",
        "shadow-sm",
        "focus:ring-2",
        "focus:ring-offset-2",
        "focus:outline-0",
        "px-4",
        "py-[0.5625rem]",
        "leading-5",
        buttonThemeClassName,
        className
      )}
      {...rest}
    >
      {Prefix != null ? (
        <Prefix
          className={cn(
            "-ml-0.5",
            "h-5",
            "w-5",
            "stroke-2",
            iconThemeClassName
          )}
          aria-hidden="true"
        />
      ) : null}
      {children}
      {Suffix != null ? (
        <Suffix
          className={cn(
            "-mr-0.5",
            "h-5",
            "w-5",
            "stroke-2",
            iconThemeClassName
          )}
          aria-hidden="true"
        />
      ) : null}
    </Link>
  );
}
