import React from "react";
import cn from "classnames";
import { ArrowDown } from "../../icon";

interface SortingArrowProps {
  sorting: "asc" | "desc" | false;
}

export const SortingArrow = React.memo(
  (props: SortingArrowProps): React.ReactElement | null => {
    const { sorting } = props;

    if (sorting === "asc") {
      return (
        <ArrowDown
          className={cn("w-3", "h-3", "ml-2", "origin-center", "rotate-180")}
        />
      );
    }

    if (sorting === "desc") {
      return <ArrowDown className={cn("w-3", "h-3", "ml-2")} />;
    }

    return null;
  }
);
