import React, { ReactElement, useMemo } from "react";
import cn from "classnames";

import { Message } from "oneclick-component/src/store/apis/enhancedApi";

import { useRenderMessageContent } from "../../../../hooks/useRenderMessageContent";

interface Props {
  message: Message;
}

export const MessageBubbleTextContent = React.memo(
  (props: Props): ReactElement => {
    const { message } = props;
    const { renderMessageContent } = useRenderMessageContent();

    const textContent = useMemo(() => {
      return renderMessageContent(message);
    }, [renderMessageContent, message]);

    return (
      <span
        className={cn(
          "block",
          "whitespace-pre-wrap",
          "overflow-wrap-anywhere",
          "text-sm",
          "text-left",
          "p-3"
        )}
      >
        {textContent}
      </span>
    );
  }
);
