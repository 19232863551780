import * as z from "zod";
import {
  ShiftRequestBase,
  ShiftRequestBaseWithNumbering,
  ShiftRequestStatus,
  BorrowingRequestStatus,
  Weekly418Status,
} from "oneclick-component/src/store/apis/enhancedApi";

import { PTUserWithVisitCountSchema } from "./ptUser";

export interface HiredShiftRequestBase
  extends Omit<ShiftRequestBase, "status"> {
  status: "hired";
}

export interface HiredShiftRequestBaseWithNumbering
  extends Omit<ShiftRequestBaseWithNumbering, "status"> {
  status: "hired";
}

export interface RequestedShiftRequestBase
  extends Omit<ShiftRequestBase, "status"> {
  status: "requested";
}

export interface RequestedShiftRequestBaseWithNumbering
  extends Omit<ShiftRequestBaseWithNumbering, "status"> {
  status: "requested";
}

export interface AppliedShiftRequestBase
  extends Omit<ShiftRequestBase, "status"> {
  status: "applied";
}

export interface AppliedShiftRequestBaseWithNumbering
  extends Omit<ShiftRequestBaseWithNumbering, "status"> {
  status: "applied";
}

export interface ContactedShiftRequestBase
  extends Omit<ShiftRequestBase, "status"> {
  status: "contacted";
}

export interface ContactedShiftRequestBaseWithNumbering
  extends Omit<ShiftRequestBaseWithNumbering, "status"> {
  status: "contacted";
}

export type ResponsedShiftRequestBase =
  | AppliedShiftRequestBase
  | ContactedShiftRequestBase;
export type ResponsedShiftRequestBaseWithNumbering =
  | AppliedShiftRequestBaseWithNumbering
  | ContactedShiftRequestBaseWithNumbering;

export const ShiftRequestBaseWithNumberingSchema = z.object({
  shiftId: z.number(),
  ptUserId: z.number(),
  rejectReason: z.string().nullable(),
  sentRequestCount: z.number(),
  status: z.custom<ShiftRequestStatus>(),
  borrowingRequestStatus: z.custom<BorrowingRequestStatus>().nullable(),
  ptUser: PTUserWithVisitCountSchema,
  chatId: z.number().nullable(),
  createdAt: z.string().datetime({ offset: true }),
  updatedAt: z.string().datetime({ offset: true }),
  appliedAt: z.string().datetime({ offset: true }).nullable(),
  hiredAt: z.string().datetime({ offset: true }).nullable(),
  approvedAt: z.string().datetime({ offset: true }).nullable(),
  rejectedAt: z.string().datetime({ offset: true }).nullable(),
  lastReadAt: z.string().datetime({ offset: true }).nullable(),
  ptUserShiftWeek418Status: z.custom<Weekly418Status>(),
  cancelCount: z.number().nullable(),
  index: z.number().nullish(),
});
