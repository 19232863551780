import LoadingScreen from "oneclick-component/src/components/LoadingScreen";
import cn from "classnames";
import { PtUser } from "oneclick-component/src/store/apis/enhancedApi";
import PartTimeUserCard from "./PartTimeUserCard";
import { ListEmptyView } from "../../../components/EmptyView";
import { Trans, useTranslation } from "react-i18next";
import { ButtonLink } from "oneclick-component/src/components/Button";
import { PlusIcon } from "@heroicons/react/24/outline";
import React from "react";
import AppRoutes from "../../../routes/AppRoutes";
import Pagination from "oneclick-component/src/components/Pagination";
import { useShouldShowPtStationTeam } from "../../../hooks/useShouldShowPtStationTeam";

interface Props {
  isLoading: boolean;
  ptUsers: PtUser[];
  totalPages: number;
  pageIndex: number;
  onClickPage: (pageIndex: number) => void;
}
const PartTimeAvailabilityTabListView = (props: Props): React.ReactElement => {
  const { isLoading, ptUsers, totalPages, pageIndex, onClickPage } = props;
  const { t } = useTranslation();
  const shouldShowPtStationTeam = useShouldShowPtStationTeam();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (ptUsers.length === 0) {
    return (
      <ListEmptyView
        description={t("partTime.list.tab.availability.emptyView.description")}
        actionButton={
          <ButtonLink
            prefixIcon={PlusIcon}
            to={AppRoutes.CreatePartTimeScreen.render()}
            className="text-sm"
          >
            <Trans i18nKey="partTime.list.button.addPartTimeUser" />
          </ButtonLink>
        }
      />
    );
  }
  return (
    <div className={cn("space-y-3", "sm:space-y-5", "relative")}>
      <div className={cn("pb-24", "space-y-3", "sm:space-y-5")}>
        {ptUsers.map((pt) => (
          <PartTimeUserCard
            key={pt.id}
            ptUser={pt}
            showStationTeam={shouldShowPtStationTeam}
          />
        ))}
        <Pagination
          className={cn(
            "mt-3",
            "bg-white",
            "fixed",
            "sm:absolute",
            "bottom-0",
            "inset-x-0"
          )}
          totalPages={totalPages}
          pageIndex={pageIndex}
          onClickPage={onClickPage}
        />
      </div>
    </div>
  );
};

export default PartTimeAvailabilityTabListView;
