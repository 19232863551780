import { useMemo, useContext } from "react";
import { useTranslation } from "react-i18next";

import { Option } from "oneclick-component/src/components/inputs";
import { useListShiftRateHandlerShiftRatesGetQuery as useListShiftRateQuery } from "oneclick-component/src/store/apis/enhancedApi";
import { localizeString } from "oneclick-component/src/utils/localize";
import { FeatureConfigContext } from "../../providers/FeatureConfigProvider";

function useShiftRateFilterOptions(): Option<number | null>[] {
  const { t } = useTranslation();
  const { data: shiftRatesResponse } = useListShiftRateQuery();
  const { enableCcoRole } = useContext(FeatureConfigContext);

  const shiftRateFilterOptions = useMemo(() => {
    const nullOption: Option<null> = {
      name: t("common.all"),
      value: null,
    };

    if (shiftRatesResponse == null) {
      return [nullOption];
    }

    const rateOptions = enableCcoRole
      ? shiftRatesResponse.rates
      : shiftRatesResponse.rates.filter((rate) => rate.code !== "CCO");

    return [
      nullOption,
      ...rateOptions.map((rate) => ({
        name: rate.code,
        value: rate.id,
        displayComponent: (
          <span>
            {rate.shortDesc != null ? localizeString(rate.shortDesc) : <></>}
          </span>
        ),
      })),
    ];
  }, [shiftRatesResponse, t, enableCcoRole]);

  return shiftRateFilterOptions;
}

export default useShiftRateFilterOptions;
