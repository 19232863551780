import React, { ReactElement, useCallback, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector, useStore } from "react-redux";
import { Waypoint } from "react-waypoint";
import { Trans } from "react-i18next";
import cn from "classnames";

import {
  Chat,
  useLazyListChatHandlerChatsGetQuery,
  ListChatHandlerChatsGetApiArg as ListChatParams,
} from "oneclick-component/src/store/apis/enhancedApi";
import {
  finishFetchingChat,
  selectChat,
  startFetchingChats,
  startFetchingMoreChats,
  fetchChatFailed,
} from "../../store/chatState";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";

import { useShowError } from "../../hooks/useShowError";
import ChatListEmptyImage from "../../assets/chat-list-empty-image.svg";
import { ChatListItem } from "./ChatListItem";
import { RootState } from "../../store/store";
import { useSelectedStationProfile } from "../../hooks/useSelectedStationProfile";

const PAGE_SIZE = 20;

interface SearchInputProps {
  initialSearchInput: string;
  onSearchTextChange: (q: string) => void;
}

// TODO: Confirm search behaviour and layout search input
const SearchInput = React.memo(
  (props: SearchInputProps): ReactElement | null => {
    const {} = props;
    return null;
  }
);

export const ChatList = (): ReactElement => {
  const dispatch = useDispatch();
  const { showError } = useShowError();
  const selectedStationProfile = useSelectedStationProfile();
  const selectedStationId = selectedStationProfile?.station.id;
  const [listChat] = useLazyListChatHandlerChatsGetQuery();
  const store = useStore<RootState>();
  const { meUser, isFetchingChats, hasMoreChat, appliedSearchText, chats } =
    useSelector((state: RootState) => {
      const { chatState } = state;
      return {
        meUser: state.auth.meUser,
        isFetchingChats: chatState.isFetchingChats,
        hasMoreChat: chatState.chatListCursor != null,
        appliedSearchText: chatState.chatSearchText,
        chats: chatState.chats,
      };
    }, shallowEqual);

  const fetchChats = useCallback(
    async (params: ListChatParams, options?: { isFetchMore?: boolean }) => {
      if (options?.isFetchMore) {
        dispatch(startFetchingMoreChats());
      } else {
        dispatch(
          startFetchingChats({
            q: params.q ?? undefined,
          })
        );
      }
      try {
        const data = await listChat(params).unwrap();
        dispatch(
          finishFetchingChat({
            chats: data.items,
            before: data.before ?? undefined,
            statusByChat: data.readStatus,
          })
        );
      } catch (err: unknown) {
        dispatch(fetchChatFailed());
        showError(err, "chatDialog.messageList.getChat.error.title");
      }
    },
    [dispatch, listChat, showError]
  );

  const fetchMoreChat = useCallback(() => {
    const {
      chatSearchText,
      chatListCursor,
      isFetchingChats,
      isFetchingMoreChats,
    } = store.getState().chatState;
    if (chatListCursor == null || isFetchingChats || isFetchingMoreChats) {
      return;
    }
    fetchChats(
      {
        stationId: selectedStationId,
        q: chatSearchText,
        before: chatListCursor,
        limit: PAGE_SIZE,
      },
      { isFetchMore: true }
    ).catch(() => {});
  }, [fetchChats, selectedStationId, store]);

  useEffect(() => {
    const { isFetchingChats, isFetchingMoreChats } = store.getState().chatState;
    if (isFetchingChats || isFetchingMoreChats) {
      return;
    }
    fetchChats({
      stationId: selectedStationId,
      limit: PAGE_SIZE,
    }).catch(() => {});
    // Only run on mount
  }, [selectedStationId]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSearchTextChange = useCallback(
    (searchText: string) => {
      fetchChats({
        stationId: selectedStationId,
        q: searchText,
        before: null,
        limit: PAGE_SIZE,
      }).catch(() => {});
    },
    [fetchChats, selectedStationId]
  );

  const onChatSelected = useCallback(
    (chat: Chat) => {
      dispatch(
        selectChat({
          chat,
        })
      );
    },
    [dispatch]
  );

  return (
    <div className={cn("pt-1.5", "flex", "flex-col", "flex-1", "min-h-0")}>
      <SearchInput
        initialSearchInput={appliedSearchText ?? ""}
        onSearchTextChange={onSearchTextChange}
      />
      {isFetchingChats ? (
        <div className={cn("flex", "justify-center", "mt-2")}>
          <LoadingSpinner size="m" />
        </div>
      ) : chats.length > 0 ? (
        <div className={cn("overflow-y-auto", "overscroll-none", "flex-1")}>
          <ul>
            {chats.map((c) => (
              <ChatListItem
                key={c.id}
                meUser={meUser}
                chat={c}
                onChatSelected={onChatSelected}
              />
            ))}
          </ul>
          <Waypoint onEnter={fetchMoreChat} />
          {hasMoreChat ? (
            <div className={cn("flex", "justify-center", "mt-5.5", "mb-3")}>
              <LoadingSpinner size="m" />
            </div>
          ) : null}
        </div>
      ) : (
        <div
          className={cn(
            "flex",
            "flex-col",
            "flex-1",
            "justify-center",
            "items-center"
          )}
        >
          <img src={ChatListEmptyImage} alt="chat-list-empty" />
          <span
            className={cn(
              "flex",
              "justify-center",
              "text-gray-500",
              "text-sm",
              "font-medium",
              "mt-3"
            )}
          >
            <Trans i18nKey="chatDialog.chatList.noResult" />
          </span>
        </div>
      )}
    </div>
  );
};
