import { Tab } from "@headlessui/react";
import { Fragment, ReactElement } from "react";
import cn from "classnames";
import { Link, useLocation } from "react-router-dom";

interface MainLayoutTabGroupItem {
  label: string;
  to: string;
}
interface Props {
  items: MainLayoutTabGroupItem[];
  className?: string;
}

const MainLayoutTabGroup = (props: Props): ReactElement => {
  const { items, className } = props;
  const { pathname } = useLocation();
  const selectedIndex = items.findIndex((item) => pathname.includes(item.to));
  return (
    <Tab.Group manual={true} selectedIndex={selectedIndex}>
      <Tab.List
        as="div"
        className={cn("flex", "items-center", "gap-x-2", className)}
      >
        {items.map((item, index) => (
          <Tab key={index} as={Fragment}>
            <Link
              className={cn(
                "px-1",
                "pb-1",
                "relative",
                "focus:outline-0",
                "focus:outline-none"
              )}
              to={item.to}
            >
              {item.to.includes("shift-requests") ? (
                <span
                  className={cn(
                    "absolute",
                    "right-0",
                    "top-0",
                    "text-xs",
                    "font-bold",
                    "text-gray-400",
                    "-translate-y-1",
                    "italic",
                    "translate-x-full"
                  )}
                >
                  beta
                </span>
              ) : null}
              <span
                className={cn("text-sm", "leading-5", "font-medium", {
                  "text-primary-500": selectedIndex === index,
                  "text-black/86 hover:text-black/50": selectedIndex !== index,
                })}
              >
                {item.label}
              </span>
            </Link>
          </Tab>
        ))}
      </Tab.List>
    </Tab.Group>
  );
};

export default MainLayoutTabGroup;
