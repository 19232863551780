import { PropsWithChildren, ReactElement } from "react";

import NotFoundScreen from "oneclick-component/src/components/NotFoundScreen";

import { useIsSuperAdmin } from "../../hooks/role";

const SuperAdminRoute = ({
  children,
}: PropsWithChildren): ReactElement | null => {
  const isSuperAdmin = useIsSuperAdmin();

  if (!isSuperAdmin) {
    return <NotFoundScreen />;
  }

  return <>{children}</>;
};

export default SuperAdminRoute;
