import React, { ReactElement } from "react";
import cn from "classnames";
import { Theme, themes } from "./theme";
import { Link, LinkProps } from "react-router-dom";

interface IconButtonLinkProps extends LinkProps {
  className?: string;
  icon: React.ComponentType<any>;
  theme?: Theme;
}

export function IconButtonLink(props: IconButtonLinkProps): ReactElement {
  const { className, icon, theme = "primary", ...rest } = props;
  const { buttonThemeClassName, iconThemeClassName } = themes[theme];
  const Icon = icon;
  return (
    <div
      className={cn(
        "rounded-full",
        "shadow-sm",
        "focus:ring-2",
        "focus:ring-offset-2",
        "focus:outline-0",
        "p-2.5",
        "leading-3",
        buttonThemeClassName,
        className
      )}
    >
      <Link type="button" {...rest}>
        <Icon
          className={cn("h-5", "w-5", iconThemeClassName)}
          aria-hidden="true"
        />
      </Link>
    </div>
  );
}
