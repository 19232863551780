import React, { PropsWithChildren } from "react";
import { MediaModal } from "./MediaModal";
import cn from "classnames";

export interface VideoViewerProps {
  className?: string;
  onClose: () => void;
  isOpen: boolean;
  videoSource: string;
}

export function VideoViewer(
  props: PropsWithChildren<VideoViewerProps>
): React.ReactElement {
  const { className, onClose, isOpen, videoSource } = props;
  return (
    <MediaModal className={className} onClose={onClose} isOpen={isOpen}>
      <video
        className={cn(
          "max-w-full",
          "max-h-full",
          "w-full",
          "object-contain",
          "mx-auto",
          "pt-4",
          "pb-5",
          "px-8"
        )}
        src={videoSource}
        controls={true}
      />
    </MediaModal>
  );
}
