import React, { useCallback } from "react";
import ReactTimeAgo from "react-time-ago";
import i18next from "i18next";
import { Trans } from "react-i18next";
import { XMarkIcon } from "@heroicons/react/24/outline";
import cn from "classnames";
import {
  ShiftRequest,
  useBorrowingRequestHandlerShiftsRequestsShiftIdPtUserIdBorrowingPostMutation as useBorrowingShiftRequest,
  useContactHandlerShiftsShiftIdContactedPtUserPtUserIdPostMutation as useContactUser,
} from "oneclick-component/src/store/apis/enhancedApi";
import { IconButton } from "oneclick-component/src/components/Button";
import CCOBadge from "oneclick-component/src/components/Badge/CCOBadge";
import { ChatIcon, CheckIcon } from "oneclick-component/src/icon";
import { makeShiftDisplayInterval } from "oneclick-component/src/utils/shift";
import { StationBadge } from "oneclick-component/src/components/Badge";
import useOpenChatFromShiftRequest from "../../hooks/useOpenChatFromShiftRequest";
import { useShowError } from "../../hooks/useShowError";
import { PTUserAvatarImage } from "../../components/PTUserAvatarImage";

interface Props {
  shiftRequest: ShiftRequest;
  onStartChat: (shiftRequest: ShiftRequest) => void;
  showStationTeam: boolean;
}

function PendingNotificationRow(props: Props): React.ReactElement {
  const [openChat, isLoading] = useOpenChatFromShiftRequest();
  const [borrowShiftRequest] = useBorrowingShiftRequest();
  const { shiftRequest, onStartChat, showStationTeam } = props;
  const [contactUser] = useContactUser();
  const { showError } = useShowError();

  const handleRejectShiftRequest = useCallback(() => {
    openChat(shiftRequest.shift!, shiftRequest.ptUser, shiftRequest, true);
    borrowShiftRequest({
      shiftId: shiftRequest.shift!.id,
      ptUserId: shiftRequest.ptUser.id,
      borrowingRequestParam: {
        borrowingStatus: "rejected",
      },
    })
      .unwrap()
      .catch((e: unknown) => {
        showError(e, "notification.tab.pending.record.action.reject.error");
      });
  }, [openChat, shiftRequest, borrowShiftRequest, showError]);
  const handleApproveShiftRequest = useCallback(() => {
    openChat(shiftRequest.shift!, shiftRequest.ptUser, shiftRequest, true);
    borrowShiftRequest({
      shiftId: shiftRequest.shift!.id,
      ptUserId: shiftRequest.ptUser.id,
      borrowingRequestParam: {
        borrowingStatus: "approved",
      },
    })
      .unwrap()
      .catch((e: unknown) => {
        showError(e, "notification.tab.pending.record.action.approve.error");
      });
  }, [openChat, shiftRequest, borrowShiftRequest, showError]);

  const handleStartChatClick = useCallback(() => {
    openChat(shiftRequest.shift!, shiftRequest.ptUser, shiftRequest);
    onStartChat(shiftRequest);
    if (shiftRequest.borrowingRequestStatus === "pending") {
      contactUser({
        shiftId: shiftRequest.shift!.id,
        ptUserId: shiftRequest.ptUser.id,
        isPartnerContact: true,
      })
        .then()
        .catch((e: unknown) => {
          showError(e, "chatDialog.messageList.startChat.error.title");
        });
    }
  }, [openChat, onStartChat, shiftRequest, contactUser, showError]);

  return (
    <div
      className={cn(
        "rounded-lg",
        "border",
        "border-black/10",
        "p-4",
        "flex",
        "flex-row",
        "flex-wrap",
        "sm:flex-nowrap",
        "justify-between",
        "items-center",
        "relative"
      )}
    >
      {shiftRequest.borrowingRequestStatus === "pending" ? (
        <div
          className={cn(
            "w-[6px]",
            "h-[6px]",
            "bg-red-600",
            "absolute",
            "rounded-full",
            "right-2",
            "top-2"
          )}
        />
      ) : null}
      <div className={cn("flex-nowrap", "sm:w-auto", "flex", "flex-row")}>
        <PTUserAvatarImage ptUser={shiftRequest.ptUser} size="48" />
        <div className={"mx-10"}>
          <p className={cn("font-normal", "flex", "items-center")}>
            <Trans
              i18nKey="notification.tab.pending.record.title"
              values={{
                displayName: shiftRequest.ptUser.fullNameZhHk,
                partTimeType: shiftRequest.ptUser.role?.name ?? "SA",
                station: shiftRequest.shift?.workingStation.shortCode,
              }}
              components={
                shiftRequest.ptUser.station != null
                  ? [
                      <span key={0}>
                        <StationBadge
                          className="mx-2"
                          station={shiftRequest.ptUser.station}
                          stationTeam={shiftRequest.ptUser.stationTeam ?? null}
                          showStationTeam={showStationTeam}
                        />
                        {shiftRequest.ptUser.role?.name === "CCO" ? (
                          <CCOBadge className="mr-2" />
                        ) : null}
                      </span>,
                    ]
                  : undefined
              }
            />
          </p>
          <p className={cn("text-xs", "text-black/60", "font-normal", "mt-1")}>
            {shiftRequest.shift?.displayId} {shiftRequest.shift?.shiftTitle}{" "}
          </p>
          <p className={cn("text-xs", "text-black/60", "font-normal", "mb-1")}>
            {
              makeShiftDisplayInterval(
                shiftRequest.shift!.dutyStartTime,
                shiftRequest.shift!.dutyEndTime
              )[0]
            }
          </p>
          <p
            className={cn(
              "text-xs",
              "text-black/60",
              "font-normal",
              "flex",
              "items-center"
            )}
          >
            <ReactTimeAgo
              locale={i18next.language}
              date={new Date(shiftRequest.appliedAt ?? shiftRequest.updatedAt)}
              timeStyle="round-minute"
            />
          </p>
        </div>
      </div>
      <div className={cn("mt-3", "sm:mt-0")}>
        <div>
          <h6 className={cn("text-xs", "text-black/60", "mb-1")}>
            <Trans
              i18nKey="notification.tab.pending.record.action.reminder"
              values={{
                station: shiftRequest.ptUser.station?.shortCode,
              }}
            />
          </h6>
          <div className={cn("flex", "items-center")}>
            <button
              className={cn(
                "flex",
                "flex-row",
                "gap-2",
                "border",
                "border-gray-500",
                "px-2.5",
                "py-2",
                "items-center",
                "bg-white",
                "rounded"
              )}
              type="button"
              onClick={handleApproveShiftRequest}
            >
              <CheckIcon
                className={cn("w-5", "h-5", "fill-teal-600", "stroke-2")}
              />
              <p className={cn("text-black/90", "text-sm", "font-medium")}>
                <Trans i18nKey="notification.tab.pending.record.action.approve" />
              </p>
            </button>
            <button
              className={cn(
                "flex",
                "flex-row",
                "gap-2",
                "border",
                "border-gray-500",
                "ml-3",
                "px-2.5",
                "py-2",
                "items-center",
                "bg-white",
                "rounded"
              )}
              type="button"
              onClick={handleRejectShiftRequest}
            >
              <XMarkIcon
                className={cn("w-5", "h-5", "text-red-600", "stroke-2")}
              />
              <p className={cn("text-black/90", "text-sm", "font-medium")}>
                <Trans i18nKey="notification.tab.pending.record.action.reject" />
              </p>
            </button>
            <IconButton
              className={cn("flex-none", "ml-4")}
              icon={ChatIcon}
              theme="whitePrimary"
              disabled={isLoading}
              onClick={handleStartChatClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PendingNotificationRow;
