import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import * as Sentry from "@sentry/react";
import useWebSocket, { ReadyState } from "react-use-websocket";

import {
  enhancedApi as api,
  useLazyGenerateWebsocketTokenHandlerTokenWebsocketGetQuery as useLazyGenerateWebsocketTokenQuery,
} from "oneclick-component/src/store/apis/enhancedApi";
import { config } from "../config";
import {
  webSocketEventSchema,
  ChatReadEventPayload,
  MessageStatusUpdateEventPayload,
  NewChatEventPayload,
  NewMessageEventPayload,
  FullTimeAttendanceEventPayload,
  ShiftRequestEventPayload,
} from "../models/webSocketEvent";
import {
  chatAdded,
  chatRead,
  messageAdded,
  messageStatusUpdated,
} from "../store/chatState";
import {
  addFullTimeAttendanceToCaches,
  removeFullTimeAttendanceFromCaches,
} from "../store/fullTimeAttendanceActions";
import {
  updateShiftRequestInShiftListCaches,
  removeShiftRequestFromShiftListCaches,
} from "../store/shiftRequestActions";
import { useAppDispatch } from "../store/hooks";
import useMeUser from "../hooks/useMeUser";

const RECONNECT_INTERVAL_MS = 5000;
const HEARTBEAT_INTERVAL_MS = 5000;

interface WebSocketContextValue {
  readyState: ReadyState;
  send: (payload: string) => void;
}

export const WebSocketContext = React.createContext<WebSocketContextValue>(
  null as any
);

const WebSocketProvider = (props: PropsWithChildren): ReactElement => {
  const isHeartbeatResponsePendingRef = useRef<boolean>(false);
  const dispatch = useAppDispatch();
  const meUser = useMeUser();
  const [generateWebsocketToken] = useLazyGenerateWebsocketTokenQuery();

  const handleChatReadEvent = useCallback(
    (payload: ChatReadEventPayload) => {
      if (meUser == null) {
        return;
      }
      if (
        meUser.selectedProfile == null ||
        meUser.selectedProfile.station.id === payload.stationId
      ) {
        dispatch(
          chatRead({
            chatId: payload.chatId,
            readAt: payload.lastReadAt,
          })
        );
        dispatch(api.util.invalidateTags(["UnreadChatCount" as any]));
      }
    },
    [meUser, dispatch]
  );

  const handleMessageStatusUpdate = useCallback(
    (payload: MessageStatusUpdateEventPayload) => {
      if (meUser == null) {
        return;
      }
      dispatch(
        messageStatusUpdated({
          chatId: payload.chatId,
          messageId: payload.messageId,
          status: payload.status,
        })
      );
    },
    [meUser, dispatch]
  );

  const handleNewMessage = useCallback(
    (payload: NewMessageEventPayload) => {
      if (meUser == null) {
        return;
      }
      dispatch(messageAdded({ message: payload.message }));
      dispatch(api.util.invalidateTags(["UnreadChatCount"]));
    },
    [meUser, dispatch]
  );

  const handleNewChat = useCallback(
    (payload: NewChatEventPayload) => {
      if (meUser == null) {
        return;
      }
      dispatch(chatAdded({ chat: payload.chat }));
      dispatch(api.util.invalidateTags(["UnreadChatCount"]));
    },
    [meUser, dispatch]
  );

  const handleNewFullTimeAttendance = useCallback(
    (payload: FullTimeAttendanceEventPayload) => {
      /** Dispatch UI event for active users that need to display toast message */
      const event = new CustomEvent(
        `shiftDetail_${payload.attendance.shiftId}`,
        {
          detail: {
            type: "ADD_FULL_TIME",
            attendance: payload.attendance,
          },
        }
      );
      window.dispatchEvent(event);

      /** Dispatch update to shift list api that contain full time attendances data */
      const actions = addFullTimeAttendanceToCaches(payload);
      for (const action of actions) {
        dispatch(action);
      }
    },
    [dispatch]
  );
  const handleCancelFullTimeAttendance = useCallback(
    (payload: FullTimeAttendanceEventPayload) => {
      /** Dispatch UI event for active users that need to display toast message */
      const event = new CustomEvent(
        `shiftDetail_${payload.attendance.shiftId}`,
        {
          detail: {
            type: "REMOVE_FULL_TIME",
            attendance: payload.attendance,
          },
        }
      );
      window.dispatchEvent(event);

      /** Dispatch update to shift list api that contain full time attendances data */
      const actions = removeFullTimeAttendanceFromCaches(payload);
      for (const action of actions) {
        dispatch(action);
      }
    },
    [dispatch]
  );

  const handleUpdateShiftRequestStatus = useCallback(
    (payload: ShiftRequestEventPayload) => {
      /** Dispatch UI event for active users that need to display toast message */
      const event = new CustomEvent(
        `shiftDetail_${payload.shiftRequest.shiftId}`,
        {
          detail: {
            type: "ADD_PART_TIME",
            shiftRequest: payload.shiftRequest,
          },
        }
      );
      window.dispatchEvent(event);

      const actions = updateShiftRequestInShiftListCaches(payload);
      for (const action of actions) {
        dispatch(action);
      }
    },
    [dispatch]
  );

  const handleCancelShiftRequestStatus = useCallback(
    (payload: ShiftRequestEventPayload) => {
      /** Dispatch UI event for active users that need to display toast message */
      const event = new CustomEvent(
        `shiftDetail_${payload.shiftRequest.shiftId}`,
        {
          detail: {
            type: "REMOVE_PART_TIME",
            shiftRequest: payload.shiftRequest,
          },
        }
      );
      window.dispatchEvent(event);

      const actions = removeShiftRequestFromShiftListCaches(payload);
      for (const action of actions) {
        dispatch(action);
      }
    },
    [dispatch]
  );

  const rootMessageHandler = useCallback(
    // eslint-disable-next-line complexity
    (event: MessageEvent) => {
      let eventData;
      try {
        console.info("Received websocket event: ", event.data);
        const rawData = JSON.parse(event.data);
        eventData = webSocketEventSchema.parse(rawData);
        console.info(eventData.type, eventData.payload);
      } catch (err: unknown) {
        // Nothing can be done by user, silent error capture to Sentry
        console.warn("Failed to parse event: ", err);
        Sentry.captureException(err);
        return;
      }

      switch (eventData.type) {
        case "heartbeat":
          isHeartbeatResponsePendingRef.current = false;
          break;
        case "chatRead":
          handleChatReadEvent(eventData.payload);
          break;
        case "messageStatusUpdate":
          handleMessageStatusUpdate(eventData.payload);
          break;
        case "newChat":
          handleNewChat(eventData.payload);
          break;
        case "newMessage":
          handleNewMessage(eventData.payload);
          break;
        case "newFullTimeAttendance":
          handleNewFullTimeAttendance(eventData.payload);
          break;
        case "cancelFullTimeAttendance":
          handleCancelFullTimeAttendance(eventData.payload);
          break;
        case "updateShiftRequest":
          handleUpdateShiftRequestStatus(eventData.payload);
          break;
        case "cancelShiftRequest":
          handleCancelShiftRequestStatus(eventData.payload);
          break;
        default: {
          const msg = `Unrecognized event ${JSON.stringify(eventData)}`;
          console.warn(msg);
          Sentry.captureMessage(msg);
          break;
        }
      }
    },
    [
      handleChatReadEvent,
      handleMessageStatusUpdate,
      handleNewChat,
      handleNewMessage,
      handleNewFullTimeAttendance,
      handleCancelFullTimeAttendance,
      handleCancelShiftRequestStatus,
      handleUpdateShiftRequestStatus,
    ]
  );

  const getWebSocketUrl = useCallback(async () => {
    const { token } = await generateWebsocketToken().unwrap();
    const webSocketUrl = new URL("/ws/connect/admin-app", config.wsBaseUrl);
    webSocketUrl.searchParams.append("token", token);
    return webSocketUrl.href;
  }, [generateWebsocketToken]);

  // NOTE: always reconnect if there is no connection
  const shouldReconnectHandler = useCallback(() => true, []);

  const { sendMessage, readyState, getWebSocket } = useWebSocket(
    getWebSocketUrl,
    {
      onMessage: rootMessageHandler,
      retryOnError: true,
      reconnectInterval: RECONNECT_INTERVAL_MS,
      shouldReconnect: shouldReconnectHandler,
    }
  );

  // NOTE: heartbeat to ensure the health of connection
  useEffect(() => {
    let heartbeatHandle: number | null = null;
    if (readyState === ReadyState.OPEN) {
      heartbeatHandle = window.setInterval(() => {
        const isHeartbeatResponsePending =
          isHeartbeatResponsePendingRef.current;
        if (isHeartbeatResponsePending) {
          console.warn(
            "Previous heartbeat response not received, closing connection and reconnect"
          );
          getWebSocket()?.close();
          isHeartbeatResponsePendingRef.current = false;
          return;
        }
        sendMessage(
          JSON.stringify({
            type: "heartbeat",
          })
        );
        isHeartbeatResponsePendingRef.current = true;
      }, HEARTBEAT_INTERVAL_MS);
    }

    return () => {
      if (heartbeatHandle != null) {
        clearInterval(heartbeatHandle);
      }
    };
  }, [readyState, sendMessage, getWebSocket]);

  useEffect(() => {
    console.log(
      "websocket connection state changed: " +
        `${readyState} - ${ReadyState[readyState]}`
    );
  }, [readyState]);

  const contextValue = useMemo(
    () => ({
      send: sendMessage,
      readyState,
    }),
    [sendMessage, readyState]
  );

  return (
    <WebSocketContext.Provider value={contextValue}>
      {props.children}
    </WebSocketContext.Provider>
  );
};

export default WebSocketProvider;
