import { Popover, Transition } from "@headlessui/react";
import React, {
  CSSProperties,
  ForwardedRef,
  Fragment,
  ReactElement,
  useCallback,
  useState,
} from "react";
import cn from "classnames";
import { ChevronLeftIcon, ChevronRightIcon } from "../../../../icon";
import { MonthButton } from "./MonthButton";
import { Option } from "../Select";
import { dateTimeNow, formatDateTime } from "../../../../utils/datetime";
import { MAX_YEAR, MIN_YEAR } from "../../../../constants/datetime";
import YearMonth from "../../../../models/yearMonth";
import { DateTime } from "luxon";
import { Checkbox } from "../../Checkbox";
import { t } from "i18next";

interface CommonProps {
  style?: CSSProperties;
  className?: string;
}

interface WithoutSelectAllProps extends CommonProps {
  onChange: (value: YearMonth) => void;
  value: YearMonth | null;
  allowSelectAll?: false;
}

interface WithSelectAllProps extends CommonProps {
  onChange: (value: YearMonth | null) => void;
  value: YearMonth | null;
  allowSelectAll: true;
}

type Props = WithoutSelectAllProps | WithSelectAllProps;

export const MonthPickerPanel = React.forwardRef(function MonthPickerPanel(
  props: Props,
  ref: ForwardedRef<HTMLDivElement>
): ReactElement {
  const { value, onChange, style, className, allowSelectAll } = props;
  const [yearOption, setYearOption] = useState<number>(
    value?.year ?? dateTimeNow().year
  );
  const months: Option<YearMonth>[] = Array(12)
    .fill(0)
    .map((_, i) => {
      const yearMonth = { year: yearOption, month: i + 1 };
      const d = DateTime.fromObject(yearMonth);
      return {
        name: formatDateTime(d, "LLL"),
        value: yearMonth,
      };
    });
  const onClickPrevYear = useCallback(() => {
    setYearOption((prev) => Math.max(prev - 1, MIN_YEAR));
  }, []);
  const onClickNextYear = useCallback(() => {
    setYearOption((prev) => Math.min(prev + 1, MAX_YEAR));
  }, []);
  const [isShowAllChecked, setIsShowAllChecked] = useState<boolean>(
    value == null
  );
  const makeOnClickMonthButton = useCallback(
    (yearMonth: YearMonth) => () => {
      setIsShowAllChecked(false);
      onChange(yearMonth);
    },
    [onChange]
  );

  const onChangeShowAll = useCallback(() => {
    if (allowSelectAll !== true) {
      return;
    }
    const now = dateTimeNow();
    onChange(isShowAllChecked ? { year: now.year, month: now.month } : null);
    setIsShowAllChecked((prev) => !prev);
  }, [allowSelectAll, isShowAllChecked, onChange]);
  return (
    <Transition
      as={Fragment}
      enter="transition-opacity ease-out duration-200"
      enterFrom="opacity-0 translate-y-0"
      enterTo="opacity-100 translate-y-1"
      leave="transition-opacity ease-in duration-150"
      leaveFrom="opacity-100 translate-y-1"
      leaveTo="opacity-0 translate-y-0"
    >
      <Popover.Panel
        ref={ref}
        style={style}
        className={cn(
          "bg-white",
          "px-3",
          "pb-3",
          "overflow-hidden",
          "rounded-lg",
          "shadow-lg",
          "ring-1",
          "ring-black/5",
          className
        )}
        as="div"
      >
        <div className={cn("flex", "items-center")}>
          <button
            type="button"
            className={cn(
              "focus:ring-2",
              "focus:ring-offset-0",
              "ring-inset",
              "focus:outline-none",
              "flex",
              "items-center",
              "bg-white",
              "hover:bg-gray-50",
              "disabled:bg-white/25",
              "p-3"
            )}
            onClick={onClickPrevYear}
          >
            <ChevronLeftIcon className={cn("fill-gray-700", "w-6", "h-6")} />
          </button>
          <p
            className={cn("flex-1", "text-center", "text-black/86", "text-sm")}
          >
            {yearOption}
          </p>
          <button
            type="button"
            className={cn(
              "focus:ring-2",
              "focus:ring-offset-0",
              "ring-inset",
              "focus:outline-none",
              "flex",
              "items-center",
              "bg-white",
              "hover:bg-gray-50",
              "disabled:bg-white/25",
              "p-3"
            )}
            onClick={onClickNextYear}
          >
            <ChevronRightIcon className={cn("fill-gray-700", "w-6", "h-6")} />
          </button>
        </div>
        <div className={cn("relative", "grid", "bg-white", "grid-cols-4")}>
          {months.map(({ value: { year, month }, name }) => (
            <MonthButton
              key={`${year}-${month}`}
              onClick={makeOnClickMonthButton({ year, month })}
              // eslint-disable-next-line react/jsx-no-leaked-render
              active={year === value?.year && month === value.month}
            >
              {name}
            </MonthButton>
          ))}
        </div>
        {allowSelectAll ? (
          <>
            <div className={cn("border", "border-black/12", "my-4")}></div>
            <Checkbox
              name="showAll"
              checked={isShowAllChecked}
              onChange={onChangeShowAll}
              label={t("common.showAll")}
              labelClassName={cn("!text-black/86", "!font-normal")}
            />
          </>
        ) : null}
      </Popover.Panel>
    </Transition>
  );
});
