import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Trans } from "react-i18next";
import { RowSelectionState, SortingState } from "@tanstack/react-table";
import cn from "classnames";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import Pagination from "oneclick-component/src/components/Pagination";
import { useBreakPoints } from "oneclick-component/src/providers";
import {
  ShiftWithRequests,
  ShiftRequestBase,
  useListShiftsHandlerShiftsGetQuery as useListShift,
  WorkingStation,
  ShiftType,
} from "oneclick-component/src/store/apis/enhancedApi";
import {
  formatDateTime,
  isoToDateTime,
} from "oneclick-component/src/utils/datetime";
import { makeShiftDisplayInterval } from "oneclick-component/src/utils/shift";
import useOnClickTableColumnHeader, {
  ControlledTableState,
} from "oneclick-component/src/hooks/useOnClickTableColumnHeader";

import { RootState } from "../../../store/store";
import { saveExpiredShiftListFilter } from "../../../store/filterState";
import MobileSearchView from "../../../components/MobileSearchView";
import i18next from "../../../i18n/i18n";
import { SHIFT_EXPIRED_TAB_GROUPING_COLUMN_HEADER_MAP } from "../../../constants/shifts/expiredTab";
import { useIsManager } from "../../../hooks/role";
import { HiredShiftRequestBase } from "../../../models/shiftRequest";
import { useSelectedStationProfile } from "../../../hooks/useSelectedStationProfile";
import AppRoutes from "../../../routes/AppRoutes";
import { constructProfileScopedWorkingStationFilter } from "../workingStationFilter";
import {
  getDateRangeFromFilter,
  ShiftDateRangeFilter,
} from "../DateRangeFilter/model";
import { usePtUserRoleFilterOptions } from "../usePtUserRoleFilterOptions";
import { SHIFT_LIST_PAGE_SIZE } from "..";
import {
  ExpiredShiftListRecord,
  useExpiredShiftListTable,
} from "./useExpiredShiftListTable";
import ExpiredShiftTabSelectedActionBar from "./SelectedActionBar";
import ExpiredShiftTabActionBar from "./ActionBar";
import ExpiredShiftTabTable from "./Table";
import useShiftRateFilterOptions from "../useShiftRateFilterOptions";
import { useShiftTypeFilterOptions } from "../useShiftTypeFilterOptions";

const transformFromShiftToShiftListRecord = (
  shift: ShiftWithRequests
): ExpiredShiftListRecord => {
  const [_, { startTime, endTime, overnight }] = makeShiftDisplayInterval(
    shift.dutyStartTime,
    shift.dutyEndTime
  );
  const timeRangeString =
    endTime === ""
      ? `${startTime} - ${i18next.t("common.tbc")}`
      : `${startTime} - ${endTime}${overnight}`;
  const appliedRequests: ShiftRequestBase[] = shift.shiftRequests.filter(
    (req) =>
      (req.status === "applied" || req.status === "contacted") &&
      !req.ptUser.isDeleted
  );
  const hireRequests: HiredShiftRequestBase[] = shift.shiftRequests
    .filter((req) => req.status === "hired")
    .map((req) => req as HiredShiftRequestBase);
  let remoteStation = null;
  if (shift.isIncident) {
    remoteStation = shift.supportStation;
  } else if (
    shift.workingStation.shortCode !==
    shift.workingStation.mainStation.shortCode
  ) {
    remoteStation = shift.workingStation;
  }
  return {
    info: {
      title: shift.shiftTitle,
      subtitle: (
        <Trans
          i18nKey="shiftList.table.displayId"
          values={{ displayId: shift.displayId }}
        />
      ),
      rate: shift.rate,
      isAppliableToAllAtT3: shift.isAppliableToAllAtT3,
    },
    timeRange: timeRangeString,
    partTimeType: shift.ptUserRoles.map((role) => role.name).join(", "),
    appliedRequests: appliedRequests,
    hireStatus: {
      workingStationShortCode: shift.workingStation.shortCode,
      fulfillmentCount: shift.fulfillmentCount,
      hiredRequests: hireRequests,
    },
    title: shift.shiftTitle,
    date: formatDateTime(isoToDateTime(shift.dutyStartTime), "yyyy-LL-dd"),
    numberOfApplicants: appliedRequests.length,
    fulfillmentCount: shift.fulfillmentCount,
    fullTimeCount: shift.fulltimeCount,
    hiredFullTimeCount: shift.fullTimeAttendances?.length ?? 0,
    id: shift.id,
    shiftType: shift.shiftType,
    remoteStation: remoteStation,
  };
};

const useSortingQueryParam = (
  sortingState: SortingState
): string | undefined => {
  const defaultSort = `dutyStartTime+,dutyEndTime+`;
  if (sortingState.length === 0 || sortingState.length > 1) {
    return defaultSort;
  }
  // sortingState.length === 1
  const singleColumnSorting = sortingState[0];
  const sortDirectionSymbol = singleColumnSorting.desc ? "-" : "+";

  switch (singleColumnSorting.id) {
    case "info": {
      return `shiftTitle${sortDirectionSymbol}`;
    }
    case "timeRange": {
      return `dutyStartTime${sortDirectionSymbol},dutyEndTime${sortDirectionSymbol}`;
    }
    case "partTimeType": {
      return `ptUserRoleId${sortDirectionSymbol},${defaultSort}`;
    }
    case "appliedRequests": {
      return `numApplicants${sortDirectionSymbol},${defaultSort}`;
    }
    case "hireStatus": {
      return `fulfillmentCount${sortDirectionSymbol},${defaultSort}`;
    }
    default: {
      return defaultSort;
    }
  }
};

const ExpiredShiftTab = (): React.ReactElement => {
  const navigate = useNavigate();
  const { useIsSm } = useBreakPoints();
  const isSm = useIsSm();
  const dispatch = useDispatch();
  const isManager = useIsManager();
  const selectedStationProfile = useSelectedStationProfile();

  const savedFilter = useSelector((state: RootState) => {
    return state.filterState.expiredShiftTabFilter;
  }, shallowEqual);

  const [isMobileSearchView, setIsMobileSearchView] = useState<boolean>(false);

  const [pageIndex, setPageIndex] = useState(0);
  const [searchStringFilter, setSearchStringFilter] = useState<
    string | undefined
  >(savedFilter?.searchStringFilter);
  const [dateRangeFilter, setDateRangeFilter] = useState<ShiftDateRangeFilter>(
    savedFilter?.dateRangeFilter ?? {
      type: "all",
    }
  );
  const [workingStationId, setWorkingStationId] = useState<number | null>(
    savedFilter?.workingStationId ?? null
  );
  const [ptUserRoleFilter, setPtUserRoleFilter] = useState<string | null>(
    savedFilter?.ptUserRoleFilter ?? null
  );
  const [ptUserRoleFilterOptions, resolvePtUserRoleOptionToRoleId] =
    usePtUserRoleFilterOptions();

  const [shiftRateFilter, setShiftRateFilter] = useState<number | null>(
    savedFilter?.shiftRateFilter ?? null
  );
  const shiftRateFilterOptions = useShiftRateFilterOptions();

  const [shiftTypeFilter, setShiftTypeFilter] = useState<ShiftType>(
    savedFilter?.shiftTypeFilter ?? "REGULAR"
  );
  const shiftTypeFilterOptions = useShiftTypeFilterOptions();

  const shiftListFilterState = useMemo(() => {
    return {
      searchString: searchStringFilter,
      shiftType: shiftTypeFilter,
      dateRange: dateRangeFilter,
      ptUserRole: ptUserRoleFilter,
      workingStationId: workingStationId,
    };
  }, [
    searchStringFilter,
    shiftTypeFilter,
    dateRangeFilter,
    ptUserRoleFilter,
    workingStationId,
  ]);

  const [controlledTableState, setControlledTableState] =
    useState<ControlledTableState>({
      sorting: [{ id: "timeRange", desc: false }],
      grouping: ["date"],
    });
  const sortingQueryParam = useSortingQueryParam(controlledTableState.sorting);

  const profileScopedWorkingStationFilter =
    constructProfileScopedWorkingStationFilter(
      selectedStationProfile,
      workingStationId
    );

  const dateRange = getDateRangeFromFilter(dateRangeFilter);

  const { currentData: ExpiredShiftsResponse, isFetching } = useListShift(
    {
      status: "expired",
      pageSize: SHIFT_LIST_PAGE_SIZE,
      sort: sortingQueryParam,
      pageIndex: pageIndex,
      shiftType: shiftTypeFilter,
      searchString: searchStringFilter,
      before: dateRange?.before?.toISO(),
      after: dateRange?.after?.toISO(),
      ptUserRoleIds: resolvePtUserRoleOptionToRoleId(ptUserRoleFilter),
      rateId: shiftRateFilter,
      workingStationId: profileScopedWorkingStationFilter,
    },
    { refetchOnMountOrArgChange: true }
  );
  const { results: shifts } = ExpiredShiftsResponse ?? {};
  const totalCount = ExpiredShiftsResponse?.totalCount ?? 0;
  const tableData = useMemo(() => {
    return (shifts ?? []).map(transformFromShiftToShiftListRecord);
  }, [shifts]);
  const isShiftListEmpty = tableData.length === 0;

  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({});
  const table = useExpiredShiftListTable(tableData, setRowSelection, {
    columnVisibility: {
      partTimeType: isSm ? true : false,
      title: false,
      date: false,
      fulfillmentCount: false,
      numberOfApplicants: false,
      id: false,
    },
    sorting: controlledTableState.sorting,
    grouping: controlledTableState.grouping,
    rowSelection,
  });

  const resetToPageOne = useCallback(() => {
    setPageIndex(0);
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    resetToPageOne();
    setRowSelection({});
  }, [
    controlledTableState.sorting,
    controlledTableState.grouping,
    shiftListFilterState,
    resetToPageOne,
  ]);

  const onClickColumnHeader =
    useOnClickTableColumnHeader<ExpiredShiftListRecord>(
      setControlledTableState,
      SHIFT_EXPIRED_TAB_GROUPING_COLUMN_HEADER_MAP,
      new Set<string>(["action"]),
      new Set<string>(["date"])
    );

  const onChangeSearchString = useCallback(
    (v: string) => {
      const trimmedSearchString = v.trim();
      if (trimmedSearchString === "") {
        dispatch(
          saveExpiredShiftListFilter({
            searchStringFilter: undefined,
          })
        );
        setSearchStringFilter(undefined);
        return;
      }
      dispatch(
        saveExpiredShiftListFilter({
          searchStringFilter: trimmedSearchString,
        })
      );
      setSearchStringFilter(trimmedSearchString);
    },
    [dispatch]
  );

  const onClickTableRowFactory = useCallback(
    (shiftId: number) => () => {
      navigate(AppRoutes.ShiftDetailScreen.render(shiftId));
    },
    [navigate]
  );

  const onEnterMobileSearchView = useCallback(
    () => setIsMobileSearchView(true),
    []
  );
  const onExitMobileSearchView = useCallback(
    () => setIsMobileSearchView(false),
    []
  );

  const onChangePtUserRole = useCallback(
    (role: string | null) => {
      dispatch(
        saveExpiredShiftListFilter({
          ptUserRoleFilter: role,
        })
      );
      setPtUserRoleFilter(role);
    },
    [dispatch]
  );
  const onChangeShiftRate = useCallback(
    (rate: number | null) => {
      dispatch(
        saveExpiredShiftListFilter({
          shiftRateFilter: rate,
        })
      );
      setShiftRateFilter(rate);
    },
    [dispatch]
  );
  const onChangeWorkingStationFilter = useCallback(
    (station: WorkingStation | null) => {
      dispatch(
        saveExpiredShiftListFilter({
          workingStationId: station?.id ?? null,
        })
      );
      setWorkingStationId(station?.id ?? null);
    },
    [dispatch]
  );

  const onDateRangeFilterChange = useCallback(
    (filter: ShiftDateRangeFilter) => {
      dispatch(
        saveExpiredShiftListFilter({
          dateRangeFilter: filter,
        })
      );
      setDateRangeFilter(filter);
    },
    [dispatch]
  );

  const onChangeShiftType = useCallback(
    (value: ShiftType) => {
      dispatch(
        saveExpiredShiftListFilter({
          shiftTypeFilter: value,
        })
      );
      setShiftTypeFilter(value);
    },
    [dispatch]
  );

  const handleOnClickPage = useCallback((pageIndex: number) => {
    setPageIndex(pageIndex);
    setRowSelection({});
    window.scrollTo({ top: 0 });
  }, []);

  const contentUnderSearchBar = useMemo(
    () => (
      <>
        <ExpiredShiftTabActionBar
          className="mb-6"
          searchString={searchStringFilter}
          onChangeSearchString={onChangeSearchString}
          dateRangeFilter={dateRangeFilter}
          onDateRangeFilterChange={onDateRangeFilterChange}
          ptUserRole={ptUserRoleFilter}
          onChangePtUserRole={onChangePtUserRole}
          ptUserRoleOptions={ptUserRoleFilterOptions}
          shiftRate={shiftRateFilter}
          onChangeShiftRate={onChangeShiftRate}
          shiftRateOptions={shiftRateFilterOptions}
          shiftType={shiftTypeFilter}
          onChangeShiftType={onChangeShiftType}
          shiftTypeOptions={shiftTypeFilterOptions}
          showWorkingStationFilter={!isManager}
          workingStationFilter={workingStationId}
          onChangeWorkingStationFilter={onChangeWorkingStationFilter}
          isMobileSearchView={isMobileSearchView}
          onEnterMobileSearchView={onEnterMobileSearchView}
        />
        <ExpiredShiftTabSelectedActionBar
          className="mb-3"
          selectedShiftIds={table
            .getSelectedRowModel()
            .rows.map((row) => row.original.id)}
        />
        <ExpiredShiftTabTable
          isLoading={isFetching}
          isEmpty={isShiftListEmpty}
          table={table}
          // Make sure table UI updated when selection is updated
          rowSelection={rowSelection}
          onClickTableRowFactory={onClickTableRowFactory}
          onClickColumnHeader={onClickColumnHeader}
        />
        {totalCount > 0 ? (
          <>
            <Pagination
              className={cn(
                "mt-3",
                "bg-white",
                !isSm && ["fixed", "bottom-0", "inset-x-0"]
              )}
              totalPages={Math.ceil(totalCount / SHIFT_LIST_PAGE_SIZE)}
              pageIndex={pageIndex}
              onClickPage={handleOnClickPage}
            />
            <div className="h-16" />
          </>
        ) : null}
      </>
    ),
    [
      onChangeSearchString,
      dateRangeFilter,
      ptUserRoleFilter,
      ptUserRoleFilterOptions,
      shiftRateFilter,
      shiftRateFilterOptions,
      shiftTypeFilter,
      shiftTypeFilterOptions,
      isManager,
      workingStationId,
      isMobileSearchView,
      onEnterMobileSearchView,
      table,
      isFetching,
      isShiftListEmpty,
      rowSelection,
      onClickTableRowFactory,
      onClickColumnHeader,
      totalCount,
      isSm,
      pageIndex,
      handleOnClickPage,
      onChangePtUserRole,
      onChangeShiftRate,
      onDateRangeFilterChange,
      onChangeWorkingStationFilter,
      onChangeShiftType,
      searchStringFilter,
    ]
  );

  if (isMobileSearchView) {
    return (
      <MobileSearchView
        onChangeSearchInput={onChangeSearchString}
        onExit={onExitMobileSearchView}
      >
        {contentUnderSearchBar}
      </MobileSearchView>
    );
  }
  return <div className="mt-1">{contentUnderSearchBar}</div>;
};

export default ExpiredShiftTab;
