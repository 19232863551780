import React, { ChangeEvent, ReactNode, useCallback, useState } from "react";
import * as Sentry from "@sentry/react";

type FilePickerErrorType = "assetTooLarge" | "invalidFileType";

export class FilePickerBaseError extends Error {
  errorType: FilePickerErrorType;

  constructor(errorType: FilePickerErrorType) {
    super(errorType);
    this.errorType = errorType;

    // Fix instanceof not working for class extends Error class
    // Ref: https://github.com/microsoft/TypeScript/issues/13965
    Object.setPrototypeOf(this, FilePickerBaseError.prototype);
  }
}

export class FilePickerAssetTooLargeError extends FilePickerBaseError {
  errorType = "assetTooLarge" as const;
  sizeLimitMb: number;

  constructor(sizeLimitMb: number) {
    super("assetTooLarge");
    this.sizeLimitMb = sizeLimitMb;
    Object.setPrototypeOf(this, FilePickerAssetTooLargeError.prototype);
  }
}

export class FilePickerInvalidFileTypeError extends FilePickerBaseError {
  errorType = "invalidFileType" as const;

  constructor() {
    super("invalidFileType");
    Object.setPrototypeOf(this, FilePickerInvalidFileTypeError.prototype);
  }
}

interface FilePickerProps {
  buttonNode: ReactNode;
  acceptedMime: string;
  disabled?: boolean;
  sizeLimitMb?: (file: File) => number;
  onFileSelected: (file: File) => void;
  onValidationError: (error: FilePickerBaseError) => void;
}

export const FilePicker = React.memo((props: FilePickerProps) => {
  const {
    buttonNode,
    acceptedMime,
    sizeLimitMb,
    onFileSelected,
    onValidationError,
    disabled,
  } = props;

  const [key, setKey] = useState(0);

  const handleFileInputChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      const newFile = ev.target.files?.[0];
      if (newFile == null) {
        return;
      }
      try {
        const acceptedMimeRegex = new RegExp(
          `^${acceptedMime.replace(/,/g, "|^")}`
        );
        if (!acceptedMimeRegex.test(newFile.type)) {
          throw new FilePickerInvalidFileTypeError();
        }
        if (sizeLimitMb != null) {
          const evaluatedSizeLimitMb = sizeLimitMb(newFile);
          if (newFile.size > evaluatedSizeLimitMb * 1024 * 1024) {
            throw new FilePickerAssetTooLargeError(evaluatedSizeLimitMb);
          }
        }
        onFileSelected(newFile);
      } catch (err: unknown) {
        if (err instanceof FilePickerBaseError) {
          onValidationError(err);
        } else {
          // unknown error
          console.error(err);
          Sentry.captureException(err);
        }
      } finally {
        // NOTE: remount input to clear selected file after upload
        setKey((prev) => prev + 1);
      }
    },
    [acceptedMime, sizeLimitMb, onValidationError, onFileSelected]
  );

  return (
    <label>
      {buttonNode}
      <input
        key={key}
        type="file"
        multiple={false}
        onChange={handleFileInputChange}
        className="hidden"
        accept={acceptedMime}
        disabled={disabled}
      />
    </label>
  );
});
