import {
  GroupingState,
  Header,
  SortDirection,
  SortingState,
} from "@tanstack/react-table";
import { useCallback } from "react";
import { SELECT_COLUMN_ID } from "../components/TableWithSelect/constants";

export type ColumnSortingState = {
  id: string;
  sort: SortDirection;
} | null;

export interface ControlledTableState {
  sorting: SortingState;
  grouping?: GroupingState;
}

const getNewSorting = (
  old: SortingState,
  clickedColId: string
): SortingState => {
  const isColumnChanged =
    old.length === 0 || old.length > 1 || old[0].id !== clickedColId; // old.length === 1
  if (isColumnChanged) {
    return [{ id: clickedColId, desc: false }];
  }
  const prevSort = old[0];
  switch (prevSort.desc) {
    case false: {
      return [{ id: clickedColId, desc: true }];
    }
    case true: {
      return [];
    }
    default: {
      return [{ id: clickedColId, desc: false }];
    }
  }
};

const getNewGroup = (
  clickedColId: string,
  groupingColumnHeaderMap?: Record<string, string | undefined>,
  dominantGroupingColumnHeaders?: Set<string>
): GroupingState => {
  const dominantGroupingColumns =
    dominantGroupingColumnHeaders != null
      ? [...dominantGroupingColumnHeaders]
      : [];

  if (groupingColumnHeaderMap == null) {
    return [...dominantGroupingColumns];
  }
  const groupingColumn = groupingColumnHeaderMap[clickedColId];
  if (
    groupingColumn == null ||
    dominantGroupingColumns.includes(groupingColumn)
  ) {
    return [...dominantGroupingColumns];
  }
  return [groupingColumn, ...dominantGroupingColumns];
};

const useOnClickTableColumnHeader = <T,>(
  setControlledTableState: (
    action: (prev: ControlledTableState) => ControlledTableState
  ) => void,
  groupingColumnHeaderMap?: Record<string, string | undefined>,
  disabledColumnHeaders?: Set<string>,
  dominantGroupingColumnHeaders?: Set<string>
): ((header: Header<T, unknown>) => void) => {
  return useCallback(
    (header: Header<T, unknown>) => {
      const columnHeaderId = header.column.id;
      if (
        disabledColumnHeaders?.has(columnHeaderId) ||
        columnHeaderId === SELECT_COLUMN_ID
      ) {
        return;
      }
      setControlledTableState((prev) => {
        const { sorting } = prev;
        const newSorting = getNewSorting(sorting, columnHeaderId);
        const newGrouping = getNewGroup(
          columnHeaderId,
          groupingColumnHeaderMap,
          dominantGroupingColumnHeaders
        );
        return { ...prev, sorting: newSorting, grouping: newGrouping };
      });
    },
    [
      disabledColumnHeaders,
      dominantGroupingColumnHeaders,
      groupingColumnHeaderMap,
      setControlledTableState,
    ]
  );
};

export default useOnClickTableColumnHeader;
