import {
  PtUserVisitCount,
  PtUserWithVisitCount,
  Shift,
} from "oneclick-component/src/store/apis/enhancedApi";
import React, { ReactElement, useCallback, useMemo } from "react";
import { RequestedShiftRequestBase } from "../../models/shiftRequest";
import cn from "classnames";
import useOpenChatFromShiftRequest from "../../hooks/useOpenChatFromShiftRequest";
import { DeletedUserIcon } from "../../icon";
import { Trans } from "react-i18next";
import { Button, IconButton } from "oneclick-component/src/components/Button";
import { ChatIcon, ClockIcon } from "oneclick-component/src/icon";
import { isoToDateTime } from "oneclick-component/src/utils/datetime";
import { DateTime } from "luxon";
import { formatShiftRequestTime } from "../../utils/shiftRequest";
import { PTUserAvatarImage } from "../PTUserAvatarImage";
import { StationBadge } from "oneclick-component/src/components/Badge";
import CCOBadge from "oneclick-component/src/components/Badge/CCOBadge";

interface PTInfoProps {
  className?: string;
  disabled: boolean;
  user: PtUserWithVisitCount;
  shiftRequest: RequestedShiftRequestBase;
  userVisitCount: number;
  showStationTeam: boolean;
}

const PTInfo = (props: PTInfoProps): ReactElement => {
  const {
    className,
    disabled,
    user,
    shiftRequest,
    userVisitCount,
    showStationTeam,
  } = props;
  const shiftRequestTimeDisplayString = useMemo<string | null>(() => {
    const dt: DateTime = isoToDateTime(shiftRequest.createdAt);

    return formatShiftRequestTime(dt);
  }, [shiftRequest]);
  return (
    <div
      className={cn(
        "flex",
        "flex-row",
        {
          "opacity-40": disabled,
        },
        className
      )}
    >
      <div className={cn("flex", "items-center", "mr-8")}>
        <PTUserAvatarImage
          ptUser={user}
          override418Status={shiftRequest.ptUserShiftWeek418Status}
        />
      </div>
      <div>
        <p className={cn("font-normal", "flex", "items-center")}>
          {user.fullNameZhHk}
          {user.station != null ? (
            <StationBadge
              className="mx-2"
              station={user.station}
              stationTeam={user.stationTeam ?? null}
              showStationTeam={showStationTeam}
            />
          ) : null}
          {user.role?.name === "CCO" ? <CCOBadge /> : null}
        </p>
        <p className={cn("text-sm", "font-normal", "mb-1")}>
          <span className="text-black/60">ID</span>{" "}
          <span className="text-black/86">{user.eid}</span>{" "}
          <span className="text-black/12">|</span>{" "}
          <span className="text-black/60">
            <Trans i18nKey="shiftDetail.visitCount" />
          </span>{" "}
          <span className="text-black/86">{userVisitCount}</span>
        </p>

        <div className={cn("sm:flex", "sm:flex-row")}>
          <p className={cn("text-xs", "font-normal")}>
            <span className="text-black/60">
              <Trans i18nKey="shiftDetail.request.requestedAt" />
            </span>{" "}
            <span className="text-black/86">
              {shiftRequestTimeDisplayString}
            </span>{" "}
          </p>

          {shiftRequest.cancelCount != null ? (
            <p className={cn("text-xs", "font-normal", "ml-3")}>
              <span className="text-red-500">
                <Trans
                  i18nKey="shiftDetail.request.cancelCount"
                  values={{ count: shiftRequest.cancelCount }}
                />
              </span>
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

interface Props {
  user: PtUserWithVisitCount;
  shift: Shift;
  shiftRequest: RequestedShiftRequestBase;
  unread?: boolean;
  showChatButton?: boolean;
  showStationTeam?: boolean;
  disabled?: boolean;
}

const RequestedPTUserRequestRow = (props: Props): React.ReactElement => {
  const {
    user,
    shiftRequest,
    shift,
    unread,
    showChatButton = false,
    showStationTeam = false,
    disabled = false,
  } = props;
  const [openChat, isLoading] = useOpenChatFromShiftRequest();

  const handleStartChatClick = useCallback(() => {
    openChat(shift, user, shiftRequest);
  }, [openChat, shift, user, shiftRequest]);

  const disableChatButton = isLoading || user.isDeleted;

  const userVisitCount = useMemo<number>(() => {
    const userVisit: PtUserVisitCount | undefined = user.visitCounts?.find(
      (station: PtUserVisitCount) =>
        station.workingStationShortCode === shift.workingStation.shortCode
    );
    return userVisit?.visitCount ?? 0;
  }, [user, shift]);

  return (
    <div
      className={cn(
        "rounded-lg",
        "border",
        "border-black/10",
        "p-4",
        "pl-10",
        "flex",
        "flex-row",
        "items-center",
        "flex-wrap",
        "relative",
        disabled && ["bg-neutral-900/[.04]"]
      )}
    >
      {unread && !user.isDeleted ? (
        <div
          className={cn(
            "w-[6px]",
            "h-[6px]",
            "bg-red-600",
            "absolute",
            "rounded-full",
            "right-2",
            "top-2"
          )}
        />
      ) : null}
      <PTInfo
        className={cn("flex-none", "sm:w-auto", "mr-3")}
        disabled={disabled}
        user={user}
        showStationTeam={showStationTeam}
        userVisitCount={userVisitCount}
        shiftRequest={shiftRequest}
      />
      <div className={cn("basis-full", "sm:basis-0")} />
      {user.isDeleted ? (
        <div
          className={cn(
            "flex",
            "sm:flex-1",
            "sm:justify-end",
            "items-center",
            "mt-3",
            "sm:mt-0",
            "gap-1",
            "opacity-40",
            "mr-3",
            "last:mr-0"
          )}
        >
          <DeletedUserIcon className={cn("w-4", "h-4")} />
          <span
            className={cn(
              "text-xs",
              "text-black/60",
              "font-medium",
              "whitespace-nowrap"
            )}
          >
            <Trans i18nKey="partTime.shiftStatusRow.partTimeSuspended" />
          </span>
        </div>
      ) : (
        <div
          className={cn(
            "flex",
            "sm:flex-1",
            "justify-end",
            disabled && "opacity-40"
          )}
        >
          {shift.status === "cancelled" ? (
            <Button
              className={cn("mt-3", "sm:mt-0", "text-gray-600")}
              disabled={true}
              theme="white"
              iconClassName="fill-gray-600"
            >
              <Trans i18nKey="shiftDetail.action.shift.status.cancelled" />
            </Button>
          ) : (
            <Button
              prefixIcon={ClockIcon}
              className={cn("mt-3", "sm:mt-0", "text-gray-600")}
              disabled={true}
              theme="white"
              iconClassName="fill-gray-600"
            >
              <Trans i18nKey="shiftDetail.request.waitResponse" />
            </Button>
          )}
        </div>
      )}
      {showChatButton ? (
        <div
          className={cn("mt-3", "sm:mt-0", "ml-3", {
            "opacity-40": disableChatButton,
          })}
        >
          <IconButton
            icon={ChatIcon}
            theme="whitePrimary"
            disabled={disableChatButton}
            onClick={handleStartChatClick}
          />
        </div>
      ) : null}
    </div>
  );
};

export default RequestedPTUserRequestRow;
