import React, { useCallback, useMemo } from "react";
import cn from "classnames";
import { DateTime } from "luxon";
import { ShiftRequest } from "oneclick-component/src/store/apis/enhancedApi";
import { IconButton } from "oneclick-component/src/components/Button";
import { CloseIcon, ChatIcon, CheckIcon } from "oneclick-component/src/icon";
import { isoToDateTime } from "oneclick-component/src/utils/datetime";
import { Trans } from "react-i18next";
import useOpenChatFromShiftRequest from "../../hooks/useOpenChatFromShiftRequest";
import { makeShiftDisplayInterval } from "oneclick-component/src/utils/shift";
import { PTUserAvatarImage } from "../../components/PTUserAvatarImage";
import { formatShiftRequestTime } from "../../utils/shiftRequest";

interface Props {
  shiftRequest: ShiftRequest;
}

function HandledNotificationRow(props: Props): React.ReactElement {
  const [openChat, isLoading] = useOpenChatFromShiftRequest();
  const { shiftRequest } = props;
  const handleStartChatClick = useCallback(() => {
    openChat(shiftRequest.shift!, shiftRequest.ptUser, shiftRequest);
  }, [openChat, shiftRequest]);

  const statusBadge = useMemo(() => {
    switch (shiftRequest.borrowingRequestStatus) {
      case "approved":
        return (
          <span
            className={cn(
              "flex",
              "flex-none",
              "justify-center",
              "items-center",
              "py-0.5",
              "px-3",
              "border",
              "border-green-600",
              "rounded-md"
            )}
          >
            <CheckIcon className={cn("w-3", "h-3", "mr-2", "fill-teal-600")} />
            <p className={cn("text-xs", "font-normal", "text-green-600")}>
              <Trans i18nKey="notification.tab.handled.record.status.approved" />
            </p>
          </span>
        );
      case "rejected":
        return (
          <span
            className={cn(
              "flex",
              "flex-none",
              "justify-center",
              "items-center",
              "py-0.5",
              "px-3",
              "border",
              "border-red-600",
              "rounded-md"
            )}
          >
            <CloseIcon className={cn("w-3", "h-3", "mr-2")} />
            <p className={cn("text-xs", "font-normal", "text-red-600")}>
              <Trans i18nKey="notification.tab.handled.record.status.rejected" />
            </p>
          </span>
        );
      default:
        return null;
    }
  }, [shiftRequest]);

  const applyTimeDisplayString = useMemo<string>(() => {
    if (shiftRequest.appliedAt == null) {
      return "";
    }
    const dt: DateTime = isoToDateTime(shiftRequest.appliedAt);
    return formatShiftRequestTime(dt);
  }, [shiftRequest]);
  const approveTimeDisplayString = useMemo<string>(() => {
    if (shiftRequest.approvedAt == null) {
      return "";
    }
    const dt: DateTime = isoToDateTime(shiftRequest.approvedAt);
    return formatShiftRequestTime(dt);
  }, [shiftRequest]);
  const rejectTimeDisplayString = useMemo<string>(() => {
    if (shiftRequest.rejectedAt == null) {
      return "";
    }
    const dt: DateTime = isoToDateTime(shiftRequest.rejectedAt);
    return formatShiftRequestTime(dt);
  }, [shiftRequest]);

  return (
    <div
      className={cn(
        "rounded-lg",
        "border",
        "border-black/10",
        "p-4",
        "flex",
        "flex-row",
        "flex-nowrap",
        "justify-between",
        "items-center",
        "relative"
      )}
    >
      <div className={cn("flex-nowrap", "sm:w-auto", "flex", "flex-row")}>
        <PTUserAvatarImage ptUser={shiftRequest.ptUser} size="48" />
        <div className={"mx-10"}>
          <span className={cn("flex", "flex-wrap", "items-center")}>
            <p className={cn("font-normal", "mr-2")}>
              {shiftRequest.ptUser.fullNameZhHk}
            </p>
            {statusBadge}
          </span>
          <p className={cn("text-xs", "text-black/60", "font-normal", "mt-1")}>
            {shiftRequest.shift?.displayId} |{" "}
            {
              makeShiftDisplayInterval(
                shiftRequest.shift!.dutyStartTime,
                shiftRequest.shift!.dutyEndTime
              )[0]
            }
          </p>
          <p className={cn("text-xs", "font-normal", "mt-1")}>
            {shiftRequest.appliedAt == null ? null : (
              <>
                <span className="text-black/60">
                  <Trans i18nKey="shiftDetail.request.appliedAt" />
                </span>{" "}
                <span className={cn("text-black/86", "mr-3")}>
                  {applyTimeDisplayString}
                </span>
              </>
            )}
            {shiftRequest.approvedAt == null ? null : (
              <>
                <span className="text-black/60">
                  <Trans i18nKey="shiftDetail.request.hiredAt" />
                </span>{" "}
                <span className="text-black/86">
                  {approveTimeDisplayString}
                </span>{" "}
              </>
            )}
            {shiftRequest.rejectedAt == null ? null : (
              <>
                <span className="text-black/60">
                  <Trans i18nKey="shiftDetail.request.rejectedAt" />
                </span>{" "}
                <span className="text-black/86">{rejectTimeDisplayString}</span>{" "}
              </>
            )}
          </p>
        </div>
      </div>
      <IconButton
        className="flex-none"
        icon={ChatIcon}
        theme="whitePrimary"
        disabled={isLoading}
        onClick={handleStartChatClick}
      />
    </div>
  );
}

export default HandledNotificationRow;
