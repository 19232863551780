import {
  Station,
  useListShiftRequestHandlerShiftRequestsGetQuery as useListShiftRequestQuery,
  ListShiftRequestHandlerShiftRequestsGetApiArg as ListShiftRequestQueryArg,
} from "oneclick-component/src/store/apis/enhancedApi";
import useMeUserStations from "../../../hooks/useMeUserStations";
import { useMemo } from "react";
import {
  ViewState,
  makeViewStateEmpty,
  makeViewStateError,
  makeViewStateLoaded,
  makeViewStateLoading,
} from "./viewState";

export interface UseOutgoingTabQueryOptions
  extends Partial<ListShiftRequestQueryArg> {
  isOut: true;
  pageIndex?: number;
  pageSize?: number;
}

const useOutgoingTabQuery = (
  options?: UseOutgoingTabQueryOptions
): ViewState => {
  const meStations: Station[] | "all" = useMeUserStations();

  const ptStationIds = useMemo<number[] | null>(() => {
    if (meStations === "all" || meStations.length === 0) {
      return null;
    }
    return meStations.map((s) => s.id);
  }, [meStations]);

  const {
    data: listShiftRequestData,
    isFetching: isListShiftRequestFetching,
    error: listShiftRequestError,
  } = useListShiftRequestQuery(
    {
      ...options,
      shiftStatus: ["active", "expired"],
      shiftRequestStatus: ["hired"],
      ptStationId: options?.ptStationId ?? ptStationIds,
    },
    { refetchOnMountOrArgChange: true }
  );

  if (listShiftRequestData == null || isListShiftRequestFetching) {
    return makeViewStateLoading();
  }

  if (listShiftRequestError != null) {
    return makeViewStateError(listShiftRequestError);
  }

  if (listShiftRequestData.results.length === 0) {
    return makeViewStateEmpty();
  }

  return makeViewStateLoaded(
    listShiftRequestData.results,
    listShiftRequestData.totalCount,
    options?.pageIndex ?? 0
  );
};

export default useOutgoingTabQuery;
