import React, {
  ReactElement,
  useCallback,
  useEffect,
  useId,
  useMemo,
} from "react";
import { useParams } from "react-router-dom";
import { EditPartTimeForm, editPartTimeFormSchema } from "./form";
import { useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Option,
  SelectDecorator,
  Checkbox,
} from "oneclick-component/src/components/inputs";
import cn from "classnames";
import {
  FormField,
  FormSelect,
  FormTextInput,
} from "oneclick-component/src/components/forms";
import { Trans, useTranslation } from "react-i18next";
import {
  useGetPtUserByIdHandlerPartTimeUsersPtUserIdGetQuery,
  useGetPtUsersRolesHandlerPartTimeUsersRolesGetQuery,
  useUpdatePtUserHandlerPartTimeUsersPtUserIdPostMutation,
  useListStationTeamHandlerStationTeamsGetQuery as useListStationTeam,
} from "oneclick-component/src/store/apis/enhancedApi";
import { Button, ButtonLink } from "oneclick-component/src/components/Button";
import { localizeString } from "oneclick-component/src/utils/localize";
import AppRoutes from "../../routes/AppRoutes";
import {
  COUNTRY_CODE_NUM_DIGITS,
  countryCodeOptions,
} from "../../constants/countryCode";
import { PTUserFormWeekCardList } from "../../components/PTUserFormWeekCard";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import { FIXED_EID_LENGTH } from "../../constants/partTimeUserEid";
import useShowMessage from "oneclick-component/src/hooks/useShowMessage";
import { UpdatePTUserAPIValidationError } from "../../models/error";
import { useShowError } from "../../hooks/useShowError";
import { getDirtyValues } from "../../utils/form";
import { transformNumberOnly } from "oneclick-component/src/utils/string";
import {
  isoToDateTime,
  localizeDateTime,
} from "oneclick-component/src/utils/datetime";
import { DateTime } from "luxon";
import { OneClickCustomError } from "oneclick-component/src/models/error";

export const EditPartTimeScreen = (): ReactElement => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { showError } = useShowError();
  const showMessage = useShowMessage();

  if (id == null) {
    // TODO(peter)#293: use Generic AppError
    throw new Error("Invalid Route: Should provide ptUserId for edit params");
  }

  const {
    data: ptUserResponse,
    isLoading: isPtUserLoading,
    error: loadPtUserError,
  } = useGetPtUserByIdHandlerPartTimeUsersPtUserIdGetQuery({
    ptUserId: parseInt(id, 10),
  });
  const ptUser = useMemo(() => ptUserResponse?.ptUser, [ptUserResponse]);
  const {
    data: ptUserRoles,
    isLoading: isPtUserRolesLoading,
    error: loadPtUserRolesError,
  } = useGetPtUsersRolesHandlerPartTimeUsersRolesGetQuery();
  const {
    data: stationTeamsData,
    isLoading: isStationTeamsLoading,
    error: loadStationTeamsError,
  } = useListStationTeam();

  const [updatePtUser, { isLoading: isUpdatePtUserLoading }] =
    useUpdatePtUserHandlerPartTimeUsersPtUserIdPostMutation();
  const formId = useId();

  const isLoading =
    isPtUserLoading || isPtUserRolesLoading || isStationTeamsLoading;

  useEffect(() => {
    if (loadPtUserError != null) {
      showError(loadPtUserError);
    }
    if (loadPtUserRolesError != null) {
      showError(loadPtUserRolesError);
    }
    if (loadStationTeamsError != null) {
      showError(loadStationTeamsError);
    }
  }, [loadPtUserError, loadPtUserRolesError, loadStationTeamsError, showError]);

  const partTimeRoleOptions: Option<number>[] = useMemo(() => {
    if (ptUserRoles == null) {
      return [];
    }
    return ptUserRoles.ptUserRoles.map((role) => ({
      name: role.name,
      value: role.id,
    }));
  }, [ptUserRoles]);

  const defaultFormValues = useMemo<Partial<EditPartTimeForm>>(() => {
    if (ptUser == null) {
      return {
        regularShiftSchedule: [],
        countryCode: countryCodeOptions[0].value,
        role: 1,
      };
    }

    return {
      firstNameEn: ptUser.firstNameEn.trim(),
      lastNameEn: ptUser.lastNameEn.trim(),
      fullNameZhHk: ptUser.fullNameZhHk.trim(),
      eid: ptUser.eid,
      phoneNumber: ptUser.phoneNumber,
      countryCode: ptUser.countryCode,
      regularShiftSchedule:
        ptUser.regularShiftSchedule?.map((wa) => ({
          dayOfWeek: wa.dayOfWeek,
          startTime: wa.startTime,
          endTime: wa.endTime,
        })) ?? [],
      role: ptUser.role?.id ?? 1,
      station:
        ptUser.station?.name != null ? localizeString(ptUser.station.name) : "",
      scheduledLastDate:
        ptUser.scheduledSuspendAt == null
          ? null
          : localizeDateTime(
              isoToDateTime(ptUser.scheduledSuspendAt),
              DateTime.DATE_MED
            ),
      stationTeamId: ptUser.stationTeam?.id ?? null,
      receiveInviteOnHoliday: ptUser.receiveInviteOnHoliday,
    };
  }, [ptUser]);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, dirtyFields, isDirty },
    setError,
  } = useForm<EditPartTimeForm>({
    resolver: zodResolver(editPartTimeFormSchema),
    defaultValues: defaultFormValues,
  });

  const countryCode = useWatch({ control, name: "countryCode" });
  const scheduledLastDate = useWatch({ control, name: "scheduledLastDate" });
  const role = useWatch({ control, name: "role" });

  const isCCORole = useMemo(() => {
    const selectedRole = partTimeRoleOptions.find(
      (roleOption) => roleOption.value === role
    );

    if (selectedRole == null) {
      return false;
    }
    return selectedRole.name === "CCO";
  }, [partTimeRoleOptions, role]);

  useEffect(() => {
    reset(defaultFormValues);
  }, [reset, defaultFormValues]);

  const stationTeamOptions: Option<number | null>[] = useMemo(() => {
    if (stationTeamsData == null || ptUser?.station?.id == null) {
      return [];
    }
    const nullOption = {
      name: t("partTime.edit.form.stationTeam.options.noTeam.text"),
      value: null,
    };
    const teamOptions = stationTeamsData.stationTeams
      .filter((st) => st.stationId === ptUser.station?.id)
      .map((st) => ({
        name: t("partTime.edit.form.stationTeam.options.text", {
          code: st.teamCode,
        }),
        value: st.id,
      }));
    if (teamOptions.length === 0) {
      return [];
    }
    return [nullOption, ...teamOptions];
  }, [stationTeamsData, ptUser, t]);

  const _onSubmit = useCallback(
    (data: EditPartTimeForm) => {
      const dirtyValues = getDirtyValues(dirtyFields, data);

      if (ptUser == null) {
        // TODO(peter)#293: use Generic AppError
        throw new Error("Unexpected null ptUser");
      }

      const oldDisplayName = ptUser.fullNameZhHk;
      const newDisplayName = data.fullNameZhHk;

      // Change to cco from other pt role is not allowed.
      // Other pt role can apply shift without end time but CCO user cannot which is required for CCO user.
      if (isCCORole && ptUser.role?.name !== "CCO") {
        showError(
          new OneClickCustomError(
            t("partTime.edit.toast.fail.ccoRole.message")
          ),
          t("partTime.edit.toast.fail.title", { oldDisplayName })
        );
        return;
      }

      updatePtUser({
        ptUserId: ptUser.id,
        updatePtUserRequest: {
          ...dirtyValues,
          roleId: dirtyValues.role,
        },
      })
        .unwrap()
        .then(() => {
          showMessage({
            title: t("partTime.edit.toast.success.title"),
            message: t("partTime.edit.toast.success.message", {
              oldDisplayName,
              newDisplayName,
            }),
            type: "success",
            showDismiss: true,
          });
        })
        .catch((err) => {
          const errorDescription: string = err?.data?.detail?.description ?? "";
          if (
            errorDescription.includes(
              UpdatePTUserAPIValidationError.DuplicateEid
            )
          ) {
            setError("eid", {
              message: t("partTime.edit.form.error.duplicateEid"),
            });
          } else if (
            errorDescription.includes(
              UpdatePTUserAPIValidationError.DuplicatePhoneNumber
            )
          ) {
            setError("phoneNumber", {
              message: t("partTime.edit.form.error.duplicatePhoneNumber"),
            });
          } else if (
            errorDescription.includes(
              UpdatePTUserAPIValidationError.IncorrectPhoneNumberFormat
            )
          ) {
            setError("phoneNumber", {
              message: t("partTime.edit.form.error.incorrectPhoneNumberFormat"),
            });
          } else if (
            errorDescription.includes(
              UpdatePTUserAPIValidationError.NonWhitelistedPhoneNumber
            )
          ) {
            showError(
              new OneClickCustomError(
                t("partTime.edit.toast.fail.nonWhitelistedPhoneNumber.message")
              ),
              t("partTime.edit.toast.fail.title", { oldDisplayName })
            );
          } else {
            showError(
              err,
              t("partTime.edit.toast.fail.title", { oldDisplayName })
            );
          }
        });
    },
    [
      dirtyFields,
      ptUser,
      setError,
      showError,
      showMessage,
      t,
      updatePtUser,
      isCCORole,
    ]
  );
  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      handleSubmit(_onSubmit)(e).catch((err: unknown) => {
        throw err;
      });
      e.stopPropagation();
    },
    [handleSubmit, _onSubmit]
  );

  const maxPhoneNumberLength = useMemo(
    () => COUNTRY_CODE_NUM_DIGITS[countryCode],
    [countryCode]
  );

  if (isLoading) {
    return (
      <div
        className={cn(
          "fixed",
          "top-1/2",
          "left-1/2",
          "-translate-x-1/2",
          "-translate-y-1/2"
        )}
      >
        <LoadingSpinner size="l" />
      </div>
    );
  }

  return (
    <main>
      <div
        className={cn(
          "overflow-hidden",
          "rounded-lg",
          "bg-white",
          "shadow",
          "lg:w-2/3",
          "mx-auto",
          "p-6",
          "pb-20"
        )}
      >
        <form
          id={formId}
          onSubmit={onSubmit}
          className={cn(
            "grid",
            "grid-cols-1",
            "gap-y-5",
            "sm:grid-cols-2",
            "sm:gap-5"
          )}
        >
          <FormTextInput
            name="firstNameEn"
            control={control}
            label={t("partTime.edit.form.firstNameEn")}
            required={true}
            errorMessage={errors.firstNameEn?.message}
          />
          <FormTextInput
            name="lastNameEn"
            control={control}
            label={t("partTime.edit.form.lastNameEn")}
            required={true}
            errorMessage={errors.lastNameEn?.message}
          />
          <FormTextInput
            name="eid"
            pattern="[0-9]*"
            transform={transformNumberOnly}
            control={control}
            label={t("partTime.edit.form.eid")}
            required={true}
            errorMessage={errors.eid?.message}
            maxLength={FIXED_EID_LENGTH}
          />
          <FormTextInput
            name="fullNameZhHk"
            control={control}
            label={t("partTime.edit.form.fullNameZhHk")}
            required={true}
            errorMessage={errors.fullNameZhHk?.message}
          />
          <FormTextInput
            name="phoneNumber"
            pattern="[0-9]*"
            transform={transformNumberOnly}
            control={control}
            label={t("partTime.edit.form.phoneNumber")}
            required={true}
            errorMessage={errors.phoneNumber?.message}
            maxLength={maxPhoneNumberLength}
            prefixDecorator={
              <FormField.Control name="countryCode" control={control}>
                {({ field }) => (
                  <SelectDecorator
                    options={countryCodeOptions}
                    className={cn("-ml-3", "mr-1", "w-28")}
                    {...field}
                  />
                )}
              </FormField.Control>
            }
          />
          <FormSelect
            options={partTimeRoleOptions}
            name="role"
            control={control}
            label={t("partTime.edit.form.type")}
            required={true}
            errorMessage={errors.role?.message}
          />
          <FormTextInput
            name="station"
            control={control}
            label={t("partTime.edit.form.stations")}
            required={true}
            errorMessage={errors.station?.message}
            disabled={true}
          />
          {stationTeamOptions.length > 0 ? (
            <FormSelect
              options={stationTeamOptions}
              name="stationTeamId"
              control={control}
              label={t("partTime.edit.form.stationTeam")}
              required={true}
              errorMessage={errors.stationTeamId?.message}
            />
          ) : null}
          {scheduledLastDate != null ? (
            <FormTextInput
              name="scheduledLastDate"
              control={control}
              label={t("partTime.edit.form.scheduledLastDate")}
              disabled={true}
            />
          ) : null}
          {isCCORole ? null : (
            <>
              <div
                className={cn(
                  "col-span-1",
                  "sm:col-span-2",
                  "text-sm",
                  "flex",
                  "flex-row",
                  "items-center"
                )}
              >
                <div className="grow">
                  <Trans i18nKey="partTime.create.form.regularShiftSchedule" />
                </div>
                <FormField.Control
                  name="receiveInviteOnHoliday"
                  control={control}
                >
                  {({ field }) => (
                    <Checkbox
                      name="receiveInviteOnHoliday"
                      label={t("partTime.create.form.receiveInviteOnHoliday")}
                      checked={field.value}
                      onChange={field.onChange}
                    />
                  )}
                </FormField.Control>
              </div>
              <FormField.Control name="regularShiftSchedule" control={control}>
                {({ field }) => (
                  <FormField.Container
                    errorMessage={errors.regularShiftSchedule?.message}
                    required={false}
                    className={cn("col-span-1", "sm:col-span-2")}
                  >
                    <PTUserFormWeekCardList
                      field={field}
                      className={cn("space-y-5", "w-full")}
                    />
                  </FormField.Container>
                )}
              </FormField.Control>
            </>
          )}
        </form>
      </div>
      <footer
        className={cn(
          "fixed",
          "bottom-0",
          "left-0",
          "bg-white",
          "w-full",
          "h-16",
          "flex",
          "sm:justify-end",
          "border-t",
          "border-black/12",
          "px-6",
          "py-3",
          "space-x-3"
        )}
      >
        <ButtonLink
          theme={"white"}
          className={cn("flex-1", "sm:flex-none", "text-sm")}
          to={AppRoutes.PartTimeListScreen.render()}
        >
          <Trans i18nKey="partTime.edit.footer.button.cancel" />
        </ButtonLink>
        <Button
          type="submit"
          form={formId}
          className={cn("flex-1", "sm:flex-none")}
          isLoading={isUpdatePtUserLoading}
          disabled={!isDirty}
        >
          <Trans i18nKey="partTime.edit.footer.button.save" />
        </Button>
      </footer>
    </main>
  );
};
