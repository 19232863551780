import React, { useCallback, useMemo, useState } from "react";
import cn from "classnames";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Trans, useTranslation } from "react-i18next";
import { PencilIcon } from "@heroicons/react/24/outline";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import {
  useListShiftFullTimeAttendanceByShiftHandlerShiftFullTimeAttendancesGetQuery as useListShiftFullTimeAttendance,
  useAddShiftFullTimeAttendanceHandlerShiftFullTimeAttendancesPostMutation as useAddShiftFullTimeAttendance,
  useEditShiftHandlerShiftsShiftIdPostMutation as useEditShift,
  ShiftFullTimeAttendance,
} from "oneclick-component/src/store/apis/enhancedApi";
import { Modal } from "oneclick-component/src/components/Modal";
import { TextInput } from "oneclick-component/src/components/inputs";
import { Button } from "oneclick-component/src/components/Button";
import {
  FormTextInput,
  FormField,
} from "oneclick-component/src/components/forms";
import useShowMessage from "oneclick-component/src/hooks/useShowMessage";
import { LazyLoadStationSelectionDropdown } from "../StationSelectionDropdown";
import { useShowError } from "../../hooks/useShowError";
import ProgressBar from "oneclick-component/src/components/ProgressBar";
import { Form, formSchema } from "./form";
import ShiftFullTimeAttendanceTable from "./Table";
import {
  useShiftFullTimeAttendanceTable,
  ShiftFullTimeAttendanceRecord,
} from "./useShiftFullTimeAttendanceTable";

const transformToShiftFullTimeAttendanceRecord = (
  attendance: ShiftFullTimeAttendance
): ShiftFullTimeAttendanceRecord => {
  return {
    id: attendance.id,
    nameEn: attendance.name.en ?? "",
    nameZhHk: attendance.name.zhHk ?? "",
    role: attendance.role,
    stationCode: attendance.station.shortCode,
  };
};

interface EditDialogProps {
  onClose: () => void;
  isOpen: boolean;
  shiftId: number;
  fulltimeCount: number;
}

const FullTimeCountDialog = (
  props: EditDialogProps
): React.ReactElement | null => {
  const { isOpen, onClose, shiftId, fulltimeCount } = props;
  const { t } = useTranslation();
  const showMessage = useShowMessage();
  const { showError } = useShowError();
  const [updatedCount, setUpdatedCount] = useState(fulltimeCount);

  const [editShift, { isLoading }] = useEditShift();

  const onUpdateClick = useCallback(() => {
    if (updatedCount > fulltimeCount) {
      editShift({
        shiftId: shiftId,
        editShiftRequest: {
          fulltimeCount: updatedCount,
          fulfillmentCount: null,
          shiftTitle: null,
          shiftDescription: null,
        },
      })
        .unwrap()
        .then(() => {
          showMessage({
            title: t("fulltimeAttendance.edit.fullTimeCount.success"),
            type: "success",
            showDismiss: true,
          });
          onClose();
        })
        .catch((e) => {
          showError(e, t("fulltimeAttendance.edit.fullTimeCount.fail"));
        });
    }
  }, [
    shiftId,
    editShift,
    updatedCount,
    fulltimeCount,
    onClose,
    showError,
    t,
    showMessage,
  ]);

  const onChangeCount = useCallback(
    (v: string) => {
      const newValue = parseInt(v, 10);

      if (newValue >= fulltimeCount) {
        setUpdatedCount(newValue);
      }
    },
    [fulltimeCount]
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      hasXMarkButton={true}
      className="!w-70"
    >
      <div className={cn("mb-4", "flex", "flex-row", "items-center")}>
        <PencilIcon className={cn("w-4", "h-4", "text-black/86", "mr-2")} />
        <p className={cn("text-black/86", "text-lg", "font-medium")}>
          <Trans i18nKey="fulltimeAttendance.edit.fullTimeCount.title" />
        </p>
      </div>
      <p className={cn("text-black/60", "text-sm")}>
        <Trans i18nKey="fulltimeAttendance.edit.fullTimeCount.description" />
      </p>
      <TextInput type="number" value={updatedCount} onChange={onChangeCount} />
      <div className={cn("flex", "flex-col", "mt-5")}>
        <Button
          className={cn(
            "mt-4",
            "bg-red-50",
            "border-red-600",
            "border",
            "!text-red-600",
            "hover:bg-red-100",
            "disabled:bg-red-300",
            "self-end"
          )}
          disabled={isLoading}
          onClick={onUpdateClick}
          iconClassName={cn("!fill-red-600")}
          suffixIcon={ArrowRightIcon}
          type="submit"
        >
          <Trans i18nKey="fulltimeAttendance.edit.fullTimeCount.action" />
        </Button>
      </div>
    </Modal>
  );
};

interface Props {
  onClose: () => void;
  isOpen: boolean;
  shiftId: number;
  fulltimeCount: number;
}

const ShiftFullTimeAttendanceDialog = (
  props: Props
): React.ReactElement | null => {
  const { isOpen, onClose, shiftId, fulltimeCount } = props;
  const { t } = useTranslation();
  const showMessage = useShowMessage();
  const { showError } = useShowError();
  const [isEditCountDialogOpen, setIsEditCountDialogOpen] = useState(false);

  const [addFullTime, { isLoading: isAddLoading }] =
    useAddShiftFullTimeAttendance();
  const { data, isLoading } = useListShiftFullTimeAttendance(
    {
      shiftId: shiftId,
    },
    {
      skip: !isOpen,
    }
  );

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<Form>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      nameEn: "",
      nameZhHk: "",
      role: "",
    },
  });

  const _onSubmit = useCallback(
    (data: Form) => {
      addFullTime({
        shiftId: shiftId,
        createShiftFullTimeAttendanceRequest: {
          nameEn: data.nameEn,
          nameZhHk: data.nameZhHk,
          role: data.role,
          stationId: data.stationId,
        },
      })
        .unwrap()
        .then(() => {
          showMessage({
            title: t("fulltimeAttendance.add.success"),
            type: "success",
            showDismiss: true,
          });
          reset();
        })
        .catch((e) => {
          showError(e, t("fulltimeAttendance.add.fail"));
        });
    },
    [addFullTime, shiftId, reset, showMessage, showError, t]
  );

  const onOpenEditDialog = useCallback(() => {
    setIsEditCountDialogOpen(true);
  }, []);
  const onCloseEditDialog = useCallback(() => {
    setIsEditCountDialogOpen(false);
  }, []);

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      handleSubmit(_onSubmit)(e).catch((err: unknown) => {
        throw err;
      });
      e.stopPropagation();
    },
    [handleSubmit, _onSubmit]
  );

  const tableData = useMemo(() => {
    return (data?.items ?? []).map(transformToShiftFullTimeAttendanceRecord);
  }, [data]);
  const table = useShiftFullTimeAttendanceTable(tableData);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      hasXMarkButton={false}
      className="!min-w-260"
    >
      <div className={cn("flex", "flex-row", "w-full", "mb-5")}>
        <p className={cn("text-black/86", "grow")}>
          <Trans i18nKey="fulltimeAttendance.title" />
        </p>
        <ProgressBar
          max={fulltimeCount}
          count={(data?.items ?? []).length}
          labelStyle="right"
        />
      </div>
      <div>
        <p className={cn("text-black/86", "text-sm", "mb-1")}>
          <Trans i18nKey="fulltimeAttendance.targetCount" />*
        </p>
        <div className={cn("flex", "flex-row", "items-center")}>
          <TextInput
            className={cn("w-55", "mr-2")}
            disabled={true}
            value={fulltimeCount}
          />
          <button type="button" onClick={onOpenEditDialog}>
            <PencilIcon className={cn("w-4", "h-4", "text-gray-400")} />
          </button>
        </div>
      </div>
      <hr className="my-5" />
      <div className={cn("flex", "flex-row")}>
        <div className={cn("pr-6", "mr-6", "border-r", "border-gray-200")}>
          <p className={cn("text-sm", "text-black/86", "mb-5")}>
            <Trans i18nKey="fulltimeAttendance.add.title" />
          </p>
          <form
            className={cn("gap-y-2", "flex", "flex-col")}
            onSubmit={onSubmit}
          >
            <FormTextInput
              className="w-55"
              name="nameZhHk"
              control={control}
              label={t("fulltimeAttendance.column.nameZhHk")}
              placeholder={t("fulltimeAttendance.add.field.input.placeholder")}
              required={true}
              hasError={errors.nameZhHk != null}
              errorMessage={
                errors.nameZhHk?.message != null
                  ? t(errors.nameZhHk.message)
                  : undefined
              }
            />
            <FormTextInput
              className="w-55"
              name="nameEn"
              control={control}
              label={t("fulltimeAttendance.column.nameEn")}
              placeholder={t("fulltimeAttendance.add.field.input.placeholder")}
              required={true}
              hasError={errors.nameEn != null}
              errorMessage={
                errors.nameEn?.message != null
                  ? t(errors.nameEn.message)
                  : undefined
              }
            />
            <FormTextInput
              className="w-55"
              name="role"
              control={control}
              label={t("fulltimeAttendance.column.role")}
              placeholder={t("fulltimeAttendance.add.field.role.placeholder")}
              required={true}
              hasError={errors.role != null}
              errorMessage={errors.role?.message}
            />
            <FormField.Control name="stationId" control={control}>
              {({ field }) => (
                <FormField.Container
                  label={t("fulltimeAttendance.column.station")}
                  errorMessage={errors.stationId?.message}
                  required={true}
                >
                  <LazyLoadStationSelectionDropdown
                    selectedStationId={field.value}
                    onChange={field.onChange}
                  />
                </FormField.Container>
              )}
            </FormField.Control>
            <Button
              className={cn(
                "mt-4",
                "bg-red-50",
                "border-red-600",
                "border",
                "!text-red-600",
                "hover:bg-red-100",
                "disabled:bg-red-300",
                "self-end"
              )}
              disabled={isAddLoading}
              iconClassName={cn("!fill-red-600")}
              suffixIcon={ArrowRightIcon}
              type="submit"
            >
              <Trans i18nKey="fulltimeAttendance.add.action" />
            </Button>
          </form>
        </div>
        <div className="flex-1">
          <p className={cn("text-sm", "text-black/86", "mb-5")}>
            <Trans i18nKey="fulltimeAttendance.list.title" />
          </p>
          <ShiftFullTimeAttendanceTable
            isLoading={isLoading}
            isEmpty={tableData.length === 0}
            table={table}
          />
        </div>
      </div>
      <FullTimeCountDialog
        isOpen={isEditCountDialogOpen}
        onClose={onCloseEditDialog}
        shiftId={shiftId}
        fulltimeCount={fulltimeCount}
      />
    </Modal>
  );
};

export default ShiftFullTimeAttendanceDialog;
