import React, { ForwardedRef, ReactElement } from "react";
import cn from "classnames";

export interface TextAreaProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  className?: string;
  hasError?: boolean;
}

export const TextArea = React.forwardRef(function TextArea(
  props: TextAreaProps,
  ref: ForwardedRef<HTMLTextAreaElement>
): ReactElement {
  const { className, hasError = false, ...rest } = props;

  return (
    <textarea
      ref={ref}
      className={cn(
        "rounded-md",
        "px-3",
        "ring-1",
        "focus:ring-2",
        "border-0",
        "outline-0",
        "placeholder:text-black/25",
        hasError ? "focus:ring-red-500" : "focus:ring-primary-600",
        hasError ? "ring-red-300" : "ring-gray-300",
        className
      )}
      {...rest}
    />
  );
});
