import { ReactElement } from "react";
import { Trans, useTranslation } from "react-i18next";
import { PlusIcon } from "@heroicons/react/24/outline";
import cn from "classnames";
import {
  PtUser,
  WeekOfYear,
  Weekly418Status,
  WorkingStatus,
} from "oneclick-component/src/store/apis/enhancedApi";
import { ButtonLink } from "oneclick-component/src/components/Button";
import LoadingScreen from "oneclick-component/src/components/LoadingScreen";
import { ListEmptyView } from "../../../components/EmptyView";
import AppRoutes from "../../../routes/AppRoutes";
import { useShouldShowPtStationTeam } from "../../../hooks/useShouldShowPtStationTeam";
import PartTimeUser418Card from "./PartTimeUser418Card";

interface Props {
  ptUsers: PtUser[];
  ptUserRowWeekOfYearsMap: Record<number, WeekOfYear | undefined>;
  firstWeekOfSelectedMonth: WeekOfYear;
  onChangeRowWeek: (ptUserId: number, woy: WeekOfYear) => void;
  onChangeRowWorkingStatus: (
    ptUserId: number,
    woy: WeekOfYear,
    workingStatus: WorkingStatus | null,
    existingWorkingStatus: WorkingStatus | null,
    existing418Status: Weekly418Status
  ) => void;
  isLoading: boolean;
}
const PartTimeListView418 = (props: Props): ReactElement => {
  const {
    ptUsers,
    ptUserRowWeekOfYearsMap,
    firstWeekOfSelectedMonth,
    onChangeRowWeek,
    onChangeRowWorkingStatus,
    isLoading,
  } = props;
  const { t } = useTranslation();
  const shouldShowPtStationTeam = useShouldShowPtStationTeam();

  if (isLoading) {
    return <LoadingScreen />;
  }
  if (ptUsers.length === 0) {
    return (
      <ListEmptyView
        description={t("partTime.list.tab.workStatus418.emptyView.description")}
        actionButton={
          <ButtonLink
            prefixIcon={PlusIcon}
            to={AppRoutes.CreatePartTimeScreen.render()}
            className="text-sm"
          >
            <Trans i18nKey="partTime.list.button.addPartTimeUser" />
          </ButtonLink>
        }
      />
    );
  }

  return (
    <div className={cn("space-y-3", "sm:space-y-5")}>
      <div className={cn("space-y-3", "sm:space-y-5")}>
        {ptUsers.map((ptUser) => (
          <PartTimeUser418Card
            key={ptUser.id}
            ptUser={ptUser}
            startingWeekOfYear={
              ptUserRowWeekOfYearsMap[ptUser.id] ?? firstWeekOfSelectedMonth
            }
            onChangeStartingWeekOfYear={onChangeRowWeek}
            onChangeWorkingStatus={onChangeRowWorkingStatus}
            showStationTeam={shouldShowPtStationTeam}
          />
        ))}
      </div>
    </div>
  );
};

export default PartTimeListView418;
