import React, { useCallback, useMemo } from "react";
import cn from "classnames";
import { DateTime } from "luxon";
import {
  FormSelect,
  FormTextInput,
  FormField,
  FormTextArea,
} from "oneclick-component/src/components/forms";
import {
  Calendar,
  Select,
  Option,
} from "oneclick-component/src/components/inputs";
import {
  localizeDateTime,
  isoToDateTime,
  dateTimeNow,
} from "oneclick-component/src/utils/datetime";
import { WorkingStation } from "oneclick-component/src/store/apis/enhancedApi";
import { UseFormReturn } from "react-hook-form";
import { ShiftFormValues } from "./form";
import { useTranslation } from "react-i18next";
import {
  LazyLoadWorkingStationSelectionDropdown,
  EagerLoadWorkingStationSelectionDropdown,
} from "../WorkingStationSelectionDropdown";
import { useIsSuperAdmin } from "../../hooks/role";
import { useSelectedStationProfile } from "../../hooks/useSelectedStationProfile";
import { DEFAULT_SHIFT_MINUTE } from "../../constants/shifts/create";
import useMeUser from "../../hooks/useMeUser";

interface Props
  extends React.DetailedHTMLProps<
    React.FormHTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  > {
  form: UseFormReturn<ShiftFormValues>;
  initialSelectedWorkingStation?: WorkingStation | null;
  initialSelectedSupportStation?: WorkingStation | null;
  onWorkingStationSelected: (workingStation: WorkingStation | null) => void;
  partTimeTypeOptions: Option<number>[];
  shiftRateOptions: Option<number>[];
  onSubmit: () => void;
  isEditing: boolean;
  isIncident: boolean;
}

const ShiftForm = (props: Props): React.ReactElement => {
  const {
    onSubmit,
    form,
    initialSelectedWorkingStation,
    initialSelectedSupportStation,
    onWorkingStationSelected,
    partTimeTypeOptions,
    shiftRateOptions,
    isEditing,
    isIncident,
    ...rest
  } = props;
  const { t } = useTranslation();
  const meUser = useMeUser();
  const selectedStationProfile = useSelectedStationProfile();
  const isSuperAdmin = useIsSuperAdmin();

  const {
    control,
    formState: { errors },
  } = form;

  const initialWorkingStationOptions = useMemo(() => {
    return initialSelectedWorkingStation != null
      ? [initialSelectedWorkingStation]
      : null;
  }, [initialSelectedWorkingStation]);

  const workingStationOptions = useMemo(() => {
    if (isSuperAdmin || meUser == null) {
      return [];
    }
    if (selectedStationProfile != null) {
      return selectedStationProfile.station.workingStations;
    }
    return meUser.stations.flatMap((s) => s.workingStations);
  }, [meUser, selectedStationProfile, isSuperAdmin]);

  const startTimeHourOptions: Option<number>[] = useMemo(() => {
    return new Array(24).fill(0).map((_, index) => ({
      name: index.toString().padStart(2, "0"),
      value: index,
    }));
  }, []);
  const startTimeMinsOptions: Option<number>[] = useMemo(() => {
    return new Array(60 / 15).fill(0).map((_, index) => ({
      name: (15 * index).toString().padStart(2, "0"),
      value: 15 * index,
    }));
  }, []);
  const endTimeHourOptions = useMemo(() => {
    return [
      { name: t("shift.create.form.endTime.hour.placeholder"), value: null },
      ...new Array(48).fill(0).map((_, index) => {
        const hour = index % 24;
        const day = Math.floor(index / 24);
        const hourString = hour.toString().padStart(2, "0");
        const dayString =
          day === 0
            ? ""
            : `(${t("shift.create.form.endTime.hour.option.overnight")})`;
        return {
          name: `${hourString} ${dayString}`,
          value: index,
        };
      }),
    ];
  }, [t]);
  const endTimeMinsOptions = useMemo(() => {
    return [
      { name: t("shift.create.form.endTime.minute.placeholder"), value: null },
      ...startTimeMinsOptions,
    ];
  }, [startTimeMinsOptions, t]);

  const makeOnChangeStartTimeHour = useCallback(
    (fieldOnChange: (value: number) => void) => (value: number) => {
      form.setValue("startTime.minute", DEFAULT_SHIFT_MINUTE);
      fieldOnChange(value);
    },
    [form]
  );

  const makeOnChangeEndTimeHour = useCallback(
    (fieldOnChange: (value: number | null) => void) =>
      (value: number | null) => {
        form.setValue(
          "endTime.minute",
          value == null ? null : DEFAULT_SHIFT_MINUTE
        );
        fieldOnChange(value);
      },
    [form]
  );

  const now = useMemo(() => dateTimeNow(), []);

  return (
    <form
      className={cn("flex", "flex-col", "gap-y-5")}
      onSubmit={onSubmit}
      {...rest}
    >
      <div className={cn("flex", "flex-col", "md:flex-row", "gap-5")}>
        <FormField.Control name="stationId" control={control}>
          {({ field }) => (
            <FormField.Container
              label={t("shift.create.form.station")}
              errorMessage={errors.stationId?.message}
              required={true}
              className="flex-1"
            >
              {isSuperAdmin ? (
                <LazyLoadWorkingStationSelectionDropdown
                  initialWorkingStationOptions={initialWorkingStationOptions}
                  selectedWorkingStationId={field.value}
                  onWorkingStationSelected={onWorkingStationSelected}
                  onChange={field.onChange}
                  disabled={isEditing}
                />
              ) : (
                <EagerLoadWorkingStationSelectionDropdown
                  workingStations={workingStationOptions}
                  selectedWorkingStationId={field.value}
                  onWorkingStationSelected={onWorkingStationSelected}
                  onChange={field.onChange}
                  disabled={workingStationOptions.length === 1 || isEditing}
                />
              )}
            </FormField.Container>
          )}
        </FormField.Control>
        <FormSelect
          className="flex-1"
          options={shiftRateOptions}
          name={"rate"}
          control={control}
          label={t("shift.create.form.shiftRate")}
          required={true}
          disabled={isEditing}
          errorMessage={errors.rate?.message}
          placeholder={t("shift.create.form.shiftRate.placeholder")}
        />
      </div>
      {/* Render below field for edit view, create shift screen already deprecated */}
      {isIncident ? (
        <FormField.Control name="supportStationId" control={control}>
          {({ field }) => (
            <FormField.Container
              label={t("shift.create.form.supportStation")}
              errorMessage={errors.supportStationId?.message}
            >
              <EagerLoadWorkingStationSelectionDropdown
                workingStations={
                  initialSelectedSupportStation != null
                    ? [initialSelectedSupportStation]
                    : []
                }
                selectedWorkingStationId={field.value}
                onChange={field.onChange}
                disabled={true}
              />
            </FormField.Container>
          )}
        </FormField.Control>
      ) : null}
      <div className={cn("flex", "flex-col", "md:flex-row", "gap-5")}>
        <FormSelect
          className="flex-1"
          options={partTimeTypeOptions}
          name={"ptType"}
          control={control}
          label={t("shift.create.form.partTimeType")}
          required={true}
          disabled={isEditing}
          errorMessage={errors.ptType?.message}
          placeholder={t("shift.create.form.partTimeType.placeholder")}
        />
        <FormTextInput
          className="flex-1"
          name={"fulfillmentCount"}
          control={control}
          label={t("shift.create.form.fulfillmentCount")}
          required={true}
          type="number"
          min={1}
          errorMessage={errors.fulfillmentCount?.message}
        />
      </div>
      <FormField.Container
        className={"flex-1"}
        label={t("shift.create.form.date")}
        required={true}
        errorMessage={errors.date?.message}
      >
        <FormField.Control name={"date"} control={control}>
          {({ field }) => (
            // FIXME: date display updated to have localization
            <Calendar
              {...field}
              disabled={isEditing}
              min={now.toFormat("yyyy-LL-dd")}
              hasError={errors.date?.message != null}
              formattedValue={
                field.value === ""
                  ? ""
                  : localizeDateTime(
                      isoToDateTime(field.value),
                      DateTime.DATE_MED
                    )
              }
            />
          )}
        </FormField.Control>
      </FormField.Container>
      <div className={cn("flex", "flex-col", "md:flex-row", "w-full", "gap-5")}>
        <FormField.Container
          className={"flex-1"}
          label={t("shift.create.form.startTime")}
          required={true}
          errorMessage={
            errors.startTime?.hour?.message ?? errors.startTime?.minute?.message
          }
        >
          <div className={cn("flex", "items-center", "gap-x-2")}>
            <FormField.Control name={"startTime.hour"} control={control}>
              {({ field }) => (
                <div className={cn("flex", "items-center", "flex-1")}>
                  <Select
                    className={"flex-1"}
                    options={startTimeHourOptions}
                    placeholder={t(
                      "shift.create.form.startTime.hour.placeholder"
                    )}
                    disabled={isEditing}
                    hasError={errors.startTime?.hour?.message != null}
                    {...field}
                    onChange={makeOnChangeStartTimeHour(field.onChange)}
                  />
                </div>
              )}
            </FormField.Control>
            <span>:</span>
            <FormField.Control name={"startTime.minute"} control={control}>
              {({ field }) => (
                <div className={cn("flex", "items-center", "flex-1")}>
                  <Select
                    className={"flex-1"}
                    options={startTimeMinsOptions}
                    placeholder={t(
                      "shift.create.form.startTime.minute.placeholder"
                    )}
                    disabled={isEditing}
                    hasError={errors.startTime?.minute?.message != null}
                    {...field}
                  />
                </div>
              )}
            </FormField.Control>
          </div>
        </FormField.Container>
        <FormField.Container
          className={"flex-1"}
          label={t("shift.create.form.endTime")}
          errorMessage={
            errors.endTime?.hour?.message ?? errors.endTime?.minute?.message
          }
        >
          <div className={cn("flex", "items-center", "gap-x-2")}>
            <FormField.Control name={"endTime.hour"} control={control}>
              {({ field }) => (
                <div className={cn("flex", "items-center", "flex-1")}>
                  <Select
                    className={"flex-1"}
                    options={endTimeHourOptions}
                    disabled={isEditing}
                    placeholder={t(
                      "shift.create.form.endTime.hour.placeholder"
                    )}
                    hasError={errors.endTime?.hour?.message != null}
                    {...field}
                    onChange={makeOnChangeEndTimeHour(field.onChange)}
                  />
                </div>
              )}
            </FormField.Control>
            <span>:</span>
            <FormField.Control name={"endTime.minute"} control={control}>
              {({ field }) => (
                <div className={cn("flex", "items-center", "flex-1")}>
                  <Select
                    className={"flex-1"}
                    options={endTimeMinsOptions}
                    placeholder={t(
                      "shift.create.form.endTime.minute.placeholder"
                    )}
                    disabled={isEditing}
                    hasError={errors.endTime?.hour?.message != null}
                    {...field}
                  />
                </div>
              )}
            </FormField.Control>
          </div>
        </FormField.Container>
      </div>
      <FormTextInput
        name={"title"}
        control={control}
        label={t("shift.create.form.title")}
        required={true}
        errorMessage={errors.title?.message}
        placeholder={t("shift.create.form.title.placeholder")}
      />
      <FormTextArea
        className={"w-full"}
        name={"description"}
        control={control}
        rows={5}
        label={t("shift.create.form.description")}
        required={true}
        errorMessage={errors.description?.message}
        placeholder={`${t("shift.create.form.description.placeholder")}`}
      />
    </form>
  );
};

export default ShiftForm;
