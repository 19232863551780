import { ReactElement, useEffect } from "react";
import cn from "classnames";
import { useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import GenericErrorScreen from "oneclick-component/src/components/GenericErrorScreen";
import { ButtonLink } from "oneclick-component/src/components/Button";
import { useGetMasterShiftHandlerMasterShiftsMasterShiftIdGetQuery as useGetMasterShift } from "oneclick-component/src/store/apis/enhancedApi";

import StepBar from "../../components/StepBar";
import AppRoutes from "../../routes/AppRoutes";
import { useShowError } from "../../hooks/useShowError";
import { MultiShiftInfo } from "./MultiShiftInfo";
import InvitationSection from "./InvitationSection";

const MultiShiftInvitationScreen = (): ReactElement => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { showError } = useShowError();

  const {
    data: masterShiftData,
    error: getMasterShiftError,
    isLoading: isLoadingMasterShift,
  } = useGetMasterShift({
    masterShiftId: parseInt(id!, 10),
  });

  useEffect(() => {
    if (getMasterShiftError != null) {
      showError(getMasterShiftError);
    }
  }, [getMasterShiftError, showError]);

  if (getMasterShiftError != null) {
    return (
      <div
        className={cn(
          "bg-white",
          "rounded-lg",
          "shadow-md",
          "p-20",
          "h-[calc(100vh-130px)]"
        )}
      >
        <GenericErrorScreen />
      </div>
    );
  }

  return (
    <main className={"pb-20"}>
      <StepBar
        className="max-w-160"
        displays={[
          t("shift.create.multiple.step1"),
          t("shift.create.multiple.step2"),
          t("shift.create.multiple.step3"),
        ]}
        step={2}
      />
      <MultiShiftInfo
        isLoading={isLoadingMasterShift}
        masterShift={masterShiftData?.masterShift}
      />
      <div className="mb-5" />
      {masterShiftData?.masterShift != null ? (
        <InvitationSection
          masterShift={masterShiftData.masterShift}
          defaultExpand={true}
        />
      ) : null}
      <footer
        className={cn(
          "fixed",
          "bottom-0",
          "left-0",
          "bg-white",
          "w-full",
          "h-16",
          "flex",
          "justify-end",
          "px-6",
          "py-3"
        )}
      >
        <ButtonLink
          theme={"white"}
          className={"mr-3 text-sm"}
          to={AppRoutes.ShiftListScreen.render()}
        >
          <Trans i18nKey="shift.create.footer.button.complete" />
        </ButtonLink>
      </footer>
    </main>
  );
};

export default MultiShiftInvitationScreen;
