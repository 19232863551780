import { UserStationProfile } from "oneclick-component/src/store/apis/enhancedApi";

export function constructProfileScopedWorkingStationFilter(
  selectedStationProfile: UserStationProfile | null,
  workingStationId?: number | null
): number[] | undefined {
  const workingStationIdSet = new Set<number>();
  if (selectedStationProfile != null) {
    const workingStations = selectedStationProfile.station.workingStations;
    for (const workingStation of workingStations) {
      workingStationIdSet.add(workingStation.id);
    }
    if (workingStationId == null) {
      return Array.from(workingStationIdSet);
    }
    return [workingStationId].filter((wsid) => workingStationIdSet.has(wsid));
  }

  return workingStationId ? [workingStationId] : undefined;
}
