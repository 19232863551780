import { ReactElement, useMemo, useState, useCallback, useEffect } from "react";
import cn from "classnames";
import { useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import {
  PtUserWithVisitCount,
  useCancelHireHandlerShiftsShiftIdCancelHirePtUserPtUserIdPostMutation as useCancelHire,
  useMakeExpireShiftHandlerShiftsShiftIdMakeExpirePostMutation as useMakeExpire,
  Weekly418Status,
  ShiftRequestBaseWithNumbering,
  ShiftFullTimeAttendance,
} from "oneclick-component/src/store/apis/enhancedApi";
import { WelcomeIcon } from "oneclick-component/src/icon";
import { Button, ButtonLink } from "oneclick-component/src/components/Button";
import { ActionDialog } from "oneclick-component/src/components/Modal";
import useShowMessage from "oneclick-component/src/hooks/useShowMessage";
import { useShowError } from "../../hooks/useShowError";
import FindPTUserSection from "./FindPTUserSection";
import ShiftDetailSection from "./ShiftDetailSection";
import RequestedPTUserSection from "./RequestedPTUserSection";
import CancelHireDialog from "./CancelHireDialog";
import { showErrorScreen } from "../../utils/error";
import { ChatBubble } from "../../components/ChatBubble";
import AppRoutes from "../../routes/AppRoutes";
import CancelShiftDialog from "../../components/CancelShiftDialog/CancelShiftDialog";
import { StopIcon } from "../../icon";
import {
  UseListResponsedShiftRequestQueryParams,
  useShiftDetailScreenQuery,
} from "./useShiftDetailScreenQuery";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import NotFoundScreen from "oneclick-component/src/components/NotFoundScreen";
import useGenerateShiftPdfWebSocket from "../../hooks/useGenerateShiftPdfWebSocket";
import { SelectComponentProps } from "oneclick-component/src/components/inputs";
import { StationFilter } from "./RequestedPTUserSection/types";
import useCloseIncidentExpiryCountdown from "./useCloseIncidentExpiryCountdown";
import useShiftAccessControl from "../../hooks/useShiftAccessControl";
import FullTimeListSection from "./FullTimeListSection";

interface FullTimeEventPayload {
  type: "ADD_FULL_TIME" | "REMOVE_FULL_TIME";
  attendance: ShiftFullTimeAttendance;
}

interface PartTimeEventPayload {
  type: "ADD_PART_TIME" | "REMOVE_PART_TIME";
  shiftRequest: ShiftRequestBaseWithNumbering;
}

interface StatusUpdateEvent extends Event {
  detail?: FullTimeEventPayload | PartTimeEventPayload;
}

// eslint-disable-next-line complexity
const ShiftDetailScreen = (): ReactElement => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { showError } = useShowError();
  const shiftId = parseInt(id!, 10);
  const [cancelHire, { isLoading: isCancelHireLoading }] = useCancelHire();
  const [makeExpire, { isLoading: isMakeExpireLoading }] = useMakeExpire();
  const [hiredUserIds, setHiredUserIds] = useState<number[]>([]);
  const [responsedRequestsFilter, setResponsedRequestsFilter] = useState<
    UseListResponsedShiftRequestQueryParams | undefined
  >(undefined);
  const {
    shift,
    hiredShiftRequestsQuery,
    respondedShiftRequestsQuery,
    requestedShiftRequestsQuery,
    cancelledRecordQuery,
    cancelReasons,
    isShiftDetailLoading,
    isHiredShiftRequestsLoading,
    error,
  } = useShiftDetailScreenQuery(shiftId, responsedRequestsFilter);

  const {
    data: { shiftRequests: hiredShiftRequests },
  } = hiredShiftRequestsQuery;

  const showMessage = useShowMessage();
  const [isCancelHireOpen, setIsCancelHireOpen] = useState(false);
  const [pendingCancelUser, setPendingCancelUser] =
    useState<PtUserWithVisitCount | null>(null);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const onCloseConfirmDialog = useCallback(
    () => setIsConfirmDialogOpen(false),
    []
  );
  const onOpenConfirmDialog = useCallback(
    async () => setIsConfirmDialogOpen(true),
    []
  );
  const [isCloseIncidentDialogOpen, setIsCloseIncidentDialogOpen] =
    useState(false);
  const onCloseCloseIncidentDialog = useCallback(
    () => setIsCloseIncidentDialogOpen(false),
    []
  );

  const onConfirmCloseIncident = useCallback(async () => {
    if (shift?.id == null) {
      return;
    }
    makeExpire({ shiftId: shift.id })
      .unwrap()
      .then(() => {
        showMessage({
          type: "success",
          title: t("shiftDetail.closeIncident.dialog.success.title"),
          message: t("shiftDetail.closeIncident.dialog.success.description"),
        });
      })
      .catch((err) => {
        showError(err, "shiftDetail.closeIncident.dialog.fail.title");
      });
  }, [makeExpire, shift?.id, showError, showMessage, t]);

  const onSendCancel = useCallback(() => {
    if (shift != null && pendingCancelUser != null) {
      cancelHire({
        shiftId: shift.id,
        ptUserId: pendingCancelUser.id,
      })
        .unwrap()
        .then(() => {
          showMessage({
            type: "success",
            title: t("shiftDetail.cancelHire.dialog.success.title"),
            message: t("shiftDetail.cancelHire.dialog.success.description"),
          });
          setIsCancelHireOpen(false);
        })
        .catch((err) => {
          showError(err, "shiftDetail.cancelHire.dialog.fail.title");
        });
    }
  }, [pendingCancelUser, cancelHire, showError, t, showMessage, shift]);

  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const onCloseCancelDialog = useCallback(() => {
    setIsCancelDialogOpen(false);
  }, []);
  const onOpenCancelDialog = useCallback(() => {
    setIsCancelDialogOpen(true);
  }, []);
  const onCloseCancelHireDialog = useCallback(() => {
    setIsCancelHireOpen(false);
  }, []);
  const onOpenCancelHireDialog = useCallback((user: PtUserWithVisitCount) => {
    setPendingCancelUser(user);
    setIsCancelHireOpen(true);
  }, []);
  const addHiredUserIds = useCallback((userIds: number[]) => {
    setHiredUserIds((prev) => [...new Set([...prev, ...userIds])]);
  }, []);

  const triggerPdfGeneration = useGenerateShiftPdfWebSocket();
  const onExportPdf = useCallback(() => {
    if (shift) {
      triggerPdfGeneration([shift.id]);
    }
  }, [shift, triggerPdfGeneration]);

  const fulfillmentLeft = useMemo<number>(() => {
    if (shift == null || hiredShiftRequests == null) {
      return 0;
    }
    return Math.max(shift.fulfillmentCount - hiredShiftRequests.length, 0);
  }, [shift, hiredShiftRequests]);

  const hiredRequests = useMemo(
    () => hiredShiftRequests ?? [],
    [hiredShiftRequests]
  );

  const requestedPtStatus418Filter = useMemo<
    SelectComponentProps<Weekly418Status | null>
  >(() => {
    return {
      onChange: (v: Weekly418Status | null) => {
        setResponsedRequestsFilter((prev) => ({
          ...prev,
          ptUser418Status: v ?? undefined,
        }));
      },
      value: responsedRequestsFilter?.ptUser418Status ?? null,
    };
  }, [responsedRequestsFilter?.ptUser418Status]);

  const requestedPtStationFilter = useMemo<
    SelectComponentProps<StationFilter | null>
  >(() => {
    return {
      onChange: (v: StationFilter | null) => {
        if (shift == null) {
          console.error(
            "shift is null, unable to apply station filter on requested pt users"
          );
          return;
        }
        switch (v) {
          case "currentStation": {
            setResponsedRequestsFilter((prev) => ({
              ptUser418Status: prev?.ptUser418Status,
              includeStationIds: [shift.workingStation.mainStationId],
            }));
            return;
          }
          case "otherStations": {
            setResponsedRequestsFilter((prev) => ({
              ptUser418Status: prev?.ptUser418Status,
              excludeStationIds: [shift.workingStation.mainStationId],
            }));
            return;
          }
          case null: {
            setResponsedRequestsFilter((prev) => ({
              ptUser418Status: prev?.ptUser418Status,
            }));
          }
        }
      },
      value:
        responsedRequestsFilter?.includeStationIds != null
          ? "currentStation"
          : responsedRequestsFilter?.excludeStationIds != null
          ? "otherStations"
          : null,
    };
  }, [
    responsedRequestsFilter?.excludeStationIds,
    responsedRequestsFilter?.includeStationIds,
    shift,
  ]);

  const onClickCloseIncidentShift = useCallback(() => {
    setIsCloseIncidentDialogOpen(true);
  }, []);

  const { durationUntilExpiry: durationUntilCloseIncidentExpiry } =
    useCloseIncidentExpiryCountdown(shift);
  const durationUntilCloseIncidentExpiryDisplayString = useMemo<
    string | undefined
  >(() => {
    if (
      durationUntilCloseIncidentExpiry == null ||
      durationUntilCloseIncidentExpiry.toMillis() < 0
    ) {
      return undefined;
    }
    return durationUntilCloseIncidentExpiry.toFormat("hh:mm:ss");
  }, [durationUntilCloseIncidentExpiry]);

  const { canClose } = useShiftAccessControl(shift);
  const stateUpdateMessageCallback = useCallback(
    // eslint-disable-next-line complexity
    (e: StatusUpdateEvent) => {
      if (e.detail != null) {
        switch (e.detail.type) {
          case "ADD_FULL_TIME":
            showMessage({
              type: "success",
              title: t("shiftDetail.addFullTime.title", {
                name: `${e.detail.attendance.name.zhHk ?? ""} ${
                  e.detail.attendance.name.en ?? ""
                }`,
              }),
              toastIcon: (
                <WelcomeIcon className={cn("fill-green-400", "my-1")} />
              ),
              duration: 10000,
            });
            break;
          case "REMOVE_FULL_TIME":
            showMessage({
              type: "warning",
              title: t("shiftDetail.removeFullTime.title", {
                name: `${e.detail.attendance.name.zhHk ?? ""} ${
                  e.detail.attendance.name.en ?? ""
                }`,
              }),
              duration: 10000,
            });
            break;
          case "ADD_PART_TIME":
            if (e.detail.shiftRequest.status === "hired") {
              showMessage({
                type: "success",
                title: t("shiftDetail.addPartTime.title", {
                  name: `${e.detail.shiftRequest.ptUser.fullNameZhHk} ${e.detail.shiftRequest.ptUser.firstNameEn} ${e.detail.shiftRequest.ptUser.lastNameEn}`,
                }),
                toastIcon: (
                  <WelcomeIcon className={cn("fill-green-400", "my-1")} />
                ),
                duration: 10000,
              });
            }
            break;
          case "REMOVE_PART_TIME":
            showMessage({
              type: "warning",
              title: t("shiftDetail.removePartTime.title", {
                name: `${e.detail.shiftRequest.ptUser.fullNameZhHk} ${e.detail.shiftRequest.ptUser.firstNameEn} ${e.detail.shiftRequest.ptUser.lastNameEn}`,
              }),
              duration: 10000,
            });
            break;
        }
      }
    },
    [showMessage, t]
  );

  useEffect(() => {
    window.addEventListener(
      `shiftDetail_${shiftId}`,
      stateUpdateMessageCallback
    );
    return () => {
      window.removeEventListener(
        `shiftDetail_${shiftId}`,
        stateUpdateMessageCallback
      );
    };
  }, [stateUpdateMessageCallback, shiftId]);

  if (isNaN(shiftId)) {
    return (
      <div
        className={cn(
          "h-[calc(100vh-120px)]",
          "flex",
          "justify-center",
          "items-center"
        )}
      >
        <NotFoundScreen />
      </div>
    );
  }

  if (error != null) {
    return showErrorScreen(error);
  }

  if (isShiftDetailLoading || shift == null) {
    return (
      <div
        className={cn(
          "fixed",
          "top-1/2",
          "left-1/2",
          "-translate-x-1/2",
          "-translate-y-1/2"
        )}
      >
        <LoadingSpinner size="l" />
      </div>
    );
  }

  return (
    <main className="pb-24">
      <div
        className={cn(
          "md:rounded-lg",
          "bg-white",
          "shadow",
          "lg:w-4/5",
          "lg:min-w-[910px]",
          "w-full",
          "mx-auto",
          "mb-3",
          "overflow-hidden"
        )}
      >
        {shift.isIncident ? (
          <div className={cn("bg-red-500", "px-6", "py-1")}>
            <p className={cn("text-white", "font-medium", "text-base")}>
              <Trans i18nKey="shiftDetail.header.incidentBanner" />
            </p>
          </div>
        ) : null}
        <div className={cn("md:p-6", "p-4")}>
          <ShiftDetailSection
            shift={shift}
            cancelledRecordQuery={cancelledRecordQuery}
            isHiredShiftRequestsLoading={isHiredShiftRequestsLoading}
            hiredRequests={hiredRequests}
            onClickCancelShift={onOpenCancelDialog}
            onCancelUser={onOpenCancelHireDialog}
            onExportPdf={onExportPdf}
          />
          {shift.isIncident ? (
            <FullTimeListSection
              shiftId={shift.id}
              fullTimeCount={shift.fulltimeCount}
            />
          ) : null}
        </div>
      </div>
      <RequestedPTUserSection
        shift={shift}
        fulfillmentLeft={fulfillmentLeft}
        status418Filter={requestedPtStatus418Filter}
        stationFilter={requestedPtStationFilter}
        requestedShiftRequestsQuery={requestedShiftRequestsQuery}
        respondedShiftRequestsQuery={respondedShiftRequestsQuery}
        hiredUserIds={hiredUserIds}
        addHiredUserIds={addHiredUserIds}
      />
      {shift.status === "active" ? (
        <FindPTUserSection shift={shift} defaultExpand={true} />
      ) : null}
      <ChatBubble
        className={cn(
          "fixed",
          "z-20",
          "right-4",
          "bottom-20",
          "space-y-3",
          "sm:hidden"
        )}
      />
      <footer
        className={cn(
          "fixed",
          "bottom-0",
          "left-0",
          "z-10",
          "bg-white",
          "w-full",
          "h-18",
          "flex",
          "gap-x-3",
          "justify-end",
          "items-center",
          "px-6",
          "py-4",
          "border-t",
          "border-black/10"
        )}
      >
        {shift.status === "active" && canClose ? (
          <>
            <p className={cn("text-black/60", "text-xs", "font-normal")}>
              <span>
                <Trans i18nKey="shiftDetail.footer.closeIncidentShift.autoClose" />
              </span>{" "}
              <span>{durationUntilCloseIncidentExpiryDisplayString}</span>
            </p>
            <Button
              prefixIcon={StopIcon}
              className={cn("bg-red-600", "hover:bg-red-800", "h-10")}
              isFlashy={true}
              flashyClassName={cn("!bg-red-600")}
              onClick={onClickCloseIncidentShift}
              title={t("shiftList.table.incident.tooltip")}
            >
              <span className={cn("text-sm", "font-medium")}>
                <Trans i18nKey="shiftList.table.incident.closeShift" />
              </span>
            </Button>
          </>
        ) : null}

        <ButtonLink
          theme={"white"}
          to={AppRoutes.ShiftListScreen.render()}
          className={cn("hidden", "sm:block")}
        >
          <span className={cn("text-sm", "font-medium", "text-gray-700")}>
            <Trans i18nKey="shiftDetail.footer.backToHome" />
          </span>
        </ButtonLink>
      </footer>
      <CancelShiftDialog
        shiftIds={[shiftId]}
        isOpen={isCancelDialogOpen}
        onClose={onCloseCancelDialog}
        cancelReasons={cancelReasons ?? []}
      />
      <CancelHireDialog
        isOpen={isCancelHireOpen}
        onClose={onCloseCancelHireDialog}
        onSendCancel={onSendCancel}
        user={pendingCancelUser}
        shift={shift}
        isLoading={isCancelHireLoading}
      />
      <ActionDialog
        title={t("shiftDetail.closeIncidentShift.dialog.title")}
        actionButtonText={t(
          "shiftDetail.closeIncidentShift.dialog.action.confirm"
        )}
        cancelButtonText={t("common.cancel")}
        isOpen={isCloseIncidentDialogOpen}
        onClose={onCloseCloseIncidentDialog}
        onClickActionButton={onConfirmCloseIncident}
        isActionLoading={isMakeExpireLoading}
        titleIcon={
          <ExclamationTriangleIcon
            className={cn("w-6", "h-6", "mr-2", "text-black")}
          />
        }
      />
      <ActionDialog
        title={t("shiftDetail.completeHire.dialog.confirmComplete")}
        actionButtonText={t("shiftDetail.completeHire.dialog.action.confirm")}
        cancelButtonText={t("shiftDetail.completeHire.dialog.action.cancel")}
        onClose={onCloseConfirmDialog}
        onClickActionButton={onOpenConfirmDialog}
        isOpen={isConfirmDialogOpen}
      >
        <p className={cn("my-3", "text-sm", "font-medium", "text-black/60")}>
          <Trans i18nKey="shiftDetail.completeHire.dialog.notCompleteText" />
        </p>
      </ActionDialog>
    </main>
  );
};

export default ShiftDetailScreen;
