import React from "react";
import cn from "classnames";

interface Props {
  className?: string;
}

const IncidentBadge = (props: Props): React.ReactElement => {
  const { className } = props;

  return (
    <span
      className={cn(
        "leading-3",
        "min-w-7",
        "py-1",
        "text-[11px]",
        "text-center",
        "inline",
        "font-normal",
        "text-white",
        "align-middle",
        "bg-red-500",
        className
      )}
    >
      INC
    </span>
  );
};

export default IncidentBadge;
