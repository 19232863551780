import React, { PropsWithChildren, useMemo, useState, useEffect } from "react";
import {
  useListBorrowingRequestsHandlerShiftsBorrowingShiftRequestsGetQuery as useListBorrowingRequests,
  useListNotificationsHandlerNotificationsGetQuery as useListNotifications,
} from "oneclick-component/src/store/apis/enhancedApi";
import LoadingScreen from "oneclick-component/src/components/LoadingScreen";
import { useTranslation } from "react-i18next";
import { useSelectedStationProfile } from "../hooks/useSelectedStationProfile";

// eslint-disable-next-line react-refresh/only-export-components
export enum NotificationType {
  Pending = "Pending",
  General = "General",
  Handled = "Handled",
}

export interface Notification {
  description: string;
  type: NotificationType;
}

interface NotificationContextValue {
  pendingNotificationUnreadCount: number;
  generalNotificationUnreadCount: number;
  unreadNotifications: Notification[];
  setNotificationUnreadCount: (count: number) => void;
}

export const NotificationContext =
  React.createContext<NotificationContextValue>(null as any);

export default function NotificationProvider(
  props: PropsWithChildren
): React.ReactElement {
  const { t } = useTranslation();
  const [unreadCount, setUnreadCount] = useState(0);
  const selectedStationProfile = useSelectedStationProfile();
  const {
    data: borrowingShiftRequests,
    isLoading: isLoadingBorrowingShiftRequests,
  } = useListBorrowingRequests({
    stationId: selectedStationProfile?.station.id,
    borrowingStatus: ["pending"],
    pageIndex: 0,
    pageSize: 3,
  });
  const { data: notifications, isLoading: isLoadingNotifications } =
    useListNotifications({
      stationId: selectedStationProfile?.station.id,
      sort: "unread-,updated_at-",
      pageIndex: 0,
      pageSize: 3,
    });

  const isLoading = isLoadingBorrowingShiftRequests || isLoadingNotifications;
  const unreadNotifications = useMemo(() => {
    const data = (borrowingShiftRequests?.results ?? [])
      .filter((request) => request.borrowingRequestStatus === "pending")
      .map((request) => ({
        description: t("notification.pending.summary", {
          name: request.ptUser.fullNameZhHk,
          role: request.ptUser.role?.name,
          station: request.shift?.workingStation.shortCode,
        }),
        type: NotificationType.Pending,
      }));

    return data;
  }, [borrowingShiftRequests, t]);

  useEffect(() => {
    setUnreadCount(borrowingShiftRequests?.uncontactedCount ?? 0);
  }, [borrowingShiftRequests]);

  const value = useMemo(
    () => ({
      pendingNotificationUnreadCount: unreadCount,
      generalNotificationUnreadCount: notifications?.unreadCount ?? 0,
      unreadNotifications,
      setNotificationUnreadCount: setUnreadCount,
    }),
    [unreadNotifications, notifications, setUnreadCount, unreadCount]
  );

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <NotificationContext.Provider value={value}>
      {props.children}
    </NotificationContext.Provider>
  );
}
