import { Option } from "oneclick-component/src/components/inputs";

export function makeTimeOptions(): Option<string>[] {
  const times: string[] = Array(97)
    .fill(0)
    .map((_, index) => {
      const hour = Math.floor(index / 4)
        .toString()
        .padStart(2, "0");
      const minute = ((index % 4) * 15).toString().padStart(2, "0");
      return hour.concat(":", minute);
    });
  return times.map((t) => ({ name: t, value: t }));
}
