import React, { PropsWithChildren, useCallback } from "react";
import { Table as ReactTable, flexRender, Header } from "@tanstack/react-table";
import cn from "classnames";
import { SELECT_COLUMN_ID } from "./constants";
import { SortingArrow } from "./SortingArrow";

interface TableWithSelectProps<T> extends PropsWithChildren {
  className?: string;
  tableClassName?: string;
  table: ReactTable<T>;
  onClickColumnHeader?: (header: Header<T, unknown>) => void;
}

export function TableWithSelect<T>(
  props: TableWithSelectProps<T>
): React.ReactElement {
  const { table, className, children, onClickColumnHeader, tableClassName } =
    props;
  const makeOnClickHeader = useCallback(
    (header: Header<T, unknown>) => {
      if (onClickColumnHeader == null) {
        return undefined;
      }
      return () => onClickColumnHeader(header);
    },
    [onClickColumnHeader]
  );
  return (
    <div
      className={cn("overflow-x-auto", "min-w-full", "align-middle", className)}
    >
      <table className={cn("min-w-full", tableClassName)}>
        <thead className="bg-gray-50">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                if (header.column.id === SELECT_COLUMN_ID) {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      scope="col"
                      className={cn("bg-white", "pr-3", "cursor-pointer")}
                      style={{
                        width:
                          header.getSize() === Number.MAX_SAFE_INTEGER
                            ? "auto"
                            : header.getSize(),
                      }}
                      onClick={makeOnClickHeader(header)}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </th>
                  );
                }
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    scope="col"
                    className={cn(
                      "py-3",
                      "px-3",
                      "sm:px-6",
                      "text-left",
                      "text-xs",
                      "text-gray-500",
                      "whitespace-nowrap",
                      header.column.getCanSort()
                        ? ["cursor-pointer", "select-none"]
                        : ""
                    )}
                    style={{
                      width:
                        header.getSize() === Number.MAX_SAFE_INTEGER
                          ? "auto"
                          : header.getSize(),
                    }}
                    onClick={makeOnClickHeader(header)}
                  >
                    <span className={cn("flex", "items-center")}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      <SortingArrow sorting={header.column.getIsSorted()} />
                    </span>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody className="bg-white">{children}</tbody>
      </table>
    </div>
  );
}
