import { ReactElement, useCallback, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import cn from "classnames";
import { AddUserIcon, ChevronDownIcon } from "oneclick-component/src/icon";
import { Button } from "oneclick-component/src/components/Button";
import useShowMessage from "oneclick-component/src/hooks/useShowMessage";
import {
  ComboBox,
  ComboBoxOption,
} from "oneclick-component/src/components/inputs";
import {
  Shift,
  PtUser,
  useHireHandlerShiftsShiftIdHirePostMutation as useHirePtUser,
  useLazyGetPtUsersHandlerPartTimeUsersListPostQuery as useSearchPtUser,
  PtUserWithVisitCount,
  ListPtUserShiftCancelByShiftHandlerPtUserShiftCancelsShiftIdGetApiResponse as ListCancelledRecordResponse,
  PtUserShiftCancelCountWithPtUser,
} from "oneclick-component/src/store/apis/enhancedApi";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import { UseInfiniteQueryReturnValue } from "oneclick-component/src/hooks/useInfiniteQuery";
import PTUserDirectHireRow from "../../components/PTUserDirectHireRow";
import CancelledRecordRow from "../../components/CancelledRecordRow";
import HireStatusRow from "../../components/HireStatusRow";
import ProgressBar from "oneclick-component/src/components/ProgressBar";
import { useShowError } from "../../hooks/useShowError";
import { HiredShiftRequestBaseWithNumbering } from "../../models/shiftRequest";
import useShiftAccessControl from "../../hooks/useShiftAccessControl";
import useMeUserStations from "../../hooks/useMeUserStations";

interface PropsType {
  shift: Shift;
  isLoading: boolean;
  hiredRequests: HiredShiftRequestBaseWithNumbering[];
  onCancelUser: (user: PtUserWithVisitCount) => void;
  cancelledRecordQuery: UseInfiniteQueryReturnValue<ListCancelledRecordResponse>;
}

const HireStatusSection = (props: PropsType): ReactElement => {
  const {
    shift,
    hiredRequests,
    onCancelUser,
    isLoading,
    cancelledRecordQuery,
  } = props;
  const { t } = useTranslation();
  const [hire] = useHirePtUser();
  const { showError } = useShowError();
  const [searchPtUser, { data, isFetching, error }] = useSearchPtUser();
  const showMessage = useShowMessage();
  const [pendingDirectHireUserIds, setPendingDirectHireUserIds] = useState<
    string[]
  >([]);

  const handleFetchMore = useCallback(() => {
    if (
      cancelledRecordQuery.hasNextPage &&
      cancelledRecordQuery.nextPageIndex != null
    ) {
      cancelledRecordQuery.fetchNextPage(cancelledRecordQuery.nextPageIndex);
    }
  }, [cancelledRecordQuery]);

  const cancelRecords: PtUserShiftCancelCountWithPtUser[] = useMemo(() => {
    if (cancelledRecordQuery.pages == null) {
      return [];
    }

    return cancelledRecordQuery.pages.reduce<
      PtUserShiftCancelCountWithPtUser[]
    >((dataList, page) => [...dataList, ...page.data.results], []);
  }, [cancelledRecordQuery]);

  const handleDirectHire = useCallback(() => {
    const userIds = pendingDirectHireUserIds.map((option) =>
      parseInt(option, 10)
    );
    if (userIds.length > 0) {
      hire({
        shiftId: shift.id,
        hireRequestParam: {
          ptUserIds: userIds,
          isDirectHire: true,
        },
      })
        .unwrap()
        .then(() => {
          showMessage({
            type: "success",
            title: t("shiftDetail.hire.sent.title"),
            message: t("shiftDetail.hired.message"),
          });
          setPendingDirectHireUserIds([]);
        })
        .catch((e) => {
          // TODO: change to check error id
          if (
            e.data.detail.description.startsWith(
              "CCO staff cannot work longer than"
            )
          ) {
            showError(e, "shiftDetail.hire.sent.fail.ccoOverWork");
          } else {
            showError(e, "shiftDetail.hire.sent.fail.title");
          }
        });
    }
  }, [pendingDirectHireUserIds, hire, showMessage, t, shift, showError]);

  const handleChange = useCallback((users: string[]) => {
    setPendingDirectHireUserIds(users);
  }, []);

  const meStations = useMeUserStations();

  const handleSearchPtUsers = useCallback(
    (query: string) => {
      const regularShiftStationIds = [shift.workingStation.mainStationId];
      const incidentShiftStationIds =
        meStations === "all" ? null : meStations.map((s) => s.id);
      const stationIds =
        shift.shiftType === "REGULAR"
          ? regularShiftStationIds
          : incidentShiftStationIds;
      searchPtUser({
        searchString: query,
        getPtUsersRequest: {
          exceptUserIds: hiredRequests.map((req) => req.ptUserId),
          isFilterNotConsent: true,
          ptRoleIds: shift.ptUserRoles.map((role) => role.id),
          stationIds: stationIds,
        },
      })
        .unwrap()
        .catch((err) => {
          showError(err);
        });
    },
    [
      shift.workingStation.mainStationId,
      shift.shiftType,
      shift.ptUserRoles,
      meStations,
      searchPtUser,
      hiredRequests,
      showError,
    ]
  );
  const searchUserOptions: ComboBoxOption<PtUser>[] = useMemo(() => {
    if (data == null || error != null) {
      return [];
    }
    return data.results.map((user) => ({
      object: user,
      value: `${user.id}`,
      displayAbbrev: user.fullNameZhHk,
      name: `${user.fullNameZhHk} (${user.eid})`,
    }));
  }, [data, error]);

  const renderOptions = useCallback(
    (selected: boolean, active: boolean, option: ComboBoxOption<PtUser>) => {
      return (
        <div>
          <PTUserDirectHireRow
            user={option.object}
            isSelected={selected}
            isActive={active}
          />
        </div>
      );
    },
    []
  );

  const { canDirectHireOwnStationPt } = useShiftAccessControl(shift);

  if (isLoading) {
    return (
      <div
        className={cn(
          "w-full",
          "h-full",
          "flex",
          "items-center",
          "justify-center"
        )}
      >
        <LoadingSpinner size="l" />
      </div>
    );
  }

  return (
    <section className="w-full">
      <div className={cn("text-sm", "font-medium", "mb-3")}>
        <Trans i18nKey="shiftDetail.hire.title" />
      </div>
      <div className={cn("lg:flex", "lg:flex-row", "w-full", "gap-x-5")}>
        <div className="flex-1">
          <div
            className={cn("flex", "flex-row", "items-center", "w-full", "mb-3")}
          >
            <p className={cn("grow", "font-normal")}>
              <Trans i18nKey="shiftDetail.hire.hiredTitle" />
            </p>
            <div>
              <ProgressBar
                max={shift.fulfillmentCount}
                count={hiredRequests.length}
              />
            </div>
          </div>
          <div className="mb-3">
            {hiredRequests.length === 0 ? (
              <div
                className={cn(
                  "text-gray-400",
                  "font-normal",
                  "text-center",
                  "text-sm",
                  "p-4",
                  "border",
                  "border-black/12",
                  "bg-neutral-900/5",
                  "rounded-lg",
                  "my-3"
                )}
              >
                <Trans i18nKey="shiftDetail.hire.noHiredUser" />
              </div>
            ) : (
              hiredRequests.map((req) => (
                <HireStatusRow
                  key={`${req.shiftId}_${req.ptUserId}`}
                  workingStationShortCode={shift.workingStation.shortCode}
                  user={req.ptUser}
                  shiftRequest={req}
                  isIncident={shift.shiftType === "INCIDENT"}
                  showOption={shift.status !== "cancelled"}
                  shift={shift}
                  onCancelUser={onCancelUser}
                  disabled={
                    req.ptUser.isDeleted || shift.status === "cancelled"
                  }
                />
              ))
            )}
          </div>
          <div>
            {cancelRecords.map((record) => (
              <CancelledRecordRow
                key={`${record.ptUser.id}`}
                workingStationShortCode={shift.workingStation.shortCode}
                user={record.ptUser}
                cancelRecord={record}
              />
            ))}
            {!cancelledRecordQuery.isFetchingNextPage &&
            cancelledRecordQuery.hasNextPage ? (
              <div className={cn("flex", "justify-center")}>
                <Button
                  className={cn(
                    "bg-transparent",
                    "border-none",
                    "!shadow-none",
                    "hover:bg-transparent",
                    "h-6",
                    "md:my-3"
                  )}
                  iconClassName="fill-gray-500"
                  suffixIcon={ChevronDownIcon}
                  theme="white"
                  onClick={handleFetchMore}
                >
                  <span
                    className={cn("text-gray-500", "text-xs", "font-normal")}
                  >
                    <Trans i18nKey="shiftDetail.userList.fetchMore" />
                  </span>
                </Button>
              </div>
            ) : null}
            {cancelledRecordQuery.isFetchingNextPage ? (
              <div
                className={cn(
                  "w-full",
                  "h-full",
                  "flex",
                  "items-center",
                  "justify-center"
                )}
              >
                <LoadingSpinner size="l" />
              </div>
            ) : null}
          </div>
          <div>
            <p className="mb-3">
              <Trans i18nKey="shiftDetail.hire.addHireUser" />
            </p>
            <div className={cn("flex", "flex-row")}>
              <ComboBox
                className={cn("grow", "mr-3")}
                options={searchUserOptions}
                selectedItems={pendingDirectHireUserIds}
                onChange={handleChange}
                onLoadOptions={handleSearchPtUsers}
                isLoading={isFetching}
                placeholder={t("shiftDetail.hire.userNameOfPhoneTitle")}
                customRenderOptions={renderOptions}
                disabled={!canDirectHireOwnStationPt}
              />
              <Button
                prefixIcon={AddUserIcon}
                className="whitespace-nowrap"
                theme="white"
                onClick={handleDirectHire}
                disabled={!canDirectHireOwnStationPt}
              >
                <Trans i18nKey="shiftDetail.hire.addUser" />
              </Button>
            </div>
          </div>
          <p
            className={cn(
              "mt-3",
              "text-grey-2",
              "text-xs",
              "leading-5",
              "font-normal"
            )}
          >
            <Trans i18nKey="shiftDetail.hire.reminder" />
          </p>
          <div className={cn("border-b", "my-3", "lg:border-none")} />
        </div>
      </div>
    </section>
  );
};

export default HireStatusSection;
