import { IconButton } from "oneclick-component/src/components/Button";
import { ReactElement, useCallback } from "react";
import { StartChatButtonIcon } from "../../icon";
import { useDispatch } from "react-redux";
import cn from "classnames";
import { startChat } from "../../store/chatState";

interface Props {
  className?: string;
}
export const ChatBubble = (props: Props): ReactElement => {
  const { className } = props;
  const dispatch = useDispatch();
  const onStartChatClick = useCallback(() => {
    dispatch(startChat({ deselectChat: true }));
  }, [dispatch]);
  return (
    <div className={cn(className)}>
      <IconButton
        icon={StartChatButtonIcon}
        onClick={onStartChatClick}
        theme="primary"
      />
    </div>
  );
};
