import { ReactElement, useMemo } from "react";
import { BriefStation } from "oneclick-component/src/store/apis/enhancedApi";

import {
  EagerLoadStationSelectionDropdown,
  LazyLoadStationSelectionDropdown,
} from "../StationSelectionDropdown";
import { useSelectedStationProfile } from "../../hooks/useSelectedStationProfile";
import useMeUser from "../../hooks/useMeUser";

interface Props {
  className?: string;
  initialStationOptions?: BriefStation[];
  selectedStationId: number | null;
  onChange?: (stationId: number | null) => void;
  onStationSelected?: (station: BriefStation | null) => void;
  disabled?: boolean;
  nullIfEmpty?: boolean;
}

export const StationFilter = (props: Props): ReactElement => {
  const {
    className,
    initialStationOptions,
    selectedStationId,
    onChange,
    onStationSelected,
    disabled,
    nullIfEmpty,
  } = props;

  const meUser = useMeUser();
  const isSuperAdmin = meUser?.adminRole === "ADMIN";
  const selectedStationProfile = useSelectedStationProfile();

  const stationOptions = useMemo(() => {
    if (isSuperAdmin || meUser == null) {
      return [];
    }
    if (selectedStationProfile != null) {
      return [selectedStationProfile.station];
    }
    return meUser.stations;
  }, [meUser, selectedStationProfile, isSuperAdmin]);

  if (isSuperAdmin) {
    return (
      <LazyLoadStationSelectionDropdown
        className={className}
        initialStationOptions={initialStationOptions}
        selectedStationId={selectedStationId}
        onStationSelected={onStationSelected}
        onChange={onChange}
        disabled={disabled}
        nullIfEmpty={nullIfEmpty}
      />
    );
  }

  return (
    <EagerLoadStationSelectionDropdown
      stations={stationOptions}
      selectedStationId={selectedStationId}
      onStationSelected={onStationSelected}
      onChange={onChange}
      disabled={meUser?.adminRole === "MANAGER" || disabled}
      nullIfEmpty={nullIfEmpty}
    />
  );
};
