import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Trans } from "react-i18next";
import { RowSelectionState, SortingState } from "@tanstack/react-table";
import cn from "classnames";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import Pagination from "oneclick-component/src/components/Pagination";
import { useBreakPoints } from "oneclick-component/src/providers";
import {
  ShiftWithRequests,
  ShiftRequestBase,
  useListShiftsHandlerShiftsGetQuery,
  WorkingStation,
  ShiftType,
} from "oneclick-component/src/store/apis/enhancedApi";
import {
  formatDateTime,
  isoToDateTime,
} from "oneclick-component/src/utils/datetime";
import { makeShiftDisplayInterval } from "oneclick-component/src/utils/shift";
import { localizeString } from "oneclick-component/src/utils/localize";
import useOnClickTableColumnHeader, {
  ControlledTableState,
} from "oneclick-component/src/hooks/useOnClickTableColumnHeader";

import { RootState } from "../../../store/store";
import { saveCancelledShiftListFilter } from "../../../store/filterState";
import AppRoutes from "../../../routes/AppRoutes";
import MobileSearchView from "../../../components/MobileSearchView";
import i18next from "../../../i18n/i18n";
import { SHIFT_CANCELLED_TAB_GROUPING_COLUMN_HEADER_MAP } from "../../../constants/shifts/cancelledTab";
import { useIsManager } from "../../../hooks/role";
import { useSelectedStationProfile } from "../../../hooks/useSelectedStationProfile";
import { constructProfileScopedWorkingStationFilter } from "../workingStationFilter";
import { usePtUserRoleFilterOptions } from "../usePtUserRoleFilterOptions";
import {
  getDateRangeFromFilter,
  ShiftDateRangeFilter,
} from "../DateRangeFilter/model";
import { SHIFT_LIST_PAGE_SIZE } from "..";
import {
  CancelledShiftListRecord,
  useCancelledShiftListTable,
} from "./useCancelledShiftListTable";
import CancelledShiftTabActionBar from "./ActionBar";
import CancelledShiftTabTable from "./Table";
import useShiftRateFilterOptions from "../useShiftRateFilterOptions";
import { useShiftTypeFilterOptions } from "../useShiftTypeFilterOptions";

const transformFromShiftToShiftListRecord = (
  shift: ShiftWithRequests
): CancelledShiftListRecord => {
  const [_, { startTime, endTime, overnight }] = makeShiftDisplayInterval(
    shift.dutyStartTime,
    shift.dutyEndTime
  );
  const timeRangeString =
    endTime === ""
      ? `${startTime} - ${i18next.t("common.tbc")}`
      : `${startTime} - ${endTime}${overnight}`;
  const appliedRequests: ShiftRequestBase[] = shift.shiftRequests.filter(
    (req) =>
      (req.status === "applied" || req.status === "contacted") &&
      !req.ptUser.isDeleted
  );
  const hiredRequests: ShiftRequestBase[] = shift.shiftRequests.filter(
    (req) => req.status === "hired" && !req.ptUser.isDeleted
  );
  let remoteStation = null;
  if (shift.isIncident) {
    remoteStation = shift.supportStation;
  } else if (
    shift.workingStation.shortCode !==
    shift.workingStation.mainStation.shortCode
  ) {
    remoteStation = shift.workingStation;
  }
  return {
    info: {
      title: shift.shiftTitle,
      subtitle: (
        <Trans
          i18nKey="shiftList.table.displayId"
          values={{ displayId: shift.displayId }}
        />
      ),
      rate: shift.rate,
      isAppliableToAllAtT3: shift.isAppliableToAllAtT3,
    },
    timeRange: timeRangeString,
    cancelReason:
      shift.cancelReason != null ? localizeString(shift.cancelReason) : "",
    partTimeType: shift.ptUserRoles.map((role) => role.name).join(", "),
    numberOfApplicants: appliedRequests.length,
    hiredCount: hiredRequests.length,
    title: shift.shiftTitle,
    date: formatDateTime(isoToDateTime(shift.dutyStartTime), "yyyy-LL-dd"),
    id: shift.id,
    shiftType: shift.shiftType,
    remoteStation: remoteStation,
  };
};

// eslint-disable-next-line complexity
const useSortingQueryParam = (
  sortingState: SortingState
): string | undefined => {
  const defaultSort = `dutyStartTime+,dutyEndTime+`;
  if (sortingState.length === 0 || sortingState.length > 1) {
    return defaultSort;
  }
  // sortingState.length === 1
  const singleColumnSorting = sortingState[0];
  const sortDirectionSymbol = singleColumnSorting.desc ? "-" : "+";
  switch (singleColumnSorting.id) {
    case "info": {
      return `shiftTitle${sortDirectionSymbol}`;
    }
    case "timeRange": {
      return `dutyStartTime${sortDirectionSymbol},dutyEndTime${sortDirectionSymbol}`;
    }
    case "partTimeType": {
      return `ptUserRoleId${sortDirectionSymbol},${defaultSort}`;
    }
    case "numberOfApplicants": {
      return `numApplicants${sortDirectionSymbol},${defaultSort}`;
    }
    case "hiredCount": {
      return `hiredCount${sortDirectionSymbol},${defaultSort}`;
    }
    case "cancelReason": {
      if (i18next.language === "en") {
        return `cancel_reason_en${sortDirectionSymbol},${defaultSort}`;
      }
      return `cancel_reason_zh_hk${sortDirectionSymbol},${defaultSort}`;
    }
    default: {
      return defaultSort;
    }
  }
};

// eslint-disable-next-line complexity
const CancelledShiftTab = (): React.ReactElement => {
  const navigate = useNavigate();
  const { useIsSm } = useBreakPoints();
  const isSm = useIsSm();
  const isManager = useIsManager();
  const selectedStationProfile = useSelectedStationProfile();
  const dispatch = useDispatch();

  const savedFilter = useSelector((state: RootState) => {
    return state.filterState.cancelledShiftTabFilter;
  }, shallowEqual);

  const [pageIndex, setPageIndex] = useState(0);

  const [searchStringFilter, setSearchStringFilter] = useState<
    string | undefined
  >(savedFilter?.searchStringFilter);
  const [dateRangeFilter, setDateRangeFilter] = useState<ShiftDateRangeFilter>(
    savedFilter?.dateRangeFilter ?? {
      type: "all",
    }
  );
  const [workingStationId, setWorkingStationId] = useState<number | null>(
    savedFilter?.workingStationId ?? null
  );
  const [ptUserRoleFilter, setPtUserRoleFilter] = useState<string | null>(
    savedFilter?.ptUserRoleFilter ?? null
  );
  const [ptUserRoleFilterOptions, resolvePtUserRoleOptionToRoleId] =
    usePtUserRoleFilterOptions();
  const [shiftRateFilter, setShiftRateFilter] = useState<number | null>(
    savedFilter?.shiftRateFilter ?? null
  );
  const shiftRateFilterOptions = useShiftRateFilterOptions();
  const [shiftTypeFilter, setShiftTypeFilter] = useState<ShiftType>(
    savedFilter?.shiftTypeFilter ?? "REGULAR"
  );
  const shiftTypeFilterOptions = useShiftTypeFilterOptions();

  const shiftListFilterState = useMemo(() => {
    return {
      searchString: searchStringFilter,
      shiftType: shiftTypeFilter,
      dateRange: dateRangeFilter,
      ptUserRole: ptUserRoleFilter,
      workingStationId: workingStationId,
    };
  }, [
    searchStringFilter,
    shiftTypeFilter,
    dateRangeFilter,
    ptUserRoleFilter,
    workingStationId,
  ]);

  const [controlledTableState, setControlledTableState] =
    useState<ControlledTableState>({
      sorting: [{ id: "timeRange", desc: false }],
      grouping: ["date"],
    });
  const sortingQueryParam = useSortingQueryParam(controlledTableState.sorting);

  const profileScopedWorkingStationFilter =
    constructProfileScopedWorkingStationFilter(
      selectedStationProfile,
      workingStationId
    );

  const dateRange = getDateRangeFromFilter(dateRangeFilter);

  const { currentData: cancelledShiftsResponse, isFetching } =
    useListShiftsHandlerShiftsGetQuery(
      {
        status: "cancelled",
        pageSize: SHIFT_LIST_PAGE_SIZE,
        shiftType: shiftTypeFilter,
        sort: sortingQueryParam,
        pageIndex: pageIndex,
        searchString: searchStringFilter,
        before: dateRange?.before?.toISO(),
        after: dateRange?.after?.toISO(),
        ptUserRoleIds: resolvePtUserRoleOptionToRoleId(ptUserRoleFilter),
        rateId: shiftRateFilter,
        workingStationId: profileScopedWorkingStationFilter,
      },
      { refetchOnMountOrArgChange: true }
    );
  const { results: shifts } = cancelledShiftsResponse ?? {};
  const totalCount = cancelledShiftsResponse?.totalCount ?? 0;
  const tableData = useMemo(() => {
    return (shifts ?? []).map(transformFromShiftToShiftListRecord);
  }, [shifts]);
  const isShiftListEmpty = (shifts ?? []).length === 0;

  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({});
  const table = useCancelledShiftListTable(tableData, setRowSelection, {
    columnVisibility: {
      partTimeType: isSm ? true : false,
      title: false,
      date: false,
      id: false,
    },
    sorting: controlledTableState.sorting,
    grouping: controlledTableState.grouping,
    rowSelection,
  });

  const resetToPageOne = useCallback(() => {
    setPageIndex(0);
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    resetToPageOne();
    setRowSelection({});
  }, [
    controlledTableState.sorting,
    controlledTableState.grouping,
    shiftListFilterState,
    resetToPageOne,
  ]);

  const onClickColumnHeader =
    useOnClickTableColumnHeader<CancelledShiftListRecord>(
      setControlledTableState,
      SHIFT_CANCELLED_TAB_GROUPING_COLUMN_HEADER_MAP,
      undefined,
      new Set<string>(["date"])
    );

  const onChangeSearchString = useCallback(
    (v: string) => {
      const trimmedSearchString = v.trim();
      if (trimmedSearchString === "") {
        dispatch(
          saveCancelledShiftListFilter({
            searchStringFilter: undefined,
          })
        );
        setSearchStringFilter(undefined);
        return;
      }
      dispatch(
        saveCancelledShiftListFilter({
          searchStringFilter: trimmedSearchString,
        })
      );
      setSearchStringFilter(trimmedSearchString);
    },
    [dispatch]
  );

  const onClickTableRowFactory = useCallback(
    (shiftId: number) => () => {
      navigate(AppRoutes.ShiftDetailScreen.render(shiftId));
    },
    [navigate]
  );

  const handleOnClickPage = useCallback((pageIndex: number) => {
    setPageIndex(pageIndex);
    window.scrollTo({ top: 0 });
  }, []);

  const [isMobileSearchView, setIsMobileSearchView] = useState<boolean>(false);

  const onEnterMobileSearchView = useCallback(
    () => setIsMobileSearchView(true),
    []
  );
  const onExitMobileSearchView = useCallback(
    () => setIsMobileSearchView(false),
    []
  );

  const onChangePtUserRole = useCallback(
    (role: string | null) => {
      dispatch(
        saveCancelledShiftListFilter({
          ptUserRoleFilter: role,
        })
      );
      setPtUserRoleFilter(role);
    },
    [dispatch]
  );
  const onChangeShiftRate = useCallback(
    (rate: number | null) => {
      dispatch(
        saveCancelledShiftListFilter({
          shiftRateFilter: rate,
        })
      );
      setShiftRateFilter(rate);
    },
    [dispatch]
  );
  const onChangeWorkingStationFilter = useCallback(
    (station: WorkingStation | null) => {
      dispatch(
        saveCancelledShiftListFilter({
          workingStationId: station?.id ?? null,
        })
      );
      setWorkingStationId(station?.id ?? null);
    },
    [dispatch]
  );

  const onDateRangeFilterChange = useCallback(
    (filter: ShiftDateRangeFilter) => {
      dispatch(
        saveCancelledShiftListFilter({
          dateRangeFilter: filter,
        })
      );
      setDateRangeFilter(filter);
    },
    [dispatch]
  );

  const onChangeShiftType = useCallback(
    (value: ShiftType) => {
      dispatch(
        saveCancelledShiftListFilter({
          shiftTypeFilter: value,
        })
      );
      setShiftTypeFilter(value);
    },
    [dispatch]
  );

  const contentUnderSearchBar = useMemo(
    () => (
      <>
        <CancelledShiftTabActionBar
          className="mb-6"
          searchString={searchStringFilter}
          onChangeSearchString={onChangeSearchString}
          dateRangeFilter={dateRangeFilter}
          onDateRangeFilterChange={onDateRangeFilterChange}
          ptUserRole={ptUserRoleFilter}
          onChangePtUserRole={onChangePtUserRole}
          ptUserRoleOptions={ptUserRoleFilterOptions}
          shiftRate={shiftRateFilter}
          onChangeShiftRate={onChangeShiftRate}
          shiftRateOptions={shiftRateFilterOptions}
          shiftType={shiftTypeFilter}
          onChangeShiftType={onChangeShiftType}
          shiftTypeOptions={shiftTypeFilterOptions}
          showWorkingStationFilter={!isManager}
          workingStationFilter={workingStationId}
          onChangeWorkingStationFilter={onChangeWorkingStationFilter}
          isMobileSearchView={isMobileSearchView}
          onEnterMobileSearchView={onEnterMobileSearchView}
        />
        <CancelledShiftTabTable
          isLoading={isFetching}
          isEmpty={isShiftListEmpty}
          onClickTableRowFactory={onClickTableRowFactory}
          table={table}
          // Make sure table UI updated when selection is updated
          rowSelection={rowSelection}
          onClickColumnHeader={onClickColumnHeader}
        />
        {totalCount > 0 ? (
          <>
            <Pagination
              className={cn(
                "mt-3",
                "bg-white",
                !isSm && ["fixed", "bottom-0", "inset-x-0"]
              )}
              totalPages={Math.ceil(totalCount / SHIFT_LIST_PAGE_SIZE)}
              pageIndex={pageIndex}
              onClickPage={handleOnClickPage}
            />
            <div className="h-16" />
          </>
        ) : null}
      </>
    ),
    [
      onChangeSearchString,
      dateRangeFilter,
      ptUserRoleFilter,
      ptUserRoleFilterOptions,
      shiftRateFilter,
      shiftRateFilterOptions,
      shiftTypeFilter,
      shiftTypeFilterOptions,
      isManager,
      workingStationId,
      isMobileSearchView,
      onEnterMobileSearchView,
      isFetching,
      isShiftListEmpty,
      onClickTableRowFactory,
      table,
      rowSelection,
      onClickColumnHeader,
      totalCount,
      isSm,
      pageIndex,
      handleOnClickPage,
      onChangeShiftType,
      onDateRangeFilterChange,
      onChangeWorkingStationFilter,
      onChangeShiftRate,
      onChangePtUserRole,
      searchStringFilter,
    ]
  );

  if (isMobileSearchView) {
    return (
      <MobileSearchView
        onChangeSearchInput={onChangeSearchString}
        onExit={onExitMobileSearchView}
      >
        {contentUnderSearchBar}
      </MobileSearchView>
    );
  }
  return <div className="mt-1">{contentUnderSearchBar}</div>;
};

export default CancelledShiftTab;
