import React, { ReactElement, useCallback, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import cn from "classnames";

import { MonthPicker, Select } from "oneclick-component/src/components/inputs";
import YearMonth from "oneclick-component/src/models/yearMonth";
import { formatDateTime } from "oneclick-component/src/utils/datetime";
import { RadioInput } from "oneclick-component/src/components/inputs/RadioInput";
import { RadioGroup } from "@headlessui/react";
import {
  getDefaultDateRangeFilter,
  ShiftDateRangeFilter,
  ShiftDateRangeFilterType,
} from "./model";

interface PastDaysFilterProps {
  dateRangeFilter: ShiftDateRangeFilter;
  onDateRangeFilterChange: (filter: ShiftDateRangeFilter) => void;
}

const PastDaysFilter = React.memo((props: PastDaysFilterProps) => {
  const { dateRangeFilter, onDateRangeFilterChange } = props;
  const { t } = useTranslation();

  const pastDayOptions = useMemo(() => {
    return Array(7)
      .fill(null)
      .map((_, index) => index + 1)
      .map((day) => ({
        name: day.toString(),
        value: day,
      }));
  }, []);

  const selectedDay = useMemo(() => {
    if (dateRangeFilter.type !== "past") {
      return undefined;
    }
    return dateRangeFilter.numberOfDays ?? undefined;
  }, [dateRangeFilter]);

  const onChangePastDays = useCallback(
    (value: number) => {
      if (dateRangeFilter.type !== "past") {
        return;
      }
      onDateRangeFilterChange({
        ...dateRangeFilter,
        numberOfDays: value,
      });
    },
    [dateRangeFilter, onDateRangeFilterChange]
  );

  return (
    <RadioGroup.Option value="past">
      {({ active, checked }) => (
        <div className={cn("flex", "gap-3")}>
          <RadioInput
            labelClassName={cn(
              "text-base",
              "text-gray-700",
              "font-medium",
              "whitespace-nowrap"
            )}
            active={active}
            checked={checked}
            label={t("shiftList.filter.dateRange.past.label")}
          />
          <Select
            className="w-29.5"
            placeholder={t("shiftList.filter.dateRange.past.placeholder")}
            onChange={onChangePastDays}
            options={pastDayOptions}
            value={selectedDay}
            disabled={dateRangeFilter.type !== "past"}
          />
        </div>
      )}
    </RadioGroup.Option>
  );
});

interface FutureDaysFilterProps {
  dateRangeFilter: ShiftDateRangeFilter;
  onDateRangeFilterChange: (filter: ShiftDateRangeFilter) => void;
}

const FutureDaysFilter = React.memo((props: FutureDaysFilterProps) => {
  const { dateRangeFilter, onDateRangeFilterChange } = props;
  const { t } = useTranslation();

  const futureDayOptions = useMemo(() => {
    return Array(7)
      .fill(null)
      .map((_, index) => index + 1)
      .map((day) => ({
        name: day.toString(),
        value: day,
      }));
  }, []);

  const selectedDay = useMemo(() => {
    if (dateRangeFilter.type !== "future") {
      return undefined;
    }
    return dateRangeFilter.numberOfDays ?? undefined;
  }, [dateRangeFilter]);

  const onChangeFutureDays = useCallback(
    (value: number) => {
      if (dateRangeFilter.type !== "future") {
        return;
      }
      onDateRangeFilterChange({
        ...dateRangeFilter,
        numberOfDays: value,
      });
    },
    [dateRangeFilter, onDateRangeFilterChange]
  );

  return (
    <RadioGroup.Option value="future">
      {({ active, checked }) => (
        <div className={cn("flex", "gap-3")}>
          <RadioInput
            labelClassName={cn(
              "text-base",
              "text-gray-700",
              "font-medium",
              "whitespace-nowrap"
            )}
            active={active}
            checked={checked}
            label={t("shiftList.filter.dateRange.future.label")}
          />
          <Select
            className="w-29.5"
            placeholder={t("shiftList.filter.dateRange.future.placeholder")}
            onChange={onChangeFutureDays}
            options={futureDayOptions}
            value={selectedDay}
            disabled={dateRangeFilter.type !== "future"}
          />
        </div>
      )}
    </RadioGroup.Option>
  );
});

interface Props {
  className?: string;
  dateRangeFilter: ShiftDateRangeFilter;
  onDateRangeFilterChange: (filter: ShiftDateRangeFilter) => void;
  showFutureDaysFilter: boolean;
  showPastDaysFilter: boolean;
}

export const DateRangeFilter = React.memo((props: Props): ReactElement => {
  const {
    className,
    dateRangeFilter,
    onDateRangeFilterChange,
    showPastDaysFilter,
    showFutureDaysFilter,
  } = props;
  const { t } = useTranslation();

  const formattedYearMonthValue = useMemo(() => {
    if (dateRangeFilter.type !== "month" || dateRangeFilter.yearMonth == null) {
      return undefined;
    }
    return formatDateTime(
      DateTime.fromObject(dateRangeFilter.yearMonth),
      t("common.monthWithYear.displayformat")
    );
  }, [t, dateRangeFilter]);

  const onDateRangeFilterTypeChange = useCallback(
    (value: ShiftDateRangeFilterType) => {
      onDateRangeFilterChange(getDefaultDateRangeFilter(value));
    },
    [onDateRangeFilterChange]
  );

  const onChangeMonth = useCallback(
    (value: YearMonth) => {
      if (dateRangeFilter.type !== "month") {
        return;
      }
      onDateRangeFilterChange({
        ...dateRangeFilter,
        yearMonth: value,
      });
    },
    [dateRangeFilter, onDateRangeFilterChange]
  );

  return (
    <div className={cn("text-left", className)}>
      <label className={cn("block", "text-black/90", "text-sm", "mb-1")}>
        <Trans i18nKey="shiftList.filter.dateRange.label" />
      </label>
      <RadioGroup
        name="dateRangeFilterType"
        onChange={onDateRangeFilterTypeChange}
        value={dateRangeFilter.type}
        className={cn("flex", "items-center", "h-10.5", "gap-6")}
      >
        <RadioGroup.Option value="today">
          {({ active, checked }) => (
            <RadioInput
              labelClassName={cn(
                "text-base",
                "text-gray-700",
                "font-medium",
                "whitespace-nowrap"
              )}
              active={active}
              checked={checked}
              label={t("shiftList.filter.dateRange.today.label")}
            />
          )}
        </RadioGroup.Option>
        {showPastDaysFilter ? (
          <PastDaysFilter
            dateRangeFilter={dateRangeFilter}
            onDateRangeFilterChange={onDateRangeFilterChange}
          />
        ) : null}
        {showFutureDaysFilter ? (
          <FutureDaysFilter
            dateRangeFilter={dateRangeFilter}
            onDateRangeFilterChange={onDateRangeFilterChange}
          />
        ) : null}
        <RadioGroup.Option value="month">
          {({ active, checked }) => (
            <div className={cn("flex", "gap-3")}>
              <RadioInput
                labelClassName={cn(
                  "text-base",
                  "text-gray-700",
                  "font-medium",
                  "whitespace-nowrap"
                )}
                active={active}
                checked={checked}
                label={t("shiftList.filter.dateRange.month.label")}
              />
              <MonthPicker
                className="w-32"
                value={
                  dateRangeFilter.type === "month"
                    ? dateRangeFilter.yearMonth
                    : null
                }
                onChange={onChangeMonth}
                placeholder={t("shiftList.filter.dateRange.month.placeholder")}
                formattedValue={formattedYearMonthValue}
                allowSelectAll={false}
                disabled={dateRangeFilter.type !== "month"}
              />
            </div>
          )}
        </RadioGroup.Option>
        <RadioGroup.Option value="all">
          {({ active, checked }) => (
            <RadioInput
              labelClassName={cn(
                "text-base",
                "text-gray-700",
                "font-medium",
                "whitespace-nowrap"
              )}
              active={active}
              checked={checked}
              label={t("shiftList.filter.dateRange.all.label")}
            />
          )}
        </RadioGroup.Option>
      </RadioGroup>
    </div>
  );
});
