import React, { useCallback } from "react";
import { FilterProps, OptionFilterProps } from "oneclick-component/src/models";
import { ShiftDateRangeFilter } from "../../ShiftListScreen/DateRangeFilter/model";
import cn from "classnames";
import { Button } from "oneclick-component/src/components/Button";
import { DownloadIcon } from "oneclick-component/src/icon";
import { DateRangeFilter } from "../../ShiftListScreen/DateRangeFilter/DateRangeFilter";
import { SearchInput, Select } from "oneclick-component/src/components/inputs";
import { Trans, useTranslation } from "react-i18next";
import {
  BriefStation,
  WorkingStation,
} from "oneclick-component/src/store/apis/enhancedApi";
import { LazyLoadWorkingStationSelectionDropdown } from "../../../components/WorkingStationSelectionDropdown";
import { StationFilter } from "../../../components/StationFilter";

interface SelectWithLabelProps<TData> {
  label: string;
  filter: OptionFilterProps<TData>;
}

function SelectWithLabel<TData>(
  props: SelectWithLabelProps<TData>
): React.ReactElement {
  const { label, filter } = props;

  return (
    <div>
      <label
        className={cn(
          "text-sm",
          "block",
          "font-normal",
          "text-left",
          "text-black/86",
          "mb-1"
        )}
      >
        {label}
      </label>
      <Select<TData>
        className="w-27"
        value={filter.value}
        onChange={filter.onChange}
        options={filter.options}
      />
    </div>
  );
}
interface Props {
  shiftDateRangeFilter: FilterProps<ShiftDateRangeFilter>;
  ptRoleFilter: OptionFilterProps<string | null>;
  shiftRateFilter: OptionFilterProps<number | null>;
  workingStationFilter: FilterProps<number | null>;
  ptStationFilter?: FilterProps<number | null>;
  searchString?: string;
  onChangeSearchString: (v: string) => void;
  onExport: () => void;
  className?: string;
}

const OutgoingTabActionBar = (props: Props): React.ReactElement => {
  const {
    shiftDateRangeFilter,
    ptRoleFilter,
    shiftRateFilter,
    ptStationFilter,
    workingStationFilter,
    searchString,
    onChangeSearchString,
    onExport,
    className,
  } = props;
  const { t } = useTranslation();

  const onWorkingStationSelect = useCallback(
    (workingStation: WorkingStation | null) => {
      workingStationFilter.onChange(workingStation?.id ?? null);
    },
    [workingStationFilter]
  );
  const onPtStationSelect = useCallback(
    (ptStation: BriefStation | null) => {
      ptStationFilter?.onChange(ptStation?.id ?? null);
    },
    [ptStationFilter]
  );

  return (
    <div
      className={cn(
        "flex",
        "flex-wrap",
        "items-end",
        "gap-x-5",
        "gap-y-4",
        className
      )}
    >
      <DateRangeFilter
        dateRangeFilter={shiftDateRangeFilter.value}
        onDateRangeFilterChange={shiftDateRangeFilter.onChange}
        showPastDaysFilter={true}
        showFutureDaysFilter={true}
      />
      <div
        className={cn(
          "border-[0.5px]",
          "border-black/12",
          "w-[1px]",
          "self-stretch"
        )}
      ></div>
      <div className={cn("flex", "gap-x-4")}>
        <SelectWithLabel
          label={t("shiftRequestList.filter.ptRole.label")}
          filter={ptRoleFilter}
        />
        <SelectWithLabel
          label={t("shiftRequestList.filter.shiftRate.label")}
          filter={shiftRateFilter}
        />
        <div>
          <label
            className={cn(
              "text-sm",
              "block",
              "font-normal",
              "text-left",
              "text-black/86",
              "mb-1"
            )}
          >
            <Trans i18nKey="shiftRequestList.filter.workingStation.label" />
          </label>
          <LazyLoadWorkingStationSelectionDropdown
            className="w-30"
            selectedWorkingStationId={workingStationFilter.value ?? null}
            onWorkingStationSelected={onWorkingStationSelect}
          />
        </div>
        {ptStationFilter != null ? (
          <div>
            <label
              className={cn(
                "text-sm",
                "block",
                "font-normal",
                "text-left",
                "text-black/86",
                "mb-1"
              )}
            >
              <Trans i18nKey="shiftRequestList.filter.ptStation.label" />
            </label>
            <StationFilter
              className="w-30"
              selectedStationId={ptStationFilter.value ?? null}
              onStationSelected={onPtStationSelect}
            />
          </div>
        ) : null}
      </div>
      <div
        className={cn(
          "border-[0.5px]",
          "border-black/12",
          "w-[1px]",
          "self-stretch"
        )}
      ></div>
      <SearchInput
        initialSearchText={searchString}
        className={cn("w-83", "h-10.5")}
        onChange={onChangeSearchString}
        placeholder={t("shiftRequestList.filter.searchInput.placeholder")}
      />
      <div className="grow" />
      <Button
        theme="white"
        prefixIcon={DownloadIcon}
        className={cn("h-10.5", "text-sm", "font-normal", "mr-3")}
        iconClassName="!w-3"
        onClick={onExport}
      >
        <Trans i18nKey="shiftRequestList.outgoing.export" />
      </Button>
    </div>
  );
};

export default OutgoingTabActionBar;
