import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  Shift,
  PtUser,
  useLazyGetChatHandlerShiftsShiftIdChatWithPtuserPtUserIdGetQuery as useGetChatByShiftRequest,
  ShiftRequestBase,
} from "oneclick-component/src/store/apis/enhancedApi";
import { useShowError } from "./useShowError";
import { startChat } from "../store/chatState";

const useOpenChatFromShiftRequest = (): [
  (
    shift: Shift,
    ptUser: PtUser,
    shiftRequest?: ShiftRequestBase,
    isPrivate?: boolean
  ) => void,
  boolean,
] => {
  const [getChat, { isLoading }] = useGetChatByShiftRequest();
  const dispatch = useDispatch();
  const { showError } = useShowError();

  const showChat = useCallback(
    (
      shift: Shift,
      ptUser: PtUser,
      shiftRequest?: ShiftRequestBase,
      isPrivate?: boolean
    ) => {
      if (shiftRequest?.chatId != null) {
        dispatch(
          startChat({
            chatId: shiftRequest.chatId,
            isPrivate,
          })
        );
      } else if (!isLoading) {
        getChat({
          shiftId: shift.id,
          ptUserId: ptUser.id,
        })
          .unwrap()
          .then((result) => {
            console.info("open chat: ", result);
            dispatch(
              startChat({
                chatId: result.chat.id,
                isPrivate,
              })
            );
          })
          .catch((e: unknown) => {
            showError(e, "chatDialog.messageList.startChat.error.title");
          });
      }
    },
    [getChat, dispatch, isLoading, showError]
  );

  return [showChat, isLoading];
};

export default useOpenChatFromShiftRequest;
