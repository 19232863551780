import { Weekly418Status, WorkingStatus } from "../store/apis/enhancedApi";

export const get418StatusFromPrev3WeekWorkingStatus = (
  prev3WeekWorkingStatus: [
    WorkingStatus | null,
    WorkingStatus | null,
    WorkingStatus | null,
  ]
): Weekly418Status => {
  // const arr = [statusThreeWeeksAgo, statusTwoWeeksAgo, statusOneWeekAgo]
  if (prev3WeekWorkingStatus.some((v) => v === "LT18")) {
    return "SAFE";
  }
  if (prev3WeekWorkingStatus.some((v) => v == null)) {
    return "UNKNOWN";
  }
  return "WARNING";
};
