import { ReactElement } from "react";
import cn from "classnames";
import { Dialog } from "@headlessui/react";
import { useSelector } from "react-redux";
import { Trans } from "react-i18next";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Modal, ModalProps } from "oneclick-component/src/components/Modal";
import { localizeString } from "oneclick-component/src/utils/localize";
import {
  PtUserWithVisitCount,
  TemplateMessage,
  Shift,
} from "oneclick-component/src/store/apis/enhancedApi";
import { RootState } from "../../store/store";
import { SendTemplateActionSection } from "../../components/SendTemplateActionSection";

interface Props extends ModalProps {
  user: PtUserWithVisitCount | null;
  shift: Shift;
  onClose: () => void;
  onSendCancel: () => void;
  isLoading: boolean;
}

const CancelHireDialog = (props: Props): ReactElement => {
  const { onClose, onSendCancel, user, shift, isLoading, ...rest } = props;

  const hireTemplate = useSelector((state: RootState) => {
    return state.templateMessage.templateMessages.find(
      (template: TemplateMessage) => template.slug === "shift_cancel_hire_user"
    );
  });

  return (
    <Modal
      onClose={onClose}
      hasXMarkButton={false}
      {...rest}
      className={cn("w-full", "max-w-169")}
    >
      <div className={cn("sm:flex", "sm:items-start", "w-full")}>
        <div
          className={cn(
            "mt-3",
            "text-center",
            "sm:mt-0",
            "sm:text-left",
            "w-full",
            "flex",
            "flex-row",
            "items-center"
          )}
        >
          <Dialog.Title
            className={cn("text-black", "text-lg", "font-medium", "grow")}
          >
            <Trans
              i18nKey="shiftDetail.cancelHire.dialog.title"
              values={{
                name: user != null ? user.fullNameZhHk : "",
              }}
            />
          </Dialog.Title>
          <button
            type="button"
            className={cn(
              "rounded-md",
              "bg-white",
              "text-gray-400",
              "hover:text-gray-500",
              "focus:outline-none",
              "focus:ring-2",
              "focus:ring-indigo-500",
              "focus:ring-offset-2"
            )}
            onClick={onClose}
          >
            <span className="sr-only">Close</span>
            <XMarkIcon className={cn("h-6", "w-6")} aria-hidden="true" />
          </button>
        </div>
      </div>
      <p
        className={cn(
          "text-sm",
          "leading-5",
          "font-normal",
          "text-black/60",
          "my-5"
        )}
      >
        <Trans i18nKey="shiftDetail.cancelHire.dialog.description" />
      </p>
      <SendTemplateActionSection
        className="sm:max-w-none"
        onSend={onSendCancel}
        isSending={isLoading}
        sendTemplate={hireTemplate}
        templateParam={{
          "1": localizeString(shift.workingStation.name),
          "2": shift.shiftTitle,
          "3": shift.ptUserRoles.map((role) => role.name).join(","),
          "4": shift.dutyStartTime,
          "5": "",
          "6": "",
        }}
      />
    </Modal>
  );
};

export default CancelHireDialog;
