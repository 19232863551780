import React, { useCallback, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import cn from "classnames";

import { useExportAllShiftsHandlerShiftsExportAllPostMutation as useExportAllShifts } from "oneclick-component/src/store/apis/enhancedApi";
import { Button } from "oneclick-component/src/components/Button";
import { TrashIcon, DownloadIcon } from "oneclick-component/src/icon";
import useShowMessage from "oneclick-component/src/hooks/useShowMessage";
import useGenerateShiftPdfWebSocket from "../../../hooks/useGenerateShiftPdfWebSocket";
import { useIsSuperAdmin } from "../../../hooks/role";

interface ActionBarProps {
  className?: string;
  selectedShiftIds: number[];
  onClickOpenDialog: () => void;
}
const ExpiredShiftTabSelectedActionBar = (
  props: ActionBarProps
): React.ReactElement => {
  const { className, selectedShiftIds, onClickOpenDialog } = props;
  const { t } = useTranslation();
  const [exportAllEnabled, setExportAllEnabled] = useState(true);
  const showMessage = useShowMessage();
  const [exportAllShifts, { isLoading }] = useExportAllShifts();

  const triggerPdfGeneration = useGenerateShiftPdfWebSocket();
  const isSuperAdmin = useIsSuperAdmin();

  const onClickExportPdf = useCallback(() => {
    triggerPdfGeneration(selectedShiftIds);
  }, [selectedShiftIds, triggerPdfGeneration]);

  const onClickCancel = useCallback(() => {
    onClickOpenDialog();
  }, [onClickOpenDialog]);

  const onClickExportAllShift = useCallback(() => {
    exportAllShifts()
      .unwrap()
      .then(() => {
        showMessage({
          type: "info",
          title: t("shift.exportAllShifts.started"),
        });
        setExportAllEnabled(false);
      })
      .catch(() => {
        showMessage({
          type: "fail",
          title: t("shift.exportPdf.failed"),
        });
      });
  }, [t, exportAllShifts, showMessage]);

  return (
    <div className={cn("flex", "flex-row", "h-8", className)}>
      <div className={cn("grow", "flex", "flex-row", "items-center")}>
        <p className={cn("text-xs", "text-black/60", "mr-2")}>
          <Trans i18nKey="shiftList.tab.expired.selectedShift" />
        </p>
        <p className={cn("text-xs", "text-black")}>{selectedShiftIds.length}</p>
      </div>
      {isSuperAdmin ? (
        <Button
          theme="whiteNoFillBlackTextThick"
          prefixIcon={DownloadIcon}
          className={cn("h-8", "text-xs", "font-medium", {
            "mr-3": selectedShiftIds.length > 0,
          })}
          iconClassName="!w-3"
          disabled={isLoading || !exportAllEnabled}
          onClick={onClickExportAllShift}
        >
          <Trans i18nKey="shiftList.tab.exportMenu.exportAllShift" />
        </Button>
      ) : null}
      {selectedShiftIds.length > 0 ? (
        <div>
          {!isSuperAdmin ? (
            <Button
              theme="whiteNoFillBlackTextThick"
              prefixIcon={DownloadIcon}
              className={cn("h-8", "text-xs", "font-medium", "mr-3")}
              iconClassName="!w-3"
              onClick={onClickExportPdf}
              disabled={isSuperAdmin}
            >
              <Trans i18nKey="shiftList.tab.completed.exportMenu.pdf" />
            </Button>
          ) : null}
          <Button
            theme="whiteBgRedText"
            prefixIcon={TrashIcon}
            className={cn("h-8", "text-xs", "text-rose-400", "font-medium")}
            iconClassName={cn("!w-3", "mr-2", "!fill-rose-400")}
            onClick={onClickCancel}
          >
            <Trans i18nKey="shiftDetail.actionMenu.cancelShift" />
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default ExpiredShiftTabSelectedActionBar;
