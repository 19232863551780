import { RegularShiftSchedule } from "oneclick-component/src/store/apis/enhancedApi";
import { ReactElement, useMemo } from "react";
import { IntraDayRegularShift } from "../../../models";
import { Trans, useTranslation } from "react-i18next";
import cn from "classnames";
import { DAYS_OF_WEEK_NAMES } from "../../../constants/dayOfWeek";

interface Props {
  regularShiftSchedule: RegularShiftSchedule[];
}
interface WeekTableDayOfWeekViewModel {
  regularShiftSchedule: IntraDayRegularShift[];
}

function mapRegularShiftSchedule(
  regularShiftSchedule: RegularShiftSchedule[]
): WeekTableDayOfWeekViewModel[] {
  const result: WeekTableDayOfWeekViewModel[] = [
    { regularShiftSchedule: [] },
    { regularShiftSchedule: [] },
    { regularShiftSchedule: [] },
    { regularShiftSchedule: [] },
    { regularShiftSchedule: [] },
    { regularShiftSchedule: [] },
    { regularShiftSchedule: [] },
  ];

  for (const s of regularShiftSchedule) {
    result[s.dayOfWeek].regularShiftSchedule.push({
      startTime: s.startTime,
      endTime: s.endTime,
    });
  }

  return result;
}

interface TableColumnProps {
  title: string;
  content: WeekTableDayOfWeekViewModel;
}
const PartTimeUserCardWeekTableColumn = (
  props: TableColumnProps
): ReactElement => {
  const { content, title } = props;
  const { regularShiftSchedule } = content;
  return (
    <div
      className={cn(
        "flex",
        "flex-row",
        "sm:flex-col",
        "rounded-md",
        "overflow-hidden"
      )}
    >
      <p
        className={cn(
          "flex-none",
          "font-medium",
          "text-xs",
          "text-gray-500",
          "bg-gray-50",
          "p-3",
          "sm:py-3",
          "sm:px-6",
          "border-r",
          "sm:border-r-0",
          "sm:border-b",
          "border-gray-200",
          "w-25",
          "sm:w-auto"
        )}
      >
        {title}
      </p>
      {regularShiftSchedule.length === 0 ? (
        <p
          className={cn(
            "font-medium",
            "text-xs",
            "sm:text-sm",
            "p-3",
            "sm:py-4",
            "sm:px-6",
            "flex",
            "flex-1",
            "items-center"
          )}
        >
          <Trans i18nKey="partTime.list.tab.availability.table.week.content.unavailable" />
        </p>
      ) : null}
      {regularShiftSchedule.length > 0 ? (
        <div
          className={cn(
            "flex",
            "flex-row",
            "sm:block",
            "font-medium",
            "text-xs",
            "sm:text-sm"
          )}
        >
          {regularShiftSchedule.map((a) => (
            <p className={cn("p-3", "sm:py-4", "sm:px-6")} key={a.startTime}>
              {a.startTime.concat("-", a.endTime)}
            </p>
          ))}
        </div>
      ) : null}
    </div>
  );
};

const PartTimeUserCardWeekTable = (props: Props): ReactElement => {
  const { regularShiftSchedule } = props;
  const { t } = useTranslation();

  const tableViewModel = useMemo(
    () => mapRegularShiftSchedule(regularShiftSchedule),
    [regularShiftSchedule]
  );
  const tableViewDaysOfWeek = [6, 0, 1, 2, 3, 4, 5];

  return (
    <div
      className={cn(
        "grid",
        "grid-rows-7",
        "sm:grid-rows-none",
        "sm:grid-cols-7",
        "w-full",
        "min-w-max"
      )}
    >
      {tableViewDaysOfWeek.map((dow) => (
        <PartTimeUserCardWeekTableColumn
          key={dow}
          title={t(
            `partTime.list.tab.availability.table.week.title.${DAYS_OF_WEEK_NAMES[dow]}`
          )}
          content={tableViewModel[dow]}
        />
      ))}
    </div>
  );
};

export default PartTimeUserCardWeekTable;
