import React from "react";
import { PlusIcon } from "@heroicons/react/24/outline";
import cn from "classnames";

import { FilePicker } from "oneclick-component/src/components/inputs/FilePicker";

import { WHATSAPP_ATTACHMENT_ACCEPTED_MIME } from "../../constants/attachments";
import useValidateAttachmentType from "../../hooks/useValidateAttachmentType";

interface MessageAttachmentPickerProps {
  onAttachmentSelected: (file: File) => void;
  disabled?: boolean;
}

export const MessageAttachmentPicker = React.memo(
  (props: MessageAttachmentPickerProps) => {
    const { onAttachmentSelected, disabled } = props;

    const { onValidationError, getAttachmentSizeLimitMb } =
      useValidateAttachmentType();

    return (
      <FilePicker
        onFileSelected={onAttachmentSelected}
        onValidationError={onValidationError}
        acceptedMime={WHATSAPP_ATTACHMENT_ACCEPTED_MIME}
        sizeLimitMb={getAttachmentSizeLimitMb}
        disabled={disabled}
        buttonNode={
          <div
            className={cn(
              "flex",
              "items-center",
              "justify-center",
              "w-9",
              "h-9",
              "min-w-9",
              "min-h-9",
              "rounded-full",
              {
                "bg-gray-600 cursor-pointer": !disabled,
                "bg-gray-300": disabled,
              }
            )}
          >
            <PlusIcon className={cn("w-6", "h-6", "text-white", "stroke-2")} />
          </div>
        }
      />
    );
  }
);
