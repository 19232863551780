import { DateTime, Duration } from "luxon";
import { isoToDateTime } from "oneclick-component/src/utils/datetime";
import { config } from "../config";

export function getWhatsAppConversationExpireDateTime(
  lastInboundMessageTimestamp: string | null
): DateTime | null {
  if (lastInboundMessageTimestamp == null) {
    return null;
  }
  const expireDateTime = isoToDateTime(lastInboundMessageTimestamp).plus(
    Duration.fromMillis(config.whatsAppReplyWindowMs)
  );

  return expireDateTime;
}
