import { SortingState } from "@tanstack/react-table";
import { useMemo } from "react";

const useSortingQueryParam = (
  sortingState: SortingState
): string | undefined => {
  // switch-case false-positive of complexity
  // eslint-disable-next-line complexity
  return useMemo(() => {
    const defaultSort = `shiftDutyStartTime+`;
    if (sortingState.length === 0 || sortingState.length > 1) {
      return `${defaultSort}`;
    }
    // sortingState.length === 1
    const singleColumnSorting = sortingState[0];
    const sortDirectionSymbol = singleColumnSorting.desc ? "-" : "+";

    switch (singleColumnSorting.id) {
      case "ptEid": {
        return `ptEid${sortDirectionSymbol},${defaultSort}`;
      }
      case "ptNameEn": {
        return `ptNameEn${sortDirectionSymbol},${defaultSort}`;
      }
      case "ptNameZh": {
        return `ptNameZh${sortDirectionSymbol},${defaultSort}`;
      }
      case "ptRole": {
        return `ptRole${sortDirectionSymbol},${defaultSort}`;
      }
      case "ptStationShortCode": {
        return `ptStation${sortDirectionSymbol},${defaultSort}`;
      }
      case "shiftWorkingStationShortCode": {
        return `shiftStation${sortDirectionSymbol},${defaultSort}`;
      }
      case "shiftTimeDisplayString": {
        return `shiftDutyStartTime${sortDirectionSymbol}`;
      }
      case "shiftRateCode": {
        return `shiftRate${sortDirectionSymbol},${defaultSort}`;
      }
      case "shiftTitle": {
        return `shiftTitle${sortDirectionSymbol},${defaultSort}`;
      }
      case "shiftDisplayId": {
        return `shiftDisplayId${sortDirectionSymbol},${defaultSort}`;
      }
      default: {
        return `${defaultSort}`;
      }
    }
  }, [sortingState]);
};

export default useSortingQueryParam;
