import { z } from "zod";

const ensureName = ({
  nameEn,
  nameZhHk,
}: {
  nameEn: string;
  nameZhHk: string;
}) => {
  return `${nameEn}${nameZhHk}`.trim().length > 0;
};

export const formSchema = z
  .object({
    nameEn: z.string(),
    nameZhHk: z.string(),
    role: z.string().min(1),
    stationId: z.number(),
  })
  .refine(ensureName, {
    message: "fulltimeAttendance.add.error.emptyName",
    path: ["nameEn"],
  });

export type Form = z.infer<typeof formSchema>;
