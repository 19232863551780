import {
  Asset,
  useCreateAssetHandlerAssetsPostMutation,
} from "oneclick-component/src/store/apis/enhancedApi";
import { useCallback, useMemo, useState } from "react";
import { useUploadFileToStorage } from "./useUploadFileToStorage";

interface AttachmentStateInitial {
  state: "initial";
  error?: undefined;
}
interface AttachmentStateUploading {
  state: "uploading";
  file: File;
  error?: undefined;
}
interface AttachmentStateUploaded {
  state: "uploaded";
  file: File;
  assetId: number;
  error?: undefined;
}

interface AttachmentStateError {
  state: "error";
  file: File;
  error?: unknown;
}

export type AttachmentState =
  | AttachmentStateInitial
  | AttachmentStateUploading
  | AttachmentStateUploaded
  | AttachmentStateError;

interface UploadMessageAttachmentHookReturnValue {
  selectedAttachmentId: number | undefined;
  attachmentState: AttachmentState;
  onAttachmentSelected: (file: File) => void;
  onAttachmentRemoved: (file: File) => void;
  onAttachmentSent: () => void;
}

export function useUploadAttachment(): UploadMessageAttachmentHookReturnValue {
  const uploadFileToStorage = useUploadFileToStorage();
  const [createAsset] = useCreateAssetHandlerAssetsPostMutation();

  const [attachmentState, setAttachmentState] = useState<AttachmentState>({
    state: "initial",
  });

  const onAttachmentUploaded = useCallback((asset: Asset) => {
    setAttachmentState((prev) => {
      if (prev.state !== "uploading") {
        return prev;
      }
      return {
        state: "uploaded",
        file: prev.file,
        assetId: asset.id,
      };
    });
  }, []);

  const onAttachmentUploadError = useCallback((err: unknown) => {
    setAttachmentState((prev) => {
      if (prev.state !== "uploading") {
        return prev;
      }
      return {
        state: "error",
        error: err,
        file: prev.file,
      };
    });
  }, []);

  const uploadAttachment = useCallback(
    async (file: File) => {
      try {
        const { asset, uploadUrl } = await createAsset({
          createAssetRequest: {
            filename: file.name,
            contentSize: file.size,
            contentType: file.type,
          },
        }).unwrap();

        await uploadFileToStorage(file, uploadUrl);
        onAttachmentUploaded(asset);
      } catch (err: unknown) {
        onAttachmentUploadError(err);
      }
    },
    [
      createAsset,
      uploadFileToStorage,
      onAttachmentUploaded,
      onAttachmentUploadError,
    ]
  );

  const onAttachmentSelected = useCallback(
    (file: File) => {
      setAttachmentState({
        state: "uploading",
        file,
      });
      uploadAttachment(file).catch((err: unknown) => {
        throw err; // Error should be handled already
      });
    },
    [uploadAttachment]
  );

  const onAttachmentRemoved = useCallback((_file: File) => {
    setAttachmentState({ state: "initial" });
  }, []);

  const onAttachmentSent = useCallback(() => {
    setAttachmentState({ state: "initial" });
  }, []);

  const selectedAttachmentId = useMemo<number | undefined>(() => {
    return attachmentState.state === "uploaded"
      ? attachmentState.assetId
      : undefined;
  }, [attachmentState]);

  return {
    attachmentState,
    selectedAttachmentId,
    onAttachmentSelected,
    onAttachmentRemoved,
    onAttachmentSent,
  };
}
