import { ReactElement, useMemo } from "react";

import { WorkingStation } from "oneclick-component/src/store/apis/enhancedApi";

import {
  EagerLoadWorkingStationSelectionDropdown,
  LazyLoadWorkingStationSelectionDropdown,
} from "../WorkingStationSelectionDropdown";
import { useSelectedStationProfile } from "../../hooks/useSelectedStationProfile";
import useMeUser from "../../hooks/useMeUser";

interface Props {
  className?: string;
  initialWorkingStationOptions?: WorkingStation[];
  selectedWorkingStationId: number | null;
  onChange?: (workingStationId: number | null) => void;
  onWorkingStationSelected?: (workingStation: WorkingStation | null) => void;
  disabled?: boolean;
  nullIfEmpty?: boolean;
}

export const WorkingStationFilter = (props: Props): ReactElement => {
  const {
    className,
    initialWorkingStationOptions,
    selectedWorkingStationId,
    onChange,
    onWorkingStationSelected,
    disabled,
    nullIfEmpty,
  } = props;

  const meUser = useMeUser();
  const isSuperAdmin = meUser?.adminRole === "ADMIN";
  const selectedStationProfile = useSelectedStationProfile();

  const workingStationOptions = useMemo(() => {
    if (isSuperAdmin || meUser == null) {
      return [];
    }
    if (selectedStationProfile != null) {
      return selectedStationProfile.station.workingStations;
    }
    return meUser.stations.flatMap((s) => s.workingStations);
  }, [meUser, selectedStationProfile, isSuperAdmin]);

  if (isSuperAdmin) {
    return (
      <LazyLoadWorkingStationSelectionDropdown
        className={className}
        initialWorkingStationOptions={initialWorkingStationOptions}
        selectedWorkingStationId={selectedWorkingStationId}
        onWorkingStationSelected={onWorkingStationSelected}
        onChange={onChange}
        disabled={disabled}
        nullIfEmpty={nullIfEmpty}
      />
    );
  }

  return (
    <EagerLoadWorkingStationSelectionDropdown
      workingStations={workingStationOptions}
      selectedWorkingStationId={selectedWorkingStationId}
      onWorkingStationSelected={onWorkingStationSelected}
      onChange={onChange}
      disabled={meUser?.adminRole === "MANAGER" || disabled}
      nullIfEmpty={nullIfEmpty}
    />
  );
};
