import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import auth from "./auth";
import { MsalAuthService } from "../services/msalAuth";
import { enhancedApi } from "oneclick-component/src/store/apis/enhancedApi";
import ptUserWorkingStatusesMapState from "oneclick-component/src/store/apis/states/ptUserWorkingStatusesMapState";
import chatState from "./chatState";
import filterState from "./filterState";
import templateMessage from "./templateMessage";
import audioPlayer from "./audioPlayer";
import multiShiftCreateForm from "./multiShiftCreateForm";
import multiShiftCreateFormPreview from "./multiShiftPreview";

const reducerMap = {
  [enhancedApi.reducerPath]: enhancedApi.reducer,
  auth,
  chatState: persistReducer(
    {
      key: "chatState",
      storage,
      whitelist: ["isChatDialogShown", "selectedChatId"],
    },
    chatState
  ),
  filterState: persistReducer(
    {
      key: "filterState",
      storage,
      whitelist: [
        "activeShiftTabFilter",
        "expiredShiftTabFilter",
        "cancelledShiftTabFilter",
        "incomingPtTabFilter",
        "outgoingPtTabFilter",
      ],
    },
    filterState
  ),
  ptUserWorkingStatusesMapState,
  templateMessage,
  audioPlayer,
  multiShiftCreateForm,
  multiShiftCreateFormPreview,
};

export const reducer = combineReducers(reducerMap);

export type RootState = ReturnType<typeof reducer>;

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
      thunk: {
        extraArgument: { authService: MsalAuthService.getInstance() },
      },
    }).concat(enhancedApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
