import React, {
  Fragment,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useMemo,
} from "react";
import cn from "classnames";
import { Menu, Transition } from "@headlessui/react";
import PopMenuItem, { PopMenuItemProps } from "./PopMenuItem";
import {
  FloatingPortal,
  autoUpdate,
  flip,
  shift,
  useFloating,
} from "@floating-ui/react";

interface PopMenuProps extends PropsWithChildren {
  controlElement: ReactNode;
  controlElementClass?: string | (({ open }: { open: boolean }) => string);
  menuClass?: string;
  menuBodyClass?: string;
  menuItems?: PopMenuItemProps[];
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const PopMenu = (props: PopMenuProps): ReactElement => {
  const {
    children,
    controlElement,
    controlElementClass,
    menuClass,
    menuBodyClass,
    menuItems,
    onClick,
  } = props;

  const menuItemElements = useMemo(() => {
    if (menuItems == null) {
      return null;
    }
    return menuItems.map((item, index) => {
      return <PopMenuItem key={index} {...item} />;
    });
  }, [menuItems]);

  const { refs, floatingStyles } = useFloating({
    whileElementsMounted: autoUpdate,
    placement: "bottom-end",
    middleware: [flip(), shift()],
  });
  return (
    <Menu as="div" className={menuClass}>
      <Menu.Button
        ref={refs.setReference}
        className={controlElementClass}
        onClick={onClick}
      >
        {controlElement}
      </Menu.Button>
      <FloatingPortal>
        <Transition
          as={Fragment}
          enter="transition-opacity ease-out duration-200"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition-opacity ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            ref={refs.setFloating}
            style={floatingStyles}
            className={cn(
              "z-10",
              "mt-2",
              "min-w-48",
              "max-w-75",
              "divide-y",
              "divide-gray-100",
              "rounded-md",
              "bg-white",
              "py-1",
              "shadow-lg",
              "ring-1",
              "ring-black/5",
              "focus:outline-none",
              menuBodyClass
            )}
          >
            {menuItemElements}
            {children}
          </Menu.Items>
        </Transition>
      </FloatingPortal>
    </Menu>
  );
};

export default PopMenu;

export { PopMenuItem };
