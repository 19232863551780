import React, { ButtonHTMLAttributes, ReactElement } from "react";
import cn from "classnames";
import { Theme, themes } from "./theme";

interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  icon: React.ComponentType<any>;
  theme?: Theme;
  iconClassName?: string;
}

export function IconButton(props: IconButtonProps): ReactElement {
  const { className, icon, theme = "primary", iconClassName, ...rest } = props;
  const { buttonThemeClassName, iconThemeClassName } = themes[theme];
  const Icon = icon;
  return (
    <button
      type="button"
      className={cn(
        "group",
        "rounded-full",
        "shadow-sm",
        "focus:ring-2",
        "focus:ring-offset-2",
        "focus:outline-0",
        "p-2.5",
        buttonThemeClassName,
        className
      )}
      {...rest}
    >
      <Icon
        className={cn("h-5", "w-5", iconThemeClassName, iconClassName)}
        aria-hidden="true"
      />
    </button>
  );
}
