import React, {
  ReactElement,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";
import cn from "classnames";

import { IconButton } from "oneclick-component/src/components/Button";
import { SendIcon } from "oneclick-component/src/icon";
import { TemplateMessage } from "oneclick-component/src/store/apis/enhancedApi";
import { isMobile } from "../../utils/userAgent";
import { AttachmentState } from "../../hooks/useUploadAttachment";

function shouldDisableSendButton(
  isSending: boolean,
  attachmentState: AttachmentState["state"],
  isTemplateOnly: boolean,
  draftMessage: string,
  selectedTemplate: TemplateMessage | null
) {
  if (isSending || attachmentState === "uploading") {
    return true;
  }
  const isDraftMessageEmpty = draftMessage.trim().length === 0;
  const isMessageContentEmpty = isTemplateOnly
    ? selectedTemplate == null
    : isDraftMessageEmpty;
  if (!isMessageContentEmpty) {
    return false;
  }
  // message content empty, if there is attachment uploaded allow send
  return attachmentState !== "uploaded";
}

interface Props {
  className?: string;
  draftMessage: string;
  selectedTemplate: TemplateMessage | null;
  placeholder: string;
  attachmentState: AttachmentState["state"];
  isTemplateOnly: boolean;
  isSending: boolean;
  onDraftMessageChange: (message: string) => void;
  onSendMessage: () => Promise<void>;
}

export const MessageInputTextarea = React.memo((props: Props): ReactElement => {
  const {
    className,
    draftMessage,
    selectedTemplate,
    placeholder,
    attachmentState,
    isTemplateOnly,
    isSending,
    onDraftMessageChange,
    onSendMessage,
  } = props;

  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  // determined by user agent, expected to be fixed
  const isMobileRef = useRef(isMobile());

  const resizeTextarea = useCallback(() => {
    if (textareaRef.current == null) {
      return;
    }
    // NOTE: make sure text area height smaller than content
    textareaRef.current.style.height = "0";
    const targetHeight = textareaRef.current.scrollHeight;
    textareaRef.current.style.height = `${targetHeight}px`;
  }, []);

  const onSendClick = useCallback(() => {
    onSendMessage().catch((err) => {
      throw err;
    });
  }, [onSendMessage]);

  const disableSendButton = useMemo(() => {
    return shouldDisableSendButton(
      isSending,
      attachmentState,
      isTemplateOnly,
      draftMessage,
      selectedTemplate
    );
  }, [
    isSending,
    attachmentState,
    isTemplateOnly,
    draftMessage,
    selectedTemplate,
  ]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (!isMobileRef.current) {
        // Shift + Enter for new line
        // Enter for send message
        if (e.key === "Enter" && !e.shiftKey) {
          e.preventDefault();
          if (!disableSendButton) {
            onSendClick();
          }
        }
      }
    },
    [onSendClick, disableSendButton]
  );

  // NOTE: useLayoutEffect is needed to avoid flickering
  // as it is UI blocking
  useLayoutEffect(() => {
    resizeTextarea();
  }, [draftMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  const onTextareaRefChange = useCallback(
    (ref: HTMLTextAreaElement | null) => {
      if (ref != null) {
        textareaRef.current = ref;
        // NOTE: update text area height
        resizeTextarea();
      }
    },
    [resizeTextarea]
  );

  const onInputChange = useCallback(
    (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
      ev.preventDefault();
      ev.stopPropagation();
      if (isTemplateOnly) {
        return;
      }
      const { value } = ev.target;
      onDraftMessageChange(value);
    },
    [isTemplateOnly, onDraftMessageChange]
  );

  return (
    <div
      className={cn(
        "flex",
        "py-2",
        "pl-3",
        "pr-3",
        "bg-white",
        "border",
        "border-gray-300",
        "rounded-[24px]",
        "items-end",
        {
          "bg-zinc-800/5": isTemplateOnly,
        },
        className
      )}
    >
      <textarea
        ref={onTextareaRefChange}
        className={cn(
          "flex-1",
          "p-0",
          "appearance-none",
          "resize-none",
          "outline-none",
          "border-none",
          "placeholder:text-black/25",
          "bg-transparent",
          "text-black/90",
          "focus:outline-none",
          "focus:ring-0",
          "max-h-30" // line height * 5 = max 5 lines without scroll
        )}
        onChange={onInputChange}
        value={draftMessage}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        disabled={isTemplateOnly}
      />
      <IconButton
        className={cn("!py-0.5", "!px-1", "w-6", "h-6")}
        icon={SendIcon}
        theme="transparentPrimary"
        onClick={onSendClick}
        disabled={disableSendButton}
      />
    </div>
  );
});
