import { ReactNode, useCallback, HTMLProps } from "react";
import ReactSlider, { ReactSliderProps } from "react-slider";
import { t } from "i18next";
import cn from "classnames";

interface SliderProps extends ReactSliderProps {
  labels?: string[];
  labelClassName?: string;
}

const SliderTrack = (
  props: HTMLProps<HTMLDivElement>,
  state: {
    index: number;
    value: number;
  }
) => {
  return (
    <div
      {...props}
      className={cn("rounded-full", "h-1", "top-[10px]", {
        "bg-primary-400": state.index === 0,
        "bg-black/10": state.index === 1,
      })}
    />
  );
};

const SliderThumb = (props: HTMLProps<HTMLDivElement>) => {
  return (
    <div
      {...props}
      className={cn(
        "h-6",
        "bg-white",
        "w-6",
        "shadow-md",
        "rounded-full",
        "border-2",
        "border-primary-300",
        "cursor-pointer"
      )}
    >
      {props["aria-valuenow"] === 3 ? ( // "aria-valuenow" props provided by react-slider library
        <>
          <span
            className={cn(
              "w-25",
              "h-25",
              "rounded-full",
              "border",
              "border-purple-300",
              "bg-purple-100/50",
              "absolute",
              "left-1/2",
              "top-1/2",
              "-translate-y-1/2",
              "-translate-x-1/2"
            )}
          />
          <p
            className={cn(
              "absolute",
              "text-center",
              "text-xs",
              "font-medium",
              "text-purple-700",
              "w-25",
              "top-14",
              "left-1/2",
              "translate-y-1/2",
              "-translate-x-1/2"
            )}
          >
            {t("findPtSlider.stationGroup.selected")}
          </p>
        </>
      ) : null}
    </div>
  );
};

const Slider = (props: SliderProps): ReactNode => {
  const { labels, labelClassName, className, ...rest } = props;
  const SliderMark = useCallback(
    (props: HTMLProps<HTMLSpanElement>) => {
      if (labels == null || props.key == null) {
        return null;
      }

      const style = { ...props.style };
      if (style.left != null) {
        /** thumb size is fixed to be 24, half is 12 */
        style.left = (style.left as number) + 12;
      }
      return (
        <span
          {...props}
          style={style}
          className={cn(
            "absolute",
            "top-8",
            "-translate-x-1/2",
            "text-center",
            "cursor-pointer",
            "text-xs",
            "font-medium",
            labelClassName
          )}
        >
          {labels[props.key as number]}
        </span>
      );
    },
    [labels, labelClassName]
  );

  return (
    <ReactSlider
      className={cn(
        "h-6",
        "mx-auto",
        "cursor-pointer",
        {
          "sm:mb-10": labels != null && labels.length > 0,
          "sm:mb-4": labels == null,
        },
        className
      )}
      marks={!!(labels != null && labels.length > 0)}
      renderTrack={SliderTrack}
      renderThumb={SliderThumb}
      renderMark={SliderMark}
      {...rest}
    />
  );
};

export default Slider;
