import {
  isFetchQueryBaseError,
  isApiErrorCode,
} from "oneclick-component/src/models/error";
import NotFoundScreen from "oneclick-component/src/components/NotFoundScreen";
import React from "react";
import GenericErrorScreen from "oneclick-component/src/components/GenericErrorScreen";

export function showErrorScreen(error: unknown): React.ReactElement {
  if (isFetchQueryBaseError(error)) {
    const rawCode = (error.data as any)?.detail?.code;
    if (typeof rawCode === "string" && isApiErrorCode(rawCode)) {
      switch (rawCode) {
        case "NOT_FOUND":
        case "UNAUTHORIZED":
          return <NotFoundScreen />;
        default:
          break;
      }
    }
  }

  return <GenericErrorScreen />;
}
