import React, { ReactElement, useMemo } from "react";
import cn from "classnames";

import {
  BriefStationWithWorkingStation,
  StationTeam,
} from "oneclick-component/src/store/apis/enhancedApi";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/outline";
import { StationBadge } from "oneclick-component/src/components/Badge";

interface ChatStationsBadgeProps {
  homeStation: BriefStationWithWorkingStation;
  partnerStation: BriefStationWithWorkingStation | null;
  ptUserStationTeam: StationTeam | null;
  showStationTeam?: boolean;
}

export const ChatStationsBadge = React.memo(
  (props: ChatStationsBadgeProps): ReactElement => {
    const {
      homeStation,
      partnerStation,
      ptUserStationTeam,
      showStationTeam = false,
    } = props;

    const { homeStationTeam, partnerStationTeam } = useMemo(() => {
      const stationTeams: {
        homeStationTeam: StationTeam | null;
        partnerStationTeam: StationTeam | null;
      } = {
        homeStationTeam: null,
        partnerStationTeam: null,
      };

      if (ptUserStationTeam == null) {
        return stationTeams;
      }

      // borrow PT from partner station
      if (
        partnerStation != null &&
        partnerStation.id === ptUserStationTeam.stationId
      ) {
        stationTeams.partnerStationTeam = ptUserStationTeam;
      }

      if (homeStation.id === ptUserStationTeam.stationId) {
        stationTeams.homeStationTeam = ptUserStationTeam;
      }

      return stationTeams;
    }, [ptUserStationTeam, partnerStation, homeStation]);

    return (
      <div className={cn("flex", "items-center")}>
        {partnerStation != null ? (
          <>
            <StationBadge
              station={partnerStation}
              stationTeam={partnerStationTeam}
              showStationTeam={showStationTeam}
            />
            <ChevronDoubleRightIcon
              className={cn("text-gray-400", "w-3", "h-3", "mx-1")}
            />
          </>
        ) : null}
        <StationBadge
          station={homeStation}
          stationTeam={homeStationTeam}
          showStationTeam={showStationTeam}
        />
      </div>
    );
  }
);
