import { ReactElement, useCallback, useId } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import cn from "classnames";

import { FormField } from "oneclick-component/src/components/forms";
import { useBatchSendWelcomeMessageHandlerPartTimeUsersBatchSendWelcomeMessagePostMutation as useBatchSendWelcomeMessage } from "oneclick-component/src/store/apis/enhancedApi";
import { LazyLoadStationSelectionDropdown } from "../../components/StationSelectionDropdown";
import { useShowError } from "../../hooks/useShowError";
import {
  sendWelcomePtMessageFormSchema,
  SendWelcomePtMessageFormValues,
} from "./sendWelcomePtMessageForm";
import useShowMessage from "oneclick-component/src/hooks/useShowMessage";
import { Button } from "oneclick-component/src/components/Button";

const SendWelcomePtMessageScreen = (): ReactElement => {
  const { t } = useTranslation();
  const formId = useId();
  const { showError } = useShowError();
  const showMessage = useShowMessage();
  const [sendWelcomePtMessage, { isLoading: isSendingMessage }] =
    useBatchSendWelcomeMessage();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<SendWelcomePtMessageFormValues>({
    resolver: zodResolver(sendWelcomePtMessageFormSchema),
    defaultValues: {
      stationId: undefined,
    },
  });

  const onValidSubmit = useCallback(
    async (data: SendWelcomePtMessageFormValues) => {
      try {
        const response = await sendWelcomePtMessage({
          batchSendWelcomeMessageRequest: {
            stationIds: [data.stationId],
          },
        }).unwrap();
        if (response.failedPtUserIds.length === 0) {
          showMessage({
            type: "success",
            title: t("sendWelcomePtMessage.successDialog.title"),
            message: t("sendWelcomePtMessage.successDialog.message"),
          });
        } else {
          showMessage({
            type: "warning",
            title: t("sendWelcomePtMessage.warningDialog.title"),
            message: t("sendWelcomePtMessage.warningDialog.message", {
              ptUserIds: response.failedPtUserIds.join(", "),
            }),
          });
        }
        // reset form
        reset();
      } catch (err: unknown) {
        showError(err);
      }
    },
    [sendWelcomePtMessage, showMessage, showError, reset, t]
  );

  return (
    <main
      className={cn(
        "rounded-lg",
        "bg-white",
        "sm:shadow",
        "sm:mx-auto",
        "px-4",
        "pb-5",
        "sm:p-6"
      )}
    >
      <div className="mb-3">
        <h1 className={cn("text-2xl", "font-bold")}>
          <Trans i18nKey="sendWelcomePtMessage.title" />
        </h1>
      </div>
      <form
        id={formId}
        className={cn("flex", "flex-col", "gap-y-5")}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(onValidSubmit)}
      >
        <FormField.Control name={"stationId"} control={control}>
          {({ field }) => (
            <FormField.Container
              label={t("sendWelcomePtMessage.form.stationId.label")}
              errorMessage={errors.stationId?.message}
              required={true}
            >
              <LazyLoadStationSelectionDropdown
                selectedStationId={field.value}
                onChange={field.onChange}
              />
            </FormField.Container>
          )}
        </FormField.Control>
      </form>
      <footer
        className={cn(
          "fixed",
          "bottom-0",
          "left-0",
          "bg-white",
          "w-full",
          "h-16",
          "flex",
          "justify-end",
          "px-6",
          "py-3"
        )}
      >
        <Button type="submit" form={formId} disabled={isSendingMessage}>
          <Trans i18nKey="sendWelcomePtMessage.footer.button.send" />
        </Button>
      </footer>
    </main>
  );
};

export default SendWelcomePtMessageScreen;
