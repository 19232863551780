import * as z from "zod";

export const fileGenerationWebSocketEvents = [
  "fileGenerationStarted",
  "fileBlobUploaded",
  "fileGenerationFailed",
] as const;
export const FileGenerationWebSocketEventTypeSchema = z.enum(
  fileGenerationWebSocketEvents
);
export type FileGenerationWebSocketEventType = z.infer<
  typeof FileGenerationWebSocketEventTypeSchema
>;

export const fileGenerationWebSocketEventBaseSchema = z.object({
  type: FileGenerationWebSocketEventTypeSchema,
  payload: z.any(),
});

function createFileGenerationWebSocketEventSchema<
  TType extends FileGenerationWebSocketEventType,
  TPayload extends z.ZodTypeAny,
>(eventType: TType, payloadSchema: TPayload) {
  return z.object({
    type: z.literal(eventType),
    payload: payloadSchema,
  });
}

export const fileGenerationStartedEventPayloadSchema = z.object({
  taskId: z.string(),
});

export type FileGenerationStartedEventPayload = z.infer<
  typeof fileGenerationStartedEventPayloadSchema
>;

export const fileBlobUploadedEventPayloadSchema = z.object({
  taskId: z.string(),
  blobUrl: z.string(),
});

export type FileBlobUploadedEventPayload = z.infer<
  typeof fileBlobUploadedEventPayloadSchema
>;

export const fileGenerationFailedEventPayloadSchema = z.union([
  z.object({
    taskId: z.string(),
  }),
  z.string(),
]);

export type FileGenerationFailedEventPayload = z.infer<
  typeof fileGenerationFailedEventPayloadSchema
>;

export const fileGenerationStartedEventSchema =
  createFileGenerationWebSocketEventSchema(
    "fileGenerationStarted",
    fileGenerationStartedEventPayloadSchema
  );

export const fileBlobUploadedEventSchema =
  createFileGenerationWebSocketEventSchema(
    "fileBlobUploaded",
    fileBlobUploadedEventPayloadSchema
  );

export const fileGenerationFailedEventSchema =
  createFileGenerationWebSocketEventSchema(
    "fileGenerationFailed",
    fileGenerationFailedEventPayloadSchema
  );

export const fileGenerationWebSocketEventSchema = z.union([
  fileGenerationStartedEventSchema,
  fileBlobUploadedEventSchema,
  fileGenerationFailedEventSchema,
]);
