import React, { useCallback, useMemo, useState } from "react";
import cn from "classnames";
import { Shift } from "oneclick-component/src/store/apis/enhancedApi";
import { makeShiftDisplayInterval } from "oneclick-component/src/utils/shift";
import { IconButton } from "oneclick-component/src/components/Button";
import { ChevronDownIcon } from "oneclick-component/src/icon";
import {
  formatDateTime,
  isoToDateTime,
} from "oneclick-component/src/utils/datetime";
import { Trans, useTranslation } from "react-i18next";
import { HiredShiftRequestBaseWithNumbering } from "../../models/shiftRequest";

interface ExpandedPanelContentProps {
  shift: Shift;
  hiredRequests: HiredShiftRequestBaseWithNumbering[];
  className?: string;
}

const ExpandedPanelContent = (
  props: ExpandedPanelContentProps
): React.ReactElement => {
  const { t } = useTranslation();
  const { shift, hiredRequests } = props;
  const [_, { dateWithWeekDay, startTime, endTime, overnight }] =
    useMemo(() => {
      return makeShiftDisplayInterval(shift.dutyStartTime, shift.dutyEndTime);
    }, [shift]);

  return (
    <div className={"w-full"}>
      <div className={cn("grid", "grid-cols-3", "gap-x-3")}>
        <h6 className={cn("text-xs", "leading-5", "text-black/60")}>
          <Trans i18nKey="shiftDetail.detail.station" />
        </h6>
        <h6 className={cn("text-xs", "leading-5", "text-black/60")}>
          <Trans i18nKey="shiftDetail.detail.ratioOfHire" />
        </h6>
        <h6 className={cn("text-xs", "leading-5", "text-black/60")}>
          <Trans i18nKey="shiftDetail.detail.partTimeType" />
        </h6>
        <p className={cn("text-sm", "font-medium", "text-gray-900", "mt-1")}>
          {shift.workingStation.shortCode}
        </p>
        <p className={cn("text-sm", "font-medium", "text-gray-900", "mt-1")}>
          {`${hiredRequests.length}/${shift.fulfillmentCount}`}
        </p>
        <p className={cn("text-sm", "font-medium", "text-gray-900", "mt-1")}>
          {shift.ptUserRoles.map((role) => role.name).join(",")}
        </p>
        <h6 className={cn("text-xs", "leading-5", "text-black/60", "mt-5")}>
          <Trans i18nKey="shiftDetail.detail.date" />
        </h6>
        <h6 className={cn("text-xs", "leading-5", "text-black/60", "mt-5")}>
          <Trans i18nKey="shiftDetail.detail.startTime" />
        </h6>
        <h6 className={cn("text-xs", "leading-5", "text-black/60", "mt-5")}>
          <Trans i18nKey="shiftDetail.detail.endTime" />
        </h6>
        <p className={cn("text-sm", "font-medium", "text-gray-900", "mt-1")}>
          {dateWithWeekDay}
        </p>
        <p className={cn("text-sm", "font-medium", "text-gray-900", "mt-1")}>
          {startTime}
        </p>
        <p
          className={cn(
            "text-sm",
            "font-medium",
            "text-gray-900",
            "mt-1",
            "justify-self-start"
          )}
        >
          {endTime !== "" ? endTime : <Trans i18nKey="common.tbc" />}
          {overnight}
        </p>
      </div>
      <hr className="my-5" />
      <h6 className={cn("text-xs", "leading-5", "text-black/60")}>
        <Trans i18nKey="shiftDetail.detail.title" />
      </h6>
      <p className={cn("text-sm", "font-medium", "text-gray-900", "mt-1")}>
        {shift.shiftTitle}
      </p>
      <h6 className={cn("text-xs", "leading-5", "text-black/60", "mt-5")}>
        <Trans i18nKey="shiftDetail.detail.description" />
      </h6>
      <p
        className={cn(
          "text-sm",
          "font-medium",
          "text-gray-900",
          "mt-1",
          "whitespace-pre-wrap",
          "break-words"
        )}
      >
        {shift.shiftDescription}
      </p>
      <p
        className={cn(
          "text-center",
          "text-xs",
          "text-gray-300",
          "font-normal",
          "mt-5"
        )}
      >
        <Trans
          i18nKey="shiftDetail.detail.createdAt"
          values={{
            dateDisplay: `${formatDateTime(
              isoToDateTime(shift.createdAt),
              t("common.datetime.displayFormat")
            )}`,
          }}
        />
      </p>
    </div>
  );
};

interface ShiftDetailPanelProps {
  shift: Shift;
  hiredRequests: HiredShiftRequestBaseWithNumbering[];
}

const ShiftDetailPanel = (props: ShiftDetailPanelProps): React.ReactElement => {
  const { shift, hiredRequests } = props;
  const [shiftInterval] = makeShiftDisplayInterval(
    shift.dutyStartTime,
    shift.dutyEndTime
  );
  const subtitle = `${shiftInterval}`;
  const [isExpanded, setIsExpanded] = useState(false);
  const onClickPanel = useCallback(() => {
    setIsExpanded((value) => !value);
  }, []);
  return (
    <div
      className={cn(
        "border",
        "border-black/10",
        "border-solid",
        isExpanded ? "py-5" : "py-2",
        "h-full",
        "px-5",
        "rounded-lg",
        "flex",
        "justify-between",
        "transition-all",
        "relative",
        "overflow-hidden"
      )}
    >
      {!isExpanded ? (
        <div className={cn("cursor-pointer", "w-full")} onClick={onClickPanel}>
          <p
            className={cn(
              "text-sm",
              "leading-5",
              "font-normal",
              "text-black/90"
            )}
          >
            {subtitle}
          </p>
        </div>
      ) : (
        <ExpandedPanelContent shift={shift} hiredRequests={hiredRequests} />
      )}

      <IconButton
        className={cn(
          "w-5",
          "h-5",
          "right-5",
          "!p-0",
          "border-none",
          "!shadow-none",
          "absolute",
          "fill-gray-500",
          isExpanded && ["origin-center", "rotate-180"]
        )}
        theme="white"
        icon={ChevronDownIcon}
        onClick={onClickPanel}
      />
    </div>
  );
};

export default ShiftDetailPanel;
