import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { approxEqual } from "../utils/math";

type AudioPlayerType = "ogv" | "built-in";
type AudioPlayerStatus = "initial" | "loading" | "playing" | "error";

interface AudioPlayer {
  playerId: string;
  playerType: AudioPlayerType;
  src: string;
  duration: number | null;
  currentTime: number;
}

export interface AudioPlayerState {
  activePlayerId: string | null;
  playerStateMap: Partial<Record<string, AudioPlayer>>;
  ogvPlayerStatus: AudioPlayerStatus;
  builtInPlayerStatus: AudioPlayerStatus;
}

export interface PlayAudioPayload {
  playerId: string;
  playerType: AudioPlayerType;
  src: string;
}

export interface PlayingAudioPayload {
  playerType: AudioPlayerType;
}

export interface PauseAudioPayload {
  playerId: string;
}

export interface AudioLoadedPayload {
  playerType: AudioPlayerType;
  duration: number;
}

export interface AudioEndedPayload {
  playerType: AudioPlayerType;
}

export interface AudioPlayerErrorPayload {
  playerType: AudioPlayerType;
}

export interface ActivePlayerTimeUpdatedPayload {
  currentTime: number;
}

export interface PlayerTimeUpdatedPayload {
  playerId: string;
  currentTime: number;
}

function updatePlayerStatus(
  state: AudioPlayerState,
  playerType: AudioPlayerType,
  playerStatus: AudioPlayerStatus
): AudioPlayerState {
  return {
    ...state,
    ogvPlayerStatus:
      playerType === "ogv" ? playerStatus : state.ogvPlayerStatus,
    builtInPlayerStatus:
      playerType === "built-in" ? playerStatus : state.builtInPlayerStatus,
  };
}

function updatePlayerCurrentTime(
  state: AudioPlayerState,
  player: AudioPlayer,
  currentTime: number
): AudioPlayerState {
  return {
    ...state,
    playerStateMap: {
      ...state.playerStateMap,
      [player.playerId]: {
        ...player,
        currentTime,
      },
    },
  };
}

function updatePlayerDuration(
  state: AudioPlayerState,
  player: AudioPlayer,
  duration: number
): AudioPlayerState {
  return {
    ...state,
    playerStateMap: {
      ...state.playerStateMap,
      [player.playerId]: {
        ...player,
        duration,
      },
    },
  };
}

const initialState: AudioPlayerState = {
  activePlayerId: null,
  playerStateMap: {},
  ogvPlayerStatus: "initial",
  builtInPlayerStatus: "initial",
};

export const selectActiveAudioPlayer = (
  state: AudioPlayerState
): AudioPlayer | null => {
  const playerId = state.activePlayerId;
  const currentAudioPlayer =
    playerId != null ? state.playerStateMap[playerId] : null;
  return currentAudioPlayer ?? null;
};

const audioPlayerSlice = createSlice({
  name: "audioPlayerState",
  initialState,
  reducers: {
    playAudio: (state, action: PayloadAction<PlayAudioPayload>) => {
      const { playerId, playerType, src } = action.payload;
      const audioPlayer = state.playerStateMap[playerId];
      const updatedAudioPlayer: AudioPlayer =
        audioPlayer != null
          ? {
              ...audioPlayer,
              src,
              playerType,
            }
          : {
              playerId,
              playerType,
              src,
              duration: null,
              currentTime: 0,
            };

      const updatedState: AudioPlayerState = {
        ...state,
        activePlayerId: playerId,
        playerStateMap: {
          ...state.playerStateMap,
          [playerId]: updatedAudioPlayer,
        },
      };
      return updatePlayerStatus(updatedState, playerType, "loading");
    },
    playingAudio: (state, action: PayloadAction<PlayingAudioPayload>) => {
      const { playerType } = action.payload;
      return updatePlayerStatus(state, playerType, "playing");
    },
    pauseAudio: (state, action: PayloadAction<PauseAudioPayload>) => {
      const { playerId } = action.payload;
      const existingAudioPlayer = state.playerStateMap[playerId];
      if (existingAudioPlayer == null) {
        return state;
      }

      return updatePlayerStatus(
        state,
        existingAudioPlayer.playerType,
        "initial"
      );
    },
    audioLoaded: (state, action: PayloadAction<AudioLoadedPayload>) => {
      const { duration } = action.payload;
      const playerId = state.activePlayerId;
      const activeAudioPlayer =
        playerId != null ? state.playerStateMap[playerId] : null;
      if (playerId == null || activeAudioPlayer == null) {
        return;
      }
      return updatePlayerDuration(state, activeAudioPlayer, duration);
    },
    audioEnded: (state, action: PayloadAction<AudioEndedPayload>) => {
      const { playerType } = action.payload;
      const activeAudioPlayer = selectActiveAudioPlayer(state);
      let updatedState;
      if (
        activeAudioPlayer?.duration != null &&
        activeAudioPlayer.duration > 0
      ) {
        updatedState = updatePlayerCurrentTime(
          state,
          activeAudioPlayer,
          activeAudioPlayer.duration
        );
      } else {
        updatedState = state;
      }
      return updatePlayerStatus(updatedState, playerType, "initial");
    },
    audioPlayerError: (
      state,
      action: PayloadAction<AudioPlayerErrorPayload>
    ) => {
      const { playerType } = action.payload;
      return updatePlayerStatus(state, playerType, "error");
    },
    activePlayerTimeUpdated: (
      state,
      action: PayloadAction<ActivePlayerTimeUpdatedPayload>
    ) => {
      const { currentTime } = action.payload;
      const playerId = state.activePlayerId;
      const activeAudioPlayer =
        playerId != null ? state.playerStateMap[playerId] : null;
      if (playerId == null || activeAudioPlayer == null) {
        return state;
      }
      return updatePlayerCurrentTime(state, activeAudioPlayer, currentTime);
    },
    playerTimeUpdated: (
      state,
      action: PayloadAction<PlayerTimeUpdatedPayload>
    ) => {
      const { playerId, currentTime } = action.payload;
      const audioPlayer = state.playerStateMap[playerId];
      if (audioPlayer == null) {
        return state;
      }
      return updatePlayerCurrentTime(state, audioPlayer, currentTime);
    },
  },
});

interface CurrentAudioPlayerState {
  isActive: boolean;
  isEnded: boolean;
  isPlaying: boolean;
  isLoading: boolean;
  isError: boolean;
  currentTime: number | null;
  duration: number | null;
  remainingTime: number | null;
}

export const selectAudioPlayerStatus = (
  state: AudioPlayerState,
  audioPlayer: AudioPlayer | undefined
): AudioPlayerStatus => {
  if (audioPlayer == null) {
    return "initial";
  }
  switch (audioPlayer.playerType) {
    case "ogv":
      return state.ogvPlayerStatus;
    case "built-in":
      return state.builtInPlayerStatus;
  }
};

const getRemainTime = (currentTime: number | null, duration: number | null) => {
  if (duration == null || currentTime == null) {
    return null;
  }
  return Math.max(duration - currentTime, 0);
};

export const selectAudioPlayerState = (
  state: AudioPlayerState,
  playerId: string
): CurrentAudioPlayerState => {
  const isActive = state.activePlayerId === playerId;
  const audioPlayer = state.playerStateMap[playerId];
  const status = selectAudioPlayerStatus(state, audioPlayer);

  const currentTime = audioPlayer?.currentTime ?? null;
  const duration = audioPlayer?.duration ?? null;
  const isEnded =
    currentTime != null &&
    duration != null &&
    approxEqual(currentTime, duration, 0.01);

  return {
    isActive,
    isEnded,
    isPlaying: isActive && status === "playing",
    isLoading: isActive && status === "loading",
    isError: isActive && status === "error",
    currentTime,
    duration,
    remainingTime: getRemainTime(currentTime, duration),
  };
};

export const {
  playAudio,
  playingAudio,
  pauseAudio,
  audioLoaded,
  audioEnded,
  audioPlayerError,
  activePlayerTimeUpdated,
  playerTimeUpdated,
} = audioPlayerSlice.actions;

export default audioPlayerSlice.reducer;
