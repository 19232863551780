import { ReactElement, useCallback } from "react";
import cn from "classnames";

import { Trans } from "react-i18next";
import { Button } from "oneclick-component/src/components/Button";
import { ErrorScreenLogo } from "../../image";

const GenericErrorScreen = (): ReactElement => {
  const onRetry = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <main
      className={cn(
        "bg-white",
        "w-full",
        "h-full",
        "flex",
        "items-center",
        "justify-center",
        "py-5"
      )}
    >
      <div className={cn("px-4", "text-center")}>
        <ErrorScreenLogo className={cn("w-36", "h-36", "mx-auto", "mb-9.5")} />
        <span className={cn("block", "mb-3", "text-lg", "text-black")}>
          <Trans i18nKey="errorScreen.title" />
        </span>
        <span
          className={cn(
            "block",
            "mb-3",
            "text-sm",
            "text-black/60",
            "font-medium"
          )}
        >
          <Trans i18nKey="errorScreen.desc" />
        </span>
        <Button onClick={onRetry} className="block">
          <Trans i18nKey="errorScreen.retryButton.text" />
        </Button>
      </div>
    </main>
  );
};

export default GenericErrorScreen;
