import cn from "classnames";

export const themes = {
  primary: {
    buttonThemeClassName: cn(
      "bg-primary-600",
      "hover:bg-primary-700",
      "focus:ring-offset-white",
      "focus:ring-indigo-500",
      "text-white",
      "disabled:bg-black/25"
    ),
    iconThemeClassName: cn("fill-white"),
  },
  secondary: {
    buttonThemeClassName: cn(
      "bg-primary-100",
      "hover:bg-primary-200",
      "focus:ring-offset-white",
      "focus:ring-indigo-500",
      "text-primary-600"
    ),
    iconThemeClassName: cn("fill-primary-600"),
  },
  white: {
    buttonThemeClassName: cn(
      "bg-white",
      "hover:bg-gray-50",
      "focus:ring-offset-white",
      "focus:ring-indigo-500",
      "text-gray-700",
      "border",
      "border-gray-300",
      "group",
      "disabled:border-gray-300/40",
      "disabled:hover:bg-white",
      "disabled:text-gray-700/40"
    ),
    iconThemeClassName: cn("fill-gray-500", "group-disabled:!fill-gray-500/40"),
  },
  whitePrimary: {
    buttonThemeClassName: cn(
      "bg-white",
      "hover:bg-gray-50",
      "focus:ring-offset-white",
      "focus:ring-indigo-500",
      "text-primary-600",
      "border",
      "border-gray-300"
    ),
    iconThemeClassName: cn("fill-primary-600"),
  },
  gray: {
    buttonThemeClassName: cn(
      "bg-gray-100",
      "hover:bg-gray-200",
      "focus:ring-offset-white",
      "focus:ring-primary-500",
      "text-black/60"
    ),
    iconThemeClassName: cn("fill-black/60"),
  },
  red: {
    buttonThemeClassName: cn(
      "bg-red-600",
      "hover:bg-red-700",
      "focus:ring-offset-white",
      "focus:ring-indigo-500",
      "text-white",
      "disabled:bg-red-600/25"
    ),
    iconThemeClassName: cn("fill-white"),
  },
  transparentPrimary: {
    buttonThemeClassName: cn(
      "!shadow-none",
      "bg-transparent",
      "focus:ring-offset-white",
      "focus:ring-indigo-500",
      "text-primary-600"
    ),
    iconThemeClassName: cn("fill-primary-600", "group-disabled:fill-gray-400"),
  },
  whiteNoFillBlackTextThick: {
    buttonThemeClassName: cn(
      "bg-white",
      "hover:bg-gray-50",
      "focus:ring-offset-white",
      "focus:ring-indigo-500",
      "text-black/[0.86]",
      "border",
      "border-gray-300",
      "disabled:opacity-40"
    ),
    iconThemeClassName: cn("text-black/[0.86]", "stroke-2"),
  },
  whiteBgRedText: {
    buttonThemeClassName: cn(
      "bg-white",
      "hover:bg-gray-50",
      "focus:ring-offset-white",
      "focus:ring-indigo-500",
      "text-red-600",
      "border",
      "border-gray-300",
      "disabled:bg-black/[0.24]",
      "disabled:text-white"
    ),
    iconThemeClassName: cn("fill-white", "stroke-2"),
  },
};

export type Theme = keyof typeof themes;
