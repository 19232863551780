import React, { PropsWithChildren, Fragment, useCallback } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import cn from "classnames";

export interface ModalProps {
  onClose: () => void;
  isOpen: boolean;
  hasXMarkButton?: boolean;
  shouldCloseOnClickBackground?: boolean;
  className?: string;
}

export function Modal(
  props: PropsWithChildren<ModalProps>
): React.ReactElement {
  const {
    onClose,
    isOpen,
    children,
    className,
    hasXMarkButton = true,
    shouldCloseOnClickBackground = true,
  } = props;
  const onDialogClose = useCallback(() => {
    if (!shouldCloseOnClickBackground) return;
    onClose();
  }, [onClose, shouldCloseOnClickBackground]);
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={cn("relative", "z-50")}
        onClose={onDialogClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={cn(
              "fixed",
              "inset-0",
              "bg-gray-500/40",
              "transition-opacity"
            )}
          />
        </Transition.Child>
        <div className={cn("fixed", "inset-0", "z-10", "overflow-y-auto")}>
          <div
            className={cn(
              "flex",
              "w-full",
              "min-h-full",
              "justify-center",
              "items-center",
              "p-4",
              "text-center",
              "sm:p-0"
            )}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={cn(
                  "relative",
                  "rounded-lg",
                  "bg-white",
                  "p-4",
                  "sm:p-6",
                  "text-left",
                  "shadow-xl",
                  "transition-all",
                  "w-full",
                  "min-w-[90%]",
                  "sm:min-w-0",
                  "sm:my-8",
                  "sm:w-full",
                  "sm:max-w-lg",
                  className
                )}
              >
                {hasXMarkButton ? (
                  <div
                    className={cn(
                      "absolute",
                      "right-0",
                      "top-0",
                      "pr-4",
                      "pt-4",
                      "sm:block"
                    )}
                  >
                    <button
                      type="button"
                      className={cn(
                        "rounded-md",
                        "bg-white",
                        "text-gray-400",
                        "hover:text-gray-500",
                        "focus:outline-none",
                        "focus:ring-2",
                        "focus:ring-indigo-500",
                        "focus:ring-offset-2"
                      )}
                      onClick={onClose}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon
                        className={cn("h-6", "w-6")}
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                ) : null}

                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
