import { ReactElement } from "react";
import { Select, SelectProps } from "../inputs";
import { FieldValues, FieldPath } from "react-hook-form";
import {
  FormField,
  FormFieldControlProps,
  FormFieldContainerProps,
} from "./FormField";

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  T = string,
> extends Omit<
      SelectProps<T>,
      keyof FormFieldContainerProps | keyof FormFieldControlProps | "onChange"
    >,
    Omit<FormFieldControlProps<TFieldValues, TName>, "children">,
    Omit<FormFieldContainerProps, "children"> {}

export function FormSelect<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  T = string,
>(props: Props<TFieldValues, TName, T>): ReactElement {
  const {
    label,
    errorMessage,
    required,
    className,
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control,
    ...rest
  } = props;

  return (
    <FormField.Control
      name={name}
      rules={rules}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      control={control}
    >
      {({ field }) => (
        <FormField.Container
          label={label}
          errorMessage={errorMessage}
          required={required}
          className={className}
        >
          <Select {...rest} {...field} hasError={errorMessage != null} />
        </FormField.Container>
      )}
    </FormField.Control>
  );
}
