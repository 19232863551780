import React, { PropsWithChildren, ReactElement, useMemo } from "react";
import {
  AdminAppConfig,
  useListAdminAppConfigHandlerAdminAppConfigGetQuery,
} from "oneclick-component/src/store/apis/enhancedApi";
import LoadingScreen from "oneclick-component/src/components/LoadingScreen";

interface FeatureConfigContextValue extends AdminAppConfig {}

const FALLBACK_FEATURE_CONFIG_CONTEXT_VALUE: FeatureConfigContextValue = {
  shouldShowGreen418Badge: false,
  allStationsFilterEnabledStations: "",
  shouldShowIncidentFeature: false,
  shouldShowT3Feature: false,
  enableCcoRole: false,
};

export const FeatureConfigContext =
  React.createContext<FeatureConfigContextValue>(null as any);

const FeatureConfigProvider = (props: PropsWithChildren): ReactElement => {
  const { data, isLoading } =
    useListAdminAppConfigHandlerAdminAppConfigGetQuery();

  const contextValue = useMemo(() => {
    if (data == null) {
      return FALLBACK_FEATURE_CONFIG_CONTEXT_VALUE;
    }
    return { ...data };
  }, [data]);

  if (isLoading) {
    return <LoadingScreen />;
  }
  return (
    <FeatureConfigContext.Provider value={contextValue}>
      {props.children}
    </FeatureConfigContext.Provider>
  );
};

export default FeatureConfigProvider;
