import * as z from "zod";

import { messageSchema } from "./message";
import { briefStationWithWorkingStationSchema } from "./station";
import { ptuserSchema } from "./user";
import { Chat, Message } from "oneclick-component/src/store/apis/enhancedApi";

export const chatSchema = z.object({
  id: z.number(),
  lastInboundMessageTimestamp: z.string().nullable(),
  lastMessage: messageSchema.nullable(),
  ptUser: ptuserSchema,
  station: briefStationWithWorkingStationSchema,
  partnerStation: briefStationWithWorkingStationSchema.nullable(),
});

export function sortChat(list: Chat[]): Chat[] {
  return list.sort((a, b) => {
    // Empty conversation without last message is not expected
    // Added to ensure unique sort
    if (a.lastMessage == null && b.lastMessage != null) {
      return -1;
    }
    if (a.lastMessage != null && b.lastMessage == null) {
      return 1;
    }
    if (a.lastMessage == null && b.lastMessage == null) {
      return a.id < b.id ? 1 : -1;
    }
    return new Date(a.lastMessage!.createdAt) <
      new Date(b.lastMessage!.createdAt)
      ? 1
      : -1;
  });
}

export function sortMessages(list: Message[]): Message[] {
  return list.sort((a, b) => {
    return new Date(a.createdAt) < new Date(b.createdAt) ? -1 : 1;
  });
}

export function updateChatByNewMessage(chat: Chat, message: Message): Chat {
  // Only update lastInboundMessageTimestamp if message
  // is sent by PT user (last inbound WhatsApp message)
  if (message.ptUser != null) {
    return {
      ...chat,
      lastMessage: message,
      lastInboundMessageTimestamp: message.createdAt,
    };
  }
  return {
    ...chat,
    lastMessage: message,
  };
}
