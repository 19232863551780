import { ChangeEvent, ReactElement, useCallback, useMemo } from "react";
import { DateTime } from "luxon";
import { Trans } from "react-i18next";
import cn from "classnames";
import {
  Control,
  FieldErrors,
  useFieldArray,
  FieldError,
} from "react-hook-form";
import { FormField } from "oneclick-component/src/components/forms";
import { Button } from "oneclick-component/src/components/Button";
import { Calendar } from "oneclick-component/src/components/inputs";
import DutyTimeSelect from "./DutyTimeSelect";
import { BadgeButton } from "oneclick-component/src/components/inputs/ComboBox";
import { PlusCircleIcon } from "../../../icon";
import { MultiShiftFormValues } from "../form";
import {
  DEFAULT_SHIFT_START_TIME_HOUR,
  DEFAULT_SHIFT_START_TIME_MINUTE,
} from "../../../constants/shifts/create";

interface SelectedDateProps {
  index: number;
  onRemove: (index: number) => void;
  name: string;
}

const SelectedDateOption = (props: SelectedDateProps) => {
  const { index, onRemove, name } = props;
  const onRemoveClick = useCallback(() => {
    onRemove(index);
  }, [index, onRemove]);
  return (
    <BadgeButton
      key={index}
      className={cn("mr-2", "mb-1", "text-gray-400")}
      name={name}
      displayAbbrev={name}
      remove={onRemoveClick}
    />
  );
};

interface Props {
  control: Control<MultiShiftFormValues>;
  errors: FieldErrors<MultiShiftFormValues>;
  isEditing: boolean;
  now: DateTime;
  setTimeRangeStartMinuteByIndex: (index: number, value: number) => void;
  setTimeRangeEndMinuteByIndex: (index: number, value: number | null) => void;
}

const CustomDateSection = (props: Props): ReactElement => {
  const {
    errors,
    control,
    isEditing,
    now,
    setTimeRangeStartMinuteByIndex,
    setTimeRangeEndMinuteByIndex,
  } = props;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "timeRanges",
  });

  const {
    fields: dateFields,
    append: appendDate,
    remove: removeDate,
  } = useFieldArray({
    control,
    name: "recurringOptions.dates",
  });

  const onAddTimeRange = useCallback(() => {
    append({
      startTime: {
        hour: DEFAULT_SHIFT_START_TIME_HOUR,
        minute: DEFAULT_SHIFT_START_TIME_MINUTE,
      },
      endTime: {
        hour: null,
        minute: null,
      },
    });
  }, [append]);

  const onRemoveTimeRange = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove]
  );

  const removeSelectedDay = useCallback(
    (index: number) => {
      removeDate(index);
    },
    [removeDate]
  );

  const addSelectDay = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const existingDate = dateFields.filter(
        (df) => df.date === e.target.value
      );

      if (existingDate.length === 0) {
        appendDate({ date: e.target.value });
      }
    },
    [appendDate, dateFields]
  );

  const datesError: FieldError | undefined = useMemo(() => {
    if (errors.recurringOptions != null && "dates" in errors.recurringOptions) {
      return errors.recurringOptions.dates as FieldError;
    }
  }, [errors.recurringOptions]);

  const makeSetTimeRangeStartMinute = useCallback(
    (index: number) => (value: number) => {
      setTimeRangeStartMinuteByIndex(index, value);
    },
    [setTimeRangeStartMinuteByIndex]
  );

  const makeSetTimeRangeEndMinute = useCallback(
    (index: number) => (value: number | null) => {
      setTimeRangeEndMinuteByIndex(index, value);
    },
    [setTimeRangeEndMinuteByIndex]
  );

  return (
    <>
      <div className={cn("flex", "gap-x-5")}>
        <div className={cn("flex-1", "mb-5")}>
          <p className={cn("text-sm", "mb-2")}>
            <Trans i18nKey="shift.create.form.date" />
          </p>
          <Calendar
            disabled={isEditing}
            onChange={addSelectDay}
            min={now.toFormat("yyyy-LL-dd")}
            formattedValue="選擇"
            hasError={datesError?.message != null}
          />
          <FormField.Container errorMessage={datesError?.message} />
        </div>
        <div className="flex-1" />
      </div>
      <div className={cn("mb-5", "flex", "flex-row", "flex-wrap", "gap-1")}>
        {dateFields.map((item, index) => (
          <FormField.Control
            key={item.id}
            name={`recurringOptions.dates.${index}`}
            control={control}
          >
            {({ field }) => (
              <SelectedDateOption
                name={field.value.date}
                onRemove={removeSelectedDay}
                index={index}
              />
            )}
          </FormField.Control>
        ))}
      </div>
      <div className={cn("mb-3", "flex", "flex-col", "gap-y-5")}>
        {fields.map((item, index) => (
          <DutyTimeSelect
            key={item.id}
            index={index}
            canRemove={fields.length > 1}
            onRemove={onRemoveTimeRange}
            errors={errors}
            control={control}
            isEditing={isEditing}
            setStartMinute={makeSetTimeRangeStartMinute(index)}
            setEndMinute={makeSetTimeRangeEndMinute(index)}
          />
        ))}
        <FormField.Container errorMessage={errors.timeRanges?.message} />
      </div>
      <div className="mb-3">
        <Button
          onClick={onAddTimeRange}
          prefixIcon={PlusCircleIcon}
          theme="transparentPrimary"
          className={cn("pl-0", "pr-0")}
          iconClassName="!fill-none"
        >
          <Trans i18nKey="shift.create.multiple.addTimeRange" />
        </Button>
      </div>
    </>
  );
};

export default CustomDateSection;
