import React, { useCallback, useState } from "react";
import cn from "classnames";
import LoadingScreen from "oneclick-component/src/components/LoadingScreen";
import { useListBorrowingRequestsHandlerShiftsBorrowingShiftRequestsGetQuery as useListBorrowingRequests } from "oneclick-component/src/store/apis/enhancedApi";
import { t } from "i18next";
import { Checkbox } from "oneclick-component/src/components/inputs";
import { ListEmptyView } from "../../components/EmptyView";
import HandledNotificationRow from "./HandledNotificationRow";
import Pagination from "oneclick-component/src/components/Pagination/Pagination";
import { useBreakPoints } from "oneclick-component/src/providers";
import { DEFAULT_PAGE_SIZE } from "../../constants/pagination";
import { useSelectedStationProfile } from "../../hooks/useSelectedStationProfile";

const HandledNotificationTab = (): React.ReactElement => {
  const selectedStationProfile = useSelectedStationProfile();
  const { useIsSm } = useBreakPoints();
  const isSm = useIsSm();
  const [pageIndex, setPageIndex] = useState(0);
  const [isCheckApprovedOnly, setIsCheckApprovedOnly] = useState(false);
  const handleOnCheck = useCallback(() => {
    setIsCheckApprovedOnly(!isCheckApprovedOnly);
    setPageIndex(0);
  }, [isCheckApprovedOnly]);

  const pageSize = DEFAULT_PAGE_SIZE;
  const {
    currentData,
    data: cachedData,
    isFetching,
  } = useListBorrowingRequests({
    stationId: selectedStationProfile?.station.id,
    borrowingStatus: isCheckApprovedOnly
      ? ["approved"]
      : ["approved", "rejected"],
    pageIndex,
    sort: "updated_at-",
    pageSize,
  });

  const handleOnClickPage = useCallback((pageIndex: number) => {
    setPageIndex(pageIndex);
    window.scrollTo({ top: 0 });
  }, []);

  const handledNotifications = currentData?.results ?? [];

  if (isFetching) {
    return <LoadingScreen />;
  }

  return handledNotifications.length === 0 ? (
    <ListEmptyView description={t("notification.emptyState")} />
  ) : (
    <section>
      <Checkbox
        name="approvedOnly"
        className={cn("mb-3", "text-gray-700")}
        label={t("notification.tab.handled.checkbox.approvedOnly")}
        checked={isCheckApprovedOnly}
        onChange={handleOnCheck}
      />
      <div className={"space-y-5"}>
        {handledNotifications.map((request, index) => {
          return (
            <HandledNotificationRow
              key={index}
              shiftRequest={request}
            ></HandledNotificationRow>
          );
        })}
      </div>
      <Pagination
        className={cn(
          "mt-3",
          "bg-white",
          !isSm && ["fixed", "bottom-0", "inset-x-0"]
        )}
        totalPages={Math.ceil((cachedData?.totalCount ?? 0) / pageSize)}
        pageIndex={pageIndex}
        onClickPage={handleOnClickPage}
      />
    </section>
  );
};

export default HandledNotificationTab;
