import { ButtonHTMLAttributes, ReactElement } from "react";
import cn from "classnames";

interface MonthButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  active?: boolean;
}
export const MonthButton = (props: MonthButtonProps): ReactElement => {
  const { children, className, active = false, disabled, ...rest } = props;
  return (
    <button
      type="button"
      className={cn(
        "inline-flex",
        "rounded-md",
        "p-3",
        "font-medium",
        "text-sm",
        "justify-center",
        "focus:ring-2",
        "focus:ring-offset-0",
        "ring-inset",
        "focus:outline-none",
        active ? ["bg-primary-600", "text-white"] : ["hover:bg-gray-50"],
        className
      )}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
};
