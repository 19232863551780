import {
  IncidentShiftAccessControl,
  Shift,
} from "oneclick-component/src/store/apis/enhancedApi";
import { useMemo } from "react";

const FULL_SHIFT_ACCESS_CONTROL = {
  canView: true,
  canEdit: true,
  canCancel: true,
  canClose: false,
  canCancelHiredPt: true,
  canConfirmAppliedPt: true,
  canDirectHireOwnStationPt: true,
  canChatWithHiredPt: true,
};

const NONE_SHIFT_ACCESS_CONTROL = {
  canView: false,
  canEdit: false,
  canCancel: false,
  canClose: false,
  canCancelHiredPt: false,
  canConfirmAppliedPt: false,
  canDirectHireOwnStationPt: false,
  canChatWithHiredPt: false,
};
const useShiftAccessControl = (shift?: Shift): IncidentShiftAccessControl => {
  return useMemo(() => {
    if (shift == null || !shift.isIncident) {
      return FULL_SHIFT_ACCESS_CONTROL;
    }

    const apiIncidentAccessControl = shift.incidentAccessControl;

    if (apiIncidentAccessControl == null) {
      return NONE_SHIFT_ACCESS_CONTROL;
    }

    return apiIncidentAccessControl;
  }, [shift]);
};

export default useShiftAccessControl;
