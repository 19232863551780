import React, {
  ReactElement,
  useId,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from "react";
import cn from "classnames";
import { useNavigate } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, ButtonLink } from "oneclick-component/src/components/Button";
import {
  FormField,
  FormSelect,
  FormTextInput,
} from "oneclick-component/src/components/forms";
import {
  Option,
  SelectDecorator,
  Checkbox,
} from "oneclick-component/src/components/inputs";
import {
  useGetPtUsersRolesHandlerPartTimeUsersRolesGetQuery,
  useCreatePtUserHandlerPartTimeUsersPostMutation as useCreatePartTimeUser,
  useListStationTeamHandlerStationTeamsGetQuery as useListStationTeam,
  useLazyCheckEidHandlerPartTimeUsersCheckEidGetQuery as useCheckEid,
} from "oneclick-component/src/store/apis/enhancedApi";
import { transformNumberOnly } from "oneclick-component/src/utils/string";
import useShowMessage from "oneclick-component/src/hooks/useShowMessage";
import { Trans, useTranslation } from "react-i18next";
import { CreatePartTimeForm, createPartTimeFormSchema } from "./form";
import AppRoutes from "../../routes/AppRoutes";
import { CreatePTUserAPIValidationError } from "../../models/error";
import {
  COUNTRY_CODE_NUM_DIGITS,
  countryCodeOptions,
} from "../../constants/countryCode";
import { PTUserFormWeekCardList } from "../../components/PTUserFormWeekCard";
import { FIXED_EID_LENGTH } from "../../constants/partTimeUserEid";
import { useShowError } from "../../hooks/useShowError";
import {
  LazyLoadStationSelectionDropdown,
  EagerLoadStationSelectionDropdown,
} from "../../components/StationSelectionDropdown";
import { useIsManager, useIsSuperAdmin } from "../../hooks/role";
import { useSelectedStationProfile } from "../../hooks/useSelectedStationProfile";
import { OneClickCustomError } from "oneclick-component/src/models/error";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import useMeUser from "../../hooks/useMeUser";

const CreatePartTimeScreen = (): ReactElement => {
  const { t } = useTranslation();
  const meUser = useMeUser();
  const [checkEid] = useCheckEid();
  const isSuperAdmin = useIsSuperAdmin();
  const isManager = useIsManager();
  const selectedStationProfile = useSelectedStationProfile();
  const navigate = useNavigate();
  const { showError } = useShowError();
  const showMessage = useShowMessage();
  const [create] = useCreatePartTimeUser();

  const {
    data: ptUserRoles,
    isLoading: isPtUserRolesLoading,
    error: loadPtUserRolesError,
  } = useGetPtUsersRolesHandlerPartTimeUsersRolesGetQuery();
  const {
    data: stationTeamsData,
    isLoading: isStationTeamsLoading,
    error: loadStationTeamsError,
  } = useListStationTeam();

  useEffect(() => {
    if (loadPtUserRolesError != null) {
      showError(loadPtUserRolesError);
    }
    if (loadStationTeamsError != null) {
      showError(loadStationTeamsError);
    }
  }, [loadPtUserRolesError, loadStationTeamsError, showError]);

  const partTimeRoleOptions: Option<number>[] = useMemo(() => {
    if (ptUserRoles == null) {
      return [];
    }
    return ptUserRoles.ptUserRoles.map((role) => ({
      name: role.name,
      value: role.id,
    }));
  }, [ptUserRoles]);

  const defaultValues = useMemo(() => {
    return {
      firstNameEn: "",
      lastNameEn: "",
      fullNameZhHk: "",
      eid: "",
      email: "",
      phoneNumber: "",
      countryCode: countryCodeOptions[0].value,
      regularShiftSchedule: [],
      role:
        partTimeRoleOptions.length > 0
          ? partTimeRoleOptions[0].value
          : undefined,
      stationId: selectedStationProfile?.station.id,
      receiveInviteOnHoliday: false,
    };
  }, [partTimeRoleOptions, selectedStationProfile]);

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid },
    setValue,
    setError,
    clearErrors,
  } = useForm<CreatePartTimeForm>({
    resolver: zodResolver(createPartTimeFormSchema),
    defaultValues: defaultValues,
  });

  // Sync pre-selected station with user station profile
  // TODO: confirm behvaiour of this screen
  useEffect(() => {
    if (selectedStationProfile != null) {
      setValue("stationId", selectedStationProfile.station.id);
    }
  }, [selectedStationProfile, setValue]);

  const formValues = watch();
  const countryCode = useWatch({ control, name: "countryCode" });
  const eid = useWatch({ control, name: "eid" });

  const isCCORole = useMemo(() => {
    const selectedRole = partTimeRoleOptions.find(
      (role) => role.value === formValues.role
    );

    if (selectedRole == null) {
      return false;
    }
    return selectedRole.name === "CCO";
  }, [partTimeRoleOptions, formValues]);

  const stationTeamOptions: Option<number | null>[] = useMemo(() => {
    if (
      stationTeamsData == null ||
      (formValues.stationId as number | null) == null
    ) {
      return [];
    }
    const nullOption = {
      name: t("partTime.create.form.stationTeam.options.noTeam.text"),
      value: null,
    };
    const teamOptions = stationTeamsData.stationTeams
      .filter((st) => st.stationId === formValues.stationId)
      .map((st) => ({
        name: t("partTime.create.form.stationTeam.options.text", {
          code: st.teamCode,
        }),
        value: st.id,
      }));
    if (teamOptions.length === 0) {
      return [];
    }
    return [nullOption, ...teamOptions];
  }, [stationTeamsData, formValues.stationId, t]);

  useEffect(() => {
    if (stationTeamOptions.length > 0) {
      setValue("stationTeamId", stationTeamOptions[0].value);
    } else {
      setValue("stationTeamId", undefined);
    }
  }, [stationTeamOptions, setValue]);

  const checkEidExist = useCallback(() => {
    if (eid.length === 6) {
      checkEid({
        eid: eid,
      })
        .then((result) => {
          if (result.data?.exist) {
            setError("eid", {
              message: t("partTime.create.form.error.existingEidOtherStation", {
                stationCode: result.data.stationCode,
              }),
            });
          } else {
            clearErrors("eid");
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [setError, eid, checkEid, t, clearErrors]);

  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] =
    useState<boolean>(false);
  const _onSubmit = useCallback(
    (data: CreatePartTimeForm) => {
      // Confirm unrelated value dont send for CCO role
      if (isCCORole) {
        data.regularShiftSchedule = [];
        data.receiveInviteOnHoliday = false;
      }
      setIsSubmitButtonDisabled(true);
      create({
        createPtUserRequest: {
          ...data,
          firstNameEn: data.firstNameEn.trim(),
          lastNameEn: data.lastNameEn.trim(),
          fullNameZhHk: data.fullNameZhHk.trim(),
          roleId: data.role,
          startWorkDate: new Date().toISOString(),
          stationId: data.stationId,
        },
      })
        .unwrap()
        .then((resp) => {
          navigate(AppRoutes.PartTimeListScreen.render());
          const displayName = resp.ptUser.fullNameZhHk;
          showMessage({
            title: t("partTime.create.toast.success.title"),
            message: t("partTime.create.toast.success.message", {
              displayName,
            }),
            type: "success",
            showDismiss: true,
          });
        })
        .catch((err) => {
          const errorDescription: string = err?.data?.detail?.description ?? "";

          if (
            errorDescription.includes(
              CreatePTUserAPIValidationError.DuplicatePhoneNumber
            )
          ) {
            setError("phoneNumber", {
              message: t("partTime.create.form.error.duplicatePhoneNumber"),
            });
          } else if (
            errorDescription.includes(
              CreatePTUserAPIValidationError.DuplicateEid
            )
          ) {
            checkEidExist();
          } else if (
            errorDescription.includes(
              CreatePTUserAPIValidationError.IncorrectPhoneNumberFormat
            )
          ) {
            setError("phoneNumber", {
              message: t(
                "partTime.create.form.error.incorrectPhoneNumberFormat"
              ),
            });
          } else if (
            errorDescription.includes(
              CreatePTUserAPIValidationError.NonWhitelistedPhoneNumber
            )
          ) {
            showError(
              new OneClickCustomError(
                t(
                  "partTime.create.toast.fail.nonWhitelistedPhoneNumber.message"
                )
              ),
              "partTime.create.toast.fail.title"
            );
          } else {
            showError(err, "partTime.create.toast.fail.title");
          }
        })
        .finally(() => {
          setIsSubmitButtonDisabled(false);
        });
    },
    [
      create,
      setIsSubmitButtonDisabled,
      navigate,
      showMessage,
      t,
      setError,
      showError,
      checkEidExist,
      isCCORole,
    ]
  );

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      handleSubmit(_onSubmit)(e).catch((err: unknown) => {
        throw err;
      });
      e.stopPropagation();
    },
    [handleSubmit, _onSubmit]
  );

  const formId = useId();

  const maxPhoneNumberLength = useMemo(
    () => COUNTRY_CODE_NUM_DIGITS[countryCode],
    [countryCode]
  );

  const isLoading = isPtUserRolesLoading || isStationTeamsLoading;
  if (isLoading) {
    return (
      <main
        className={cn(
          "bg-white",
          "pb-20",
          "w-full",
          "flex",
          "items-center",
          "justify-center"
        )}
      >
        <LoadingSpinner size="l" />
      </main>
    );
  }

  if (ptUserRoles == null || stationTeamsData == null) {
    return <></>;
  }

  return (
    <main>
      <div
        className={cn(
          "overflow-hidden",
          "rounded-lg",
          "bg-white",
          "shadow",
          "lg:w-2/3",
          "mx-auto",
          "pb-20"
        )}
      >
        <p className={cn("ml-6", "mt-6", "text-lg", "font-medium")}>
          <Trans i18nKey="partTime.create.title" />
        </p>
        <div className="p-6">
          <form
            id={formId}
            onSubmit={onSubmit}
            className={cn(
              "grid",
              "grid-cols-1",
              "gap-y-5",
              "sm:grid-cols-2",
              "sm:gap-5"
            )}
          >
            <FormTextInput
              name="firstNameEn"
              control={control}
              label={t("partTime.create.form.firstNameEn")}
              required={true}
              errorMessage={errors.firstNameEn?.message}
            />
            <FormTextInput
              name="lastNameEn"
              control={control}
              label={t("partTime.create.form.lastNameEn")}
              required={true}
              errorMessage={errors.lastNameEn?.message}
            />
            <FormTextInput
              name="eid"
              pattern="[0-9]*"
              control={control}
              transform={transformNumberOnly}
              label={t("partTime.create.form.eid")}
              required={true}
              errorMessage={errors.eid?.message}
              maxLength={FIXED_EID_LENGTH}
              onBlur={checkEidExist}
            />
            <FormTextInput
              name="fullNameZhHk"
              control={control}
              label={t("partTime.create.form.fullNameZhHk")}
              required={true}
              errorMessage={errors.fullNameZhHk?.message}
            />
            <FormTextInput
              name="phoneNumber"
              pattern="[0-9]*"
              control={control}
              label={t("partTime.create.form.phoneNumber")}
              transform={transformNumberOnly}
              required={true}
              errorMessage={errors.phoneNumber?.message}
              maxLength={maxPhoneNumberLength}
              prefixDecorator={
                <FormField.Control name="countryCode" control={control}>
                  {({ field }) => (
                    <SelectDecorator
                      options={countryCodeOptions}
                      className={cn("-ml-3", "mr-1", "w-28")}
                      {...field}
                    />
                  )}
                </FormField.Control>
              }
            />
            <FormSelect
              options={partTimeRoleOptions}
              name="role"
              control={control}
              label={t("partTime.create.form.type")}
              required={true}
              errorMessage={errors.role?.message}
            />
            <FormField.Control
              name="stationId"
              defaultValue={defaultValues.stationId}
              control={control}
            >
              {({ field }) => (
                <FormField.Container
                  label={t("partTime.create.form.stations")}
                  errorMessage={errors.stationId?.message}
                  required={true}
                >
                  {isSuperAdmin ? (
                    <LazyLoadStationSelectionDropdown
                      selectedStationId={field.value}
                      onChange={field.onChange}
                    />
                  ) : (
                    <EagerLoadStationSelectionDropdown
                      selectedStationId={field.value}
                      stations={meUser?.stations ?? []}
                      onChange={field.onChange}
                      disabled={isManager}
                    />
                  )}
                </FormField.Container>
              )}
            </FormField.Control>
            {stationTeamOptions.length > 0 ? (
              <FormSelect
                options={stationTeamOptions}
                name="stationTeamId"
                control={control}
                label={t("partTime.create.form.stationTeam")}
                required={true}
                errorMessage={errors.stationTeamId?.message}
              />
            ) : null}
            {isCCORole ? null : (
              <>
                <div
                  className={cn(
                    "col-span-1",
                    "sm:col-span-2",
                    "text-sm",
                    "flex",
                    "flex-row",
                    "items-center"
                  )}
                >
                  <div className="grow">
                    <Trans i18nKey="partTime.create.form.regularShiftSchedule" />
                  </div>
                  <FormField.Control
                    name="receiveInviteOnHoliday"
                    control={control}
                  >
                    {({ field }) => (
                      <Checkbox
                        name="receiveInviteOnHoliday"
                        label={t("partTime.create.form.receiveInviteOnHoliday")}
                        checked={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  </FormField.Control>
                </div>
                <FormField.Control
                  name="regularShiftSchedule"
                  control={control}
                >
                  {({ field }) => (
                    <FormField.Container
                      errorMessage={errors.regularShiftSchedule?.message}
                      required={false}
                      className={cn("col-span-1", "sm:col-span-2")}
                    >
                      <PTUserFormWeekCardList
                        field={field}
                        className={cn("space-y-5", "w-full")}
                      />
                    </FormField.Container>
                  )}
                </FormField.Control>
              </>
            )}
          </form>
        </div>
      </div>
      <footer
        className={cn(
          "fixed",
          "bottom-0",
          "left-0",
          "bg-white",
          "w-full",
          "h-16",
          "flex",
          "sm:justify-end",
          "border-t",
          "border-black/12",
          "px-6",
          "py-3",
          "space-x-3"
        )}
      >
        <ButtonLink
          theme={"white"}
          className={cn("flex-1", "sm:flex-none", "text-sm")}
          to={AppRoutes.PartTimeListScreen.render()}
        >
          <Trans i18nKey="partTime.create.footer.button.cancel" />
        </ButtonLink>
        <Button
          type="submit"
          form={formId}
          disabled={isSubmitButtonDisabled || !isValid}
          className={cn("flex-1", "sm:flex-none")}
        >
          <Trans i18nKey="partTime.create.footer.button.create" />
        </Button>
      </footer>
    </main>
  );
};

export default CreatePartTimeScreen;
