import { z } from "zod";
import { MeUser } from "oneclick-component/src/store/apis/enhancedApi";

// ref https://github.com/colinhacks/zod/discussions/768#discussioncomment-1759035
const announcementTypeSchema = z.enum(["FRIENDLY_REMINDER", "IMPORTANT"]);
export const createAnnouncemenFormSchema = z.object({
  type: announcementTypeSchema,
  content: z.string().trim().min(1),
  stations: z.array(z.number()),
  line: z.number().nullable(),
  sendType: z.string(),
});

export type CreateAnnouncementForm = z.infer<
  typeof createAnnouncemenFormSchema
>;

export const getFormDefaultByMeUser = (
  user: MeUser | null
): CreateAnnouncementForm => {
  switch (user?.adminRole) {
    case "ADMIN":
      return {
        type: "FRIENDLY_REMINDER",
        content: "",
        sendType: "all",
        stations: [],
        line: null,
      };
    case "LANDLORD":
      return {
        type: "FRIENDLY_REMINDER",
        content: "",
        sendType: "all",
        stations: [],
        line: user.railwayLine?.id ?? null,
      };
    case "MANAGER":
      return {
        type: "FRIENDLY_REMINDER",
        content: "",
        sendType: "stations",
        stations:
          user.selectedProfile != null ? [user.selectedProfile.station.id] : [],
        line: null,
      };
    default:
      return {
        type: "FRIENDLY_REMINDER",
        content: "",
        sendType: "stations",
        stations: [],
        line: null,
      };
  }
};
