import { ControllerRenderProps } from "react-hook-form";
import { RegularShiftForm } from "./form";
import { ReactElement, useCallback, useMemo } from "react";
import { Option, Select } from "oneclick-component/src/components/inputs";
import cn from "classnames";
import { Trans } from "react-i18next";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { FifteenthInstant, IntraDayRegularShift } from "../../models";
import { makeTimeOptions } from "../../utils/select";

interface RowProps {
  onRemove: (rowIndex: number) => void;
  field: ControllerRenderProps<RegularShiftForm, "regularShifts">;
  rowIndex: number;
}
export const PTUserWeekCardDialogRow = (props: RowProps): ReactElement => {
  const { field, rowIndex, onRemove } = props;
  const regularShift = field.value[rowIndex];
  const timeOptions: Option<FifteenthInstant>[] = useMemo(
    () => makeTimeOptions(),
    []
  );

  const updateRegularShifts = useCallback(
    (newRegularShift: IntraDayRegularShift) => {
      field.onChange(
        Object.assign(field.value.slice(), {
          [rowIndex]: newRegularShift,
        })
      );
    },
    [rowIndex, field]
  );

  const onChangeStartTime = useCallback(
    (newStartTime: string) => {
      const newRegularShift: IntraDayRegularShift = {
        ...regularShift,
        startTime: newStartTime,
      };
      updateRegularShifts(newRegularShift);
    },
    [updateRegularShifts, regularShift]
  );

  const onChangeEndTime = useCallback(
    (newEndTime: string) => {
      const newRegularShift: IntraDayRegularShift = {
        ...regularShift,
        endTime: newEndTime,
      };
      updateRegularShifts(newRegularShift);
    },
    [updateRegularShifts, regularShift]
  );

  const handleRemove = useCallback(
    () => onRemove(rowIndex),
    [rowIndex, onRemove]
  );

  return (
    <div className={cn("flex", "space-x-5")}>
      <div className={cn("flex-1", "space-y-1")}>
        <span className="text-sm">
          <Trans i18nKey="partTime.form.weekCard.editModal.from" />
        </span>
        <Select
          options={timeOptions}
          onChange={onChangeStartTime}
          value={regularShift.startTime}
        />
      </div>
      <div className={cn("flex-1", "space-y-1")}>
        <span className="text-sm">
          <Trans i18nKey="partTime.form.weekCard.editModal.to" />
        </span>
        <Select
          options={timeOptions}
          onChange={onChangeEndTime}
          value={regularShift.endTime}
        />
      </div>
      <div className="space-y-1">
        <div className="h-5"></div>
        <button type="button" className="h-10.5" onClick={handleRemove}>
          <XMarkIcon className={cn("h-6", "w-6", "self-center")} />
        </button>
      </div>
    </div>
  );
};

interface ListProps {
  field: ControllerRenderProps<RegularShiftForm, "regularShifts">;
  setRegularShiftsValue: (value: IntraDayRegularShift[]) => void;
  getValues: (payload?: string | string[]) => RegularShiftForm;
}

export const PTUserWeekCardDialogRowList = (props: ListProps): ReactElement => {
  const { field, setRegularShiftsValue, getValues } = props;

  const onRemoveRow = useCallback(
    (rowIndex: number) => {
      const filteredRows = getValues().regularShifts.filter(
        (_, itemIndex) => itemIndex !== rowIndex
      );
      setRegularShiftsValue(filteredRows);
    },
    [getValues, setRegularShiftsValue]
  );

  return (
    <ul className="space-y-5">
      {field.value.map((_, i) => (
        <PTUserWeekCardDialogRow
          key={i}
          onRemove={onRemoveRow}
          field={field}
          rowIndex={i}
        />
      ))}
    </ul>
  );
};
