const suffixList = ["B", "KB", "MB"] as const;

export function constructFileSizeDisplayString(
  fileSizeBytes: number,
  precision: number = 1
): string {
  let sizeInCurrUnit = fileSizeBytes;
  const maxUnitIdx = suffixList.length - 1;

  for (let unitIdx = 0; unitIdx <= maxUnitIdx; ++unitIdx) {
    const sizeInNextUnit = sizeInCurrUnit / 1024;
    if (sizeInNextUnit < 1 || unitIdx >= maxUnitIdx) {
      const suffix = suffixList[unitIdx];
      return `${sizeInCurrUnit.toFixed(precision)} ${suffix}`;
    }
    sizeInCurrUnit = sizeInNextUnit;
  }

  // unreachable
  return "-";
}
