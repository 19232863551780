import React, { PropsWithChildren, useCallback } from "react";
import cn from "classnames";
import {
  Row,
  Table as ReactTable,
  flexRender,
  Header,
} from "@tanstack/react-table";
import { ArrowDown } from "../../icon";

interface TableProps<T> extends PropsWithChildren {
  className?: string;
  table: ReactTable<T>;
  onClickColumnHeader?: (header: Header<T, unknown>) => void;
}

interface TableRowProps<T>
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableRowElement>,
    HTMLTableRowElement
  > {
  className?: string;
  row: Row<T>;
}

function TableRow<T>(props: TableRowProps<T>): React.ReactElement {
  const { row, onClick, className } = props;

  return (
    <tr className={className} onClick={onClick}>
      {row.getVisibleCells().map((cell) => {
        return (
          <td
            key={cell.id}
            className={cn("py-4", "pl-4", "pr-3", "text-sm", "sm:pl-6")}
            style={{
              width:
                cell.column.getSize() === Number.MAX_SAFE_INTEGER
                  ? "auto"
                  : cell.column.getSize(),
            }}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        );
      })}
    </tr>
  );
}

function _Table<T>(props: TableProps<T>): React.ReactElement {
  const { table, className, children, onClickColumnHeader } = props;
  const makeOnClickHeader = useCallback(
    (header: Header<T, unknown>) => {
      if (onClickColumnHeader == null) {
        return undefined;
      }
      return () => onClickColumnHeader(header);
    },
    [onClickColumnHeader]
  );
  return (
    <div
      className={cn(
        "overflow-x-auto",
        "min-w-full",
        "align-middle",
        "rounded-lg",
        "shadow",
        "ring-1",
        "ring-black/5",
        className
      )}
    >
      <table className={cn("min-w-full", "divide-y", "divide-gray-300")}>
        <thead className="bg-gray-50">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    scope="col"
                    className={cn(
                      "py-3",
                      "px-3",
                      "sm:px-6",
                      "text-left",
                      "text-xs",
                      "text-gray-500",
                      "whitespace-nowrap",
                      header.column.getCanSort()
                        ? ["cursor-pointer", "select-none"]
                        : ""
                    )}
                    style={{
                      width:
                        header.getSize() === Number.MAX_SAFE_INTEGER
                          ? "auto"
                          : header.getSize(),
                    }}
                    onClick={makeOnClickHeader(header)}
                  >
                    <span className={cn("flex", "items-center")}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {{
                        asc: (
                          <ArrowDown
                            className={cn(
                              "w-3",
                              "h-3",
                              "ml-2",
                              "origin-center",
                              "rotate-180"
                            )}
                          />
                        ),
                        desc: (
                          <ArrowDown className={cn("w-3", "h-3", "ml-2")} />
                        ),
                      }[header.column.getIsSorted() as string] ?? (
                        <div className={cn("w-3", "ml-2")}></div>
                      )}
                    </span>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody className={cn("divide-y", "divide-gray-200", "bg-white")}>
          {children}
        </tbody>
      </table>
    </div>
  );
}

const Table = Object.assign(_Table, { Row: TableRow });
export default Table;
