import * as z from "zod";

export const shiftTimeRangeSchema = z.object({
  dutyStartTime: z.string(),
  dutyEndTime: z.string().nullish(),
  isOvernight: z.boolean(),
});
export type ShiftTimeRange = z.infer<typeof shiftTimeRangeSchema>;

export const baseMasterShiftTimePatternSchema = z.object({
  recurring: z.boolean(),
  shiftTimeRanges: z.array(shiftTimeRangeSchema),
});
export type BaseMasterShiftTimePattern = z.infer<
  typeof baseMasterShiftTimePatternSchema
>;

const nonRecurringMasterShiftTimePatternSchema =
  baseMasterShiftTimePatternSchema.extend({
    recurring: z.literal(false),
    shiftStartDates: z.array(z.string()),
  });
export type NonRecurringMasterShiftTimePattern = z.infer<
  typeof nonRecurringMasterShiftTimePatternSchema
>;

const recurringMasterShiftTimePatternSchema =
  baseMasterShiftTimePatternSchema.extend({
    recurring: z.literal(true),
    frequency: z.literal("weekly"),
    recurrenceStartDate: z.string(),
    recurrenceEndDate: z.string(),
  });
export type RecurringMasterShiftTimePattern = z.infer<
  typeof recurringMasterShiftTimePatternSchema
>;

const weeklyRecurringMasterShiftTimePatternSchema =
  recurringMasterShiftTimePatternSchema.extend({
    byWeekday: z.array(z.number().min(0).max(6)),
  });
export type WeeklyRecurringMasterShiftTimePattern = z.infer<
  typeof weeklyRecurringMasterShiftTimePatternSchema
>;

export const masterShiftTimePatternSchema = z.union([
  nonRecurringMasterShiftTimePatternSchema,
  weeklyRecurringMasterShiftTimePatternSchema,
]);
export type MasterShiftTimePattern = z.infer<
  typeof masterShiftTimePatternSchema
>;
