import React, { useCallback, useMemo, useState } from "react";
import {
  PublicHoliday,
  useListPublicHolidaysHandlerPublicHolidaysGetQuery as useListPublicHoliday,
} from "oneclick-component/src/store/apis/enhancedApi";
import { isoToDateTime } from "oneclick-component/src/utils/datetime";
import { dedup } from "oneclick-component/src/utils/list";
import { localizeString } from "oneclick-component/src/utils/localize";
import cn from "classnames";
import { Trans } from "react-i18next";
import { useShowError } from "../../hooks/useShowError";

interface HolidayTableProps {
  holidays: PublicHoliday[];
}

const HolidayTable = (props: HolidayTableProps): React.ReactElement => {
  const { holidays } = props;
  return (
    <div
      className={cn(
        "shadow-md",
        "rounded-lg",
        "border",
        "border-gray-200",
        "lg:w-120"
      )}
    >
      <div
        className={cn(
          "bg-gray-50",
          "rounded-t-lg",
          "border-b",
          "border-b-gray-200",
          "px-6",
          "py-3",
          "flex",
          "flex-row",
          "gap-x-6"
        )}
      >
        <div className="flex-1">
          <Trans i18nKey="publicHoliday.holiday.title" />
        </div>
        <div className="flex-1">
          <Trans i18nKey="publicHoliday.holiday.date" />
        </div>
      </div>
      <div className="text-black/60">
        {holidays.map((date) => {
          const dateObject = isoToDateTime(date.date);
          return (
            <div
              key={`holiday-${date.date}`}
              className={cn("px-6", "py-7", "flex", "flex-row", "gap-x-6")}
            >
              <div className="flex-1">
                <p>{localizeString(date.name)}</p>
              </div>
              <div className="flex-1">
                <Trans
                  i18nKey="publicHoliday.holiday.actualDate"
                  values={{
                    month: dateObject.month,
                    day: dateObject.day,
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const PublicHolidayScreen = (): React.ReactElement => {
  const { data: holidays, error } = useListPublicHoliday({});
  const { showError } = useShowError();
  const [viewIndex, setViewIndex] = useState(0);

  if (error != null) {
    showError(error);
  }

  const years = useMemo(() => {
    if (holidays == null) {
      return [];
    }
    const yearObject = holidays.results.map((date) => ({
      id: isoToDateTime(date.date).year,
    }));
    return dedup(yearObject).map((year) => year.id);
  }, [holidays]);

  const holidayByMap = useMemo(() => {
    if (holidays == null) {
      return {};
    }

    const map: Record<string, PublicHoliday[] | undefined> = {};
    for (const holiday of holidays.results) {
      const yearKey = `${isoToDateTime(holiday.date).year}`;

      if (map[yearKey] == null) {
        map[yearKey] = [holiday];
      } else {
        map[yearKey]?.push(holiday);
      }
    }

    return map;
  }, [holidays]);

  const onChangeYear = useCallback((index: number) => {
    return () => {
      setViewIndex(index);
    };
  }, []);
  return (
    <main className={"h-full pt-19"}>
      <div
        className={cn(
          "rounded-lg",
          "bg-white",
          "shadow",
          "max-w-250",
          "mx-auto",
          "p-12",
          "lg:flex",
          "lg:flex-row"
        )}
      >
        <div
          className={cn(
            "lg:ml-8",
            "lg:mr-23",
            "lg:pt-14",
            "flex",
            "flex-row",
            "lg:block"
          )}
        >
          {years.map((year, index) => (
            <button
              key={`year-${year}`}
              type="button"
              onClick={onChangeYear(index)}
              className={cn("font-normal", "mb-6", "w-full", {
                "text-black/60": viewIndex !== index,
                "text-primary-500": viewIndex === index,
              })}
            >
              {year}
            </button>
          ))}
        </div>
        <div>
          <p className={cn("mb-6", "text-lg", "font-medium")}>
            <Trans i18nKey="publicHoliday.title" />
          </p>
          <HolidayTable holidays={holidayByMap[years[viewIndex]] ?? []} />
        </div>
      </div>
    </main>
  );
};

export default PublicHolidayScreen;
