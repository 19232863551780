interface ptRolesGroupType {
  name: string;
  includeRoles: string[];
}

export const ptRolesGroup: Record<string, ptRolesGroupType> = {
  SA: {
    name: "SA",
    includeRoles: ["SA", "SAS"],
  },
  "SA and CUCA": {
    name: "SA and CUCA",
    includeRoles: ["SA", "SAS", "CUCA"],
  },
};
