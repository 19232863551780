import React, {
  useMemo,
  useRef,
  useEffect,
  useCallback,
  ReactElement,
} from "react";
import { OGVLoader, OGVPlayer } from "ogv";
import { useDispatch, useStore } from "react-redux";
import {
  audioEnded,
  audioPlayerError,
  activePlayerTimeUpdated,
  playerTimeUpdated,
  pauseAudio,
  playAudio,
  playingAudio,
  audioLoaded,
} from "../store/audioPlayer";
import { RootState } from "../store/store";
import { config } from "../config";

const AUTO_UPDATE_CURRENT_TIME_INTERVAL_MS = 500;

function isOGG(mime: string) {
  return /^audio\/(ogg|opus)/.test(mime);
}

export interface AudioPlayerContextValue {
  play: (
    playerId: string,
    src: string,
    mime: string,
    currentTime: number,
    options: { resetPlayer: boolean }
  ) => void;
  pause: (playerId: string) => void;
  setCurrentTime: (playerId: string, currentTime: number) => void;
}

export const AudioPlayerContext = React.createContext<AudioPlayerContextValue>(
  null as any
);

export const AudioPlayerProvider = (
  props: React.PropsWithChildren
): ReactElement => {
  const dispatch = useDispatch();
  const store = useStore<RootState>();
  const playerRef = useRef<OGVPlayer | HTMLAudioElement | null>(null);
  const audioPlayerRef = useRef<HTMLAudioElement | null>(null);
  const ogvPlayerRef = useRef<OGVPlayer | null>(null);

  const onAudioPlayerError = useCallback(() => {
    dispatch(audioPlayerError({ playerType: "built-in" }));
  }, [dispatch]);
  const onAudioPlayerEnded = useCallback(() => {
    dispatch(audioEnded({ playerType: "built-in" }));
  }, [dispatch]);
  const onAudioPlayerPlaying = useCallback(() => {
    if (audioPlayerRef.current == null) {
      return;
    }
    const duration = audioPlayerRef.current.duration;
    dispatch(audioLoaded({ playerType: "built-in", duration }));
    dispatch(playingAudio({ playerType: "built-in" }));
  }, [dispatch]);

  const onOgvPlayerError = useCallback(() => {
    dispatch(audioPlayerError({ playerType: "ogv" }));
  }, [dispatch]);
  const onOgvPlayerEnded = useCallback(() => {
    dispatch(audioEnded({ playerType: "ogv" }));
  }, [dispatch]);
  const onOgvPlayerPlaying = useCallback(() => {
    if (ogvPlayerRef.current == null) {
      return;
    }
    const duration = ogvPlayerRef.current.duration;
    dispatch(audioLoaded({ playerType: "ogv", duration }));
    dispatch(playingAudio({ playerType: "ogv" }));
  }, [dispatch]);

  const loadAudioPlayer = useCallback(() => {
    if (audioPlayerRef.current == null) {
      audioPlayerRef.current = new Audio();
      audioPlayerRef.current.addEventListener("error", onAudioPlayerError);
      audioPlayerRef.current.addEventListener("ended", onAudioPlayerEnded);
      audioPlayerRef.current.addEventListener("playing", onAudioPlayerPlaying);
    }
  }, [onAudioPlayerError, onAudioPlayerEnded, onAudioPlayerPlaying]);

  // NOTE: call this function for all players that has OGG file src
  const loadOGVPlayer = useCallback(() => {
    if (ogvPlayerRef.current == null) {
      if (config.staticAssetBaseUrl != null) {
        OGVLoader.base = config.staticAssetBaseUrl.replace(/\/$/, "") + "/ogv";
      } else {
        OGVLoader.base = import.meta.env.BASE_URL + "ogv"; // relative to public directory
      }
      ogvPlayerRef.current = new OGVPlayer();
      ogvPlayerRef.current.addEventListener("error", onOgvPlayerError);
      ogvPlayerRef.current.addEventListener("ended", onOgvPlayerEnded);
      ogvPlayerRef.current.addEventListener("playing", onOgvPlayerPlaying);
    }
  }, [onOgvPlayerError, onOgvPlayerEnded, onOgvPlayerPlaying]);

  const onPlayerTimeUpdate = useCallback(() => {
    if (playerRef.current == null || playerRef.current.paused) {
      return;
    }
    const currentTime = playerRef.current.currentTime;
    dispatch(activePlayerTimeUpdated({ currentTime }));
  }, [dispatch]);

  // Update current time state
  useEffect(() => {
    const intervalHandle = window.setInterval(() => {
      onPlayerTimeUpdate();
    }, AUTO_UPDATE_CURRENT_TIME_INTERVAL_MS);

    return () => {
      window.clearInterval(intervalHandle);
    };
  }, [onPlayerTimeUpdate]);

  // eagerly instantiate player
  useEffect(() => {
    loadAudioPlayer();
    loadOGVPlayer();
  }, [loadAudioPlayer, loadOGVPlayer]);

  const ogvPlayerPlay = useCallback(
    (ogvPlayer: OGVPlayer) => {
      try {
        ogvPlayer.play();
      } catch {
        dispatch(audioPlayerError({ playerType: "ogv" }));
      }
    },
    [dispatch]
  );

  // Could throw error if not initialized properly
  const play = useCallback(
    (
      playerId: string,
      src: string,
      mime: string,
      currentTime: number,
      options: { resetPlayer: boolean }
    ) => {
      // pause current playing audio first
      if (playerRef.current != null) {
        playerRef.current.pause();
      }

      // pick player to use
      const playerType = isOGG(mime) ? "ogv" : "built-in";
      if (playerType === "ogv") {
        loadOGVPlayer();
        playerRef.current = ogvPlayerRef.current!;
      } else {
        loadAudioPlayer();
        playerRef.current = audioPlayerRef.current!;
      }

      // Update source set current time
      if (playerRef.current.src !== src) {
        playerRef.current.src = src;
      }
      // Need to manually reset current time for ogv player if user replay after reaching end
      if (options.resetPlayer) {
        playerRef.current.currentTime = 0;
      } else {
        playerRef.current.currentTime = currentTime;
      }

      dispatch(playAudio({ playerId, playerType, src }));

      if (playerRef.current instanceof HTMLAudioElement) {
        playerRef.current.play().catch(() => {
          dispatch(audioPlayerError({ playerType: "built-in" }));
        });
      } else {
        ogvPlayerPlay(playerRef.current);
      }
    },
    [dispatch, loadOGVPlayer, loadAudioPlayer, ogvPlayerPlay]
  );

  const pause = useCallback(
    (playerId: string) => {
      if (playerRef.current != null) {
        playerRef.current.pause();
        dispatch(pauseAudio({ playerId }));
      }
    },
    [dispatch]
  );

  const setCurrentTime = useCallback(
    (playerId: string, currentTime: number) => {
      const activePlayerId = store.getState().audioPlayer.activePlayerId;
      if (playerRef.current != null) {
        if (activePlayerId === playerId) {
          playerRef.current.currentTime = currentTime;
        }
        dispatch(playerTimeUpdated({ playerId, currentTime }));
      }
    },
    [dispatch, store]
  );

  const contextValue = useMemo<AudioPlayerContextValue>(() => {
    return {
      play,
      pause,
      setCurrentTime,
    };
  }, [play, pause, setCurrentTime]);

  return (
    <AudioPlayerContext.Provider value={contextValue}>
      {props.children}
    </AudioPlayerContext.Provider>
  );
};
