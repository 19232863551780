import React, { useContext } from "react";
import cn from "classnames";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import TabMenu from "oneclick-component/src/components/TabMenu";
import { PeopleIcon } from "../../icon";
import { ChatBubble } from "../../components/ChatBubble";
import AppRoutes from "../../routes/AppRoutes";
import { NotificationContext } from "../../providers/NotificationProvider";

const NotificationScreen = (): React.ReactElement => {
  const { t } = useTranslation();
  const { pendingNotificationUnreadCount } = useContext(NotificationContext);

  return (
    <main className={"pb-24"}>
      <div className={cn("rounded-lg", "bg-white", "max-w-344", "mx-auto")}>
        <div className={cn("sm:p-6", "pb-6", "px-6")}>
          <TabMenu
            panels={[
              {
                icon: PeopleIcon,
                title: t("notification.tab.title.pending", {
                  count: pendingNotificationUnreadCount,
                }),
                to: AppRoutes.PendingNotificationTab.render(),
              },
              {
                icon: CheckCircleIcon,
                title: t("notification.tab.title.handled", {
                  count: 0,
                }),
                to: AppRoutes.HandledNotificationTab.render(),
              },
            ]}
          />
        </div>
      </div>
      <ChatBubble
        className={cn(
          "fixed",
          "z-20",
          "right-4",
          "bottom-18",
          "space-y-3",
          "sm:hidden"
        )}
      />
    </main>
  );
};

export default NotificationScreen;
