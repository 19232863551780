import React, { useCallback, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useForm, Control } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { RadioGroup } from "@headlessui/react";
import cn from "classnames";

import { Modal } from "oneclick-component/src/components/Modal";
import {
  Select,
  Option,
  BadgeButton,
} from "oneclick-component/src/components/inputs";
import {
  AnnouncementType,
  useCreateAnnouncementHandlerAnnouncementsPostMutation as useCreateAnnouncementMutation,
  useListStationHandlerStationsGetQuery as useStationList,
  useListRailwayLineHandlerRailwayLinesGetQuery as useRailwayList,
  RailwayLineWithStation,
  BriefStation,
  MeUser,
  CreateAnnouncementRequest,
} from "oneclick-component/src/store/apis/enhancedApi";
import {
  FormSelect,
  FormTextArea,
  FormField,
} from "oneclick-component/src/components/forms";
import { RadioInput } from "oneclick-component/src/components/inputs/RadioInput";
import { Button } from "oneclick-component/src/components/Button";
import useShowMessage from "oneclick-component/src/hooks/useShowMessage";
import { EagerLoadStationSelectionDropdown } from "../../../components/StationSelectionDropdown";
import {
  WHATSAPP_ATTACHMENT_DOCUMENT_SIZE_LIMIT_MB,
  WHATSAPP_ATTACHMENT_IMAGE_SIZE_LIMIT_MB,
} from "../../../constants/attachments";
import { useShowError } from "../../../hooks/useShowError";
import { AnnouncementAttachmentPicker } from "./AnnouncementAttachmentPicker";
import { useUploadAttachment } from "../../../hooks/useUploadAttachment";
import AttachmentPreview from "../../../components/AttachmentPreview.tsx/AttachmentPreview";
import { announcementTypeTitleI18nKeyMap } from "../constants";
import {
  CreateAnnouncementForm,
  createAnnouncemenFormSchema,
  getFormDefaultByMeUser,
} from "./form";
import { StationBadge } from "oneclick-component/src/components/Badge";
import useMeUser from "../../../hooks/useMeUser";

// Max: 1600 https://help.twilio.com/articles/360033806753-Maximum-Message-Length-with-Twilio-Programmable-Messaging
const MAX_ANNOUNCEMENT_CONTENT_LENGTH = 1000;

interface StationListProps {
  stations: BriefStation[];
  onRemove: (idStr: string) => void;
}

const StationList = (props: StationListProps) => {
  const { stations, onRemove } = props;
  return (
    <div className={cn("ml-6", "flex", "flex-row", "flex-wrap")}>
      {stations.map((st) => (
        <BadgeButton
          className={cn("mr-2", "mb-1")}
          key={st.id}
          name={`${st.id}`}
          displayAbbrev={st.shortCode}
          remove={onRemove}
        />
      ))}
    </div>
  );
};

interface AdminTargetOptionsProps {
  stations: BriefStation[];
  railwayLines: RailwayLineWithStation[];
  control: Control<CreateAnnouncementForm>;
  onAddStation: (station: BriefStation | null) => void;
  onRemoveStation: (stationIdStr: string) => void;
}

const AdminTargetOptions = (props: AdminTargetOptionsProps) => {
  const { stations, railwayLines, control, onAddStation, onRemoveStation } =
    props;
  const { t } = useTranslation();

  const railwayOptions: Option<number>[] = useMemo(() => {
    return railwayLines.map((r) => ({ name: r.shortCode, value: r.id }));
  }, [railwayLines]);

  return (
    <>
      <RadioGroup.Option value="all">
        {({ active, checked }) => (
          <RadioInput
            className={cn("text-sm", "py-3")}
            labelClassName={cn("!text-black/86", "font-medium", "text-sm")}
            active={active}
            checked={checked}
            label={t("announcement.create.dialog.options.all")}
          />
        )}
      </RadioGroup.Option>
      <RadioGroup.Option value="line">
        {({ active, checked }) => (
          <>
            <RadioInput
              className={cn("text-sm", "py-3")}
              labelClassName={cn("!text-black/86", "font-medium", "text-sm")}
              active={active}
              checked={checked}
              label={t("announcement.create.dialog.options.lineSelect")}
            />
            {checked ? (
              <FormField.Control control={control} name="line">
                {({ field, fieldState }) => (
                  <>
                    <Select
                      {...field}
                      className="ml-6"
                      options={railwayOptions}
                      hasError={fieldState.error != null}
                    />
                    <div className={cn("ml-6", "text-red-500")}>
                      {fieldState.error?.message}
                    </div>
                  </>
                )}
              </FormField.Control>
            ) : null}
          </>
        )}
      </RadioGroup.Option>
      <RadioGroup.Option value="stations">
        {({ active, checked }) => (
          <>
            <RadioInput
              className={cn("text-sm", "py-3")}
              labelClassName={cn("!text-black/86", "font-medium", "text-sm")}
              active={active}
              checked={checked}
              label={t("announcement.create.dialog.options.stationSelect")}
            />
            {checked ? (
              <>
                <FormField.Control control={control} name="stations">
                  {({ field, fieldState }) => (
                    <>
                      <EagerLoadStationSelectionDropdown
                        className={cn("ml-6", "mb-2")}
                        inputClassName={
                          fieldState.error != null ? "ring-red-500" : ""
                        }
                        selectedStationId={null}
                        stations={stations}
                        onStationSelected={onAddStation}
                      />
                      <StationList
                        stations={stations.filter(
                          (st) => field.value.indexOf(st.id) !== -1
                        )}
                        onRemove={onRemoveStation}
                      />
                      <div className={cn("ml-6", "text-red-500")}>
                        {fieldState.error?.message}
                      </div>
                    </>
                  )}
                </FormField.Control>
              </>
            ) : null}
          </>
        )}
      </RadioGroup.Option>
    </>
  );
};

interface LandlordTargetOptionsProps {
  meUser: MeUser | null;
  railwayLines: RailwayLineWithStation[];
  onAddStation: (station: BriefStation | null) => void;
  onRemoveStation: (stationIdStr: string) => void;
  control: Control<CreateAnnouncementForm>;
}

const LandlordTargetOptions = (props: LandlordTargetOptionsProps) => {
  const { meUser, railwayLines, onAddStation, onRemoveStation, control } =
    props;
  const { t } = useTranslation();

  const line = meUser?.railwayLine?.shortCode ?? "-";
  const stations = useMemo(() => {
    const railwayLine = railwayLines.find(
      (line) => line.id === meUser?.railwayLine?.id
    );
    if (railwayLine == null) {
      return [];
    }
    return railwayLine.stations;
  }, [meUser, railwayLines]);
  return (
    <>
      <RadioGroup.Option value="all">
        {({ active, checked }) => (
          <RadioInput
            className={cn("text-sm", "py-3")}
            labelClassName={cn("!text-black/86", "font-medium", "text-sm")}
            active={active}
            checked={checked}
            label={t("announcement.create.dialog.options.all")}
          />
        )}
      </RadioGroup.Option>
      <RadioGroup.Option value="line">
        {({ active, checked }) => (
          <RadioInput
            className={cn("text-sm", "py-3")}
            labelClassName={cn("!text-black/86", "font-medium", "text-sm")}
            active={active}
            checked={checked}
            label={t("announcement.create.dialog.options.line", { line })}
          />
        )}
      </RadioGroup.Option>
      <RadioGroup.Option value="stations">
        {({ active, checked }) => (
          <>
            <RadioInput
              className={cn("text-sm", "py-3")}
              labelClassName={cn("!text-black/86", "font-medium", "text-sm")}
              active={active}
              checked={checked}
              label={t(
                "announcement.create.dialog.options.lineStationsSelect",
                {
                  line,
                }
              )}
            />
            {checked ? (
              <FormField.Control control={control} name="stations">
                {({ field, fieldState }) => (
                  <>
                    <EagerLoadStationSelectionDropdown
                      className={cn("ml-6", "mb-2")}
                      inputClassName={
                        fieldState.error != null ? "ring-red-500" : ""
                      }
                      selectedStationId={null}
                      stations={stations}
                      onStationSelected={onAddStation}
                    />
                    <StationList
                      stations={stations.filter(
                        (st) => field.value.indexOf(st.id) !== -1
                      )}
                      onRemove={onRemoveStation}
                    />
                    <div className={cn("ml-6", "text-red-500")}>
                      {fieldState.error?.message}
                    </div>
                  </>
                )}
              </FormField.Control>
            ) : null}
          </>
        )}
      </RadioGroup.Option>
    </>
  );
};

interface ManagerTargetOptionsProps {
  meUser: MeUser | null;
}

const ManagerTargetOptions = (props: ManagerTargetOptionsProps) => {
  const { meUser } = props;
  const { t } = useTranslation();
  const selectedStation = meUser?.selectedProfile?.station;
  if (selectedStation == null) {
    console.warn("Expect manager user to have selected station profile");
    return t("announcement.create.dialog.options.station", {
      recipient: "-",
    });
  }
  const stationBadge = (
    <StationBadge
      key={`StationBadge-${selectedStation.id}`}
      className="mx-2"
      station={selectedStation}
      stationTeam={null}
    />
  );
  return (
    <RadioGroup.Option value="stations">
      {({ active, checked }) => (
        <RadioInput
          className={cn("text-sm", "py-3")}
          labelClassName={cn("!text-black/86", "font-medium", "text-sm")}
          active={active}
          checked={checked}
          label={t("announcement.create.dialog.options.station", {
            recipient: stationBadge,
          })}
        />
      )}
    </RadioGroup.Option>
  );
};

interface Props {
  onClose: () => void;
  isOpen: boolean;
  className?: string;
}
const CreateAnnouncementDialog = (props: Props): React.ReactElement => {
  const { onClose, isOpen, className } = props;
  const meUser = useMeUser();
  const { t } = useTranslation();
  const { data: railwayLines } = useRailwayList();
  const { data: stations } = useStationList({ pageSize: 200 });
  const [createAnnouncement, { isLoading: isCreateAnnouncementLoading }] =
    useCreateAnnouncementMutation();
  const showMessage = useShowMessage();
  const { showError } = useShowError();
  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<CreateAnnouncementForm>({
    resolver: zodResolver(createAnnouncemenFormSchema),
    defaultValues: getFormDefaultByMeUser(meUser),
  });

  const formValues = watch();

  const {
    attachmentState,
    selectedAttachmentId,
    onAttachmentSelected,
    onAttachmentRemoved,
    onAttachmentSent,
  } = useUploadAttachment();

  const onDialogClose = useCallback(() => {
    onClose();
    reset();
  }, [onClose, reset]);

  const _onSubmit = useCallback(
    (data: CreateAnnouncementForm) => {
      const requestParams: CreateAnnouncementRequest = {
        type: data.type,
        content: data.content,
        attachmentId: selectedAttachmentId,
      };

      if (data.sendType === "line" && data.line == null) {
        setError("line", {
          message: t("announcement.create.dialog.error.noLine"),
        });
        return;
      }

      if (data.sendType === "stations" && data.stations.length === 0) {
        setError("stations", {
          message: t("announcement.create.dialog.error.noStations"),
        });
        return;
      }
      clearErrors();

      switch (data.sendType) {
        case "all":
          requestParams.isSendAll = true;
          break;
        case "line":
          requestParams.line = data.line;
          requestParams.isSendAll = false;
          break;
        case "stations":
          requestParams.stations = data.stations;
          requestParams.isSendAll = false;
          break;
        default:
          console.error("Unknown target type");
          return;
      }

      createAnnouncement({
        createAnnouncementRequest: requestParams,
      })
        .unwrap()
        .then(() => {
          onAttachmentSent();
          onDialogClose();
          showMessage({
            title: t("announcement.create.toast.success.title"),
            type: "success",
          });
        })
        .catch((err) => {
          console.error(err);
          showError(err, t("announcement.create.toast.failed.title"));
        });
    },
    [
      createAnnouncement,
      clearErrors,
      selectedAttachmentId,
      onAttachmentSent,
      onDialogClose,
      showMessage,
      t,
      showError,
      setError,
    ]
  );
  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      handleSubmit(_onSubmit)(e).catch((err: unknown) => {
        throw err;
      });
      e.stopPropagation();
    },
    [handleSubmit, _onSubmit]
  );
  const announcementTypeOptions: Option<AnnouncementType>[] = useMemo(
    () => [
      {
        name: t("announcement.type.friendlyReminder"),
        value: "FRIENDLY_REMINDER",
      },
      {
        name: t("announcement.type.important"),
        value: "IMPORTANT",
      },
    ],
    [t]
  );

  const handleAddStation = useCallback(
    (station: BriefStation | null) => {
      if (station == null) {
        return;
      }
      const selectedStations = formValues.stations;
      if (selectedStations.indexOf(station.id) === -1) {
        setValue("stations", [...selectedStations, station.id]);
      }
    },
    [formValues.stations, setValue]
  );

  const handleRemoveStation = useCallback(
    (stationIdStr: string) => {
      const stationId = parseInt(stationIdStr, 10);
      const selectedStations = formValues.stations;
      setValue(
        "stations",
        selectedStations.filter((st) => st !== stationId)
      );
    },
    [formValues, setValue]
  );

  const targetOptions = useMemo(() => {
    const adminRole = meUser?.adminRole;
    switch (adminRole) {
      case "ADMIN": {
        return (
          <AdminTargetOptions
            control={control}
            stations={stations?.results ?? []}
            railwayLines={railwayLines?.railwayLines ?? []}
            onAddStation={handleAddStation}
            onRemoveStation={handleRemoveStation}
          />
        );
      }
      case "LANDLORD": {
        return (
          <LandlordTargetOptions
            meUser={meUser}
            railwayLines={railwayLines?.railwayLines ?? []}
            control={control}
            onAddStation={handleAddStation}
            onRemoveStation={handleRemoveStation}
          />
        );
      }
      case "MANAGER": {
        return <ManagerTargetOptions meUser={meUser} />;
      }
      default:
        return null;
    }
  }, [
    meUser,
    stations,
    railwayLines,
    control,
    handleAddStation,
    handleRemoveStation,
  ]);

  return (
    <Modal
      onClose={onDialogClose}
      isOpen={isOpen}
      hasXMarkButton={false}
      className={cn("w-full", "sm:!max-w-221", className)}
    >
      <form className={cn("gap-y-3", "sm:gap-y-5")} onSubmit={onSubmit}>
        <p className={cn("mb-3", "sm:mb-5")}>
          <Trans i18nKey="announcement.create.dialog.title" />
        </p>
        <div className={cn("sm:flex", "sm:flex-row")}>
          <div className="w-56">
            <FormField.Control name="sendType" control={control}>
              {({ field }) => (
                <RadioGroup {...field}>{targetOptions}</RadioGroup>
              )}
            </FormField.Control>
          </div>
          <div
            className={cn(
              "mb-5",
              "border-b",
              "border-b-gray-300",
              "mt-6",
              "sm:hidden"
            )}
          />
          <div
            className={cn(
              "sm:ml-5",
              "sm:pl-5",
              "sm:border-l",
              "border-gray-300",
              "flex-1"
            )}
          >
            <FormSelect
              options={announcementTypeOptions}
              name="type"
              control={control}
              errorMessage={errors.type?.message}
            />
            <p
              className={cn(
                "mt-3",
                "sm:mt-5",
                "text-sm",
                "font-normal",
                "leading-5"
              )}
            >
              <Trans i18nKey="announcement.create.dialog.title.header" />
            </p>
            <p>
              <Trans
                i18nKey={announcementTypeTitleI18nKeyMap[formValues.type]}
              />
            </p>
            <FormTextArea
              className={cn("mt-3", "sm:mt-5")}
              name="content"
              control={control}
              rows={5}
              label={t("announcement.create.dialog.content.header")}
              required={true}
              errorMessage={errors.content?.message}
              placeholder={`${t(
                "announcement.create.dialog.content.placeholder"
              )}`}
              maxLength={MAX_ANNOUNCEMENT_CONTENT_LENGTH}
            />
            {attachmentState.state !== "initial" ? (
              <AttachmentPreview
                className={cn("mt-3", "sm:mt-5")}
                isUploading={attachmentState.state === "uploading"}
                attachment={attachmentState.file}
                error={attachmentState.error}
                onAttachmentRemoved={onAttachmentRemoved}
              />
            ) : null}
            <div className={cn("mt-3", "sm:mt-5")}>
              <AnnouncementAttachmentPicker
                onAttachmentSelected={onAttachmentSelected}
                disabled={false}
              />
              <p
                className={cn(
                  "font-normal",
                  "text-xs",
                  "text-black/60",
                  "mt-1"
                )}
              >
                <Trans
                  i18nKey="announcement.create.dialog.attachmentLimit"
                  values={{
                    imageSize: WHATSAPP_ATTACHMENT_IMAGE_SIZE_LIMIT_MB,
                    docSize: WHATSAPP_ATTACHMENT_DOCUMENT_SIZE_LIMIT_MB,
                  }}
                />
              </p>
            </div>
          </div>
        </div>
        <div
          className={cn(
            "mt-3",
            "sm:mt-7",
            "flex",
            "flex-row",
            "justify-center",
            "sm:justify-end",
            "items-center"
          )}
        >
          <Button
            className={cn("font-semibold", "flex-1", "sm:flex-none")}
            onClick={onDialogClose}
            theme="white"
          >
            {t("common.cancel")}
          </Button>
          <Button
            type="submit"
            className={cn("font-semibold", "ml-3", "flex-1", "sm:flex-none")}
            isLoading={isCreateAnnouncementLoading}
          >
            {t("announcement.create.dialog.button.confirm")}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default CreateAnnouncementDialog;
