import i18next, { t } from "i18next";
import {
  MasterShiftTimePattern,
  NonRecurringMasterShiftTimePattern,
  ShiftTimeRange,
  WeeklyRecurringMasterShiftTimePattern,
} from "../models/shiftTimePattern";
import {
  formatDateTime,
  timeStringToDateTime,
  isoToDateTime,
} from "./datetime";

const makeDateDisplayText = (dateStr: string) => {
  const date = isoToDateTime(dateStr);
  return formatDateTime(
    date,
    i18next.t("common.dateWithWeekday.displayFormat")
  );
};

const makeDateListDisplayText = (dates: string[]) => {
  const separator = i18next.t("common.commaSepartor");
  return dates.map((d) => makeDateDisplayText(d)).join(separator);
};

const makeDateRangeDisplayText = (startDateStr: string, endDateStr: string) => {
  const startDateTime = isoToDateTime(startDateStr);
  const endDateTime = isoToDateTime(endDateStr);
  const startDateWithWeekdayString = formatDateTime(
    startDateTime,
    i18next.t("common.dateWithWeekday.displayFormat")
  );
  const endDateWithWeekdayString = formatDateTime(
    endDateTime,
    i18next.t("common.dateWithWeekday.displayFormat")
  );

  return i18next.t("common.date.range", {
    start: startDateWithWeekdayString,
    end: endDateWithWeekdayString,
  });
};

const makeTimeRangeDisplayText = (timeRange: ShiftTimeRange) => {
  const startTime = timeStringToDateTime(timeRange.dutyStartTime);
  const startTimeString = formatDateTime(
    startTime,
    i18next.t("common.time.displayFormat")
  );

  if (timeRange.dutyEndTime == null) {
    return `${startTimeString} - ${i18next.t("common.tbc")}`;
  }

  const endTime = isoToDateTime(timeRange.dutyEndTime);
  const endTimeString = formatDateTime(
    endTime,
    i18next.t("common.time.displayFormat")
  );

  const overnightString = timeRange.isOvernight
    ? ` (${t("shift.create.form.endTime.hour.option.overnight")})`
    : "";

  return `${startTimeString} - ${endTimeString}${overnightString}`;
};

const makeTimeRangeListDisplayText = (timeRanges: ShiftTimeRange[]) => {
  return timeRanges.map((r) => makeTimeRangeDisplayText(r)).join(" | ");
};

const makeWeeklyRecurrenceDisplayText = (byWeekday: number[]): string => {
  const dayOfWeekStrList = byWeekday.map((weekday) =>
    i18next.t(`common.dayOfWeek.${weekday}.short`)
  );
  const prefix = i18next.t("common.dayOfWeek.prefix");
  const separator = i18next.t("common.commaSepartor");
  return `${prefix}${dayOfWeekStrList.join(separator)}`;
};

interface MasterShiftTimePatternDisplayText {
  dateRange: string;
  recurrence: string | null;
  timeRanges: string;
}

const makeWeeklyRecurringTimePatternDisplayText = (
  recurringTimePattern: WeeklyRecurringMasterShiftTimePattern
): MasterShiftTimePatternDisplayText => {
  return {
    dateRange: makeDateRangeDisplayText(
      recurringTimePattern.recurrenceStartDate,
      recurringTimePattern.recurrenceEndDate
    ),
    recurrence: makeWeeklyRecurrenceDisplayText(recurringTimePattern.byWeekday),
    timeRanges: makeTimeRangeListDisplayText(
      recurringTimePattern.shiftTimeRanges
    ),
  };
};

const makeNonRecurringTimePatternDisplayText = (
  nonRecurringTimePattern: NonRecurringMasterShiftTimePattern
): MasterShiftTimePatternDisplayText => {
  return {
    dateRange: makeDateListDisplayText(nonRecurringTimePattern.shiftStartDates),
    recurrence: null,
    timeRanges: makeTimeRangeListDisplayText(
      nonRecurringTimePattern.shiftTimeRanges
    ),
  };
};

export const makeMasterShiftTimePatternDisplayText = (
  masterShiftTimePattern: MasterShiftTimePattern
): MasterShiftTimePatternDisplayText => {
  if (masterShiftTimePattern.recurring) {
    // NOTE: only support 1 frequency now (weekly)
    return makeWeeklyRecurringTimePatternDisplayText(masterShiftTimePattern);
  }
  // Non recurring
  return makeNonRecurringTimePatternDisplayText(masterShiftTimePattern);
};
