import { useCallback } from "react";
import { shallowEqual, useSelector } from "react-redux";
import * as Sentry from "@sentry/react";

import {
  Message,
  TemplateMessage,
} from "oneclick-component/src/store/apis/enhancedApi";
import { renderTemplateMessage } from "oneclick-component/src/utils/templateMessage";
import { RootState } from "../store/store";

interface RenderMessageContentHookValue {
  renderMessageContent: (message: Message) => string;
}

export function useRenderMessageContent(): RenderMessageContentHookValue {
  const templateMessageById = useSelector((state: RootState) => {
    return state.templateMessage.templateMessages.reduce<
      Partial<Record<number, TemplateMessage>>
    >((map, curr) => {
      map[curr.id] = curr;
      return map;
    }, {});
  }, shallowEqual);

  const renderMessageContent = useCallback(
    (message: Message) => {
      if (message.templateId == null) {
        return message.content ?? "";
      }
      // otherwise template message, render with param
      const templateMessage = templateMessageById[message.templateId];
      if (templateMessage == null) {
        return "";
      }
      try {
        return renderTemplateMessage(
          templateMessage.body,
          message.templateParam ?? {}
        );
      } catch (err: unknown) {
        // Silent error to avoid app crashing due to missing parameters
        // in template message, log to Sentry as this is unexpected
        console.error(
          `Failed to render template message content for template ${templateMessage.id}`
        );
        Sentry.captureException(err, {
          tags: {
            templateId: templateMessage.id,
          },
        });
        return "-";
      }
    },
    [templateMessageById]
  );

  return {
    renderMessageContent,
  };
}
