import React, { useCallback } from "react";
import cn from "classnames";
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/20/solid";
import { Trans } from "react-i18next";
import { Select } from "../inputs";

interface Props {
  className?: string;
  pageIndex: number;
  totalPages: number;
  pagePadding?: number;
  onClickPage: (pageIndex: number) => void;
}

export default function Pagination(props: Props): React.ReactElement {
  const {
    className,
    pageIndex,
    totalPages,
    onClickPage,
    pagePadding = 2,
  } = props;

  const isFirstOrLastPage = useCallback(
    (index: number) => {
      return index === 0 || index === totalPages - 1;
    },
    [totalPages]
  );

  const isWithinPagePadding = useCallback(
    (index: number) => {
      return (
        index >= pageIndex - pagePadding && index <= pageIndex + pagePadding
      );
    },
    [pageIndex, pagePadding]
  );

  const onClickPageFactory = useCallback(
    (pageIndex: number) => () => onClickPage(pageIndex),
    [onClickPage]
  );

  const onClickPrevious = useCallback(() => {
    if (pageIndex === 0) {
      return;
    }
    onClickPage(pageIndex - 1);
  }, [onClickPage, pageIndex]);
  const onClickNext = useCallback(() => {
    if (pageIndex === Math.max(totalPages - 1, 0)) {
      return;
    }
    onClickPage(pageIndex + 1);
  }, [onClickPage, pageIndex, totalPages]);
  return (
    <div
      className={cn(
        "flex",
        "items-center",
        "justify-between",
        "border-t",
        "border-gray-200",
        "px-4",
        "sm:px-0",
        "pb-2",
        "sm:pb-0",
        className
      )}
    >
      <div className={cn("-mt-px", "flex", "w-0", "flex-1")}>
        <button
          type="button"
          className={cn(
            "inline-flex",
            "items-center",
            "border-t-2",
            "border-transparent",
            "pr-1",
            "pt-4",
            "text-sm",
            "font-medium",
            "text-gray-500",
            "group",
            "disabled:text-gray-200",
            "enabled:hover:border-gray-300",
            "enabled:hover:text-gray-700"
          )}
          onClick={onClickPrevious}
          disabled={pageIndex === 0}
        >
          <ArrowLongLeftIcon
            className={cn(
              "mr-3",
              "h-5",
              "w-5",
              "group-enabled:text-gray-400",
              "group-disabled:text-gray-200"
            )}
            aria-hidden="true"
          />
          <Trans i18nKey="pagination.previous" />
        </button>
      </div>
      <div className={cn("hidden", "sm:-mt-px", "sm:flex")}>
        {new Array(totalPages).fill(0).map((_, index) => {
          if (!isFirstOrLastPage(index) && !isWithinPagePadding(index)) {
            return null;
          }
          return (
            <React.Fragment key={index}>
              {index === totalPages - 1 &&
              !isWithinPagePadding(totalPages - 2) ? (
                <span
                  className={cn(
                    "text-gray-500",
                    "text-sm",
                    "font-medium",
                    "border-t-2",
                    "border-transparent",
                    "px-4",
                    "pt-4"
                  )}
                >
                  ...
                </span>
              ) : null}
              <button
                type="button"
                onClick={onClickPageFactory(index)}
                className={cn(
                  "inline-flex",
                  "items-center",
                  "border-t-2",
                  pageIndex === index
                    ? ["border-primary-500", "text-primary-600"]
                    : [
                        "text-gray-500",
                        "border-transparent",
                        "hover:border-gray-300",
                        "hover:text-gray-700",
                      ],
                  "px-4",
                  "pt-4",
                  "text-sm",
                  "font-medium"
                )}
              >
                {index + 1}
              </button>
              {index === 0 && !isWithinPagePadding(index + 1) ? (
                <span
                  className={cn(
                    "text-gray-500",
                    "text-sm",
                    "font-medium",
                    "border-t-2",
                    "border-transparent",
                    "px-4",
                    "pt-4"
                  )}
                >
                  ...
                </span>
              ) : null}
            </React.Fragment>
          );
        })}
      </div>
      <div className={cn("sm:hidden", "-mt-px", "flex", "pt-4")}>
        {
          <Select
            options={new Array(totalPages).fill(0).map((_, index) => ({
              name: (index + 1).toString(),
              value: index,
            }))}
            value={pageIndex}
            onChange={onClickPage}
          />
        }
      </div>
      <div className={cn("-mt-px", "flex", "w-0", "flex-1", "justify-end")}>
        <button
          type="button"
          className={cn(
            "inline-flex",
            "items-center",
            "border-t-2",
            "border-transparent",
            "pl-1",
            "pt-4",
            "text-sm",
            "font-medium",
            "text-gray-500",
            "disabled:text-gray-200",
            "group",
            "enabled:hover:border-gray-300",
            "enabled:hover:text-gray-700"
          )}
          onClick={onClickNext}
          disabled={pageIndex === Math.max(totalPages - 1, 0)}
        >
          <Trans i18nKey="pagination.next" />
          <ArrowLongRightIcon
            className={cn(
              "ml-3",
              "h-5",
              "w-5",
              "group:enabled:text-gray-400",
              "group:disabled:text-gray-200"
            )}
            aria-hidden="true"
          />
        </button>
      </div>
    </div>
  );
}
