import * as z from "zod";

import {
  PtUserEmploymentStatus,
  Weekly418Status,
} from "oneclick-component/src/store/apis/enhancedApi";

import { stationSchema } from "./station";

export const PTUserWithVisitCountSchema = z.object({
  id: z.number(),
  firstNameEn: z.string(),
  lastNameEn: z.string(),
  fullNameZhHk: z.string(),
  eid: z.string(),
  email: z.string().nullable(),
  countryCode: z.string(),
  phoneNumber: z.string(),
  startWorkDate: z.string().datetime({ offset: true }),
  isDeleted: z.boolean(),
  pendingConsent: z.boolean(),
  receiveInviteOnHoliday: z.boolean(),
  employmentStatus: z.custom<PtUserEmploymentStatus>(),
  station: stationSchema.nullish(),
  role: z.object({
    id: z.number(),
    name: z.string(),
  }),
  currentWeek418Status: z
    .object({
      weekOfYear: z.object({
        year: z.number(),
        weekNumber: z.number(),
      }),
      status: z.custom<Weekly418Status>(),
    })
    .nullish(),
  scheduledSuspendAt: z.string().datetime({ offset: true }).nullish(),
  stationTeam: z
    .object({
      id: z.number(),
      stationId: z.number(),
      teamCode: z.string(),
      colorHexcode: z.string(),
    })
    .nullish(),
  visitCounts: z
    .array(
      z.object({
        id: z.number(),
        workingStationShortCode: z.string(),
        visitCount: z.number(),
      })
    )
    .nullable(),
});
