import { useMemo, useContext } from "react";

import { Option } from "oneclick-component/src/components/inputs";
import { useListShiftRateHandlerShiftRatesGetQuery as useListShiftRateQuery } from "oneclick-component/src/store/apis/enhancedApi";
import ShiftRateBadge from "oneclick-component/src/components/ShiftRateBadge";
import cn from "classnames";
import { localizeString } from "oneclick-component/src/utils/localize";
import { FeatureConfigContext } from "../providers/FeatureConfigProvider";

interface UseShiftRateOptionsProps {
  shouldRenderWithDescription?: boolean;
}
function useShiftRateOptions(
  props?: UseShiftRateOptionsProps
): Option<number>[] {
  const { shouldRenderWithDescription = true } = props ?? {};
  const { data: shiftRatesResponse } = useListShiftRateQuery();
  const { enableCcoRole } = useContext(FeatureConfigContext);

  const options: Option<number>[] = useMemo(() => {
    if (shiftRatesResponse == null) {
      return [];
    }

    const rateOptions = enableCcoRole
      ? shiftRatesResponse.rates
      : shiftRatesResponse.rates.filter((rate) => rate.code !== "CCO");
    const shiftRateOptions: Option<number>[] = rateOptions.map((rate) => ({
      name: rate.code,
      value: rate.id,
      displayComponent: shouldRenderWithDescription ? (
        <div className={cn("flex", "items-center", "gap-x-3")}>
          <ShiftRateBadge rate={rate} />
          <span className="text-sm">
            {rate.desc != null ? localizeString(rate.desc) : <></>}
          </span>
        </div>
      ) : (
        <ShiftRateBadge rate={rate} />
      ),
    }));

    return shiftRateOptions;
  }, [shiftRatesResponse, shouldRenderWithDescription, enableCcoRole]);

  return options;
}

export default useShiftRateOptions;
