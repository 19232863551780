import React, { ReactElement, useCallback, useMemo } from "react";

import cn from "classnames";

interface Props {
  className?: string;
  inputClassName?: string;
  isChecked: boolean;
  label: React.ReactNode;
  labelClassName?: string;
  onToggle: (checked: boolean) => void;
  disabled?: boolean;
}

export const ToggleSwitch = React.memo((props: Props): ReactElement => {
  const {
    className,
    inputClassName,
    labelClassName,
    isChecked,
    label,
    onToggle,
    disabled,
  } = props;

  const toggleSwitchId = useMemo(() => {
    return `toggleSwitch-${Date.now()}`;
  }, []);

  const onToggleChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      ev.stopPropagation();
      const { checked } = ev.target;
      onToggle(checked);
    },
    [onToggle]
  );

  return (
    <div className={className}>
      <input
        className={cn(
          "w-7",
          "h-3",
          "m-1",
          "appearance-none",
          "border-none",
          "rounded-full",
          "bg-gray-400",
          "after:absolute",
          "after:-mt-1",
          "after:-ml-1",
          "after:h-5",
          "after:w-5",
          "after:rounded-full",
          "after:border",
          "after:border-gray-200",
          "after:bg-white",
          "after:transition-[background-color_0.2s,transform_0.2s]",
          "after:content-['']",
          "checked:bg-none",
          "checked:bg-primary-600",
          "checked:after:-mt-1",
          "checked:after:ml-3",
          "hover:cursor-pointer",
          "hover:checked:bg-primary-600",
          "focus:outline",
          "focus:ring-2",
          "focus:ring-primary-500",
          "focus:ring-offset-8",
          "focus:after:block",
          "focus:checked:bg-primary-600",
          inputClassName
        )}
        type="checkbox"
        role="switch"
        id={toggleSwitchId}
        checked={isChecked}
        onChange={onToggleChange}
        disabled={disabled}
      />
      <label
        className={cn(
          "inline-block",
          "pl-2",
          "hover:cursor-pointer",
          "text-xs",
          labelClassName
        )}
        htmlFor={toggleSwitchId}
      >
        {label}
      </label>
    </div>
  );
});
