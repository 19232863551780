import { Badge } from "oneclick-component/src/components/Badge";
import { ReactElement } from "react";
import cn from "classnames";
import { Trans } from "react-i18next";

interface Props {
  className?: string;
}

const PTUserActiveBadge = (props: Props): ReactElement => {
  const { className } = props;
  // TODO(peter)#556 #568: load user 418 status and change this badger color
  return (
    <Badge
      className={cn("text-green-600", "border", "border-green-600", className)}
    >
      <Trans i18nKey="partTime.badge.active" />
    </Badge>
  );
};

export default PTUserActiveBadge;
