import React, { ReactElement } from "react";
import cn from "classnames";
import { PeopleIcon } from "../../icon";

interface Props {
  className?: string;
  onClick: () => void;
  unreadNotificationCount: number;
}

export const NotificationButton = React.memo((props: Props): ReactElement => {
  const { className, onClick, unreadNotificationCount } = props;

  return (
    <button
      type="button"
      className={cn(
        "relative",
        "rounded-full",
        "bg-gray-100",
        "hover:bg-gray-200",
        "focus:ring-2",
        "focus:ring-offset-2",
        "focus:outline-0",
        "focus:ring-offset-white",
        "focus:ring-primary-500",
        "p-2.5",
        className
      )}
      onClick={onClick}
    >
      <PeopleIcon className={cn("h-5", "w-5", "fill-black/60")} />
      {unreadNotificationCount > 0 ? (
        <span
          className={cn(
            "absolute",
            "-top-1",
            "right-0",
            "translate-x-1/2",
            "min-w-5",
            "h-5",
            "text-white",
            "text-xs",
            "py-0.5",
            "px-1.5",
            "font-medium",
            "bg-red-600",
            "rounded-full"
          )}
        >
          {unreadNotificationCount}
        </span>
      ) : null}
    </button>
  );
});
