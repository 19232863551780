import { useCallback, useRef, useState, useEffect } from "react";
import { v4 as uuid4 } from "uuid";
import { toast } from "react-hot-toast";
import { config } from "../config";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { fileGenerationWebSocketEventSchema } from "../models/fileGenerationWebSocketEvent";
import * as Sentry from "@sentry/react";
import useDownloadFileFromUrl from "./useDownloadFileFromUrl";
import { useShowError } from "./useShowError";
import { OneClickCustomError } from "oneclick-component/src/models/error";
import useShowMessage from "oneclick-component/src/hooks/useShowMessage";
import { useTranslation } from "react-i18next";
import { useLazyGenerateWebsocketTokenHandlerTokenWebsocketGetQuery as useLazyGenerateWebsocketTokenQuery } from "oneclick-component/src/store/apis/enhancedApi";

const useGenerateShiftPdfWebSocket = (): ((shiftIds: number[]) => void) => {
  const [shouldConnectWebsocket, setShouldConnectWebsocket] =
    useState<boolean>(false);
  const [shiftIds, setShiftIds] = useState<number[]>([]);

  const showMessage = useShowMessage();
  const { showError } = useShowError();
  const { t } = useTranslation();
  const loadingToastIds = useRef<Set<string>>(new Set<string>());
  const pendingTaskIds = useRef<Set<string>>(new Set<string>());

  const [generateWebsocketToken] = useLazyGenerateWebsocketTokenQuery();
  const downloadFileFromUrl = useDownloadFileFromUrl();
  const getWebSocketUrl = useCallback(async () => {
    const { token } = await generateWebsocketToken().unwrap();
    const webSocketUrl = new URL("/ws/connect/shifts-export", config.wsBaseUrl);
    webSocketUrl.searchParams.append("token", token);
    for (const shiftId of shiftIds) {
      webSocketUrl.searchParams.append("shiftIds", `${shiftId}`);
    }
    return webSocketUrl.href;
  }, [generateWebsocketToken, shiftIds]);

  const dismissLoadingToasts = useCallback(() => {
    for (const tid of loadingToastIds.current.keys()) {
      toast.dismiss();
      loadingToastIds.current.delete(tid);
    }
  }, []);

  const onMessage = useCallback(
    (event: MessageEvent) => {
      let eventData;
      try {
        console.info(
          "Received websocket event in shift websocket: ",
          event.data
        );
        const rawData = JSON.parse(event.data);
        eventData = fileGenerationWebSocketEventSchema.parse(rawData);
        console.info(eventData.type, eventData.payload);
      } catch (err: unknown) {
        // Nothing can be done by user, silent error capture to Sentry
        console.warn("Failed to parse event: ", err);
        Sentry.captureException(err);
        return;
      }
      const taskId =
        typeof eventData.payload === "object" ? eventData.payload.taskId : null;
      if (taskId == null || !pendingTaskIds.current.has(taskId)) {
        console.log(
          `Skiping unrecognised generate shift PDF task ${taskId ?? "-"}`
        );
        return;
      }
      switch (eventData.type) {
        case "fileBlobUploaded": {
          pendingTaskIds.current.delete(taskId);
          downloadFileFromUrl(eventData.payload.blobUrl);
          dismissLoadingToasts();
          setShouldConnectWebsocket(false);
          break;
        }
        case "fileGenerationStarted": {
          const toastId = showMessage({
            type: "info",
            title: t("shift.exportPdf.started"),
          });
          loadingToastIds.current.add(toastId);
          break;
        }
        case "fileGenerationFailed": {
          pendingTaskIds.current.delete(taskId);
          dismissLoadingToasts();
          showError(
            new OneClickCustomError(t("shift.exportPdf.failed")),
            "shift.exportPdf.failed"
          );
          setShouldConnectWebsocket(false);
          break;
        }
        default:
          break;
      }
    },
    [downloadFileFromUrl, showMessage, t, showError, dismissLoadingToasts]
  );

  const { sendMessage, readyState } = useWebSocket(
    getWebSocketUrl,
    {
      onMessage,
    },
    shouldConnectWebsocket
  );

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      const taskId = uuid4();
      sendMessage(
        JSON.stringify({
          type: "triggerPdfGeneration",
          taskId: taskId,
        })
      );
      pendingTaskIds.current.add(taskId);
    }
    return () => {};
  }, [readyState, shiftIds, sendMessage]);

  const triggerPdfGeneration = useCallback((shiftIds: number[]) => {
    setShiftIds(shiftIds);
    setShouldConnectWebsocket(true);
  }, []);
  return triggerPdfGeneration;
};

export default useGenerateShiftPdfWebSocket;
