import React, { useMemo } from "react";
import { DateTime } from "luxon";
import i18next from "i18next";
import cn from "classnames";

interface Props {
  date: DateTime;
}

export const MessageListDateSeparator = React.memo((props: Props) => {
  const { date } = props;

  const formatedDate = useMemo(() => {
    return date.setLocale(i18next.language).toFormat("DDD");
  }, [date]);

  return (
    <div className={cn("mb-2", "text-center")}>
      <span
        className={cn("text-gray-400", "text-sm", "font-medium", "leading-5")}
      >
        {formatedDate}
      </span>
    </div>
  );
});
