import {
  formatDateTime,
  isToday,
  isYesterday,
  localizeDateTime,
} from "oneclick-component/src/utils/datetime";
import i18next from "i18next";
import { DateTime } from "luxon";

export const formatShiftRequestTime = (dt: DateTime): string => {
  if (isToday(dt)) {
    return `${i18next.t("common.today")} ${localizeDateTime(
      dt,
      DateTime.TIME_24_SIMPLE
    )}`;
  }
  if (isYesterday(dt)) {
    return `${i18next.t("common.yesterday")} ${localizeDateTime(
      dt,
      DateTime.TIME_24_SIMPLE
    )}`;
  }
  const dtDisplayFormat = i18next.t("shiftDetail.request.time.displayFormat");
  return formatDateTime(dt, dtDisplayFormat);
};
