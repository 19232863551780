import * as z from "zod";
import { chatSchema } from "./chat";
import { messageSchema, messageStatusSchema } from "./message";
import { fullTimeAttendanceSchema } from "./fullTimeAttendance";
import { ShiftRequestBaseWithNumberingSchema } from "./shiftRequest";

export const webSocketEvents = [
  "heartbeat",
  "chatRead",
  "messageStatusUpdate",
  "newChat",
  "newMessage",
  "newFullTimeAttendance",
  "cancelFullTimeAttendance",
  "updateShiftRequest",
  "cancelShiftRequest",
] as const;
export const webSocketEventTypeSchema = z.enum(webSocketEvents);
export type WebSocketEventType = z.infer<typeof webSocketEventTypeSchema>;

export const webSocketEventBaseSchema = z.object({
  type: webSocketEventTypeSchema,
  payload: z.any(),
});

function createWebSocketEventSchema<
  TType extends WebSocketEventType,
  TPayload extends z.ZodTypeAny,
>(eventType: TType, payloadSchema: TPayload) {
  return z.object({
    type: z.literal(eventType),
    payload: payloadSchema,
  });
}

export const chatReadEventPayloadSchema = z.object({
  chatId: z.number(),
  stationId: z.number(),
  lastReadAt: z.string(),
});
export type ChatReadEventPayload = z.infer<typeof chatReadEventPayloadSchema>;

export const messageStatusUpdateEventPayloadSchema = z.object({
  chatId: z.number(),
  messageId: z.number(),
  status: messageStatusSchema,
});
export type MessageStatusUpdateEventPayload = z.infer<
  typeof messageStatusUpdateEventPayloadSchema
>;

export const newChatEventPayloadSchema = z.object({
  chat: chatSchema,
});
export type NewChatEventPayload = z.infer<typeof newChatEventPayloadSchema>;

export const newMessageEventPayloadSchema = z.object({
  message: messageSchema,
});
export type NewMessageEventPayload = z.infer<
  typeof newMessageEventPayloadSchema
>;

export const fullTimeAttendancePayloadSchema = z.object({
  attendance: fullTimeAttendanceSchema,
});
export type FullTimeAttendanceEventPayload = z.infer<
  typeof fullTimeAttendancePayloadSchema
>;

export const shiftRequestPayloadSchema = z.object({
  shiftRequest: ShiftRequestBaseWithNumberingSchema,
});
export type ShiftRequestEventPayload = z.infer<
  typeof shiftRequestPayloadSchema
>;

export const heartbeatEventSchema = createWebSocketEventSchema(
  "heartbeat",
  z.null().optional()
);

export const chatReadEventSchema = createWebSocketEventSchema(
  "chatRead",
  chatReadEventPayloadSchema
);

export const messageStatusUpdateEventSchema = createWebSocketEventSchema(
  "messageStatusUpdate",
  messageStatusUpdateEventPayloadSchema
);

export const newChatEventSchema = createWebSocketEventSchema(
  "newChat",
  newChatEventPayloadSchema
);

export const newMessageEventSchema = createWebSocketEventSchema(
  "newMessage",
  newMessageEventPayloadSchema
);

export const newFullTimeAttendanceEventSchema = createWebSocketEventSchema(
  "newFullTimeAttendance",
  fullTimeAttendancePayloadSchema
);

export const cancelFullTimeAttendanceEventSchema = createWebSocketEventSchema(
  "cancelFullTimeAttendance",
  fullTimeAttendancePayloadSchema
);

export const updateShiftRequestEventSchema = createWebSocketEventSchema(
  "updateShiftRequest",
  shiftRequestPayloadSchema
);

export const cancelShiftRequestEventSchema = createWebSocketEventSchema(
  "cancelShiftRequest",
  shiftRequestPayloadSchema
);

export const webSocketEventSchema = z.union([
  heartbeatEventSchema,
  chatReadEventSchema,
  messageStatusUpdateEventSchema,
  newChatEventSchema,
  newMessageEventSchema,
  newFullTimeAttendanceEventSchema,
  cancelFullTimeAttendanceEventSchema,
  updateShiftRequestEventSchema,
  cancelShiftRequestEventSchema,
]);
