import React, { ReactElement } from "react";
import cn from "classnames";

interface LoadingSpinnerProps {
  className?: string;
  size?: "xs" | "s" | "m" | "l";
  color?: string;
}

export const LoadingSpinner = React.memo(
  (props: LoadingSpinnerProps): ReactElement => {
    const { className, size = "m" } = props;

    return (
      <div
        className={cn(
          "inline-block",
          "animate-spin",
          "rounded-full",
          "border-solid",
          "border-black/[0.12]",
          "border-r-primary-400",
          "align-[-0.125em]",
          "motion-reduce:animate-[spin_1.5s_linear_infinite]",
          className,
          {
            "h-12 w-12 border-[6px]": size === "l",
            "h-7 w-7 border-4": size === "m",
            "h-6 w-6 border-[3px]": size === "s",
            "h-5 w-5 border-2": size === "xs",
          }
        )}
        role="status"
      />
    );
  }
);
