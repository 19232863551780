import React, { useMemo } from "react";
import { UseFormReturn } from "react-hook-form";
import { Option } from "oneclick-component/src/components/inputs";
import { WorkingStation } from "oneclick-component/src/store/apis/enhancedApi";
import { MultiShiftFormValues } from "./form";
import IncidentFormContent from "./IncidentFormContent";
import RegularFormContent from "./RegularFormContent";

interface Props
  extends React.DetailedHTMLProps<
    React.FormHTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  > {
  form: UseFormReturn<MultiShiftFormValues>;
  onWorkingStationSelected: (workingStation: WorkingStation | null) => void;
  onSupportStationSelected?: (supportStation: WorkingStation | null) => void;
  partTimeGroupOptions: Option<string>[];
  shiftRateOptions: Option<number>[];
  onSubmit: () => void;
  isEditing: boolean;
}

const MultiShiftForm = (props: Props): React.ReactElement => {
  const {
    onSubmit,
    form,
    onWorkingStationSelected,
    onSupportStationSelected,
    partTimeGroupOptions,
    shiftRateOptions,
    isEditing,
    ...rest
  } = props;

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = form;

  const formValues = watch();

  const isIncidentShift = useMemo(() => {
    return formValues.shiftType === "INCIDENT";
  }, [formValues]);

  return (
    <form onSubmit={onSubmit} {...rest}>
      {isIncidentShift ? (
        <IncidentFormContent
          formValues={formValues}
          setValue={setValue}
          control={control}
          errors={errors}
          partTimeGroupOptions={partTimeGroupOptions}
          onSupportStationSelected={onSupportStationSelected}
          isEditing={isEditing}
          onSubmit={onSubmit}
        />
      ) : (
        <RegularFormContent
          formValues={formValues}
          setValue={setValue}
          errors={errors}
          control={control}
          onWorkingStationSelected={onWorkingStationSelected}
          partTimeGroupOptions={partTimeGroupOptions}
          shiftRateOptions={shiftRateOptions}
          isEditing={isEditing}
          onSubmit={onSubmit}
        />
      )}
    </form>
  );
};

export default MultiShiftForm;
