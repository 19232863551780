import { ReactElement, useCallback, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import cn from "classnames";
import {
  Shift,
  useHireHandlerShiftsShiftIdHirePostMutation as useHire,
  PtUser,
  Weekly418Status,
  ListShiftRequestsWithNumberingHandlerShiftsShiftIdRequestsGetApiResponse as ListShiftRequestResponse,
} from "oneclick-component/src/store/apis/enhancedApi";
import useShowMessage from "oneclick-component/src/hooks/useShowMessage";
import { UseInfiniteLoadQueryResponse } from "oneclick-component/src/hooks/useInfiniteQuery";
import { useShowError } from "../../../hooks/useShowError";
import RequestedSection from "./RequestedUsers";
import ResponsedSection from "./ResponsedUsers";
import {
  ResponsedShiftRequestBaseWithNumbering,
  RequestedShiftRequestBaseWithNumbering,
} from "../../../models/shiftRequest";
import { SelectComponentProps } from "oneclick-component/src/components/inputs";
import { StationFilter } from "./types";
import { OneClickCustomError } from "oneclick-component/src/models/error";

interface Props {
  shift: Shift;
  status418Filter: SelectComponentProps<Weekly418Status | null>;
  stationFilter: SelectComponentProps<StationFilter | null>;
  fulfillmentLeft: number;
  requestedShiftRequestsQuery: UseInfiniteLoadQueryResponse<ListShiftRequestResponse>;
  respondedShiftRequestsQuery: UseInfiniteLoadQueryResponse<ListShiftRequestResponse>;
  hiredUserIds: number[];
  addHiredUserIds: (userIds: number[]) => void;
}

const RequestedPTUserSection = (props: Props): ReactElement => {
  const {
    shift,
    fulfillmentLeft,
    status418Filter,
    stationFilter,
    requestedShiftRequestsQuery,
    respondedShiftRequestsQuery,
    hiredUserIds,
    addHiredUserIds,
  } = props;
  const { t } = useTranslation();
  const { showError } = useShowError();
  const showMessage = useShowMessage();
  const [hire, { isLoading }] = useHire();

  const responsedRequests = useMemo(() => {
    if (respondedShiftRequestsQuery.data == null) {
      return [];
    }
    return [...respondedShiftRequestsQuery.data.results].sort(
      (a, b) => a.index - b.index
    );
  }, [respondedShiftRequestsQuery]);
  const requestedRequests = useMemo(() => {
    if (requestedShiftRequestsQuery.data == null) {
      return [];
    }
    return [...requestedShiftRequestsQuery.data.results].sort(
      (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    );
  }, [requestedShiftRequestsQuery]);

  const handleHire = useCallback(
    (targetUsers: PtUser[]) => {
      hire({
        shiftId: shift.id,
        hireRequestParam: {
          ptUserIds: targetUsers.map((user) => user.id),
          isDirectHire: false,
        },
      })
        .unwrap()
        .then(({ hiredShiftRequests }) => {
          const hiredUsers = hiredShiftRequests
            .map((sq) => sq.ptUser)
            .filter((pt) => targetUsers.map((pt) => pt.id).includes(pt.id));

          addHiredUserIds(hiredUsers.map((user) => user.id));
          /* Ref issue comment for below toast display handling https://github.com/oursky/mtr-oneclick/issues/1693#issuecomment-1827398394 */
          if (hiredUsers.length > 0) {
            // show success if any hired
            showMessage({
              type: "success",
              title: t("shiftDetail.hire.sent.title"),
              message: t("shiftDetail.hire.sent.message", {
                ptNameList: hiredUsers.map((pt) => pt.fullNameZhHk).join(", "),
              }),
            });
          } else if (hiredUsers.length === 0 && targetUsers.length > 0) {
            // show success if none hired
            showError(
              new OneClickCustomError(
                t("shiftDetail.hired.fail.clashedShift.message")
              ),
              t("shiftDetail.hired.fail.clashedShift.title")
            );
          }

          if (
            targetUsers.length > 0 &&
            hiredUsers.length < targetUsers.length
          ) {
            // show info that clashed_shift_pt data is being synced if some hired
            showMessage({
              type: "info",
              title: t("shiftDetail.hire.clashedShift.title"),
              message: t("shiftDetail.hire.clashedShift.message", {
                ptNameList: targetUsers
                  .filter(
                    (targetPt) =>
                      !hiredUsers.map((pt) => pt.id).includes(targetPt.id)
                  )
                  .map((pt) => pt.fullNameZhHk)
                  .join(", "),
              }),
            });
          }
        })
        .catch((e: unknown) => {
          showError(e, "shiftDetail.hire.sent.fail.title");
        });
    },
    [hire, showMessage, t, shift, showError, addHiredUserIds]
  );

  return (
    <section
      className={cn(
        "rounded-lg",
        "bg-white",
        "shadow",
        "lg:w-4/5",
        "lg:min-w-[910px]",
        "mx-auto",
        "mb-5",
        "w-full"
      )}
    >
      <div className={cn("md:p-6", "p-4")}>
        <div className={cn("flex", "flex-col", "md:gap-5", "gap-3")}>
          <p className={cn("md:font-medium", "text-lg", "grow", "font-normal")}>
            <Trans i18nKey="shiftDetail.requested.title" />
          </p>
          <ResponsedSection
            shift={shift}
            requests={
              responsedRequests as ResponsedShiftRequestBaseWithNumbering[]
            }
            ptUsersWithClashedShifts={
              respondedShiftRequestsQuery.data?.ptUsersWithClashedShifts ?? []
            }
            fulfillmentLeft={fulfillmentLeft}
            onHire={handleHire}
            notifyContacted={true}
            isSending={isLoading}
            status418Filter={status418Filter}
            stationFilter={stationFilter}
            respondedShiftRequestsQuery={respondedShiftRequestsQuery}
            hiredUserIds={hiredUserIds}
          />
          <RequestedSection
            shift={shift}
            requests={
              requestedRequests as RequestedShiftRequestBaseWithNumbering[]
            }
            requestedShiftRequestsQuery={requestedShiftRequestsQuery}
          />
        </div>
      </div>
    </section>
  );
};

export default RequestedPTUserSection;
