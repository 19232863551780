import React, { useMemo } from "react";
import { DateTime } from "luxon";
import cn from "classnames";
import { Trans } from "react-i18next";

import { dateTimeNow } from "oneclick-component/src/utils/datetime";

interface Props {
  isPrivateMode: boolean;
  expireDateTime: DateTime | null;
}

export const MessageListChatExpiredIndicator = React.memo((props: Props) => {
  const { isPrivateMode, expireDateTime } = props;

  const formattedExpireTimestamp = useMemo(() => {
    if (expireDateTime == null) {
      return null;
    }

    const nowDatetime = dateTimeNow();
    const sameDate = nowDatetime.toISODate() === expireDateTime.toISODate();
    const sameYear = nowDatetime.year === expireDateTime.year;

    if (sameDate) {
      return expireDateTime.toFormat("h:mm a");
    } else if (sameYear) {
      return expireDateTime.toFormat("dd/LL h:mm a");
    }
    // not same year
    return expireDateTime.toFormat("dd/LL/yyyy h:mm a");
  }, [expireDateTime]);

  return (
    <div className={cn("flex", "items-center", "px-5", "mb-5", "text-center")}>
      <div
        className={cn("flex-1", "border-b", {
          "border-black/[0.12]": !isPrivateMode,
          "border-white/20": isPrivateMode,
        })}
      />
      <div className="text-sm">
        <span
          className={cn("block", "px-3", {
            "text-black/[0.86]": !isPrivateMode,
            "text-white": isPrivateMode,
          })}
        >
          <Trans i18nKey="chatDialog.messageItem.chatExpiredIndicator.message" />
        </span>
        {formattedExpireTimestamp != null ? (
          <span
            className={cn("block", {
              "text-black/60": !isPrivateMode,
              "text-white": isPrivateMode,
            })}
          >
            {formattedExpireTimestamp}
          </span>
        ) : null}
      </div>
      <div
        className={cn("flex-1", "border-b", {
          "border-black/[0.12]": !isPrivateMode,
          "border-white/20": isPrivateMode,
        })}
      />
    </div>
  );
});
