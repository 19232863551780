import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

const allApiErrorCodes = [
  "UNKNOWN",
  "INVALID_REQUEST",
  "UNAUTHORIZED",
  "FORBIDDEN",
  "NOT_FOUND",
  "CONFLICT",
  "INTERNAL_ERROR",
  "NETWORK",
  "TIMEOUT", // Add more API error code here
] as const;
export type ApiErrorCode = (typeof allApiErrorCodes)[number];

export function isApiErrorCode(code: string): code is ApiErrorCode {
  return allApiErrorCodes.includes(code as ApiErrorCode);
}

// ref: https://redux-toolkit.js.org/rtk-query/usage-with-typescript
export function isFetchQueryBaseError(
  error: unknown
): error is FetchBaseQueryError {
  return typeof error === "object" && error != null && "status" in error;
}

// In app error with custom error message
export class OneClickCustomError extends Error {
  message: string;

  constructor(message: string) {
    super(message);
    this.message = message;
    // Fix instanceof not working for class extends Error class
    // Ref: https://github.com/microsoft/TypeScript/issues/13965
    Object.setPrototypeOf(this, OneClickCustomError.prototype);
  }
}
