import React, { useCallback, useState, useContext } from "react";
import cn from "classnames";
import {
  PtUserWithVisitCount,
  Shift,
  ShiftStatus,
  ListPtUserShiftCancelByShiftHandlerPtUserShiftCancelsShiftIdGetApiResponse as ListCancelledRecordResponse,
} from "oneclick-component/src/store/apis/enhancedApi";
import { Badge } from "oneclick-component/src/components/Badge";
import { ChevronDownIcon, DotsVertical } from "oneclick-component/src/icon";
import { IconButton } from "oneclick-component/src/components/Button";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import HireStatusSection from "./HireStatusSection";
import { UseInfiniteQueryReturnValue } from "oneclick-component/src/hooks/useInfiniteQuery";
import ProgressBar from "oneclick-component/src/components/ProgressBar";
import PopMenu, {
  PopMenuItem,
} from "oneclick-component/src/components/PopMenu";
import AppRoutes from "../../routes/AppRoutes";
import ShiftDetailPanel from "./ShiftDetailPanel";
import { HiredShiftRequestBaseWithNumbering } from "../../models/shiftRequest";
import ShiftBadge from "oneclick-component/src/components/ShiftRateBadge/ShiftBadge";
import useShiftAccessControl from "../../hooks/useShiftAccessControl";
import ShiftT3Badge from "oneclick-component/src/components/Badge/ShiftT3Badge";
import { FeatureConfigContext } from "../../providers/FeatureConfigProvider";
import { useIsSuperAdmin } from "../../hooks/role";

interface ActionMenuProps {
  shiftId: number;
  onClickCancelShift: () => void;
}

const ActionMenu = (props: ActionMenuProps) => {
  const { shiftId, onClickCancelShift } = props;
  const { t } = useTranslation();
  return (
    <>
      <div className="py-1">
        <PopMenuItem
          type="link"
          link={AppRoutes.EditShiftScreen.render(shiftId)}
          text={t("shiftDetail.actionMenu.editShift")}
        />
        <PopMenuItem
          type="button"
          className={"text-rose-500"}
          onClick={onClickCancelShift}
          text={t("shiftDetail.actionMenu.cancelShift")}
        />
      </div>
    </>
  );
};

interface StatusBadgeProps {
  status: ShiftStatus;
}

const ShiftDetailStatusBadge = (
  props: StatusBadgeProps
): React.ReactElement => {
  const { status } = props;
  switch (status) {
    case "active":
      return (
        <Badge className={cn("text-blue-800", "bg-blue-100")}>
          <Trans i18nKey="shiftDetail.detail.hiring" />
        </Badge>
      );
    case "expired":
      return (
        <Badge className={cn("text-black/60", "bg-black/12")}>
          <Trans i18nKey="shiftDetail.detail.expired" />
        </Badge>
      );
    case "cancelled":
      return (
        <Badge className={cn("text-rose-800", "bg-rose-100")}>
          <Trans i18nKey="shiftDetail.detail.cancelled" />
        </Badge>
      );
    default:
  }
  return <></>;
};

interface Props {
  shift: Shift;
  hiredRequests: HiredShiftRequestBaseWithNumbering[];
  cancelledRecordQuery: UseInfiniteQueryReturnValue<ListCancelledRecordResponse>;
  isHiredShiftRequestsLoading: boolean;
  onClickCancelShift: () => void;
  onCancelUser: (user: PtUserWithVisitCount) => void;
  onExportPdf: (shiftId: number) => void;
  className?: string;
}

const ShiftDetailSection = (props: Props): React.ReactElement => {
  const { shouldShowT3Feature } = useContext(FeatureConfigContext);
  const {
    shift,
    hiredRequests,
    cancelledRecordQuery,
    isHiredShiftRequestsLoading,
    onClickCancelShift,
    onCancelUser,
    onExportPdf,
  } = props;
  const [isHireSectionExpanded, setIsHireSectionExpanded] = useState(true);
  const onClickHirePanel = useCallback(() => {
    setIsHireSectionExpanded((value) => !value);
  }, []);

  const handleClickExportPdf = useCallback(() => {
    onExportPdf(shift.id);
  }, [shift.id, onExportPdf]);

  const { t } = useTranslation();
  const isSuperAdmin = useIsSuperAdmin();

  const { canCancel } = useShiftAccessControl(shift);

  return (
    <section className={cn("flex", "flex-col", "gap-5")}>
      <div>
        <div className={cn("flex", "flex-row", "items-center")}>
          <div
            className={cn(
              "flex",
              "flex-row",
              "gap-x-3",
              "items-center",
              "grow",
              "overflow-x-auto"
            )}
          >
            <ShiftBadge rate={shift.rate} isIncident={shift.isIncident} />
            <p className={cn("font-medium", "text-lg")}>{shift.shiftTitle}</p>
            <ShiftDetailStatusBadge status={shift.status} />
            {shouldShowT3Feature && !shift.isIncident ? (
              <>
                <div
                  className={cn(
                    "border",
                    "border-solid",
                    "border-black/24",
                    "self-stretch",
                    "w-[1px]",
                    "my-[5px]"
                  )}
                ></div>
                <ShiftT3Badge active={shift.isAppliableToAllAtT3} />
              </>
            ) : null}
          </div>
          {shift.status === "active" && canCancel ? (
            <PopMenu
              menuClass="relative ml-3"
              controlElementClass="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              controlElement={<DotsVertical className={cn("w-5", "h-5")} />}
              menuBodyClass="right-0"
            >
              <ActionMenu
                shiftId={shift.id}
                onClickCancelShift={onClickCancelShift}
              />
            </PopMenu>
          ) : null}
          {shift.status === "expired" && !isSuperAdmin ? (
            <PopMenu
              menuClass="relative ml-3"
              controlElementClass={cn(
                "inline-flex",
                "justify-center",
                "items-center",
                "gap-x-1.5",
                "rounded-md",
                "shadow-sm",
                "focus:ring-2",
                "focus:ring-offset-2",
                "focus:outline-0",
                "px-4",
                "py-2",
                "font-medium",
                "text-sm",
                "bg-primary-600",
                "hover:bg-primary-700",
                "focus:ring-offset-white",
                "focus:ring-indigo-500",
                "text-white",
                "disabled:bg-black/25",
                "break-keep"
              )}
              controlElement={<Trans i18nKey="shiftDetail.export" />}
            >
              <div className="py-1">
                <PopMenuItem
                  type="button"
                  onClick={handleClickExportPdf}
                  className={"text-gray-700"}
                  text={t("shiftList.tab.completed.exportMenu.pdf")}
                />
              </div>
            </PopMenu>
          ) : null}
        </div>

        <Link
          to={AppRoutes.MultiShiftInvitationScreen.render(shift.masterShiftId)}
        >
          <p
            className={cn(
              "font-normal",
              "text-xs",
              "leading-5",
              "text-black/60",
              { "text-red-600": shift.shiftType === "INCIDENT" },
              "mt-1"
            )}
          >
            <Trans
              i18nKey="shiftList.table.displayId"
              values={{ displayId: shift.displayId }}
            />
          </p>
        </Link>
      </div>
      <ShiftDetailPanel shift={shift} hiredRequests={hiredRequests} />
      <div
        className={cn(
          "relative",
          "border",
          "border-black/10",
          "border-solid",
          isHireSectionExpanded ? "py-5" : "py-2",
          "h-full",
          "px-5",
          "rounded-lg",
          "flex",
          "justify-between",
          "transition-all",
          { "overflow-hidden": !isHireSectionExpanded }
        )}
      >
        {!isHireSectionExpanded ? (
          <div
            className={cn(
              "flex",
              "flex-row",
              "w-full",
              "mr-6",
              "cursor-pointer"
            )}
            onClick={onClickHirePanel}
          >
            <p
              className={cn(
                "text-sm",
                "leading-5",
                "font-normal",
                "grow",
                "text-black/90"
              )}
            >
              <Trans i18nKey="shiftDetail.hire.title" />
            </p>
            <ProgressBar
              count={hiredRequests.length}
              max={shift.fulfillmentCount}
            />
          </div>
        ) : (
          <HireStatusSection
            shift={shift}
            hiredRequests={hiredRequests}
            isLoading={isHiredShiftRequestsLoading}
            onCancelUser={onCancelUser}
            cancelledRecordQuery={cancelledRecordQuery}
          />
        )}

        <IconButton
          className={cn(
            "w-5",
            "h-5",
            "right-5",
            "!p-0",
            "border-none",
            "!shadow-none",
            "absolute",
            "fill-gray-500",
            { "origin-center rotate-180": isHireSectionExpanded }
          )}
          theme="white"
          icon={ChevronDownIcon}
          onClick={onClickHirePanel}
        />
      </div>
    </section>
  );
};

export default ShiftDetailSection;
