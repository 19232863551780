import { ThunkAction, Action } from "@reduxjs/toolkit";
import { enhancedApi as api } from "oneclick-component/src/store/apis/enhancedApi";
import { store, RootState } from "./store";
import { ShiftRequestEventPayload } from "../models/webSocketEvent";

export const removeShiftRequestFromShiftListCaches = (
  payload: ShiftRequestEventPayload
): ThunkAction<
  void,
  RootState,
  unknown,
  Action<"listShiftRequestsWithNumberingHandlerShiftsShiftIdRequestsGet">
  // eslint-disable-next-line sonarjs/cognitive-complexity
>[] => {
  const actions = [];

  /** Search for all `IncidentShifts` cache with different params to update */
  for (const { endpointName, originalArgs } of api.util.selectInvalidatedBy(
    store.getState(),
    [{ type: "ShiftRequests" }]
  )) {
    if (
      endpointName ===
        "listShiftRequestsWithNumberingHandlerShiftsShiftIdRequestsGet" &&
      originalArgs.shiftId === payload.shiftRequest.shiftId
    ) {
      actions.push(
        api.util.updateQueryData(endpointName, originalArgs, (draft) => {
          const ptUserIds = draft.results.map((req) => req.ptUserId);
          /** remove it when new payload found in the query */
          if (ptUserIds.includes(payload.shiftRequest.ptUserId)) {
            draft.results = draft.results.filter(
              (req) => req.ptUserId !== payload.shiftRequest.ptUserId
            );
          }
        })
      );
    }
  }

  /** Search for all shift list cache with different params to remove target shift request from their `shiftRequests` */
  for (const { endpointName, originalArgs } of api.util.selectInvalidatedBy(
    store.getState(),
    [{ type: "IncidentShifts" }]
  )) {
    if (endpointName === "listShiftsHandlerShiftsGet") {
      actions.push(
        api.util.updateQueryData(endpointName, originalArgs, (draft) => {
          for (const shift of draft.results) {
            if (shift.id === payload.shiftRequest.shiftId) {
              shift.shiftRequests = shift.shiftRequests.filter(
                (req) => req.ptUserId !== payload.shiftRequest.ptUserId
              );
            }
          }
        })
      );
    }
  }

  return actions;
};

export const updateShiftRequestInShiftListCaches = (
  payload: ShiftRequestEventPayload
): ThunkAction<
  void,
  RootState,
  unknown,
  Action<"listShiftRequestsWithNumberingHandlerShiftsShiftIdRequestsGet">
  // eslint-disable-next-line sonarjs/cognitive-complexity
>[] => {
  const actions = [];

  /** Search for all `ShiftRequests` cache with different params to update */
  for (const { endpointName, originalArgs } of api.util.selectInvalidatedBy(
    store.getState(),
    [{ type: "ShiftRequests" }]
  )) {
    if (
      endpointName ===
        "listShiftRequestsWithNumberingHandlerShiftsShiftIdRequestsGet" &&
      originalArgs.shiftId === payload.shiftRequest.shiftId
    ) {
      const isIncludePayloadStatus =
        originalArgs.statusList == null
          ? true
          : originalArgs.statusList.includes(payload.shiftRequest.status);

      /** if payload status is belongs to this query result */
      if (isIncludePayloadStatus) {
        actions.push(
          api.util.updateQueryData(endpointName, originalArgs, (draft) => {
            const ptUserIds = draft.results.map((req) => req.ptUserId);

            /** if payload does not exist in the result array, add it into results */
            if (!ptUserIds.includes(payload.shiftRequest.ptUserId)) {
              draft.results.push({
                ...payload.shiftRequest,
                index: draft.results.length + 1,
              });
            } else {
              /** update existing shift request status to new payload */
              for (const shiftRequest of draft.results) {
                if (shiftRequest.ptUserId === payload.shiftRequest.ptUserId) {
                  shiftRequest.status = payload.shiftRequest.status;
                }
              }
            }
          })
        );
      } else {
        /** new payload does not belong to this query result */
        actions.push(
          api.util.updateQueryData(endpointName, originalArgs, (draft) => {
            const ptUserIds = draft.results.map((req) => req.ptUserId);
            /** remove it when new payload found in the query */
            if (ptUserIds.includes(payload.shiftRequest.ptUserId)) {
              draft.results = draft.results.filter(
                (req) => req.ptUserId !== payload.shiftRequest.ptUserId
              );
            }
          })
        );
      }
    }
  }

  /** Search for all shift list cache with different params to update their `shiftRequests` */
  for (const { endpointName, originalArgs } of api.util.selectInvalidatedBy(
    store.getState(),
    [{ type: "IncidentShifts" }]
  )) {
    if (endpointName === "listShiftsHandlerShiftsGet") {
      actions.push(
        api.util.updateQueryData(endpointName, originalArgs, (draft) => {
          for (const shift of draft.results) {
            if (shift.id === payload.shiftRequest.shiftId) {
              const targetShiftRequest = shift.shiftRequests.find(
                (req) => req.ptUserId === payload.shiftRequest.ptUserId
              );
              if (targetShiftRequest != null) {
                targetShiftRequest.status = payload.shiftRequest.status;
              } else {
                const newRequest = { ...payload.shiftRequest };
                delete newRequest.index;
                shift.shiftRequests.push(newRequest);
              }
            }
          }
        })
      );
    }
  }

  return actions;
};
