import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { TemplateMessage } from "oneclick-component/src/store/apis/enhancedApi";

export interface TemplateMessageState {
  templateMessages: TemplateMessage[];
}

export interface LoadedTemplateMessagePayload {
  templateMessages: TemplateMessage[];
}

const initialState: TemplateMessageState = {
  templateMessages: [],
};

const templateMessageSlice = createSlice({
  name: "templateMessage",
  initialState,
  reducers: {
    loadedTemplateMessage: (
      state,
      action: PayloadAction<LoadedTemplateMessagePayload>
    ) => {
      return {
        ...state,
        templateMessages: action.payload.templateMessages,
      };
    },
  },
});

export const { loadedTemplateMessage } = templateMessageSlice.actions;

export default templateMessageSlice.reducer;
