import React, {
  ForwardedRef,
  ReactElement,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { ChevronDownIcon } from "../../../icon";
import cn from "classnames";
import "./Calendar.css";

export interface CalendarProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  className?: string;
  placeholderClassName?: string;
  hasError?: boolean;
  formattedValue?: string;
}

// eslint-disable-next-line complexity
export const Calendar = React.forwardRef(function Calendar(
  props: CalendarProps,
  ref: ForwardedRef<HTMLInputElement>
): ReactElement {
  const {
    className,
    placeholder = "",
    placeholderClassName,
    value,
    hasError = false,
    onFocus,
    onBlur,
    formattedValue,
    disabled,
    ...rest
  } = props;
  const calendarRef = useRef<HTMLInputElement>(null);
  useImperativeHandle(ref, () => calendarRef.current!);
  const [isInputFocused, setIsInputFocused] = useState(false);

  const onInputFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      setIsInputFocused(true);
      onFocus?.(e);
    },
    [onFocus]
  );

  const onInputBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      setIsInputFocused(false);
      onBlur?.(e);
    },
    [onBlur]
  );

  const onKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    return false;
  }, []);

  return (
    <div
      className={cn(
        "flex",
        "items-center",
        "rounded-md",
        "px-3",
        "relative",
        "min-h-10.5",
        "pl-3",
        "text-gray-900",
        isInputFocused ? "ring-2" : "ring-1",
        isInputFocused && (hasError ? "ring-red-500" : "ring-primary-600"),
        !isInputFocused && (hasError ? "ring-red-300" : "ring-gray-300"),
        disabled ? "bg-gray-200" : "bg-white",
        className
      )}
    >
      <input
        type="date"
        ref={calendarRef}
        className={"calendar"}
        onClick={() => {
          calendarRef.current?.focus();
          calendarRef.current?.showPicker();
        }}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
        onKeyDown={onKeyDown}
        value={value}
        disabled={disabled}
        {...rest}
      />
      <span
        className={cn(
          "block",
          "truncate",
          disabled && "text-black/25",
          (formattedValue ?? value) == null && placeholderClassName
        )}
      >
        {formattedValue ?? value ?? placeholder}
      </span>
      <span
        className={cn(
          "pointer-events-none",
          "absolute",
          "inset-y-0",
          "right-0",
          "flex",
          "items-center",
          "pr-2"
        )}
      >
        <ChevronDownIcon
          className={cn(
            "h-4",
            "w-4",
            disabled ? "fill-gray-700/25" : "fill-gray-700"
          )}
          aria-hidden="true"
        />
      </span>
    </div>
  );
});
