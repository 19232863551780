import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

export interface AuthState<T> {
  isInitialized: boolean;
  meUser: T | null;
  token: string | null;
  redirectTo: string;
}

interface LoginSucceedPayload<T> {
  meUser: T;
  token: string;
}
interface RefetchTokenSucceedPayload {
  token: string;
}
interface LoginRedirectedPayload {
  redirectTo: string;
}
interface MeUserUpdatedPayload<T> {
  meUser: T;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createAuthSlice = <T>(initialState: AuthState<T>) =>
  createSlice({
    name: "auth",
    initialState,
    reducers: {
      initialized: (state) => {
        return {
          ...state,
          isInitialized: true,
        };
      },
      loginSucceed: (state, action: PayloadAction<LoginSucceedPayload<T>>) => {
        const { meUser, token } = action.payload;
        return {
          ...state,
          meUser,
          token,
        };
      },
      loginRedirected: (
        state,
        action: PayloadAction<LoginRedirectedPayload>
      ) => {
        const { redirectTo } = action.payload;
        return {
          ...state,
          redirectTo,
        };
      },
      logoutSucceed: (state) => {
        return {
          ...state,
          meUser: null,
          token: null,
        };
      },
      unauthenticated: (state) => {
        return {
          ...state,
          meUser: null,
          token: null,
        };
      },
      refetchTokenSucceed: (
        state,
        action: PayloadAction<RefetchTokenSucceedPayload>
      ) => {
        const { token } = action.payload;
        return {
          ...state,
          token,
        };
      },
      meUserUpdated: (
        state,
        action: PayloadAction<MeUserUpdatedPayload<T>>
      ) => {
        const { meUser } = action.payload;
        return {
          ...state,
          meUser,
        };
      },
    },
  });
