import React, { ReactElement } from "react";
import cn from "classnames";
import { RadioGroup } from "@headlessui/react";

interface Props {
  className?: string;
  labelClassName?: string;
  label: React.ReactNode;
  checked: boolean;
  active: boolean;
}

export const RadioInput = React.memo((props: Props): ReactElement => {
  const { className, labelClassName, label, checked } = props;
  return (
    <div className={cn("flex", "gap-2", "items-center", className)}>
      {checked ? (
        <div
          className={cn(
            "w-4",
            "h-4",
            "bg-primary-800",
            "rounded-lg",
            "justify-center",
            "items-center",
            "inline-flex"
          )}
        >
          <div className={cn("w-1.5", "h-1.5", "bg-white", "rounded-full")} />
        </div>
      ) : (
        <div
          className={cn("w-4", "h-4", "justify-center", "items-center", "flex")}
        >
          <div
            className={cn(
              "w-4",
              "h-4",
              "bg-white",
              "rounded-full",
              "border",
              "border-gray-300"
            )}
          />
        </div>
      )}
      <RadioGroup.Label
        className={cn("font-medium", "text-gray-900", labelClassName)}
      >
        {label}
      </RadioGroup.Label>
    </div>
  );
});
