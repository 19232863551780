export function dedup<T extends { id: number }>(list: T[]): T[] {
  const deduplicated = [];
  const seenKey = new Set<number>();
  for (const item of list) {
    if (seenKey.has(item.id)) {
      continue;
    }
    seenKey.add(item.id);
    deduplicated.push(item);
  }

  return deduplicated;
}

export function sort<T extends Record<string, any>>(
  list: T[],
  sorts: { key: keyof T; direction: "asc" | "desc" }[]
): T[] {
  return list.sort((i1, i2) => {
    for (const s of sorts) {
      const v1 = i1[s.key];
      const v2 = i2[s.key];
      if (v1 < v2) {
        return s.direction === "asc" ? -1 : 1;
      } else if (v2 < v1) {
        return s.direction === "asc" ? 1 : -1;
      }
    }
    return 0;
  });
}
