import React, {
  useRef,
  useCallback,
  useMemo,
  useEffect,
  useContext,
  ReactElement,
} from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { PlayIcon } from "@heroicons/react/24/solid";
import cn from "classnames";

import useShowMessage from "oneclick-component/src/hooks/useShowMessage";
import { formatDurationBySecond } from "oneclick-component/src/utils/duration";

import { AudioPlayerContext } from "../../../../providers/AudioPlayerProvider";
import { RootState } from "../../../../store/store";
import { selectAudioPlayerState } from "../../../../store/audioPlayer";
import { PauseIcon } from "@heroicons/react/24/outline";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import { clamp } from "../../../../utils/math";

// For removing drage image of the progress dot
const blankCanvas = document.createElement("canvas");
blankCanvas.style.position = "absolute";
blankCanvas.style.left = "-100%";
blankCanvas.style.top = "0%";
document.body.append(blankCanvas);

interface AudioPlayerProps {
  mime: string;
  playerId: string;
  audioSource: string | null;
}

const AudioPlayer = (props: AudioPlayerProps): ReactElement => {
  const { mime, audioSource, playerId } = props;

  const { t } = useTranslation();
  const { play, pause, setCurrentTime } = useContext(AudioPlayerContext);
  const {
    duration,
    currentTime,
    remainingTime,
    isEnded,
    isPlaying,
    isLoading,
    isError,
  } = useSelector((state: RootState) => {
    return selectAudioPlayerState(state.audioPlayer, playerId);
  }, shallowEqual);
  const showMessage = useShowMessage();

  const isPlayingRef = useRef(isPlaying);
  isPlayingRef.current = isPlaying;
  const progressBarRef = useRef<HTMLDivElement>(null);

  const onAudioError = useCallback(() => {
    showMessage({
      title: t("chatDialog.audioPlayer.error.playAudioFailed.title"),
      message: t("chatDialog.audioPlayer.error.playAudioFailed.message"),
      type: "fail",
      showDismiss: true,
    });
  }, [showMessage, t]);

  const onTogglePlayingClicked = useCallback(() => {
    if (!isPlaying && !isLoading) {
      if (audioSource != null) {
        try {
          play(playerId, audioSource, mime, currentTime ?? 0, {
            resetPlayer: isEnded,
          });
        } catch {
          onAudioError();
        }
      }
    } else {
      pause(playerId);
    }
  }, [
    onAudioError,
    isEnded,
    isPlaying,
    isLoading,
    playerId,
    currentTime,
    audioSource,
    mime,
    play,
    pause,
  ]);

  const setProgress = useCallback(
    (e: React.DragEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>) => {
      if (
        duration == null ||
        duration <= 0 ||
        e.clientX === 0 ||
        progressBarRef.current == null
      ) {
        return;
      }
      const percentage = clamp(
        (e.clientX - progressBarRef.current.getBoundingClientRect().left) /
          progressBarRef.current.offsetWidth,
        0,
        1
      );
      const currentTime = duration * percentage;
      setCurrentTime(playerId, currentTime);
    },
    [duration, setCurrentTime, playerId]
  );

  const onProgressBarClicked = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      setProgress(e);
    },
    [setProgress]
  );

  const onProgressDotDragStart = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.dataTransfer.setDragImage(blankCanvas, 0, 0);
    },
    []
  );

  const onProgressDotDrag = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      setProgress(e);
    },
    [setProgress]
  );

  const onProgressDotDragEnd = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.dataTransfer.setDragImage(blankCanvas, 0, 0);
    },
    []
  );

  useEffect(() => {
    if (isError) {
      onAudioError();
    }
  }, [isError, onAudioError]);

  const countDownString = useMemo(() => {
    if (remainingTime == null) {
      return "--:--";
    }
    return formatDurationBySecond(remainingTime);
  }, [remainingTime]);

  const progress = useMemo(() => {
    return duration != null && remainingTime != null
      ? clamp(((duration - remainingTime) / duration) * 100, 0, 100)
      : 0;
  }, [duration, remainingTime]);

  // Pause audio on unmount
  useEffect(() => {
    return () => {
      if (isPlayingRef.current) {
        pause(playerId);
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={cn("flex", "gap-3", "w-full", "p-3")}>
      <button
        className={cn(
          "w-9.5",
          "h-9.5",
          "p-[0.5625rem]",
          "rounded-full",
          "bg-primary-600"
        )}
        type="button"
        onClick={onTogglePlayingClicked}
        disabled={audioSource == null}
      >
        {isPlaying ? (
          <PauseIcon className={cn("w-5", "h-5", "text-white")} />
        ) : null}
        {isLoading ? (
          <LoadingSpinner
            size="xs"
            className={cn("border-white", "border-r-transparent")}
          />
        ) : null}
        {!isPlaying && !isLoading ? (
          <PlayIcon className={cn("w-5", "h-5", "fill-white")} />
        ) : null}
      </button>
      <div className={"flex-1"}>
        <div className={cn("py-2", "w-full", "mb-1")}>
          <div
            className={cn(
              "relative",
              "h-1",
              "w-full",
              "bg-white",
              "rounded-full"
            )}
            ref={progressBarRef}
            onClick={onProgressBarClicked}
          >
            <div
              className={cn("h-1", "bg-primary-400", "rounded-full")}
              style={{
                width: `${progress}%`,
              }}
            />
            <div
              draggable={true}
              onDragStart={onProgressDotDragStart}
              onDragEnd={onProgressDotDragEnd}
              onDrag={onProgressDotDrag}
              className={cn(
                "absolute",
                "h-3",
                "w-3",
                "bg-white",
                "border",
                "rounded-full",
                "border-primary-300",
                "-translate-x-1/2",
                "-translate-y-1/2",
                "top-1/2",
                "hover:cursor-pointer"
              )}
              style={{
                left: `${progress}%`,
              }}
            />
          </div>
        </div>
        <div className={cn("text-xs", "text-black/60")}>{countDownString}</div>
      </div>
    </div>
  );
};

export default AudioPlayer;
