import { ReactElement } from "react";
import { Navigate, matchPath, useLocation } from "react-router-dom";
import AppRoutes from "../../routes/AppRoutes";
import cn from "classnames";
import TabMenu from "oneclick-component/src/components/TabMenu";
import { useTranslation } from "react-i18next";

export const SHIFT_REQUEST_LIST_PAGE_SIZE = 12;
const ShiftRequestListScreen = (): ReactElement => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  if (matchPath(AppRoutes.ShiftRequestListScreen.path, pathname)) {
    return (
      <Navigate
        to={AppRoutes.ShiftRequestListIncomingPartTimeTab.render()}
        replace={true}
      />
    );
  }

  return (
    <main
      className={cn(
        "rounded-lg",
        "bg-white",
        "mx-auto",
        "sm:mb-6",
        "sm:px-9",
        "sm:py-6",
        "pb-6",
        "px-6"
      )}
    >
      <TabMenu
        panels={[
          {
            title: t("shiftRequestList.tab.incoming.title"),
            to: AppRoutes.ShiftRequestListIncomingPartTimeTab.render(),
          },
          {
            title: t("shiftRequestList.tab.outgoing.title"),
            to: AppRoutes.ShiftRequestListOutgoingPartTimeTab.render(),
          },
        ]}
        className="sm:!justify-center"
      />
    </main>
  );
};

export default ShiftRequestListScreen;
