import cn from "classnames";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "oneclick-component/src/components/Button";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import useShowMessage from "oneclick-component/src/hooks/useShowMessage";
import {
  useEditShiftHandlerShiftsShiftIdPostMutation,
  useGetPtUsersRolesHandlerPartTimeUsersRolesGetQuery,
  useLazyGetShiftByIdHandlerShiftsShiftIdGetQuery,
  useListShiftCancelReasonsHandlerShiftsCancelReasonsGetQuery,
  WorkingStation,
} from "oneclick-component/src/store/apis/enhancedApi";
import { isoToDateTime } from "oneclick-component/src/utils/datetime";
import { Option } from "oneclick-component/src/components/inputs";
import React, { useCallback, useId, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import ShiftForm, {
  shiftFormSchema,
  ShiftFormValues,
} from "../../components/ShiftForm";
import { useShowError } from "../../hooks/useShowError";
import AppRoutes from "../../routes/AppRoutes";
import CancelShiftDialog from "../../components/CancelShiftDialog/CancelShiftDialog";
import { showErrorScreen } from "../../utils/error";
import useShiftRateOptions from "../../hooks/useShiftRateOptions";

const EditShiftScreen = (): React.ReactElement => {
  const { id } = useParams();
  const shiftId = parseInt(id!, 10);
  const [getShiftById, { isLoading: isLoadingShift }] =
    useLazyGetShiftByIdHandlerShiftsShiftIdGetQuery();
  const { t } = useTranslation();
  const { showError } = useShowError();
  const showMessage = useShowMessage();
  const navigate = useNavigate();
  const [editShift] = useEditShiftHandlerShiftsShiftIdPostMutation();
  const {
    data: ptUserRoles,
    isLoading: isLoadingPtUsersRoles,
    error: getPtUserRolesError,
  } = useGetPtUsersRolesHandlerPartTimeUsersRolesGetQuery();

  const [selectedWorkingStation, setSelectedWorkingStation] =
    useState<WorkingStation | null>(null);
  const [selectedSupportStation, setSelectedSupportStation] =
    useState<WorkingStation | null>(null);

  const [isIncident, setIsIncident] = useState(false);

  const partTimeTypeOptions: Option<number>[] = useMemo(() => {
    if (ptUserRoles == null) {
      return [];
    }
    return ptUserRoles.ptUserRoles.map((role) => ({
      name: role.name,
      value: role.id,
    }));
  }, [ptUserRoles]);

  const shiftRateOptions: Option<number>[] = useShiftRateOptions();

  const form = useForm<ShiftFormValues>({
    resolver: zodResolver(shiftFormSchema),
    defaultValues: async () => {
      const { shift } = await getShiftById({ shiftId }).unwrap();
      setSelectedWorkingStation(shift.workingStation);
      setSelectedSupportStation(shift.supportStation);
      setIsIncident(shift.isIncident);
      const startDateTime = isoToDateTime(shift.dutyStartTime);
      const endDateTime =
        shift.dutyEndTime != null ? isoToDateTime(shift.dutyEndTime) : null;
      let endTimeHour = endDateTime == null ? null : endDateTime.get("hour");
      if (
        endTimeHour != null &&
        startDateTime.toISODate() !== endDateTime?.toISODate()
      ) {
        endTimeHour = endTimeHour + 24;
      }
      return {
        stationId: shift.workingStationId,
        ptType: shift.ptUserRoles[0].id, // TODO: display with frontend type
        supportStationId: shift.supportStationId,
        rate: shift.rate?.id ?? null,
        fulfillmentCount: shift.fulfillmentCount,
        date: startDateTime.toFormat("yyyy-LL-dd"),
        startTime: {
          hour: startDateTime.get("hour"),
          minute: startDateTime.get("minute"),
        },
        endTime: {
          hour: endTimeHour,
          minute: endDateTime == null ? null : endDateTime.get("minute"),
        },
        title: shift.shiftTitle,
        description: shift.shiftDescription,
      };
    },
  });

  const onClickCancelButton = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const {
    data: cancelReasons,
    isLoading: isLoadingCancelReasons,
    error: getCancelReasonsError,
  } = useListShiftCancelReasonsHandlerShiftsCancelReasonsGetQuery();
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const onCloseCancelDialog = useCallback(() => {
    setIsCancelDialogOpen(false);
  }, []);
  const onOpenCancelDialog = useCallback(() => {
    setIsCancelDialogOpen(true);
  }, []);
  const onCancelShiftSuccess = useCallback(() => {
    navigate(AppRoutes.ShiftDetailScreen.render(shiftId));
  }, [navigate, shiftId]);

  const { handleSubmit } = form;
  const formId = useId();

  const onSubmit = useMemo(() => {
    return handleSubmit((values, e?: React.BaseSyntheticEvent) => {
      e?.preventDefault();
      editShift({
        shiftId,
        editShiftRequest: {
          fulltimeCount: null,
          fulfillmentCount: values.fulfillmentCount,
          shiftTitle: values.title.trim(),
          shiftDescription: values.description.trim(),
        },
      })
        .unwrap()
        .then((resp) => {
          showMessage({
            title: t("shift.edit.toast.success.title"),
            message: t("shift.edit.toast.success.message"),
            type: "success",
            showDismiss: true,
          });
          navigate(AppRoutes.ShiftDetailScreen.render(resp.shift.id));
        })
        .catch((e: unknown) => {
          showError(e, "shift.edit.toast.fail.title");
        });
    });
  }, [editShift, handleSubmit, navigate, shiftId, showError, showMessage, t]);

  const isLoading =
    isLoadingShift || isLoadingPtUsersRoles || isLoadingCancelReasons;

  if (getPtUserRolesError != null || getCancelReasonsError != null) {
    return showErrorScreen(getPtUserRolesError ?? getCancelReasonsError);
  }

  if (isLoading || ptUserRoles == null || cancelReasons == null) {
    return (
      <div
        className={cn(
          "fixed",
          "top-1/2",
          "left-1/2",
          "-translate-x-1/2",
          "-translate-y-1/2"
        )}
      >
        <LoadingSpinner size="l" />
      </div>
    );
  }

  return (
    <main className={"pb-20"}>
      <div
        className={cn(
          "rounded-lg",
          "bg-white",
          "shadow",
          "max-w-169",
          "mx-auto"
        )}
      >
        <div className="p-6">
          <section>
            <p className={cn("font-medium", "text-lg", "grow", "mb-5")}>
              <Trans i18nKey="shift.edit.title" />
            </p>
            <ShiftForm
              id={formId}
              form={form}
              initialSelectedWorkingStation={selectedWorkingStation}
              initialSelectedSupportStation={selectedSupportStation}
              onWorkingStationSelected={setSelectedWorkingStation}
              partTimeTypeOptions={partTimeTypeOptions}
              shiftRateOptions={shiftRateOptions}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onSubmit={onSubmit}
              isEditing={true}
              isIncident={isIncident}
            />
          </section>
        </div>
      </div>
      <footer
        className={cn(
          "fixed",
          "bottom-0",
          "left-0",
          "bg-white",
          "w-full",
          "h-16",
          "flex",
          "justify-end",
          "px-6",
          "py-3",
          "border-t",
          "border",
          "border-black/12"
        )}
      >
        <Button
          type="button"
          theme={"white"}
          className={cn("text-rose-500", "mr-3")}
          onClick={onOpenCancelDialog}
        >
          <Trans i18nKey="shift.edit.footer.button.delete" />
        </Button>
        <Button
          theme={"white"}
          className={"mr-3"}
          onClick={onClickCancelButton}
        >
          <Trans i18nKey="shift.edit.footer.button.cancel" />
        </Button>
        <Button type="submit" form={formId}>
          <Trans i18nKey="shift.edit.footer.button.save" />
        </Button>
      </footer>
      <CancelShiftDialog
        shiftIds={[shiftId]}
        isOpen={isCancelDialogOpen}
        onClose={onCloseCancelDialog}
        cancelReasons={cancelReasons.cancelReasons}
        onCancelShiftSuccess={onCancelShiftSuccess}
      />
    </main>
  );
};

export default EditShiftScreen;
