import z from "zod";
import i18next from "../../i18n/i18n";

const ensureStartTimeBeforeEndTime = ({
  endTime,
  startTime,
}: {
  endTime: {
    hour: number | null;
    minute: number | null;
  };
  startTime: {
    hour: number;
    minute: number;
  };
}) => {
  if (endTime.hour == null || endTime.minute == null) {
    return true;
  }

  if (endTime.hour === startTime.hour) {
    return endTime.minute > startTime.minute;
  }

  return endTime.hour > startTime.hour;
};

export const shiftFormSchema = z.lazy(() =>
  z
    .object({
      stationId: z.number().int(),
      supportStationId: z.number().int().nullable(),
      rate: z.number().int().nullable(),
      ptType: z.number().int(),
      fulfillmentCount: z.preprocess(
        (value) => (value === "" ? undefined : Number(value)),
        z
          .number()
          .int()
          .min(1)
          .max(Math.pow(2, 31) - 1)
      ),
      date: z.string().min(1),
      startTime: z.object({
        hour: z.number(),
        minute: z.number(),
      }),
      endTime: z.object({
        hour: z.number().nullable(),
        minute: z.number().nullable(),
      }),
      title: z.string().trim().min(1),
      description: z.string().trim().min(1),
    })
    .refine(ensureStartTimeBeforeEndTime, {
      message: i18next.t("shift.create.form.error.endTimeBeforeStartTime"),
      path: ["endTime", "hour"],
    })
);

export type ShiftFormValues = z.infer<typeof shiftFormSchema>;
