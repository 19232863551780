import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import useShowMessage from "oneclick-component/src/hooks/useShowMessage";
import {
  isApiErrorCode,
  isFetchQueryBaseError,
  OneClickCustomError,
} from "oneclick-component/src/models/error";

import { ErrorCode } from "../models/error";

export type ErrorLocaleKeyMap = Partial<Record<ErrorCode, string>>;

export const errorCodeLocaleKeyMap: ErrorLocaleKeyMap = {
  UNKNOWN: "apiError.unknown",
  INVALID_REQUEST: "apiError.invalidRequest",
  UNAUTHORIZED: "apiError.unknown", // 401 => refetch token / logout
  FORBIDDEN: "apiError.unauthorized", // 403 => user found no access
  NOT_FOUND: "apiError.notFound",
  CONFLICT: "apiError.unknown", // 409 => conflict for unknown reason, let user override with custom error message
  INTERNAL_ERROR: "apiError.unknown",
  NETWORK: "apiError.network",
  TIMEOUT: "apiError.timeout",
};

export function useShowError(): {
  /**
   * Present translated error in toast.
   * @param error - Error data returned by rtk query hook
   * @param title - String or i18n key, title of error toast
   * @param customErrorLocaleKeyMap - Map of error message i18n key by error code, overrides default error message
   * Can be used for providing customised error message for error code specific to an API
   */
  showError: (
    error: unknown,
    title?: string,
    customErrorMessageMap?: ErrorLocaleKeyMap
  ) => void;
  getErrorMessageID: (
    error: unknown,
    customErrorMessageMap?: ErrorLocaleKeyMap
  ) => string;
  getErrorMessage: (
    error: unknown,
    customErrorMessageMap?: ErrorLocaleKeyMap
  ) => string;
} {
  const { t } = useTranslation();
  const showMessage = useShowMessage();

  const getErrorMessageID = useCallback(
    (
      error: unknown,
      customErrorLocaleKeyMap: Partial<Record<string, string>> = {}
    ) => {
      let errorCode: ErrorCode = "UNKNOWN"; // fallback

      if (isFetchQueryBaseError(error)) {
        if (error.status === "FETCH_ERROR") {
          errorCode = "NETWORK";
        } else if (error.status === "TIMEOUT_ERROR") {
          errorCode = "TIMEOUT";
        } else {
          const rawCode = (error.data as any)?.detail?.code;
          errorCode =
            typeof rawCode === "string" && isApiErrorCode(rawCode)
              ? rawCode
              : "UNKNOWN";
        }
      }

      const messageLocaleKey =
        customErrorLocaleKeyMap[errorCode] ??
        errorCodeLocaleKeyMap[errorCode] ??
        "apiError.unknown";

      return messageLocaleKey;
    },
    []
  );

  const getErrorMessage = useCallback(
    (error: unknown, customErrorLocaleKeyMap?: ErrorLocaleKeyMap) => {
      if (error instanceof OneClickCustomError) {
        return error.message;
      }

      const errorMessageID = getErrorMessageID(error, customErrorLocaleKeyMap);
      return t(errorMessageID);
    },
    [getErrorMessageID, t]
  );

  const showError = useCallback(
    (
      error: any,
      title?: string,
      customErrorLocaleKeyMap: Partial<Record<string, string>> = {}
    ) => {
      console.error(error);

      const errorMessage = getErrorMessage(error, customErrorLocaleKeyMap);
      showMessage({
        type: "fail",
        title: t(title ?? "errorToast.title", {
          defaultValue: title,
        }),
        message: errorMessage,
      });
    },
    [getErrorMessage, showMessage, t]
  );

  return { showError, getErrorMessageID, getErrorMessage };
}
