import { ReactElement } from "react";
import { Trans } from "react-i18next";
import cn from "classnames";
import { StationBadge } from "oneclick-component/src/components/Badge";
import {
  PtUserWithVisitCount,
  PtUserShiftCancelCountWithPtUser,
} from "oneclick-component/src/store/apis/enhancedApi";
import { PTUserAvatarImage } from "../PTUserAvatarImage";
import { isoToDateTime } from "oneclick-component/src/utils/datetime";
import { DateTime } from "luxon";
import { formatShiftRequestTime } from "../../utils/shiftRequest";
import { useShouldShowPtStationTeam } from "../../hooks/useShouldShowPtStationTeam";

interface TimestampDisplayProps {
  cancelRecord: PtUserShiftCancelCountWithPtUser;
}

const TimestampDisplay = (props: TimestampDisplayProps): ReactElement => {
  const { cancelRecord } = props;
  const dt: DateTime = isoToDateTime(cancelRecord.updatedAt);
  const cancelTimeDisplayString = formatShiftRequestTime(dt);

  return (
    <p className={cn("text-xs", "font-normal", "line-through")}>
      <span className="text-black/60">
        <Trans i18nKey="shiftDetail.request.cancelledAt" />
      </span>{" "}
      <span className={cn("text-black/86", "mr-3")}>
        {cancelTimeDisplayString}
      </span>
    </p>
  );
};

interface PropsType {
  user: PtUserWithVisitCount;
  cancelRecord: PtUserShiftCancelCountWithPtUser;
  workingStationShortCode: string;
  className?: string;
}

const CancelledRecordRow = (props: PropsType): ReactElement => {
  const { user, workingStationShortCode, className, cancelRecord } = props;
  const shouldShowPtStationTeam = useShouldShowPtStationTeam();

  const visit = user.visitCounts?.find(
    (station) => station.workingStationShortCode === workingStationShortCode
  );
  const visitCount = visit?.visitCount ?? 0;

  const visitCountDisplayString = visitCount > 10 ? "10+" : visitCount;

  return (
    <div
      className={cn(
        "rounded-lg",
        "border",
        "border-black/10",
        "p-4",
        "mb-3",
        "flex",
        "flex-row",
        "w-full",
        "relative",
        className
      )}
    >
      <div className={cn("flex", "items-center", "mr-8")}>
        <PTUserAvatarImage ptUser={user} />
      </div>
      <div className={cn("grow", "hidden", "lg:block")}>
        <div>
          <p className={cn("font-normal", "flex", "items-center")}>
            <span className="line-through">{user.fullNameZhHk}</span>
            {user.station != null ? (
              <StationBadge
                className={cn("ml-2", "mr-7")}
                station={user.station}
                stationTeam={user.stationTeam ?? null}
                showStationTeam={shouldShowPtStationTeam}
              />
            ) : null}
            <span className={cn("text-sm", "text-red-500")}>
              <Trans i18nKey="shiftDetail.request.cancelled" />
            </span>
          </p>
          <p className={cn("text-sm", "font-normal", "mb-1", "line-through")}>
            <span className="text-black/60">ID</span>{" "}
            <span className="text-black/86">{user.eid}</span>{" "}
            <span className="text-black/12">|</span>{" "}
            <span className="text-black/60">
              <Trans i18nKey="shiftDetail.visitCount" />
            </span>{" "}
            <span className="text-black/86">{visitCountDisplayString}</span>
          </p>
          <TimestampDisplay cancelRecord={cancelRecord} />
        </div>
      </div>
      {/** Mobile layout has different dom structure */}
      <div className={cn("grow", "block", "lg:hidden")}>
        <div
          className={cn(
            "flex",
            "flex-row",
            "items-center",
            "gap-x-2",
            "line-through"
          )}
        >
          <span>{user.fullNameZhHk}</span>
          {user.station != null ? (
            <StationBadge
              station={user.station}
              stationTeam={user.stationTeam ?? null}
              showStationTeam={shouldShowPtStationTeam}
            />
          ) : null}
        </div>
        <div className="line-through">
          <p className={cn("text-sm", "font-normal", "line-clamp-1")}>
            <span className="text-black/60">
              <Trans i18nKey="shiftDetail.visitCount" />
            </span>{" "}
            <span className="text-black/86">{visitCountDisplayString}</span>
          </p>
        </div>
        <span className={cn("text-sm", "text-red-500")}>
          <Trans i18nKey="shiftDetail.request.cancelled" />
        </span>
      </div>
    </div>
  );
};

export default CancelledRecordRow;
