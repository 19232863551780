import React, { useCallback, useContext } from "react";
import {
  CellContext,
  ColumnDef,
  Table,
  TableState,
  createColumnHelper,
  getCoreRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  useReactTable,
  RowSelectionState,
  OnChangeFn,
} from "@tanstack/react-table";
import { t } from "i18next";
import cn from "classnames";
import {
  CellCheckbox,
  HeaderCheckbox,
} from "oneclick-component/src/components/TableWithSelect";
import { SELECT_COLUMN_ID } from "oneclick-component/src/components/TableWithSelect/constants";
import { DownloadIcon } from "oneclick-component/src/icon";
import { useBreakPoints } from "oneclick-component/src/providers";
import {
  ShiftRequestBase,
  ShiftType,
  BriefStation,
} from "oneclick-component/src/store/apis/enhancedApi";
import PopMenu, {
  PopMenuItem,
} from "oneclick-component/src/components/PopMenu";
import ProgressBar from "oneclick-component/src/components/ProgressBar";
import useGenerateShiftPdfWebSocket from "../../../hooks/useGenerateShiftPdfWebSocket";
import { HiredShiftRequestBase } from "../../../models/shiftRequest";
import { FeatureConfigContext } from "../../../providers/FeatureConfigProvider";
import HireStatusPopup from "../HireStatusPopup";
import { ShiftRateViewModel } from "oneclick-component/src/components/ShiftRateBadge";
import ShiftBadge from "oneclick-component/src/components/ShiftRateBadge/ShiftBadge";
import ShiftT3Badge from "oneclick-component/src/components/Badge/ShiftT3Badge";
import { StationBadge } from "oneclick-component/src/components/Badge";
import { useIsSuperAdmin } from "../../../hooks/role";

export interface ExpiredShiftListRecord {
  info: {
    title: string;
    subtitle: React.ReactElement;
    rate: ShiftRateViewModel | null;
    isAppliableToAllAtT3: boolean;
  };
  timeRange: string;
  partTimeType: string;
  appliedRequests: ShiftRequestBase[];
  hireStatus: {
    workingStationShortCode: string;
    fulfillmentCount: number;
    hiredRequests: HiredShiftRequestBase[];
  };
  // grouping/sorting, invisible columns
  title: string;
  date: string;
  numberOfApplicants: number;
  fulfillmentCount: number;
  id: number;

  // helper info
  fullTimeCount: number;
  hiredFullTimeCount: number;
  shiftType: ShiftType;
  remoteStation?: BriefStation | null;
}

interface FullTimeStatusSectionProps {
  record: ExpiredShiftListRecord;
}

// eslint-disable-next-line react-refresh/only-export-components
const FullTimeStatusSection = (props: FullTimeStatusSectionProps) => {
  const { record } = props;

  return (
    <div>
      <ProgressBar
        max={record.fullTimeCount}
        count={record.hiredFullTimeCount}
        labelStyle="top"
        barSize="l"
        className={cn("mt-8", "mb-2")}
        title={t("shiftList.table.incident.hireStatus.fullTime")}
      />
    </div>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
const ExpiredShiftListTableAction = ({
  row,
}: CellContext<ExpiredShiftListRecord, unknown>): React.ReactElement => {
  const triggerPdfGeneration = useGenerateShiftPdfWebSocket();
  const isSuperAdmin = useIsSuperAdmin();

  const onClickExportPdf = useCallback(() => {
    triggerPdfGeneration([row.original.id]);
  }, [row, triggerPdfGeneration]);

  const popMenuControlElementClass = useCallback(
    ({ open }: { open: boolean }) =>
      cn(
        "flex",
        "max-w-xs",
        "mx-auto",
        "items-center",
        "rounded-full",
        "bg-white",
        "text-sm",
        "focus:outline-none",
        "focus:ring-2",
        "focus:ring-primary-500",
        "focus:ring-offset-2",
        "p-3", // enlarge clickable area
        "-m-3", // enlarge clickable area
        open ? "fill-primary-500" : "fill-gray-500"
      ),
    []
  );

  const stopPropagate = useCallback((e?: React.MouseEvent<HTMLElement>) => {
    e?.stopPropagation();
  }, []);
  return (
    <div onClick={stopPropagate}>
      {isSuperAdmin ? (
        <></>
      ) : (
        <PopMenu
          // workaround cell padding asymmertic
          menuClass={cn("relative", "pr-1", "sm:pr-3")}
          controlElementClass={popMenuControlElementClass}
          controlElement={<DownloadIcon className={cn("w-5", "h-5")} />}
        >
          <div className="py-1">
            <PopMenuItem
              type="button"
              onClick={onClickExportPdf}
              className={"text-gray-700"}
              text={t("shiftList.tab.completed.exportMenu.pdf")}
            />
          </div>
        </PopMenu>
      )}
    </div>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
const ExpiredShiftListTableHireStatus = ({
  row,
}: CellContext<ExpiredShiftListRecord, unknown>): React.ReactElement | null => {
  const { original } = row;
  const isIncident = original.shiftType === "INCIDENT";
  return (
    <HireStatusPopup
      shiftId={original.id}
      workingStationShortCode={original.hireStatus.workingStationShortCode}
      isIncident={isIncident}
      shiftRequests={original.hireStatus.hiredRequests}
      fulfillmentCount={original.hireStatus.fulfillmentCount}
      fulltimeCount={original.fullTimeCount}
    >
      <ProgressBar
        max={original.hireStatus.fulfillmentCount}
        count={original.hireStatus.hiredRequests.length}
        labelStyle="top"
        barSize="l"
        title={
          isIncident ? t("shiftList.table.incident.hireStatus.partTime") : ""
        }
      />
      {isIncident ? <FullTimeStatusSection record={original} /> : null}
    </HireStatusPopup>
  );
};

const columnHelper = createColumnHelper<ExpiredShiftListRecord>();

export const useExpiredShiftListTableColumns = (): ColumnDef<
  ExpiredShiftListRecord,
  any
>[] => {
  const { shouldShowT3Feature } = useContext(FeatureConfigContext);
  const isSuperAdmin = useIsSuperAdmin();
  const { useIsSm } = useBreakPoints();
  const isSm = useIsSm();
  return [
    ...(isSuperAdmin
      ? []
      : [
          {
            id: SELECT_COLUMN_ID,
            size: 16,
            header: HeaderCheckbox,
            cell: CellCheckbox,
          },
        ]),
    columnHelper.accessor("info", {
      header: () => t("shiftList.table.header.shiftInfo"),
      cell: ({ cell, row }) => {
        const isIncident = row.original.shiftType === "INCIDENT";
        const info = cell.getValue();
        return (
          <div className={cn("flex", "flex-row", "items-center")}>
            <div className={cn("flex", "flex-col", "flex-1")}>
              <div className={cn("flex", "gap-x-3", "items-center")}>
                <ShiftBadge rate={info.rate} isIncident={isIncident} />
                <p className={cn("font-medium", "text-sm", "text-black/90")}>
                  {info.title}
                </p>
                {shouldShowT3Feature && !isIncident ? (
                  <>
                    <div
                      className={cn(
                        "border",
                        "border-solid",
                        "border-black/24",
                        "self-stretch",
                        "w-[1px]",
                        "my-0.5"
                      )}
                    ></div>
                    <ShiftT3Badge active={info.isAppliableToAllAtT3} />
                  </>
                ) : null}
              </div>

              <p
                className={cn(
                  "font-normal",
                  "text-xs",
                  "leading-5",
                  "text-black/60",
                  "mt-1"
                )}
              >
                {info.subtitle}
              </p>
            </div>
            {row.original.remoteStation ? (
              <div>
                <StationBadge
                  station={row.original.remoteStation}
                  stationTeam={null}
                />
              </div>
            ) : null}
          </div>
        );
      },
    }),
    columnHelper.accessor("timeRange", {
      header: () => t("shiftList.table.header.timeRange"),
      size: 140,
      cell: ({ cell }) => {
        return (
          <p
            className={cn(
              "font-normal",
              "text-sm",
              "leading-5",
              "text-gray-500"
            )}
          >
            {cell.getValue()}
          </p>
        );
      },
    }),
    columnHelper.accessor("partTimeType", {
      header: () => t("shiftList.table.header.partTimeType"),
      size: 120,
      cell: ({ cell }) => {
        return (
          <p
            className={cn(
              "font-normal",
              "text-sm",
              "leading-5",
              "text-gray-500"
            )}
          >
            {cell.getValue()}
          </p>
        );
      },
    }),
    columnHelper.accessor("appliedRequests", {
      header: () => (
        <span className={"min-w-10"}>
          {t("shiftList.table.header.numberOfApplicant")}
        </span>
      ),
      size: isSm ? 120 : 76,
      cell: ({ cell }) => {
        const requests = cell.getValue();
        return (
          <div className={cn("flex", "flex-row")}>
            <p
              className={cn(
                "font-normal",
                "text-sm",
                "leading-5",
                "text-gray-500"
              )}
            >
              {requests.length}
            </p>
          </div>
        );
      },
    }),
    columnHelper.accessor("hireStatus", {
      header: () => (
        <span className={"whitespace-normal"}>
          {t("shiftList.table.header.ratioOfHire")}
        </span>
      ),
      size: 160,
      cell: ExpiredShiftListTableHireStatus,
    }),
    columnHelper.display({
      id: "action",
      header: () => (
        <span className={cn("text-center", "w-full")}>
          {t("shiftList.table.header.action")}
        </span>
      ),
      size: 90,
      cell: ExpiredShiftListTableAction,
    }),
    columnHelper.accessor("title", {
      enableHiding: true,
    }),
    columnHelper.accessor("date", {
      enableHiding: true,
    }),
    columnHelper.accessor("fulfillmentCount", {
      enableHiding: true,
    }),
    columnHelper.accessor("numberOfApplicants", {
      enableHiding: true,
    }),
    columnHelper.accessor("id", {
      enableHiding: true,
    }),
  ];
};

export const useExpiredShiftListTable = (
  data: ExpiredShiftListRecord[],
  onRowSelectionChange: OnChangeFn<RowSelectionState>,
  state?: Partial<TableState>
): Table<ExpiredShiftListRecord> => {
  const shiftListTableColumns = useExpiredShiftListTableColumns();
  return useReactTable({
    data: data,
    columns: shiftListTableColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    defaultColumn: {
      minSize: 0,
      size: Number.MAX_SAFE_INTEGER,
      maxSize: Number.MAX_SAFE_INTEGER,
    },
    groupedColumnMode: false,
    manualSorting: true,
    enableRowSelection: true,
    onRowSelectionChange,
    state: state,
  });
};
