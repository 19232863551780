import { Badge } from "oneclick-component/src/components/Badge";
import { ReactElement, useContext } from "react";
import cn from "classnames";
import { Weekly418Status } from "oneclick-component/src/store/apis/enhancedApi";
import { FeatureConfigContext } from "../../../providers/FeatureConfigProvider";

interface Props {
  status: Weekly418Status;
  className?: string;
}

const PTUser418Badge = (props: Props): ReactElement => {
  const { status, className } = props;
  const { shouldShowGreen418Badge } = useContext(FeatureConfigContext);
  return (
    <Badge
      className={cn(
        {
          "text-yellow-800 bg-yellow-100": status === "WARNING",
          "text-green-800 bg-green-100":
            status === "SAFE" && shouldShowGreen418Badge,
          "text-gray-800 bg-gray-100":
            status === "UNKNOWN" ||
            (status === "SAFE" && !shouldShowGreen418Badge),
        },
        className
      )}
    >
      418
    </Badge>
  );
};

export default PTUser418Badge;
