import React, { PropsWithChildren, useRef } from "react";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { store } from "../store/store";

interface Props extends PropsWithChildren {}

export const StoreProvider = (props: Props): React.ReactElement => {
  const { children } = props;
  // NOTE: msalInstance is not a react state, the reference will not change.
  const _store = useRef(store);
  persistStore(_store.current);
  return <Provider store={_store.current}>{children}</Provider>;
};
