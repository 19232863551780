import React, {
  CSSProperties,
  ForwardedRef,
  Fragment,
  ReactElement,
  Ref,
} from "react";
import cn from "classnames";
import { Transition, Listbox } from "@headlessui/react";
import { Option } from "./Select";

interface Props<T> {
  className?: string;
  style?: CSSProperties;
  open: boolean;
  options: Option<T>[];
}

export const SelectOptions = React.forwardRef(function SelectOptions<T>(
  props: Props<T>,
  ref: ForwardedRef<HTMLElement>
): ReactElement {
  const { style, className, open, options } = props;
  return (
    <Transition
      show={open}
      as={Fragment}
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Listbox.Options
        ref={ref}
        style={style}
        className={cn(
          "z-10",
          "max-h-60",
          "overflow-auto",
          "rounded-md",
          "bg-white",
          "py-1",
          "text-base",
          "shadow-lg",
          "ring-1",
          "ring-black/5",
          "focus:outline-none",
          "sm:text-sm",
          className
        )}
      >
        {options.map((option, index) => (
          <Listbox.Option
            key={index}
            // eslint-disable-next-line react/jsx-no-bind
            className={({ active }) =>
              cn(
                active ? "bg-gray-100" : "bg-white",
                "text-gray-900",
                "relative",
                "cursor-default",
                "select-none",
                "py-2",
                "pl-3",
                "pr-9"
              )
            }
            value={option.value}
          >
            {option.displayComponent ?? (
              <span className={cn("font-normal", "block", "text-left")}>
                {option.name}
              </span>
            )}
          </Listbox.Option>
        ))}
      </Listbox.Options>
    </Transition>
  );
}) as <T>(p: Props<T> & { ref?: Ref<HTMLElement> }) => ReactElement;
