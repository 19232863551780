import { ReactElement } from "react";
import cn from "classnames";

import { Badge } from "./Badge";

interface Props {
  className?: string;
}

const CCOBadge = (props: Props): ReactElement => {
  const { className } = props;
  return (
    <Badge
      className={cn(
        "font-medium",
        "text-xs",
        "border",
        "bg-blue-600",
        "border-blue-600",
        "text-white",
        className
      )}
    >
      CCO
    </Badge>
  );
};

export default CCOBadge;
