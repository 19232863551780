import React, {
  ReactElement,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
  useEffect,
} from "react";
import cn from "classnames";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Bars3Icon } from "@heroicons/react/24/outline";

import {
  MeUser,
  Station,
  useGetUnreadChatCountHandlerChatsUnreadChatCountGetQuery as useGetUnreadChatCount,
  UserStationProfile,
} from "oneclick-component/src/store/apis/enhancedApi";
import PopMenu from "oneclick-component/src/components/PopMenu";
import { LogoIcon } from "oneclick-component/src/icon";
import {
  EnvironmentBadge,
  StationBadge,
} from "oneclick-component/src/components/Badge";
import { Environment } from "oneclick-component/src/constants/environment";
import { useBreakPoints } from "oneclick-component/src/providers";

import { useShowError } from "../../hooks/useShowError";
import AppRoutes from "../../routes/AppRoutes";
import { AuthContext } from "../../providers/AuthProvider";
import { ChatDialog } from "../ChatDialog";
import { UserMenu } from "./UserMenu";
import { SlideoverUserMenu } from "./SlideoverUserMenu";
import { StartChatButton } from "./StartChatButton";
import { startChat } from "../../store/chatState";
import { resetFilter } from "../../store/filterState";
import { RootState } from "../../store/store";
import UserAvatarImage from "../UserAvatarImage";
import { NotificationContext } from "../../providers/NotificationProvider";
import { config } from "../../config";
import { useSelectedStationProfile } from "../../hooks/useSelectedStationProfile";
import { SwitchStationProfileDialog } from "./SwitchStationProfileDialog";
import { RemoveStationProfileDialog } from "./RemoveStationProfileDialog";
import AddStationManagerDialog from "../AddStationManagerDialog";
import { NotificationButton } from "./NotificationButton";
import useMeUser from "../../hooks/useMeUser";
import MainLayoutTabGroup from "./MainLayoutTabGroup";
import UnreadInviteStationDialog from "../UnreadInviteStationDialog";
import { useTranslation } from "react-i18next";

interface MainHeaderProps {
  meUser: MeUser | null;
  meUserSelectedStation: UserStationProfile | null;
  hasUnreadChat: boolean;
  onLogoutClick: () => void;
  onSwitchStationClick: () => void;
  onOpenSlideover: () => void;
  onStartChatClick: () => void;
  showChatDialogChatList: boolean;
  onNotificationClick: () => void;
  onAddStationManagerDialogClick: () => void;
  unreadNotificationCount: number;
}

const MainHeader = React.memo((props: MainHeaderProps) => {
  const {
    meUser,
    meUserSelectedStation,
    hasUnreadChat,
    onLogoutClick,
    onSwitchStationClick,
    onOpenSlideover,
    onStartChatClick,
    showChatDialogChatList,
    onNotificationClick,
    onAddStationManagerDialogClick,
    unreadNotificationCount,
  } = props;

  const getControlElementClasses = useCallback((option: { open: boolean }) => {
    return cn(
      "flex",
      "max-w-xs",
      "items-center",
      "rounded-full",
      "bg-white",
      "text-sm",
      option.open && [
        "outline-none",
        "ring-2",
        "ring-primary-500",
        "ring-offset-2",
      ]
    );
  }, []);

  const { t } = useTranslation();

  return (
    <header
      className={cn(
        "sticky",
        "z-20",
        "top-0",
        "bg-white",
        "border-b",
        "border-gray-300"
      )}
    >
      <nav
        className={cn(
          "py-2.5",
          "px-4",
          "mx-auto",
          "flex",
          "items-center",
          "justify-between",
          "sm:py-3",
          "sm:px-8"
        )}
        aria-label="Global"
      >
        <div className={cn("hidden", "sm:flex", "relative", "items-center")}>
          <div className="relative">
            <Link to={AppRoutes.ShiftListScreen.render()}>
              <LogoIcon />
              <EnvironmentBadge
                environment={
                  Environment[config.environment as keyof typeof Environment]
                }
                className={cn(
                  "absolute",
                  "bottom-1/2",
                  "right-1/2",
                  "translate-x-1/2",
                  "translate-y-1/2"
                )}
              />
            </Link>
          </div>
          <div
            className={cn("block", "h-8", "w-px", "bg-gray-900/10", "mx-5")}
            aria-hidden="true"
          />
          {meUser?.selectedProfile != null ? (
            <StationBadge
              className="self-center"
              station={meUser.selectedProfile.station}
              stationTeam={null}
            />
          ) : null}
        </div>
        <div
          className={cn(
            "hidden",
            "sm:flex",
            "sm:flex-1",
            "sm:justify-end",
            "items-center"
          )}
        >
          <MainLayoutTabGroup
            items={[
              {
                label: t("mainLayout.shiftManagement"),
                to: AppRoutes.ShiftListScreen.render(),
              },
              {
                label: t("mainLayout.workingRecords"),
                to: AppRoutes.ShiftRequestListScreen.render(),
              },
            ]}
            className="mr-10"
          />
          <div
            className={cn("block", "h-8", "w-px", "bg-gray-900/10", "mx-5")}
            aria-hidden="true"
          />
          <NotificationButton
            unreadNotificationCount={unreadNotificationCount}
            onClick={onNotificationClick}
          />
          <StartChatButton
            className="ml-3"
            hasUnreadChat={hasUnreadChat}
            onClick={onStartChatClick}
            isActive={showChatDialogChatList}
          />
          <PopMenu
            menuClass="relative ml-3"
            controlElementClass={getControlElementClasses}
            controlElement={<UserAvatarImage user={meUser} size="40" />}
            menuBodyClass="right-0 z-40"
          >
            <UserMenu
              meUser={meUser}
              meUserSelectedStation={meUserSelectedStation}
              onLogoutClick={onLogoutClick}
              onSwitchStationClick={onSwitchStationClick}
              onAddStationManagerDialogClick={onAddStationManagerDialogClick}
            />
          </PopMenu>
        </div>
        <div
          className={cn(
            "-mr-2",
            "flex",
            "items-center",
            "sm:hidden",
            "space-x-2"
          )}
        >
          <button
            type="button"
            className={cn(
              "inline-flex",
              "items-center",
              "justify-center",
              "rounded-md",
              "bg-white",
              "p-0",
              "sm:p-2",
              "text-gray-400",
              "hover:bg-gray-100",
              "hover:text-gray-500",
              "focus:outline-none",
              "focus:ring-2",
              "focus:ring-primary-500",
              "focus:ring-offset-2"
            )}
            onClick={onOpenSlideover}
          >
            <Bars3Icon
              className={cn("block", "h-5", "w-5")}
              aria-hidden="true"
            />
          </button>
          <div className="shrink-0">
            <UserAvatarImage user={meUser} size="40" />
          </div>
          <NotificationButton
            unreadNotificationCount={unreadNotificationCount}
            onClick={onNotificationClick}
          />
        </div>
      </nav>
    </header>
  );
});

export const MainLayout = ({ children }: PropsWithChildren): ReactElement => {
  const { useIsSm } = useBreakPoints();
  const isSm = useIsSm();
  const { showError } = useShowError();
  const meUser = useMeUser();
  const meUserSelectedStation = useSelectedStationProfile();
  const { showChatDialog, showChatDialogChatList } = useSelector(
    (state: RootState) => {
      const showChatDialog = state.chatState.isChatDialogShown;
      const showChatList = state.chatState.selectedChatId == null;
      return {
        showChatDialog,
        showChatDialogChatList: showChatDialog && showChatList,
      };
    },
    shallowEqual
  );
  const { logout } = useContext(AuthContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isSwitchStationDialogOpen, setIsSwitchStationDialogOpen] =
    useState(false);
  const [
    isRemoveStationProfileDialogOpen,
    setIsRemoveStationProfileDialogOpen,
  ] = useState(false);
  const [removeStation, setRemoveStation] = useState<Station | null>(null);
  const [isAddStationManagerDialogOpen, setIsAddStationManagerDialogOpen] =
    useState(false);

  const { data: chatUnreadCountResponse } = useGetUnreadChatCount();
  const unreadChatCount = chatUnreadCountResponse?.count ?? 0;

  const { pendingNotificationUnreadCount } = useContext(NotificationContext);

  const onLogoutClick = useCallback(() => {
    logout().catch((err: unknown) => {
      showError(err, "auth.action.logout.error.title");
    });
    dispatch(resetFilter());
  }, [logout, showError, dispatch]);

  const onSwitchStationClick = useCallback(() => {
    setIsSwitchStationDialogOpen(true);
  }, []);

  const onSwitchStationProfileDialogClose = useCallback(() => {
    setIsSwitchStationDialogOpen(false);
  }, []);

  const onStartChatClick = useCallback(() => {
    dispatch(startChat({ deselectChat: true }));
  }, [dispatch]);

  const onNotificationClick = useCallback(() => {
    navigate(AppRoutes.PendingNotificationTab.render());
  }, [navigate]);

  const [isSlideoverOpen, setIsSlideoverOpen] = useState<boolean>(false);

  const onOpenSlideover = useCallback(() => setIsSlideoverOpen(true), []);
  const onCloseSlideover = useCallback(() => setIsSlideoverOpen(false), []);

  const onAddStationManagerDialogClick = useCallback(() => {
    setIsAddStationManagerDialogOpen(true);
  }, []);
  const onAddStationManagerDialogClose = useCallback(() => {
    setIsAddStationManagerDialogOpen(false);
  }, []);

  const onRemoveStationProfileDialogClick = useCallback(
    (targetStation: Station) => {
      setRemoveStation(targetStation);
      setIsSwitchStationDialogOpen(false);
      setIsRemoveStationProfileDialogOpen(true);
    },
    []
  );
  const onRemoveStationProfileDialogClose = useCallback(() => {
    setIsRemoveStationProfileDialogOpen(false);
  }, []);

  // Workaround background scrollable when chat dialog is active
  // while covering whole screen in mobile view
  useEffect(() => {
    if (showChatDialog && !isSm) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [showChatDialog, isSm]);

  return (
    <div>
      <MainHeader
        meUser={meUser}
        meUserSelectedStation={meUserSelectedStation}
        hasUnreadChat={unreadChatCount > 0}
        onLogoutClick={onLogoutClick}
        onSwitchStationClick={onSwitchStationClick}
        onOpenSlideover={onOpenSlideover}
        onStartChatClick={onStartChatClick}
        showChatDialogChatList={showChatDialogChatList}
        onNotificationClick={onNotificationClick}
        unreadNotificationCount={pendingNotificationUnreadCount}
        onAddStationManagerDialogClick={onAddStationManagerDialogClick}
      />
      <SlideoverUserMenu
        isOpen={isSlideoverOpen}
        onClose={onCloseSlideover}
        meUser={meUser}
        meUserSelectedStation={meUserSelectedStation}
        onClickLogout={onLogoutClick}
        onSwitchStationClick={onSwitchStationClick}
      />
      <div
        className={cn(
          "pt-4",
          "sm:pt-6",
          "md:px-8",
          "sm:pb-6",
          "flex-1",
          "min-h-0"
        )}
      >
        {children}
      </div>
      {meUser?.adminRole === "MANAGER" ? (
        <AddStationManagerDialog
          isOpen={isAddStationManagerDialogOpen}
          onClose={onAddStationManagerDialogClose}
          meUserSelectedStation={meUserSelectedStation}
        />
      ) : null}
      {meUser != null ? (
        <>
          <RemoveStationProfileDialog
            station={removeStation}
            isOpen={isRemoveStationProfileDialogOpen}
            onClose={onRemoveStationProfileDialogClose}
          />
          <SwitchStationProfileDialog
            meUser={meUser}
            isOpen={isSwitchStationDialogOpen}
            onClose={onSwitchStationProfileDialogClose}
            onRemoveClick={onRemoveStationProfileDialogClick}
          />
        </>
      ) : null}
      {showChatDialog ? <ChatDialog /> : null}
      <UnreadInviteStationDialog />
    </div>
  );
};
