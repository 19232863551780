import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { sortChat, sortMessages, updateChatByNewMessage } from "../models/chat";
import { dedup } from "oneclick-component/src/utils/list";
import {
  Chat,
  ChatUserStatus,
  Message,
} from "oneclick-component/src/store/apis/enhancedApi";
import { useSelector } from "react-redux";
import { RootState } from "./store";

export type FetchChatMessageDirection = "before" | "after";
export type ChatDialogView = "chatList" | "messageList";
// status by stationId by chatId
interface ChatStatusState {
  lastReadAt: string | null;
}
export type ChatReadStatusMap = Partial<
  Partial<Record<string, ChatStatusState>>
>;

export interface ChatMessageState {
  isPrivate: boolean;
  isFetchingMessages: boolean;
  isFetchingMoreMessagesBefore: boolean;
  isFetchingMoreMessagesAfter: boolean;
  messageListAfterCursor: string | undefined;
  messageListBeforeCursor: string | undefined;
  messages: Message[];
}

export interface ChatState {
  isChatDialogShown: boolean;
  selectedChatId: number | undefined;
  isFetchingChatById: Partial<Record<number, boolean>>;
  chats: Chat[];
  chatById: Partial<Record<number, Chat>>;
  chatReadStatus: ChatReadStatusMap;
  isFetchingChats: boolean;
  isFetchingMoreChats: boolean;
  chatListCursor: string | undefined;
  chatSearchText: string | undefined;
  chatMessages: Partial<Record<number, ChatMessageState>>;
  navigationStack: ChatDialogView[];
}

export interface StartChatPayload {
  chatId?: number;
  deselectChat?: boolean;
  isPrivate?: boolean;
}

export interface SelectChatPayload {
  chat: Chat;
}

export interface StartFetchChatByIdPayload {
  chatId: number;
}

export interface FinishFetchChatByIdPayload {
  chat: Chat;
  chatStatus: ChatUserStatus | null;
}

export interface FetchChatByIdFailedPayload {
  chatId: number;
}

export interface StartFetchingChatsPayload {
  q: string | undefined;
}

export interface FinishFetchingChatsPayload {
  chats: Chat[];
  before: string | undefined;
  statusByChat: Partial<Record<string, ChatUserStatus | null>>;
}

export interface StartFetchingMessagesPayload {
  chatId: number;
}

export interface StartFetchingMoreMessagesPayload {
  direction: FetchChatMessageDirection;
  chatId: number;
}

export interface FinishFetchingMoreMessagesPayload {
  chatId: number;
  direction: FetchChatMessageDirection;
  messages: Message[];
  before: string | undefined;
  after: string | undefined;
}

export interface FetchMessagesFailedPayload {
  chatId: number;
}

export interface MessageAddedPayload {
  message: Message;
}

export interface ChatAddedPayload {
  chat: Chat;
}

export interface PrivateModeToggledPayload {
  chatId: number;
  isPrivate: boolean;
}

export interface ChatReadPayload {
  chatId: number;
  readAt?: string; // default to now
}

export interface MessageStatusUpdatedPayload {
  chatId: number;
  messageId: number;
  status: Message["status"];
}

export const initialChatMessageState: ChatMessageState = {
  isPrivate: false,
  isFetchingMessages: false,
  isFetchingMoreMessagesBefore: false,
  isFetchingMoreMessagesAfter: false,
  messageListAfterCursor: undefined,
  messageListBeforeCursor: undefined,
  messages: [],
};

const initialState: ChatState = {
  isChatDialogShown: false,
  selectedChatId: undefined,
  isFetchingChatById: {},
  chats: [],
  chatById: {},
  chatReadStatus: {},
  isFetchingChats: false,
  isFetchingMoreChats: false,
  chatListCursor: undefined,
  chatSearchText: undefined,
  chatMessages: {},
  navigationStack: [],
};

function updateChatStatus(
  chatReadStatusState: ChatReadStatusMap,
  chatId: number,
  readAt: string | null
): ChatReadStatusMap {
  const existingChatStatus = chatReadStatusState[chatId] ?? {};
  const newChatStatus: ChatStatusState = {
    ...existingChatStatus,
    lastReadAt: readAt,
  };
  return {
    ...chatReadStatusState,
    [chatId]: newChatStatus,
  };
}

function batchUpdateChatStatus(
  chatReadStatusState: ChatReadStatusMap,
  statusByChat: Partial<Record<string, ChatUserStatus | null>>
): ChatReadStatusMap {
  const chatStatusMap = {
    ...chatReadStatusState,
  };
  for (const chatId of Object.keys(statusByChat)) {
    chatStatusMap[chatId] = {
      lastReadAt: statusByChat[chatId]?.lastReadAt ?? null,
    };
  }
  return chatStatusMap;
}

function updateMessage(
  state: ChatState,
  chatId: number,
  messageId: number,
  updater: (prev: Message) => Message
): ChatState {
  const chatMessageState = state.chatMessages[chatId];
  if (chatMessageState == null) {
    return state;
  }

  const updatedMessages = chatMessageState.messages.map((m) => {
    if (m.id !== messageId) {
      return m;
    }
    return updater(m);
  });

  return {
    ...state,
    chatMessages: {
      ...state.chatMessages,
      [chatId]: {
        ...chatMessageState,
        messages: updatedMessages,
      },
    },
  };
}

const chatStateSlice = createSlice({
  name: "chatState",
  initialState,
  reducers: {
    startChat: (state, action: PayloadAction<StartChatPayload | undefined>) => {
      const { chatId, deselectChat, isPrivate } = action.payload ?? {};
      const selectedChatId = deselectChat
        ? undefined
        : chatId ?? state.selectedChatId;
      const navigationStack: ChatDialogView[] =
        selectedChatId != null ? ["messageList"] : ["chatList"];

      let updatedChatMessagesMap = null;
      if (selectedChatId != null) {
        const chatMessageState =
          state.chatMessages[selectedChatId] ?? initialChatMessageState;
        updatedChatMessagesMap = {
          ...state.chatMessages,
          [selectedChatId]: {
            ...chatMessageState,
            isPrivate: isPrivate ?? chatMessageState.isPrivate,
          },
        };
      }
      return {
        ...state,
        selectedChatId,
        isChatDialogShown: true,
        navigationStack,
        chatMessages: updatedChatMessagesMap ?? state.chatMessages,
      };
    },
    dismissChatDialog: (state) => {
      return {
        ...state,
        isChatDialogShown: false,
        navigationStack: [],
      };
    },
    selectChat: (state, action: PayloadAction<SelectChatPayload>) => {
      let updatedNavigationStack: ChatDialogView[];
      if (state.navigationStack[0] !== "messageList") {
        updatedNavigationStack = ["messageList", ...state.navigationStack];
      } else {
        updatedNavigationStack = state.navigationStack;
      }
      return {
        ...state,
        selectedChatId: action.payload.chat.id,
        navigationStack: updatedNavigationStack,
      };
    },
    back: (state) => {
      const updatedNavigationStack = state.navigationStack.slice(1);
      const updatedView = updatedNavigationStack[0];

      // Can only back to chat list, otherwise empty stack / invalid stack
      // Dismiss dialog reset stack
      if (updatedView === "chatList") {
        return {
          ...state,
          selectedChatId: undefined,
          navigationStack: updatedNavigationStack,
        };
      }
      return {
        ...state,
        selectedChatId: undefined,
        isChatDialogShown: false,
        navigationStack: [],
      };
    },
    startFetchChatById: (
      state,
      action: PayloadAction<StartFetchChatByIdPayload>
    ) => {
      const { chatId } = action.payload;
      return {
        ...state,
        isFetchingChatById: {
          ...state.isFetchingChatById,
          [chatId]: true,
        },
      };
    },
    finishFetchChatById: (
      state,
      action: PayloadAction<FinishFetchChatByIdPayload>
    ) => {
      const { chat, chatStatus } = action.payload;
      const updatedChatById = {
        ...state.chatById,
        [chat.id]: chat,
      };
      const updatedIsFetchingChatById = {
        ...state.isFetchingChatById,
        [chat.id]: false,
      };
      return {
        ...state,
        isFetchingChatById: updatedIsFetchingChatById,
        chatById: updatedChatById,
        chatReadStatus: updateChatStatus(
          state.chatReadStatus,
          chat.id,
          chatStatus?.lastReadAt ?? null
        ),
      };
    },
    fetchChatByIdFailed: (
      state,
      action: PayloadAction<FetchChatByIdFailedPayload>
    ) => {
      const { chatId } = action.payload;
      return {
        ...state,
        isFetchingChatById: {
          ...state.isFetchingChatById,
          [chatId]: false,
        },
      };
    },
    startFetchingChats: (
      state,
      action: PayloadAction<StartFetchingChatsPayload>
    ) => {
      return {
        ...state,
        isFetchingChats: true,
        isFetchingMoreChats: false,
        chats: [],
        chatListCursor: undefined,
        chatSearchText: action.payload.q,
      };
    },
    startFetchingMoreChats: (state) => {
      return {
        ...state,
        isFetchingMoreChats: true,
      };
    },
    finishFetchingChat: (
      state,
      action: PayloadAction<FinishFetchingChatsPayload>
    ) => {
      const { chats, statusByChat, before } = action.payload;
      const updatedChatById = { ...state.chatById };
      for (const chat of chats) {
        updatedChatById[chat.id] = chat;
      }
      return {
        ...state,
        isFetchingChats: false,
        isFetchingMoreChats: false,
        chats: [...state.chats, ...chats],
        chatById: updatedChatById,
        chatListCursor: before,
        chatReadStatus: batchUpdateChatStatus(
          state.chatReadStatus,
          statusByChat
        ),
      };
    },
    fetchChatFailed: (state) => {
      return {
        ...state,
        isFetchingChats: false,
        isFetchingMoreChats: false,
      };
    },
    startFetchingMessages: (
      state,
      action: PayloadAction<StartFetchingMessagesPayload>
    ) => {
      const chatMessageState =
        state.chatMessages[action.payload.chatId] ?? initialChatMessageState;
      return {
        ...state,
        chatMessages: {
          ...state.chatMessages,
          [action.payload.chatId]: {
            ...chatMessageState,
            isFetchingMessages: true,
          },
        },
      };
    },
    startFetchingMoreMessages: (
      state,
      action: PayloadAction<StartFetchingMoreMessagesPayload>
    ) => {
      const chatMessageState =
        state.chatMessages[action.payload.chatId] ?? initialChatMessageState;

      const updatedChatMessageState = {
        ...chatMessageState,
      };
      if (action.payload.direction === "before") {
        updatedChatMessageState.isFetchingMoreMessagesBefore = true;
      } else {
        // action.payload.direction === "after"
        updatedChatMessageState.isFetchingMoreMessagesAfter = true;
      }
      return {
        ...state,
        chatMessages: {
          ...state.chatMessages,
          [action.payload.chatId]: updatedChatMessageState,
        },
      };
    },
    finishFetchingMessages: (
      state,
      action: PayloadAction<FinishFetchingMoreMessagesPayload>
    ) => {
      const chatMessageState =
        state.chatMessages[action.payload.chatId] ?? initialChatMessageState;

      const updatedChatMessageState: ChatMessageState = {
        ...chatMessageState,
        isFetchingMessages: false,
        messageListBeforeCursor: action.payload.before,
        messageListAfterCursor: action.payload.after,
      };

      const newMessages = action.payload.messages;
      if (action.payload.direction === "before") {
        updatedChatMessageState.messages = [
          ...[...newMessages].reverse(),
          ...updatedChatMessageState.messages,
        ];
        updatedChatMessageState.isFetchingMoreMessagesBefore = false;
      } else {
        // action.payload.direction === "after"
        updatedChatMessageState.messages = [
          ...updatedChatMessageState.messages,
          ...newMessages,
        ];
        updatedChatMessageState.isFetchingMoreMessagesAfter = false;
      }

      // dedup and sort message list
      updatedChatMessageState.messages = sortMessages(
        dedup(updatedChatMessageState.messages)
      );

      return {
        ...state,
        chatMessages: {
          ...state.chatMessages,
          [action.payload.chatId]: updatedChatMessageState,
        },
      };
    },
    fetchMessagesFailed: (
      state,
      action: PayloadAction<FetchMessagesFailedPayload>
    ) => {
      const chatMessageState =
        state.chatMessages[action.payload.chatId] ?? initialChatMessageState;
      return {
        ...state,
        chatMessages: {
          ...state.chatMessages,
          [action.payload.chatId]: {
            ...chatMessageState,
            isFetchingMessages: false,
            isFetchingMoreMessages: false,
          },
        },
      };
    },
    messageAdded: (state, action: PayloadAction<MessageAddedPayload>) => {
      const { message } = action.payload;

      let updatedChatById;
      let sortedUpdatedChats;
      let updatedChatMessagesMap;

      // Update chat last message
      const chatMessageState = state.chatMessages[message.chatId];
      const chatInMap = state.chatById[message.chatId];
      if (chatInMap != null) {
        updatedChatById = {
          ...state.chatById,
          [message.chatId]: updateChatByNewMessage(chatInMap, message),
        };
      } else {
        updatedChatById = state.chatById;
      }

      // Update chat list item last message
      const chatListIndex = state.chats.findIndex(
        (c) => c.id === message.chatId
      );
      if (chatListIndex >= 0) {
        const updatedChats = state.chats.map((c, index) => {
          if (index !== chatListIndex) {
            return c;
          }
          return updateChatByNewMessage(c, message);
        });
        sortedUpdatedChats = sortChat(updatedChats);
      } else {
        sortedUpdatedChats = state.chats;
      }

      // if latest message not loaded, skip adding message let user scroll to load more
      if (
        chatMessageState != null &&
        chatMessageState.messageListAfterCursor == null
      ) {
        updatedChatMessagesMap = {
          ...state.chatMessages,
          [message.chatId]: {
            ...chatMessageState,
            messages: dedup([...chatMessageState.messages, message]),
          },
        };
      } else {
        updatedChatMessagesMap = state.chatMessages;
      }

      return {
        ...state,
        chatById: updatedChatById,
        chats: sortedUpdatedChats,
        chatMessages: updatedChatMessagesMap,
      };
    },
    chatAdded: (state, action: PayloadAction<ChatAddedPayload>) => {
      const { chat } = action.payload;
      const existingChatIndex = state.chats.findIndex((c) => c.id === chat.id);
      if (existingChatIndex >= 0) {
        return state;
      }
      return {
        ...state,
        chats: sortChat([chat, ...state.chats]),
      };
    },
    privateModeToggled: (
      state,
      action: PayloadAction<PrivateModeToggledPayload>
    ) => {
      const { chatId, isPrivate } = action.payload;
      const chatMessageState = state.chatMessages[chatId];
      if (
        chatMessageState != null &&
        chatMessageState.isPrivate !== isPrivate
      ) {
        const updatedChatMessagesMap = {
          ...state.chatMessages,
          [chatId]: {
            ...chatMessageState,
            isPrivate,
          },
        };
        return {
          ...state,
          chatMessages: updatedChatMessagesMap,
        };
      }

      return state;
    },
    chatRead: (state, action: PayloadAction<ChatReadPayload>) => {
      const { chatId, readAt } = action.payload;

      return {
        ...state,
        chatReadStatus: updateChatStatus(
          state.chatReadStatus,
          chatId,
          readAt ?? new Date().toISOString()
        ),
      };
    },
    messageStatusUpdated: (
      state,
      action: PayloadAction<MessageStatusUpdatedPayload>
    ) => {
      const { chatId, messageId, status } = action.payload;

      function updateMessageStatus(m: Message) {
        return {
          ...m,
          status,
        };
      }

      return updateMessage(state, chatId, messageId, updateMessageStatus);
    },
  },
});

export const {
  startChat,
  dismissChatDialog,
  selectChat,
  back,
  startFetchChatById,
  finishFetchChatById,
  fetchChatByIdFailed,
  startFetchingChats,
  startFetchingMoreChats,
  finishFetchingChat,
  fetchChatFailed,
  startFetchingMessages,
  startFetchingMoreMessages,
  finishFetchingMessages,
  fetchMessagesFailed,
  messageAdded,
  chatAdded,
  privateModeToggled,
  chatRead,
  messageStatusUpdated,
} = chatStateSlice.actions;

export default chatStateSlice.reducer;

export function useChatStatus(chat: Chat): ChatStatusState | null {
  return (
    useSelector((state: RootState) => {
      return state.chatState.chatReadStatus[chat.id];
    }) ?? null
  );
}
