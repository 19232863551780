import React, { ForwardedRef, ReactElement, useCallback } from "react";
import cn from "classnames";

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  className?: string;
  label?: string;
  labelClassName?: string;
  description?: string;
  indeterminate?: boolean;
}

export const Checkbox = React.forwardRef(function Checkbox(
  props: Props,
  ref: ForwardedRef<HTMLInputElement>
): ReactElement {
  const {
    name,
    label,
    description,
    className,
    labelClassName,
    indeterminate,
    checked,
    ...rest
  } = props;
  const myRef = React.useRef<HTMLInputElement>(null!);

  React.useEffect(() => {
    if (indeterminate != null) {
      myRef.current.indeterminate = !checked && indeterminate;
    }
  }, [ref, indeterminate, checked]);

  const resolveRef = useCallback(
    (element: HTMLInputElement) => {
      myRef.current = element;
      if (typeof ref === "function") {
        ref(element);
      } else if (ref?.current != null) {
        ref.current = element;
      }
    },
    [ref]
  );
  return (
    <div className={cn("relative", "flex", "items-start", className)}>
      <div className={cn("flex", "h-6", "items-center")}>
        <input
          id={name}
          name={name}
          ref={resolveRef}
          aria-describedby="description"
          type="checkbox"
          className={cn(
            "h-4",
            "w-4",
            "rounded",
            "border-gray-300",
            "text-primary-600",
            "focus:ring-indigo-600",
            "hover:cursor-pointer"
          )}
          checked={checked}
          {...rest}
        />
      </div>
      {label != null ? (
        <div className={cn("text-sm", "leading-6", "text-left")}>
          <label
            htmlFor={name}
            className={cn(
              "pl-3",
              "font-medium",
              "text-gray-900",
              "hover:cursor-pointer",
              labelClassName
            )}
          >
            {label}
          </label>
          {description != null ? (
            <p id="description" className="text-gray-500">
              {description}
            </p>
          ) : null}
        </div>
      ) : null}
    </div>
  );
});
