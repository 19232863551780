import React, { ReactElement } from "react";
import cn from "classnames";
import { StartChatButtonIcon } from "../../icon";

interface Props {
  className?: string;
  onClick: () => void;
  isActive: boolean;
  hasUnreadChat: boolean;
}

export const StartChatButton = React.memo((props: Props): ReactElement => {
  const { className, onClick, isActive, hasUnreadChat } = props;

  return (
    <button
      type="button"
      className={cn(
        "relative",
        "rounded-full",
        "focus:ring-2",
        "focus:ring-offset-2",
        "focus:outline-0",
        "focus:ring-offset-white",
        "focus:ring-primary-500",
        "p-2.5",
        className,
        {
          "bg-gray-100 hover:bg-gray-200": !isActive,
          "bg-primary-300 hover:bg-primary-400": isActive,
        }
      )}
      onClick={onClick}
    >
      <StartChatButtonIcon className={cn("h-5", "w-5", "fill-black/60")} />
      {hasUnreadChat ? (
        <div
          // eslint-disable-next-line tailwindcss/enforces-negative-arbitrary-values
          className={cn(
            "absolute",
            "top-0",
            "-right-[0.1875rem]",
            "w-2",
            "h-2",
            "min-w-2",
            "min-h-2",
            "bg-red-600",
            "rounded-full"
          )}
        />
      ) : null}
    </button>
  );
});
