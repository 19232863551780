import { ReactElement } from "react";
import cn from "classnames";

interface Props {
  active: boolean;
  className?: string;
}

const ShiftT3Badge = (props: Props): ReactElement => {
  const { active, className } = props;
  return (
    <span
      className={cn(
        "inline",
        "rounded-full",
        "px-2",
        "outline",
        "outline-1",
        "text-xs",
        "font-bold",
        "h-4.5",
        "py-[1px]",
        "text-center",
        "align-middle",
        {
          "outline-green-500 text-green-500": active,
          "outline-black/24 text-black/24": !active,
        },
        "break-keep",
        className
      )}
    >
      T‑3
    </span>
  );
};

export default ShiftT3Badge;
