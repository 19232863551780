import { ReactElement, useCallback } from "react";
import { Trans } from "react-i18next";
import cn from "classnames";
import { Button } from "oneclick-component/src/components/Button";
import { ChevronDownIcon } from "oneclick-component/src/icon";
import {
  Shift,
  ListShiftRequestsWithNumberingHandlerShiftsShiftIdRequestsGetApiResponse as ListShiftRequestResponse,
} from "oneclick-component/src/store/apis/enhancedApi";
import { UseInfiniteLoadQueryResponse } from "oneclick-component/src/hooks/useInfiniteQuery";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import {
  RequestedShiftRequestBase,
  RequestedShiftRequestBaseWithNumbering,
} from "../../../models/shiftRequest";
import RequestedPTUserRequestRow from "../../../components/RequestedPTUserRequestRow";
import { useShouldShowPtStationTeam } from "../../../hooks/useShouldShowPtStationTeam";

interface SectionType {
  shift: Shift;
  requests: RequestedShiftRequestBaseWithNumbering[];
  requestedShiftRequestsQuery: UseInfiniteLoadQueryResponse<ListShiftRequestResponse>;
}

const RequestedSection = (props: SectionType): ReactElement => {
  const { requests: _requests, shift, requestedShiftRequestsQuery } = props;
  const shouldShowPtStationTeam = useShouldShowPtStationTeam();

  // remove index here, as no need to show numbers in requested pt
  const requests: RequestedShiftRequestBase[] = _requests.map((req) => ({
    ...req,
    index: undefined,
  }));

  const handleFetchMore = useCallback(() => {
    if (requestedShiftRequestsQuery.data?.nextPageIndex != null) {
      requestedShiftRequestsQuery.fetchNextPage();
    }
  }, [requestedShiftRequestsQuery]);

  if (requests.length === 0) {
    return <></>;
  }
  return (
    <section className={cn("flex", "flex-col", "gap-3")}>
      <p className={cn("grow", "text-black/60", "text-sm", "md:text-base")}>
        <Trans
          i18nKey="shiftDetail.requested.requestedGroup"
          values={{ count: requestedShiftRequestsQuery.data?.totalCount ?? 0 }}
        />
      </p>
      {requests.map((req) => (
        <RequestedPTUserRequestRow
          key={`${shift.id}_${req.ptUserId}`}
          shift={shift}
          user={req.ptUser}
          shiftRequest={req}
          showChatButton={false}
          showStationTeam={shouldShowPtStationTeam}
          disabled={
            shift.status === "cancelled" ||
            shift.status === "expired" ||
            req.ptUser.isDeleted
          }
        />
      ))}
      {requestedShiftRequestsQuery.data?.nextPageIndex != null &&
      !requestedShiftRequestsQuery.isFetching ? (
        <div className={cn("flex", "justify-center")}>
          <Button
            className={cn(
              "bg-transparent",
              "border-none",
              "!shadow-none",
              "hover:bg-transparent",
              "h-6",
              "md:my-3"
            )}
            iconClassName="fill-gray-500"
            suffixIcon={ChevronDownIcon}
            theme="white"
            onClick={handleFetchMore}
          >
            <span className={cn("text-gray-500", "text-xs", "font-normal")}>
              <Trans i18nKey="shiftDetail.userList.fetchMore" />
            </span>
          </Button>
        </div>
      ) : null}
      {requestedShiftRequestsQuery.isFetching ? (
        <div
          className={cn(
            "w-full",
            "h-full",
            "flex",
            "items-center",
            "justify-center"
          )}
        >
          <LoadingSpinner size="l" />
        </div>
      ) : null}
    </section>
  );
};

export default RequestedSection;
