export function isIOS(): boolean {
  const userAgent = window.navigator.userAgent;
  return /iPad|iPhone|iPod/.test(userAgent);
}

export function isSafari(): boolean {
  const userAgent = window.navigator.userAgent;
  return /Safari/.test(userAgent);
}

export function isAndroid(): boolean {
  const userAgent = window.navigator.userAgent;
  return /android/i.test(userAgent);
}

export function isMobile(): boolean {
  return isAndroid() || isIOS();
}
