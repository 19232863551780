import { z } from "zod";
import { regularShiftSchema } from "../../models";

export const editPartTimeFormSchema = z.object({
  firstNameEn: z.string().trim().min(1),
  lastNameEn: z.string().trim().min(1),
  fullNameZhHk: z.string().trim().min(1),
  eid: z.string().trim().length(6),
  countryCode: z.string().min(1),
  phoneNumber: z.string().regex(/^\d+$/), // numeric
  station: z.string().trim().min(1),
  role: z.number(),
  regularShiftSchedule: z.array(regularShiftSchema),
  scheduledLastDate: z.string().nullable(),
  stationTeamId: z.number().int().nullish(),
  receiveInviteOnHoliday: z.boolean(),
});

export type EditPartTimeForm = z.infer<typeof editPartTimeFormSchema>;
