import { DateTime, Duration } from "luxon";
import { Shift } from "oneclick-component/src/store/apis/enhancedApi";
import {
  dateTimeNow,
  isoToDateTime,
} from "oneclick-component/src/utils/datetime";
import { useEffect, useMemo, useState } from "react";

interface UseCloseIncidentExpiryCountdownReturnValue {
  durationUntilExpiry: Duration | null;
}
const useCloseIncidentExpiryCountdown = (
  incidentShift: Shift | undefined
): UseCloseIncidentExpiryCountdownReturnValue => {
  const [timeNow, setTimeNow] = useState<DateTime>(dateTimeNow());
  const closeIncidentExpiryTime = useMemo<DateTime | undefined>(() => {
    if (incidentShift == null || !incidentShift.isIncident) {
      return undefined;
    }
    const shiftStartTime = isoToDateTime(incidentShift.dutyStartTime);
    const expiryTime = shiftStartTime.plus({ hours: 24 });
    return expiryTime;
  }, [incidentShift]);

  const durationUntilExpiry = closeIncidentExpiryTime?.diff(timeNow) ?? null;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeNow(dateTimeNow());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return {
    durationUntilExpiry,
  };
};

export default useCloseIncidentExpiryCountdown;
