import tailwindConfig from "../../tailwind.config";

export const breakpoints: Record<
  keyof NonNullable<typeof tailwindConfig.theme.screens>,
  number
> = Object.entries(tailwindConfig.theme.screens).reduce<any>(
  (acc, [device, size]) => ({
    ...acc,
    [device]: +Number(size.replace("px", "")),
  }),
  {}
);

export default tailwindConfig;
