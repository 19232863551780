import React, { ReactElement, useMemo } from "react";
import { DateTime } from "luxon";
import { Trans } from "react-i18next";
import cn from "classnames";

import {
  formatDateTime,
  isoToDateTime,
} from "oneclick-component/src/utils/datetime";
import { Message } from "oneclick-component/src/store/apis/enhancedApi";

import { BotIcon } from "../../../icon";
import { MessageBubbleContent } from "./MessageBubbleContent";

const BotMessageAvatar = React.memo((): ReactElement => {
  return (
    <div
      className={cn(
        "flex",
        "items-center",
        "justify-center",
        "bg-white",
        "border",
        "border-primary-300",
        "rounded-full",
        "w-8",
        "h-8",
        "min-w-8",
        "min-h-8"
      )}
    >
      <BotIcon className={cn("w-5", "h-5", "fill-primary-500")} />
    </div>
  );
});

interface SystemMessageItemHeaderProps {
  isPrivateMode: boolean;
  timestamp: DateTime;
}

const SystemMessageItemHeader = React.memo(
  (props: SystemMessageItemHeaderProps): ReactElement => {
    const { timestamp, isPrivateMode } = props;

    const formattedTimestamp = useMemo(() => {
      return formatDateTime(timestamp, "h:mm a");
    }, [timestamp]);

    return (
      <div className={cn("flex", "items-center", "mb-1", "justify-start")}>
        <div>
          <span
            className={cn(
              "truncate",
              "overflow-hidden",
              "text-xs",
              "font-medium",
              {
                "text-white": isPrivateMode,
                "text-black/[.86]": !isPrivateMode,
              }
            )}
          >
            <Trans i18nKey="chatDialog.messageItem.systemUsername" />
          </span>
          <span
            className={cn("text-xs", "ml-2", {
              "text-white": isPrivateMode,
              "text-black/[.86]": !isPrivateMode,
            })}
          >
            {formattedTimestamp}
          </span>
        </div>
      </div>
    );
  }
);

interface MessageListSystemMessageProps {
  message: Message;
  isPrivateMode: boolean;
}

export const MessageListSystemMessage = React.memo(
  (props: MessageListSystemMessageProps): ReactElement => {
    const { isPrivateMode, message } = props;

    const messageTimestamp = useMemo(() => {
      return isoToDateTime(message.createdAt);
    }, [message.createdAt]);

    return (
      <li
        className={cn(
          "list-none",
          "px-5",
          "mb-5",
          "flex",
          "items-end",
          "gap-3",
          "justify-start"
        )}
      >
        <BotMessageAvatar />
        <div className={cn("max-w-[85%]", "text-left")}>
          <SystemMessageItemHeader
            timestamp={messageTimestamp}
            isPrivateMode={isPrivateMode}
          />
          <div
            className={cn(
              "inline-block",
              "rounded-3xl",
              "whitespace-pre-wrap",
              "overflow-wrap-anywhere",
              "text-sm",
              "text-left",
              "bg-gray-100"
            )}
          >
            <MessageBubbleContent contentType="text" message={message} />
          </div>
        </div>
      </li>
    );
  }
);
