import React, { ReactElement, useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import i18next from "i18next";
import { Menu } from "@headlessui/react";
import cn from "classnames";

import {
  MeUser,
  UserStationProfile,
  useUpdateLanguagePreferenceHandlerMeLanguagePreferencePostMutation,
} from "oneclick-component/src/store/apis/enhancedApi";
import { Button } from "oneclick-component/src/components/Button";
import { PopMenuItem } from "oneclick-component/src/components/PopMenu";
import { Locale } from "oneclick-component/src/i18n/Locale";
import { StationBadge } from "oneclick-component/src/components/Badge";
import { PlusIcon } from "@heroicons/react/20/solid";
import AppRoutes from "../../routes/AppRoutes";
import { useShowError } from "../../hooks/useShowError";
import { ToggleButton } from "./ToggleButton";

interface UserMenuProps {
  meUser: MeUser | null;
  meUserSelectedStation: UserStationProfile | null;
  onLogoutClick: () => void;
  onSwitchStationClick: () => void;
  onAddStationManagerDialogClick: () => void;
}
export const UserMenu = (props: UserMenuProps): ReactElement => {
  const {
    meUser,
    meUserSelectedStation,
    onLogoutClick,
    onSwitchStationClick,
    onAddStationManagerDialogClick,
  } = props;
  const { t } = useTranslation();
  const { showError } = useShowError();
  const [updateLanguagePreference] =
    useUpdateLanguagePreferenceHandlerMeLanguagePreferencePostMutation();
  const makeOnClickLocale = useCallback(
    (locale: Locale) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      if (i18next.language === locale) return;
      updateLanguagePreference({
        updateLanguagePreferenceRequest: {
          newLanguagePreference: locale,
        },
      })
        .unwrap()
        .then(() => {
          window.location.reload();
        })
        .catch((err: unknown) => {
          console.error(err);
          showError(err, "menu.changeLang.toast.fail.title");
        });
    },
    [updateLanguagePreference, showError]
  );

  if (meUser == null) {
    return (
      <>
        <div className="py-1">
          <Menu.Item>
            <div className={cn("px-4", "py-2", "text-sm", "text-gray-900")}>
              <Trans i18nKey="auth.anonymous" />
            </div>
          </Menu.Item>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="py-1">
        <Menu.Item>
          <div className={cn("px-4", "py-3", "text-sm", "text-gray-900")}>
            <div className={cn("flex", "flex-row")}>
              <p className="grow">
                <Trans i18nKey="menu.loginAs" />
              </p>
              <p className={cn("text-xs", "text-black/24", "font-normal")}>
                V {window.appConfig.version ?? "--"}
              </p>
            </div>
            <div>{meUser.email}</div>
            {meUser.adminRole === "MANAGER" ? (
              <div
                className={cn(
                  "flex",
                  "justify-between",
                  "items-center",
                  "mt-2"
                )}
              >
                {meUserSelectedStation != null ? (
                  <StationBadge
                    station={meUserSelectedStation.station}
                    stationTeam={null}
                  />
                ) : null}
                {meUser.stations.length > 1 ? (
                  <button
                    type="button"
                    className={cn("text-primary-800", "text-sm", "underline")}
                    onClick={onSwitchStationClick}
                  >
                    <Trans i18nKey="menu.switchStation" />
                  </button>
                ) : null}
              </div>
            ) : null}
          </div>
        </Menu.Item>
      </div>
      <div className="py-1">
        <PopMenuItem
          type="link"
          link={AppRoutes.PartTimeListScreen.render()}
          text={t("menu.managePTUser")}
        />
        <PopMenuItem
          type="link"
          link={AppRoutes.AnnouncementScreen.render()}
          text={t("menu.announcement")}
        />
        {meUser.adminRole === "ADMIN" ? (
          <PopMenuItem
            type="link"
            link={AppRoutes.PublicHolidayScreen.render()}
            text={t("menu.publicHoliday")}
          />
        ) : null}
      </div>
      <div className="py-1">
        <Menu.Item>
          <div className={cn("py-3", "px-4", "flex", "flex-row")}>
            <ToggleButton
              active={i18next.language === Locale.zhHantHK}
              onClick={makeOnClickLocale(Locale.zhHantHK)}
              className="flex-1"
            >
              <Trans i18nKey="common.locale.zhHantHK" />
            </ToggleButton>
            <ToggleButton
              active={i18next.language === Locale.en}
              onClick={makeOnClickLocale(Locale.en)}
              className="flex-1"
            >
              <Trans i18nKey="common.locale.en" />
            </ToggleButton>
          </div>
        </Menu.Item>
        <Button
          className={cn("my-3", "mx-4", "text-xs", "h-7.5", "!px-3")}
          theme="white"
          onClick={onAddStationManagerDialogClick}
          prefixIcon={PlusIcon}
        >
          <Trans i18nKey="menu.addStationManager" />
        </Button>
      </div>
      <div className="py-1">
        <PopMenuItem
          type="button"
          onClick={onLogoutClick}
          text={t("menu.logout")}
        />
      </div>
    </>
  );
};
