import React, { ReactElement, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import cn from "classnames";

import {
  Chat,
  Message,
  MeUser,
} from "oneclick-component/src/store/apis/enhancedApi";
import {
  dateTimeNow,
  isoToDateTime,
} from "oneclick-component/src/utils/datetime";
import CCOBadge from "oneclick-component/src/components/Badge/CCOBadge";

import { UserIcon } from "../../icon";
import { useRenderMessageContent } from "../../hooks/useRenderMessageContent";
import { ChatStationsBadge } from "./ChatStationBadge";
import { PTUserAvatarImage } from "../PTUserAvatarImage";
import { useChatStatus } from "../../store/chatState";
import { useShouldShowPtStationTeam } from "../../hooks/useShouldShowPtStationTeam";
import { resolveMessageContentType } from "./MessageListItem/type";

export const UnreadIndicator = React.memo((): ReactElement => {
  return (
    <div
      className={cn(
        "w-2",
        "h-2",
        "min-w-2",
        "min-h-2",
        "bg-primary-500",
        "rounded-full"
      )}
    />
  );
});

function renderLastMessageText(
  lastMessage: Message,
  renderTextMessageContent: (message: Message) => string,
  t: TFunction
) {
  const messageContentType = resolveMessageContentType(lastMessage);
  switch (messageContentType) {
    case "text":
      return renderTextMessageContent(lastMessage);
    case "image":
      return t("chatDialog.chatList.lastMessage.image");
    case "video":
      return t("chatDialog.chatList.lastMessage.video");
    case "audio":
      return t("chatDialog.chatList.lastMessage.audio");
    case "document": {
      const attachments = lastMessage.attachments;
      if (attachments.length > 1) {
        return t("chatDialog.chatList.lastMessage.multipleDocument");
      }
      return t("chatDialog.chatList.lastMessage.document", {
        filename: attachments[0].asset.filename,
      });
    }
  }
}

function hasUnreadMessage(
  lastMessage: Message | null,
  lastReadAt: string | null
) {
  if (lastMessage == null) {
    return false;
  }
  if (lastReadAt == null) {
    return true;
  }

  // last message timestamp later than last read timestamp
  return (
    isoToDateTime(lastReadAt).toUnixInteger() <
    isoToDateTime(lastMessage.createdAt).toUnixInteger()
  );
}

interface LastMessageSectionProps {
  chat: Chat;
  meUser: MeUser | null;
  lastReadAt: string | null;
}

export const LastMessageSection = React.memo(
  (props: LastMessageSectionProps) => {
    const { chat, meUser, lastReadAt } = props;
    const { renderMessageContent } = useRenderMessageContent();
    const { t } = useTranslation();

    const lastMessageContent = useMemo(() => {
      if (chat.lastMessage == null) {
        return "-";
      }

      return renderLastMessageText(chat.lastMessage, renderMessageContent, t);
    }, [chat.lastMessage, renderMessageContent, t]);

    const isChatUnread = useMemo(() => {
      if (meUser == null) {
        return false;
      }

      return hasUnreadMessage(chat.lastMessage, lastReadAt);
    }, [lastReadAt, chat.lastMessage, meUser]);

    return (
      <div className={cn("flex", "items-center")}>
        <span
          className={cn("pr-2", "truncate", "overflow-hidden", "text-xs", {
            "text-primary-600": isChatUnread,
            "text-black/60": !isChatUnread,
          })}
        >
          {lastMessageContent}
        </span>
        {isChatUnread ? <UnreadIndicator /> : null}
      </div>
    );
  }
);

interface ChatListItemProps {
  meUser: MeUser | null;
  chat: Chat;
  onChatSelected: (chat: Chat) => void;
}

export const ChatListItem = React.memo(
  // eslint-disable-next-line sonarjs/cognitive-complexity
  (props: ChatListItemProps): ReactElement => {
    const { meUser, chat, onChatSelected } = props;
    const { t } = useTranslation();
    const chatStatus = useChatStatus(chat);

    const ptUserName = chat.ptUser.fullNameZhHk;
    const shouldShowPtStationTeam = useShouldShowPtStationTeam();

    const ptUserExtraInfo = useMemo(() => {
      // Add indicator for deleted PT user
      if (chat.ptUser.isDeleted) {
        return `(${t("chatDialog.deletedPtUser")})`;
      }
      return null;
    }, [chat.ptUser, t]);

    const participantsDisplayText = useMemo(() => {
      const participants = [
        ptUserName,
        t("chatDialog.chatList.stationSupervisorParticipant", {
          stationCode: chat.station.shortCode,
        }),
      ];
      if (chat.partnerStation != null) {
        participants.push(
          t("chatDialog.chatList.stationSupervisorParticipant", {
            stationCode: chat.partnerStation.shortCode,
          })
        );
      }
      return participants.join(", ");
    }, [t, ptUserName, chat.station, chat.partnerStation]);

    const formattedLastMessageTimestamp = useMemo(() => {
      if (chat.lastMessage == null) {
        return "-";
      }
      const parsedDatetime = isoToDateTime(chat.lastMessage.createdAt);
      const nowDatetime = dateTimeNow();
      const sameDate = nowDatetime.toISODate() === parsedDatetime.toISODate();
      const sameYear = nowDatetime.year === parsedDatetime.year;

      if (sameDate) {
        return parsedDatetime.toFormat("h:mm a");
      } else if (sameYear) {
        return parsedDatetime.toFormat("dd/LL");
      }
      // not same year
      return parsedDatetime.toFormat("dd/LL/yyyy");
    }, [chat.lastMessage]);

    const onItemClick = useCallback(() => {
      onChatSelected(chat);
    }, [chat, onChatSelected]);
    const onAvatarClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation(); // does not fire row's onClick if target is avatar
    }, []);

    return (
      <li
        className={cn(
          "list-none",
          "border-b",
          "border-gray-200",
          "px-4",
          "py-4.5",
          "flex",
          "items-center",
          "gap-3",
          "active:bg-gray-300"
        )}
        role="button"
        onClick={onItemClick}
      >
        <div onClick={onAvatarClick}>
          <PTUserAvatarImage
            ptUser={chat.ptUser}
            size="56"
            show418Badge={false}
          />
        </div>

        <div className={cn("flex-1", "min-w-0")}>
          <div className={cn("flex", "items-center", "mb-0.5")}>
            <span
              className={cn(
                "flex-1",
                "pr-1",
                "truncate",
                "overflow-hidden",
                "text-black/[0.86]",
                "text-lg",
                "leading-6",
                "font-medium"
              )}
            >
              {ptUserExtraInfo == null
                ? ptUserName
                : `${ptUserName} ${ptUserExtraInfo}`}
            </span>
            <ChatStationsBadge
              homeStation={chat.station}
              partnerStation={chat.partnerStation ?? null}
              ptUserStationTeam={chat.ptUser.stationTeam ?? null}
              showStationTeam={shouldShowPtStationTeam}
            />
            {chat.ptUser.role?.name === "CCO" ? (
              <CCOBadge className="ml-2" />
            ) : null}
          </div>
          <div
            className={cn(
              "flex",
              "justify-between",
              "gap-1",
              "text-black/60",
              "text-xs",
              "mb-0.5"
            )}
          >
            <div className={cn("flex", "items-center", "min-w-0")}>
              <UserIcon className={cn("w-3", "h-3", "mr-1", "fill-black/60")} />
              <span className={cn("flex-1", "truncate", "overflow-hidden")}>
                {participantsDisplayText}
              </span>
            </div>
            <span>{formattedLastMessageTimestamp}</span>
          </div>
          <LastMessageSection
            meUser={meUser}
            chat={chat}
            lastReadAt={chatStatus?.lastReadAt ?? null}
          />
        </div>
      </li>
    );
  }
);
