import {
  MeUser,
  UserStationProfile,
} from "oneclick-component/src/store/apis/enhancedApi";
import useMeUser from "./useMeUser";

function constructDefaultProfile(meUser: MeUser): UserStationProfile | null {
  if (meUser.stations.length > 0) {
    return {
      userId: meUser.id,
      station: meUser.stations[0],
    };
  }
  return null;
}

export function useSelectedStationProfile(): UserStationProfile | null {
  const meUser = useMeUser();
  if (meUser == null || meUser.adminRole !== "MANAGER") {
    return null;
  }
  const meUserSelectedStationProfile = meUser.selectedProfile;
  return meUserSelectedStationProfile ?? constructDefaultProfile(meUser);
}
