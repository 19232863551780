import { ReactElement, PropsWithChildren } from "react";
import cn from "classnames";
import { StationBadge } from "oneclick-component/src/components/Badge";
import { PtUser } from "oneclick-component/src/store/apis/enhancedApi";
import { PTUserAvatarImage } from "../PTUserAvatarImage";
import { useShouldShowPtStationTeam } from "../../hooks/useShouldShowPtStationTeam";

interface Props {
  user: PtUser;
  isSelected?: boolean;
  isActive?: boolean;
}

const PTUserDirectHireRow = (props: PropsWithChildren<Props>): ReactElement => {
  const { user, isSelected, isActive } = props;
  const shouldShowPtStationTeam = useShouldShowPtStationTeam();

  return (
    <div
      className={cn(
        "rounded-lg",
        "border-black/10",
        "text-black/80",
        "p-4",
        "w-full",
        "flex",
        "flex-row",
        "items-center",
        "flex-wrap",
        "relative",
        isActive && "!bg-neutral-900/5",
        isSelected && "bg-neutral-900/10"
      )}
    >
      <div
        className={cn(
          "flex-none",
          "w-full",
          "flex",
          "flex-row",
          "mr-3",
          "items-center"
        )}
      >
        <div className="mr-8">
          <PTUserAvatarImage ptUser={user} size="48" />
        </div>
        <div className="grow">
          <p
            className={cn(
              "font-normal",
              "flex",
              "items-center",
              "flex-wrap",
              "mb-2",
              "sm:mb-0"
            )}
          >
            {user.fullNameZhHk}
            {user.station != null ? (
              <StationBadge
                className={cn("ml-0", "sm:ml-2")}
                station={user.station}
                stationTeam={user.stationTeam ?? null}
                showStationTeam={shouldShowPtStationTeam}
              />
            ) : null}
          </p>
          <p className={cn("text-xs", "font-normal", "mb-1")}>
            ID <span>{user.eid}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PTUserDirectHireRow;
