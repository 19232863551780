import React, { useCallback, useState } from "react";
import { Row } from "@tanstack/react-table";
import { IndeterminateCheckboxInput } from "../inputs/IndeterminateCheckboxInput";

interface GroupHeaderCheckboxProps<TData> {
  row: Row<TData>;
}

export function GroupHeaderCheckbox<TData>(
  props: GroupHeaderCheckboxProps<TData>
): React.ReactElement {
  const { row } = props;

  const [checkboxKey, setCheckboxKey] = useState(0);

  const onGroupSelectChange = useCallback(
    (ev: React.FormEvent<HTMLInputElement>) => {
      ev.preventDefault();
      ev.stopPropagation();

      const { checked } = ev.currentTarget;
      const indeterminate = row.getIsSomeSelected();
      if (!checked || indeterminate) {
        row.subRows.map((r) => r.toggleSelected(false));
      } else {
        row.subRows.map((r) => r.toggleSelected(true));
      }

      // force update checkbox after setting state
      setCheckboxKey((prev) => prev + 1);
    },
    [row]
  );

  return (
    <IndeterminateCheckboxInput
      key={checkboxKey}
      checked={row.getIsAllSubRowsSelected()}
      indeterminate={row.getIsSomeSelected()}
      onChange={onGroupSelectChange}
      disabled={!row.getCanSelect()}
    />
  );
}
