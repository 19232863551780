import React from "react";
import { Modal } from "oneclick-component/src/components/Modal";
import cn from "classnames";
import { Announcement } from "oneclick-component/src/store/apis/enhancedApi";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import { Trans, useTranslation } from "react-i18next";
import { announcementTypeTitleI18nKeyMap } from "../constants";
import DownloadableAttachmentPreview from "../../../components/AttachmentPreview.tsx/DownloadableAttachmentPreview";
import { renderAnnouncementRecipient } from "../../../models/announcement";
import { Button } from "oneclick-component/src/components/Button";
interface Props {
  onClose: () => void;
  isOpen: boolean;
  className?: string;
  isLoading: boolean;
  announcement: Announcement | null;
}
const AnnouncementDetailDialog = (props: Props): React.ReactElement => {
  const { onClose, isOpen, className, isLoading, announcement } = props;
  const { t } = useTranslation();

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      hasXMarkButton={false}
      className={cn("w-full", "max-w-169", className)}
    >
      {isLoading || announcement == null ? (
        <div
          className={cn(
            "w-full",
            "flex",
            "justify-center",
            "items-center",
            "h-64"
          )}
        >
          <LoadingSpinner size="l" />
        </div>
      ) : (
        <>
          <p
            className={cn(
              "text-sm",
              "font-normal",
              "leading-5",
              "text-black/60"
            )}
          >
            <Trans i18nKey="announcement.detail.dialog.title.header" />
          </p>
          <p>
            <Trans
              i18nKey={announcementTypeTitleI18nKeyMap[announcement.type]}
            />
          </p>
          <p
            className={cn(
              "text-sm",
              "font-normal",
              "leading-5",
              "text-black/60",
              "mt-3",
              "sm:mt-5"
            )}
          >
            <Trans i18nKey="announcement.detail.dialog.recipient.header" />
          </p>
          <p className="break-words">
            {renderAnnouncementRecipient(announcement.recipient, false, t)}
          </p>
          <p
            className={cn(
              "text-sm",
              "font-normal",
              "leading-5",
              "text-black/60",
              "mt-3",
              "sm:mt-5"
            )}
          >
            <Trans i18nKey="announcement.detail.dialog.content.header" />
          </p>
          <p className="break-words">{announcement.content}</p>

          <p
            className={cn(
              "text-sm",
              "font-normal",
              "leading-5",
              "text-black/60",
              "mt-3",
              "sm:mt-5"
            )}
          >
            <Trans i18nKey="announcement.detail.dialog.attachment.header" />
          </p>
          {announcement.attachment == null ? (
            <Trans i18nKey="announcement.detail.dialog.attachment.noAttachment" />
          ) : (
            <DownloadableAttachmentPreview
              attachment={announcement.attachment}
            />
          )}
          <div
            className={cn(
              "mt-3",
              "sm:mt-5",
              "flex",
              "flex-row",
              "justify-center",
              "sm:justify-end",
              "items-center"
            )}
          >
            <Button
              className={cn("font-semibold", "ml-3", "flex-1", "sm:flex-none")}
              onClick={onClose}
            >
              <Trans i18nKey="common.close" />
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default AnnouncementDetailDialog;
