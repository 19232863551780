import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  PtUserWorkingStatusWith418Status,
  WeekOfYear,
  Weekly418Status,
  WorkingStatus,
} from "../enhancedApi";
import { getNextWeek } from "../../../utils/weekOfYear";
import { get418StatusFromPrev3WeekWorkingStatus } from "../../../utils/418";

type PtUserId = number;
type Year = number;
type WeekNumber = number;

interface SuccessWorkingStatusResult {
  workingStatusRecord: PtUserWorkingStatusWith418Status;
  queryResultState: "success";
}

interface LoadingWorkingStatusResult {
  queryResultState: "loading";
}

interface ErrorWorkingStatusResult {
  queryResultState: "error";
}
type WorkingStatusResult =
  | SuccessWorkingStatusResult
  | LoadingWorkingStatusResult
  | ErrorWorkingStatusResult;

type PtUserWorkingStatuses = Record<
  PtUserId,
  | Record<
      Year,
      Record<WeekNumber, WorkingStatusResult | undefined> | undefined
    >
  | undefined
>;
interface PtUserWorkingStatusesMapState {
  ptUserWorkingStatuses: PtUserWorkingStatuses;
}

interface SuccessUpdateByPtUserListPayloadItem {
  ptUserId: number;
  workingStatusRecords: PtUserWorkingStatusWith418Status[];
}
interface NonSuccessUpdateByPtUserListPayloadItem {
  ptUserId: number;
  weekOfYears: WeekOfYear[];
}

interface LoadingUpdateByPtUserListPayloadItem
  extends NonSuccessUpdateByPtUserListPayloadItem {}
interface ErrorUpdateByPtUserListPayloadItem
  extends NonSuccessUpdateByPtUserListPayloadItem {}
interface SuccessUpdateByPtUserListPayload {
  items: SuccessUpdateByPtUserListPayloadItem[];
  queryResultState: "success";
}
interface LoadingUpdateByPtUserListPayload {
  items: LoadingUpdateByPtUserListPayloadItem[];
  queryResultState: "loading";
}
interface ErrorUpdateByPtUserListPayload {
  items: ErrorUpdateByPtUserListPayloadItem[];
  queryResultState: "error";
}

type UpdateByPtUserListPayload =
  | SuccessUpdateByPtUserListPayload
  | LoadingUpdateByPtUserListPayload
  | ErrorUpdateByPtUserListPayload;

interface SuccessUpdateByPtUserPayload {
  ptUserId: number;
  workingStatusRecords: PtUserWorkingStatusWith418Status[];
  queryResultState: "success";
}

interface LoadingUpdateByPtUserPayload {
  ptUserId: number;
  weekOfYears: WeekOfYear[];
  queryResultState: "loading";
}

interface ErrorUpdateByPtUserPayload {
  ptUserId: number;
  weekOfYears: WeekOfYear[];
  queryResultState: "error";
}

type UpdateByPtUserPayload =
  | SuccessUpdateByPtUserPayload
  | LoadingUpdateByPtUserPayload
  | ErrorUpdateByPtUserPayload;

interface UpdateWorkingStatusPayload {
  ptUserId: number;
  workingStatusRecord: PtUserWorkingStatusWith418Status;
}

const initialState: PtUserWorkingStatusesMapState = {
  ptUserWorkingStatuses: {},
};

function upsertPtUserWorkingStatus(
  old: PtUserWorkingStatuses,
  ptUserId: number,
  year: number,
  weekNumber: number,
  newResult: WorkingStatusResult
): Record<
  Year,
  Record<WeekNumber, WorkingStatusResult | undefined> | undefined
> {
  return {
    ...old[ptUserId],
    [year]: {
      ...old[ptUserId]?.[year],
      [weekNumber]: newResult,
    },
  };
}
const ptUserWorkingStatusesMapSlice = createSlice({
  name: "ptUserWorkingStatusesMap",
  initialState,
  reducers: {
    updateByPtUserList(
      state,
      action: PayloadAction<UpdateByPtUserListPayload>
    ) {
      const { items, queryResultState } = action.payload;
      if (queryResultState === "loading") {
        items.forEach(({ ptUserId, weekOfYears }) => {
          weekOfYears.forEach(({ year, weekNumber }) => {
            // TODO: optimize as batch instead of seqeuntial updates
            state.ptUserWorkingStatuses[ptUserId] = upsertPtUserWorkingStatus(
              state.ptUserWorkingStatuses,
              ptUserId,
              year,
              weekNumber,
              { queryResultState }
            );
          });
        });
      }
      if (queryResultState === "error") {
        items.forEach(({ ptUserId, weekOfYears }) => {
          weekOfYears.forEach(({ year, weekNumber }) => {
            // TODO: optimize as batch instead of seqeuntial updates
            state.ptUserWorkingStatuses[ptUserId] = upsertPtUserWorkingStatus(
              state.ptUserWorkingStatuses,
              ptUserId,
              year,
              weekNumber,
              { queryResultState }
            );
          });
        });
      }
      if (queryResultState === "success") {
        action.payload.items.forEach(({ ptUserId, workingStatusRecords }) => {
          workingStatusRecords.forEach((r) => {
            // TODO: optimize as batch instead of seqeuntial updates
            state.ptUserWorkingStatuses[ptUserId] = upsertPtUserWorkingStatus(
              state.ptUserWorkingStatuses,
              ptUserId,
              r.year,
              r.weekNumber,
              { workingStatusRecord: r, queryResultState: "success" }
            );
          });
        });
      }
    },
    updateByPtUser(state, action: PayloadAction<UpdateByPtUserPayload>) {
      const { ptUserId } = action.payload;
      if (action.payload.queryResultState === "loading") {
        const { weekOfYears } = action.payload;
        weekOfYears.forEach(({ year, weekNumber }) => {
          // TODO: optimize as batch instead of seqeuntial updates
          state.ptUserWorkingStatuses[ptUserId] = upsertPtUserWorkingStatus(
            state.ptUserWorkingStatuses,
            ptUserId,
            year,
            weekNumber,
            { queryResultState: "loading" }
          );
        });
      } else if (action.payload.queryResultState === "error") {
        const { weekOfYears } = action.payload;
        weekOfYears.forEach(({ year, weekNumber }) => {
          // TODO: optimize as batch instead of seqeuntial updates
          state.ptUserWorkingStatuses[ptUserId] = upsertPtUserWorkingStatus(
            state.ptUserWorkingStatuses,
            ptUserId,
            year,
            weekNumber,
            { queryResultState: "error" }
          );
        });
      } else {
        const { workingStatusRecords } = action.payload;
        workingStatusRecords.forEach((r) => {
          // TODO: optimize as batch instead of seqeuntial updates
          state.ptUserWorkingStatuses[ptUserId] = upsertPtUserWorkingStatus(
            state.ptUserWorkingStatuses,
            ptUserId,
            r.year,
            r.weekNumber,
            { workingStatusRecord: r, queryResultState: "success" }
          );
        });
      }
    },
    updateWorkingStatus(
      state,
      action: PayloadAction<UpdateWorkingStatusPayload>
    ) {
      const { ptUserId, workingStatusRecord } = action.payload;
      const { year, weekNumber, workingStatus } = workingStatusRecord;

      const prev2Next3Weeks: WeekOfYear[] = [-2, -1, 0, 1, 2, 3].map((i) =>
        getNextWeek({ year, weekNumber }, i)
      );
      const prev2Next3WeeksWorkingStatus: Array<WorkingStatus | null> =
        prev2Next3Weeks.map(({ year: _year, weekNumber: _weekNumber }) => {
          const record =
            state.ptUserWorkingStatuses[ptUserId]?.[_year]?.[_weekNumber];
          if (_year === year && weekNumber === _weekNumber)
            return workingStatus;
          return record?.queryResultState === "success"
            ? record.workingStatusRecord.workingStatus
            : null;
        });

      const next3Week418Status: Weekly418Status[] = [0, 1, 2].map((i) => {
        const prev3WeekWorkingStatus = prev2Next3WeeksWorkingStatus.slice(
          i,
          i + 3
        ) as [WorkingStatus | null, WorkingStatus | null, WorkingStatus | null];
        return get418StatusFromPrev3WeekWorkingStatus(prev3WeekWorkingStatus);
      });
      const next3WeekRecords: PtUserWorkingStatusWith418Status[] =
        prev2Next3Weeks
          .slice(3, 6)
          .map(({ year: _year, weekNumber: _weekNumber }, i) => {
            const existingRecord =
              state.ptUserWorkingStatuses[ptUserId]?.[_year]?.[_weekNumber];
            const newWeekly418Status = next3Week418Status[i];
            if (existingRecord?.queryResultState === "success") {
              return {
                year: _year,
                weekNumber: _weekNumber,
                workingStatus: existingRecord.workingStatusRecord.workingStatus,
                weekly418Status: newWeekly418Status,
              };
            }
            return {
              year: _year,
              weekNumber: _weekNumber,
              workingStatus: null,
              weekly418Status: newWeekly418Status,
            };
          });
      const affectedRecords = [workingStatusRecord, ...next3WeekRecords];
      affectedRecords.forEach((r) => {
        // TODO: optimize as batch instead of seqeuntial updates
        state.ptUserWorkingStatuses[ptUserId] = upsertPtUserWorkingStatus(
          state.ptUserWorkingStatuses,
          ptUserId,
          r.year,
          r.weekNumber,
          { workingStatusRecord: r, queryResultState: "success" }
        );
      });
    },
  },
});

export const { updateByPtUserList, updateByPtUser, updateWorkingStatus } =
  ptUserWorkingStatusesMapSlice.actions;
export default ptUserWorkingStatusesMapSlice.reducer;
