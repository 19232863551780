import { MeUser } from "oneclick-component/src/store/apis/enhancedApi";
import { AuthState, createAuthSlice } from "oneclick-component/src/store/auth";
import AppRoutes from "../routes/AppRoutes";

const initialState: AuthState<MeUser> = {
  isInitialized: false,
  meUser: null,
  token: null,
  redirectTo: AppRoutes.ShiftListScreen.render(),
};

const authSlice = createAuthSlice(initialState);

export const {
  loginSucceed,
  logoutSucceed,
  initialized,
  unauthenticated,
  refetchTokenSucceed,
  meUserUpdated,
} = authSlice.actions;
export default authSlice.reducer;
