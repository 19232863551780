import { Station } from "oneclick-component/src/store/apis/enhancedApi";
import useMeUser from "./useMeUser";

const useMeUserStations = (): Station[] | "all" => {
  const meUser = useMeUser();
  if (meUser == null) {
    return [];
  }
  switch (meUser.adminRole) {
    case "MANAGER": {
      return meUser.selectedProfile != null
        ? meUser.stations.filter(
            (s) => s.id === meUser.selectedProfile!.station.id
          )
        : [];
    }
    case "LANDLORD": {
      return meUser.stations;
    }

    case "ADMIN": {
      return "all";
    }
  }
};

export default useMeUserStations;
