import {
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useEffect,
} from "react";
import cn from "classnames";
import { Trans, useTranslation } from "react-i18next";
import { Menu } from "@headlessui/react";
import { Navigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { LogoIcon } from "oneclick-component/src/icon";
import { Button } from "oneclick-component/src/components/Button";
import PopMenu, {
  PopMenuItem,
} from "oneclick-component/src/components/PopMenu";
import { useGetMeHandlerMeGetQuery } from "oneclick-component/src/store/apis/enhancedApi";

import AppRoutes from "../../routes/AppRoutes";
import { resetFilter } from "../../store/filterState";
import UserAvatarImage from "../../components/UserAvatarImage";
import { AuthContext } from "../../providers/AuthProvider";
import { useShowError } from "../../hooks/useShowError";
import { NotAuthorizedImage } from "../../image";

const UnauthorizedScreen = (): ReactElement => {
  const { t } = useTranslation();
  const { showError } = useShowError();
  const { logout, activeADAccount } = useContext(AuthContext);
  const dispatch = useDispatch();

  console.info("current active ad account: ", activeADAccount);

  const { data: meUser } = useGetMeHandlerMeGetQuery();

  useEffect(() => {
    dispatch(resetFilter());
  }, [dispatch]);

  const onLogoutClick = useCallback(() => {
    logout().catch((err: unknown) => {
      showError(err, "auth.action.logout.error.title");
    });
  }, [logout, showError]);

  const getControlElementClasses = useCallback((option: { open: boolean }) => {
    return cn(
      "flex",
      "max-w-xs",
      "items-center",
      "rounded-full",
      "bg-white",
      "text-sm",
      option.open && [
        "outline-none",
        "ring-2",
        "ring-primary-500",
        "ring-offset-2",
      ]
    );
  }, []);

  const notAuthorizedUserAvatar = useMemo(() => {
    if (activeADAccount != null) {
      return (
        <UserAvatarImage
          user={{
            id: -1,
            adminRole: "",
            name: activeADAccount.name ?? "",
            email: activeADAccount.username,
            nameZhHk: activeADAccount.name ?? "",
            profilePic: null,
          }}
          size="40"
        />
      );
    }
    return null;
  }, [activeADAccount]);

  if (meUser?.id != null) {
    return <Navigate replace={true} to={AppRoutes.ShiftListScreen.render()} />;
  }

  if (activeADAccount == null) {
    return <Navigate replace={true} to={AppRoutes.LogInScreen.render()} />;
  }

  return (
    <main className={cn("bg-white", "h-full")}>
      <header
        className={cn(
          "sticky",
          "z-20",
          "top-0",
          "bg-white",
          "border-b",
          "border-gray-300"
        )}
      >
        <nav
          className={cn(
            "py-2.5",
            "px-4",
            "mx-auto",
            "flex",
            "items-center",
            "justify-between",
            "sm:py-3",
            "sm:px-8"
          )}
          aria-label="Global"
        >
          <div className="flex">
            <Link to={AppRoutes.LogInScreen.render()}>
              <LogoIcon />
            </Link>
          </div>
          <div className={cn("flex", "flex-1", "justify-end")}>
            <PopMenu
              menuClass="relative ml-3"
              controlElementClass={getControlElementClasses}
              controlElement={<>{notAuthorizedUserAvatar}</>}
              menuBodyClass="right-0 z-40"
            >
              <div className="py-1">
                <Menu.Item>
                  <div
                    className={cn("px-4", "py-2", "text-sm", "text-gray-900")}
                  >
                    <Trans i18nKey="menu.loginAs" />
                    <div>{activeADAccount.username}</div>
                  </div>
                </Menu.Item>
              </div>
              <div className="py-1">
                <PopMenuItem
                  type="button"
                  onClick={onLogoutClick}
                  text={t("menu.logout")}
                />
              </div>
            </PopMenu>
          </div>
        </nav>
      </header>
      <div
        className={cn(
          "w-full",
          "flex",
          "flex-col",
          "justify-center",
          "h-full",
          "absolute",
          "top-0"
        )}
      >
        <NotAuthorizedImage className="mx-auto" />
        <div
          className={cn(
            "mt-9",
            "gap-3",
            "flex",
            "flex-col",
            "items-center",
            "text-center"
          )}
        >
          <p className={cn("text-lg", "font-normal")}>
            <Trans i18nKey="auth.anonymous" />
          </p>
          <p className={cn("font-medium", "text-black/60")}>
            <Trans i18nKey="auth.notPermitted" />
          </p>
          <Button className="text-center" onClick={onLogoutClick}>
            <span>
              <Trans i18nKey="auth.action.logout" />
            </span>
          </Button>
        </div>
      </div>
    </main>
  );
};

export default UnauthorizedScreen;
