import { Modal } from "oneclick-component/src/components/Modal";
import { ReactElement } from "react";
import cn from "classnames";
import { Button } from "oneclick-component/src/components/Button";
import { Trans } from "react-i18next";

interface ReactivateDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirmReactivate: () => void;
  ptUserName: string;
  isLoading: boolean;
}
const ReactivateDialog = (props: ReactivateDialogProps): ReactElement => {
  const { isOpen, onClose, ptUserName, onConfirmReactivate, isLoading } = props;
  return (
    <Modal onClose={onClose} isOpen={isOpen} hasXMarkButton={false}>
      <div className={cn("sm:flex", "sm:items-start", "w-full")}>
        <div
          className={cn(
            "text-center",
            "sm:mt-0",
            "sm:text-left",
            "w-full",
            "space-y-5"
          )}
        >
          <p className={cn("text-black", "text-lg", "font-medium")}>
            <Trans i18nKey="partTime.list.tab.availability.reactivateDialog.title" />
          </p>
          <p className={cn("text-black/60", "text-sm")}>
            <Trans
              i18nKey="partTime.list.tab.availability.reactivateDialog.content"
              tOptions={{ ptUserName }}
            />
          </p>
        </div>
      </div>
      <div
        className={cn(
          "mt-5",
          "sm:mt-4",
          "flex",
          "flex-row",
          "justify-center",
          "sm:justify-end",
          "items-center"
        )}
      >
        <Button
          className={cn("font-semibold", "flex-1", "sm:flex-none")}
          onClick={onClose}
          theme="white"
          isLoading={isLoading}
        >
          <Trans i18nKey="partTime.list.tab.availability.reactivateDialog.cancel" />
        </Button>
        <Button
          className={cn("font-semibold", "ml-3", "flex-1", "sm:flex-none")}
          theme="primary"
          onClick={onConfirmReactivate}
          isLoading={isLoading}
        >
          <Trans i18nKey="partTime.list.tab.availability.reactivateDialog.reactivate" />
        </Button>
      </div>
    </Modal>
  );
};
export default ReactivateDialog;
