import React, {
  CSSProperties,
  ReactElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { PhotoIcon } from "@heroicons/react/24/outline";
import cn from "classnames";

import { Message } from "oneclick-component/src/store/apis/enhancedApi";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import { ImageViewer } from "./ImageViewer";

interface ImageViewStateLoading {
  state: "loading";
}
interface ImageViewStateLoaded {
  state: "loaded";
  height: number;
  width: number;
}
interface ImageViewStateError {
  state: "error";
}
type ImageViewState =
  | ImageViewStateLoading
  | ImageViewStateLoaded
  | ImageViewStateError;

const MIN_ASPECT_RATIO = 0.75;

interface Props {
  message: Message;
}

export const MessageBubbleImageContent = React.memo(
  (props: Props): ReactElement => {
    const { message } = props;

    const [viewState, setViewState] = useState<ImageViewState>({
      state: "loading",
    });

    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const imageSource = useMemo(() => {
      const attachment = message.attachments[0];
      return attachment.asset.url ?? "#";
    }, [message.attachments]);

    const onImageLoadStart = useCallback(() => {
      setViewState({ state: "loading" });
    }, []);

    useEffect(() => {
      onImageLoadStart();
    }, [imageSource, onImageLoadStart]);

    const onImageLoad = useCallback(
      (ev: React.SyntheticEvent<HTMLImageElement>) => {
        setViewState({
          state: "loaded",
          width: ev.currentTarget.width,
          height: ev.currentTarget.height,
        });
      },
      []
    );

    const imageRef = useRef<HTMLImageElement | null>(null);
    const [imageStyle, setImageStyle] = useState<CSSProperties | undefined>();
    useLayoutEffect(() => {
      if (viewState.state !== "loaded" || imageRef.current == null) {
        setImageStyle(undefined);
        return;
      }

      // Using image ref to ensure we get the image width with style updated due to view state change
      const maxHeight = imageRef.current.width / MIN_ASPECT_RATIO;
      setImageStyle({ maxHeight });
    }, [viewState]);

    const onImageError = useCallback(() => {
      setViewState({ state: "error" });
    }, []);

    const openImageViewer = useCallback(() => {
      setIsViewerOpen(true);
    }, []);

    const closeImageViewer = useCallback(() => {
      setIsViewerOpen(false);
    }, []);

    return (
      <div>
        <img
          ref={imageRef}
          className={cn("rounded-lg", "max-w-full", "object-cover", "mx-auto", {
            block: viewState.state === "loaded",
            hidden: viewState.state !== "loaded",
          })}
          style={imageStyle}
          src={imageSource}
          onLoad={onImageLoad}
          onError={onImageError}
          onClick={openImageViewer}
        />
        {viewState.state === "loading" ? (
          <div
            className={cn(
              "rounded-lg",
              "w-50",
              "h-50",
              "flex",
              "items-center",
              "justify-center",
              "bg-gray-300"
            )}
          >
            <LoadingSpinner size="m" />
          </div>
        ) : null}
        {viewState.state === "error" ? (
          <div
            className={cn(
              "rounded-lg",
              "w-50",
              "h-50",
              "flex",
              "items-center",
              "justify-center",
              "bg-gray-300"
            )}
          >
            <PhotoIcon className={cn("text-black/80", "p-20")} />
          </div>
        ) : null}
        {message.content ? (
          <span
            className={cn(
              "block",
              "whitespace-pre-wrap",
              "overflow-wrap-anywhere",
              "text-sm",
              "text-left",
              "p-3"
            )}
          >
            {message.content}
          </span>
        ) : null}
        <ImageViewer
          imageSource={imageSource}
          isOpen={isViewerOpen}
          onClose={closeImageViewer}
        />
      </div>
    );
  }
);
