import { ReactElement, useCallback, useState } from "react";
import cn from "classnames";
import { Trans, useTranslation } from "react-i18next";
import { useExportUsageHandlerWhatsappUsageExportPostMutation as useExportUsage } from "oneclick-component/src/store/apis/enhancedApi";
import { Button } from "oneclick-component/src/components/Button";
import { Select } from "oneclick-component/src/components/inputs";
import useShowMessage from "oneclick-component/src/hooks/useShowMessage";

const monthOptions = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const thisYear = new Date().getFullYear();

const WhatsappUsageScreen = (): ReactElement => {
  const showMessage = useShowMessage();
  const { t } = useTranslation();
  const [exportUsage] = useExportUsage();

  const [targetYear, setTargetYear] = useState(new Date().getFullYear());
  const [targetMonth, setTargetMonth] = useState(new Date().getMonth() + 1);

  const handleGenerateReport = useCallback(() => {
    exportUsage({
      generateUsageRequest: {
        year: targetYear,
        month: targetMonth,
      },
    })
      .unwrap()
      .then(() => {
        showMessage({
          type: "info",
          title: t("whatsappUsage.export.started"),
        });
      })
      .catch(() => {
        showMessage({
          type: "fail",
          title: t("whatsappUsage.export.failed"),
        });
      });
  }, [exportUsage, showMessage, t, targetMonth, targetYear]);

  const handleChangeMonth = useCallback((value: number) => {
    setTargetMonth(value);
  }, []);
  const handleChangeYear = useCallback((value: number) => {
    setTargetYear(value);
  }, []);

  return (
    <main
      className={cn(
        "rounded-lg",
        "bg-white",
        "sm:shadow",
        "sm:mx-auto",
        "px-4",
        "pb-5",
        "sm:p-6"
      )}
    >
      <div
        className={cn("text-2xl", "flex", "flex-row", "items-center", "mb-4")}
      >
        <span className={cn("font-bold", "mr-6")}>
          <Trans i18nKey="whatsappUsage.export.title" />
        </span>
      </div>
      <p>
        <Trans i18nKey="whatsappUsage.export.chooseMonth" />
      </p>
      <div className={cn("flex", "flex-row", "mt-2")}>
        <Select
          className={cn("mr-2", "w-24")}
          options={[0, 1, 2, 3, 4].map((year) => ({
            name: `${thisYear - year}`,
            value: thisYear - year,
          }))}
          value={targetYear}
          onChange={handleChangeYear}
        />
        <Select
          className={cn("mr-6", "w-24")}
          options={monthOptions.map((month, index) => ({
            name: month,
            value: index + 1,
          }))}
          value={targetMonth}
          onChange={handleChangeMonth}
        />
        <Button onClick={handleGenerateReport}>
          <Trans i18nKey="whatsappUsage.export.action" />
        </Button>
      </div>
    </main>
  );
};

export default WhatsappUsageScreen;
