import { ReactElement, useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import cn from "classnames";
import { Modal } from "oneclick-component/src/components/Modal";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import {
  Station,
  useLeaveStationHandlerUsersLeaveStationPostMutation as useLeaveStation,
} from "oneclick-component/src/store/apis/enhancedApi";
import { Button } from "oneclick-component/src/components/Button";
import useShowMessage from "oneclick-component/src/hooks/useShowMessage";
import { isFetchQueryBaseError } from "oneclick-component/src/models/error";
import { useShowError } from "../../hooks/useShowError";

interface SwitchStationProfileDialogProps {
  isOpen: boolean;
  onClose: () => void;
  station: Station | null;
}

export const RemoveStationProfileDialog = (
  props: SwitchStationProfileDialogProps
): ReactElement => {
  const { station, isOpen, onClose } = props;
  const { t } = useTranslation();
  const showMessage = useShowMessage();
  const [leaveStation, { isLoading }] = useLeaveStation();
  const { showError } = useShowError();

  const onConfirmClick = useCallback(() => {
    if (station == null) {
      return;
    }
    leaveStation({
      leaveStationRequest: {
        stationId: station.id,
      },
    })
      .unwrap()
      .then(() => {
        showMessage({
          title: t("removeStationDialog.success", { code: station.shortCode }),
          type: "success",
          showDismiss: false,
        });
        onClose();

        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch((e) => {
        if (
          isFetchQueryBaseError(e) &&
          (e.data as any).detail.description.includes(
            "has 1 station manager left"
          )
        ) {
          showMessage({
            title: t("removeStationDialog.error.onlyOneManagerLeft", {
              code: station.shortCode,
            }),
            type: "fail",
            showDismiss: true,
          });
        } else {
          showError(e, t("shift.cancel.toast.fail.title"));
        }
      });
  }, [leaveStation, showError, station, onClose, showMessage, t]);

  return (
    <Modal onClose={onClose} isOpen={isOpen} hasXMarkButton={false}>
      <h3
        className={cn(
          "mb-5",
          "text-black",
          "text-lg",
          "font-medium",
          "flex",
          "flex-row",
          "items-center",
          "leading-7"
        )}
      >
        <ExclamationTriangleIcon
          className={cn("w-6", "h-6", "mr-2", "text-black")}
        />
        <Trans
          i18nKey="removeStationDialog.title"
          values={{
            code: station?.shortCode,
          }}
        />
      </h3>
      <div className={cn("w-full", "max-w-md", "mb-23")}>
        <p>
          <Trans
            i18nKey="removeStationDialog.description"
            values={{
              code: station?.shortCode,
            }}
          />
        </p>
      </div>
      <div
        className={cn(
          "mt-5",
          "flex",
          "flex-row",
          "gap-3",
          "justify-center",
          "sm:justify-end",
          "items-center"
        )}
      >
        <Button
          className={cn("font-medium", "flex-1", "sm:flex-none")}
          theme="primary"
          onClick={onConfirmClick}
          disabled={isLoading}
        >
          <Trans i18nKey="removeStationDialog.action" />
        </Button>
      </div>
    </Modal>
  );
};
