import { ReactElement, useMemo, useState } from "react";
import cn from "classnames";
import {
  useFloating,
  useHover,
  useInteractions,
  flip,
  shift,
  offset,
  FloatingPortal,
  useDismiss,
} from "@floating-ui/react";
import AvatarImage from "oneclick-component/src/components/AvatarImage";
import {
  PtUser,
  Weekly418Status,
} from "oneclick-component/src/store/apis/enhancedApi";
import { getInitialsFromPtUserFirstLastName } from "oneclick-component/src/utils/ptUser";
import PTUser418Badge from "../PTUserBadge/PTUser418Badge";
import Status418ToolTip from "./Status418ToolTip";

interface Props {
  ptUser: PtUser;
  className?: string;
  size?: string;
  show418Badge?: boolean;
  override418Status?: Weekly418Status | null;
  shouldShow418ToolTip?: boolean;
}

export const PTUserAvatarImage = (props: Props): ReactElement => {
  const {
    ptUser,
    className,
    size = "48",
    show418Badge = true,
    override418Status,
    shouldShow418ToolTip = true,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    placement: "right-start",
    onOpenChange: setIsOpen,
    middleware: [flip(), shift(), offset(32)],
  });
  const hover = useHover(context);
  const dismiss = useDismiss(context, { outsidePress: true });
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    dismiss,
  ]);

  const initials = useMemo<string>(
    () =>
      getInitialsFromPtUserFirstLastName(ptUser.firstNameEn, ptUser.lastNameEn),
    [ptUser.firstNameEn, ptUser.lastNameEn]
  );

  return (
    <div
      className={cn("relative", className)}
      ref={refs.setReference}
      {...getReferenceProps()}
    >
      <AvatarImage
        profilePic={ptUser.profilePic ?? null}
        username={ptUser.fullNameZhHk}
        initials={initials}
        size={size}
        maxInitials={2}
      />
      {ptUser.isDeleted || ptUser.role?.name === "CCO" ? null : (
        <>
          {show418Badge &&
          (override418Status != null || ptUser.currentWeek418Status != null) ? (
            <PTUser418Badge
              status={override418Status ?? ptUser.currentWeek418Status!.status}
              className={cn("absolute", "top-0", "-right-6", "px-2")}
            />
          ) : null}
          {shouldShow418ToolTip && isOpen ? (
            <FloatingPortal>
              <div
                className="z-50"
                ref={refs.setFloating}
                style={floatingStyles}
                {...getFloatingProps()}
              >
                <Status418ToolTip ptUser={ptUser} />
              </div>
            </FloatingPortal>
          ) : null}
        </>
      )}
    </div>
  );
};
