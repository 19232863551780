import React, { useMemo } from "react";
import { Header, Row } from "@tanstack/react-table";
import { OutgoingTabRecord, mapOutgoingTabRecord } from "./model";
import Table from "oneclick-component/src/components/Table";
import { ShiftRequestListGroupHeader } from "../ShiftRequestListGroupHeader";
import { ViewState, ViewStateStatus } from "./viewState";
import { useShowError } from "../../../hooks/useShowError";
import { ControlledTableState } from "oneclick-component/src/hooks/useOnClickTableColumnHeader";
import useOutgoingTabTable from "./useOutgoingTabTable";
import GenericErrorScreen from "oneclick-component/src/components/GenericErrorScreen";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import cn from "classnames";
import { ListEmptyView } from "../../../components/EmptyView";
import { useTranslation } from "react-i18next";

const RENDER_ROW_MAX_DEPTH = 4;

const renderRow = (
  row: Row<OutgoingTabRecord>,
  headerColSpan: number,
  depth: number = 1
): React.ReactElement | null => {
  // Guard infinite recursion
  if (depth > RENDER_ROW_MAX_DEPTH) {
    console.warn(
      `Exceeded maximum render depth ${RENDER_ROW_MAX_DEPTH} for ShiftRequestList IncomingPartTimeTab grouped items`
    );
    return null;
  }

  // Leaf rows
  if (row.subRows.length === 0) {
    return <Table.Row key={row.id} row={row} />;
  }

  // Group header
  return (
    // grouping header row.id  will be sth like "partTimeType:CUCA>shiftDate:2023-09-30"
    <React.Fragment key={row.id}>
      <ShiftRequestListGroupHeader row={row} headerColSpan={headerColSpan} />
      {row.subRows.map((r) => renderRow(r, headerColSpan, depth + 1))}
    </React.Fragment>
  );
};

interface Props {
  viewState: ViewState;
  tableState: ControlledTableState;
  onClickColumnHeader: (header: Header<OutgoingTabRecord, unknown>) => void;
  isHidePtStationColumn: boolean;
}

const OutgoingTabTable = React.memo(function OutgoingTabTable(
  props: Props
): React.ReactElement {
  const { viewState, tableState, onClickColumnHeader, isHidePtStationColumn } =
    props;
  const { showError } = useShowError();
  const { t } = useTranslation();
  const tableData = useMemo<OutgoingTabRecord[]>(() => {
    if (viewState.status !== ViewStateStatus.Loaded) {
      return [];
    }

    return viewState.shiftRequestList.map(mapOutgoingTabRecord);
  }, [viewState]);

  const table = useOutgoingTabTable(tableData, isHidePtStationColumn, {
    sorting: tableState.sorting,
    grouping: tableState.grouping,
  });

  if (viewState.status === ViewStateStatus.Error) {
    showError(viewState.error);
    return (
      <Table table={table}>
        <tr className={"h-171"}>
          <td colSpan={table.getVisibleFlatColumns().length}>
            <GenericErrorScreen />
          </td>
        </tr>
      </Table>
    );
  }
  if (viewState.status === ViewStateStatus.Loading) {
    return (
      <Table table={table}>
        <tr className={"h-171"}>
          <td colSpan={table.getVisibleFlatColumns().length}>
            <span className={cn("flex", "justify-center", "items-center")}>
              <LoadingSpinner />
            </span>
          </td>
        </tr>
      </Table>
    );
  }

  if (viewState.status === ViewStateStatus.Empty) {
    return (
      <Table table={table}>
        <tr className={"h-171"}>
          <td colSpan={table.getVisibleFlatColumns().length}>
            <ListEmptyView
              description={t("shiftRequestList.table.emptyState")}
            />
          </td>
        </tr>
      </Table>
    );
  }

  // viewState.status === ViewStateStatus.Loaded
  return (
    <Table table={table} onClickColumnHeader={onClickColumnHeader}>
      {table
        .getRowModel()
        .rows.map((row) =>
          renderRow(row, table.getVisibleFlatColumns().length)
        )}
    </Table>
  );
});

export default OutgoingTabTable;
