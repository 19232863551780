import React, { useCallback, useMemo, useState } from "react";
import { HeaderContext } from "@tanstack/react-table";
import { IndeterminateCheckboxInput } from "../inputs/IndeterminateCheckboxInput";

export function HeaderCheckbox<TData, TValue>(
  props: HeaderContext<TData, TValue>
): React.ReactElement {
  const { table } = props;

  const [checkboxKey, setCheckboxKey] = useState(0);

  const onChange = useCallback(
    (ev: React.FormEvent<HTMLInputElement>) => {
      ev.preventDefault();
      ev.stopPropagation();

      const { checked } = ev.currentTarget;
      const indeterminate = table.getIsSomePageRowsSelected();
      if (!checked || indeterminate) {
        table.toggleAllPageRowsSelected(false);
      } else {
        table.toggleAllPageRowsSelected(true);
      }

      // force update checkbox after setting state
      setCheckboxKey((prev) => prev + 1);
    },
    [table]
  );

  const rowSelection = table.getState().rowSelection;

  // NOTE: Only check leaf row, as there is some hidden rows for grouping purpose
  const { checked, indeterminate } = useMemo(
    () => {
      const pageLeafRows = table
        .getPaginationRowModel()
        .flatRows.filter((r) => r.getCanSelect())
        .filter((r) => !r.getIsGrouped());
      return {
        checked:
          pageLeafRows.length > 0 &&
          pageLeafRows.every((r) => r.getIsSelected()),
        indeterminate: pageLeafRows.some((r) => r.getIsSelected()),
      };
    },
    // Need to update on rowSelection state change
    [rowSelection, table] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <IndeterminateCheckboxInput
      key={checkboxKey}
      checked={checked}
      indeterminate={indeterminate}
      onChange={onChange}
    />
  );
}
