import { ReactElement, useCallback, useMemo, useState } from "react";
import cn from "classnames";
import { SearchInput } from "oneclick-component/src/components/inputs";
import {
  BriefStation,
  GetPtUsersHandlerPartTimeUsersListPostApiArg as GetPtUsersArg,
  useGetPtUsersHandlerPartTimeUsersListPostQuery,
} from "oneclick-component/src/store/apis/enhancedApi";
import LoadingScreen from "oneclick-component/src/components/LoadingScreen";
import { Button } from "oneclick-component/src/components/Button";
import { SearchIcon } from "oneclick-component/src/icon";
import { DEFAULT_PAGE_SIZE } from "../../../constants/pagination";
import MobileSearchView from "../../../components/MobileSearchView";
import { StationFilter } from "../../../components/StationFilter";
import { useSelectedStationProfile } from "../../../hooks/useSelectedStationProfile";
import { useIsManager } from "../../../hooks/role";
import {
  getStationFilterFromFilterAndProfile,
  PtUserListFilter,
} from "../type";
import PartTimeAvailabilityTabListView from "./ListView";

export const PartTimeListAvailabilityTab = (): ReactElement => {
  const isManager = useIsManager();
  const selectedStationProfile = useSelectedStationProfile();

  const [ptUserListFilter, setPtUserListFilter] = useState<PtUserListFilter>({
    searchString: undefined,
    pageIndex: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    sort: undefined,
    stationFilter: undefined,
  });

  const getPtUsersArgs = useMemo<GetPtUsersArg>(() => {
    return {
      searchString: ptUserListFilter.searchString,
      pageIndex: ptUserListFilter.pageIndex,
      pageSize: ptUserListFilter.pageSize,
      sort: ptUserListFilter.sort,
      getPtUsersRequest: {
        stationIds: getStationFilterFromFilterAndProfile(
          selectedStationProfile,
          ptUserListFilter
        ),
        showDeleted: true,
      },
    };
  }, [ptUserListFilter, selectedStationProfile]);
  const { data, isLoading, isFetching } =
    useGetPtUsersHandlerPartTimeUsersListPostQuery(getPtUsersArgs, {
      refetchOnMountOrArgChange: true,
    });

  const onChangeSearchInput = useCallback((v: string) => {
    const trimmedSearchString: string = v.trim();
    const searchString: string | undefined =
      trimmedSearchString === "" ? undefined : trimmedSearchString;
    setPtUserListFilter((prev) => ({ ...prev, searchString, pageIndex: 0 }));
  }, []);
  const onClickPage = useCallback((pageIndex: number) => {
    setPtUserListFilter((prev) => ({ ...prev, pageIndex }));
  }, []);
  const [isMobileSearchView, setIsMobileSearchView] = useState<boolean>(false);

  const onEnterMobileSearchView = useCallback(
    () => setIsMobileSearchView(true),
    []
  );
  const onExitMobileSearchView = useCallback(
    () => setIsMobileSearchView(false),
    []
  );

  const { results: ptUsersResponse, totalCount } = data ?? {};
  const ptUsers = ptUsersResponse ?? [];
  const totalPages = useMemo(() => {
    if (totalCount == null) {
      return 0;
    }
    return Math.ceil(totalCount / ptUserListFilter.pageSize);
  }, [totalCount, ptUserListFilter.pageSize]);

  const onStationFilterChange = useCallback((station: BriefStation | null) => {
    setPtUserListFilter((prev) => ({
      ...prev,
      pageIndex: 0,
      stationFilter: station?.id,
    }));
  }, []);

  const showStationFilter = !isManager;

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isMobileSearchView) {
    return (
      <MobileSearchView
        onChangeSearchInput={onChangeSearchInput}
        showStationFilter={showStationFilter}
        stationFilter={ptUserListFilter.stationFilter}
        onStationFilterChange={onStationFilterChange}
        onExit={onExitMobileSearchView}
      >
        <PartTimeAvailabilityTabListView
          isLoading={isFetching}
          ptUsers={ptUsers}
          totalPages={totalPages}
          pageIndex={ptUserListFilter.pageIndex}
          onClickPage={onClickPage}
        />
      </MobileSearchView>
    );
  }
  return (
    <div className={cn("gap-y-3", "sm:gap-y-5", "h-full", "flex", "flex-col")}>
      <Button
        className={cn("block", "sm:hidden", "!px-3", "!py-2.75", "w-full")}
        theme="white"
        onClick={onEnterMobileSearchView}
      >
        <SearchIcon className={cn("w-5", "h-5", "fill-gray-400")} />
      </Button>
      <div className={cn("hidden", "sm:flex", "sm:gap-3")}>
        <SearchInput className="flex-1" onChange={onChangeSearchInput} />
        {showStationFilter ? (
          <StationFilter
            selectedStationId={ptUserListFilter.stationFilter ?? null}
            onStationSelected={onStationFilterChange}
          />
        ) : null}
      </div>
      <PartTimeAvailabilityTabListView
        isLoading={isFetching}
        ptUsers={ptUsers}
        totalPages={totalPages}
        pageIndex={ptUserListFilter.pageIndex}
        onClickPage={onClickPage}
      />
    </div>
  );
};
