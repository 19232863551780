import { DateTime } from "luxon";
import YearMonth from "oneclick-component/src/models/yearMonth";
import { dateTimeNow } from "oneclick-component/src/utils/datetime";

export type ShiftDateRangeFilterType =
  | "today"
  | "future"
  | "past"
  | "month"
  | "all";

interface BaseShiftDateRangeFilter {
  type: ShiftDateRangeFilterType;
}
interface TodayShiftDateRangeFilter extends BaseShiftDateRangeFilter {
  type: "today";
}
interface FutureShiftDateRangeFilter extends BaseShiftDateRangeFilter {
  type: "future";
  numberOfDays: number | null;
}
interface PastShiftDateRangeFilter extends BaseShiftDateRangeFilter {
  type: "past";
  numberOfDays: number | null;
}
interface MonthShiftDateRangeFilter extends BaseShiftDateRangeFilter {
  type: "month";
  yearMonth: YearMonth | null;
}
interface AllShiftDateRangeFilter extends BaseShiftDateRangeFilter {
  type: "all";
}

export type ShiftDateRangeFilter =
  | TodayShiftDateRangeFilter
  | FutureShiftDateRangeFilter
  | PastShiftDateRangeFilter
  | MonthShiftDateRangeFilter
  | AllShiftDateRangeFilter;

export interface ShiftDateRange {
  before: DateTime | null;
  after: DateTime | null;
}

export function getDateRangeFromFilter(
  filter: ShiftDateRangeFilter
): ShiftDateRange | null {
  switch (filter.type) {
    case "today":
      return {
        before: dateTimeNow().plus({ day: 1 }).startOf("day"),
        after: dateTimeNow().startOf("day"),
      };
    case "future": {
      if (filter.numberOfDays == null) {
        return null;
      }
      return {
        before: dateTimeNow()
          .startOf("day")
          .plus({ day: filter.numberOfDays + 1 }),
        after: dateTimeNow().plus({ day: 1 }).startOf("day"),
      };
    }
    case "past": {
      if (filter.numberOfDays == null) {
        return null;
      }
      return {
        before: dateTimeNow().startOf("day"),
        after: dateTimeNow().startOf("day").minus({ day: filter.numberOfDays }),
      };
    }
    case "month": {
      if (filter.yearMonth == null) {
        return null;
      }
      const yearMonthDt = DateTime.fromObject(filter.yearMonth);
      return {
        before: yearMonthDt.endOf("month").plus({ day: 1 }),
        after: yearMonthDt.startOf("month"),
      };
    }
    case "all":
      return {
        before: null,
        after: null,
      };
  }
}

export function getDefaultDateRangeFilter(
  filterType: ShiftDateRangeFilterType
): ShiftDateRangeFilter {
  switch (filterType) {
    case "today":
      return {
        type: "today",
      };
    case "future":
      return {
        type: "future",
        numberOfDays: null,
      };
    case "past":
      return {
        type: "past",
        numberOfDays: null,
      };
    case "month": {
      return {
        type: "month",
        yearMonth: null,
      };
    }
    case "all":
      return {
        type: "all",
      };
  }
}
