import React, { useCallback, useContext, useState } from "react";
import cn from "classnames";
import {
  CellContext,
  ColumnDef,
  Table,
  TableState,
  createColumnHelper,
  getCoreRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  useReactTable,
  RowSelectionState,
  OnChangeFn,
} from "@tanstack/react-table";
import { Trans } from "react-i18next";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import {
  CellCheckbox,
  HeaderCheckbox,
} from "oneclick-component/src/components/TableWithSelect";
import useShowMessage from "oneclick-component/src/hooks/useShowMessage";
import { ActionDialog } from "oneclick-component/src/components/Modal";
import { Button } from "oneclick-component/src/components/Button";
import { t } from "i18next";
import { DotsHorizon } from "oneclick-component/src/icon";
import { useBreakPoints } from "oneclick-component/src/providers";
import {
  BriefStation,
  IncidentShiftAccessControl,
  ShiftRequestBase,
  ShiftType,
  useMakeExpireShiftHandlerShiftsShiftIdMakeExpirePostMutation as useMakeExpire,
} from "oneclick-component/src/store/apis/enhancedApi";
import { StationBadge } from "oneclick-component/src/components/Badge";
import { useShowError } from "../../../hooks/useShowError";
import ProgressBar from "oneclick-component/src/components/ProgressBar";
import { StopIcon } from "../../../icon";
import HireStatusPopup from "../HireStatusPopup";
import PopMenu, {
  PopMenuItem,
} from "oneclick-component/src/components/PopMenu";
import { SELECT_COLUMN_ID } from "oneclick-component/src/components/TableWithSelect/constants";
import AppRoutes from "../../../routes/AppRoutes";
import CancelShiftDialog from "../../../components/CancelShiftDialog/CancelShiftDialog";
import { ShiftListContext } from "..";
import { HiredShiftRequestBase } from "../../../models/shiftRequest";
import { FeatureConfigContext } from "../../../providers/FeatureConfigProvider";
import ShiftFullTimeAttendanceDialog from "../../../components/ShiftFullTimeAttendanceDialog";
import { ShiftRateViewModel } from "oneclick-component/src/components/ShiftRateBadge";
import ShiftBadge from "oneclick-component/src/components/ShiftRateBadge/ShiftBadge";
import ShiftT3Badge from "oneclick-component/src/components/Badge/ShiftT3Badge";

export interface ActiveShiftListRecord {
  info: {
    title: string;
    rate: ShiftRateViewModel | null;
    subtitle: React.ReactElement;
    isAppliableToAllAtT3: boolean;
  };
  timeRange: string;
  partTimeType: string;
  appliedRequests: ShiftRequestBase[];
  hireStatus: {
    workingStationShortCode: string;
    fulfillmentCount: number;
    hiredRequests: HiredShiftRequestBase[];
  };
  // grouping/sorting, invisible columns
  title: string;
  date: string;
  numberOfApplicants: number;
  fulfillmentCount: number;
  id: number;

  // helper info
  fullTimeCount: number;
  hiredFullTimeCount: number;
  shiftType: ShiftType;
  incidentAccessControl?: IncidentShiftAccessControl | null;
  remoteStation?: BriefStation | null;
}

interface FullTimeStatusSectionProps {
  isFullTimeManageOpen: boolean;
  onFullTimeManageOpen: () => void;
  onFullTimeManageClose: () => void;
  record: ActiveShiftListRecord;
}

// eslint-disable-next-line react-refresh/only-export-components
const FullTimeStatusSection = (props: FullTimeStatusSectionProps) => {
  const {
    record,
    isFullTimeManageOpen,
    onFullTimeManageOpen,
    onFullTimeManageClose,
  } = props;

  return (
    <div>
      <ProgressBar
        max={record.fullTimeCount}
        count={record.hiredFullTimeCount}
        labelStyle="top"
        barSize="l"
        labelClassName="text-red-600"
        className={cn("mt-8", "mb-2")}
        title={t("shiftList.table.incident.hireStatus.fullTime")}
      />
      <button
        onClick={onFullTimeManageOpen}
        type="button"
        className={cn(
          "text-red-600",
          "text-xs",
          "font-medium",
          "flex",
          "flex-row",
          "items-center"
        )}
      >
        <PencilSquareIcon className={cn("h-3", "w-3", "mr-0.5")} />
        <u>
          <Trans i18nKey="fulltimeAttendance.action.updateList" />
        </u>
      </button>
      <ShiftFullTimeAttendanceDialog
        isOpen={isFullTimeManageOpen}
        onClose={onFullTimeManageClose}
        shiftId={record.id}
        fulltimeCount={record.fullTimeCount}
      />
    </div>
  );
};

const columnHelper = createColumnHelper<ActiveShiftListRecord>();

// eslint-disable-next-line react-refresh/only-export-components
const ActiveShiftListTableAction = ({
  row,
}: CellContext<ActiveShiftListRecord, unknown>): React.ReactElement | null => {
  const showMessage = useShowMessage();
  const { showError } = useShowError();
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [isCloseIncidentDialogOpen, setIsCloseIncidentDialogOpen] =
    useState(false);
  const onCloseCloseIncidentDialog = useCallback(
    () => setIsCloseIncidentDialogOpen(false),
    []
  );
  const { shiftCancelReasons } = useContext(ShiftListContext);

  const [makeExpire, { isLoading: isMakeExpireLoading }] = useMakeExpire();

  const isIncident = row.original.shiftType === "INCIDENT";
  const { canEdit: canEditIncident, canCancel: canCancelIncident } =
    row.original.incidentAccessControl ?? {};

  const shouldRenderIncidentActions =
    isIncident && (canEditIncident || canCancelIncident);
  const shouldRender = !isIncident || shouldRenderIncidentActions;

  const onConfirmCloseIncident = useCallback(async () => {
    makeExpire({ shiftId: row.original.id })
      .unwrap()
      .then(() => {
        showMessage({
          type: "success",
          title: t("shiftDetail.closeIncident.dialog.success.title"),
          message: t("shiftDetail.closeIncident.dialog.success.description"),
        });
      })
      .catch((err) => {
        showError(err, "shiftDetail.closeIncident.dialog.fail.title");
      });
  }, [makeExpire, row, showError, showMessage]);

  const onClickCloseIncidentShift = useCallback(() => {
    setIsCloseIncidentDialogOpen(true);
  }, []);
  const onCloseCancelDialog = useCallback(() => {
    setIsCancelDialogOpen(false);
  }, []);
  const onOpenCancelDialog = useCallback(
    (e?: React.MouseEvent<HTMLButtonElement>) => {
      e?.stopPropagation();
      setIsCancelDialogOpen(true);
    },
    []
  );
  const popMenuControlElementClass = useCallback(
    ({ open }: { open: boolean }) =>
      cn(
        "flex",
        "max-w-xs",
        "p-2", // for larger clickable area
        "mx-auto",
        "items-center",
        "rounded-full",
        "text-sm",
        "focus:outline-none",
        "focus:ring-2",
        "focus:ring-primary-500",
        "focus:ring-offset-2",
        {
          "fill-gray-500": !open && !isIncident,
          "fill-red-600": !open && isIncident,
          "fill-primary-500": open,
          "bg-white": !isIncident,
          "bg-red-50": isIncident,
        }
      ),
    [isIncident]
  );

  const stopPropagate = useCallback((e?: React.MouseEvent<HTMLElement>) => {
    e?.stopPropagation();
  }, []);

  if (!shouldRender) {
    return null;
  }
  return (
    <div onClick={stopPropagate}>
      {isIncident ? (
        <div>
          <Button
            prefixIcon={StopIcon}
            className={cn("bg-red-600", "hover:bg-red-800")}
            isFlashy={true}
            flashyClassName={cn("!bg-red-600")}
            onClick={onClickCloseIncidentShift}
            title={t("shiftList.table.incident.tooltip")}
          >
            <Trans i18nKey="shiftList.table.incident.closeShift" />
          </Button>
          <ActionDialog
            title={t("shiftDetail.closeIncidentShift.dialog.title")}
            actionButtonText={t(
              "shiftDetail.closeIncidentShift.dialog.action.confirm"
            )}
            cancelButtonText={t("common.cancel")}
            isOpen={isCloseIncidentDialogOpen}
            onClose={onCloseCloseIncidentDialog}
            onClickActionButton={onConfirmCloseIncident}
            isActionLoading={isMakeExpireLoading}
            titleIcon={
              <ExclamationTriangleIcon
                className={cn("w-6", "h-6", "mr-2", "text-black")}
              />
            }
          />
        </div>
      ) : (
        <div>
          <PopMenu
            // workaround cell padding asymmertic
            menuClass={cn("relative", "pr-1", "sm:pr-3")}
            controlElementClass={popMenuControlElementClass}
            controlElement={<DotsHorizon className={cn("w-5", "h-5")} />}
          >
            <div className="py-1">
              <PopMenuItem
                type="link"
                className={"text-gray-700"}
                link={AppRoutes.EditShiftScreen.render(row.original.id)}
                text={t("shiftList.tab.active.actionMenu.editShift")}
              />
              <PopMenuItem
                type="button"
                onClick={onOpenCancelDialog}
                className={"text-rose-500"}
                text={t("shiftList.tab.active.actionMenu.cancelShift")}
              />
            </div>
          </PopMenu>
          <CancelShiftDialog
            shiftIds={[row.original.id]}
            isOpen={isCancelDialogOpen}
            onClose={onCloseCancelDialog}
            cancelReasons={shiftCancelReasons}
          />
        </div>
      )}
    </div>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
const ActiveShiftListTableHireStatus = ({
  row,
}: CellContext<ActiveShiftListRecord, unknown>): React.ReactElement | null => {
  const { original } = row;
  const isIncident = original.shiftType === "INCIDENT";
  const [isFullTimeManageOpen, setIsFullTimeManageOpen] = useState(false);

  const onFullTimeManageOpen = useCallback(() => {
    setIsFullTimeManageOpen(true);
  }, []);

  const onFullTimeManageClose = useCallback(() => {
    setIsFullTimeManageOpen(false);
  }, []);
  return (
    <HireStatusPopup
      shiftId={original.id}
      workingStationShortCode={original.hireStatus.workingStationShortCode}
      isIncident={isIncident}
      shiftRequests={original.hireStatus.hiredRequests}
      fulfillmentCount={original.hireStatus.fulfillmentCount}
      fulltimeCount={original.fullTimeCount}
      onFullTimeManageOpen={onFullTimeManageOpen}
    >
      <ProgressBar
        max={original.hireStatus.fulfillmentCount}
        count={original.hireStatus.hiredRequests.length}
        labelStyle="top"
        barSize="l"
        labelClassName={cn({ "text-red-600": isIncident })}
        title={
          isIncident ? t("shiftList.table.incident.hireStatus.partTime") : ""
        }
      />
      {isIncident ? (
        <FullTimeStatusSection
          isFullTimeManageOpen={isFullTimeManageOpen}
          onFullTimeManageOpen={onFullTimeManageOpen}
          onFullTimeManageClose={onFullTimeManageClose}
          record={original}
        />
      ) : null}
    </HireStatusPopup>
  );
};

export const useActiveShiftListTableColumns = (
  hasIncident: boolean
  // eslint-disable-next-line sonarjs/cognitive-complexity
): ColumnDef<ActiveShiftListRecord, any>[] => {
  const { shouldShowT3Feature } = useContext(FeatureConfigContext);
  const { useIsSm } = useBreakPoints();
  const isSm = useIsSm();
  return [
    {
      id: SELECT_COLUMN_ID,
      size: 16,
      header: HeaderCheckbox,
      cell: CellCheckbox,
    },
    columnHelper.accessor("info", {
      header: () => t("shiftList.table.header.shiftInfo"),
      cell: ({ cell, row }) => {
        const isIncident = row.original.shiftType === "INCIDENT";
        const info = cell.getValue();
        return (
          <div className={cn("flex", "flex-row", "items-center")}>
            <div className={cn("flex", "flex-col", "flex-1")}>
              <div className={cn("flex", "gap-x-3", "items-center")}>
                <ShiftBadge rate={info.rate} isIncident={isIncident} />
                <p
                  className={cn("font-medium", "text-sm", "text-black/90", {
                    "text-red-600": isIncident,
                  })}
                >
                  {info.title}
                </p>
                {shouldShowT3Feature && !isIncident ? (
                  <>
                    <div
                      className={cn(
                        "border",
                        "border-solid",
                        "border-black/24",
                        "self-stretch",
                        "w-[1px]",
                        "my-0.5"
                      )}
                    ></div>
                    <ShiftT3Badge active={info.isAppliableToAllAtT3} />
                  </>
                ) : null}
              </div>
              <p
                className={cn(
                  "font-normal",
                  "text-xs",
                  "leading-5",
                  "text-black/60",
                  { "text-red-600": isIncident },
                  "mt-1"
                )}
              >
                {info.subtitle}
              </p>
            </div>
            {row.original.remoteStation ? (
              <div>
                <StationBadge
                  station={row.original.remoteStation}
                  stationTeam={null}
                />
              </div>
            ) : null}
          </div>
        );
      },
    }),
    columnHelper.accessor("timeRange", {
      header: () => t("shiftList.table.header.timeRange"),
      size: 140,
      cell: ({ cell, row }) => {
        const isIncident = row.original.shiftType === "INCIDENT";
        return (
          <p
            className={cn(
              "font-normal",
              "text-sm",
              "leading-5",
              "text-gray-500",
              { "text-red-600": isIncident }
            )}
          >
            {cell.getValue()}
          </p>
        );
      },
    }),
    columnHelper.accessor("partTimeType", {
      header: () => t("shiftList.table.header.partTimeType"),
      size: 120,
      cell: ({ cell, row }) => {
        const isIncident = row.original.shiftType === "INCIDENT";
        return (
          <p
            className={cn(
              "font-normal",
              "text-sm",
              "leading-5",
              "text-gray-500",
              { "text-red-600": isIncident }
            )}
          >
            {cell.getValue()}
          </p>
        );
      },
    }),
    columnHelper.accessor("appliedRequests", {
      header: () => (
        <span className={"min-w-10"}>
          {t("shiftList.table.header.numberOfApplicant")}
        </span>
      ),
      size: isSm ? 120 : 76,
      cell: ({ cell, row }) => {
        const isIncident = row.original.shiftType === "INCIDENT";
        const requests = cell.getValue();
        const unread = requests.some(
          (req: ShiftRequestBase) =>
            (req.status === "applied" && req.borrowingRequestStatus == null) ||
            (req.status === "applied" &&
              req.borrowingRequestStatus === "approved")
        );
        return (
          <div className={cn("flex", "flex-row")}>
            <p
              className={cn(
                "font-normal",
                "text-sm",
                "leading-5",
                "text-gray-500",
                { "text-red-600": isIncident }
              )}
            >
              {requests.length}
            </p>
            {unread ? (
              <div
                className={cn(
                  "w-[6px]",
                  "h-[6px]",
                  "ml-1",
                  "bg-red-600",
                  "rounded-full"
                )}
              />
            ) : null}
          </div>
        );
      },
    }),
    columnHelper.accessor("hireStatus", {
      header: () => (
        <span className={"whitespace-normal"}>
          {t("shiftList.table.header.ratioOfHire")}
        </span>
      ),
      size: 160,
      cell: ActiveShiftListTableHireStatus,
    }),
    columnHelper.display({
      id: "action",
      header: () => (
        <span className="w-full">{t("shiftList.table.header.action")}</span>
      ),
      size: hasIncident ? 220 : 90,
      cell: ActiveShiftListTableAction,
    }),
    columnHelper.accessor("title", {
      enableHiding: true,
    }),
    columnHelper.accessor("date", {
      enableHiding: true,
    }),
    columnHelper.accessor("fulfillmentCount", {
      enableHiding: true,
    }),
    columnHelper.accessor("numberOfApplicants", {
      enableHiding: true,
    }),
    columnHelper.accessor("id", {
      enableHiding: true,
    }),
    columnHelper.accessor("shiftType", {
      enableHiding: true,
    }),
  ];
};

export const useActiveShiftListTable = (
  data: ActiveShiftListRecord[],
  onRowSelectionChange: OnChangeFn<RowSelectionState>,
  state?: Partial<TableState>
): Table<ActiveShiftListRecord> => {
  const hasIncident = data.filter((s) => s.shiftType === "INCIDENT").length > 0;
  const shiftListTableColumns = useActiveShiftListTableColumns(hasIncident);
  const table = useReactTable({
    data,
    columns: shiftListTableColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    defaultColumn: {
      minSize: 0,
      size: Number.MAX_SAFE_INTEGER,
      maxSize: Number.MAX_SAFE_INTEGER,
    },
    manualSorting: true,
    groupedColumnMode: false,
    enableRowSelection: true,
    onRowSelectionChange,
    state,
  });
  return table;
};
