import React, { ReactElement, useMemo, useState } from "react";
import TabMenu from "oneclick-component/src/components/TabMenu";
import { useTranslation, Trans } from "react-i18next";
import { MegaphoneIcon } from "@heroicons/react/20/solid";
import { PlusIcon } from "@heroicons/react/24/outline";
import cn from "classnames";
import {
  ButtonLink,
  IconButtonLink,
} from "oneclick-component/src/components/Button";
import AppRoutes from "../../routes/AppRoutes";
import { Navigate, matchPath, useLocation } from "react-router-dom";
import { ChatBubble } from "../../components/ChatBubble";
import {
  ShiftCancelReason,
  useListShiftCancelReasonsHandlerShiftsCancelReasonsGetQuery,
} from "oneclick-component/src/store/apis/enhancedApi";
import { useShowError } from "../../hooks/useShowError";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import { ClockFilledIcon, TrashIcon } from "oneclick-component/src/icon";
import useMeUser from "../../hooks/useMeUser";

export const SHIFT_LIST_PAGE_SIZE = 10;

interface ShiftListContextValue {
  activeShiftListLength: number;
  setActiveShiftListLength: (shiftLength: number) => void;
  shiftCancelReasons: ShiftCancelReason[];
}

export const ShiftListContext = React.createContext<ShiftListContextValue>(
  null as any
);

const ShiftListScreen = (): ReactElement => {
  const { t } = useTranslation();
  const meUser = useMeUser();
  const location = useLocation();
  const { showError } = useShowError();

  const createShiftScreenPath = useMemo<string>(() => {
    return AppRoutes.CreateMultiShiftScreen.render();
  }, []);

  const [activeShiftListLength, setActiveShiftListLength] = useState<number>(0);

  const {
    data: cancelReasonsResponse,
    isLoading: isLoadingCancelReasons,
    error: getCancelReasonsError,
  } = useListShiftCancelReasonsHandlerShiftsCancelReasonsGetQuery();

  const contextValue: ShiftListContextValue = useMemo(
    () => ({
      activeShiftListLength,
      setActiveShiftListLength,
      shiftCancelReasons: cancelReasonsResponse?.cancelReasons ?? [],
    }),
    [activeShiftListLength, cancelReasonsResponse?.cancelReasons]
  );

  const showCreateShiftButton =
    location.pathname === AppRoutes.ShiftListActiveShiftTab.render() &&
    activeShiftListLength !== 0 &&
    meUser?.adminRole === "MANAGER";

  if (getCancelReasonsError != null) {
    showError(getCancelReasonsError);
  }

  if (isLoadingCancelReasons) {
    return (
      <div
        className={cn(
          "fixed",
          "top-1/2",
          "left-1/2",
          "-translate-x-1/2",
          "-translate-y-1/2"
        )}
      >
        <LoadingSpinner size="l" />
      </div>
    );
  }

  if (matchPath(AppRoutes.ShiftListScreen.path, location.pathname)) {
    return (
      <Navigate
        to={AppRoutes.ShiftListActiveShiftTab.render()}
        replace={true}
      />
    );
  }

  return (
    <ShiftListContext.Provider value={contextValue}>
      <main>
        <div
          className={cn(
            "rounded-lg",
            "bg-white",
            "max-w-344",
            "mx-auto",
            "sm:mb-6"
          )}
        >
          <div className={cn("sm:px-9", "sm:py-6", "pb-6", "px-6")}>
            <TabMenu
              actionButton={
                showCreateShiftButton ? (
                  <ButtonLink
                    prefixIcon={PlusIcon}
                    to={createShiftScreenPath}
                    className={cn("my-2", "hidden", "sm:inline-flex")}
                  >
                    <Trans i18nKey="shiftList.tab.button.create" />
                  </ButtonLink>
                ) : null
              }
              panels={[
                {
                  icon: MegaphoneIcon,
                  title: t("shiftList.tab.title.announced"),
                  to: AppRoutes.ShiftListActiveShiftTab.render(),
                },
                {
                  icon: ClockFilledIcon,
                  title: t("shiftList.tab.title.expired"),
                  to: AppRoutes.ShiftListExpiredShiftTab.render(),
                },
                {
                  icon: TrashIcon,
                  title: t("shiftList.tab.title.closed"),
                  to: AppRoutes.ShiftListCancelledShiftTab.render(),
                },
              ]}
            />
          </div>
        </div>
        <div
          className={cn(
            "fixed",
            "z-20",
            "right-4",
            "bottom-18",
            "space-y-3",
            "sm:hidden"
          )}
        >
          {showCreateShiftButton ? (
            <IconButtonLink
              to={createShiftScreenPath}
              icon={PlusIcon}
              theme="whitePrimary"
            />
          ) : null}
          <ChatBubble />
        </div>
      </main>
    </ShiftListContext.Provider>
  );
};

export default ShiftListScreen;
