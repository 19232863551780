import { UseLazyQuery } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { useCallback, useEffect, useMemo, useState } from "react";

export interface InfiniteQueryPage<TAPIResponse> {
  pageIndex: number;
  data: TAPIResponse;
}

interface PageData<TAPIResponse> {
  pages: InfiniteQueryPage<TAPIResponse>[] | null;
  hasNextPage: boolean;
  nextPageIndex: number | null;
  totalCount: number;
}

export interface UseInfiniteQueryReturnValue<TAPIResponse>
  extends PageData<TAPIResponse> {
  fetchNextPage: (nextPageIndex: number) => void;
  resetAndFetchFirstPage: () => void;
  isFetchingNextPage: boolean;
}

export interface UseInfiniteLoadQueryResponse<TAPIResponse> {
  data?: TAPIResponse;
  isLoading: boolean;
  isFetching: boolean;
  fetchNextPage: () => void;
}

const DEFAULT_PAGE_DATA = {
  pages: null,
  hasNextPage: true,
  nextPageIndex: 0,
  totalCount: 0,
};

const EMPTY_PAGE_DATA = {
  pages: [],
  hasNextPage: false,
  nextPageIndex: null,
  totalCount: 0,
};

const useInfiniteQuery = <TAPIResponse>(
  useLazyQuery: UseLazyQuery<any>, // FIXME: Figure out rtk-query type parsing
  queryParams: any, // FIXME: Figure out rtk-query type parsing
  skipOnMount?: boolean
): UseInfiniteQueryReturnValue<TAPIResponse> => {
  const [fetch, { isFetching, isLoading }] = useLazyQuery();

  const [pageData, setPageData] =
    useState<PageData<TAPIResponse>>(DEFAULT_PAGE_DATA);

  const fetchNextPage = useCallback(
    (nextPageIndex: number) => {
      fetch({ ...queryParams, pageIndex: nextPageIndex })
        .unwrap()
        .then((data: any) => {
          // // FIXME: Figure out rtk-query type parsing
          if (
            data == null ||
            data.results == null ||
            data.results.length === 0
          ) {
            setPageData(EMPTY_PAGE_DATA);
            return;
          }
          const { nextPageIndex: newNextPageIndex, totalCount } = data;
          setPageData((prev) => {
            const duplicatePages =
              prev.pages?.filter((page) => page.pageIndex === nextPageIndex) ??
              [];
            if (duplicatePages.length > 0) {
              return prev;
            }

            return {
              pages: [
                ...(prev.pages ?? []),
                { pageIndex: nextPageIndex, data: data },
              ],
              hasNextPage: newNextPageIndex != null,
              nextPageIndex: newNextPageIndex,
              totalCount,
            };
          });
        })
        .catch(console.error);
    },
    [fetch, queryParams]
  );

  const resetAndFetchFirstPage = useCallback(() => {
    // reset
    setPageData(DEFAULT_PAGE_DATA);

    // fetch first page
    fetchNextPage(0);
  }, [fetchNextPage]);

  // fetch first page on mount
  useEffect(() => {
    if (skipOnMount !== true) {
      fetchNextPage(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skipOnMount]);

  const returnValue = useMemo(
    () => ({
      fetchNextPage,
      resetAndFetchFirstPage,
      ...pageData,
      isFetchingNextPage: isFetching || isLoading || pageData.pages === null,
    }),
    [fetchNextPage, isFetching, isLoading, pageData, resetAndFetchFirstPage]
  );
  return returnValue;
};

export default useInfiniteQuery;
