import { z } from "zod";
import {
  intraDayRegularShiftSchema,
  compareByStartTimeAscending,
  IntraDayRegularShift,
} from "../../models";
import i18next from "i18next";

export const defaultIntraDayRegularShift: IntraDayRegularShift = {
  startTime: "08:00",
  endTime: "12:00",
};

export const regularShiftFormSchema = z.lazy(() =>
  z.object({
    regularShifts: z
      .array(intraDayRegularShiftSchema)
      .refine(
        (vals) => vals.every((v) => v.startTime < v.endTime),
        i18next.t(
          "partTime.form.weekCard.editModal.form.availabilities.endTimeBeforeStartTime"
        )
      )
      .refine((vals) => {
        const sorted = vals.slice().sort(compareByStartTimeAscending);
        return sorted.every(
          (val, i) =>
            i >= sorted.length - 1 || val.endTime <= sorted[i + 1]?.startTime
        );
      }, i18next.t("partTime.form.weekCard.editModal.form.availabilities.noOverlapAvailabilities")),
  })
);
export type RegularShiftForm = z.infer<typeof regularShiftFormSchema>;
