import React, { useCallback } from "react";
import cn from "classnames";
import { Row, flexRender } from "@tanstack/react-table";
import { SELECT_COLUMN_ID } from "./constants";

interface TableWithSelectRowProps<T> {
  className?: string;
  row: Row<T>;
  onClick?: () => void;
}

export function TableWithSelectRow<T>(
  props: TableWithSelectRowProps<T>
): React.ReactElement {
  const { row, onClick, className } = props;

  const stopPropagate = useCallback((e?: React.MouseEvent<HTMLElement>) => {
    e?.stopPropagation();
  }, []);

  return (
    <tr
      className={cn(
        {
          "cursor-pointer": onClick != null,
        },
        className
      )}
      onClick={onClick}
    >
      {row.getVisibleCells().map((cell) => {
        if (cell.column.id === SELECT_COLUMN_ID) {
          return (
            <td
              key={cell.id}
              className={cn("pr-3", "text-sm", "bg-white")}
              style={{
                width:
                  cell.column.getSize() === Number.MAX_SAFE_INTEGER
                    ? "auto"
                    : cell.column.getSize(),
              }}
              onClick={stopPropagate}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          );
        }
        return (
          <td
            key={cell.id}
            className={cn("py-4", "pl-4", "pr-3", "text-sm", "sm:pl-6")}
            style={{
              width:
                cell.column.getSize() === Number.MAX_SAFE_INTEGER
                  ? "auto"
                  : cell.column.getSize(),
            }}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        );
      })}
    </tr>
  );
}
