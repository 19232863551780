import React, { ReactElement, useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import cn from "classnames";
import { FormField } from "oneclick-component/src/components/forms";
import { PlusSmallIcon } from "@heroicons/react/24/outline";
import { Button } from "oneclick-component/src/components/Button";
import {
  RegularShiftForm,
  regularShiftFormSchema,
  defaultIntraDayRegularShift,
} from "./form";
import { PTUserWeekCardDialogRowList } from "./WeekCardDialogRow";
import {
  IntraDayRegularShift,
  compareByStartTimeAscending,
} from "../../models";
interface Props {
  className?: string;
  onCloseModal: () => void;
  regularShifts: IntraDayRegularShift[];
  setRegularShifts: (v: IntraDayRegularShift[]) => void;
}

export const PTUserWeekCardDialogForm = (props: Props): ReactElement => {
  const { t } = useTranslation();
  const { regularShifts, className, setRegularShifts, onCloseModal } = props;
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<RegularShiftForm>({
    resolver: zodResolver(regularShiftFormSchema),
    defaultValues: {
      regularShifts,
    },
  });

  const watchedRegularShifts = useWatch({ control, name: "regularShifts" });

  const onClickAddRegularShift = useCallback(() => {
    const newRegularShifts = getValues().regularShifts.slice();
    newRegularShifts.push(defaultIntraDayRegularShift);
    setValue("regularShifts", newRegularShifts);
  }, [getValues, setValue]);

  const _onSubmit = useCallback(
    (data: RegularShiftForm) => {
      setRegularShifts(data.regularShifts.sort(compareByStartTimeAscending));
      onCloseModal();
    },
    [setRegularShifts, onCloseModal]
  );

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      handleSubmit(_onSubmit)(e).catch(console.error);
      e.stopPropagation();
    },
    [_onSubmit, handleSubmit]
  );

  return (
    <form className={cn("space-y-5", className)} onSubmit={onSubmit}>
      {watchedRegularShifts.length === 0 ? (
        <label
          className={cn("text-sm", "leading-6", "font-medium", "text-gray-900")}
        >
          <Trans i18nKey="partTime.form.weekCard.editModal.isEntireDayUnavailable" />
        </label>
      ) : null}
      {errors.regularShifts?.message != null ? (
        <p className={cn("mt-2", "text-sm", "text-red-600", "text-left")}>
          {errors.regularShifts.message}
        </p>
      ) : null}
      <FormField.Control name="regularShifts" control={control}>
        {({ field }) => (
          <PTUserWeekCardDialogRowList
            field={field}
            setRegularShiftsValue={field.onChange}
            getValues={getValues}
          />
        )}
      </FormField.Control>
      <button
        type="button"
        className={cn(
          "flex",
          "items-center",
          "gap-x-1.5",
          "rounded-sm",
          "focus:outline-0",
          "text-primary-600",
          "font-medium",
          "text-sm"
        )}
        onClick={onClickAddRegularShift}
      >
        <PlusSmallIcon className={cn("w-4", "h-4")} />
        <span>
          {t("partTime.form.weekCard.editModal.addAvailability.button.text")}
        </span>
      </button>

      <div className={cn("mt-5", "flex", "flex-row-reverse")}>
        <Button className={cn("w-full", "font-semibold", "ml-3")} type="submit">
          {t("partTime.form.weekCard.editModal.action")}
        </Button>
        <Button
          className={cn("w-full", "font-semibold")}
          onClick={onCloseModal}
          theme="white"
        >
          {t("partTime.form.weekCard.editModal.cancel")}
        </Button>
      </div>
    </form>
  );
};
