import { useMemo, useRef } from "react";

type Action = (...args: any[]) => void;

export function useDebounce<F extends Action>(
  func: F,
  delayMs?: number
): (...args: Parameters<F>) => void {
  const timeoutIdRef = useRef<number | null>(null);

  const debouncedFunc = useMemo(() => {
    return (...args: Parameters<F>) => {
      if (timeoutIdRef.current != null) {
        clearTimeout(timeoutIdRef.current);
      }

      timeoutIdRef.current = window.setTimeout(() => {
        timeoutIdRef.current = null;
        func(...args);
      }, delayMs);
    };
  }, [func, delayMs]);

  return debouncedFunc;
}
