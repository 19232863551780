export function containsSubstring(
  container: string,
  substring: string
): boolean {
  const cleanedContainer = container.replace(/\s+/g, "").toLowerCase();
  const cleanedSubstring = substring.replace(/\s+/g, "").toLowerCase();

  return cleanedContainer.includes(cleanedSubstring);
}

export function transformNumberOnly(value: string): string {
  return value.replace(/[^0-9]/g, "");
}
