import { ReactElement, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import cn from "classnames";

import {
  PtUser,
  useSchedulePtUserSuspensionHandlerPartTimeUsersPtUserIdScheduleSuspensionPostMutation as useScheduleSuspendPtUser,
  useReactivatePtUserHandlerPartTimeUsersPtUserIdReactivatePostMutation as useReactivatePtUser,
  useWelcomePtUserHandlerPartTimeUsersPtUserIdWelcomePostMutation as useWelcomePtUser,
} from "oneclick-component/src/store/apis/enhancedApi";
import { StationBadge } from "oneclick-component/src/components/Badge";
import CCOBadge from "oneclick-component/src/components/Badge/CCOBadge";
import useShowMessage from "oneclick-component/src/hooks/useShowMessage";
import AppRoutes from "../../../../routes/AppRoutes";
import { PTUserAvatarImage } from "../../../../components/PTUserAvatarImage";
import { useShowError } from "../../../../hooks/useShowError";
import PartTimeUserCardWeekTable from "../PartTimeUserCardWeekTable";
import PTUserSuspendedBadge from "../../../../components/PTUserBadge/PTUserSuspendedBadge";
import PTUserActiveBadge from "../../../../components/PTUserBadge/PTUserActiveBadge";
import SuspendedPTUserMoreButton from "./SuspendedPTUserMoreButton";
import ActivePTUserMoreButton from "./ActivePTUserMoreButton";
import ReactivateDialog from "./ReactivateDialog";
import ScheduleSuspendDialog, {
  ScheduleSuspendDialogConfirmPayload,
} from "./ScheduleSuspendDialog";
import {
  isoToDateTime,
  localizeDateTime,
} from "oneclick-component/src/utils/datetime";

interface Props {
  ptUser: PtUser;
  showStationTeam: boolean;
}

const PartTimeUserCard = (props: Props): ReactElement => {
  const { ptUser, showStationTeam } = props;
  const { showError } = useShowError();
  const [isScheduleSuspendDialogOpen, setIsScheduleSuspendDialogOpen] =
    useState<boolean>(false);
  const [isReactivateDialogOpen, setIsReactivateDialogOpen] =
    useState<boolean>(false);
  const [scheduleSuspendPtUser, { isLoading: isScheduleSuspendPtUserLoading }] =
    useScheduleSuspendPtUser();
  const [reactivatePtUser, { isLoading: isReactivatePtUserLoading }] =
    useReactivatePtUser();
  const [sendLoginLink] = useWelcomePtUser();
  const showMessage = useShowMessage();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onClickEdit = useCallback(() => {
    navigate(AppRoutes.EditPartTimeScreen.render(ptUser.id));
  }, [navigate, ptUser]);

  const formattedScheduledLastDate = useMemo(() => {
    if (ptUser.scheduledSuspendAt == null) {
      return null;
    }
    return localizeDateTime(
      isoToDateTime(ptUser.scheduledSuspendAt).plus({ days: -1 }),
      DateTime.DATE_MED
    );
  }, [ptUser.scheduledSuspendAt]);

  const onClickScheduleSuspend = useCallback(() => {
    setIsScheduleSuspendDialogOpen(true);
  }, []);
  const onClickReenable = useCallback(() => {
    setIsReactivateDialogOpen(true);
  }, []);
  const onCloseScheduleSuspendDialog = useCallback(() => {
    setIsScheduleSuspendDialogOpen(false);
  }, []);
  const onCloseReactivateDialog = useCallback(() => {
    setIsReactivateDialogOpen(false);
  }, []);

  const onClickSendLoginLink = useCallback(() => {
    sendLoginLink({ ptUserId: ptUser.id })
      .unwrap()
      .then((_) => {
        showMessage({
          type: "success",
          title: t(
            "partTime.list.tab.availability.sendLoginLink.toast.success.title"
          ),
        });
      })
      .catch((err: unknown) => {
        showError(
          err,
          "partTime.list.tab.availability.sendLoginLink.toast.fail.title"
        );
      });
  }, [sendLoginLink, showError, showMessage, t, ptUser]);

  const handleConfirmScheduleSuspension = useCallback(
    (state: ScheduleSuspendDialogConfirmPayload) => {
      const displayName = ptUser.fullNameZhHk;
      scheduleSuspendPtUser({
        ptUserId: ptUser.id,
        schedulePtUserSuspensionRequest: {
          scheduledSuspendAt: state.scheduledSuspendAt,
          isImmediate: state.isImmediate,
        },
      })
        .unwrap()
        .then((_) => {
          showMessage({
            type: "success",
            title: t(
              "partTime.list.tab.availability.scheduleSuspendDialog.toast.success.title"
            ),
            message: t(
              "partTime.list.tab.availability.scheduleSuspendDialog.toast.success.message",
              { displayName }
            ),
          });
        })
        .catch((err) => {
          showError(
            err,
            "partTime.list.tab.availability.scheduleSuspendDialog.toast.fail.title",
            {
              CONFLICT:
                "partTime.list.tab.availability.scheduleSuspendDialog.toast.fail.alreadyScheduledSuspension.message",
            }
          );
        })
        .finally(() => {
          onCloseScheduleSuspendDialog();
        });
    },
    [
      ptUser,
      onCloseScheduleSuspendDialog,
      scheduleSuspendPtUser,
      showMessage,
      t,
      showError,
    ]
  );

  const handleConfirmReactivate = useCallback(() => {
    const displayName = ptUser.fullNameZhHk;
    reactivatePtUser({ ptUserId: ptUser.id })
      .unwrap()
      .then((_) => {
        showMessage({
          type: "success",
          title: t(
            "partTime.list.tab.availability.reactivateDialog.toast.success.title"
          ),
          message: t(
            "partTime.list.tab.availability.reactivateDialog.toast.success.message",
            { displayName }
          ),
        });
      })
      .catch((err: unknown) => {
        showError(
          err,
          "partTime.list.tab.availability.reactivateDialog.toast.fail.title",
          {
            CONFLICT:
              "partTime.list.tab.availability.reactivateDialog.toast.fail.message.conflict",
          }
        );
      })
      .finally(() => {
        onCloseReactivateDialog();
      });
  }, [
    ptUser,
    onCloseReactivateDialog,
    reactivatePtUser,
    showMessage,
    t,
    showError,
  ]);

  const statusBadge = useMemo(() => {
    if (ptUser.pendingConsent) {
      return null;
    }
    return ptUser.isDeleted ? (
      <PTUserSuspendedBadge className="flex-none" />
    ) : (
      <PTUserActiveBadge className="flex-none" />
    );
  }, [ptUser]);

  return (
    <div
      className={cn(
        "p-4",
        "rounded-md",
        "border",
        "border-gray-200",
        "w-full",
        "space-y-3"
      )}
    >
      <div className={cn("flex", "relative")}>
        <PTUserAvatarImage ptUser={ptUser} className="mr-8" />
        <div className="flex-1">
          <div
            className={cn(
              "flex",
              "items-center",
              "gap-x-2",
              "mr-3",
              "flex-wrap"
            )}
          >
            <p>{ptUser.fullNameZhHk}</p>
            <div className={cn("flex", "items-center", "gap-x-2")}>
              {ptUser.station != null ? (
                <StationBadge
                  className="flex-none"
                  station={ptUser.station}
                  stationTeam={ptUser.stationTeam ?? null}
                  showStationTeam={showStationTeam}
                />
              ) : null}
              {ptUser.role?.name === "CCO" ? <CCOBadge /> : null}
              {statusBadge}
            </div>
            {ptUser.scheduledSuspendAt != null ? (
              <span
                className={cn("text-sm", "leading-[14px]", {
                  "text-pink-300": !ptUser.isDeleted,
                  "text-black/24": ptUser.isDeleted,
                })}
              >
                <Trans
                  i18nKey="partTime.list.tab.availability.lastDate"
                  values={{ date: formattedScheduledLastDate }}
                />
              </span>
            ) : null}
          </div>
          <div
            className={cn(
              "flex",
              "items-center",
              "space-x-2",
              "text-sm",
              "font-medium"
            )}
          >
            <span className="text-black/60">ID</span>
            <span className="text-black/86">{ptUser.eid}</span>
          </div>
        </div>
        {ptUser.isDeleted ? (
          <SuspendedPTUserMoreButton onClickReenable={onClickReenable} />
        ) : (
          <ActivePTUserMoreButton
            showSendLoginLink={ptUser.pendingConsent}
            showScheduleSuspendButton={ptUser.scheduledSuspendAt == null}
            onClickEdit={onClickEdit}
            onClickScheduleSuspend={onClickScheduleSuspend}
            onClickSendLoginLink={onClickSendLoginLink}
          />
        )}

        <ScheduleSuspendDialog
          isOpen={isScheduleSuspendDialogOpen}
          onClose={onCloseScheduleSuspendDialog}
          onConfirmScheduleSuspend={handleConfirmScheduleSuspension}
          ptUser={ptUser}
          isLoading={isScheduleSuspendPtUserLoading}
        />
        <ReactivateDialog
          isOpen={isReactivateDialogOpen}
          onClose={onCloseReactivateDialog}
          onConfirmReactivate={handleConfirmReactivate}
          ptUserName={ptUser.fullNameZhHk}
          isLoading={isReactivatePtUserLoading}
        />
      </div>
      {ptUser.role?.name === "CCO" ? null : (
        <div
          className={cn(
            "overflow-x-auto",
            "w-full",
            "rounded-md",
            "border",
            "border-gray-200"
          )}
        >
          <PartTimeUserCardWeekTable
            regularShiftSchedule={ptUser.regularShiftSchedule ?? []}
          />
        </div>
      )}
    </div>
  );
};

export default PartTimeUserCard;
