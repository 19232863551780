import React, { ForwardedRef, ReactElement, Ref, useMemo } from "react";
import cn from "classnames";
import { ListboxProps, Listbox } from "@headlessui/react";
import { ChevronDownIcon } from "../../../icon";
import { Option } from "../Select";
import { SelectOptions } from "../Select/SelectOptions";
import {
  FloatingPortal,
  autoUpdate,
  flip,
  shift,
  size,
  useFloating,
} from "@floating-ui/react";

interface Props<T>
  extends Omit<ListboxProps<typeof Listbox, T, T>, "value" | "defaultValue"> {
  options: Option<T>[];
  onChange: (value: T) => void;
  placeholder?: string;
  hasError?: boolean;
  value?: T;
}

export const SelectDecorator = React.forwardRef(function SelectDecorator<T>(
  props: Props<T>,
  ref: ForwardedRef<HTMLElement>
): ReactElement {
  const {
    options,
    onChange,
    placeholder = "",
    value,
    className,
    ...rest
  } = props;

  const selected = useMemo(() => {
    return options.find((option) => option.value === value);
  }, [options, value]);

  const { refs, floatingStyles } = useFloating({
    whileElementsMounted: autoUpdate,
    middleware: [
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
          });
        },
      }),
      shift(),
      flip(),
    ],
  });

  return (
    <Listbox
      ref={ref}
      value={value}
      onChange={onChange}
      as="div"
      className={cn("relative", "flex-1", "h-full", className)}
      {...rest}
    >
      {({ open }) => (
        <>
          <Listbox.Button
            ref={refs.setReference}
            className={cn(
              "relative",
              "w-full",
              "h-full",
              "px-3",
              "cursor-default",
              "rounded-md",
              "text-left",
              "text-gray-900",
              open && "ring-2",
              open && "ring-primary-600",
              "focus:ring-2",
              "focus:ring-primary-600",
              "focus:outline-none",
              "sm:text-sm",
              "sm:leading-6",
              selected == null && "text-opacity-25",
              "flex",
              "items-center"
            )}
          >
            <span className={cn("inline", "truncate", "mr-1", "flex-1")}>
              {selected?.name ?? placeholder}
            </span>
            <span className="pointer-events-none">
              <ChevronDownIcon
                className={cn(
                  "h-5",
                  "w-5",
                  "text-gray-400",
                  "fill-gray-400",
                  "inline"
                )}
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <FloatingPortal>
            <SelectOptions
              ref={refs.setFloating}
              style={floatingStyles}
              open={open}
              options={options}
              className="mt-1"
            />
          </FloatingPortal>
        </>
      )}
    </Listbox>
  );
}) as <T>(p: Props<T> & { ref?: Ref<HTMLElement> }) => ReactElement;
