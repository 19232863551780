import { useMemo, ReactElement } from "react";
import cn from "classnames";
import { SendIcon } from "oneclick-component/src/icon";
import { IconButton } from "oneclick-component/src/components/Button";
import { renderTemplateMessage } from "oneclick-component/src/utils/templateMessage";
import { TemplateMessage } from "oneclick-component/src/store/apis/enhancedApi";
interface SectionType {
  onSend?: () => void;
  isSending?: boolean;
  sendTemplate?: TemplateMessage;
  templateParam?: Record<string, string>;
  disabled?: boolean;
  className?: string;
}

export const SendTemplateActionSection = (props: SectionType): ReactElement => {
  const {
    sendTemplate,
    onSend,
    isSending,
    templateParam,
    disabled = false,
    className,
  } = props;

  const templatePreviewText = useMemo(() => {
    if (sendTemplate == null || templateParam == null) {
      return "";
    }
    return renderTemplateMessage(sendTemplate.body, templateParam);
  }, [sendTemplate, templateParam]);

  if (sendTemplate == null) {
    return <></>;
  }

  return (
    <div
      className={cn(
        "flex-1",
        "sm:max-w-82",
        "flex",
        "flex-row",
        "items-center",
        "bg-neutral-900/5",
        "p-3",
        "rounded-xl",
        "mt-3",
        "sm:mt-0",
        className
      )}
    >
      <p
        className={cn(
          "grow",
          "text-black/60",
          "text-xs",
          "font-medium",
          "leading-4",
          "whitespace-pre-wrap",
          "line-clamp-3"
        )}
      >
        {templatePreviewText}
      </p>
      <div>
        <IconButton
          icon={SendIcon}
          theme="whitePrimary"
          onClick={onSend}
          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
          disabled={isSending || disabled}
        />
      </div>
    </div>
  );
};
