import React, { PropsWithChildren, useContext, useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../utils/tailwind";

interface MediaQueryContextValue {
  useIsXs: () => boolean;
  useIsSm: () => boolean;
  useIsMd: () => boolean;
  useIsLg: () => boolean;
  useIsXl: () => boolean;
  useIs2xl: () => boolean;
}

const MediaQueryContext = React.createContext<MediaQueryContextValue>(
  null as any
);

export const MediaQueryProvider = ({
  children,
}: PropsWithChildren): React.ReactElement => {
  const useIsXs = () => useMediaQuery({ minWidth: breakpoints.xs });
  const useIsSm = () => useMediaQuery({ minWidth: breakpoints.sm });
  const useIsMd = () => useMediaQuery({ minWidth: breakpoints.md });
  const useIsLg = () => useMediaQuery({ minWidth: breakpoints.lg });
  const useIsXl = () => useMediaQuery({ minWidth: breakpoints.xl });
  const useIs2xl = () =>
    useMediaQuery({
      minWidth: breakpoints["2xl"],
    });

  const value = useMemo(
    () => ({
      useIsXs,
      useIsSm,
      useIsMd,
      useIsLg,
      useIsXl,
      useIs2xl,
    }),
    []
  );
  return (
    <MediaQueryContext.Provider value={value}>
      {children}
    </MediaQueryContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useBreakPoints = (): MediaQueryContextValue => {
  return useContext(MediaQueryContext);
};
