import React from "react";
import cn from "classnames";

export interface IndeterminateCheckboxInputProps {
  className?: string;
  indeterminate?: boolean;
  checked: boolean;
  disabled?: boolean;
  onChange: React.FormEventHandler<HTMLInputElement>;
}

export const IndeterminateCheckboxInput = React.memo(
  (props: IndeterminateCheckboxInputProps) => {
    const { indeterminate, className, checked, disabled, onChange } = props;
    const ref = React.useRef<HTMLInputElement>(null!);

    React.useEffect(() => {
      if (indeterminate != null) {
        ref.current.indeterminate = !checked && indeterminate;
      }
    }, [ref, indeterminate, checked]);

    return (
      <input
        type="checkbox"
        ref={ref}
        className={cn(
          "h-4",
          "w-4",
          // margin for focus ring
          "m-1",
          "rounded",
          "cursor-pointer",
          "border-gray-300",
          "text-primary-600",
          "focus:ring-indigo-600",
          className
        )}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
    );
  }
);
