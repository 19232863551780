import { ReactElement } from "react";
import cn from "classnames";
import { Badge } from "./Badge";
import { Environment } from "../../constants/environment";

interface Props {
  className?: string;
  environment: Environment;
}

interface EnvironmentBadgeConfig {
  bgColorClassName: string;
  content: string;
}
const ENVIRONMENT_BADGE_CONFIG_MAP: Record<
  Environment,
  EnvironmentBadgeConfig | null
> = {
  [Environment.LOCAL]: { content: "LOCAL", bgColorClassName: "bg-green-500" }, // not visible to client, developer-defined bg color
  [Environment.DEV]: { content: "DEV", bgColorClassName: "bg-orange-500" }, // not visible to client, developer-defined bg color
  [Environment.STAGING]: { content: "SIT", bgColorClassName: "bg-[#0074B9]" },
  [Environment.UAT]: { content: "UAT", bgColorClassName: "bg-[#903DA5]" },
  [Environment.PROD]: null,
};

export const EnvironmentBadge = (props: Props): ReactElement => {
  const { environment, className } = props;

  const badgeConfig = ENVIRONMENT_BADGE_CONFIG_MAP[environment];
  if (badgeConfig == null) {
    return <></>;
  }

  return (
    <Badge
      className={cn(
        "font-medium",
        "text-xs",
        "text-white",
        badgeConfig.bgColorClassName,
        className
      )}
    >
      {badgeConfig.content}
    </Badge>
  );
};
