import { ReactElement } from "react";
import { CommonListEmptyImage } from "../../image";
import cn from "classnames";
interface Props {
  description: string;
  className?: string;
  actionButton?: ReactElement;
}
export const ListEmptyView = (props: Props): ReactElement => {
  const { description, className, actionButton } = props;
  return (
    <div
      className={cn(
        "flex",
        "flex-col",
        "items-center",
        "justify-center",
        "h-full",
        className
      )}
    >
      <CommonListEmptyImage className="mb-7" />
      <p className={cn("mb-3", "text-sm", "text-black/60")}>{description}</p>
      {actionButton}
    </div>
  );
};
