import { useMemo } from "react";
import { useListStationTeamHandlerStationTeamsGetQuery as useListStationTeam } from "oneclick-component/src/store/apis/enhancedApi";
import { useSelectedStationProfile } from "./useSelectedStationProfile";
import useMeUser from "./useMeUser";

// NOTE: only display PT user station team to manager user in station with station team
export function useShouldShowPtStationTeam(): boolean {
  const meUser = useMeUser();
  const userIsAdmin = meUser?.adminRole === "ADMIN";
  const userSelectedProfile = useSelectedStationProfile();
  const { data: stationTeamsData } = useListStationTeam(undefined, {
    skip: userIsAdmin,
  });

  const stationTeamsInSelectedStation = useMemo(() => {
    if (stationTeamsData == null) {
      return [];
    }
    if (userSelectedProfile == null) {
      return stationTeamsData.stationTeams.filter((st) => {
        if (meUser == null) {
          return false;
        }
        return meUser.stations.some((s) => st.stationId === s.id);
      });
    }
    return stationTeamsData.stationTeams.filter(
      (s) => s.stationId === userSelectedProfile.station.id
    );
  }, [userSelectedProfile, stationTeamsData, meUser]);

  if (userIsAdmin) {
    return true;
  }

  return stationTeamsInSelectedStation.length > 0;
}
