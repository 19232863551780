import { ReactElement, useContext } from "react";
import PTUser418Badge from "../../../components/PTUserBadge/PTUser418Badge";
import { Trans } from "react-i18next";
import cn from "classnames";
import { FeatureConfigContext } from "../../../providers/FeatureConfigProvider";

const Legend418 = (): ReactElement => {
  const { shouldShowGreen418Badge } = useContext(FeatureConfigContext);
  return (
    <div className={cn("space-y-3", "sm:space-y-0", "sm:space-x-3", "sm:flex")}>
      {shouldShowGreen418Badge ? (
        <div className={cn("flex", "space-x-2", "items-center")}>
          <PTUser418Badge status="SAFE" />
          <p className={cn("text-xs", "text-black/60")}>
            <Trans i18nKey="partTime.list.tab.workStatus418.legend.safe" />
          </p>
        </div>
      ) : null}
      <div className={cn("flex", "space-x-2", "items-center")}>
        <PTUser418Badge status="WARNING" />
        <p className={cn("text-xs", "text-black/60")}>
          <Trans i18nKey="partTime.list.tab.workStatus418.legend.warning" />
        </p>
      </div>
    </div>
  );
};

export default Legend418;
