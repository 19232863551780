import i18next, { Resource } from "i18next";
import ICU from "i18next-icu";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { z } from "zod";
import { zodI18nMap } from "zod-i18n-map";
import { Locale } from "oneclick-component/src/i18n/Locale";
import enTranslation from "./en/translation.json";
import zhHantHkTranslation from "./zh-Hant-HK/translation.json";
import enZod from "./en/zod.json";
import zhHantHkZod from "./zh-Hant-HK/zod.json";

const resources: Resource = {
  en: {
    translation: enTranslation,
    zod: enZod,
  },
  "zh-Hant-HK": {
    translation: zhHantHkTranslation,
    zod: zhHantHkZod,
  },
};

i18next
  .use(ICU)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: [Locale.zhHantHK, Locale.en],
    ns: ["translation", "zod"],
    supportedLngs: [Locale.zhHantHK, Locale.en],
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["navigator"],
    },
  })
  .catch(console.error);
z.setErrorMap(zodI18nMap);

export default i18next;
