import * as z from "zod";

export const workingStationSchema = z.object({
  id: z.number(),
  name: z.object({ en: z.string(), zhHk: z.string() }),
  shortCode: z.string(),
  colorHexcode: z.string(),
  mainStationId: z.number(),
});

export const stationSchema = z.object({
  id: z.number(),
  name: z.object({ en: z.string(), zhHk: z.string() }),
  shortCode: z.string(),
  // TODO: define separate model, remove unnecessary joined entities
  workingStations: z.array(z.any()),
  relativeStations: z.array(z.any()),
  lines: z.array(z.any()),
  colorHexcode: z.string(),
});

export const briefStationWithWorkingStationSchema = z.object({
  id: z.number(),
  name: z.object({ en: z.string(), zhHk: z.string() }),
  shortCode: z.string(),
  workingStations: z.array(workingStationSchema),
  colorHexcode: z.string(),
});
