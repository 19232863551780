import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  useFloating,
  useHover,
  useInteractions,
  flip,
  shift,
  offset,
  safePolygon,
  FloatingPortal,
} from "@floating-ui/react";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import { useLazyListShiftFullTimeAttendanceByShiftHandlerShiftFullTimeAttendancesGetQuery as useLazyListShiftFullTimeAttendance } from "oneclick-component/src/store/apis/enhancedApi";
import cn from "classnames";
import { Trans } from "react-i18next";
import { HireIcon } from "oneclick-component/src/icon";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import { StationBadge } from "oneclick-component/src/components/Badge";
import ProgressBar from "oneclick-component/src/components/ProgressBar";
import HireStatusRow from "../../components/HireStatusRow";
import { HiredShiftRequestBase } from "../../models/shiftRequest";

interface PropType extends PropsWithChildren {
  shiftId: number;
  workingStationShortCode: string;
  shiftRequests: HiredShiftRequestBase[];
  fulfillmentCount: number;
  fulltimeCount: number;
  onFullTimeManageOpen?: () => void;
  isIncident?: boolean;
}

interface FullTimeSectionProps {
  shiftId: number;
  isOpen: boolean;
  fulltimeCount: number;
  isFullTimeManageOpen?: boolean;
  onFullTimeManageOpen?: () => void;
}

const FullTimeSection = (props: FullTimeSectionProps): ReactElement => {
  const { isOpen, shiftId, fulltimeCount, onFullTimeManageOpen } = props;
  const [loadList, { data, isLoading }] = useLazyListShiftFullTimeAttendance();

  // Always keep this list getting latest result
  useEffect(() => {
    if (isOpen) {
      loadList(
        {
          shiftId: shiftId,
        },
        false
      ).catch(() => {});
    }
  }, [isOpen, loadList, shiftId]);

  if (isLoading) {
    return (
      <div className={cn("flex", "flex-row", "justify-center", "my-2")}>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <hr className="mb-3" />
      <div className={cn("flex", "flex-row", "mb-3")}>
        <div className={cn("grow", "flex", "flex-row", "items-center")}>
          <HireIcon className={cn("mr-2", "fill-green-600")} />
          <p className={cn("text-green-600", "text-xs", "mr-6")}>
            <Trans i18nKey="shiftDetail.requested.status.hired" />:{" "}
            <Trans i18nKey="shiftList.table.incident.hireStatus.fullTime" />
          </p>
          {onFullTimeManageOpen != null ? (
            <button
              onClick={onFullTimeManageOpen}
              type="button"
              className={cn(
                "text-gray-400",
                "text-xs",
                "font-medium",
                "flex",
                "flex-row",
                "items-center"
              )}
            >
              <PencilSquareIcon className={cn("h-3", "w-3", "mr-0.5")} />
              <u>
                <Trans i18nKey="fulltimeAttendance.action.updateList" />
              </u>
            </button>
          ) : null}
        </div>
        <ProgressBar
          max={fulltimeCount}
          count={(data?.items ?? []).length}
          barSize="s"
        />
      </div>
      {data != null && data.items.length > 0 ? (
        <div
          className={cn(
            "max-h-89",
            "rounded-lg",
            "border-black/10",
            "border",
            "overflow-y-auto"
          )}
        >
          {data.items.map((fulltime, index) => (
            <div
              key={fulltime.id}
              className={cn(
                "grid",
                "grid-cols-12",
                "px-4",
                "text-sm",
                "first:pt-3",
                "pb-3",
                "font-medium"
              )}
            >
              <div className={cn("col-span-8", "grid", "grid-cols-8")}>
                {fulltime.name.zhHk && fulltime.name.zhHk.length > 0 ? (
                  <div className="col-span-2">{fulltime.name.zhHk}</div>
                ) : null}
                <div className="col-span-6">{fulltime.name.en}</div>
              </div>
              <div className="col-span-2">
                <StationBadge station={fulltime.station} stationTeam={null} />
              </div>
              <div className="col-span-2">{fulltime.role}</div>
              {index + 1 < data.items.length ? (
                <div
                  className={cn(
                    "col-span-12",
                    "mt-3",
                    "border-b-2",
                    "border-gray-200"
                  )}
                />
              ) : null}
            </div>
          ))}
        </div>
      ) : (
        <p
          className={cn(
            "text-gray-400",
            "font-normal",
            "text-center",
            "mt-5",
            "mb-3"
          )}
        >
          <Trans i18nKey="shiftDetail.hire.noHiredUser" />
        </p>
      )}
    </>
  );
};

const HireStatusPopup = (props: PropType): ReactElement => {
  const {
    shiftId,
    workingStationShortCode,
    children,
    shiftRequests,
    fulfillmentCount,
    fulltimeCount,
    isIncident,
    onFullTimeManageOpen,
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    placement: "left",
    onOpenChange: setIsOpen,
    middleware: [flip(), shift(), offset(12)],
  });
  const hover = useHover(context, {
    handleClose: safePolygon(),
  });
  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  const handleClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  }, []);

  return (
    <div
      className="relative"
      onClick={handleClick}
      ref={refs.setReference}
      {...getReferenceProps()}
    >
      {children}
      {isOpen ? (
        <FloatingPortal>
          <div
            className={cn("z-50", "max-w-sm", "w-94")}
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <div
              className={cn(
                "overflow-hidden",
                "rounded-lg",
                "shadow-lg",
                "ring-1",
                "ring-black/5",
                "p-3",
                "bg-white"
              )}
            >
              <div className={cn("flex", "flex-row", "mb-3")}>
                <div className={cn("grow", "flex", "flex-row", "items-center")}>
                  <HireIcon className={cn("mr-2", "fill-green-600")} />
                  <p className={cn("text-green-600", "text-xs")}>
                    <Trans i18nKey="shiftDetail.requested.status.hired" />
                    {isIncident ? (
                      <span>
                        {": "}
                        <Trans i18nKey="shiftList.table.incident.hireStatus.partTime" />
                      </span>
                    ) : null}
                  </p>
                </div>
                <ProgressBar
                  max={fulfillmentCount}
                  count={shiftRequests.length}
                  barSize="s"
                />
              </div>
              {shiftRequests.length > 0 ? (
                <div
                  className={cn(
                    "max-h-149",
                    "rounded-lg",
                    "border-black/10",
                    "overflow-y-auto"
                  )}
                >
                  {shiftRequests.map((req) => (
                    <HireStatusRow
                      key={`${req.shiftId}_${req.ptUserId}`}
                      workingStationShortCode={workingStationShortCode}
                      user={req.ptUser}
                      shiftRequest={req}
                      shouldShowAction={false}
                      isIncident={isIncident}
                    />
                  ))}
                </div>
              ) : (
                <p
                  className={cn(
                    "text-gray-400",
                    "font-normal",
                    "text-center",
                    "mt-5",
                    "mb-3"
                  )}
                >
                  <Trans i18nKey="shiftDetail.hire.noHiredUser" />
                </p>
              )}
              {isIncident ? (
                <FullTimeSection
                  shiftId={shiftId}
                  isOpen={isOpen}
                  fulltimeCount={fulltimeCount}
                  onFullTimeManageOpen={onFullTimeManageOpen}
                />
              ) : null}
            </div>
          </div>
        </FloatingPortal>
      ) : null}
    </div>
  );
};

export default HireStatusPopup;
