import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { Trans } from "react-i18next";
import cn from "classnames";
import { Modal } from "oneclick-component/src/components/Modal";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import {
  MeUser,
  Station,
  useSwitchProfileHandlerMeSwitchProfilePostMutation as useSwitchStationProfile,
} from "oneclick-component/src/store/apis/enhancedApi";
import { Button } from "oneclick-component/src/components/Button";
import { RemoveIcon } from "oneclick-component/src/icon";
import { RadioInput } from "oneclick-component/src/components/inputs/RadioInput";
import { RadioGroup } from "@headlessui/react";
import { useDispatch } from "react-redux";
import { localizeString } from "oneclick-component/src/utils/localize";
import { useShowError } from "../../hooks/useShowError";
import { meUserUpdated } from "../../store/auth";
import { useSelectedStationProfile } from "../../hooks/useSelectedStationProfile";

interface SwitchStationProfileDialogProps {
  meUser: MeUser;
  isOpen: boolean;
  onClose: () => void;
  onRemoveClick: (stationId: Station) => void;
}

export const SwitchStationProfileDialog = (
  props: SwitchStationProfileDialogProps
): ReactElement => {
  const { meUser, isOpen, onClose, onRemoveClick } = props;
  const dispatch = useDispatch();
  const selectedStationProfile = useSelectedStationProfile();
  const [switchStationProfile, { isLoading }] = useSwitchStationProfile();
  const { showError } = useShowError();

  const stationOptions = useMemo(() => {
    return meUser.stations.map((s) => ({
      key: s.id,
      station: s,
    }));
  }, [meUser.stations]);

  const initialStationOption = useMemo(() => {
    return selectedStationProfile != null
      ? stationOptions.find(
          (op) => op.station.id === selectedStationProfile.station.id
        ) ?? null
      : null;
  }, [selectedStationProfile, stationOptions]);

  const [selectedStationOption, setSelectedStationOption] =
    useState(initialStationOption);
  useEffect(() => {
    setSelectedStationOption(initialStationOption);
  }, [initialStationOption]);

  const disableConfirm = selectedStationOption == null;

  const onConfirmClick = useCallback(() => {
    if (selectedStationOption == null) {
      return;
    }
    switchStationProfile({
      meSwitchProfileRequest: {
        stationId: selectedStationOption.station.id,
      },
    })
      .unwrap()
      .then((data) => {
        dispatch(meUserUpdated({ meUser: data.me }));
        window.location.reload();
      })
      .catch((err: unknown) => {
        showError(err);
      });
  }, [selectedStationOption, showError, dispatch, switchStationProfile]);

  const onLeaveClick = useCallback(
    (station: Station) => {
      return () => {
        onRemoveClick(station);
      };
    },
    [onRemoveClick]
  );

  return (
    <Modal onClose={onClose} isOpen={isOpen} hasXMarkButton={false}>
      <h3
        className={cn(
          "mb-5",
          "text-black",
          "text-lg",
          "font-medium",
          "flex",
          "flex-row",
          "items-center",
          "leading-7"
        )}
      >
        <ExclamationTriangleIcon
          className={cn("w-6", "h-6", "mr-2", "text-black")}
        />
        <Trans i18nKey="switchStationDialog.title" />
      </h3>
      <div className={cn("w-full", "max-w-md")}>
        <RadioGroup
          value={selectedStationOption}
          onChange={setSelectedStationOption}
        >
          {stationOptions.map((op) => (
            <div
              key={op.key}
              className={cn("grid", "grid-cols-3", "items-center")}
            >
              <RadioGroup.Option key={op.key} value={op}>
                {({ active, checked }) => (
                  <RadioInput
                    className={cn("px-4", "py-3", "col-span-3")}
                    active={active}
                    checked={checked}
                    label={
                      <p className={cn("text-sm", "text-black/86")}>
                        {op.station.shortCode} {localizeString(op.station.name)}
                      </p>
                    }
                  />
                )}
              </RadioGroup.Option>
              <div>
                <button
                  className={cn(
                    "flex",
                    "flex-row",
                    "items-center",
                    "px-2.5",
                    "bg-gray-100",
                    "rounded-xl",
                    "h-5"
                  )}
                  type="button"
                  onClick={onLeaveClick(op.station)}
                >
                  <RemoveIcon className={cn("w-4", "h-4", "text-gray-400")} />
                  <span
                    className={cn(
                      "text-xs",
                      "font-medium",
                      "text-left",
                      "text-gray-400"
                    )}
                  >
                    <Trans i18nKey="menu.switchStation.remove" />
                  </span>
                </button>
              </div>
              <div />
            </div>
          ))}
        </RadioGroup>
      </div>
      <div
        className={cn(
          "mt-5",
          "flex",
          "flex-row",
          "gap-3",
          "justify-center",
          "sm:justify-end",
          "items-center"
        )}
      >
        <Button
          className={cn("font-medium", "flex-1", "sm:flex-none")}
          theme="white"
          onClick={onClose}
          disabled={isLoading}
        >
          <Trans i18nKey="switchStationDialog.back" />
        </Button>
        <Button
          className={cn("font-medium", "flex-1", "sm:flex-none")}
          theme="primary"
          onClick={onConfirmClick}
          disabled={disableConfirm || isLoading}
        >
          <Trans i18nKey="switchStationDialog.remove" />
        </Button>
      </div>
    </Modal>
  );
};
