import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { ShiftType } from "oneclick-component/src/store/apis/enhancedApi";
import { ShiftDateRangeFilter } from "../screen/ShiftListScreen/DateRangeFilter/model";

export interface SaveShiftListDateFilterPayload {
  filter: ShiftDateRangeFilter;
}

export interface ActiveShiftTabFilterState {
  dateRangeFilter?: ShiftDateRangeFilter;
  ptUserRoleFilter?: string | null;
  shiftRateFilter?: number | null;
  searchStringFilter?: string;
  workingStationId?: number | null;
}

export interface ExpiredShiftTabFilterState {
  dateRangeFilter?: ShiftDateRangeFilter;
  ptUserRoleFilter?: string | null;
  shiftRateFilter?: number | null;
  shiftTypeFilter?: ShiftType | null;
  searchStringFilter?: string;
  workingStationId?: number | null;
}

export interface CancelledShiftTabFilterState {
  dateRangeFilter?: ShiftDateRangeFilter;
  ptUserRoleFilter?: string | null;
  shiftRateFilter?: number | null;
  shiftTypeFilter?: ShiftType | null;
  searchStringFilter?: string;
  workingStationId?: number | null;
}

export interface IncomingPtTabFilterState {
  shiftDateRange?: ShiftDateRangeFilter;
  ptRole?: string | null;
  shiftRate?: number | null;
  searchString?: string;
  ptStationId?: number | null;
  workingStationId?: number | null;
}

export interface OutgoingPtTabFilterState {
  shiftDateRange?: ShiftDateRangeFilter;
  ptRole?: string | null;
  shiftRate?: number | null;
  searchString?: string;
  ptStationId?: number | null;
  workingStationId?: number | null;
}

export interface FilterState {
  activeShiftTabFilter?: ActiveShiftTabFilterState;
  expiredShiftTabFilter?: ExpiredShiftTabFilterState;
  cancelledShiftTabFilter?: CancelledShiftTabFilterState;
  incomingPtTabFilter?: IncomingPtTabFilterState;
  outgoingPtTabFilter?: OutgoingPtTabFilterState;
}

export const initialState: FilterState = {
  activeShiftTabFilter: undefined,
  expiredShiftTabFilter: undefined,
  cancelledShiftTabFilter: undefined,
  incomingPtTabFilter: undefined,
  outgoingPtTabFilter: undefined,
};

const filterStateSlice = createSlice({
  name: "filterState",
  initialState,
  reducers: {
    saveActiveShiftListFilter: (
      state,
      payload: PayloadAction<ActiveShiftTabFilterState>
    ) => {
      return {
        ...state,
        activeShiftTabFilter: {
          ...state.activeShiftTabFilter,
          ...payload.payload,
        },
      };
    },
    saveExpiredShiftListFilter: (
      state,
      payload: PayloadAction<ExpiredShiftTabFilterState>
    ) => {
      return {
        ...state,
        expiredShiftTabFilter: {
          ...state.expiredShiftTabFilter,
          ...payload.payload,
        },
      };
    },
    saveCancelledShiftListFilter: (
      state,
      payload: PayloadAction<CancelledShiftTabFilterState>
    ) => {
      return {
        ...state,
        cancelledShiftTabFilter: {
          ...state.cancelledShiftTabFilter,
          ...payload.payload,
        },
      };
    },
    saveIncomingPtTabFilter: (
      state,
      payload: PayloadAction<IncomingPtTabFilterState>
    ) => {
      return {
        ...state,
        incomingPtTabFilter: {
          ...state.incomingPtTabFilter,
          ...payload.payload,
        },
      };
    },
    saveOutgoingPtTabFilter: (
      state,
      payload: PayloadAction<OutgoingPtTabFilterState>
    ) => {
      return {
        ...state,
        outgoingPtTabFilter: {
          ...state.outgoingPtTabFilter,
          ...payload.payload,
        },
      };
    },
    resetFilter: () => {
      return initialState;
    },
  },
});

export const {
  saveActiveShiftListFilter,
  saveExpiredShiftListFilter,
  saveCancelledShiftListFilter,
  saveIncomingPtTabFilter,
  saveOutgoingPtTabFilter,
  resetFilter,
} = filterStateSlice.actions;

export default filterStateSlice.reducer;
