import { Menu, Transition } from "@headlessui/react";
import { Fragment, ReactElement } from "react";
import cn from "classnames";
import { PencilAltEditIcon, MoreIcon, MinusCircleIcon } from "../../../../icon";
import { Trans } from "react-i18next";
import { PaperAirplaneIcon } from "oneclick-component/src/icon";

interface ActivePTUserMoreButtonProps {
  showScheduleSuspendButton: boolean;
  showSendLoginLink: boolean;
  onClickEdit: () => void;
  onClickScheduleSuspend: () => void;
  onClickSendLoginLink: () => void;
}

const ActivePTUserMoreButton = (
  props: ActivePTUserMoreButtonProps
): ReactElement => {
  const {
    showSendLoginLink,
    showScheduleSuspendButton,
    onClickEdit,
    onClickScheduleSuspend,
    onClickSendLoginLink,
  } = props;

  return (
    <Menu as="div">
      <Menu.Button
        className={cn(
          "h-full",
          "px-1.5",
          "rounded-md",
          "hover:bg-gray-50",
          "focus:ring-offset-white",
          "focus:ring-indigo-500"
        )}
      >
        <MoreIcon className="fill-gray-500" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={cn(
            "absolute",
            "top-10",
            "right-0",
            "w-30",
            "sm:w-56",
            "origin-top-right",
            "rounded-md",
            "bg-white",
            "shadow-lg",
            "ring-1",
            "ring-black/5",
            "focus:outline-none"
          )}
        >
          <div className={cn("px-1", "py-1")}>
            <Menu.Item>
              {({ active }) => (
                <button
                  type="button"
                  className={cn(
                    active ? "bg-gray-50" : "text-gray-700",
                    "flex",
                    "w-full",
                    "items-center",
                    "space-x-3",
                    "rounded-md",
                    "px-4",
                    "py-2",
                    "text-sm"
                  )}
                  onClick={onClickEdit}
                >
                  <PencilAltEditIcon
                    className={cn("hidden", "sm:block", "fill-gray-400")}
                  />
                  <p className={cn("text-sm", "font-medium", "text-gray-700")}>
                    <Trans i18nKey="partTime.list.tab.availability.header.moreButton.dialog.edit" />
                  </p>
                </button>
              )}
            </Menu.Item>
            {showSendLoginLink ? (
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    className={cn(
                      active ? "bg-gray-50" : "text-gray-700",
                      "flex",
                      "w-full",
                      "items-center",
                      "space-x-3",
                      "rounded-md",
                      "px-4",
                      "py-2",
                      "text-sm"
                    )}
                    onClick={onClickSendLoginLink}
                  >
                    <PaperAirplaneIcon className={cn("hidden", "sm:block")} />
                    <p
                      className={cn("text-sm", "font-medium", "text-gray-700")}
                    >
                      <Trans i18nKey="partTime.list.tab.availability.header.moreButton.dialog.sendLoginLink" />
                    </p>
                  </button>
                )}
              </Menu.Item>
            ) : null}
            {showScheduleSuspendButton ? (
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    className={cn(
                      active ? "bg-gray-50" : "text-red-600",
                      "flex",
                      "w-full",
                      "items-center",
                      "space-x-3",
                      "rounded-md",
                      "px-4",
                      "py-2",
                      "text-sm"
                    )}
                    onClick={onClickScheduleSuspend}
                  >
                    <MinusCircleIcon
                      className={cn("hidden", "sm:block", "stroke-red-600")}
                    />
                    <p
                      className={cn(
                        "text-sm",
                        "font-medium",
                        "text-red-600",
                        "text-left"
                      )}
                    >
                      <Trans i18nKey="partTime.list.tab.availability.header.moreButton.dialog.delete" />
                    </p>
                  </button>
                )}
              </Menu.Item>
            ) : null}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default ActivePTUserMoreButton;
