import { useCallback, ReactNode, ReactElement } from "react";
import toast from "react-hot-toast";
import {
  CheckCircleIcon,
  InformationCircleIcon,
  ExclamationTriangleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/20/solid";
import cn from "classnames";

interface ToastOptions {
  title?: string;
  message?: string;
  type: "success" | "fail" | "warning" | "info";
  showDismiss?: boolean;
  toastIcon?: ReactElement;
  duration?: number;
}

type ReturnType = (options: ToastOptions) => string;
const useShowMessage = (): ReturnType => {
  const showMessage = useCallback((options: ToastOptions): string => {
    let icon: ReactNode = null;

    switch (options.type) {
      case "success":
        icon = (
          <CheckCircleIcon
            className={cn("h-6", "w-6", "text-green-400")}
            aria-hidden="true"
          />
        );
        break;
      case "fail":
        icon = (
          <XCircleIcon
            className={cn("h-6", "w-6", "text-red-400")}
            aria-hidden="true"
          />
        );
        break;
      case "warning":
        icon = (
          <ExclamationTriangleIcon
            className={cn("h-6", "w-6", "text-yellow-400")}
            aria-hidden="true"
          />
        );
        break;
      case "info":
        icon = (
          <InformationCircleIcon
            className={cn("h-6", "w-6", "text-gray-400")}
            aria-hidden="true"
          />
        );
        break;
      default:
    }

    return toast.custom(
      (t) => (
        <div
          aria-live="assertive"
          className={cn(
            "pointer-events-none",
            "inset-0",
            "flex",
            "w-full",
            "items-end",
            "mx-4",
            "my-6",
            "sm:items-start",
            "sm:m-6",
            {
              "animate-toast-enter": t.visible,
              "animate-toast-leave": !t.visible,
            }
          )}
        >
          <div
            className={cn(
              "flex",
              "w-full",
              "flex-col",
              "items-center",
              "sm:items-end"
            )}
          >
            <>
              <div
                className={cn(
                  "pointer-events-auto",
                  "w-full",
                  "max-w-sm",
                  "overflow-hidden",
                  "rounded-lg",
                  "bg-white",
                  "shadow-lg",
                  "ring-1",
                  "ring-black/5"
                )}
              >
                <div className="p-4">
                  <div className={cn("flex", "items-start")}>
                    <div className="shrink-0">{options.toastIcon ?? icon}</div>
                    <div className={cn("ml-3", "w-0", "flex-1", "pt-0.5")}>
                      {options.title ? (
                        <p
                          className={cn(
                            "text-sm",
                            "font-medium",
                            "text-gray-900"
                          )}
                        >
                          {options.title}
                        </p>
                      ) : null}
                      <p
                        className={cn("text-sm", "text-gray-500", {
                          "mt-1": options.title != null,
                        })}
                      >
                        {options.message}
                      </p>
                    </div>
                    {options.showDismiss !== false ? (
                      <div className={cn("ml-4", "flex", "shrink-0")}>
                        <button
                          type="button"
                          className={cn(
                            "inline-flex",
                            "rounded-md",
                            "bg-white",
                            "text-gray-400",
                            "hover:text-gray-500",
                            "focus:outline-none",
                            "focus:ring-2",
                            "focus:ring-primary-500",
                            "focus:ring-offset-2"
                          )}
                          onClick={() => toast.dismiss(t.id)}
                        >
                          <span className="sr-only">Close</span>
                          <XMarkIcon
                            className={cn("h-5", "w-5")}
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      ),
      { duration: options.duration ?? 4000 }
    );
  }, []);

  return showMessage;
};

export default useShowMessage;
