import { ReactElement } from "react";
import { TextInput, TextInputProps } from "../inputs";
import { FieldValues, FieldPath } from "react-hook-form";
import {
  FormField,
  FormFieldControlProps,
  FormFieldContainerProps,
} from "./FormField";

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<FormFieldControlProps<TFieldValues, TName>, "children">,
    Omit<FormFieldContainerProps, "children">,
    Omit<
      TextInputProps,
      keyof FormFieldContainerProps | keyof FormFieldControlProps
    > {
  transform?: (old: string) => string;
}

export function FormTextInput<
  T extends FieldValues,
  S extends FieldPath<T> = FieldPath<T>,
>(props: Props<T, S>): ReactElement {
  const {
    label,
    errorMessage,
    required,
    className,
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control,
    ...rest
  } = props;

  return (
    <FormField.Control
      name={name}
      rules={rules}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      control={control}
    >
      {({ field }) => (
        <FormField.Container
          label={label}
          errorMessage={errorMessage}
          required={required}
          className={className}
        >
          <TextInput
            {...rest}
            {...field}
            onCustomBlur={rest.onBlur}
            hasError={errorMessage != null}
          />
        </FormField.Container>
      )}
    </FormField.Control>
  );
}
