import React, { useEffect } from "react";
import cn from "classnames";
import { useNavigate } from "react-router-dom";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import { useDispatch } from "react-redux";
import { logoutSucceed } from "../store/auth";
import AppRoutes from "../routes/AppRoutes";

const LogoutRedirectScreen = (): React.ReactElement => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(logoutSucceed());
    navigate(AppRoutes.LogInScreen.render());
  }, [dispatch, navigate]);

  return (
    <div
      className={cn(
        "fixed",
        "top-1/2",
        "left-1/2",
        "-translate-x-1/2",
        "-translate-y-1/2"
      )}
    >
      <LoadingSpinner />
    </div>
  );
};

export default LogoutRedirectScreen;
