import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { DateTime } from "luxon";
import cn from "classnames";

import {
  MonthPicker,
  SearchInput,
} from "oneclick-component/src/components/inputs";
import {
  dateTimeNow,
  formatDateTime,
} from "oneclick-component/src/utils/datetime";
import {
  WeekOfYear,
  Weekly418Status,
  WorkingStatus,
  GetPtUsersHandlerPartTimeUsersListPostApiArg as GetPtUsersArg,
  useDeletePtUserWorkingStatusRecordHandlerPartTimeUsersPtUserIdWorkingStatusRecordDeleteMutation,
  useGetPtUsersHandlerPartTimeUsersListPostQuery,
  useLazyListPtUserWorkingStatusRecordHandlerPartTimeUsersWorkingStatusRecordsPostQuery,
  useUpsertPtUserWorkingStatusRecordHandlerPartTimeUsersPtUserIdWorkingStatusRecordPostMutation,
  useGetPtUsersRolesHandlerPartTimeUsersRolesGetQuery as useGetPtUserRoles,
  BriefStation,
} from "oneclick-component/src/store/apis/enhancedApi";
import Pagination from "oneclick-component/src/components/Pagination";
import YearMonth from "oneclick-component/src/models/yearMonth";
import { Button } from "oneclick-component/src/components/Button";
import { SearchIcon } from "oneclick-component/src/icon";
import {
  updateByPtUserList,
  updateByPtUser,
  updateWorkingStatus,
} from "oneclick-component/src/store/apis/states/ptUserWorkingStatusesMapState";
import { DEFAULT_PAGE_SIZE } from "../../../constants/pagination";
import { NUM_WEEK_PER_USER_CARD } from "../../../constants/partTime418";
import { useShowError } from "../../../hooks/useShowError";
import MobileSearchView from "../../../components/MobileSearchView";
import { useSelectedStationProfile } from "../../../hooks/useSelectedStationProfile";
import { useIsManager } from "../../../hooks/role";
import Legend418 from "./Legend418";
import PartTimeListView418 from "./PartTimeListView418";
import {
  getStationFilterFromFilterAndProfile,
  PtUserListFilter,
} from "../type";
import { StationFilter } from "../../../components/StationFilter";

export const PartTimeListWorkStatus418Tab = (): ReactElement => {
  const isManager = useIsManager();
  const { data: ptUserRolesResponse } = useGetPtUserRoles();
  const selectedStationProfile = useSelectedStationProfile();
  const { t } = useTranslation();

  const [ptUserListFilter, setPtUserListFilter] = useState<PtUserListFilter>({
    searchString: undefined,
    pageIndex: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    sort: undefined,
    stationFilter: undefined,
  });

  const getPtUsersArgs = useMemo<GetPtUsersArg>(() => {
    return {
      searchString: ptUserListFilter.searchString,
      pageIndex: ptUserListFilter.pageIndex,
      pageSize: ptUserListFilter.pageSize,
      sort: ptUserListFilter.sort,
      getPtUsersRequest: {
        stationIds: getStationFilterFromFilterAndProfile(
          selectedStationProfile,
          ptUserListFilter
        ),
        showDeleted: false,
        // no need to include CCO user in 418 status
        ptRoleIds: ptUserRolesResponse?.ptUserRoles
          .filter((role) => role.name !== "CCO")
          .map((role) => role.id),
      },
    };
  }, [ptUserListFilter, selectedStationProfile, ptUserRolesResponse]);

  const onChangeInput = useCallback((v: string) => {
    const trimmedSearchString: string = v.trim();
    const searchString: string | undefined =
      trimmedSearchString === "" ? undefined : trimmedSearchString;
    setPtUserListFilter((prev) => ({ ...prev, searchString, pageIndex: 0 }));
  }, []);

  const { showError } = useShowError();

  const [ptUserRowWeekOfYearsMap, setPtUserRowWeekOfYearMap] = useState<
    Record<number, WeekOfYear | undefined>
  >({});

  const [selectedYearMonth, setSelectedYearMonth] = useState<YearMonth>({
    year: dateTimeNow().year,
    month: dateTimeNow().month,
  });
  const firstWeekOfSelectedMonth = useMemo<WeekOfYear>(() => {
    const secondDayOfSelectedMonth = DateTime.fromObject({
      ...selectedYearMonth,
      day: 2, // Note(peter)#555: Since iso week numbers starts at Monday, need to shift 1 day to prevent cases where first day of month is the ending sunday of a week
    });
    return {
      year: secondDayOfSelectedMonth.weekYear,
      weekNumber: secondDayOfSelectedMonth.weekNumber,
    };
  }, [selectedYearMonth]);

  const onChangeMonth = useCallback((selectedYearMonth: YearMonth) => {
    setSelectedYearMonth(selectedYearMonth);
    setTimeout(() => setPtUserRowWeekOfYearMap({}), 0);
  }, []);

  const {
    data: ptUsersResponse,
    isLoading: isListPTUserLoading,
    isFetching: isListPTUserFetching,
  } = useGetPtUsersHandlerPartTimeUsersListPostQuery(getPtUsersArgs, {
    refetchOnMountOrArgChange: true,
  });
  const ptUsers = useMemo(
    () => ptUsersResponse?.results ?? [],
    [ptUsersResponse]
  );

  const [getPtUserWorkingStatuses] =
    useLazyListPtUserWorkingStatusRecordHandlerPartTimeUsersWorkingStatusRecordsPostQuery();

  const [upsertWorkingStatus] =
    useUpsertPtUserWorkingStatusRecordHandlerPartTimeUsersPtUserIdWorkingStatusRecordPostMutation();
  const [deleteWorkingStatus] =
    useDeletePtUserWorkingStatusRecordHandlerPartTimeUsersPtUserIdWorkingStatusRecordDeleteMutation();

  const dispatch = useDispatch();

  useEffect(() => {
    if (ptUsersResponse == null) {
      return;
    }
    const weekOfYears: WeekOfYear[] = Array(NUM_WEEK_PER_USER_CARD)
      .fill(0)
      .map((_, i) => ({
        year: firstWeekOfSelectedMonth.year + i,
        weekNumber: firstWeekOfSelectedMonth.weekNumber + i,
      }));
    const ptUserIds: number[] = ptUsersResponse.results.map((pt) => pt.id);
    dispatch(
      updateByPtUserList({
        items: ptUserIds.map((ptUserId) => ({ ptUserId, weekOfYears })),
        queryResultState: "loading",
      })
    );
    getPtUserWorkingStatuses({
      listPtUserWorkingStatusRecordRequest: {
        ptUserIds,
        offsetDirection: "after",
        numWeekOfYears: NUM_WEEK_PER_USER_CARD,
        anchorWeekOfYear: firstWeekOfSelectedMonth,
      },
    })
      .unwrap()
      .then((data) => {
        setPtUserRowWeekOfYearMap({});
        dispatch(updateByPtUserList({ ...data, queryResultState: "success" }));
      })
      .catch((err) => {
        console.error(err);
        showError(err);
        dispatch(
          updateByPtUserList({
            items: ptUserIds.map((ptUserId) => ({ ptUserId, weekOfYears })),
            queryResultState: "error",
          })
        );
      });
  }, [
    getPtUserWorkingStatuses,
    showError,
    ptUsersResponse,
    firstWeekOfSelectedMonth,
    dispatch,
  ]);

  const onChangeRowWeek = useCallback(
    (ptUserId: number, woy: WeekOfYear) => {
      const weekOfYears: WeekOfYear[] = Array(NUM_WEEK_PER_USER_CARD)
        .fill(0)
        .map((_, i) => ({
          year: woy.year + i,
          weekNumber: woy.weekNumber + i,
        }));
      dispatch(
        updateByPtUser({
          ptUserId,
          weekOfYears,
          queryResultState: "loading",
        })
      );
      setPtUserRowWeekOfYearMap((prev) => ({ ...prev, [ptUserId]: woy }));
      getPtUserWorkingStatuses({
        listPtUserWorkingStatusRecordRequest: {
          ptUserIds: [ptUserId],
          offsetDirection: "after",
          numWeekOfYears: NUM_WEEK_PER_USER_CARD,
          anchorWeekOfYear: woy,
        },
      })
        .unwrap()
        .then((data) => {
          dispatch(
            updateByPtUser({ ...data.items[0], queryResultState: "success" })
          );
        })
        .catch((err) => {
          console.error(err);
          dispatch(
            updateByPtUser({
              ptUserId,
              weekOfYears,
              queryResultState: "error",
            })
          );
          showError(err);
        });
    },
    [getPtUserWorkingStatuses, showError, dispatch]
  );

  const onChangeRowWorkingStatus = useCallback(
    (
      ptUserId: number,
      woy: WeekOfYear,
      workingStatus: WorkingStatus | null,
      existingWorkingStatus: WorkingStatus | null,
      existing418Status: Weekly418Status
    ) => {
      if (workingStatus == null) {
        // optimistic update
        dispatch(
          updateWorkingStatus({
            ptUserId,
            workingStatusRecord: {
              ...woy,
              weekly418Status: existing418Status,
              workingStatus,
            },
          })
        );

        // api call
        deleteWorkingStatus({ ptUserId, ...woy })
          .unwrap()
          .catch((err) => {
            console.error(err);
            showError(
              err,
              t("partTime.list.tab.workStatus418.toast.fail.title")
            );
            dispatch(
              updateWorkingStatus({
                ptUserId,
                workingStatusRecord: {
                  ...woy,
                  weekly418Status: existing418Status,
                  workingStatus: existingWorkingStatus,
                },
              })
            );
          });
        return;
      }
      // optimistic update
      dispatch(
        updateWorkingStatus({
          ptUserId,
          workingStatusRecord: {
            ...woy,
            weekly418Status: existing418Status,
            workingStatus,
          },
        })
      );

      // api call
      upsertWorkingStatus({
        ptUserId,
        upsertPtUserWorkingStatusRecordRequest: {
          weekOfYear: woy,
          status: workingStatus,
        },
      })
        .unwrap()
        .catch((err) => {
          console.error(err);
          showError(err, t("partTime.list.tab.workStatus418.toast.fail.title"));
          dispatch(
            updateWorkingStatus({
              ptUserId,
              workingStatusRecord: {
                ...woy,
                weekly418Status: existing418Status,
                workingStatus: existingWorkingStatus,
              },
            })
          );
        });
    },
    [deleteWorkingStatus, upsertWorkingStatus, dispatch, t, showError]
  );

  const [isMobileSearchView, setIsMobileSearchView] = useState<boolean>(false);

  const onEnterMobileSearchView = useCallback(
    () => setIsMobileSearchView(true),
    []
  );
  const onExitMobileSearchView = useCallback(
    () => setIsMobileSearchView(false),
    []
  );

  const onClickPage = useCallback((pageIndex: number) => {
    setPtUserListFilter((prev) => ({ ...prev, pageIndex }));
  }, []);

  const onStationFilterChange = useCallback((station: BriefStation | null) => {
    setPtUserListFilter((prev) => ({
      ...prev,
      pageIndex: 0,
      stationFilter: station?.id,
    }));
  }, []);

  const totalPages = ptUsersResponse?.totalCount
    ? Math.ceil(ptUsersResponse.totalCount / ptUserListFilter.pageSize)
    : 0;

  const showStationFilter = !isManager;

  const contentBelowSearchBar = useMemo(
    () => (
      <>
        <div className={cn("sm:flex", "sm:justify-end")}>
          <Legend418 />
        </div>
        <PartTimeListView418
          ptUsers={ptUsers}
          ptUserRowWeekOfYearsMap={ptUserRowWeekOfYearsMap}
          firstWeekOfSelectedMonth={firstWeekOfSelectedMonth}
          onChangeRowWeek={onChangeRowWeek}
          onChangeRowWorkingStatus={onChangeRowWorkingStatus}
          isLoading={isListPTUserLoading || isListPTUserFetching}
        />
        <Pagination
          className={cn(
            "mt-3",
            "bg-white",
            "fixed",
            "sm:absolute",
            "bottom-0",
            "inset-x-0"
          )}
          totalPages={totalPages}
          pageIndex={ptUserListFilter.pageIndex}
          onClickPage={onClickPage}
        />
      </>
    ),
    [
      firstWeekOfSelectedMonth,
      ptUserListFilter.pageIndex,
      isListPTUserFetching,
      isListPTUserLoading,
      onChangeRowWeek,
      onChangeRowWorkingStatus,
      onClickPage,
      ptUserRowWeekOfYearsMap,
      ptUsers,
      totalPages,
    ]
  );

  if (isMobileSearchView) {
    return (
      <MobileSearchView
        onChangeSearchInput={onChangeInput}
        showStationFilter={showStationFilter}
        stationFilter={ptUserListFilter.stationFilter}
        onStationFilterChange={onStationFilterChange}
        onExit={onExitMobileSearchView}
      >
        <MonthPicker
          className="flex-1"
          onChange={onChangeMonth}
          value={selectedYearMonth}
          formattedValue={formatDateTime(
            DateTime.fromObject(selectedYearMonth),
            t("common.monthWithYear.displayformat")
          )}
        />
        {contentBelowSearchBar}
      </MobileSearchView>
    );
  }
  return (
    <div
      className={cn(
        "gap-y-3",
        "sm:gap-y-5",
        "pb-24",
        "relative",
        "flex",
        "flex-col"
      )}
    >
      <div className={cn("gap-x-3", "flex")}>
        <Button
          className={cn("block", "sm:hidden", "!px-3", "!py-2.75")}
          theme="white"
          onClick={onEnterMobileSearchView}
        >
          <SearchIcon className={cn("w-5", "h-5", "fill-gray-400")} />
        </Button>
        <SearchInput
          className={cn("flex-1", "min-w-0", "hidden", "sm:flex")}
          onChange={onChangeInput}
        />
        {showStationFilter ? (
          <StationFilter
            className="flex-1"
            selectedStationId={ptUserListFilter.stationFilter ?? null}
            onStationSelected={onStationFilterChange}
          />
        ) : null}
        <MonthPicker
          className="flex-1"
          onChange={onChangeMonth}
          value={selectedYearMonth}
          formattedValue={formatDateTime(
            DateTime.fromObject(selectedYearMonth),
            t("common.monthWithYear.displayformat")
          )}
        />
      </div>
      {contentBelowSearchBar}
    </div>
  );
};
