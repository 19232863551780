import React, { useCallback } from "react";
import cn from "classnames";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import {
  PtUser,
  WeekOfYear,
  Weekly418Status,
  WorkingStatus,
} from "oneclick-component/src/store/apis/enhancedApi";
import WorkingStatusCard from "./WorkingStatusCard";
import { Trans } from "react-i18next";
import { Button } from "oneclick-component/src/components/Button";

export type QueryStatus = "success" | "loading" | "error" | "idle";

interface RowViewProps {
  ptUser: PtUser;
  onChangeWorkingStatus: (
    ptUserId: number,
    woy: WeekOfYear,
    workingStatus: WorkingStatus | null,
    existingWorkingStatus: WorkingStatus | null,
    existing418Status: Weekly418Status
  ) => void;
  weekOfYears: WeekOfYear[];
  queryStatus: QueryStatus;
  className?: string;
}
const PartTimeUser418CardWorkingStatusRowView = (
  props: RowViewProps
): React.ReactElement => {
  const { ptUser, onChangeWorkingStatus, weekOfYears, queryStatus, className } =
    props;

  const onRetry = useCallback(() => window.location.reload(), []);

  if (queryStatus === "error") {
    return (
      <div className={cn("px-4", "text-center", "!block", className)}>
        <span className={cn("block", "mb-3", "text-lg", "text-black")}>
          <Trans i18nKey="errorScreen.title" />
        </span>
        <span
          className={cn(
            "block",
            "mb-3",
            "text-sm",
            "text-black/60",
            "font-medium"
          )}
        >
          <Trans i18nKey="errorScreen.desc" />
        </span>
        <Button onClick={onRetry} className="block">
          <Trans i18nKey="errorScreen.retryButton.text" />
        </Button>
      </div>
    );
  }
  if (queryStatus === "loading") {
    return (
      <div
        className={cn(
          "relative",
          "flex",
          "items-center",
          "justify-center",
          className
        )}
      >
        <LoadingSpinner size="l" />
      </div>
    );
  }

  return (
    <div className={cn("space-x-3", "overflow-x-auto", "flex", className)}>
      {weekOfYears.map(({ year, weekNumber }) => (
        <WorkingStatusCard
          key={`${year}-${weekNumber}`}
          ptUserId={ptUser.id}
          onChangeWorkingStatus={onChangeWorkingStatus}
          weekYear={year}
          weekNumber={weekNumber}
          className={cn("grow", "shrink-0")}
        />
      ))}
    </div>
  );
};

export default PartTimeUser418CardWorkingStatusRowView;
