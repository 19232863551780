import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Option } from "oneclick-component/src/components/inputs";
import { ShiftType } from "oneclick-component/src/store/apis/enhancedApi";

export function useShiftTypeFilterOptions(): Option<ShiftType>[] {
  const { t } = useTranslation();

  const shiftTypeFilterOptions = useMemo<Option<ShiftType>[]>(() => {
    return [
      {
        name: t("shiftList.filter.shiftType.regular"),
        value: "REGULAR",
      },
      {
        name: t("shiftList.filter.shiftType.incident"),
        value: "INCIDENT",
      },
    ];
  }, [t]);

  return shiftTypeFilterOptions;
}
