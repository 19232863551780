import { ReactElement } from "react";
import { LoadingSpinner } from "../LoadingSpinner";
import cn from "classnames";

// Note(peter): This is not loading overlay, but used as a screen

interface Props {
  className?: string;
}

const LoadingScreen = (props: Props): ReactElement => {
  const { className } = props;
  return (
    <div
      className={cn(
        "bg-white",
        "w-full",
        "h-full",
        "flex",
        "items-center",
        "justify-center",
        "py-10",
        className
      )}
    >
      <LoadingSpinner size="l" />
    </div>
  );
};

export default LoadingScreen;
