import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { PlayIcon, VideoCameraIcon } from "@heroicons/react/24/solid";
import cn from "classnames";

import { Message } from "oneclick-component/src/store/apis/enhancedApi";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";

import { isIOS, isSafari } from "../../../../utils/userAgent";
import { VideoViewer } from "./VideoViewer";

interface VideoPreviewViewStateLoading {
  state: "loading";
}
interface VideoPreviewViewStateLoaded {
  state: "loaded";
  height: number;
  width: number;
}
interface VideoPreviewViewStateError {
  state: "error";
}
type VideoPreviewViewState =
  | VideoPreviewViewStateLoading
  | VideoPreviewViewStateLoaded
  | VideoPreviewViewStateError;

interface Props {
  message: Message;
}

export const MessageBubbleVideoContent = React.memo(
  (props: Props): ReactElement => {
    const { message } = props;

    const [viewState, setViewState] = useState<VideoPreviewViewState>({
      state: "loading",
    });
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const videoSource = useMemo(() => {
      const attachment = message.attachments[0];
      return attachment.asset.url ?? "#";
    }, [message.attachments]);

    const _videoSource = useMemo(() => {
      if (isIOS() || isSafari()) {
        // NOTE: for iOS, force preload first frame
        return `${videoSource}#t=0.001`;
      }
      return videoSource;
    }, [videoSource]);

    const onLoadStart = useCallback(() => {
      setViewState({ state: "loading" });
    }, []);

    const onError = useCallback(() => {
      setViewState({ state: "error" });
    }, []);

    const onLoaded = useCallback(
      (ev: React.SyntheticEvent<HTMLVideoElement>) => {
        const { videoWidth, videoHeight } = ev.currentTarget;
        setViewState({
          state: "loaded",
          width: videoWidth,
          height: videoHeight,
        });
      },
      []
    );

    const openVideoViewer = useCallback(() => {
      setIsViewerOpen(true);
    }, []);

    const closeVideoViewer = useCallback(() => {
      setIsViewerOpen(false);
    }, []);

    return (
      <div>
        <div
          className={cn(
            "relative",
            "overflow-hidden",
            "rounded-lg",
            "max-w-full",
            "max-h-[31.25rem]",
            "mx-auto",
            {
              block: viewState.state === "loaded",
              hidden: viewState.state !== "loaded",
            }
          )}
        >
          {/* NOTE: only used for show first frame of video */}
          <video
            className={cn("w-full", "h-full")}
            preload="metadata"
            src={_videoSource}
            controls={false}
            onError={onError}
            onLoadedMetadata={onLoaded}
            onLoadStart={onLoadStart}
          />
          <button
            className={cn(
              "absolute",
              "top-0",
              "bottom-0",
              "left-0",
              "right-0",
              "flex",
              "z-[1]",
              "items-center",
              "justify-center"
            )}
            style={{
              // Workaround Firefox absolute fill not filling parent
              width: "-moz-available",
            }}
            type="button"
            onClick={openVideoViewer}
          >
            <div
              className={cn(
                "rounded-full",
                "overflow-hidden",
                "w-9.5",
                "h-9.5",
                "flex",
                "items-center",
                "justify-center",
                "border",
                "border-white",
                "bg-black/60"
              )}
            >
              <PlayIcon className={cn("w-5", "h-5", "text-white")} />
            </div>
          </button>
        </div>
        {viewState.state === "loading" ? (
          <div
            className={cn(
              "rounded-lg",
              "w-50",
              "h-50",
              "flex",
              "items-center",
              "justify-center",
              "bg-gray-300"
            )}
          >
            <LoadingSpinner size="m" />
          </div>
        ) : null}
        {viewState.state === "error" ? (
          <div
            className={cn(
              "rounded-lg",
              "w-50",
              "h-50",
              "flex",
              "items-center",
              "justify-center",
              "bg-gray-300"
            )}
          >
            <VideoCameraIcon className={cn("text-black/80", "p-20")} />
          </div>
        ) : null}
        {message.content ? (
          <span
            className={cn(
              "block",
              "whitespace-pre-wrap",
              "overflow-wrap-anywhere",
              "text-sm",
              "text-left",
              "p-3"
            )}
          >
            {message.content}
          </span>
        ) : null}
        <VideoViewer
          videoSource={_videoSource}
          isOpen={isViewerOpen}
          onClose={closeVideoViewer}
        />
      </div>
    );
  }
);
