import React, { memo, useMemo } from "react";
import {
  Header,
  Table as ReactTable,
  Row,
  RowSelectionState,
} from "@tanstack/react-table";
import { Trans, useTranslation } from "react-i18next";
import cn from "classnames";

import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import {
  TableWithSelect,
  TableWithSelectRow,
} from "oneclick-component/src/components/TableWithSelect";
import useMeUser from "../../../hooks/useMeUser";
import { ListEmptyView } from "../../../components/EmptyView";
import { ActiveShiftListRecord } from "./useActiveShiftListTable";
import { ButtonLink } from "oneclick-component/src/components/Button";
import AppRoutes from "../../../routes/AppRoutes";
import { PlusIcon as SolidPlusIcon } from "@heroicons/react/20/solid";
import { ShiftListGroupHeader } from "../ShiftListGroupHeader";

const RENDER_ROW_MAX_DEPTH = 4;

const renderRow = (
  row: Row<ActiveShiftListRecord>,
  headerColSpan: number,
  onClickTableRowFactory: (shiftId: number) => () => void,
  depth: number = 1
): React.ReactElement | null => {
  // Guard infinite recursion
  if (depth > RENDER_ROW_MAX_DEPTH) {
    console.warn(
      `Exceeded maximum render depth ${RENDER_ROW_MAX_DEPTH} for table grouped items`
    );
    return null;
  }

  // Leaf rows
  if (row.subRows.length === 0) {
    return (
      <TableWithSelectRow
        key={row.id}
        row={row}
        onClick={onClickTableRowFactory(row.getValue("id"))}
        className={cn({ "bg-red-50": row.original.shiftType === "INCIDENT" })}
      />
    );
  }

  // Group header
  return (
    // grouping header row.id  will be sth like "partTimeType:CUCA>date:2023-09-30"
    <React.Fragment key={row.id}>
      <ShiftListGroupHeader row={row} headerColSpan={headerColSpan} />
      {row.subRows.map((r) =>
        renderRow(r, headerColSpan, onClickTableRowFactory, depth + 1)
      )}
    </React.Fragment>
  );
};

interface Props {
  isLoading: boolean;
  isEmpty: boolean;
  onClickTableRowFactory: (shiftId: number) => () => void;
  table: ReactTable<ActiveShiftListRecord>;
  rowSelection: RowSelectionState;
  onClickColumnHeader: (
    colHeader: Header<ActiveShiftListRecord, unknown>
  ) => void;
}
const ActiveShiftTabTable = memo(function ActiveShiftTabTable(
  props: Props
): React.ReactElement {
  const meUser = useMeUser();
  const {
    isLoading,
    isEmpty,
    onClickTableRowFactory,
    table,
    onClickColumnHeader,
  } = props;
  const { t } = useTranslation();
  const createShiftScreenPath = useMemo<string>(() => {
    return AppRoutes.CreateMultiShiftScreen.render();
  }, []);

  return (
    <TableWithSelect
      table={table}
      onClickColumnHeader={onClickColumnHeader}
      tableClassName={cn("min-w-150", "md:min-w-full")}
    >
      {isLoading ? (
        <tr className={"h-171"}>
          <td colSpan={table.getVisibleFlatColumns().length}>
            <span className={cn("flex", "justify-center", "items-center")}>
              <LoadingSpinner />
            </span>
          </td>
        </tr>
      ) : null}
      {!isLoading && isEmpty ? (
        <tr className={"h-171"}>
          <td colSpan={table.getVisibleFlatColumns().length}>
            <ListEmptyView
              description={t("shiftList.table.emptyState")}
              actionButton={
                meUser?.adminRole === "MANAGER" ? (
                  <ButtonLink
                    to={createShiftScreenPath}
                    prefixIcon={SolidPlusIcon}
                  >
                    <Trans i18nKey="shiftList.tab.button.create" />
                  </ButtonLink>
                ) : (
                  <></>
                )
              }
            />
          </td>
        </tr>
      ) : null}
      {!isEmpty
        ? table.getRowModel().rows.map((row) => {
            return renderRow(
              row,
              table.getVisibleFlatColumns().length,
              onClickTableRowFactory
            );
          })
        : null}
    </TableWithSelect>
  );
});

export default ActiveShiftTabTable;
