import { ShiftRequest } from "oneclick-component/src/store/apis/enhancedApi";
import {
  formatDateTime,
  isoToDateTime,
} from "oneclick-component/src/utils/datetime";
import { makeShiftTimeIntervalDisplayText } from "oneclick-component/src/utils/shift";

export interface OutgoingTabRecord {
  ptEid: string;
  ptNameEn: string;
  ptNameZh: string;
  ptRole: string;
  ptStationShortCode: string;
  shiftWorkingStationShortCode: string;
  shiftTimeDisplayString: string;
  shiftRateCode: string;
  shiftTitle: string;
  shiftDisplayId: string;

  // grouping
  shiftDate: string;
  // pass in model for convenience
  shiftRequestModel: ShiftRequest;
}

export function mapOutgoingTabRecord(
  shiftRequest: ShiftRequest
): OutgoingTabRecord {
  const { ptUser, shift } = shiftRequest;

  const shiftTimeDisplayString =
    shift != null
      ? makeShiftTimeIntervalDisplayText(shift.dutyStartTime, shift.dutyEndTime)
      : "";
  return {
    ptEid: ptUser.eid,
    ptNameEn: `${ptUser.firstNameEn} ${ptUser.lastNameEn}`,
    ptNameZh: ptUser.fullNameZhHk,
    ptRole: ptUser.role?.name ?? "",
    ptStationShortCode: ptUser.station?.shortCode ?? "",
    shiftWorkingStationShortCode: shift?.workingStation.shortCode ?? "",
    shiftTimeDisplayString,
    shiftRateCode: shift?.rate?.code ?? "",
    shiftTitle: shift?.shiftTitle ?? "",
    shiftDisplayId: shift?.displayId ?? "",
    shiftRequestModel: shiftRequest,
    shiftDate:
      shift != null
        ? formatDateTime(isoToDateTime(shift.dutyStartTime), "yyyy-LL-dd")
        : "unknown date",
  };
}
