export class TemplateMissingParameterError extends Error {
  constructor(message: string) {
    super(message);

    // Fix instanceof not working for class extends Error class
    // Ref: https://github.com/microsoft/TypeScript/issues/13965
    Object.setPrototypeOf(this, TemplateMissingParameterError.prototype);
  }
}

const paramRegex = /{{(\w+)}}/g;

// Expect template with placeholder in format {{name}}, parameter name alphanumeric
export function renderTemplateMessage(
  templateBody: string,
  templateParams: Partial<Record<string, string>>
): string {
  let output = "";
  let cursor = 0;
  let match;

  while ((match = paramRegex.exec(templateBody))) {
    output += templateBody.substring(cursor, match.index);

    const paramName = match[1];
    const paramValue = templateParams[paramName];

    if (paramValue == null) {
      throw new TemplateMissingParameterError(`No value for ${paramName}`);
    }

    output += paramValue;

    cursor = match.index + match[0].length;
  }

  output += templateBody.substring(cursor);

  return output;
}
