import i18next, { t } from "i18next";
import { formatDateTime, isoToDateTime } from "./datetime";

type HourDisplay = 12 | 24;

export const makeShiftDisplayInterval = (
  startTime: string,
  endTime: string | null
): [
  string,
  {
    date: string;
    weekday: string;
    dateWithWeekDay: string;
    startTime: string;
    endTime: string;
    overnight: string;
  },
] => {
  const startDateTime = isoToDateTime(startTime);
  const dateWithWeekdayString = formatDateTime(
    startDateTime,
    i18next.t("common.dateWithWeekday.displayFormat")
  );
  const dateString = formatDateTime(
    startDateTime,
    i18next.t("common.date.displayFormat")
  );
  const weekdayString = formatDateTime(
    startDateTime,
    i18next.t("common.weekday.displayFormat")
  );
  const startTimeString = formatDateTime(
    startDateTime,
    i18next.t("common.time.displayFormat")
  );
  if (endTime == null) {
    return [
      `${dateWithWeekdayString} ${startTimeString} - ${i18next.t(
        "common.tbc"
      )}`,
      {
        date: dateString,
        weekday: weekdayString,
        dateWithWeekDay: dateWithWeekdayString,
        startTime: startTimeString,
        endTime: "",
        overnight: "",
      },
    ];
  }
  const endDateTime = isoToDateTime(endTime);
  const endTimeString = formatDateTime(
    endDateTime,
    i18next.t("common.time.displayFormat")
  );

  const overnightString =
    startDateTime.toISODate() !== endDateTime.toISODate()
      ? ` (${t("shift.create.form.endTime.hour.option.overnight")})`
      : "";

  return [
    `${dateWithWeekdayString} ${startTimeString} - ${endTimeString}${overnightString}`,
    {
      date: dateString,
      weekday: weekdayString,
      dateWithWeekDay: dateWithWeekdayString,
      startTime: startTimeString,
      endTime: endTimeString,
      overnight: overnightString,
    },
  ];
};

export const makeShiftTimeIntervalDisplayText = (
  startTime: string,
  endTime: string | null,
  hourDisplay: HourDisplay = 24
): string => {
  const startDateTime = isoToDateTime(startTime);
  const dateTimeFormat =
    hourDisplay === 12
      ? i18next.t("common.12hTime.displayFormat")
      : i18next.t("common.24hTime.displayFormat");
  const startTimeString = formatDateTime(startDateTime, dateTimeFormat);
  if (endTime == null) {
    return `${startTimeString} - ${t("common.tbc")}`;
  }
  const endDateTime = isoToDateTime(endTime);
  const endTimeString = formatDateTime(endDateTime, dateTimeFormat);
  const overnightString =
    startDateTime.toISODate() !== endDateTime.toISODate()
      ? ` (${t("shift.create.form.endTime.hour.option.overnight")})`
      : "";

  return `${startTimeString} - ${endTimeString}${overnightString}`;
};

export const makeIncidentShiftEndTimeDisplayText = (
  startTime: string,
  endTime: string | null,
  hourDisplay: HourDisplay = 24
): string => {
  const startDateTime = isoToDateTime(startTime);
  const dateTimeFormat =
    hourDisplay === 12
      ? i18next.t("common.12hTime.displayFormat")
      : i18next.t("common.24hTime.displayFormat");
  if (endTime == null) {
    return `${t("common.tbc")}`;
  }
  const endDateTime = isoToDateTime(endTime);
  const endTimeString = formatDateTime(endDateTime, dateTimeFormat);
  const overnightString =
    startDateTime.toISODate() !== endDateTime.toISODate()
      ? ` (${t("shift.create.form.endTime.hour.option.overnight")})`
      : "";

  return `${endTimeString}${overnightString} (${t("shiftList.time.tbc")})`;
};
