import React, { PropsWithChildren } from "react";
import { MediaModal } from "./MediaModal";
import cn from "classnames";

export interface ImageViewerProps {
  className?: string;
  onClose: () => void;
  isOpen: boolean;
  imageSource: string;
}

export function ImageViewer(
  props: PropsWithChildren<ImageViewerProps>
): React.ReactElement {
  const { className, onClose, isOpen, imageSource } = props;
  return (
    <MediaModal className={className} onClose={onClose} isOpen={isOpen}>
      <img
        className={cn(
          "max-w-full",
          "max-h-full",
          "object-cover",
          "mx-auto",
          "pt-4",
          "pb-5",
          "px-8"
        )}
        src={imageSource}
      />
    </MediaModal>
  );
}
