import { ApiErrorCode } from "oneclick-component/src/models/error";

// Extend with other error codes
export type ErrorCode = ApiErrorCode;

export enum CreatePTUserAPIValidationError {
  DuplicatePhoneNumber = "DuplicatePhoneNumber",
  DuplicateEid = "DuplicateEid",
  DuplicateEmail = "DuplicateEmail",
  IncorrectPhoneNumberFormat = "IncorrectPhoneNumberFormat",
  NonWhitelistedPhoneNumber = "NonWhitelistedPhoneNumber",
}

export enum UpdatePTUserAPIValidationError {
  DuplicatePhoneNumber = "DuplicatePhoneNumber",
  DuplicateEid = "DuplicateEid",
  DuplicateEmail = "DuplicateEmail",
  IncorrectPhoneNumberFormat = "IncorrectPhoneNumberFormat",
  NonWhitelistedPhoneNumber = "NonWhitelistedPhoneNumber",
}

export enum DeletePTUserAPIError {
  UnfinishedFutureShift = "UnfinishedFutureShift",
}
