import React, { ReactElement, useCallback, useMemo } from "react";
import { Trans } from "react-i18next";
import cn from "classnames";

import { DocumentIcon } from "../../icon";
import { constructFileSizeDisplayString } from "../../utils/file";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";

interface Props {
  className?: string;
  isUploading: boolean;
  attachment: File;
  error?: unknown;
  onAttachmentRemoved: (attachment: File) => void;
}

const AttachmentPreview = React.memo((props: Props): ReactElement => {
  const { className, isUploading, attachment, error, onAttachmentRemoved } =
    props;

  const fileSizeStr = useMemo(() => {
    return constructFileSizeDisplayString(attachment.size);
  }, [attachment.size]);

  const onRemoveClick = useCallback(() => {
    onAttachmentRemoved(attachment);
  }, [attachment, onAttachmentRemoved]);

  return (
    <div
      className={cn(
        "flex",
        "w-full",
        "p-3",
        "items-start",
        "gap-3",
        "rounded-lg",
        "bg-primary-100",
        className
      )}
    >
      <div
        className={cn("w-9.5", "h-9.5", "rounded-lg", "p-2", "bg-primary-600")}
      >
        {isUploading ? (
          <LoadingSpinner
            className={cn("border-white", "border-r-transparent")}
            size="xs"
          />
        ) : (
          <DocumentIcon className={cn("w-5.5", "h-5.5", "fill-white")} />
        )}
      </div>
      <div className={cn("flex-1", "min-w-0")}>
        <span
          className={cn(
            "block",
            "text-sm",
            "text-black/86",
            "mb-1",
            "truncate"
          )}
        >
          {attachment.name}
        </span>
        <div className={cn("flex", "justify-between")}>
          {error == null ? (
            <span className={cn("text-xs", "text-black/60")}>
              {fileSizeStr}
            </span>
          ) : (
            <span className={cn("text-xs", "text-[#EB5757]", "font-medium")}>
              <Trans i18nKey="attachmentPreview.error" />
            </span>
          )}
          <button
            className={cn("text-xs", "text-primary-600")}
            type="button"
            onClick={onRemoveClick}
          >
            <Trans i18nKey="attachmentPreview.remove" />
          </button>
        </div>
      </div>
    </div>
  );
});

export default AttachmentPreview;
