import { useEffect, useCallback, useRef } from "react";
import {
  useBeforeUnload,
  unstable_useBlocker as useBlocker,
  useNavigate,
} from "react-router-dom";

export const usePromptPageLeave = (
  shouldBlock: boolean,
  discardCallback?: () => void
): ((path: string) => void) => {
  const navigate = useNavigate();
  const isSkipBlock = useRef<boolean>(false);

  const nonBlockNavigate = useCallback(
    (path: string) => {
      isSkipBlock.current = true;
      navigate(path);
    },
    [navigate]
  );

  const onLocationChange = useCallback(() => {
    if (shouldBlock && !isSkipBlock.current) {
      return !window.confirm(
        "You have unsaved changes, are you sure you want to leave?"
      );
    }
    return false;
  }, [shouldBlock]);

  usePrompt(onLocationChange, shouldBlock);
  useBeforeUnload(
    useCallback(
      (event) => {
        if (shouldBlock) {
          event.preventDefault();
          event.returnValue = "";
        }
      },
      [shouldBlock]
    ),
    { capture: true }
  );

  useEffect(() => {
    return () => {
      if (typeof discardCallback === "function" && !isSkipBlock.current) {
        discardCallback();
      }
    };
  }, [discardCallback]);

  return nonBlockNavigate;
};

function usePrompt(onLocationChange: () => boolean, shouldBlock: boolean) {
  const blocker = useBlocker(shouldBlock ? onLocationChange : false);
  const prevState = useRef(blocker.state);

  useEffect(() => {
    if (blocker.state === "blocked") {
      blocker.reset();
    }
    prevState.current = blocker.state;
  }, [blocker]);
}

export default usePromptPageLeave;
