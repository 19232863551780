import * as z from "zod";

import { assetSchema } from "./asset";
import { stationSchema } from "./station";
import { whatsappConnectionSchema } from "./whatsappConnection";

export const RegularShiftScheduleSchema = z.object({
  dayOfWeek: z.number(),
  startTime: z.string(),
  endTime: z.string(),
});

export const userSchema = z.object({
  id: z.number(),
  adminRole: z.string(),
  name: z.string(),
  nameZhHk: z.string(),
  email: z.string(),
  profilePic: assetSchema.nullable(),
});

export const ptUserEmploymentStatuses = [
  "active",
  "pendingSuspension",
  "inactive",
] as const;
export const ptUserEmploymentStatusSchema = z.enum(ptUserEmploymentStatuses);
export const ptuserSchema = z.object({
  id: z.number(),
  firstNameEn: z.string(),
  lastNameEn: z.string(),
  fullNameZhHk: z.string(),
  email: z.string().nullable(),
  eid: z.string(),
  countryCode: z.string(),
  phoneNumber: z.string(),
  startWorkDate: z.string(),
  regularShiftSchedule: z.array(RegularShiftScheduleSchema).nullable(),
  receiveInviteOnHoliday: z.boolean(),
  profilePic: assetSchema.nullable(),
  station: stationSchema.nullable(),
  whatsappConnection: whatsappConnectionSchema.nullable(),
  isDeleted: z.boolean(),
  pendingConsent: z.boolean(),
  employmentStatus: ptUserEmploymentStatusSchema,
  scheduledSuspendAt: z.string().nullable(),
});
