import { DateTime, DateTimeFormatOptions } from "luxon";
import i18next from "i18next";
import { PublicHoliday } from "../store/apis/enhancedApi";
import { config } from "../config";

export function formatDateTime(dateTime: DateTime, format: string): string {
  return dateTime.setLocale(i18next.language).toFormat(format);
}

export function localizeDateTime(
  dateTime: DateTime,
  formatOpts?: DateTimeFormatOptions
): string {
  return dateTime.setLocale(i18next.language).toLocaleString(formatOpts);
}

export function isoToDateTime(isoDate: string): DateTime {
  return DateTime.fromISO(isoDate, {
    zone: config.timezone,
  });
}

export function timeStringToDateTime(isoTime: string): DateTime {
  return DateTime.fromFormat(isoTime, "HH:mm");
}

export function jsDateToDateTime(jsDate: Date): DateTime {
  return DateTime.fromJSDate(jsDate, {
    zone: config.timezone,
  });
}

export function dateTimeNow(): DateTime {
  return DateTime.now().setZone(config.timezone);
}

export function isDateTimeBetween(
  dt: DateTime,
  start: DateTime | null,
  end: DateTime | null
): boolean {
  return (start == null || start < dt) && (end == null || end > dt);
}

// ref https://github.com/moment/luxon/issues/313#issuecomment-412247468
export function isToday(dt: DateTime): boolean {
  return dt.setZone(config.timezone).hasSame(dateTimeNow(), "day");
}

export function isYesterday(dt: DateTime): boolean {
  return dt
    .setZone(config.timezone)
    .hasSame(dateTimeNow().minus({ days: 1 }), "day");
}

// 0: mon - 6: sun
// eslint-disable-next-line sonarjs/cognitive-complexity
export function getGroupedWeekdays(weeksdays: boolean[]): number[][] {
  const weekdayGroups = [];
  let start = -1;
  let isExpand = false;
  for (let i = 0; i < weeksdays.length + 1; i += 1) {
    const isOn = i <= weeksdays.length && weeksdays[i];
    if (!isExpand && isOn) {
      start = i;
      isExpand = true;
    } else if (!isOn && isExpand) {
      if (i - start > 2) {
        weekdayGroups.push([start, i - 1]);
      } else if (start === i - 1) {
        weekdayGroups.push([start]);
      } else {
        weekdayGroups.push([start]);
        weekdayGroups.push([i - 1]);
      }
      isExpand = false;
    }
  }
  return weekdayGroups;
}

export function isDateInHoliday(
  dateISOString: string,
  holidays: PublicHoliday[]
): boolean {
  if (holidays.length === 0) {
    return false;
  }
  const holiday = holidays.filter(
    (day) =>
      isoToDateTime(day.date).toISODate() ===
      isoToDateTime(dateISOString).toISODate()
  );
  return holiday.length > 0;
}
