import { ReactElement, useCallback, useMemo } from "react";
import { DateTime } from "luxon";
import cn from "classnames";
import {
  useFieldArray,
  Control,
  FieldErrors,
  FieldError,
} from "react-hook-form";
import { useTranslation, Trans } from "react-i18next";
import { FormField } from "oneclick-component/src/components/forms";
import { Button } from "oneclick-component/src/components/Button";
import {
  localizeDateTime,
  isoToDateTime,
} from "oneclick-component/src/utils/datetime";
import { Calendar, Checkbox } from "oneclick-component/src/components/inputs";
import DutyTimeSelect from "./DutyTimeSelect";
import { PlusCircleIcon } from "../../../icon";
import { MultiShiftFormValues } from "../form";
import {
  DEFAULT_SHIFT_START_TIME_HOUR,
  DEFAULT_SHIFT_START_TIME_MINUTE,
} from "../../../constants/shifts/create";

interface Props {
  control: Control<MultiShiftFormValues>;
  errors: FieldErrors<MultiShiftFormValues>;
  endDate: string;
  isEditing: boolean;
  now: DateTime;
  setTimeRangeStartMinuteByIndex: (index: number, value: number) => void;
  setTimeRangeEndMinuteByIndex: (index: number, value: number | null) => void;
}

const weekdayNameArray = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

const RepeatDateSection = (props: Props): ReactElement => {
  const {
    errors,
    control,
    isEditing,
    now,
    endDate,
    setTimeRangeStartMinuteByIndex,
    setTimeRangeEndMinuteByIndex,
  } = props;
  const { t } = useTranslation();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "timeRanges",
  });

  const onAddTimeRange = useCallback(() => {
    append({
      startTime: {
        hour: DEFAULT_SHIFT_START_TIME_HOUR,
        minute: DEFAULT_SHIFT_START_TIME_MINUTE,
      },
      endTime: {
        hour: null,
        minute: null,
      },
    });
  }, [append]);

  const onRemoveTimeRange = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove]
  );

  const startDateError: FieldError | undefined = useMemo(() => {
    if (
      errors.recurringOptions != null &&
      "startDate" in errors.recurringOptions
    ) {
      return errors.recurringOptions.startDate as FieldError;
    }
  }, [errors.recurringOptions]);

  const endDateError: FieldError | undefined = useMemo(() => {
    if (
      errors.recurringOptions != null &&
      "endDate" in errors.recurringOptions
    ) {
      return errors.recurringOptions.endDate as FieldError;
    }
  }, [errors.recurringOptions]);

  const makeSetTimeRangeStartMinute = useCallback(
    (index: number) => (value: number) => {
      setTimeRangeStartMinuteByIndex(index, value);
    },
    [setTimeRangeStartMinuteByIndex]
  );

  const makeSetTimeRangeEndMinute = useCallback(
    (index: number) => (value: number | null) => {
      setTimeRangeEndMinuteByIndex(index, value);
    },
    [setTimeRangeEndMinuteByIndex]
  );

  return (
    <>
      <div className={cn("flex", "gap-x-5")}>
        <FormField.Container
          className={"mb-5 flex-1"}
          label={t("shift.create.multiple.form.startDate")}
          required={true}
          errorMessage={startDateError?.message}
        >
          <FormField.Control
            name={"recurringOptions.startDate"}
            control={control}
          >
            {({ field }) => (
              // FIXME: date display updated to have localization
              <Calendar
                {...field}
                disabled={isEditing}
                min={now.toFormat("yyyy-LL-dd")}
                hasError={startDateError?.message != null}
                formattedValue={
                  field.value === ""
                    ? ""
                    : localizeDateTime(
                        isoToDateTime(field.value),
                        DateTime.DATE_MED
                      )
                }
              />
            )}
          </FormField.Control>
        </FormField.Container>
        <div className="flex-1" />
      </div>
      <div className={cn("mb-3", "flex", "flex-col", "gap-y-5")}>
        {fields.map((item, index) => (
          <DutyTimeSelect
            key={item.id}
            index={index}
            canRemove={fields.length > 1}
            onRemove={onRemoveTimeRange}
            errors={errors}
            control={control}
            isEditing={isEditing}
            setStartMinute={makeSetTimeRangeStartMinute(index)}
            setEndMinute={makeSetTimeRangeEndMinute(index)}
          />
        ))}
        <FormField.Container errorMessage={errors.timeRanges?.message} />
      </div>
      <div className="mb-3">
        <Button
          onClick={onAddTimeRange}
          prefixIcon={PlusCircleIcon}
          theme="transparentPrimary"
          className={cn("pl-0", "pr-0")}
          iconClassName="!fill-none"
        >
          <Trans i18nKey="shift.create.multiple.addTimeRange" />
        </Button>
      </div>
      <div className={cn("flex", "flex-row", "gap-x-5", "mb-5", "flex-wrap")}>
        {weekdayNameArray.map((name, index) => (
          <FormField.Control
            key={`${name}`}
            name={`recurringOptions.weekdays.${index}`}
            control={control}
          >
            {({ field }) => (
              <Checkbox
                label={t(`shift.create.weekday.${name}`)}
                {...field}
                checked={field.value}
                value={`${index}`}
              />
            )}
          </FormField.Control>
        ))}
      </div>
      <div className={cn("flex", "gap-x-5")}>
        <FormField.Container
          className={"mb-5 flex-1"}
          label={t("shift.create.multiple.form.endDate")}
          required={true}
          errorMessage={endDateError?.message}
        >
          <FormField.Control
            name={"recurringOptions.endDate"}
            control={control}
          >
            {({ field }) => (
              // FIXME: date display updated to have localization
              <Calendar
                {...field}
                disabled={isEditing}
                min={now.toFormat("yyyy-LL-dd")}
                hasError={endDateError?.message != null}
                formattedValue={
                  field.value === ""
                    ? ""
                    : localizeDateTime(
                        isoToDateTime(field.value),
                        DateTime.DATE_MED
                      )
                }
              />
            )}
          </FormField.Control>
        </FormField.Container>
        <div className="flex-1" />
      </div>
      <p className="text-sm">
        <Trans
          i18nKey="shift.create.multiple.endDate"
          values={{ date: endDate }}
        />
      </p>
    </>
  );
};

export default RepeatDateSection;
