import React, {
  ReactElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector, useStore } from "react-redux";
import { Waypoint } from "react-waypoint";
import { DateTime } from "luxon";
import * as Sentry from "@sentry/react";
import cn from "classnames";

import {
  Chat,
  useLazyListMessageHandlerChatsChatIdMessagesGetQuery as useListMessage,
  ListMessageHandlerChatsChatIdMessagesGetApiArg as ListMessageParams,
  useSendTextMessageHandlerChatsChatIdTextMessagePostMutation as useSendTextMessage,
  useSendTemplateMessageHandlerChatsChatIdTemplateMessagePostMutation as useSendTemplateMessage,
  useMarkChatReadHandlerChatsChatIdMarkReadPostMutation as useMarkChatRead,
  Message,
} from "oneclick-component/src/store/apis/enhancedApi";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import {
  dateTimeNow,
  isDateTimeBetween,
  isoToDateTime,
} from "oneclick-component/src/utils/datetime";
import { useDebounce } from "oneclick-component/src/hooks/useDebounce";

import { UserIcon } from "../../icon";
import { MessageInput } from "./MessageInput";
import { useShowError } from "../../hooks/useShowError";
import { getWhatsAppConversationExpireDateTime } from "../../utils/whatsapp";
import { RootState } from "../../store/store";
import {
  initialChatMessageState,
  startFetchingMoreMessages,
  startFetchingMessages,
  finishFetchingMessages,
  fetchMessagesFailed,
  privateModeToggled,
  messageAdded,
  chatRead,
} from "../../store/chatState";
import {
  MessageListDiaplayItemData,
  resolveMessageContentType,
  resolveMessageDisplayItemType,
} from "./MessageListItem/type";
import {
  MessageListDateSeparator,
  MessageListSystemMessage,
  MessageListChatMessage,
  MessageListChatExpiredIndicator,
} from "./MessageListItem";

const PAGE_SIZE = 20;
const HEIGHT_DIFF_THRESHOLD = 10;

function isHeightApproxEqual(h1: number, h2: number) {
  return Math.abs(h1 - h2) < HEIGHT_DIFF_THRESHOLD;
}

interface Props {
  chat: Chat;
}

export const MessageList = (props: Props): ReactElement => {
  const { chat } = props;
  const { t } = useTranslation();
  const { showError } = useShowError();
  const chatId = chat.id;
  const dispatch = useDispatch();
  const [listMessage] = useListMessage();
  const [sendMessage] = useSendTextMessage();
  const [sendTemplateMessage] = useSendTemplateMessage();
  const [markChatRead] = useMarkChatRead();

  const store = useStore<RootState>();
  const {
    meUser,
    isPrivateMode,
    messages,
    isFetchingMessages,
    hasMoreMessagesAfter,
    hasMoreMessagesBefore,
    messagesBeforeCursor,
    messagesAfterCursor,
  } = useSelector((state: RootState) => {
    const { chatState } = state;
    const chatMessages =
      chatState.chatMessages[chatId] ?? initialChatMessageState;

    return {
      meUser: state.auth.meUser,
      isPrivateMode: chatMessages.isPrivate,
      isFetchingMessages: chatMessages.isFetchingMessages,
      hasMoreMessagesAfter: chatMessages.messageListAfterCursor != null,
      hasMoreMessagesBefore: chatMessages.messageListBeforeCursor != null,
      messagesBeforeCursor: chatMessages.messageListBeforeCursor,
      messagesAfterCursor: chatMessages.messageListAfterCursor,
      messages: chatMessages.messages,
    };
  }, shallowEqual);

  const messageListScrollContainerRef = useRef<HTMLDivElement>(null);
  const messageListScrollPrevHeight = useRef(0);
  const messageListScrollHeight = useRef(0);
  const isOnBottomRef = useRef(false);

  const updateMessagesContainerScrollHeight = useCallback(() => {
    const scrollContainer = messageListScrollContainerRef.current;
    if (
      scrollContainer == null ||
      isHeightApproxEqual(
        scrollContainer.scrollHeight,
        messageListScrollHeight.current
      )
    ) {
      return;
    }

    messageListScrollPrevHeight.current = messageListScrollHeight.current;
    messageListScrollHeight.current = scrollContainer.scrollHeight;
  }, []);

  // Make message list stay on same scroll position when message is prepended (when next page is loaded)
  useLayoutEffect(() => {
    updateMessagesContainerScrollHeight();

    const offset =
      messageListScrollHeight.current - messageListScrollPrevHeight.current;

    if (messageListScrollHeight.current > 0 && offset > HEIGHT_DIFF_THRESHOLD) {
      messageListScrollContainerRef.current?.scrollBy({
        top: offset,
        behavior: "auto",
      });
    }
  }, [messagesBeforeCursor, updateMessagesContainerScrollHeight]);

  const scrollToBottom = useCallback((smooth: boolean = false) => {
    messageListScrollContainerRef.current?.scrollTo({
      top: messageListScrollContainerRef.current.scrollHeight,
      behavior: smooth ? "smooth" : "auto",
    });
  }, []);

  const debouncedScrollToBottom = useDebounce(scrollToBottom, 100);

  // List content resized
  // => list scroll height could be increased
  // => need to check if we need to scroll to bottom
  const listContentResizeObserver = useMemo(() => {
    const obs = new ResizeObserver((_entries) => {
      // Record height change to offset list shifted by appending / prepending messages
      updateMessagesContainerScrollHeight();

      // isOnBottom is only updated on scroll
      // isOnBottom true => is on bottom before scroll height change after most recent scroll
      if (isOnBottomRef.current) {
        debouncedScrollToBottom(true);
      }
    });

    return obs;
  }, [debouncedScrollToBottom, updateMessagesContainerScrollHeight]);

  const onListRef = useCallback(
    (elem: HTMLUListElement | null) => {
      if (elem == null) {
        return;
      }
      listContentResizeObserver.observe(elem);

      const onBottom = isHeightApproxEqual(
        elem.scrollTop + elem.offsetHeight,
        elem.scrollHeight
      );
      isOnBottomRef.current = onBottom;
    },
    [listContentResizeObserver]
  );

  const onMessageListContainerScroll = useCallback(
    (ev: React.SyntheticEvent<HTMLDivElement>) => {
      ev.stopPropagation();

      const onBottom = isHeightApproxEqual(
        ev.currentTarget.scrollTop + ev.currentTarget.offsetHeight,
        ev.currentTarget.scrollHeight
      );
      isOnBottomRef.current = onBottom;
    },
    []
  );

  const participantsDisplayText = useMemo(() => {
    const participants = [
      chat.ptUser.fullNameZhHk,
      t("chatDialog.messageList.stationSupervisorParticipant", {
        stationCode: chat.station.shortCode,
      }),
    ];
    if (chat.partnerStation != null) {
      participants.push(
        t("chatDialog.messageList.stationSupervisorParticipant", {
          stationCode: chat.partnerStation.shortCode,
        })
      );
    }
    return participants.join(", ");
  }, [t, chat.ptUser, chat.station, chat.partnerStation]);

  const fetchMessages = useCallback(
    async (params: ListMessageParams, options?: { isFetchMore?: boolean }) => {
      if (options?.isFetchMore) {
        dispatch(
          startFetchingMoreMessages({
            direction: params.direction,
            chatId,
          })
        );
      } else {
        dispatch(
          startFetchingMessages({
            chatId,
          })
        );
      }
      try {
        const data = await listMessage(params).unwrap();
        dispatch(
          finishFetchingMessages({
            chatId,
            messages: data.items,
            direction: params.direction,
            before: data.before ?? undefined,
            after: data.after ?? undefined,
          })
        );
      } catch (err: unknown) {
        dispatch(fetchMessagesFailed({ chatId }));
        showError(err, "chatDialog.messageList.listMessage.error.title");
      }
    },
    [dispatch, listMessage, chatId, showError]
  );

  const fetchMoreMessages = useCallback(
    async (direction: "before" | "after") => {
      const chatMessages = store.getState().chatState.chatMessages[chatId];
      if (chatMessages == null) {
        return;
      }
      const {
        isFetchingMessages,
        isFetchingMoreMessagesBefore,
        isFetchingMoreMessagesAfter,
        messageListAfterCursor,
        messageListBeforeCursor,
      } = chatMessages;
      const isFetchingMore =
        direction === "before"
          ? isFetchingMoreMessagesBefore
          : isFetchingMoreMessagesAfter;
      const hasMore =
        direction === "before"
          ? messageListBeforeCursor != null
          : messageListAfterCursor != null;
      if (isFetchingMessages || isFetchingMore || !hasMore) {
        return;
      }
      await fetchMessages(
        {
          chatId,
          direction,
          after: messageListAfterCursor,
          before: messageListBeforeCursor,
          limit: PAGE_SIZE,
        },
        { isFetchMore: true }
      );
    },
    [fetchMessages, store, chatId]
  );

  // Fetch more message function references changes when
  // corresponding cursor changes, re-rendering + resetting waypoint
  const fetchMoreMessagesBefore = useCallback(() => {
    if (messagesBeforeCursor == null) {
      return;
    }
    fetchMoreMessages("before").catch((err) => {
      throw err;
    });
  }, [fetchMoreMessages, messagesBeforeCursor]);

  const fetchMoreMessagesAfter = useCallback(() => {
    if (messagesAfterCursor == null) {
      return;
    }
    fetchMoreMessages("after").catch((err) => {
      throw err;
    });
  }, [fetchMoreMessages, messagesAfterCursor]);

  useEffect(() => {
    const chatMessages =
      store.getState().chatState.chatMessages[chatId] ??
      initialChatMessageState;
    if (
      chatMessages.isFetchingMessages ||
      chatMessages.isFetchingMoreMessagesBefore
    ) {
      return;
    }
    fetchMessages({
      chatId,
      direction: "before",
      limit: PAGE_SIZE,
    })
      .then(() => {
        scrollToBottom();
      })
      .catch(() => {});
    // Only run on mount
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (meUser == null) {
      return;
    }

    // optimistically update state
    dispatch(chatRead({ chatId: chat.id }));

    markChatRead({
      chatId: chat.id,
    }).catch((err: unknown) => {
      console.error(`Failed to mark chat ${chat.id} as read`);
      Sentry.captureException(err);
    });
    // Only for message list of each chat, mark read for each new message when message list is mounted
  }, [chat.id, chat.lastMessage?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const togglePrivateMode = useCallback(
    (isPrivate: boolean) => {
      dispatch(privateModeToggled({ chatId, isPrivate }));
    },
    [chatId, dispatch]
  );

  const onSendMessage = useCallback(
    async (
      content: string,
      attachmentIds: number[],
      isPrivateMode: boolean
    ) => {
      if (content.trim().length === 0 && attachmentIds.length === 0) {
        // Disallow send empty message
        return;
      }
      try {
        const resp = await sendMessage({
          chatId,
          sendTextMessageRequest: {
            text: content.trim(),
            attachmentIds: attachmentIds,
            isPublic: !isPrivateMode,
          },
        }).unwrap();
        dispatch(messageAdded({ message: resp.message }));
      } catch (err: unknown) {
        showError(err, "chatDialog.messageList.sendMessage.error.title");
      }
    },
    [sendMessage, chatId, dispatch, showError]
  );

  const onSendTemplateMessage = useCallback(
    async (templateId: number) => {
      // Assume template without parameters for user template message
      try {
        const resp = await sendTemplateMessage({
          chatId,
          sendTemplateMessageRequest: {
            templateId,
            templateParams: {},
            isPublic: true,
          },
        }).unwrap();
        dispatch(messageAdded({ message: resp.message }));
      } catch (err: unknown) {
        showError(err, "chatDialog.messageList.sendMessage.error.title");
      }
    },
    [showError, dispatch, sendTemplateMessage, chatId]
  );

  const onMessageResend = useCallback(
    async (message: Message) => {
      if (message.templateId != null) {
        await onSendTemplateMessage(message.templateId);
      } else {
        await onSendMessage(
          message.content ?? "",
          message.attachments.map((a) => a.asset.id),
          !message.isPublic
        );
      }
    },
    [onSendMessage, onSendTemplateMessage]
  );

  const chatExpireDateTime = useMemo(() => {
    return getWhatsAppConversationExpireDateTime(
      chat.lastInboundMessageTimestamp
    );
  }, [chat.lastInboundMessageTimestamp]);

  const isChatOutsideReplyWindow =
    chatExpireDateTime == null || chatExpireDateTime < dateTimeNow();

  const messageItems = useMemo(() => {
    const items: MessageListDiaplayItemData[] = [];
    let previousMessageTimestamp: DateTime | null = null;
    let isAddedChatExpireIndicator = false;

    for (const m of messages) {
      const messageTimestamp = isoToDateTime(m.createdAt);

      // Date in configured timezone
      const messageDate = messageTimestamp.toISODate();
      const previousMessageDate = previousMessageTimestamp?.toISODate();
      if (
        messageDate != null &&
        (previousMessageDate == null || messageDate !== previousMessageDate)
      ) {
        items.push({
          type: "dateSeparator",
          date: messageTimestamp.startOf("day"),
        });
      }

      if (
        isChatOutsideReplyWindow &&
        chatExpireDateTime != null &&
        isDateTimeBetween(
          chatExpireDateTime,
          previousMessageTimestamp,
          messageTimestamp
        )
      ) {
        items.push({
          type: "chatExpiredIndicator",
          expireDateTime: chatExpireDateTime,
        });
        isAddedChatExpireIndicator = true;
      }

      items.push({
        type: resolveMessageDisplayItemType(m),
        contentType: resolveMessageContentType(m),
        message: m,
      });

      previousMessageTimestamp = messageTimestamp;
    }

    if (isChatOutsideReplyWindow && !isAddedChatExpireIndicator) {
      items.push({
        type: "chatExpiredIndicator",
        expireDateTime: chatExpireDateTime,
      });
    }

    return items;
  }, [messages, chatExpireDateTime, isChatOutsideReplyWindow]);

  return (
    <div
      className={cn("flex", "flex-col", "pt-1.5", "flex-1", "min-h-0", {
        "bg-[#262626]": isPrivateMode,
      })}
    >
      <div
        className={cn(
          "flex",
          "items-center",
          "justify-center",
          "p-2",
          "border-b",
          {
            "border-black/10": !isPrivateMode,
            "border-white/10": isPrivateMode,
          }
        )}
      >
        <UserIcon
          className={cn("min-w-3", "min-h-3", "w-3", "h-3", "mr-1", {
            "fill-gray-300": isPrivateMode,
            "fill-black/60": !isPrivateMode,
          })}
        />
        <span
          className={cn(
            "truncate",
            "overflow-hidden",
            "text-xs",
            "font-medium",
            {
              "text-gray-300": isPrivateMode,
              "text-black/60": !isPrivateMode,
            }
          )}
        >
          {participantsDisplayText}
        </span>
      </div>
      {isFetchingMessages ? (
        <div className={cn("flex", "flex-1", "justify-center", "mt-2")}>
          <LoadingSpinner size="m" />
        </div>
      ) : (
        <div
          className={cn(
            "overflow-y-auto",
            "overscroll-none",
            "flex-1",
            "pt-3",
            {
              // configure browser scroll bar to use dark theme
              "[color-scheme:dark]": isPrivateMode,
            }
          )}
          ref={messageListScrollContainerRef}
          onScroll={onMessageListContainerScroll}
        >
          {hasMoreMessagesBefore ? (
            <div className={cn("flex", "justify-center", "mt-2")}>
              <LoadingSpinner size="s" />
            </div>
          ) : null}
          <Waypoint onEnter={fetchMoreMessagesBefore} />
          <ul ref={onListRef}>
            {messageItems.map((item) => {
              if (item.type === "dateSeparator") {
                return (
                  <MessageListDateSeparator
                    key={`${item.type}-${item.date.toISODate() ?? Date.now()}`}
                    date={item.date}
                  />
                );
              }
              if (item.type === "chatExpiredIndicator") {
                return (
                  <MessageListChatExpiredIndicator
                    key={`${item.type}-${
                      item.expireDateTime?.toISODate() ?? ""
                    }`}
                    isPrivateMode={isPrivateMode}
                    expireDateTime={item.expireDateTime}
                  />
                );
              }
              if (item.type === "systemMessage") {
                return (
                  <MessageListSystemMessage
                    key={`${item.type}-${item.message.id}`}
                    isPrivateMode={isPrivateMode}
                    message={item.message}
                  />
                );
              }
              // item.type === "chatMessage"
              return (
                <MessageListChatMessage
                  key={`${item.type}-${item.message.id}`}
                  meUser={meUser ?? null}
                  isPrivateMode={isPrivateMode}
                  contentType={item.contentType}
                  message={item.message}
                  // error in callback is properly caught
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onResend={onMessageResend}
                />
              );
            })}
          </ul>
          <Waypoint onEnter={fetchMoreMessagesAfter} />
          {hasMoreMessagesAfter ? (
            <div className={cn("flex", "justify-center", "mt-2")}>
              <LoadingSpinner size="s" />
            </div>
          ) : null}
        </div>
      )}
      <MessageInput
        isPrivateMode={isPrivateMode}
        onTogglePrivateMode={togglePrivateMode}
        sendMessage={onSendMessage}
        sendTemplateMessage={onSendTemplateMessage}
        isOutsideReplyWindow={isChatOutsideReplyWindow}
      />
    </div>
  );
};
