import React, { Fragment, ReactElement, useCallback } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import i18next from "i18next";
import cn from "classnames";
import {
  MeUser,
  UserStationProfile,
  useUpdateLanguagePreferenceHandlerMeLanguagePreferencePostMutation,
} from "oneclick-component/src/store/apis/enhancedApi";
import { StationBadge } from "oneclick-component/src/components/Badge";
import { Locale } from "oneclick-component/src/i18n/Locale";
import AppRoutes from "../../routes/AppRoutes";
import { useShowError } from "../../hooks/useShowError";
import { ToggleButton } from "./ToggleButton";

interface Props {
  isOpen: boolean;
  meUser: MeUser | null;
  meUserSelectedStation: UserStationProfile | null;
  onClose: () => void;
  onClickLogout: () => void;
  onSwitchStationClick: () => void;
}

export const SlideoverUserMenu = (props: Props): ReactElement => {
  const {
    isOpen,
    onClose,
    meUser,
    meUserSelectedStation,
    onClickLogout,
    onSwitchStationClick,
  } = props;
  const { showError } = useShowError();
  const handleClose = useCallback(() => onClose(), [onClose]);

  const [updateLanguagePreference] =
    useUpdateLanguagePreferenceHandlerMeLanguagePreferencePostMutation();
  const makeOnClickLocale = useCallback(
    (locale: Locale) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      if (i18next.language === locale) return;
      updateLanguagePreference({
        updateLanguagePreferenceRequest: {
          newLanguagePreference: locale,
        },
      })
        .unwrap()
        .then(() => {
          window.location.reload();
        })
        .catch((err: unknown) => {
          showError(err, "menu.changeLang.toast.fail.title");
        });
    },
    [updateLanguagePreference, showError]
  );

  const _onSwitchStationClick = useCallback(() => {
    onClose();
    onSwitchStationClick();
  }, [onClose, onSwitchStationClick]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={cn("relative", "z-30", "sm:hidden")}
        onClose={handleClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={cn(
              "fixed",
              "inset-0",
              "bg-black/30",
              "transition-opacity"
            )}
          />
        </Transition.Child>
        <div className={cn("fixed", "inset-0", "overflow-hidden")}>
          <div className={cn("absolute", "inset-0", "overflow-hidden")}>
            <div
              className={cn(
                "pointer-events-none",
                "fixed",
                "inset-y-0",
                "left-0",
                "flex",
                "max-w-full",
                "pr-10"
              )}
            >
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500"
                // Note: trial & error figures
                enterFrom="-translate-x-150"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-150"
              >
                <Dialog.Panel
                  className={cn("pointer-events-auto", "w-56", "max-w-md")}
                >
                  <div
                    className={cn(
                      "h-full",
                      "w-full",
                      "overflow-y-scroll",
                      "bg-white",
                      "shadow-xl",
                      "divide-y",
                      "divide-gray-100"
                    )}
                  >
                    <div className={cn("py-6", "px-4")}>
                      <div className={cn("flex", "flex-row")}>
                        <p className={cn("text-base", "grow")}>
                          {meUser?.name}
                        </p>
                        <p
                          className={cn(
                            "text-xs",
                            "text-black/24",
                            "font-normal"
                          )}
                        >
                          V {window.appConfig.version ?? "--"}
                        </p>
                      </div>
                      {meUser != null && meUser.adminRole === "MANAGER" ? (
                        <div
                          className={cn(
                            "flex",
                            "justify-between",
                            "items-center",
                            "mt-2"
                          )}
                        >
                          {meUserSelectedStation != null ? (
                            <StationBadge
                              station={meUserSelectedStation.station}
                              stationTeam={null}
                            />
                          ) : null}
                          {meUser.stations.length > 1 ? (
                            <button
                              type="button"
                              className={cn(
                                "text-primary-800",
                                "text-sm",
                                "underline"
                              )}
                              onClick={_onSwitchStationClick}
                              disabled={meUser.stations.length < 2}
                            >
                              <Trans i18nKey="menu.switchStation" />
                            </button>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                    <ul
                      className={cn(
                        "py-1",
                        "text-base",
                        "font-medium",
                        "text-gray-700"
                      )}
                    >
                      <li>
                        <Link
                          className={cn("block", "py-2", "px-4", "font-medium")}
                          to={AppRoutes.ShiftListScreen.render()}
                          onClick={handleClose}
                        >
                          <Trans i18nKey="menu.homePage" />
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={cn("block", "py-2", "px-4", "font-medium")}
                          to={AppRoutes.PartTimeListScreen.render()}
                          onClick={handleClose}
                        >
                          <Trans i18nKey="menu.managePTUser" />
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={cn("block", "py-2", "px-4", "font-medium")}
                          to={AppRoutes.AnnouncementScreen.render()}
                          onClick={handleClose}
                        >
                          <Trans i18nKey="menu.announcement" />
                        </Link>
                      </li>
                    </ul>
                    <div className={cn("py-3", "px-4", "flex", "flex-row")}>
                      <ToggleButton
                        active={i18next.language === Locale.zhHantHK}
                        onClick={makeOnClickLocale(Locale.zhHantHK)}
                      >
                        <Trans i18nKey="common.locale.zhHantHK" />
                      </ToggleButton>
                      <ToggleButton
                        active={i18next.language === Locale.en}
                        onClick={makeOnClickLocale(Locale.en)}
                      >
                        <Trans i18nKey="common.locale.en" />
                      </ToggleButton>
                    </div>
                    <div>
                      <div className={cn("py-3", "px-4")}>
                        <Trans
                          i18nKey="menu.loginAs"
                          className={cn("text-sm", "text-gray-900")}
                        />
                        <p
                          className={cn(
                            "text-sm",
                            "font-medium",
                            "text-gray-700"
                          )}
                        >
                          {meUser?.email}
                        </p>
                      </div>
                      <button
                        type="button"
                        onClick={onClickLogout}
                        className={cn("py-2", "px-4")}
                      >
                        <Trans i18nKey="menu.logout" />
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
