import React from "react";
import { DateTime } from "luxon";
import { Row } from "@tanstack/react-table";
import cn from "classnames";
import {
  isoToDateTime,
  localizeDateTime,
} from "oneclick-component/src/utils/datetime";

interface ShiftRequestListGroupHeaderProps<TData> {
  row: Row<TData>;
  headerColSpan: number;
}

export function ShiftRequestListGroupHeader<TData>(
  props: ShiftRequestListGroupHeaderProps<TData>
): React.ReactElement {
  const { row, headerColSpan } = props;

  return (
    <tr key={row.id} className="bg-gray-100">
      {row.groupingColumnId === "shiftDate" ? (
        <>
          <td
            className={cn(
              "border-b",
              "border-black/12",
              "py-2",
              "px-6",
              "text-sm",
              "font-medium"
            )}
            colSpan={headerColSpan}
          >
            {localizeDateTime(
              isoToDateTime(row.groupingValue as string),
              DateTime.DATE_MED
            )}
          </td>
        </>
      ) : null}
    </tr>
  );
}
