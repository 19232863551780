import React, { useCallback, useMemo, useState } from "react";
import {
  Announcement,
  ListAnnouncementHandlerAnnouncementsListGetApiArg as ListAnnouncementArg,
  useListAnnouncementHandlerAnnouncementsListGetQuery as useListAnnouncementQuery,
  useLazyGetAnnouncementByIdHandlerAnnouncementsAnnouncementIdGetQuery as useLazyGetAnnouncementQuery,
} from "oneclick-component/src/store/apis/enhancedApi";
import cn from "classnames";
import { Trans } from "react-i18next";
import { Button } from "oneclick-component/src/components/Button";
import { PlusIcon } from "@heroicons/react/24/outline";
import CreateAnnouncementDialog from "./CreateAnnouncementDialog";
import AnnouncementListView from "./AnnouncementListView";
import { ControlledTableState } from "oneclick-component/src/hooks/useOnClickTableColumnHeader";
import { SortingState } from "@tanstack/react-table";
import AnnouncementDetailDialog from "./AnnouncementDetailDialog";
import { useShowError } from "../../hooks/useShowError";

const ANNOUNCEMENT_LIST_PAGE_SIZE = 25;

const useSortingQueryParam = (
  sortingState: SortingState
): string | undefined => {
  const defaultSort = `createdAt-`;
  if (sortingState.length === 0 || sortingState.length > 1) {
    return defaultSort;
  }
  // sortingState.length === 1
  const singleColumnSorting = sortingState[0];
  const sortDirectionSymbol = singleColumnSorting.desc ? "-" : "+";

  switch (singleColumnSorting.id) {
    case "content": {
      return `content${sortDirectionSymbol},${defaultSort}`;
    }
    case "recipient": {
      return `recipientSortKey${sortDirectionSymbol},${defaultSort}`;
    }
    case "type": {
      return `type${sortDirectionSymbol},${defaultSort}`;
    }
    case "sentAt": {
      return `createdAt${sortDirectionSymbol}`; // no need default sort suffix since createdAt is default sort
    }
    case "senderEmail": {
      return `creatorEmail${sortDirectionSymbol},${defaultSort}`;
    }
    default: {
      return defaultSort;
    }
  }
};

const AnnoucementScreen = (): React.ReactElement => {
  const [controlledTableState, setControlledTableState] =
    useState<ControlledTableState>({
      sorting: [{ id: "sentAt", desc: true }],
    });

  const sorting = useSortingQueryParam(controlledTableState.sorting);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const listAnnouncementQueryParams = useMemo<ListAnnouncementArg>(
    () => ({
      pageIndex,
      pageSize: ANNOUNCEMENT_LIST_PAGE_SIZE,
      sort: sorting,
    }),
    [pageIndex, sorting]
  );
  const {
    data: announcementResponse,
    isLoading,
    isFetching,
  } = useListAnnouncementQuery(listAnnouncementQueryParams);
  const [
    getDetail,
    { data: announcementDetailResponse, isFetching: isDetailFetching },
  ] = useLazyGetAnnouncementQuery();
  const announcementList: Announcement[] = useMemo(
    () => announcementResponse?.results ?? [],
    [announcementResponse]
  );
  const announcementDetail: Announcement | null = useMemo(
    () => announcementDetailResponse?.announcement ?? null,
    [announcementDetailResponse]
  );

  const totalCount = announcementResponse?.totalCount ?? 0;
  const handleOnClickPage = useCallback((pageIndex: number) => {
    setPageIndex(pageIndex);
  }, []);

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState<boolean>(false);
  const [isDetailDialogOpen, setIsDetailDialogOpen] = useState<boolean>(false);
  const onCloseCreateDialog = useCallback(
    () => setIsCreateDialogOpen(false),
    []
  );
  const onCloseDetailDialog = useCallback(
    () => setIsDetailDialogOpen(false),
    []
  );
  const onOpenCreateDialog = useCallback(() => setIsCreateDialogOpen(true), []);
  const onOpenDetailDialog = useCallback(() => setIsDetailDialogOpen(true), []);

  const { showError } = useShowError();
  const onClickTableRowFactory = useCallback(
    (announcementId: number) => () => {
      onOpenDetailDialog();
      getDetail({ announcementId })
        .unwrap()
        .catch((err) => {
          onCloseDetailDialog();
          showError(err);
        });
    },
    [onOpenDetailDialog, getDetail, onCloseDetailDialog, showError]
  );

  return (
    <main>
      <div
        className={cn(
          "rounded-lg",
          "bg-white",
          "mx-auto",
          "px-6",
          "pt-6",
          "pb-20",
          "sm:pb-6",
          "sm:mb-6",
          "space-y-5"
        )}
      >
        <div className="flex">
          <p
            className={cn("text-black/86", "font-medium", "text-xl", "flex-1")}
          >
            <Trans i18nKey="announcementList.title" />
          </p>
          <Button prefixIcon={PlusIcon} onClick={onOpenCreateDialog}>
            <Trans i18nKey="announcementList.create" />
          </Button>
        </div>
        <AnnouncementListView
          isLoading={isLoading || isFetching}
          announcementList={announcementList}
          pageIndex={listAnnouncementQueryParams.pageIndex ?? 0}
          totalPages={Math.ceil(totalCount / ANNOUNCEMENT_LIST_PAGE_SIZE)}
          onClickPage={handleOnClickPage}
          controlledTableState={controlledTableState}
          setControlledTableState={setControlledTableState}
          onClickTableRowFactory={onClickTableRowFactory}
        />
      </div>
      <CreateAnnouncementDialog
        isOpen={isCreateDialogOpen}
        onClose={onCloseCreateDialog}
      />
      <AnnouncementDetailDialog
        isOpen={isDetailDialogOpen}
        onClose={onCloseDetailDialog}
        announcement={announcementDetail}
        isLoading={isDetailFetching}
      />
    </main>
  );
};

export default AnnoucementScreen;
