import { ReactElement, useCallback, useMemo } from "react";
import { DateTime } from "luxon";
import {
  useFieldArray,
  Control,
  FieldErrors,
  FieldError,
} from "react-hook-form";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { FormField } from "oneclick-component/src/components/forms";
import {
  localizeDateTime,
  isoToDateTime,
} from "oneclick-component/src/utils/datetime";
import { Calendar } from "oneclick-component/src/components/inputs";
import DutyTimeSelect from "./DutyTimeSelect";
import { MultiShiftFormValues } from "../form";

interface Props {
  control: Control<MultiShiftFormValues>;
  errors: FieldErrors<MultiShiftFormValues>;
  isEditing: boolean;
  now: DateTime;
  setTimeRangeStartMinuteByIndex: (index: number, value: number) => void;
  setTimeRangeEndMinuteByIndex: (index: number, value: number | null) => void;
}

const IncidentDateSection = (props: Props): ReactElement => {
  const {
    errors,
    control,
    isEditing,
    now,
    setTimeRangeStartMinuteByIndex,
    setTimeRangeEndMinuteByIndex,
  } = props;
  const { t } = useTranslation();
  const { fields } = useFieldArray({
    control,
    name: "timeRanges",
  });

  const dateError: FieldError | undefined = useMemo(() => {
    if (errors.recurringOptions != null && "date" in errors.recurringOptions) {
      return errors.recurringOptions.date as FieldError;
    }
  }, [errors.recurringOptions]);

  const makeSetTimeRangeStartMinute = useCallback(
    (index: number) => (value: number) => {
      setTimeRangeStartMinuteByIndex(index, value);
    },
    [setTimeRangeStartMinuteByIndex]
  );

  const makeSetTimeRangeEndMinute = useCallback(
    (index: number) => (value: number | null) => {
      setTimeRangeEndMinuteByIndex(index, value);
    },
    [setTimeRangeEndMinuteByIndex]
  );

  return (
    <>
      <div className={cn("flex", "gap-x-5")}>
        <FormField.Container
          className={"flex-1"}
          label={t("shift.create.multiple.form.startDate")}
          errorMessage={dateError?.message}
        >
          <FormField.Control name={"recurringOptions.date"} control={control}>
            {({ field }) => (
              // FIXME: date display updated to have localization
              <Calendar
                {...field}
                disabled={true}
                min={now.toFormat("yyyy-LL-dd")}
                hasError={dateError?.message != null}
                formattedValue={
                  field.value === ""
                    ? ""
                    : localizeDateTime(
                        isoToDateTime(field.value),
                        DateTime.DATE_MED
                      )
                }
              />
            )}
          </FormField.Control>
        </FormField.Container>
      </div>
      <div className={cn("flex", "flex-col", "gap-y-5")}>
        {fields.map((item, index) => (
          <DutyTimeSelect
            key={item.id}
            index={index}
            canRemove={fields.length > 1}
            startTimeDisable={true}
            errors={errors}
            control={control}
            isEditing={isEditing}
            setStartMinute={makeSetTimeRangeStartMinute(index)}
            setEndMinute={makeSetTimeRangeEndMinute(index)}
            endMinuteLabel={t("shift.create.form.incidentForm.endTime")}
            endHourEmptyText={t("shift.create.form.incident.time.placeholder")}
            endMinuteEmptyText={t(
              "shift.create.form.incident.time.placeholder"
            )}
          />
        ))}
        <FormField.Container errorMessage={errors.timeRanges?.message} />
      </div>
    </>
  );
};

export default IncidentDateSection;
