import React, {
  ReactElement,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from "react";
import cn from "classnames";
import { Trans, useTranslation } from "react-i18next";
import { useForm, UseFormReturn } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  RailwayLine,
  Station,
} from "oneclick-component/src/store/apis/enhancedApi";
import { FormField } from "oneclick-component/src/components/forms";
import { ChevronDownIcon } from "oneclick-component/src/icon";
import { Checkbox } from "oneclick-component/src/components/inputs";
import { useDebounce } from "oneclick-component/src/hooks/useDebounce";
import { localizeString } from "oneclick-component/src/utils/localize";
import { StationSelectionForm, stationSelectionSchema } from "./form";

export interface LineStations {
  line: RailwayLine;
  stations: Station[];
}

interface Props {
  shouldShow: boolean;
  lineStations: LineStations[];
  defaultStationIds: number[];
  isLoading: boolean;
  onUpdateSelectStations: (stationIds: number[]) => void;
}

interface LineSectionProps {
  form: UseFormReturn<StationSelectionForm>;
  line: LineStations;
  isCollapse: boolean;
  onSelectStations: (stationIds: number[]) => void;
  onCollapsePanel: (index: number) => void;
  onExpandPanel: (index: number) => void;
}

const LineSection = (props: LineSectionProps): ReactElement => {
  const {
    form,
    line,
    isCollapse,
    onSelectStations,
    onCollapsePanel,
    onExpandPanel,
  } = props;
  const { t } = useTranslation();
  const { watch } = form;
  const selectedIds = watch("stationIds");

  const onSelect = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const stationId = parseInt(e.target.value, 10);
      const newIds = [...selectedIds];
      if (newIds.includes(stationId)) {
        onSelectStations(newIds.filter((id) => id !== stationId));
      } else {
        newIds.push(stationId);
        onSelectStations(newIds);
      }
    },
    [onSelectStations, selectedIds]
  );

  const onSelectAll = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        const newIds = [...selectedIds];
        for (let i = 0; i < line.stations.length; i += 1) {
          if (!selectedIds.includes(line.stations[i].id)) {
            newIds.push(line.stations[i].id);
          }
        }
        onSelectStations(newIds);
      } else {
        const ids = line.stations.map((st) => st.id);
        onSelectStations(selectedIds.filter((id: number) => !ids.includes(id)));
      }
    },
    [line, onSelectStations, selectedIds]
  );

  const onToggleDisplay = useCallback(() => {
    if (isCollapse) {
      onExpandPanel(line.line.id);
    } else {
      onCollapsePanel(line.line.id);
    }
  }, [isCollapse, onCollapsePanel, onExpandPanel, line]);

  const isSelectedAll = useMemo(() => {
    return line.stations.every((st) => selectedIds.includes(st.id));
  }, [line, selectedIds]);

  const selectedCount = useMemo(() => {
    return line.stations.reduce(
      (count, st) => (selectedIds.includes(st.id) ? count + 1 : count),
      0
    );
  }, [line, selectedIds]);

  return (
    <div>
      <div className={cn("flex", "justify-start", "py-2")}>
        <div
          className={cn(
            "cursor-pointer",
            "hover:underline",
            "flex",
            "flex-row",
            "gap-3"
          )}
          onClick={onToggleDisplay}
        >
          <p className="text-sm">
            <Trans
              i18nKey="shiftDetail.findPT.lineNameDisplay"
              values={{
                lineName: localizeString(line.line.name),
                count: selectedCount,
              }}
            />
          </p>
          <ChevronDownIcon
            className={cn("h-5", "w-5", "fill-gray-500", {
              "rotate-180": isCollapse,
            })}
            aria-hidden="true"
          />
        </div>
      </div>
      {isCollapse ? null : (
        <div className="px-6">
          <div className="py-2">
            <Checkbox
              checked={isSelectedAll}
              label={t("shiftDetail.findPT.selectAll")}
              onChange={onSelectAll}
              name={`${line.line.id}-selectAll`}
            />
          </div>
          <div
            className={cn(
              "grid",
              "grid-cols-4",
              "lg:grid-cols-8",
              "gap-x-6",
              "gap-y-2",
              "py-1",
              "flex-wrap"
            )}
          >
            {line.stations.map((st) => (
              <FormField.Control
                name="stationIds"
                control={form.control}
                key={`${st.id}`}
              >
                {({ field }) => (
                  <Checkbox
                    label={st.shortCode}
                    name={st.shortCode}
                    value={st.id}
                    onChange={onSelect}
                    checked={field.value.includes(st.id)}
                  />
                )}
              </FormField.Control>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

type CollapseState = Record<string, boolean>;

const FindPTUserStationSelect = (props: Props): ReactElement => {
  const {
    lineStations,
    shouldShow,
    defaultStationIds,
    onUpdateSelectStations,
  } = props;
  const form = useForm<StationSelectionForm>({
    resolver: zodResolver(stationSelectionSchema),
    defaultValues: {
      stationIds: defaultStationIds,
    },
  });
  const { setValue, getValues, watch, reset } = form;
  const selectedIds = watch("stationIds");
  const defaultCollapseStates = useMemo<CollapseState>(() => {
    const result: CollapseState = {};
    lineStations.forEach(({ line }) => {
      result[`${line.id}`] = false;
    });
    return result;
  }, [lineStations]);
  const [collapseStates, setCollapseStates] = useState<CollapseState>(
    defaultCollapseStates
  );
  useEffect(() => {
    // reset collapse states on change props.lineStations
    setCollapseStates(defaultCollapseStates);
  }, [defaultCollapseStates]);
  const [hideSection, setHideSection] = useState<boolean>(false);

  const onSubmit = useCallback(() => {
    const stationIds = getValues("stationIds");
    onUpdateSelectStations(stationIds);
  }, [getValues, onUpdateSelectStations]);

  const debouncedSubmit = useDebounce(onSubmit, 500);

  const onSelectStations = useCallback(
    (stationIds: number[]) => {
      setValue("stationIds", stationIds);
      debouncedSubmit();
    },
    [setValue, debouncedSubmit]
  );

  const onCollapsePanel = useCallback((id: number) => {
    setCollapseStates((prev) => {
      const newState = {
        ...prev,
      };
      newState[`${id}`] = true;
      return newState;
    });
  }, []);

  const onExpandPanel = useCallback((id: number) => {
    setCollapseStates((prev) => {
      const newState = {
        ...prev,
      };
      newState[`${id}`] = false;
      return newState;
    });
  }, []);

  const isAllLineCollapse = useMemo(() => {
    return Object.values(collapseStates).every((s) => s === true);
  }, [collapseStates]);

  const onToggleAll = useCallback(() => {
    const targetValue = !isAllLineCollapse;
    setCollapseStates((prev) => {
      const updated: CollapseState = {};
      const keys: string[] = Object.keys(prev);

      for (let i = 0; i < keys.length; i += 1) {
        updated[keys[i]] = targetValue;
      }
      return updated;
    });
  }, [isAllLineCollapse]);

  const onToggleMasterPanel = useCallback(() => {
    setHideSection((prev) => !prev);
  }, []);

  useEffect(() => {
    reset({ stationIds: defaultStationIds });
    onUpdateSelectStations(defaultStationIds);
  }, [defaultStationIds, reset, onUpdateSelectStations]);

  if (!shouldShow) {
    return <></>;
  }

  return (
    <div className="mb-5">
      <div
        className={cn(
          "flex",
          "flex-row",
          "gap-2",
          "items-center",
          "justify-center",
          "cursor-pointer",
          "mb-5"
        )}
        onClick={onToggleMasterPanel}
      >
        <p className={cn("text-primary-600", "text-sm", "underline")}>
          <Trans i18nKey="shiftDetail.findPT.specificStations" />
        </p>
        <p className={cn("text-sm", "text-gray-500")}>
          <Trans
            i18nKey="shiftDetail.findPT.selectedStations"
            values={{ count: selectedIds.length }}
          />
        </p>
        <ChevronDownIcon
          className={cn("h-5", "w-5", "fill-gray-500", {
            "rotate-180": hideSection,
          })}
          aria-hidden="true"
        />
      </div>
      {hideSection ? null : (
        <div
          className={cn(
            "px-0",
            "py-4",
            "md:p-4",
            "border-t",
            "border-b",
            "border-gray-300"
          )}
        >
          <div className={cn("flex", "flex-row", "justify-end")}>
            <div
              className={cn(
                "flex",
                "flex-row",
                "cursor-pointer",
                "hover:underline"
              )}
              onClick={onToggleAll}
            >
              {isAllLineCollapse ? (
                <>
                  <p className={cn("text-sm", "text-gray-500")}>
                    <Trans i18nKey="shiftDetail.findPT.expandAll" />
                  </p>
                  <ChevronDownIcon
                    className={cn("h-5", "w-5", "fill-gray-500", "rotate-180")}
                    aria-hidden="true"
                  />
                </>
              ) : (
                <>
                  <p className={cn("text-sm", "text-gray-500")}>
                    <Trans i18nKey="shiftDetail.findPT.collapseAll" />
                  </p>
                  <ChevronDownIcon
                    className={cn("h-5", "w-5", "fill-gray-500")}
                    aria-hidden="true"
                  />
                </>
              )}
            </div>
          </div>
          {lineStations.map((line) => (
            <LineSection
              key={`${line.line.id}`}
              form={form}
              line={line}
              isCollapse={collapseStates[line.line.id]}
              onSelectStations={onSelectStations}
              onCollapsePanel={onCollapsePanel}
              onExpandPanel={onExpandPanel}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default FindPTUserStationSelect;
