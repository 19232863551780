import React, { PropsWithChildren, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import cn from "classnames";

export interface MediaModalProps {
  onClose: () => void;
  isOpen: boolean;
  className?: string;
}

export function MediaModal(
  props: PropsWithChildren<MediaModalProps>
): React.ReactElement {
  const { onClose, isOpen, children } = props;

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className={cn("relative", "z-50")} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={cn(
              "fixed",
              "inset-0",
              "bg-black/70",
              "transition-opacity"
            )}
          />
        </Transition.Child>
        <div className={cn("fixed", "inset-0", "z-10")}>
          <div
            className={cn(
              "flex",
              "w-full",
              "h-full",
              "justify-center",
              "items-center",
              "p-0",
              "sm:p-0"
            )}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 max-h-full flex"
              enterTo="opacity-100 max-h-full flex"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 max-h-full flex"
              leaveTo="opacity-0 max-h-full flex"
            >
              <div>
                <div
                  className={cn("absolute", "left-0", "top-0", "pl-5", "pt-5")}
                >
                  <button
                    type="button"
                    className={cn(
                      "rounded-full",
                      "border",
                      "border-white",
                      "text-white",
                      "hover:text-gray-300",
                      "focus:outline-none",
                      "p-2"
                    )}
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon
                      className={cn("h-6", "w-6")}
                      aria-hidden="true"
                    />
                  </button>
                </div>
                <div className={cn("max-h-full", "pt-16")}>{children}</div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
