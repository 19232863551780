import { ButtonHTMLAttributes, ReactElement } from "react";
import cn from "classnames";
import { themes } from "oneclick-component/src/components/Button/theme";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";

interface ToggleButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  active?: boolean;
  isLoading?: boolean;
}
export const ToggleButton = (props: ToggleButtonProps): ReactElement => {
  const {
    children,
    className,
    active = false,
    isLoading = false,
    disabled,
    ...rest
  } = props;
  return (
    <button
      type="button"
      className={cn(
        "inline-flex",
        "rounded-md",
        "focus:outline-0",
        "px-4",
        "py-2",
        "font-medium",
        "text-sm",
        "justify-center",
        active
          ? cn(themes.secondary.buttonThemeClassName, "shadow-sm")
          : cn(themes.white.buttonThemeClassName, "border-0", "shadow-none"),
        className
      )}
      disabled={disabled ?? isLoading}
      {...rest}
    >
      {isLoading ? <LoadingSpinner className={cn("!h-5", "!w-5")} /> : children}
    </button>
  );
};
