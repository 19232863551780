import React, { ReactElement, useMemo } from "react";
import { Trans } from "react-i18next";
import cn from "classnames";

import { Asset, Message } from "oneclick-component/src/store/apis/enhancedApi";
import { constructFileSizeDisplayString } from "../../../../utils/file";
import { DocumentIcon } from "../../../../icon";

interface DocumentItemProps {
  asset: Asset;
}

export const DocumentItem = React.memo((props: DocumentItemProps) => {
  const { asset } = props;

  const fileSizeStr = useMemo(() => {
    if (asset.contentSize != null) {
      return constructFileSizeDisplayString(asset.contentSize);
    }
    return "-";
  }, [asset.contentSize]);

  return (
    <li
      className={cn(
        "flex",
        "w-full",
        "p-3",
        "items-start",
        "gap-3",
        "border-b",
        "border-black/[0.12]",
        "last:border-b-0"
      )}
    >
      <div
        className={cn("w-9.5", "h-9.5", "rounded-lg", "p-2", "bg-primary-600")}
      >
        <DocumentIcon className={cn("w-5.5", "h-5.5", "fill-white")} />
      </div>
      <div className={cn("flex-1", "min-w-0")}>
        <span
          className={cn(
            "block",
            "text-sm",
            "text-black/86",
            "mb-1",
            "truncate"
          )}
        >
          {asset.filename}
        </span>
        <div className={cn("flex", "justify-between")}>
          <span className={cn("text-xs", "text-black/60")}>{fileSizeStr}</span>
          <a
            className={cn("text-xs", "text-primary-600")}
            href={asset.url ?? "#"}
            download={asset.filename}
          >
            <Trans i18nKey="chatDialog.messageList.messageListItem.documentContent.download" />
          </a>
        </div>
      </div>
    </li>
  );
});

interface Props {
  message: Message;
}

export const MessageBubbleDocumentsContent = React.memo(
  (props: Props): ReactElement => {
    const { message } = props;

    return (
      <div>
        <ul>
          {message.attachments.map((att) => (
            <DocumentItem key={att.id} asset={att.asset} />
          ))}
        </ul>
        {message.content ? (
          <span
            className={cn(
              "block",
              "border-t",
              "border-black/[0.12]",
              "whitespace-pre-wrap",
              "overflow-wrap-anywhere",
              "text-sm",
              "text-left",
              "p-3"
            )}
          >
            {message.content}
          </span>
        ) : null}
      </div>
    );
  }
);
