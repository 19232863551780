import { useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Option } from "oneclick-component/src/components/inputs";
import { useGetPtUsersRolesHandlerPartTimeUsersRolesGetQuery as useGetPtUserRoles } from "oneclick-component/src/store/apis/enhancedApi";

const ptUserRoleGroups: Record<string, string[]> = {
  "SA/SAS": ["SA", "SAS"],
};

export function usePtUserRoleFilterOptions(): [
  Option<string | null>[],
  (option: string | null) => number[],
] {
  const { t } = useTranslation();
  const { data: ptUserRolesResponse } = useGetPtUserRoles();

  const resolvePtUserRoleOptionToRoleId = useCallback(
    (option: string | null): number[] => {
      if (option == null) {
        return [];
      }
      const roles = ptUserRolesResponse?.ptUserRoles ?? [];
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (ptUserRoleGroups[option] != null) {
        return ptUserRoleGroups[option]
          .map((name) => roles.find((role) => role.name === name)?.id ?? -1)
          .filter((id) => id !== -1);
      }
      const targetRole = roles.find((role) => role.name === option);
      if (targetRole != null) {
        return [targetRole.id];
      }
      return [];
    },
    [ptUserRolesResponse]
  );

  const ptUserRoleFilterOptions = useMemo(() => {
    const nullOption: Option<null> = {
      name: t("shiftList.filter.ptUserRole.showAll"),
      value: null,
    };
    if (ptUserRolesResponse == null) {
      return [nullOption];
    }
    const groupOptions = Object.keys(ptUserRoleGroups).map((name) => ({
      name: name,
      value: name,
    }));
    return [
      nullOption,
      ...groupOptions,

      ...ptUserRolesResponse.ptUserRoles
        .filter((role) => !["SA", "SAS"].includes(role.name))
        .map((role) => ({
          name: role.name,
          value: role.name,
        })),
    ];
  }, [ptUserRolesResponse, t]);

  return [ptUserRoleFilterOptions, resolvePtUserRoleOptionToRoleId];
}
