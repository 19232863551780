import z from "zod";

const stationRoleSchema = z.object({
  stationId: z.number().int(),
  role: z.string(),
});

export const baseEditUserFormSchema = z.object({
  nameEn: z.string(),
  nameZhHk: z.string(),
  adminRole: z.enum(["MANAGER", "ADMIN", "LANDLORD"]),
  email: z.string(),
});

export const editAdminUserFormSchema = baseEditUserFormSchema.extend({
  adminRole: z.literal("ADMIN"),
});

export const editManagerUserFormSchema = baseEditUserFormSchema.extend({
  adminRole: z.literal("MANAGER"),
  stationRoles: z.array(stationRoleSchema).min(1),
});

export const editLandlordUserFormSchema = baseEditUserFormSchema.extend({
  adminRole: z.literal("LANDLORD"),
  lineCode: z.string(),
});

export const editUserFormSchema = z.union([
  editAdminUserFormSchema,
  editManagerUserFormSchema,
  editLandlordUserFormSchema,
]);

export type EditAdminUserFormValues = z.infer<typeof editAdminUserFormSchema>;
export type EditManagerUserFormValues = z.infer<
  typeof editManagerUserFormSchema
>;
export type EditLandlorfUserFormValues = z.infer<
  typeof editLandlordUserFormSchema
>;
export type EditUserFormValues = z.infer<typeof editUserFormSchema>;
