import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useState,
} from "react";
import { Dialog } from "@headlessui/react";
import { Button } from "oneclick-component/src/components/Button";
import { Modal } from "./Modal";
import cn from "classnames";

export interface ActionDialogProps {
  onClose: () => void;
  isOpen: boolean;
  title: string;
  onClickActionButton?: (
    e?: React.MouseEvent<HTMLButtonElement>
  ) => Promise<void>;
  onClickCancelButton?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  actionButtonText: string;
  cancelButtonText: string;
  isActionLoading?: boolean;
  shouldCloseOnActionSuccess?: boolean;
  className?: string;
  titleIcon?: ReactElement;
}
export function ActionDialog(
  props: PropsWithChildren<ActionDialogProps>
): React.ReactNode {
  const {
    onClose,
    isOpen,
    title,
    children,
    actionButtonText,
    cancelButtonText,
    onClickActionButton,
    onClickCancelButton,
    isActionLoading,
    shouldCloseOnActionSuccess = true,
    className,
    titleIcon,
  } = props;

  const [isActionButtonDisabled, setIsActionButtonDisabled] =
    useState<boolean>(false);
  const handleAction = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      setIsActionButtonDisabled(true);
      if (onClickActionButton == null) {
        setIsActionButtonDisabled(false);
        if (shouldCloseOnActionSuccess !== false) onClose();
        return;
      }
      onClickActionButton(e)
        .then(() => {
          setIsActionButtonDisabled(false);
          if (shouldCloseOnActionSuccess !== false) onClose();
        })
        .catch((e) => {
          throw e;
        });
    },
    [
      onClickActionButton,
      onClose,
      shouldCloseOnActionSuccess,
      setIsActionButtonDisabled,
    ]
  );

  const handleCancel = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      onClickCancelButton?.(e);
      onClose();
    },
    [onClickCancelButton, onClose]
  );
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      hasXMarkButton={false}
      className={className}
    >
      <div className={cn("sm:flex", "sm:items-start", "w-full")}>
        <div
          className={cn(
            "mt-3",
            "text-center",
            "sm:mt-0",
            "sm:text-left",
            "w-full"
          )}
        >
          <Dialog.Title className={cn("text-black", "text-lg", "font-medium")}>
            <div className={cn("flex", "flex-row", "items-center")}>
              {titleIcon}
              <p>{title}</p>
            </div>
          </Dialog.Title>
          <div className="mt-2">{children}</div>
        </div>
      </div>
      <div
        className={cn(
          "mt-5",
          "sm:mt-4",
          "flex",
          "flex-row",
          "justify-center",
          "sm:justify-end",
          "items-center"
        )}
      >
        <Button
          className={cn("font-semibold", "flex-1", "sm:flex-none")}
          onClick={handleCancel}
          theme="white"
        >
          {cancelButtonText}
        </Button>
        <Button
          className={cn("font-semibold", "ml-3", "flex-1", "sm:flex-none")}
          onClick={handleAction}
          disabled={isActionButtonDisabled}
          isLoading={isActionLoading}
        >
          {actionButtonText}
        </Button>
      </div>
    </Modal>
  );
}
