const FALLBACK_INITIALS = "NA";
export const getInitialsFromPtUserFirstLastName = (
  firstNameEn: string,
  lastNameEn: string
): string => {
  if (firstNameEn.trim() === "" || lastNameEn.trim() === "") {
    return FALLBACK_INITIALS;
  }
  return `${firstNameEn.trim().substring(0, 1)}${lastNameEn
    .trim()
    .substring(0, 1)}`.toUpperCase();
};
