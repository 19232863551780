import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import {
  Header,
  Table as ReactTable,
  Row,
  RowSelectionState,
} from "@tanstack/react-table";
import cn from "classnames";

import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import {
  TableWithSelect,
  TableWithSelectRow,
} from "oneclick-component/src/components/TableWithSelect";

import { ListEmptyView } from "../../../components/EmptyView";
import { ExpiredShiftListRecord } from "./useExpiredShiftListTable";
import { ShiftListGroupHeader } from "../ShiftListGroupHeader";

const RENDER_ROW_MAX_DEPTH = 4;

const renderRow = (
  row: Row<ExpiredShiftListRecord>,
  headerColSpan: number,
  onClickTableRowFactory: (shiftId: number) => () => void,
  depth: number = 1
): React.ReactElement | null => {
  if (depth > RENDER_ROW_MAX_DEPTH) {
    console.warn(
      `Exceeded maximum render depth ${RENDER_ROW_MAX_DEPTH} for table grouped items`
    );
    return null;
  }

  // Leaf rows
  if (row.subRows.length === 0) {
    return (
      <TableWithSelectRow
        key={row.id}
        row={row}
        onClick={onClickTableRowFactory(row.getValue("id"))}
      />
    );
  }

  // Group header
  return (
    // grouping header row.id  will be sth like "partTimeType:CUCA>date:2023-09-30"
    <React.Fragment key={row.id}>
      <ShiftListGroupHeader row={row} headerColSpan={headerColSpan} />
      {row.subRows.map((r) =>
        renderRow(r, headerColSpan, onClickTableRowFactory, depth + 1)
      )}
    </React.Fragment>
  );
};

interface Props {
  isLoading: boolean;
  isEmpty: boolean;
  onClickTableRowFactory: (shiftId: number) => () => void;
  table: ReactTable<ExpiredShiftListRecord>;
  rowSelection: RowSelectionState;
  onClickColumnHeader: (
    colHeader: Header<ExpiredShiftListRecord, unknown>
  ) => void;
}
const ExpiredShiftTabTable = memo(function ExpiredShiftTabTable(
  props: Props
): React.ReactElement {
  const {
    isLoading,
    isEmpty,
    onClickTableRowFactory,
    table,
    onClickColumnHeader,
  } = props;
  const { t } = useTranslation();
  return (
    <TableWithSelect
      table={table}
      onClickColumnHeader={onClickColumnHeader}
      tableClassName={cn("min-w-150", "md:min-w-full")}
    >
      {isLoading ? (
        <tr className={"h-171"}>
          <td colSpan={table.getVisibleFlatColumns().length}>
            <span className={cn("flex", "justify-center", "items-center")}>
              <LoadingSpinner />
            </span>
          </td>
        </tr>
      ) : null}
      {!isLoading && isEmpty ? (
        <tr className={"h-171"}>
          <td colSpan={table.getVisibleFlatColumns().length}>
            <ListEmptyView
              description={t("shiftList.expired.table.emptyState")}
            />
          </td>
        </tr>
      ) : null}
      {!isEmpty
        ? table.getRowModel().rows.map((row) => {
            return renderRow(
              row,
              table.getVisibleFlatColumns().length,
              onClickTableRowFactory
            );
          })
        : null}
    </TableWithSelect>
  );
});

export default ExpiredShiftTabTable;
