import React, { useCallback, useState, useEffect } from "react";
import { Trans } from "react-i18next";
import cn from "classnames";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import { useLazyListShiftFullTimeAttendanceByShiftHandlerShiftFullTimeAttendancesGetQuery as useLazyListShiftFullTimeAttendance } from "oneclick-component/src/store/apis/enhancedApi";
import { StationBadge } from "oneclick-component/src/components/Badge";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";
import ProgressBar from "oneclick-component/src/components/ProgressBar";
import ShiftFullTimeAttendanceDialog from "../../components/ShiftFullTimeAttendanceDialog";

interface Props {
  shiftId: number;
  fullTimeCount: number;
}

const FullTimeListSection = (props: Props): React.ReactElement => {
  const { shiftId, fullTimeCount } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [loadList, { data, isLoading }] = useLazyListShiftFullTimeAttendance();

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);
  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  // Always keep this list getting latest result
  useEffect(() => {
    loadList(
      {
        shiftId: shiftId,
      },
      false
    ).catch(() => {});
  }, [loadList, shiftId]);

  if (isLoading) {
    return (
      <div className={cn("flex", "flex-row", "justify-center", "my-2")}>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <section className="mt-5">
      <div
        className={cn(
          "relative",
          "border",
          "border-black/10",
          "border-solid",
          "py-2",
          "px-5",
          "rounded-lg"
        )}
      >
        <div className={cn("flex", "flex-row", "mb-5")}>
          <p className="mr-5">
            <Trans i18nKey="shiftDetail.detail.fullTime.title" />
          </p>
          <button
            onClick={onOpen}
            type="button"
            className={cn(
              "text-gray-400",
              "text-xs",
              "font-medium",
              "flex",
              "flex-row",
              "items-center"
            )}
          >
            <PencilSquareIcon className={cn("h-3", "w-3", "mr-0.5")} />
            <u>
              <Trans i18nKey="fulltimeAttendance.action.updateList" />
            </u>
          </button>
          <div className="grow" />
          <ProgressBar max={fullTimeCount} count={(data?.items ?? []).length} />
        </div>
        {data != null && data.items.length > 0 ? (
          data.items.map((fulltime) => (
            <div
              key={fulltime.id}
              className={cn(
                "flex",
                "flex-row",
                "py-3",
                "px-4",
                "gap-x-4",
                "text-sm",
                "font-medium",
                "border-b",
                "last:border-0",
                "border-gray-200"
              )}
            >
              {(fulltime.name.zhHk?.length ?? 0) > 0 ? (
                <p>{fulltime.name.zhHk}</p>
              ) : null}
              {(fulltime.name.en?.length ?? 0) > 0 ? (
                <p>{fulltime.name.en}</p>
              ) : null}
              <div>
                <StationBadge station={fulltime.station} stationTeam={null} />
              </div>
              <p>{fulltime.role}</p>
            </div>
          ))
        ) : (
          <p
            className={cn(
              "text-gray-400",
              "font-normal",
              "text-center",
              "mt-5",
              "mb-3"
            )}
          >
            <Trans i18nKey="shiftDetail.hire.noHiredUser" />
          </p>
        )}
      </div>
      <ShiftFullTimeAttendanceDialog
        isOpen={isOpen}
        onClose={onClose}
        shiftId={shiftId}
        fulltimeCount={fullTimeCount}
      />
    </section>
  );
};

export default FullTimeListSection;
