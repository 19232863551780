import React, {
  ReactNode,
  useCallback,
  Fragment,
  useState,
  ReactElement,
  useRef,
} from "react";
import { Combobox, Transition } from "@headlessui/react";
import { useDebounce } from "react-use";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { containsSubstring } from "../../utils/string";
import { RemoveIcon } from "../../icon";
import { Trans } from "react-i18next";
import cn from "classnames";
import { LoadingSpinner } from "../LoadingSpinner";
import {
  FloatingPortal,
  autoUpdate,
  flip,
  shift,
  size,
  useFloating,
} from "@floating-ui/react";

interface CheckboxIconProps {
  checked: boolean;
}
function CheckboxIcon(props: CheckboxIconProps): ReactNode {
  const { checked } = props;

  return (
    <input
      className={cn(
        "w-4",
        "h-4",
        "rounded",
        "border",
        "border-gray-300",
        "checked:bg-primary-800"
      )}
      disabled={true}
      type="checkbox"
      checked={checked}
    ></input>
  );
}
interface BadgeButtonProps {
  name: string;
  remove: (name: string) => void;
  displayAbbrev: string;
  className?: string;
}

export const BadgeButton = (props: BadgeButtonProps): ReactNode => {
  const { name, displayAbbrev, remove, className } = props;

  const onClick = useCallback(() => remove(name), [remove, name]);

  return (
    <button
      key={name}
      className={cn(
        "flex",
        "flex-row",
        "items-center",
        "space-x-[1px]",
        "pl-2.5",
        "pr-1",
        "bg-gray-100",
        "rounded-xl",
        className
      )}
      onClick={onClick}
      type="button"
    >
      <span className={cn("text-sm", "font-medium", "text-left")}>
        {displayAbbrev}
      </span>
      <RemoveIcon className={cn("w-4", "h-4", "text-gray-400")} />
    </button>
  );
};
export interface ComboBoxOption<T> {
  name: string;
  displayAbbrev: string;
  value: string;
  object: T;
}
export interface ComboBoxProps<T> {
  options: ComboBoxOption<T>[];
  selectedItems: string[];
  onChange: (items: string[]) => void;
  onLoadOptions?: (query: string) => void;
  placeholder?: string;
  className?: string;
  isLoading?: boolean;
  customRenderOptions?: (
    selected: boolean,
    active: boolean,
    option: ComboBoxOption<T>
  ) => ReactElement;
  disabled?: boolean;
}

export function ComboBox<T>(props: ComboBoxProps<T>): ReactElement {
  const {
    options,
    selectedItems,
    onChange,
    onLoadOptions,
    isLoading = false,
    className,
    placeholder,
    customRenderOptions,
    disabled,
  } = props;

  const inputBoxRef = useRef(null);
  const queryTextRef = useRef("");
  const [optionsMap, setOptionsMap] = useState<
    Record<string, ComboBoxOption<T> | undefined>
  >({});
  const [query, setQuery] = useState<string>("");

  useDebounce(
    () => {
      if (onLoadOptions != null) {
        onLoadOptions(query);
      }
    },
    250,
    [query]
  );

  const handleSelect = useCallback(
    (items: string[]) => {
      setOptionsMap((prev) => {
        const updatedMap = prev;
        for (const opt of items) {
          const optionInList = options.find((o) => o.value === opt);
          if (optionInList != null) {
            updatedMap[opt] = optionInList;
          }
        }
        return {
          ...updatedMap,
        };
      });
      onChange(items);
    },
    [onChange, options]
  );

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setQuery(e.target.value);
      queryTextRef.current = e.target.value;
    },
    [setQuery, queryTextRef]
  );

  const onInputFocus = useCallback(() => {
    if (queryTextRef.current !== "" && query === "") {
      setQuery(queryTextRef.current);
    }
  }, [query, queryTextRef]);

  const onInputKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (
        e.key === "Backspace" &&
        query === "" &&
        queryTextRef.current === "" &&
        selectedItems.length > 0
      ) {
        onChange(selectedItems.slice(0, -1));
      }
    },
    [query, selectedItems, onChange, queryTextRef]
  );

  const getDisplayValue = useCallback(() => {
    return queryTextRef.current;
  }, [queryTextRef]);

  const removeBadge = useCallback(
    (name: string) => {
      onChange(selectedItems.filter((v) => v !== name));
    },
    [selectedItems, onChange]
  );

  const filteredOptions: ComboBoxOption<T>[] =
    query === "" || onLoadOptions != null
      ? options
      : options.filter((o: ComboBoxOption<T>) =>
          containsSubstring(o.name, query)
        );

  const renderOptions = useCallback(
    (selected: boolean, active: boolean, option: ComboBoxOption<T>) => {
      if (customRenderOptions != null) {
        return customRenderOptions(selected, active, option);
      }
      return (
        <div className={cn("flex", "flex-row", "items-center", "space-x-2")}>
          <CheckboxIcon checked={selected} />
          <span className={cn("block", "truncate", "text-sm", "font-medium")}>
            {option.name}
          </span>
        </div>
      );
    },
    [customRenderOptions]
  );

  const { refs, floatingStyles } = useFloating({
    whileElementsMounted: autoUpdate,
    middleware: [
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
          });
        },
      }),
      flip(),
      shift(),
    ],
  });

  return (
    <Combobox<string[]>
      value={selectedItems}
      onChange={handleSelect}
      multiple={true}
      disabled={disabled}
    >
      {({ disabled }) => (
        <>
          <div className={cn("relative", className)}>
            <div
              className={cn(
                "relative",
                "flex",
                "flex-row",
                "w-full",
                "py-2",
                "pl-3",
                "cursor-default",
                "rounded-lg",
                "bg-white",
                { "!bg-gray-100 !ring-gray-100": disabled },
                "text-left",
                "ring-1",
                "ring-gray-300",
                "focus:outline-none",
                "sm:text-sm"
              )}
              ref={refs.setReference}
            >
              <div
                className={cn(
                  "flex",
                  "flex-row",
                  "items-center",
                  "flex-1",
                  "flex-wrap"
                )}
              >
                <div
                  className={cn(
                    "flex",
                    "flex-row",
                    "items-center",
                    "flex-1",
                    "flex-wrap"
                  )}
                >
                  {selectedItems.map((value) => {
                    const option = optionsMap[value];
                    return option ? (
                      <BadgeButton
                        className={cn("mr-2", "mb-1")}
                        key={option.name}
                        name={option.value}
                        displayAbbrev={option.displayAbbrev}
                        remove={removeBadge}
                      />
                    ) : null;
                  })}
                </div>
                <Combobox.Button as="div" className="w-full">
                  <Combobox.Input
                    displayValue={getDisplayValue}
                    onKeyDown={onInputKeyDown}
                    onChange={onInputChange}
                    onFocus={onInputFocus}
                    className={cn(
                      "h-6",
                      "pl-0",
                      "border-white",
                      "ring-0",
                      "focus:border-white",
                      "focus:ring-0",
                      "w-full",
                      "text-sm",
                      "lg:text-base",
                      { "!bg-gray-100 !border-gray-100": disabled }
                    )}
                    placeholder={placeholder}
                    ref={inputBoxRef}
                  />
                </Combobox.Button>
              </div>

              {isLoading && document.activeElement === inputBoxRef.current ? (
                <div
                  className={cn(
                    "inset-y-0",
                    "right-0",
                    "flex",
                    "items-center",
                    "px-3"
                  )}
                >
                  <LoadingSpinner size="s" />
                </div>
              ) : (
                <Combobox.Button
                  className={cn(
                    "inset-y-0",
                    "right-0",
                    "flex",
                    "items-center",
                    "px-3"
                  )}
                >
                  <ChevronDownIcon
                    className={cn("h-5", "w-5", "text-gray-400")}
                    aria-hidden="true"
                  />
                </Combobox.Button>
              )}
            </div>
            <FloatingPortal>
              {filteredOptions.length === 0 && query.length === 0 ? null : (
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Combobox.Options
                    className={cn(
                      "absolute",
                      "mt-1",
                      "max-h-60",
                      "w-full",
                      "overflow-auto",
                      "rounded-md",
                      "bg-white",
                      "py-1",
                      "text-base",
                      "shadow-lg",
                      "ring-1",
                      "ring-black/5",
                      "focus:outline-none",
                      "sm:text-sm",
                      "z-50"
                    )}
                    ref={refs.setFloating}
                    style={floatingStyles}
                  >
                    {filteredOptions.length === 0 && query !== "" ? (
                      <div
                        className={cn(
                          "relative",
                          "cursor-default",
                          "select-none",
                          "py-2",
                          "px-4",
                          "text-gray-700"
                        )}
                      >
                        <Trans i18nKey="reactComponent.comboBox.nothingFound" />
                      </div>
                    ) : (
                      filteredOptions.map((option) => (
                        <Combobox.Option
                          key={option.name}
                          value={option.value}
                          className={cn(
                            "relative",
                            "cursor-default",
                            "select-none",
                            "px-3",
                            "pt-3",
                            "last:pb-3",
                            "text-sm",
                            "text-gray-700"
                          )}
                        >
                          {({ selected, active }) =>
                            renderOptions(selected, active, option)
                          }
                        </Combobox.Option>
                      ))
                    )}
                  </Combobox.Options>
                </Transition>
              )}
            </FloatingPortal>
          </div>
        </>
      )}
    </Combobox>
  );
}
