import { PropsWithChildren, ReactElement } from "react";

import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Navigate } from "react-router-dom";
import AppRoutes from "../../routes/AppRoutes";
import { MainLayout } from "../MainLayout";

const ProtectedRoute = ({
  children,
}: PropsWithChildren): ReactElement | null => {
  const isLoggedIn = useSelector(
    (state: RootState) => state.auth.meUser != null
  );

  if (!isLoggedIn) {
    return <Navigate replace={true} to={AppRoutes.LogInScreen.render()} />;
  }

  return <MainLayout>{children}</MainLayout>;
};

export default ProtectedRoute;
