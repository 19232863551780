import React, {
  PropsWithChildren,
  ReactElement,
  Suspense,
  useEffect,
} from "react";
import { useSelector } from "react-redux";
import { I18nextProvider } from "react-i18next";
import i18next from "../i18n/i18n";
import LoadingScreen from "oneclick-component/src/components/LoadingScreen";
import { RootState } from "../store/store";

interface LocaleContextValue {}

export const LocaleContext = React.createContext<LocaleContextValue>(
  null as any
);

const LocaleProvider = (props: PropsWithChildren): ReactElement => {
  const authUser = useSelector((state: RootState) => state.auth);
  useEffect(() => {
    i18next
      .changeLanguage(authUser.meUser?.languagePreference)
      .catch(console.error);
  }, [authUser]);
  return (
    // ref https://github.com/facebook/react/issues/25629#issuecomment-1329098955
    <Suspense fallback={<LoadingScreen />}>
      <I18nextProvider i18n={i18next}>{props.children}</I18nextProvider>
    </Suspense>
  );
};

export default LocaleProvider;
