import React, {
  ReactElement,
  useEffect,
  PropsWithChildren,
  useCallback,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { AccountInfo } from "@azure/msal-browser";
import LoadingScreen from "oneclick-component/src/components/LoadingScreen";
import {
  useLazyAuthenticateHandlerAuthenticateGetQuery,
  useRevokeTokenHandlerMeRevokeTokenPostMutation as useRevokeTokenMutation,
  useLazyGenerateTokenHandlerTokenGetQuery as useLazyGenerateTokenQuery,
} from "oneclick-component/src/store/apis/enhancedApi";
import { config } from "../config";
import { loginSucceed, unauthenticated, initialized } from "../store/auth";
import { RootState } from "../store/store";
import AppRoutes from "../routes/AppRoutes";

interface AuthContextValue {
  login: () => Promise<void>;
  logout: () => Promise<void>;
  activeADAccount: AccountInfo | null;
}

export const AuthContext = React.createContext<AuthContextValue>(null as any);

const AuthProvider = (props: PropsWithChildren): ReactElement => {
  const { instance } = useMsal();
  const isMsalAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();
  const [authenticate] = useLazyAuthenticateHandlerAuthenticateGetQuery();
  const [revokeToken] = useRevokeTokenMutation();
  const [generateToken] = useLazyGenerateTokenQuery();
  const isInitialized = useSelector((state: RootState) => {
    return state.auth.isInitialized;
  });

  const verifyUser = useCallback(
    async (msAccessToken: string) => {
      const { data: user } = await authenticate({
        msalAccessToken: msAccessToken,
      });

      if (user == null) {
        dispatch(unauthenticated());
        return;
      }
      const { data: serverJwt } = await generateToken({
        msalAccessToken: msAccessToken,
      });
      if (serverJwt == null) {
        dispatch(unauthenticated());
        return;
      }

      dispatch(
        loginSucceed({
          meUser: user,
          token: serverJwt.token,
        })
      );
    },
    [authenticate, dispatch, generateToken]
  );

  const login = useCallback(async () => {
    try {
      // Redirect to Microsoft OAuth UI, leaving app
      await instance.loginRedirect({
        scopes: [`${config.msalClientId}/.default`],
      });
    } catch (err: unknown) {
      console.error(err);
      throw err;
    }
  }, [instance]);

  const logout = useCallback(async () => {
    await revokeToken();

    try {
      await instance.logoutRedirect({
        postLogoutRedirectUri: AppRoutes.LogoutRedirectScreen.render(),
      });
    } catch (err: unknown) {
      console.error(err);
      throw err;
    }
  }, [instance, revokeToken]);

  const tryGetAccessToken = useCallback(async () => {
    try {
      const authResult = await instance.acquireTokenSilent({
        scopes: [`${config.msalClientId}/.default`],
      });
      return authResult.accessToken;
    } catch (err: unknown) {
      // Expected to fail if refresh token expired
      console.log("Failed to obtain access token", err);
      return null;
    }
  }, [instance]);

  const initialize = useCallback(async () => {
    try {
      const redirectResult = await instance.handleRedirectPromise();
      let accessToken: string | null = null;
      // result == null if no redirect from Microsoft OAuth UI
      if (redirectResult == null) {
        if (isMsalAuthenticated) {
          accessToken = await tryGetAccessToken();
        }
      } else {
        accessToken = redirectResult.accessToken;
      }
      if (accessToken != null) {
        await verifyUser(accessToken);
      }
    } catch (err: unknown) {
      // TODO: Generic error page + error boundary
      console.error("init error and set active ad account to null", err);
      instance.setActiveAccount(null);
    }
  }, [tryGetAccessToken, verifyUser, instance, isMsalAuthenticated]);

  useEffect(() => {
    if (isInitialized) {
      return;
    }
    initialize()
      .then(() => {
        dispatch(initialized());
      })
      .catch(console.error);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const contextValue = useMemo(
    () => ({
      login,
      logout,
      activeADAccount: instance.getActiveAccount(),
    }),
    [login, logout, instance]
  );

  if (!isInitialized) {
    // TODO: Auth redirect loading screen design
    return <LoadingScreen />;
  }

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
