import { ReactElement } from "react";
import cn from "classnames";
import AvatarImage from "oneclick-component/src/components/AvatarImage";
import { MeUser, User } from "oneclick-component/src/store/apis/enhancedApi";
import { ReactAvatarProps } from "react-avatar";

interface UserAvatarProps extends ReactAvatarProps {
  user: MeUser | User | null;
}

const UserAvatarImage = (props: UserAvatarProps): ReactElement => {
  const { size, user, ...rest } = props;

  return (
    <span className={cn("relative", "inline-block")}>
      <AvatarImage
        profilePic={user?.profilePic ?? null}
        username={user?.name ?? ""}
        size={size ?? "40"}
        maxInitials={2}
        {...rest}
      />
    </span>
  );
};

export default UserAvatarImage;
