import { enhancedApi } from "./queries";

enhancedApi.enhanceEndpoints({
  endpoints: {
    batchCancelShiftsHandlerShiftsBatchCancelPost: {
      invalidatesTags: ["Shift", "Shifts"],
    },
    listShiftsHandlerShiftsGet: {
      providesTags: (_result, _error, args) => {
        if (args.shiftType === "INCIDENT") {
          return ["IncidentShifts"];
        }
        return ["Shifts"];
      },
    },
    createShiftHandlerShiftsPost: {
      invalidatesTags: ["Shifts", "IncidentShifts"],
    },
    editShiftHandlerShiftsShiftIdPost: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "Shift", id: arg.shiftId },
        "Shifts",
        "IncidentShifts",
      ],
    },
    makeExpireShiftHandlerShiftsShiftIdMakeExpirePost: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "Shift", id: arg.shiftId },
        "Shifts",
        "IncidentShifts",
      ],
    },
    getPtUserShiftCancelHandlerPtPtUserShiftCancelShiftIdGet: {
      providesTags: (_result, _error, args) => [
        { type: "PtUserShiftCancel", id: args.shiftId },
      ],
    },
    getCountByStationGroupsHandlerMasterShiftsGetCountByStationGroupsGet: {
      providesTags: ["MasterShiftAvailablePTUserCount"],
    },
    getAvailablePtUserByStationsHandlerMasterShiftsAvailablePtUsersByStationsGet:
      {
        providesTags: ["MasterShiftAvailablePTUsers"],
      },
    getMasterShiftRequestCountHandlerMasterShiftsMasterShiftIdRequestCountGet: {
      providesTags: (_result, _error, args) => [
        { type: "MasterShiftInvitationSentCount", id: args.masterShiftId },
      ],
    },
    getCountByStationsHandlerPartTimeUsersGetPtCountByStationsPost: {
      providesTags: ["MasterShiftAvailablePTUserCountByStations"],
    },
    getAvailablePtUserByStationsHandlerShiftsAvailablePtUsersByStationsGet: {
      providesTags: (_result, _error, args) => [
        { type: "AvailablePTUsers", id: args.shiftId },
        "AvailablePTUsers",
      ],
    },
    getAvailablePtCountHandlerShiftsAvailablePtCountGet: {
      providesTags: (_result, _error, args) => [
        { type: "AvailablePTUserCount", id: args.shiftId },
        "AvailablePTUserCount",
      ],
    },
    getAvailablePtCountByStationsGroupsHandlerShiftsAvailablePtCountByStationsPost:
      {
        providesTags: (_result, _error, args) => [
          { type: "AvailablePTUserCountByStations", id: args.shiftId },
          "AvailablePTUserCountByStations",
        ],
      },
    getShiftByIdHandlerShiftsShiftIdGet: {
      providesTags: (result) =>
        result
          ? [{ type: "Shift" as const, id: result.shift.id }, "Shift"]
          : ["Shift"],
    },
    hireHandlerShiftsShiftIdHirePost: {
      invalidatesTags: (_result, _error, arg) => [
        "AvailablePTUsers",
        "AvailablePTUserCount",
        "AvailablePTUserCountByStations",
        { type: "Shift", id: arg.shiftId },
        { type: "ShiftRequests", id: arg.shiftId },
        "Shifts",
      ],
    },
    contactHandlerShiftsShiftIdContactedPtUserPtUserIdPost: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "Shift", id: arg.shiftId },
        "Shifts",
        "IncidentShifts",
      ],
    },
    getShiftApplicationHandlerPtShiftsApplicationGet: {
      providesTags: ["ShiftApplication"],
    },
    getPtUsersHandlerPartTimeUsersListPost: {
      providesTags: ["PtUsers"],
    },
    getPtUserByIdHandlerPartTimeUsersPtUserIdGet: {
      providesTags: (result) =>
        result
          ? [{ type: "PtUser" as const, id: result.ptUser.id }, "PtUser"]
          : ["PtUser"],
    },
    deletePtUserHandlerPartTimeUsersPtUserIdDelete: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "PtUser", id: arg.ptUserId },
        "PtUsers",
      ],
    },
    createPtUserHandlerPartTimeUsersPost: {
      invalidatesTags: (result) => [
        result ? { type: "PtUser", id: result.ptUser.id } : "PtUser",
        "PtUsers",
      ],
    },
    updatePtUserHandlerPartTimeUsersPtUserIdPost: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "PtUser", id: arg.ptUserId },
        "PtUsers",
        "AvailablePTUsers",
        "AvailablePTUserCount",
        "AvailablePTUserCountByStations",
      ],
    },
    reactivatePtUserHandlerPartTimeUsersPtUserIdReactivatePost: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "PtUser", id: arg.ptUserId },
        "PtUsers",
      ],
    },
    schedulePtUserSuspensionHandlerPartTimeUsersPtUserIdScheduleSuspensionPost:
      {
        invalidatesTags: (_result, _error, arg) => [
          { type: "PtUser", id: arg.ptUserId },
          "PtUsers",
        ],
      },
    consentPtUserHandlerPtPartTimeUsersPtUserIdConsentPost: {
      invalidatesTags: (result) => [
        result ? { type: "PtUser", id: result.ptUser.id } : "PtUser",
        "PtUsers",
      ],
    },
    listBorrowingRequestsHandlerShiftsBorrowingShiftRequestsGet: {
      providesTags: (_result, _error, args) => {
        return [
          {
            type: "BorrowingShiftRequest",
            // Workaround to invalidate only the notification of user menu
            id: args.pageSize === 3 ? "UserMenu" : "Notification Screen",
          },
        ];
      },
    },
    ptUserListShiftsHandlerPtShiftsListGet: {
      providesTags: ["ShiftListForPtUser"],
    },
    markShiftReadHandlerPtShiftsShiftIdMarkReadPost: {
      invalidatesTags: ["ShiftListForPtUser", "PtUserUnreadShiftCount"],
    },
    likeShiftHandlerPtShiftsShiftIdLikePost: {
      invalidatesTags: (_result, _error, args) => {
        return [{ type: "PTGetShiftDetail", id: args.shiftId }];
      },
    },
    unlikeShiftHandlerPtShiftsShiftIdUnlikePost: {
      invalidatesTags: (_result, _error, args) => {
        return [{ type: "PTGetShiftDetail", id: args.shiftId }];
      },
    },
    bookmarkShiftHandlerPtShiftsShiftIdBookmarkPost: {
      invalidatesTags: (_result, _error, args) => {
        return [{ type: "PTGetShiftDetail", id: args.shiftId }];
      },
    },
    unbookmarkShiftHandlerPtShiftsShiftIdUnbookmarkPost: {
      invalidatesTags: (_result, _error, args) => {
        return [{ type: "PTGetShiftDetail", id: args.shiftId }];
      },
    },
    borrowingRequestHandlerShiftsRequestsShiftIdPtUserIdBorrowingPost: {
      invalidatesTags: (_result, _error, args) => {
        if (args.borrowingRequestParam.borrowingStatus === "contacted") {
          return [
            {
              type: "BorrowingShiftRequest",
              // Workaround to invalidate only the notification of user menu
              id: "UserMenu",
            },
            { type: "ShiftRequests", id: args.shiftId },
          ];
        }
        return [
          "BorrowingShiftRequest",
          { type: "ShiftRequests", id: args.shiftId },
        ];
      },
    },
    cancelHireHandlerShiftsShiftIdCancelHirePtUserPtUserIdPost: {
      invalidatesTags: (_result, _error, arg) => [
        "AvailablePTUsers",
        "AvailablePTUserCount",
        "AvailablePTUserCountByStations",
        { type: "Shift", id: arg.shiftId },
        { type: "ShiftRequests", id: arg.shiftId },
        "Shifts",
        "IncidentShifts",
      ],
    },
    listPtUserWorkingStatusRecordHandlerPartTimeUsersWorkingStatusRecordsPost: {
      providesTags: (result) => {
        if (result == null || result.items.length === 0) {
          return ["PtUserWorkingStatuses"];
        }

        // only 1 pt_user, not list
        if (result.items.length === 1) {
          return [
            { type: "PtUserWorkingStatus", id: result.items[0].ptUserId },
          ];
        }

        // list
        return [
          ...result.items.map(({ ptUserId }) => ({
            type: "PtUserWorkingStatus" as const,
            id: ptUserId,
          })),
          "PtUserWorkingStatuses",
        ];
      },
    },
    upsertPtUserWorkingStatusRecordHandlerPartTimeUsersPtUserIdWorkingStatusRecordPost:
      {
        invalidatesTags: (_result, _error, arg) => [
          { type: "PtUserWorkingStatus", id: arg.ptUserId },
          "PtUserWorkingStatuses",
        ],
      },
    deletePtUserWorkingStatusRecordHandlerPartTimeUsersPtUserIdWorkingStatusRecordDelete:
      {
        invalidatesTags: (_result, _error, arg) => [
          { type: "PtUserWorkingStatus", id: arg.ptUserId },
          "PtUserWorkingStatuses",
        ],
      },
    listNotificationsHandlerNotificationsGet: {
      providesTags: (_result, _error, args) => {
        return [
          {
            type: "Notifications",
            // Workaround to invalidate only the notification of user menu
            id: args.pageSize === 3 ? "UserMenu" : "Notification Screen",
          },
        ];
      },
    },
    readNotificationHandlerNotificationsNotificationIdReadPost: {
      // Workaround to invalidate only the notification of user menu
      invalidatesTags: () => {
        return [
          {
            type: "Notifications",
            id: "UserMenu",
          },
        ];
      },
    },
    getUnreadChatCountHandlerChatsUnreadChatCountGet: {
      providesTags: ["UnreadChatCount"],
    },
    markChatReadHandlerChatsChatIdMarkReadPost: {
      invalidatesTags: ["UnreadChatCount"],
    },
    markShiftRequestReadHandlerPtShiftRequestsMarkReadPost: {
      invalidatesTags: ["ShiftRequests", "PtUserUnreadShiftCount"],
    },
    getUnreadShiftCountHandlerPtShiftsPtUnreadShiftCountGet: {
      providesTags: ["PtUserUnreadShiftCount"],
    },
    listAnnouncementHandlerAnnouncementsListGet: {
      providesTags: ["Announcements"],
    },
    createAnnouncementHandlerAnnouncementsPost: {
      invalidatesTags: ["Announcements"],
    },
    listShiftRequestsWithNumberingHandlerShiftsShiftIdRequestsGet: {
      providesTags: (_result, _error, args) => {
        return [{ type: "ShiftRequests", id: args.shiftId }, "ShiftRequests"];
      },
      serializeQueryArgs: ({ queryArgs }) => {
        const newArgs = { ...queryArgs };
        if (newArgs.pageIndex != null) {
          delete newArgs.pageIndex;
        }
        return newArgs;
      },
      merge: (currentCache, newItems) => {
        if (currentCache.results != null) {
          const newIds = newItems.results.map((req) => req.ptUserId);
          const cachedResult = currentCache.results.filter(
            (req) => !newIds.includes(req.ptUserId)
          );
          return {
            ...currentCache,
            ...newItems,
            results: [...cachedResult, ...newItems.results],
          };
        }
        return newItems;
      },
    },
    getMasterShiftDetailHandlerPtMasterShiftsJobIdGet: {
      providesTags: ["MasterShiftApplications"],
    },
    applyMasterShiftByIdHandlerPtMasterShiftsJobIdApplyPost: {
      invalidatesTags: (_result, _error, args) => {
        const tags = args.applyMasterShiftRequest.shiftIds.map((shiftId) => {
          return {
            type: "PTGetShiftDetail" as "PTGetShiftDetail",
            id: shiftId,
          };
        });
        return ["MasterShiftApplications", "ShiftListForPtUser", ...tags];
      },
    },
    applyShiftHandlerPtShiftsShiftIdApplyPost: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "PTGetShiftDetail", id: arg.shiftId },
        { type: "PTGetShiftRequestByShift", id: arg.shiftId },
        { type: "PtUserShiftCancel", id: arg.shiftId },
        "ShiftListForPtUser",
        "PtUserUnreadShiftCount",
      ],
    },
    cancelApplyShiftHandlerPtShiftsShiftIdCancelApplyPost: {
      invalidatesTags: (_result, _error, arg) => [
        { type: "PTGetShiftDetail", id: arg.shiftId },
        { type: "PTGetShiftRequestByShift", id: arg.shiftId },
        "ShiftListForPtUser",
        "PtUserUnreadShiftCount",
      ],
    },
    getShiftByIdHandlerPtShiftsShiftIdGet: {
      providesTags: (_result, _error, args) => {
        return [
          { type: "PTGetShiftDetail", id: args.shiftId },
          "PTGetShiftDetail",
        ];
      },
    },
    getShiftRequestByIdHandlerPtShiftRequestsShiftsShiftIdGet: {
      providesTags: (_result, _error, args) => {
        return [
          { type: "PTGetShiftRequestByShift", id: args.shiftId },
          "PTGetShiftRequestByShift",
        ];
      },
    },
    validateInviteHandlerUsersValidateInviteGet: {
      providesTags: ["ValidateStationManagerInvite"],
    },
    inviteManagerToStationHandlerUsersInviteUserIdPost: {
      invalidatesTags: ["ValidateStationManagerInvite"],
    },
    listShiftFullTimeAttendanceByShiftHandlerShiftFullTimeAttendancesGet: {
      providesTags: (_result, _error, args) => {
        return [{ type: "ShiftFullTimeAttendance", id: args.shiftId }];
      },
    },
    removeShiftFullTimeAttendanceHandlerShiftFullTimeAttendancesAttendanceIdDelete:
      {
        invalidatesTags: (_result) => [
          { type: "ShiftFullTimeAttendance", id: _result?.item.shiftId },
        ],
      },
    addShiftFullTimeAttendanceHandlerShiftFullTimeAttendancesPost: {
      invalidatesTags: (_result, _error, args) => {
        return [{ type: "ShiftFullTimeAttendance", id: args.shiftId }];
      },
    },
  },
});

// `enhanceEndpoint` modify the existing object so just export all from queries here
export * from "./queries";
