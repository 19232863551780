import React, { useMemo, useCallback, ChangeEvent } from "react";
import cn from "classnames";
import { UseFormSetValue, FieldErrors, Control } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import {
  FormTextInput,
  FormField,
  FormTextArea,
} from "oneclick-component/src/components/forms";
import {
  Select,
  Option,
  Checkbox,
} from "oneclick-component/src/components/inputs";
import { dateTimeNow } from "oneclick-component/src/utils/datetime";
import {
  WorkingStation,
  useListIncidentContentTemplateHandlerIncidentContentTemplatesGetQuery as useListIncidentTemplate,
} from "oneclick-component/src/store/apis/enhancedApi";
import { localizeString } from "oneclick-component/src/utils/localize";
import IncidentDateSection from "./DateSection/IncidentDateSection";
import { MultiShiftFormValues } from "./form";
import { LazyLoadWorkingStationSelectionDropdown } from "../WorkingStationSelectionDropdown";

interface Props
  extends React.DetailedHTMLProps<
    React.FormHTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  > {
  setValue: UseFormSetValue<MultiShiftFormValues>;
  errors: FieldErrors<MultiShiftFormValues>;
  control: Control<MultiShiftFormValues>;
  formValues: MultiShiftFormValues;
  onSupportStationSelected?: (supportStation: WorkingStation | null) => void;
  partTimeGroupOptions: Option<string>[];
  onSubmit: () => void;
  isEditing: boolean;
}

const IncidentFormContent = (props: Props): React.ReactElement => {
  const now = useMemo(() => dateTimeNow(), []);
  const {
    control,
    errors,
    setValue,
    formValues,
    isEditing,
    onSupportStationSelected,
    partTimeGroupOptions,
  } = props;
  const { data } = useListIncidentTemplate();
  const { t } = useTranslation();

  const setTimeRangeStartMinuteByIndex = useCallback(
    (index: number, value: number) => {
      setValue(`timeRanges.${index}.startTime.minute`, value);
    },
    [setValue]
  );

  const setTimeRangeEndMinuteByIndex = useCallback(
    (index: number, value: number | null) => {
      setValue(`timeRanges.${index}.endTime.minute`, value);
    },
    [setValue]
  );

  const handleSelectTemplate = useCallback(
    (id: number) => {
      if (data == null) {
        return;
      }
      const template = data.items.filter((t) => t.id === id);
      if (template.length > 0) {
        setValue("title", localizeString(template[0].title));
        setValue("description", localizeString(template[0].body));
      }
    },
    [data, setValue]
  );

  const handlePtTypeSelect = useCallback(
    (val: ChangeEvent<HTMLInputElement>) => {
      if (formValues.ptType.includes(val.target.value)) {
        setValue(
          "ptType",
          formValues.ptType.filter((t) => t !== val.target.value)
        );
      } else {
        setValue("ptType", [...formValues.ptType, val.target.value]);
      }
    },
    [setValue, formValues]
  );

  return (
    <div className={cn("flex", "flex-col", "gap-y-5")}>
      <div>
        <p className={cn("text-sm", "font-normal", "leading-5", "mb-2")}>
          <Trans i18nKey="shift.create.form.incident.typeTitle" />
        </p>
        <span
          className={cn(
            "bg-red-500",
            "text-xs",
            "leading-5",
            "font-normal",
            "text-white",
            "px-2",
            "py-2"
          )}
        >
          INC
        </span>
      </div>
      <div className={cn("flex", "flex-col", "md:flex-row", "gap-5")}>
        <FormField.Control
          key="supportStationId"
          name="supportStationId"
          control={control}
        >
          {({ field }) => (
            <FormField.Container
              className="flex-1"
              label={t("shift.create.form.supportStation")}
              errorMessage={errors.supportStationId?.message}
              required={true}
            >
              <LazyLoadWorkingStationSelectionDropdown
                selectedWorkingStationId={field.value}
                onWorkingStationSelected={onSupportStationSelected}
                onChange={field.onChange}
                disabled={isEditing}
              />
            </FormField.Container>
          )}
        </FormField.Control>
        <FormTextInput
          className="flex-1"
          name={"fulfillmentCount"}
          control={control}
          label={t("shift.create.form.fulfillmentCount")}
          required={true}
          type="number"
          min={1}
          errorMessage={errors.fulfillmentCount?.message}
        />
      </div>
      <div>
        <p className={cn("text-sm", "font-normal", "leading-5", "mb-2")}>
          <Trans i18nKey="shift.create.form.partTimeType" />*
        </p>
        <div className={cn("flex", "flex-row", "gap-x-8")}>
          {partTimeGroupOptions
            .filter((opt) => opt.name !== "CCO")
            .map((opt) => (
              <FormField.Control
                name="ptType"
                control={control}
                key={opt.value}
              >
                {({ field }) => (
                  <Checkbox
                    name={opt.name}
                    label={opt.name}
                    value={opt.value}
                    checked={field.value.includes(opt.value)}
                    onChange={handlePtTypeSelect}
                  />
                )}
              </FormField.Control>
            ))}
        </div>
        {errors.ptType?.message ? (
          <p className={cn("text-xs", "text-red-500", "mt-1")}>
            {errors.ptType.message}
          </p>
        ) : null}
      </div>
      <IncidentDateSection
        now={now}
        errors={errors}
        isEditing={isEditing}
        control={control}
        setTimeRangeStartMinuteByIndex={setTimeRangeStartMinuteByIndex}
        setTimeRangeEndMinuteByIndex={setTimeRangeEndMinuteByIndex}
      />
      <div>
        <p className={cn("text-sm", "font-normal", "leading-5", "mb-2")}>
          <Trans i18nKey="shift.create.form.incident.template.title" />
        </p>
        <Select
          options={
            data?.items.map((template) => {
              return {
                name: localizeString(template.name),
                value: template.id,
              };
            }) ?? []
          }
          placeholder={t("shift.create.form.incident.template.placeholder")}
          value={0}
          onChange={handleSelectTemplate}
        />
      </div>
      <FormTextInput
        name={"title"}
        control={control}
        label={t("shift.create.form.title")}
        required={true}
        errorMessage={errors.title?.message}
        placeholder={t("shift.create.form.incidentTitle.placeholder")}
      />
      <FormTextArea
        className={"w-full"}
        name={"description"}
        control={control}
        rows={15}
        label={t("shift.create.form.description")}
        required={true}
        placeholder={t("shift.create.form.incidentDescription.placeholder")}
      />
    </div>
  );
};

export default IncidentFormContent;
