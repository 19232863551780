import React, {
  ForwardedRef,
  ReactElement,
  useCallback,
  useState,
} from "react";
import cn from "classnames";

export interface TextInputProps
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "onChange"
  > {
  hasError?: boolean;
  prefixDecorator?: ReactElement;
  suffixDecorator?: ReactElement;
  className?: string;
  onCustomBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (value: string) => void;
  transform?: (old: string) => string;
}

export const TextInput = React.forwardRef(function TextInput(
  props: TextInputProps,
  ref: ForwardedRef<HTMLInputElement>
): ReactElement {
  const {
    hasError = false,
    prefixDecorator,
    suffixDecorator,
    onFocus,
    onBlur,
    className,
    disabled,
    onChange,
    onCustomBlur,
    transform,
    ...rest
  } = props;
  const [isInputFocused, setIsInputFocused] = useState(false);

  const onInputFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      setIsInputFocused(true);
      onFocus?.(e);
    },
    [onFocus]
  );

  const onInputBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      setIsInputFocused(false);
      onBlur?.(e);
      onCustomBlur?.(e);
    },
    [onBlur, onCustomBlur]
  );
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue =
        transform != null ? transform(e.target.value) : e.target.value;
      onChange?.(newValue);
    },
    [onChange, transform]
  );

  return (
    <div
      className={cn(
        "flex",
        "rounded-md",
        "px-3",
        disabled ? "bg-gray-200" : "bg-white",
        isInputFocused ? "ring-2" : "ring-1",
        isInputFocused && (hasError ? "ring-red-500" : "ring-primary-600"),
        !isInputFocused && (hasError ? "ring-red-300" : "ring-gray-300"),
        className
      )}
    >
      {prefixDecorator != null ? (
        <div className={cn("flex", "items-center", "rounded-l-md")}>
          {prefixDecorator}
        </div>
      ) : null}
      <input
        ref={ref}
        className={cn(
          "flex-1",
          "px-0",
          "outline-0",
          "ring-0",
          "border-0",
          "focus:outline-0",
          "focus:ring-0",
          "focus:border-0",
          "min-w-0",
          "placeholder:text-black/25",
          "sm:text-sm",
          "sm:leading-6",
          {
            "text-gray-900": !disabled,
            "bg-gray-200 text-black/24": disabled,
          }
        )}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
        disabled={disabled}
        onChange={handleChange}
        {...rest}
      />
      {suffixDecorator != null ? (
        <div className={cn("flex", "items-center", "rounded-r-md")}>
          {suffixDecorator}
        </div>
      ) : null}
    </div>
  );
});
