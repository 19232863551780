// ref https://github.com/oursky/clublike_v2/blob/a5ca0eb0f533d981d82a1f08548078eb0c34ff91/src/components/OfferListScreen/OfferListScreenViewState.ts#L10

import { ShiftRequest } from "oneclick-component/src/store/apis/enhancedApi";

export const enum ViewStateStatus {
  Loading = 0,
  Loaded = 1,
  Empty = 2,
  Error = 3,
}

export type ViewState =
  | ViewStateLoading
  | ViewStateLoaded
  | ViewStateEmpty
  | ViewStateError;

export interface ViewStateLoading {
  status: ViewStateStatus.Loading;
}
export function makeViewStateLoading(): ViewStateLoading {
  return { status: ViewStateStatus.Loading };
}

export interface ViewStateLoaded {
  status: ViewStateStatus.Loaded;
  shiftRequestList: ShiftRequest[];
  total: number;
  pageIndex: number;
}

export function makeViewStateLoaded(
  shiftRequestList: ShiftRequest[],
  total: number,
  pageIndex: number
): ViewStateLoaded {
  return {
    status: ViewStateStatus.Loaded,
    shiftRequestList,
    total,
    pageIndex,
  };
}

export interface ViewStateEmpty {
  status: ViewStateStatus.Empty;
}

export function makeViewStateEmpty(): ViewStateEmpty {
  return {
    status: ViewStateStatus.Empty,
  };
}
export interface ViewStateError {
  status: ViewStateStatus.Error;
  error: unknown;
}
export function makeViewStateError(error: unknown): ViewStateError {
  return {
    status: ViewStateStatus.Error,
    error,
  };
}
