import Avatar, { ReactAvatarProps } from "react-avatar";
import cn from "classnames";
import { ReactElement } from "react";

import { Asset } from "../../store/apis/enhancedApi";

interface AvatarProps extends ReactAvatarProps {
  username: string;
  profilePic: Asset | null;
  size: string;
}

const AvatarImage = (props: AvatarProps): ReactElement => {
  const { size, username, ...rest } = props;
  return (
    <span className={cn("relative", "inline-block")}>
      <Avatar
        className="!bg-gray-500"
        round={true}
        size={size}
        name={username}
        alt={username}
        {...rest}
      />
    </span>
  );
};

export default AvatarImage;
