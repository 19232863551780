import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { MultiShiftFormValues } from "../components/MultiShiftForm/form";

interface MultiShiftFormState extends MultiShiftFormValues {
  filled: boolean;
}

const initialState: MultiShiftFormState = {
  filled: false,
  stationId: "" as any as number,
  supportStationId: "" as any as number,
  rate: "" as any as number,
  ptType: [],
  recurringOptions: {
    recurringType: "single",
    date: "",
  },
  fulfillmentCount: "" as any as number,
  timeRanges: [
    {
      startTime: {
        hour: "" as any as number,
        minute: "" as any as number,
      },
      endTime: {
        hour: null,
        minute: null,
      },
    },
  ],
  title: "",
  description: "",
  shiftType: "REGULAR",
  isAppliableToAllAtT3: false,
};

const multiShiftCreateFormSlice = createSlice({
  name: "multiShiftCreateForm",
  initialState,
  reducers: {
    save: (state, action: PayloadAction<MultiShiftFormValues>) => {
      return {
        ...state,
        ...{
          filled: true,
          ...action.payload,
        },
      };
    },
    clear: (state) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { save, clear } = multiShiftCreateFormSlice.actions;

export default multiShiftCreateFormSlice.reducer;
