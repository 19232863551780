import { ReactElement } from "react";
import { Toaster } from "react-hot-toast";
import { MsalProvider } from "@azure/msal-react";

import { MediaQueryProvider } from "oneclick-component/src/providers";
import AppRouter from "./routes/AppRouter";
import AuthProvider from "./providers/AuthProvider";
import TemplateMessageProvider from "./providers/TemplateMessageProvider";
import LocaleProvider from "./providers/LocaleProvider";
import { MsalAuthService } from "./services/msalAuth";

import "./App.css";
import WebSocketProvider from "./providers/WebSocketProvider";
import { StoreProvider } from "./providers/StoreProvider";
import FeatureConfigProvider from "./providers/FeatureConfigProvider";
import NotificationProvider from "./providers/NotificationProvider";
import { AudioPlayerProvider } from "./providers/AudioPlayerProvider";

const msalAuthService = MsalAuthService.getInstance();

function App(): ReactElement {
  return (
    <MsalProvider instance={msalAuthService.msalInstance}>
      <StoreProvider>
        <TemplateMessageProvider>
          <AuthProvider>
            <LocaleProvider>
              <MediaQueryProvider>
                <WebSocketProvider>
                  <NotificationProvider>
                    <FeatureConfigProvider>
                      <AudioPlayerProvider>
                        <Toaster containerClassName="!top-16 !right-0" />
                        <AppRouter />
                      </AudioPlayerProvider>
                    </FeatureConfigProvider>
                  </NotificationProvider>
                </WebSocketProvider>
              </MediaQueryProvider>
            </LocaleProvider>
          </AuthProvider>
        </TemplateMessageProvider>
      </StoreProvider>
    </MsalProvider>
  );
}

export default App;
