import { ReactElement, useCallback, useMemo, useState } from "react";
import cn from "classnames";
import { Trans, useTranslation } from "react-i18next";
import { Modal } from "oneclick-component/src/components/Modal";
import { PTUserWeekCardDialogForm } from "./WeekCardDialogForm";
import { Dialog } from "@headlessui/react";
import { ControllerRenderProps } from "react-hook-form";
import { RegularShift, IntraDayRegularShift } from "../../models";
import { DAYS_OF_WEEK, DAYS_OF_WEEK_NAMES } from "../../constants/dayOfWeek";
import { EditIcon } from "../../icon";
import { CreatePartTimeForm } from "../../screen/CreatePartTimeScreen/form";
import { EditPartTimeForm } from "../../screen/EditPartTimeScreen/form";

interface Props {
  dayOfWeek: number;
  regularShifts: RegularShift[];
  setRegularShifts: (v: RegularShift[]) => void;
}

export const PTUserFormWeekCard = (props: Props): ReactElement => {
  const { t } = useTranslation();
  const { dayOfWeek, regularShifts, setRegularShifts } = props;
  const dayOfWeekName = DAYS_OF_WEEK_NAMES[dayOfWeek];
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const onCloseModal = useCallback(() => setIsModalOpen(false), []);
  const onClickEditButton = useCallback(() => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  const description = useMemo(() => {
    return regularShifts.length > 0
      ? regularShifts.map((a) => a.startTime.concat("-", a.endTime)).join(", ")
      : t("partTime.form.weekCard.content.noRegularShift");
  }, [regularShifts, t]);

  const handleChangeRegularShifts = useCallback(
    (vals: IntraDayRegularShift[]) =>
      setRegularShifts(vals.map((val) => ({ ...val, dayOfWeek }))),
    [setRegularShifts, dayOfWeek]
  );

  return (
    <div
      className={cn(
        "p-4",
        "w-full",
        "ring-1",
        "ring-gray-300",
        "rounded-md",
        "flex",
        "flex-row",
        "items-center",
        "space-x-3"
      )}
    >
      <div className={cn("flex-1", "space-y-1")}>
        <p className={cn("text-base", "font-medium", "text-black")}>
          <Trans i18nKey={`partTime.form.weekCard.title.${dayOfWeekName}`} />
        </p>
        <p className={cn("text-sm", "font-normal", "text-gray-500")}>
          {description}
        </p>
      </div>
      <button type="button" onClick={onClickEditButton}>
        <EditIcon className={cn("w-6", "h-6")} />
      </button>
      <Modal
        onClose={onCloseModal}
        isOpen={isModalOpen}
        shouldCloseOnClickBackground={false}
      >
        <div className={cn("sm:flex", "sm:items-start")}>
          <div className={cn("text-left", "mt-0", "w-full")}>
            <Dialog.Title
              className={cn("text-black", "text-lg", "font-medium")}
            >
              {t("partTime.form.weekCard.editModal.title", {
                dayOfWeek: t(
                  `partTime.form.weekCard.editModal.title.${dayOfWeekName}`
                ),
              })}
            </Dialog.Title>
            <div className="mt-2">
              <PTUserWeekCardDialogForm
                className="pt-5"
                onCloseModal={onCloseModal}
                regularShifts={regularShifts}
                setRegularShifts={handleChangeRegularShifts}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

interface ListProps {
  field:
    | ControllerRenderProps<CreatePartTimeForm, "regularShiftSchedule">
    | ControllerRenderProps<EditPartTimeForm, "regularShiftSchedule">;
  className?: string;
}
export function PTUserFormWeekCardList(props: ListProps): ReactElement {
  const { field, className } = props;
  const handleChangeRegularShifts = useCallback(
    (dayOfWeek: number) => (newDayRegularShift: RegularShift[]) => {
      const newRegularShifts = field.value
        .filter((a) => a.dayOfWeek !== dayOfWeek)
        .concat(newDayRegularShift);
      field.onChange(newRegularShifts);
    },
    [field]
  );
  return (
    <div className={className}>
      {DAYS_OF_WEEK.map((dow) => (
        <PTUserFormWeekCard
          key={dow}
          dayOfWeek={dow}
          regularShifts={field.value.filter((a) => a.dayOfWeek === dow)}
          setRegularShifts={handleChangeRegularShifts(dow)}
        />
      ))}
    </div>
  );
}
