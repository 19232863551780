import { useSelector } from "react-redux";
import { RootState } from "../store/store";

export const useIsRole = (role: string): boolean => {
  return useSelector(
    (state: RootState) => state.auth.meUser?.adminRole === role
  );
};

export const useIsSuperAdmin = (): boolean => {
  return useIsRole("ADMIN");
};

export const useIsLandlord = (): boolean => {
  return useIsRole("LANDLORD");
};
export const useIsManager = (): boolean => {
  return useIsRole("MANAGER");
};
