import React, { ReactElement, useMemo } from "react";
import cn from "classnames";
import { Badge } from "./Badge";
import { localizeString } from "../../utils/localize";
import {
  BriefStationWithWorkingStation,
  BriefStation,
  Station,
  StationTeam,
  WorkingStation,
} from "../../store/apis/enhancedApi";
import { FALLBACK_COLOR, HEXCOLOR_REGEX } from "../../constants/hexcolor";

function getBadgeColorHexCode(
  station: BriefStationWithWorkingStation,
  stationTeam?: StationTeam | null
) {
  if (stationTeam != null) {
    return stationTeam.colorHexcode;
  }

  if (station.workingStations.length > 0) {
    return station.workingStations[0].colorHexcode;
  }

  return FALLBACK_COLOR;
}

interface Props {
  className?: string;
  station:
    | BriefStationWithWorkingStation
    | WorkingStation
    | Station
    | BriefStation;
  stationTeam?: StationTeam | null;
  showStationName?: boolean;
  showStationTeam?: boolean;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const StationBadge = React.memo((props: Props): ReactElement => {
  const {
    className,
    station,
    stationTeam,
    showStationTeam = false,
    showStationName = false,
  } = props;

  const displayStationTeam = showStationTeam ? stationTeam : null;

  const rawColorHexCode: string = useMemo(() => {
    if ("colorHexcode" in station) {
      return station.colorHexcode ?? "000000";
    }
    return getBadgeColorHexCode(station, displayStationTeam);
  }, [displayStationTeam, station]);

  const colorHexcode: string = useMemo(() => {
    return HEXCOLOR_REGEX.test(rawColorHexCode)
      ? rawColorHexCode
      : FALLBACK_COLOR;
  }, [rawColorHexCode]);

  const textContent = useMemo(() => {
    const name = showStationName
      ? localizeString(station.name)
      : station.shortCode;
    if (displayStationTeam != null) {
      return `${name} - ${displayStationTeam.teamCode}`;
    }
    return name;
  }, [station, displayStationTeam, showStationName]);

  const backgroundColorStyle = useMemo(() => {
    if (colorHexcode.length <= 6) {
      return `#${colorHexcode}0D`;
    }
    return `#${colorHexcode.substring(0, 6)}0D`;
  }, [colorHexcode]);

  return (
    <Badge
      // Note(peter)#450: Tailwind does not support dynamic value, use inline css instead
      style={{
        color: `#${colorHexcode}`,
        borderWidth: 1,
        borderColor: `#${colorHexcode}`,
        backgroundColor: backgroundColorStyle,
      }}
      className={cn("font-medium", "text-xs", className)}
    >
      {textContent}
    </Badge>
  );
});
