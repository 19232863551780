import * as z from "zod";
import { messageAttachmentSchema } from "./messageAttachment";
import { briefStationWithWorkingStationSchema } from "./station";
import { ptuserSchema, userSchema } from "./user";

export const messageStatusSchema = z.enum([
  "delivered",
  "sent",
  "read",
  "enqueued",
  "received",
  "failed",
]);

export const messageSchema = z.object({
  id: z.number(),
  type: z.enum(["chat", "system"]),
  content: z.string().nullable(),
  isPublic: z.boolean(),
  status: messageStatusSchema,
  externalId: z.string().nullable(),
  chatId: z.number(),
  templateId: z.number().nullable(),
  templateParam: z.instanceof(Object).nullable(),
  createdAt: z.string(),
  user: userSchema.nullable(),
  userStation: briefStationWithWorkingStationSchema.nullable(),
  ptUser: ptuserSchema.nullable(),
  attachments: z.array(messageAttachmentSchema),
});
