import { ReactElement, useCallback, useEffect } from "react";
import cn from "classnames";
import { shallowEqual, useDispatch, useSelector, useStore } from "react-redux";
import {
  back,
  dismissChatDialog,
  startFetchChatById,
  finishFetchChatById,
  fetchChatByIdFailed,
} from "../../store/chatState";
import { useLazyGetChatHandlerChatsChatIdGetQuery as useGetChat } from "oneclick-component/src/store/apis/enhancedApi";
import { LoadingSpinner } from "oneclick-component/src/components/LoadingSpinner";

import { useShowError } from "../../hooks/useShowError";
import {
  ChatDialogChatListHeader,
  ChatDialogMessageListHeader,
} from "./ChatDialogHeader";
import { ChatList } from "./ChatList";
import { MessageList } from "./MessageList";
import { RootState } from "../../store/store";

export const ChatDialog = (): ReactElement => {
  const dispatch = useDispatch();
  const { showError } = useShowError();
  const store = useStore<RootState>();
  const [getChat] = useGetChat();

  const { isPrivate, selectedChatId, isFetchingSelectedChat, selectedChat } =
    useSelector((state: RootState) => {
      if (state.chatState.selectedChatId == null) {
        return {
          isPrivate: false,
          isFetchingSelectedChat: false,
          selectedChatId: null,
          selectedChat: null,
        };
      }

      const selectedChatId = state.chatState.selectedChatId;
      const chatMessages = state.chatState.chatMessages[selectedChatId];

      return {
        isPrivate: chatMessages?.isPrivate ?? false,
        selectedChatId,
        isFetchingSelectedChat:
          state.chatState.isFetchingChatById[selectedChatId] ?? false,
        selectedChat: state.chatState.chatById[selectedChatId] ?? null,
      };
    }, shallowEqual);

  const getSelectedChat = useCallback(
    async (chatId: number) => {
      try {
        dispatch(startFetchChatById({ chatId }));
        const resp = await getChat({
          chatId,
        }).unwrap();
        dispatch(
          finishFetchChatById({ chat: resp.chat, chatStatus: resp.readStatus })
        );
      } catch (err: unknown) {
        dispatch(fetchChatByIdFailed({ chatId }));
        dispatch(back());
        showError(err, "chatDialog.messageList.getChat.error.title");
      }
    },
    [dispatch, getChat, showError]
  );

  useEffect(() => {
    const { chatById, isFetchingChatById } = store.getState().chatState;
    if (
      selectedChatId != null &&
      chatById[selectedChatId] == null &&
      !isFetchingChatById[selectedChatId]
    ) {
      getSelectedChat(selectedChatId).catch((err) => {
        throw err;
      });
    }
  }, [selectedChatId, getSelectedChat, store]);

  const onBack = useCallback(() => {
    dispatch(back());
  }, [dispatch]);

  const onDismiss = useCallback(() => {
    dispatch(dismissChatDialog());
  }, [dispatch]);

  return (
    <>
      {selectedChatId == null ? (
        <section
          className={cn(
            "z-30",
            "fixed",
            "bottom-0", // full screen in mobile
            "right-0",
            "left-0",
            "top-0",
            "w-auto",
            "sm:left-auto", // right side bar in desktop
            "sm:top-[4.0625rem]", // header + border 1px
            "sm:w-[24.375rem]",
            "sm:shadow-xl"
          )}
        >
          <div
            className={cn("flex", "flex-col", "h-full", "w-full", "bg-white")}
          >
            <ChatDialogChatListHeader onDismiss={onDismiss} />
            <ChatList />
          </div>
        </section>
      ) : (
        <section
          className={cn(
            "z-30",
            "fixed",
            "bottom-0", // full screen in mobile
            "right-0",
            "left-0",
            "top-0",
            "pointer-events-none", // avoid container capturing pointer event
            "w-auto",
            "h-auto",
            "sm:right-18", // floating panel stick to bottom in desktop
            "sm:left-auto",
            "sm:top-auto",
            "sm:w-[24.375rem]",
            "sm:h-[43.75rem]",
            "sm:max-h-full", // container should not exceed screen
            "sm:pt-18" // header + 8px
          )}
        >
          <div
            className={cn(
              "flex",
              "flex-col",
              "pointer-events-auto", // allow dialog content capture pointer event
              "h-full",
              "w-full",
              "bg-white",
              "sm:rounded-lg",
              "sm:overflow-hidden",
              "sm:shadow-xl"
            )}
          >
            {isFetchingSelectedChat || selectedChat == null ? (
              <div
                className={cn(
                  "flex",
                  "flex-1",
                  "items-center",
                  "justify-center"
                )}
              >
                <LoadingSpinner size="l" />
              </div>
            ) : (
              <>
                <ChatDialogMessageListHeader
                  isPrivate={isPrivate}
                  chat={selectedChat}
                  onBack={onBack}
                  onDismiss={onDismiss}
                />
                <MessageList chat={selectedChat} />
              </>
            )}
          </div>
        </section>
      )}
    </>
  );
};
