import React from "react";
import { ShiftRateViewModel } from "./model";
import IncidentBadge from "./IncidentBadge";
import ShiftRateBadge from "./ShiftRateBadge";

interface Props {
  rate: ShiftRateViewModel | null | undefined;
  isIncident: boolean;
  className?: string;
  isHideCco?: boolean;
}

const ShiftBadge = (props: Props): React.ReactElement | null => {
  const { rate, isIncident, className, isHideCco } = props;

  if (isIncident) {
    return <IncidentBadge className={className} />;
  }

  if (rate == null) {
    return null;
  }

  return (
    <ShiftRateBadge rate={rate} className={className} isHideCco={isHideCco} />
  );
};

export default ShiftBadge;
