import { Duration } from "luxon";

const HOUR_IN_SECONDS = 60 * 60;

export function formatDurationBySecond(second: number): string {
  const duration = Duration.fromObject({ second: Math.round(second) });
  if (second >= 10 * HOUR_IN_SECONDS) {
    return duration.toFormat("hh:mm:ss");
  }
  if (second >= HOUR_IN_SECONDS) {
    return duration.toFormat("h:mm:ss");
  }
  return duration.toFormat("mm:ss");
}
