import { Option } from "oneclick-component/src/components/inputs";

/* NOTE(peter)#210: Hardcode at frontend given unlikely change to country codes */
export const COUNTRY_CODES = ["852", "853", "86"];

type CountryCodeNumDigit = Record<string, number>;
export const COUNTRY_CODE_NUM_DIGITS: CountryCodeNumDigit = {
  "852": 8,
  "853": 8,
  "86": 11,
};

export const countryCodeOptions: Option<string>[] = COUNTRY_CODES.map((cc) => ({
  name: `+ ${cc}`,
  value: cc,
}));
