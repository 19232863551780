import i18next from "i18next";
import { LocalizedString } from "../store/apis/enhancedApi";
import { Locale } from "../i18n/Locale";

export const localizeString = (apiLocalizedString: LocalizedString): string => {
  const apiLocaleMap: Record<Locale, keyof LocalizedString> = {
    [Locale.zhHantHK]: "zhHk",
    [Locale.en]: "en",
  };

  const apiLocale = apiLocaleMap[i18next.language as Locale];
  return apiLocalizedString[apiLocale] ?? "null";
};
